<div *ngIf="modal" class="content-wrapper">
  <ag-modal-feedback-icon [status]="status"></ag-modal-feedback-icon>

  <div class="message-modal">
    <h6 class="font-weight-bold">
      {{ translationContextStatus + '.title' | translate }}
    </h6>
    <p>
      {{ translationContextStatus + '.message' | translate }}
    </p>
  </div>

  <button *ngIf="modal.action" type="button" class="btn btn-primary" (click)="onAction()">
    {{ translationContextStatus + '.action' | translate }}
  </button>
</div>
