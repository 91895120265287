import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AgrinessTranslateService } from './translate.service';

@Injectable()
export class AgrinessTranslateInterceptor implements HttpInterceptor {
  constructor(private translateService: AgrinessTranslateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const language = this.translateService.getLanguage();
    if (language) {
      req = req.clone({
        headers: req.headers.set('Accept-Language', language),
      });
    }
    return next.handle(req);
  }
}
