import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ClipboardModule } from 'ngx-clipboard';

import { SharedModule } from '../../common/shared.module';
import { DirectivesModule } from '../../directive/directives.module';
import { AgCopyButtonComponent } from './ag-copy-button.component';

@NgModule({
  imports: [SharedModule, ClipboardModule, DirectivesModule],
  declarations: [AgCopyButtonComponent],
  exports: [AgCopyButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgCopyButtonModule {}
