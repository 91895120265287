import { StageEnum } from '@agriness/services';
import { Observable } from 'rxjs';

import {
  AnimalGroupMonitorOpenedPerformance,
  AnimalGroupMonitorPerformance,
  AnimalGroupMonitorPerformanceByPeriod,
} from '../models/animal-group-performance.model';
import { FilterMonitor } from '../models/monitor.model';
import { PreferenceId } from '../models/user.model';

export interface GetMonitorPerformanceArgs {
  holdingId: string;
  stage: StageEnum;
  report: PreferenceId;
  filter: FilterMonitor;
}

export interface GetMonitorPerformanceByPeriodArgs {
  holdingId: string;
  stage: string;
  filter: FilterMonitor;
}

export abstract class MonitorAbstractService {
  /**
   * @deprecated
   */
  abstract getMonitorPerformance(
    idHolding: string,
    stage: string,
    filter: FilterMonitor,
  ): Observable<AnimalGroupMonitorPerformance[]>;
  abstract getMonitorPerformance(
    params: GetMonitorPerformanceArgs,
  ): Observable<AnimalGroupMonitorPerformance[]>;

  abstract getMonitorPerformanceByPeriod(
    params: GetMonitorPerformanceByPeriodArgs,
  ): Observable<AnimalGroupMonitorPerformanceByPeriod>;

  /**
   * @deprecated use {@link getMonitorPerformance}
   */
  abstract getMonitorPerformanceOpened(
    idHolding: string,
    filter: FilterMonitor,
  ): Observable<AnimalGroupMonitorOpenedPerformance[]>;
}
