import {
  UserStorageService,
  userHoldingRelationByName,
  UserHoldingRelationType,
  TypeProductionService,
} from '@agriness/services';
import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SettingsGuard implements CanLoad {
  constructor(
    private userStorageService: UserStorageService,
    private router: Router,
    private typeProductionService: TypeProductionService,
  ) {}

  canLoad(): boolean {
    const allowedProfileName = [UserHoldingRelationType.OWNER, UserHoldingRelationType.PARTNER];
    return allowedProfileName.includes(this.getUserRelation()) || this.redirectToHome();
  }

  private getUserRelation(): UserHoldingRelationType {
    const {
      userProfile,
      userPreferences: { currentHolding },
    } = this.userStorageService.getUserAgriness();

    const userRelation = currentHolding.users_relations.find(
      relation => relation.user.id == userProfile.id,
    );

    return userHoldingRelationByName[userRelation?.relation_type?.name];
  }

  private redirectToHome(): boolean {
    void this.router.navigateByUrl(`/${this.typeProductionService.get()}`);
    return false;
  }
}
