import { CorpEmbedReportTabComponent } from '@agriness/corp-app/shared/component/corp-embed-report/corp-embed-report-tab.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
const routes: Routes = [
  { path: '', component: DashboardComponent },
  {
    path: 'looker-panel',
    component: CorpEmbedReportTabComponent,
    data: {
      page_height: 2550,
      type: 'looker',
      theme: 'agrinesscorp',
    },
  },
];

export const routerLabs: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);

@NgModule({
  imports: [routerLabs],
  exports: [RouterModule],
})
export class LabsRoutingModule {}
