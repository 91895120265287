<ag-feedback [type]="typeFeedback"> </ag-feedback>

<iframe
  *ngIf="!typeFeedback"
  [id]="frameId"
  width="100%"
  [attr.height]="pageHeight"
  [src]="url"
  frameborder="0"
  style="border: 0;"
></iframe>
