import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

import { SharedModule } from '../../common/shared.module';
import { AgTextFieldComponent } from './ag-text-field.component';

@NgModule({
  declarations: [AgTextFieldComponent],
  imports: [FormsModule, ReactiveFormsModule, CommonModule, SharedModule, InputTextModule],
  exports: [AgTextFieldComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgTextFieldModule {}
