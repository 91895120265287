import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { GuaviGhostButtonComponent } from './guavi-ghost-button.component';
import { AgrinessIconModule } from '../icon/agriness-icon.module';

@NgModule({
  declarations: [GuaviGhostButtonComponent],
  imports: [SharedModule, AgrinessIconModule],
  exports: [GuaviGhostButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GuaviGhostButtonModule {}
