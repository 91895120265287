import { CardModel } from '@agriness/ui';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { InventoryTable, SearchFilterInventory } from '../models/inventory.model';

@Injectable()
export class InventoryService {
  private pathInventory = 'holdings/{holdingId}/layers/{stage}/inventory/{section}/';

  constructor(private apiCorpService: ApiCorpService) {}

  getInventory(
    holdingId: string,
    stage: string,
    section: string,
    searchFilter: SearchFilterInventory,
  ): Observable<InventoryTable[]> {
    const url = this.apiCorpService.translateUrl(this.pathInventory, {
      holdingId,
      stage,
      section,
    });

    return this.apiCorpService.get(url, searchFilter);
  }

  getInventoryCards(
    holdingId: string,
    stage: string,
    searchFilter: SearchFilterInventory,
  ): Promise<CardModel[]> {
    const url = this.apiCorpService.translateUrl(this.pathInventory, {
      holdingId,
      stage,
      section: 'cards',
    });

    return this.apiCorpService.get<CardModel[]>(url, searchFilter).toPromise();
  }
}
