import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

import { AgPopoverComponent } from './ag-popover.component';

@NgModule({
  declarations: [AgPopoverComponent],
  exports: [AgPopoverComponent],
  imports: [CommonModule, NgbPopoverModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgPopoverModule {}
