<div *ngIf="mortality" class="card shadow-sm">
  <div class="card-body">
    <div class="card-title mb-3">
      <span class="mr-2">
        {{ 'agriness.sanity.mortality_by_week' | translate }}
      </span>
    </div>

    <div class="section-header ml-0">
      {{ 'agriness.performances.total_deaths' | translate }}: {{ mortality.total.value }}
      <ng-container *ngFor="let item of mortality.performances">
        <span class="has-text-grey-3 mx-2">|</span>
        {{ 'agriness.sanity.performances.' + item.index_name | translate }}<span>: </span>
        <ag-format
          [value]="item.performance[0].index_value"
          [style]="item.measurement_unit"
          [decimalPlaces]="item.decimal_places"
        ></ag-format>

        <span
          *ngIf="item.target_definition"
          class="font-weight-normal text-lowercase has-text-grey-3"
        >
          <span> (</span>{{ 'agriness.target' | translate }}
          <span>: </span>
          <ag-format
            [value]="item.target_definition.target_value"
            [style]="item.measurement_unit"
            [decimalPlaces]="item.decimal_places"
          ></ag-format
          ><span>)</span>
        </span>
      </ng-container>
    </div>

    <ag-death-table
      [rows]="rows"
      [columns]="columns"
      [rowsData]="rowsData"
      [footerData]="footerData"
    ></ag-death-table>
  </div>
</div>
