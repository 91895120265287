<section class="is-full">
  <corp-report-filters
    #reportFilter
    [availableFilters]="filterIds"
    [stage]="stage"
    (filter)="filter()"
    (ready)="onFilterReady()"
    class="mb-3"
  ></corp-report-filters>

  <div *ngIf="globalFeedbackType">
    <ag-feedback
      [type]="globalFeedbackType"
      [position]="FeedbackPosition.CENTER_PARENT"
    ></ag-feedback>
  </div>

  <div [hidden]="globalFeedbackType">
    <div *ngIf="tabs.length" class="tabs">
      <ul class="nav nav-tabs nav-inline mb-4" id="nav-tab">
        <li class="nav-item" *ngFor="let tab of tabs">
          <a
            class="nav-link"
            [ngClass]="{
              active: selectedTab.router === tab.router && selectedPerformanceIndicator
            }"
            (click)="changeTab(tab)"
            [id]="tab.router"
          >
            {{ tab.translateKey | translate }}
          </a>
        </li>
      </ul>
    </div>

    <corp-report-performance-analysis-cards #cards></corp-report-performance-analysis-cards>

    <div class="section-header d-flex align-items-center">
      <span class="mr-2" [innerHTML]="sectionName | translate"></span>
    </div>

    <corp-chart-table-template
      [topContent]="topContent"
      [leftContent]="leftContent"
      [rightContent]="rightContent"
    >
      <ng-template #topContent>
        <div class="card-title d-flex align-items-center">
          {{ getTitle() }}

          <ag-popover
            autoClose="true"
            placement="bottom-left"
            class="ml-2"
            iconClass="icon-settings"
            styleClass="btn btn-ghost has-round-hover"
            [template]="popoverTemplate"
          >
            <ng-template #popoverTemplate>
              <a
                *ngFor="let option of groupByOptions"
                (click)="changeGroupBy(option)"
                class="d-flex popover-button"
              >
                {{ 'agriness.performance_analysis.group_by.' + option | translate }}
              </a>
            </ng-template>
          </ag-popover>
          <button
            *ngIf="!(performanceIndicatorFeedbackType || chartsFeedbackType)"
            class="btn btn-ghost has-round-hover ml-2"
            (click)="onNextDisplayTypeClick()"
          >
            <span class="icon" [ngClass]="nextDisplayIcon()"></span>
          </button>
          <corp-export-button
            *ngIf="!(performanceIndicatorFeedbackType || chartsFeedbackType) && !isTable()"
            [filename]="'agriness.performance_analysis.chart_title' | translate"
            [lazyInstance]="this.chart | lazy"
          >
          </corp-export-button>
          <button
            *ngIf="isTable()"
            (click)="tableService.exportCSV(pTables.first)"
            class="btn btn-ghost has-round-hover"
          >
            <span class="icon is-16 icon-csv"></span>
          </button>
          <corp-performance-analysis-simulation
            *ngIf="isDairy() && !globalFeedbackType"
            [currentTab]="selectedTab"
            [filter]="reportFilter"
            (visualize)="changeSimulationInput($event)"
          >
          </corp-performance-analysis-simulation>
        </div>
        <div class="d-flex justify-content-end flex-grow-1">
          <container class="px-1" *ngFor="let option of displayOptions">
            <button
              class="btn is-small rounded"
              [ngClass]="option === performanceData.displayOption ? 'btn-primary' : 'btn-secondary'"
              (click)="changeDisplayOption(option)"
            >
              {{ 'agriness.performance_analysis.display_option.' + option | translate }}
            </button>
          </container>
        </div>
      </ng-template>
      <ng-template #leftContent>
        <corp-radio-options
          controlName="optionsPerformanceIndicator"
          *ngIf="
            showPerformanceIndicatorOptions &&
            selectedPerformanceIndicator &&
            !performanceIndicatorFeedbackType
          "
          [selected]="selectedPerformanceIndicator"
          [options]="optionsPerformanceIndicator"
          (optionSelected)="changePerformanceIndicator($event)"
        ></corp-radio-options>
      </ng-template>
      <ng-template #rightContent>
        <ag-feedback
          class="h-100"
          [type]="performanceIndicatorFeedbackType || chartsFeedbackType"
          [hidden]="!(performanceIndicatorFeedbackType || chartsFeedbackType)"
          [position]="FeedbackPosition.CENTER_EXPAND"
        ></ag-feedback>

        <ng-container
          *ngIf="!(performanceIndicatorFeedbackType || chartsFeedbackType) && isChart()"
          [ngTemplateOutlet]="graphTemplate || defaultGraphTemplate"
          [ngTemplateOutletContext]="{
            $implicit: { performanceData: performanceData, chartInstance: registerChart.bind(this) }
          }"
        ></ng-container>

        <ng-template #defaultGraphTemplate let-config>
          <corp-performance-graph
            class="position-absolute"
            style="right: 0; left: 0; bottom: 0; top: 0;"
            (chartInstance)="config.chartInstance($event)"
            [performanceData]="config.performanceData"
            [useDefaultLegend]="true"
          ></corp-performance-graph>
        </ng-template>

        <ag-table
          *ngIf="!(performanceIndicatorFeedbackType || chartsFeedbackType) && isTable()"
          #dt
          [exportFilename]="downloadFilename"
          [data]="tableViewData"
          [frozenColumns]="frozenTableColumns"
          [columns]="scrollableTableColumns"
          [scrollable]="true"
          [scrollHeight]="chartHeight"
          (sort)="onSort($event)"
        >
          <ng-template pTemplate="bodycell" let-col="column" let-data="data" let-rowData="rowData">
            <td>
              <ng-container
                *ngIf="col?.field === 'count' && data?.value; then flockCell; else defaultCell"
              >
              </ng-container>

              <ng-template #flockCell>
                <ag-link
                  class="slot"
                  [route]="activeFlockRoute"
                  [query]="getCurrentFilterWithAge(rowData.current_age.value)"
                >
                  {{ data?.value }}
                </ag-link>
              </ng-template>

              <ng-template #defaultCell>
                <ag-table-cell-content
                  [data]="data"
                  [column]="col"
                  [showSymbol]="false"
                  [dateFormat]="dateFormat"
                ></ag-table-cell-content>
              </ng-template>
            </td>
          </ng-template>
        </ag-table>
      </ng-template>
    </corp-chart-table-template>
  </div>
</section>
