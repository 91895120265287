import {
  AnimalGroupMonitorPerformance,
  GetMonitorPerformanceArgs,
  ReportEnum,
} from '@agriness/corp-app/services';
import {
  PerformanceAnalysisUrlFilter,
  Report,
  SearchFilterPerformanceAnalysis,
} from '@agriness/corp-app/services/models/performance-analysis.model';
import { MonitorService } from '@agriness/corp-app/services/monitor/monitor.service';
import { PerformanceAnalysisService } from '@agriness/corp-app/services/performance-analysis/performance-analysis.service';
import { LinkSection } from '@agriness/corp-app/shared/model/link-builder.model';
import { CardBuilderService } from '@agriness/corp-app/shared/services/card-builder.service';
import { LinkBuilderService } from '@agriness/corp-app/shared/services/link-builder.service';
import { CardModel, FeedbackEnum } from '@agriness/ui';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

import { PerformanceAnalysisPerformance } from './corp-report-performance-analysis.model';

@Component({
  selector: 'corp-report-performance-analysis-cards',
  templateUrl: './corp-report-performance-analysis-cards.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpReportPerformanceAnalysisCardsComponent {
  indexLabelPrefix = 'agriness.performances.';

  cards: CardModel[] = [];
  feedbackType: FeedbackEnum = FeedbackEnum.LOADING;

  constructor(
    private cardBuilder: CardBuilderService,
    private monitorService: MonitorService,
    private performanceAnalysisService: PerformanceAnalysisService,
    private changeDetectorRef: ChangeDetectorRef,
    private linkBuilder: LinkBuilderService,
  ) {}

  loadPerformanceCards(
    args: GetMonitorPerformanceArgs,
  ): Observable<PerformanceAnalysisPerformance[]> {
    this.feedbackType = FeedbackEnum.LOADING;
    this.changeDetectorRef.markForCheck();

    return this.monitorService.getMonitorPerformance(args).pipe(
      map(performances =>
        performances.map(performance => ({
          ...this.cardBuilder.parsePerformanceToCardMonitorPerformances(
            performance,
            this.indexLabelPrefix,
            args.filter,
            args.stage,
          ),
          action: false,
        })),
      ),
      tap(cards => {
        this.cards = cards;
        this.feedbackType = null;
      }),
      catchError(err => {
        this.feedbackType = FeedbackEnum.ERROR;
        return throwError(err);
      }),
      finalize(() => this.changeDetectorRef.markForCheck()),
    );
  }

  loadPerformanceCardsForLayers(
    urlFilter: PerformanceAnalysisUrlFilter,
    searchFilter: SearchFilterPerformanceAnalysis,
  ): Observable<CardModel[]> {
    this.feedbackType = FeedbackEnum.LOADING;
    this.changeDetectorRef.markForCheck();

    return this.performanceAnalysisService.loadPerformanceCards(urlFilter, searchFilter).pipe(
      map(performances =>
        performances.map(performance => {
          const card = this.cardBuilder.parsePerformanceToCardMonitorPerformances(
            performance,
            this.indexLabelPrefix,
            searchFilter,
            urlFilter.stage,
          ) as CardModel;

          if (this.hasLink(urlFilter)) {
            card.link = this.getLink(urlFilter, searchFilter, performance);
            return { ...card, action: true };
          }

          return card;
        }),
      ),
      tap(cards => {
        this.cards = cards;
        this.feedbackType = null;
      }),
      catchError(err => {
        this.feedbackType = FeedbackEnum.ERROR;
        return throwError(err);
      }),
      finalize(() => this.changeDetectorRef.markForCheck()),
    );
  }

  private hasLink(urlFilter: PerformanceAnalysisUrlFilter) {
    return urlFilter.report === Report.CARDS_FOR_STRAIN;
  }

  private getLink(
    urlFilter: PerformanceAnalysisUrlFilter,
    searchFilter: SearchFilterPerformanceAnalysis,
    performance: AnimalGroupMonitorPerformance,
  ) {
    return this.linkBuilder.build({
      stage: urlFilter.stage,
      section: LinkSection.ANALYSIS,
      report: ReportEnum.ANIMALGROUP_LIST,
      queryParams: { ...searchFilter, strain_id: performance.id },
    });
  }
}
