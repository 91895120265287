import { AgrinessTranslateService } from '@agriness/services';
import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import {
  getValueStyle,
  ShowSymbol,
  ValueFormatStyle,
  LocaleByDecimalPlaces,
} from './value-format.model';

const defaultDecimalPlaces = 2;

@Pipe({
  name: 'agValueFormat',
})
export class ValueFormatPipe implements PipeTransform {
  private _currency = 'BRL';
  private _dateFormat = 'dd/MM/yyyy';
  private _numberFormatLocale = 'pt-BR';

  constructor(private translateService?: AgrinessTranslateService) {
    if (this.translateService) {
      const decimalPlaces = this.translateService.getDecimalSeparator();

      if (decimalPlaces) {
        this._numberFormatLocale = LocaleByDecimalPlaces[decimalPlaces];
      }
    }
  }

  get currency(): string {
    return this._currency;
  }

  set currency(value: string) {
    this._currency = value;
  }

  get dateFormat(): string {
    return this._dateFormat;
  }

  set dateFormat(value: string) {
    this._dateFormat = value;
  }

  get numberFormatLocale(): string {
    return this._numberFormatLocale;
  }

  set numberFormatLocale(value: string) {
    this._numberFormatLocale = value;
  }

  transform(
    value: any,
    style: ValueFormatStyle | string,
    decimalPlaces = defaultDecimalPlaces,
    showSymbolOpt: ShowSymbol = 'show-symbol',
  ): string {
    style = getValueStyle(value, style);

    decimalPlaces = this.getDecimalPlaces(style, decimalPlaces);
    const showSymbol = showSymbolOpt !== 'hide-symbol';

    const transformedValue = this.getValue(value, style, decimalPlaces, showSymbol);

    if (transformedValue == null) {
      return '-';
    }

    const symbol = showSymbol ? this.getSymbol(style, transformedValue) : null;
    return symbol ? transformedValue + ' ' + symbol : transformedValue;
  }

  private getValue(
    value: any,
    style: ValueFormatStyle | string,
    decimalPlaces: number,
    showSymbol: boolean,
  ) {
    if (value == null) {
      return null;
    }
    if (style === ValueFormatStyle.DATE) {
      return formatDate(value, this.dateFormat, 'en-US');
    } else if (typeof value === 'number') {
      if (showSymbol && style === ValueFormatStyle.PERCENTAGE) {
        value = value / 100;
      }

      // Intl.NumberFormat have no support for node before version 13.0.0
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
      return new Intl.NumberFormat(this.numberFormatLocale, {
        style: this.getNumberIntlStyle(style, showSymbol),
        maximumFractionDigits: decimalPlaces,
        minimumFractionDigits: decimalPlaces,
        currency: this.currency,
      }).format(value);
    } else {
      if (typeof value === 'string') {
        value = value.trim();
      }
      return value ?? null;
    }
  }

  private getDecimalPlaces(style: ValueFormatStyle | string, decimalPlaces: number) {
    if (style === ValueFormatStyle.UNITARY) {
      return 0;
    }
    if (decimalPlaces == null) {
      return defaultDecimalPlaces;
    }
    if (decimalPlaces < 0) {
      return 0;
    }
    return decimalPlaces;
  }

  private getNumberIntlStyle(style: ValueFormatStyle | string, showSymbol: boolean) {
    if (showSymbol) {
      if (style === ValueFormatStyle.PERCENTAGE) {
        return 'percent';
      } else if (style === ValueFormatStyle.MONETARY) {
        return 'currency';
      }
    }
    return 'decimal';
  }

  getSymbol(style: ValueFormatStyle | string, value): string {
    switch (style) {
      /* case ValueFormatStyle.GRAM:
        return 'g';
        case ValueFormatStyle.KILOGRAM:
        return 'kg';
      case ValueFormatStyle.POUNDS:
        return 'lb';
      case ValueFormatStyle.TONNES:
        return 't';
      case ValueFormatStyle.LITER:
        return 'L'; */
      case ValueFormatStyle.WEEKS:
        return value > 1
          ? this.translateService.instantWithFallback('agriness.unit.weeks')
          : this.translateService.instantWithFallback('agriness.unit.week');
      case ValueFormatStyle.CELSIUS:
        return this.translateService.instantWithFallback('agriness.unit.celsius');
      default:
        return null;
    }
  }
}
