import { SectionModel, SectionService } from '@agriness/ui';
import { Component } from '@angular/core';

import { SectionAbstractComponent } from '../shared/component/section-abstract.component';
import { FinishingService } from './finishing.service';

@Component({
  templateUrl: './finishing.component.html',
})
export class FinishingComponent extends SectionAbstractComponent {
  constructor(
    protected sectionService: SectionService,
    protected finishingService: FinishingService,
  ) {
    super(sectionService);
  }

  protected getSections(): SectionModel[] {
    return this.finishingService.getSections();
  }
}
