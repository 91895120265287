import { SectionAbstractComponent } from '@agriness/corp-app/shared/component/section-abstract.component';
import { SectionService, SectionModel } from '@agriness/ui';
import { Component } from '@angular/core';
import { environment } from '@env/environment.dev';

import { ProductionParamsSectionService } from './production-params-section.service';

@Component({
  selector: 'app-production-params-section',
  templateUrl: './production-params-section.component.html',
  styleUrls: ['./production-params-section.component.scss'],
})
export class ProductionParamsSectionComponent extends SectionAbstractComponent {
  typeProduction = environment.typeProduction;

  constructor(
    protected sectionService: SectionService,
    protected productionParamsService: ProductionParamsSectionService,
  ) {
    super(sectionService);
  }

  protected getSections(): SectionModel[] {
    return this.productionParamsService.getSections();
  }
}
