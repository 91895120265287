import { LookerService } from '@agriness/corp-app/services/looker/looker.service';
import { LookerPanel } from '@agriness/corp-app/services/models/looker-panel.model';
import { LookerUrl } from '@agriness/corp-app/services/models/looker.model';
import { TypeProductionService, UserStorageService } from '@agriness/services';
import { FeedbackEnum } from '@agriness/ui';
import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { LookerAttributeService } from '../../services/looker-attribute.service';
import { EmbedReportData } from './corp-embed-report-tab.model';

@Component({
  templateUrl: './corp-embed-report-tab.component.html',
})
export class CorpEmbedReportTabComponent {
  url: SafeResourceUrl;
  pageHeight: number;
  frameId: string;
  typeFeedback = FeedbackEnum.LOADING;
  lookerPanel: LookerPanel;

  constructor(
    private readonly route: ActivatedRoute,
    private location: Location,
    public sanitizer: DomSanitizer,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
    private lookerService: LookerService,
    private lookerAttributeService: LookerAttributeService,
  ) {
    const routeData = this.route.snapshot.data as EmbedReportData;
    this.lookerPanel = this.location.getState()['data'] as LookerPanel;
    this.setEmbeddedOption(routeData);
  }

  private setEmbeddedOption(routeData: EmbedReportData) {
    const options = this.getEmbeddedOptions();
    const selectedOption = options[routeData.type];
    if (selectedOption) {
      selectedOption(routeData);
      this.pageHeight = routeData.page_height;
      this.frameId = routeData.type;
    }
  }

  private getEmbeddedOptions() {
    return {
      looker: (routeData: EmbedReportData): void => {
        this.getEmbeddedLookerUrl(routeData).subscribe(url => {
          this.setUrl(url);
          this.typeFeedback = null;
        });
      },
      onepage: (routeData: EmbedReportData): void => {
        this.setUrl(routeData.url);
        this.typeFeedback = null;
      },
    };
  }

  private getEmbeddedLookerUrl(routeData: EmbedReportData): Observable<LookerUrl> {
    const user_profile = this.userStorageService.getUserProfile();
    const result = this.lookerService.getEmbeddedUrl(this.lookerPanel.id, {
      user_id: user_profile.id,
      theme: routeData.theme,
      ...this.lookerAttributeService.resolve(this.lookerPanel.attributes),
    });
    return result;
  }

  private setUrl(url: string) {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
