import { CorpReportFiltersComponent } from '@agriness/corp-app/shared/component/corp-report-filters/corp-report-filters.component';
import {
  PeriodInterval,
  PeriodUnit,
  ReportFilterType,
  ReportFilterQuery,
  ReportFilterPeriodDefaultValue,
} from '@agriness/corp-app/shared/model/report-filter.model';
import { SectionModel, SectionService, FeedbackEnum } from '@agriness/ui';
import { Component, ViewChild, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SectionAbstractComponent } from '../shared/component/section-abstract.component';
import { CorpReportComponent } from '../shared/model/report.model';
import { PresenceCalendarComponent } from './presence-calendar/presence-calendar.component';

@Component({
  templateUrl: './presence.component.html',
  styleUrls: [],
})
export class PresenceComponent extends SectionAbstractComponent implements OnDestroy {
  @ViewChild('reportFilter', { static: true }) reportFilter: CorpReportFiltersComponent;

  @ViewChildren('cardsReport')
  cardsReport: QueryList<CorpReportComponent>;

  @ViewChild('calendarReport')
  calendarReport: PresenceCalendarComponent;

  FeedbackEnum = FeedbackEnum;
  feedbackType = FeedbackEnum.LOADING;

  preferenceLabelPrefix = 'agriness.monitor.performances.';
  pageTitle = 'agriness.presence.page_title';
  sectionPerformanceTitle = 'agriness.presence.section_performance_title';
  report = 'technicians-monitor-performance-indicators';

  filterIds: ReportFilterType[] = ['PERIOD', { type: 'TECHNICIAN', hasUser: true }];
  periodDefaultValue: ReportFilterPeriodDefaultValue = [PeriodInterval.LAST, PeriodUnit.DAYS, 30];
  currentFilter: ReportFilterQuery;
  subscription: Subscription;

  constructor(protected sectionService: SectionService) {
    super(sectionService);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  filter(): void {
    this.loadPreferences().subscribe({ complete: () => this.loadData() });
  }

  loadData(): void {
    this.feedbackType = FeedbackEnum.LOADING;
    this.subscription?.unsubscribe();
    this.currentFilter = this.reportFilter.getCurrentFilterInQueryFormat();

    this.loadCards();

    this.loadCalendar();
  }

  protected getSections(): SectionModel[] {
    return [];
  }

  private loadCalendar(): void {
    this.calendarReport.loadCalendar(
      this.currentFilter.begin_date,
      this.currentFilter.end_date,
      this.currentFilter.technician_id,
    );
  }

  private loadCards(): void {
    const observables = this.getPerformancesObservables().map(obs =>
      obs.pipe(catchError(error => of({ error: error as unknown }))),
    );

    this.subscription = forkJoin(observables).subscribe(
      results => {
        if (results.every(item => 'error' in item)) {
          this.feedbackType = FeedbackEnum.ERROR;
        } else {
          this.feedbackType = null;
        }
      },
      error => {
        this.feedbackType = FeedbackEnum.ERROR;
        console.error(error);
      },
    );
  }

  private getPerformancesObservables(): Observable<CorpReportComponent>[] {
    return this.cardsReport.map(
      p =>
        p.loadPerformances(this.reportFilter.getCurrentFilterInQueryFormat()) as Observable<
          CorpReportComponent
        >,
    );
  }

  private loadPreferences(): Observable<QueryList<CorpReportComponent>[]> {
    return forkJoin(
      this.cardsReport.map(p => p.loadPreferences() as Observable<QueryList<CorpReportComponent>>),
    );
  }
}
