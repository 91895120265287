import { AnimalGroup, AnimalGroupForm } from '@agriness/corp-app/services';
import { StageEnum, TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'animal-group-editor',
  templateUrl: './animal-group-editor.component.html',
  styleUrls: ['./animal-group-editor.component.scss'],
})
export class AnimalGroupEditorComponent implements OnInit {
  @Input() visible = false;
  @Input() animalGroupData: AnimalGroup;
  @Input() modalStatus = ModalStatusEnum.DEFAULT;
  @Input() stage: StageEnum;

  @Output() closed = new EventEmitter<void>();
  @Output() updateAnimalGroup = new EventEmitter<void>();

  errorMessages = {
    producer: '',
  };

  translationContext = 'agriness.settings.animal_group';

  animalGroupForm: FormGroup;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    protected $formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.animalGroupForm = this.initializeForm();
    this.updatedFormAnimalGroup();
    this.listenValueChanges();
  }

  initializeForm(): FormGroup {
    return this.$formBuilder.group({
      farm: [null],
      name: [null],
      nutrition_type: [null],
      producer: [null],
      region: [null],
      technician: [null],
    });
  }

  listenValueChanges(): void {
    for (const control in this.animalGroupForm.controls) {
      this.animalGroupForm.controls[control].valueChanges.subscribe(() => {
        const { errors } = this.animalGroupForm.get(control);
        this.errorMessages[control] = errors ? this.t('agriness.settings.validation_required') : '';
      });
    }
  }

  onSubmit(): void {
    if (this.animalGroupData) {
      this.updateAnimalGroup.emit();
    }
  }

  onClosed(): void {
    this.closed.emit();
  }

  updatedFormAnimalGroup(): void {
    const { name, farm, nutrition_type, producer, technician, region } = this.animalGroupData;

    this.animalGroupForm.patchValue({
      name,
      farm: farm?.name ?? null,
      nutrition_type: nutrition_type?.id || null,
      producer: producer.id,
      technician: technician?.id || null,
      region: region?.id || null,
    });
  }

  getAnimalGroupForm(): AnimalGroupForm {
    this.animalGroupForm.removeControl('name');
    return this.animalGroupForm.getRawValue() as AnimalGroupForm;
  }
}
