<div
  class="form-group has-feedback"
  [formGroup]="formGroup"
  [ngStyle]="{ minWidth: inputWidth, width: inputWidth }"
  [ngClass]="{ 'has-error': errorMessage }"
>
  <input
    class="form-control"
    [attr.disabled]="disabled ? '' : null"
    type="text"
    [name]="inputId"
    [formControlName]="controlName"
    [required]="required"
    pInputText
  />
  <label class="control-label">{{ label }}</label>
  <div class="input-feedback" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
</div>
