import { Component, Input } from '@angular/core';

import { StatusItems, Header } from '../status.model';

@Component({
  selector: 'ag-status-card',
  templateUrl: './ag-status-card.component.html',
})
export class AgStatusCardComponent {
  @Input() items: StatusItems;
  @Input() header: Header;

  target(): string {
    return this.header.newTab ? '_blank' : '_self';
  }
}
