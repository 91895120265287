import { Search, Stage, TypeProductionEnum } from '@agriness/services';

import { Crossbred } from './crossbred.model';
import { Diet } from './diet.model';
import { Farm } from './farm.model';
import { Hatchery } from './hatchery.model';
import { Location } from './location.model';
import { NutritionType } from './nutrition-type.model';
import { Producer } from './producer.model';
import { Region } from './region.model';
import { Tag } from './tag.model';
import { Technician } from './technician.model';
export class SearchAnimalGroupFarm extends Search<ResultAnimalGroupFarm> {
  constructor() {
    super();
  }

  static fromJson(jsonData: Record<string, unknown>): SearchAnimalGroupFarm {
    return Object.assign(new SearchAnimalGroupFarm(), jsonData);
  }
}

export class ResultAnimalGroupFarm {
  constructor(
    public id?: string | number,
    public is_open?: boolean,
    public name?: string,
    public animal_group_type?: string,
    public farm?: Farm,
    public producer?: Producer,
    public detail?: AnimalGroupRecordDetail,
  ) {}

  get display_name(): string {
    if (!this.name && this.producer) {
      return '';
    }
    if (this.producer && this.producer.name) {
      return this.name + ' - ' + this.producer.name;
    }
    if (this.detail && this.detail.producer) {
      return this.name + ' - ' + this.detail.producer;
    }
    return this.name + ' - ' + this.farm.name;
  }
}

export class AnimalGroup {
  constructor(
    public id?: string,
    public is_open?: boolean,
    public planned?: boolean,
    public name?: string,
    public animal_group_type?: string,
    public incoming_date?: string,
    public outgoing_date?: string,
    public prediction_date?: string,
    public closed_date?: string,
    public nutrition_type?: NutritionType,
    public producer?: Producer,
    public farm?: Farm,
    public stage?: Stage,
    public pen?: string,
    public crossbred?: Crossbred,
    public locations?: Location[],
    public tags?: Tag[],
    public technician?: Technician,
    public installation_type?: string,
    public hatcheries?: Hatchery[],
    public age_at_transfer?: number,
    public strain?: string,
    public cycle_plan?: string,
    public production_system?: string,
    public region?: Region,
    public total_animal_number_input?: number,
    public feed?: Diet[],
  ) {}
}

export interface MeasurmentUnits {
  weight_measurement_unit?: string;
  currency_unit?: string;
}

export interface AnimalGroupForm {
  farm: string;
  name: string;
  nutrition_type: string;
  producer: string;
  region: string;
  technician: string;
}

export class AnimalGroupRecord {
  constructor(
    public id?: string,
    public name?: string,
    public is_open?: boolean,
    public planned?: boolean,
    public stage?: Stage,
    public farm?: Farm,
    public detail?: AnimalGroupRecordDetail,
    public feed?: Diet[],
  ) {}
}

export class AnimalGroupRecordDetail {
  constructor(
    public incoming?: string,
    public outgoing?: string,
    public prediction_outgoing?: string,
    public producer?: string,
    public location?: string,
    public farm?: string,
    public crossbred?: string,
    public pen?: string,
    public gender?: string,
    public technician?: string,
    public installation_type?: string,
    public hatchery?: string,
    public age_at_transfer?: string,
    public site?: string,
    public strain?: string,
    public cycle_plan?: string,
    public production_system?: string,
    public total_animal_number_input?: string,
  ) {}
}

export const mainDetailsByProduction: {
  [key in TypeProductionEnum]: Array<keyof AnimalGroupRecordDetail>;
} = {
  [TypeProductionEnum.DAIRY]: [],
  [TypeProductionEnum.LAYERS]: ['site'],
  [TypeProductionEnum.POULTRY]: ['producer', 'location'],
  [TypeProductionEnum.SWINES]: ['producer', 'location'],
};
export interface AnimalGroupDailyData {
  daily: Array<{
    period: number;
    value_accumulated?: number;
    value_accumulated_target?: number;
    begin_date?: string;
    end_date?: string;
    nutrition_type?: string;
    days?: number;
    difference?: number;
    difference_percentage?: number;
    balance?: number;
    feed_per_animal?: number;
    average_daily_consumption_target?: number;
    average_daily_consumption?: number;
    total_feed_per_animal?: number;
    average_sample_weighing?: number;
    consumption_per_day?: number;
    consumption_per_animal?: number;
    target_plan?: number;
    target_index?: number;
    weight_measurement_unit: string;
  }>;
  target_definition?: {
    daily?: {
      [day: string]: {
        period: number;
        targets: {
          [status: string]: {
            [condition: string]: number;
          };
        };
      };
    };
  };
}
