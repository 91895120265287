import { StageEnum } from '@agriness/services';
import { Observable } from 'rxjs';

import { AnimalGroupIndicators, FilterIndicators } from '../models/animal-group-indicators.model';

export abstract class MapIndicatorsAbstractService {
  abstract getIndicators(
    holdingId: string,
    stage: StageEnum,
    filter?: FilterIndicators,
  ): Observable<AnimalGroupIndicators[]>;
}
