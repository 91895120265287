import { AnimalGroupStatus, StageEnum, UserStorageService } from '@agriness/services';
import { CardLink, FeedbackEnum } from '@agriness/ui';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ReportFilterType, ReportFilterQuery } from '../../model/report-filter.model';
import { CorpReportComponent, ReportMonitorTabsEnum } from '../../model/report.model';
import { CorpReportFiltersComponent } from '../corp-report-filters/corp-report-filters.component';
import { LoaderUserPreference } from '../loader-user-preference';
import {
  filterIdsByTabAndProduction,
  subReportsByTabAndProduction,
  SubReportEnum,
} from './corp-report-monitor-tabs.model';

@Component({
  selector: 'corp-report-monitor-tabs',
  styleUrls: ['./corp-report-monitor-tabs.component.scss'],
  templateUrl: './corp-report-monitor-tabs.component.html',
})
export class CorpReportMonitorTabsComponent extends LoaderUserPreference implements OnInit {
  @Input() animalGroupStatus: AnimalGroupStatus;
  @ViewChild('reportFilter', { static: true })
  reportFilter: CorpReportFiltersComponent;

  @ViewChildren('report')
  reports: QueryList<CorpReportComponent>;

  type = environment.typeProduction;
  typeFeedback = FeedbackEnum.LOADING;
  linkAnimalGroupList: CardLink;
  stage: StageEnum;
  filterIds: ReportFilterType[];
  currentFilter: ReportFilterQuery;

  selectedTab: ReportMonitorTabsEnum;
  closedSubReports: SubReportEnum[];
  openSubReports: SubReportEnum[];

  constructor(
    protected userStorageService: UserStorageService,
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
  ) {
    super(userStorageService);
  }

  ngOnInit(): void {
    this.animalGroupStatus = AnimalGroupStatus.CLOSED;
    this.selectedTab = this.route.snapshot.routeConfig.path as ReportMonitorTabsEnum;
    this.stage = this.route.snapshot.data.stage as StageEnum;
    this.filterIds = filterIdsByTabAndProduction[this.selectedTab][this.type];
    this.updateReports();
  }

  filter(): void {
    this.updateCurrentFilter();

    this.typeFeedback = FeedbackEnum.LOADING;

    const observables = this.getPerformancesObservables().map(obs =>
      obs.pipe(
        tap(() => (this.typeFeedback = null)),
        catchError(error => of({ error: error as unknown })),
      ),
    );

    forkJoin(observables).subscribe(
      results => {
        if (results.every(item => 'error' in item)) {
          this.typeFeedback = FeedbackEnum.ERROR;
        }
      },
      () => {
        this.typeFeedback = null;
      },
    );

    this.updateLinkAnimalGroupList();
  }

  loadData(): void {
    if (this.selectedTab != ReportMonitorTabsEnum.DIAGNOSTIC_TREE) {
      this.loadPreferences().subscribe({ complete: () => this.filter() });
    } else this.filter();
  }

  private updateReports() {
    this.closedSubReports = subReportsByTabAndProduction[this.selectedTab][this.type].filter(
      r => !r.isOpen,
    );
    this.openSubReports = subReportsByTabAndProduction[this.selectedTab][this.type].filter(
      r => r.isOpen,
    );
  }

  private updateCurrentFilter(): void {
    this.currentFilter = {
      ...this.reportFilter.getCurrentFilterInQueryFormat(),
      is_open: (this.route.snapshot.routeConfig.path == AnimalGroupStatus.OPEN).toString(),
    };
  }

  private updateLinkAnimalGroupList(): void {
    this.linkAnimalGroupList = {
      url: `/${environment.typeProduction}/${this.stage}/analysis/animal-group-list`,
      queryParams: this.currentFilter,
    };
  }

  private getPerformancesObservables(): Array<Observable<CorpReportComponent>> {
    return this.reports.map(
      p => p.loadPerformances(this.currentFilter) as Observable<CorpReportComponent>,
    );
  }

  private loadPreferences(): Observable<unknown[]> {
    return forkJoin(this.reports.map(p => p.loadPreferences()));
  }
}
