import { textColumn, actionColumn } from '@agriness/ui';

import { commonDeletionTableFields } from '../../../deletion-table/deletion-table.model';

const producerKey = 'agriness.settings.producers.table';

export const producersTableColumns = [
  {
    ...actionColumn,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'name',
    header: `${producerKey}.name`,
    width: '500px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'farm_count',
    header: `${producerKey}.bond`,
    width: '500px',
    sortable: true,
    fixed: true,
  },
];

export const producersDeletionTableFields: string[] = [...commonDeletionTableFields];
