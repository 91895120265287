import {
  BaseEntityFilter,
  PageNumberPagination,
  QueryCorp,
  ContextFilter,
} from '@agriness/services';

export interface Routes {
  id: string;
  name: string;
}

export interface RoutesForm {
  name: string;
}

export type RoutesFilter = BaseEntityFilter & PageNumberPagination & ContextFilter & QueryCorp;

export const defaultRoutesOrder: (keyof Routes)[] = ['name', 'id'];
