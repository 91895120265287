import { Icons } from '@agriness/assets';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';

import { AgModalFooterComponent } from './ag-modal-footer/ag-modal-footer.component';
@Component({
  selector: 'ag-modal',
  templateUrl: './ag-modal.component.html',
  styleUrls: ['./ag-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgModalComponent {
  @Input() visible = false;
  @Input() showHeader = false;
  @Input() closable = true;
  @Input() title = '';
  @Input() icon: Icons = null;

  @Output() hide = new EventEmitter();

  @ContentChildren(AgModalFooterComponent) footerQueryList: QueryList<AgModalFooterComponent>;

  onVisibleChange(visible: boolean) {
    this.visible = visible;
    if (!visible) {
      this.hide.emit();
    }
  }
}
