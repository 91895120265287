<div *ngIf="filterForm" [hidden]="hidden" class="input-content" [ngStyle]="{ width: inputWidth }">
  <div class="form-group" id="filter-{{ kebabCase(controlName) }}-form-group">
    <label class="control-label">
      {{ labelKey | translate }}
    </label>
    <ag-select
      [group]="filterForm"
      [controlName]="controlName"
      [items$]="loader.options"
      (scrollToEnd)="loader.paginate()"
      [loading]="loader.loading"
      [defaultLabel]="emptySelectionKey"
      [isSearchable]="isSearchable"
    ></ag-select>
  </div>
</div>
