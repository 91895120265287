import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment.dev';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { generalTabs } from './targets.model';

@Component({
  selector: 'app-targets',
  templateUrl: './targets.component.html',
  styleUrls: ['./targets.component.scss'],
})
export class TargetsComponent implements OnDestroy {
  tabs = generalTabs[environment.typeProduction];
  showTabs = true;
  subscription: Subscription;

  constructor(private router: Router) {
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => event as NavigationEnd),
      )
      .subscribe(event => {
        if (event.url.includes('add') || event.url.includes('edit') || event.url.includes('view')) {
          this.showTabs = false;
        } else {
          this.showTabs = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
