import { BonificationService, Rule } from '@agriness/corp-app/services';
import { CorpWizzard } from '@agriness/corp-app/shared/component/corp-wizzard/corp-wizzard.model';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MainForm } from './rule-editor-form.model';
import { StepConfigComponent } from './step-config/step-config.component';
import { StepMainComponent } from './step-main/step-main.component';

@Component({
  selector: 'app-rule-editor',
  templateUrl: './rule-editor.component.html',
  styleUrls: ['./rule-editor.component.scss'],
})
export class RuleEditorComponent implements OnInit, AfterViewInit {
  @ViewChild(StepMainComponent) main: StepMainComponent;
  @ViewChild(StepConfigComponent) config: StepConfigComponent;

  stepValid = new BehaviorSubject(false);
  pageTitle = 'agriness.payment-rule.title';

  data = new BehaviorSubject<MainForm>(null);
  form: MainForm = null;

  activeIndex: number;
  isValid = false;
  validStep: number;

  wizzardItems: CorpWizzard[];
  isEdit = false;
  editId: string;

  constructor(
    private translateService: TranslateService,
    private bonificationService: BonificationService,
    private readonly route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {
    this.getDataFromRoute();
  }

  ngOnInit(): void {
    this.wizzardItems = [
      {
        items: {
          label: this.translateService.instant('agriness.payment-rule.step-1.title') as string,
          command: () => {
            this.activeIndex = 0;
          },
        },
        nextButtonLable: 'agriness.payment-rule.step-1.next-button',
      },
      {
        items: {
          label: this.translateService.instant('agriness.payment-rule.step-2.title') as string,
          command: () => {
            this.activeIndex = 1;
          },
        },
        nextButtonLable: 'agriness.payment-rule.step-2.next-button',
      },
      {
        items: {
          label: this.translateService.instant('agriness.payment-rule.step-3.title') as string,
          command: () => {
            this.activeIndex = 2;
          },
        },
        saveButtonLable: 'agriness.payment-rule.step-3.save-button',
      },
    ];
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  getDataFromRoute(): void {
    if (this.router.getCurrentNavigation().extras.state) {
      const routerData = this.router.getCurrentNavigation().extras.state as Rule;

      this.isEdit = true;
      this.editId = routerData.id;

      if (!this.data.getValue()) {
        this.data = new BehaviorSubject({
          holding_id: routerData.holding_id,
          name: routerData.name,
          stage: routerData.stage_name,
          type_weight: routerData.type_weight,
          id: routerData.id,
          indexes: routerData.indexes,
          enabled: routerData.enabled,
        });
      }
    }
  }

  setValid(event: boolean, index: number): void {
    this.stepValid.next(this.canNext(event, index));
  }

  canNext(event: boolean, step: number): boolean {
    if (this.activeIndex === step) {
      return event;
    }
    return false;
  }

  setActiveIndex(index: number): void {
    this.activeIndex = index;
  }

  saveForm(): void {
    let data: MainForm;

    this.data.subscribe(res => {
      data = res;
    });

    this.bonificationService.getStageId(data.stage).subscribe(res => {
      if (this.isEdit) {
        this.bonificationService
          .put({ ...data, stage: res.results[0].id }, this.editId)
          .pipe(
            catchError(({ error }) => {
              return throwError(error);
            }),
          )
          .subscribe(() => {
            void this.router.navigate([environment.typeProduction, 'settings', 'bonification']);
          });
      } else {
        this.bonificationService
          .save({ ...data, stage: res.results[0].id })
          .pipe(
            catchError(({ error }) => {
              return throwError(error);
            }),
          )
          .subscribe(() => {
            void this.router.navigate([environment.typeProduction, 'settings', 'bonification']);
          });
      }
    });
  }

  setData(event: MainForm): void {
    const hasData = this.data.getValue();

    if (hasData) {
      this.data.next(event);
    } else {
      this.data = new BehaviorSubject(event);
    }
  }

  next(): void {
    switch (this.activeIndex) {
      case 1:
        this.main.setFormData();
        break;
      case 2:
        this.config.setFormData();
        break;
      default:
        break;
    }
  }
}
