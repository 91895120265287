import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputMaskModule } from 'primeng/inputmask';

import { SharedModule } from '../../common/shared.module';
import { AgCalendarModule } from '../calendar/ag-calendar.module';
import { AgPopoverModule } from '../popover/ag-popover.module';
import { AgPeriodConfigurationComponent } from './ag-period-configuration.component';

@NgModule({
  declarations: [AgPeriodConfigurationComponent],
  imports: [
    FormsModule,
    SharedModule,
    AgPopoverModule,
    InputMaskModule,
    AgCalendarModule,
    ReactiveFormsModule,
  ],
  exports: [AgPeriodConfigurationComponent, FormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgPeriodConfigurationModule {}
