import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { AgFormatModule } from '../format/ag-format.module';
import { AgDeathTableComponent } from './ag-death-table.component';

@NgModule({
  declarations: [AgDeathTableComponent],
  imports: [SharedModule, AgFormatModule],
  exports: [AgDeathTableComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgDeathTableModule {}
