import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { Colors, Icons } from '@agriness/assets';

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  GHOST = 'ghost',
  DANGER = 'danger',
}

export enum ButtonFormat {
  REGULAR = 'regular',
  PILL = 'pill',
  CIRCLE = 'circle',
}

const iconColors: Record<ButtonVariant, Colors> = {
  [ButtonVariant.PRIMARY]: 'color-white',
  [ButtonVariant.SECONDARY]: 'color-grey-dark',
  [ButtonVariant.TERTIARY]: 'color-grey-dark',
  [ButtonVariant.GHOST]: 'color-primary',
  [ButtonVariant.DANGER]: 'color-white',
};

const iconSizeRegular: Record<ButtonSize, number> = {
  [ButtonSize.SMALL]: 16,
  [ButtonSize.MEDIUM]: 16,
  [ButtonSize.LARGE]: 20,
};

const iconSizePill: Record<ButtonSize, number> = {
  [ButtonSize.SMALL]: 16,
  [ButtonSize.MEDIUM]: 16,
  [ButtonSize.LARGE]: 20,
};

const iconSizeCircle: Record<ButtonSize, number> = {
  [ButtonSize.SMALL]: 16,
  [ButtonSize.MEDIUM]: 18,
  [ButtonSize.LARGE]: 24,
};

const iconSizes: Record<ButtonFormat, Record<ButtonSize, number>> = {
  [ButtonFormat.REGULAR]: iconSizeRegular,
  [ButtonFormat.PILL]: iconSizePill,
  [ButtonFormat.CIRCLE]: iconSizeCircle,
};

@Component({
  selector: 'guavi-button',
  templateUrl: './guavi-button.component.html',
  styleUrls: ['./guavi-button.component.scss'],
})
export class GuaviButtonComponent implements OnChanges {
  @Input() disabled: boolean = false;
  @Input() variant: ButtonVariant = ButtonVariant.PRIMARY;
  @Input() format: ButtonFormat = ButtonFormat.REGULAR;
  @Input() size: ButtonSize = ButtonSize.MEDIUM;
  @Input() icon: Icons;

  @Output() onClick = new EventEmitter<void>();

  iconColor: Colors;
  iconSize: number;

  ngOnChanges(): void {
    if (this.icon) {
      this.iconColor = this.disabled ? 'color-grey-medium' : iconColors[this.variant];
      this.iconSize = iconSizes[this.format][this.size];
    }
  }

  handleOnClick(): void {
    this.onClick.emit();
  }
}
