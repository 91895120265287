import { Component, Input, TemplateRef } from '@angular/core';

/**
 * Template for graph + table + control buttons
 * usually used in analysis screens
 */
@Component({
  selector: 'corp-chart-table-template',
  templateUrl: './corp-chart-table-template.component.html',
})
export class CorpChartTableTemplateComponent {
  @Input()
  topContent: TemplateRef<HTMLElement>;

  @Input()
  leftContent: TemplateRef<HTMLElement>;

  @Input()
  rightContent: TemplateRef<HTMLElement>;

  @Input()
  contentMinHeight = '400px';
}
