<section class="section is-full">
  <div class="row mb-5">
    <div class="col">
      <corp-record-table
        #table
        [title]="translationContext + '.title' | translate"
        (addClick)="onAddChecklist()"
        [tableColumns]="tableColumns"
        [defaultOrdering]="defaultOrdering"
        [data]="checklists"
        [totalRecords]="totalRecords"
        [loading]="loading"
        [error]="error"
        [actions]="availableActions"
        (actionClick)="onItemAction($event)"
        (pageRequest)="onPageRequest($event)"
        [shouldBlockAction]="shouldBlockAction"
        [blockedIconTooltipKey]="translationContext + '.table.blocked_tooltip'"
        [toggleIconTooltipKey]="translationContext + '.toggle_tooltip'"
        [hasEditActionOnToggle]="true"
      >
      </corp-record-table>
    </div>
  </div>

  <checklist-editor
    #editor
    *ngIf="editorModalVisible"
    [visible]="editorModalVisible"
    [checklistData]="selectedChecklist"
    [modalStatus]="editorModalStatus"
    (closed)="onEditorClosed()"
    (createChecklist)="createChecklist()"
    (updateChecklist)="updateChecklist()"
  ></checklist-editor>

  <corp-modal-deletion
    [visible]="deletionModalVisible"
    [modalStatus]="deletionModalStatus"
    title="{{ translationContext + '.deletion' | translate }}"
    (delete)="onDeletionModalDelete()"
    (cancel)="onDeletionModalClose()"
  >
    <deletion-table [data]="deletionTableData"></deletion-table>
  </corp-modal-deletion>
</section>
