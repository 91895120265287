export enum CurrencyEnum {
  THB = '฿',
  BOB = 'Bs.',
  CRC = '₡',
  NIO = 'C$',
  JOD = 'د.ا',
  USD = '$',
  VND = '₫',
  EUR = '€',
  PYG = '₲',
  CNY = '元',
  AOA = 'Kz',
  HNL = 'L',
  PEN = 'S/.',
  P = '$',
  CLP = '$',
  COP = '$',
  XCD = '$',
  PHP = '₱',
  MXN = '$',
  UYU = '$',
  GTQ = 'Q',
  BRL = 'R$',
  KPW = '₩',
  PLN = 'zł',
}
