import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { AgFeedbackComponent } from './ag-feedback.component';
import { AgFeedbackIconComponent } from './feedback-icon/ag-feedback-icon.component';
import { FeedbackLeavePageComponent } from './feedback-leave-page/feedback-leave-page.component';

@NgModule({
  imports: [SharedModule],
  declarations: [AgFeedbackComponent, AgFeedbackIconComponent, FeedbackLeavePageComponent],
  exports: [AgFeedbackComponent, AgFeedbackIconComponent, FeedbackLeavePageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgFeedbackModule {}
