import { AgrinessTranslateService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { snakeCase } from 'lodash';

import { ModalStatusEnum } from '../ag-modal.model';
import { ModalFeedbackContent } from './ag-modal-feedback.model';

type Modal = {
  [k in ModalStatusEnum]?: ModalFeedbackContent;
};

@Injectable({
  providedIn: 'root',
})
export class AgModalFeedbackService {
  private modal: Modal = {
    ...this.createFeedback(ModalStatusEnum.CONFIRMED, {
      icon: 'icon-feedback-attention.svg',
    }),
    ...this.createFeedback(ModalStatusEnum.FAILED, {
      icon: 'icon-feedback-error.svg',
    }),
    ...this.createFeedback(ModalStatusEnum.LOADING, {
      icon: '',
      action: false,
    }),
    ...this.createFeedback(ModalStatusEnum.PUBLISHING, {
      icon: 'icon-feedback-attention.svg',
    }),
    ...this.createFeedback(ModalStatusEnum.SUCCESS, {
      icon: 'icon-feedback-check.svg',
    }),
  };

  constructor(private translate: AgrinessTranslateService) {}

  getModal(modalStatusEnum: ModalStatusEnum): ModalFeedbackContent | undefined {
    return modalStatusEnum in this.modal ? this.modal[modalStatusEnum] : undefined;
  }

  private createFeedback(
    key: ModalStatusEnum,
    options: Partial<ModalFeedbackContent> & Pick<ModalFeedbackContent, 'icon'>,
  ): { [k in ModalStatusEnum]?: ModalFeedbackContent } {
    const keySnakeCase = snakeCase(key);
    return {
      [key]: {
        id: `icon-${keySnakeCase}`,
        iconClass: '',
        action: true,
        ...options,
      },
    };
  }
}
