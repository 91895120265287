import { StageEnum, TypeProductionEnum } from '@agriness/services';
import { Observable } from 'rxjs';

import {
  AnimalGroupListDownloadArgs,
  QuadrantAnimalGroup,
  SearchAnimalGroupList,
  SearchFilterAnimalGroupList,
  SearchFilterQuadrantAnimalGroup,
} from '../models/animal-group-list.model';
import { AnimalGroupNutritionFeed } from '../models/animal-group-nutrition.models';
import { AnimalGroupResume } from '../models/animal-group-performance.model';
import {
  AnimalGroupMortality,
  AnimalGroupMortalityPoultry,
  AnimalGroupMortalitySwines,
  ConsumptionList,
} from '../models/animal-group-sanity.model';
import {
  AnimalGroupDailyData,
  AnimalGroupForm,
  AnimalGroupRecord,
  SearchAnimalGroupFarm,
} from '../models/animal-group.model';
import { DownloadStatus } from '../models/download-status.model';
import { MovementGraph } from '../models/movement-graph.model';
import { MovementTable } from '../models/movement-table.model';
import { PerformanceItems } from '../models/performance.model';
import { WaterFlowTableItem } from '../models/water-flow-table.model';
import { WeightTableItem } from '../models/weight-table.model';

export abstract class AnimalGroupAbstractService {
  abstract searchAnimalFarmGroup(
    holdingId: string,
    term: string,
    pageSize: number,
  ): Observable<SearchAnimalGroupFarm>;

  abstract getAnimalGroup(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<AnimalGroupRecord>;

  abstract getAnimalGroupPerformance(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<AnimalGroupResume>;

  abstract getAnimalGroupPerformances(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<AnimalGroupResume[]>;

  abstract getAnimalGroupLayersPerformanceGraph(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
    fixedIndex: string,
  ): Observable<PerformanceItems>;

  abstract getAnimalGroupFinancial(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<AnimalGroupResume>;

  abstract getAnimalGroupWeight(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<WeightTableItem[]>;

  abstract getWaterFlow(
    holdingId: string,
    idFarm: string,
    idAnimalGroup: string,
  ): Observable<WaterFlowTableItem[]>;

  abstract getAnimalGroupMovementGraph(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<MovementGraph>;

  abstract getAnimalGroupMovementTable(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<MovementTable>;

  abstract getAnimalGroupMovementTableAccommodation(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<MovementTable>;

  abstract getAnimalGroupMovementTableMortality(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<MovementTable>;

  abstract getAnimalGroupMovementPerformance(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<AnimalGroupResume>;

  abstract getAnimalGroupNutritionPerformance(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<AnimalGroupResume>;
  abstract getAnimalGroupNutritionFeed(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
    aggregateBy?: string,
  ): Observable<AnimalGroupNutritionFeed>;
  abstract getAnimalGroupNutritionWeight(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<AnimalGroupDailyData>;

  abstract getAnimalGroupSanityMortality(
    typeProduction: TypeProductionEnum,
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<AnimalGroupMortality>;

  abstract getAnimalGroupSanityMortality(
    typeProduction: TypeProductionEnum.POULTRY,
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<AnimalGroupMortalityPoultry>;

  abstract getAnimalGroupSanityMortality(
    typeProduction: TypeProductionEnum.SWINES,
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string | number,
  ): Observable<AnimalGroupMortalitySwines>;

  abstract getAnimalGroupConsumption(
    typeProduction: TypeProductionEnum,
    holdingId: string,
    idFarm: string,
    idAnimalGroup: string,
  ): Observable<ConsumptionList>;

  abstract getAnimalGroupList(
    holdingId: string,
    searchFilter: SearchFilterAnimalGroupList,
    stage: StageEnum,
  ): Observable<SearchAnimalGroupList>;

  abstract getQuadrantAnimalGroupList(
    holdingId: string,
    stage: string,
    searchFilter: SearchFilterQuadrantAnimalGroup,
  ): Observable<QuadrantAnimalGroup>;

  abstract downloadList(params: AnimalGroupListDownloadArgs): Observable<DownloadStatus>;

  abstract getAnimalGroupRecordDocumentDowloadFile(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<DownloadStatus>;

  abstract update(
    holdingId: string,
    farmId: string,
    animalGroupForm: AnimalGroupForm,
    animalGroupId: string,
  ): Observable<AnimalGroupRecord>;
}
