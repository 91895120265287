import {
  IndexMapGeneratorAbstractService,
  ReportDashboardAbstractService,
  ReportEnum,
} from '@agriness/corp-app/services';
import { DocumentType } from '@agriness/corp-app/services/models/document-type.model';
import { DownloadStatus } from '@agriness/corp-app/services/models/download-status.model';
import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { StageEnum, UserStorageService, TypeProductionService } from '@agriness/services';
import { QueueItem, QueueManagerService, DualListBoxItem } from '@agriness/ui';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Observable, throwError } from 'rxjs';
import { map, tap, switchMap, catchError, finalize } from 'rxjs/operators';

import { ReportFilterQuery, ReportFilterType } from '../../model/report-filter.model';
import { IndexTranslateService } from '../../services/index-translate.service';
import { CorpReportFiltersComponent } from '../corp-report-filters/corp-report-filters.component';
import { LoaderUserPreference } from '../loader-user-preference';
import {
  filterIdsByStage,
  GroupByOption,
  groupByOptions,
  GroupByWithSubOptions,
} from './corp-index-map-generator.model';

type StepId = 'filters' | 'indexes' | 'groupBy' | 'download';

interface Step {
  done: boolean;
  enabled: boolean;
}

type Steps = { [k in StepId]: Step };

interface StepStatusProvider {
  done: (formData: FormData, steps: Steps) => boolean;
  enabled: (formData: FormData, steps: Steps) => boolean;
}

interface FormData {
  filter: ReportFilterQuery;
  indexes: DualListBoxItem[];
  groupBy: GroupByOption | GroupByWithSubOptions;
  groupByBegin: GroupByOption;
}

@Component({
  selector: 'corp-index-map-generator',
  styleUrls: ['./corp-index-map-generator.component.scss'],
  templateUrl: './corp-index-map-generator.component.html',
})
export class CorpIndexMapGeneratorComponent extends LoaderUserPreference implements AfterViewInit {
  @ViewChild('reportFilter', { static: true }) reportFilter: CorpReportFiltersComponent;

  stage: StageEnum;
  DocumentType = DocumentType;
  filterIds: ReportFilterType[];
  groupByOptions: (GroupByOption | GroupByWithSubOptions)[];

  filterValid = true;
  stepsStatusProvider: { [k in StepId]: StepStatusProvider } = {
    filters: {
      enabled: (): boolean => true,
      done: (): boolean => this.filterValid,
    },
    indexes: {
      enabled: (): boolean => true,
      done: ({ indexes }: FormData): boolean => !_.isEmpty(indexes),
    },
    groupBy: {
      enabled: (): boolean => true,
      done: ({ groupBy }: FormData): boolean => {
        return groupBy != null;
      },
    },
    download: {
      enabled: (formData: FormData, steps: Steps): boolean =>
        steps.filters.done &&
        steps.indexes.done &&
        steps.groupBy.done &&
        !this.downloadsInProgress.some(i => _.isEqual(i, formData)),
      done: (formData: FormData, steps: Steps): boolean => steps.download.enabled,
    },
  };

  steps: Steps = this.getBlankSteps();

  form: FormGroup;
  optionsIndex: DualListBoxItem[];
  downloadsInProgress: FormData[] = [];

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    protected userStorageService: UserStorageService,
    private reportService: ReportDashboardAbstractService,
    private indexMapGeneratorService: IndexMapGeneratorAbstractService,
    private indexTranslate: IndexTranslateService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private queueManager: QueueManagerService,
    private changeDetectionRef: ChangeDetectorRef,
    private typeProductionService: TypeProductionService,
  ) {
    super(userStorageService);
    this.form = this.formBuilder.group({
      indexes: [],
      groupBy: [],
      groupByBegin: [],
    });

    this.stage = this.route.snapshot.data.stage as StageEnum;
    this.groupByOptions = groupByOptions[this.typeProductionService.get()].filter(option => {
      if (this.stage === 'nursery' || this.stage === 'finishing') {
        return option.value != 'TECHNICIAN_ID' && option.value != 'FARM_ID';
      } else {
        return option.value != 'TECHNICIAN_ID' && option.value !== 'ANIMAL_GROUP_ID';
      }
    });
    this.filterIds = filterIdsByStage[this.stage];
  }

  ngAfterViewInit(): void {
    this.loadData();
    this.changeDetectionRef.detectChanges();
    this.form.valueChanges.subscribe(() => this.updateStepStatus());
  }

  onFiltersReady(): void {
    this.updateStepStatus();
  }

  onfilterValid(valid: boolean): void {
    this.filterValid = valid;
    this.updateStepStatus();
  }

  download(documentType: DocumentType): void {
    const formData = this.getFormData();
    const { filter, indexes } = formData;
    const indexKey = indexes.map(({ value }) => value).join(',');
    const groupBy = _.get(formData.groupBy, 'value');
    this.addDownloadToQueue(
      formData,
      this.indexMapGeneratorService.download({
        ...filter,
        holding_id: this.holdingId,
        system_type: this.typeProductionService.get(),
        stage: this.stage,
        index_key: indexKey,
        group_by: groupBy,
        data_format: documentType,
      }),
    );
  }

  getFormData(): FormData {
    return {
      filter: this.reportFilter.getCurrentFilterInQueryFormat(),
      ...(this.form.getRawValue() as Omit<FormData, 'filter'>),
    };
  }

  private loadData(): void {
    this.loadIndexes().subscribe();
  }

  private loadIndexes() {
    return this.reportService.getReport(ReportEnum.INDEX_MAP_GENERATOR, this.stage).pipe(
      map(report =>
        report.indexes
          .map(item => ({
            value: item.key,
            label: this.getIndexName(item.key),
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      ),
      tap(optionsIndex => (this.optionsIndex = optionsIndex)),
    );
  }

  private getIndexName(indexKey: string): string {
    return this.indexTranslate.instant(
      indexKey,
      'agriness.index_map_generator.performances.',
      this.stage,
    );
  }

  private addDownloadToQueue(
    formData: FormData,
    downloadObservable: Observable<DownloadStatus>,
  ): void {
    const t = (key: string): string => this.t(`agriness.download_feedback.${key}`);

    this.addDownloadInProgress(formData);
    this.queueManager
      .add(downloadObservable, t('waiting'))
      .pipe(
        tap(({ actions }: QueueItem) => actions.update(t('in_progress'))),
        switchMap(({ payload: download, actions }: QueueItem) => {
          return (download as Observable<DownloadStatus>).pipe(
            tap((status: DownloadStatus) => {
              if (status === DownloadStatus.NO_CONTENT) {
                actions.error(t('no_content'));
              } else {
                actions.success(t('success'));
              }
            }),
            catchError(err => {
              actions.error(t('error'));
              return throwError(err);
            }),
            finalize(() => this.removeDownloadInProgress(formData)),
          );
        }),
      )
      .subscribe();
  }

  private addDownloadInProgress(formData) {
    this.downloadsInProgress = [...this.downloadsInProgress, formData] as FormData[];
    this.updateStepStatus();
  }

  private removeDownloadInProgress(formData) {
    this.downloadsInProgress = this.downloadsInProgress.filter(i => i !== formData);
    this.updateStepStatus();
  }

  private getBlankSteps(): Steps {
    return {
      filters: {
        enabled: false,
        done: false,
      },
      indexes: {
        enabled: false,
        done: false,
      },
      groupBy: {
        enabled: false,
        done: false,
      },
      download: {
        enabled: false,
        done: false,
      },
    };
  }

  private updateStepStatus() {
    const formData = this.getFormData();
    const newValue = this.getBlankSteps();
    for (const stepId of Object.keys(this.stepsStatusProvider) as StepId[]) {
      const provider = this.stepsStatusProvider[stepId];
      const step = newValue[stepId];
      step.enabled = provider.enabled(formData, newValue);
      step.done = provider.done(formData, newValue);
    }
    if (!_.isEqual(this.steps, newValue)) {
      this.steps = newValue;
    }
  }
}
