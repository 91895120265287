export const colors = {
  'color-1-dark': '#8c51b7',
  'color-1-light': '#b58ed0',
  'color-2-dark': '#34bca9',
  'color-2-light': '#96e5d4',
  'color-3-dark': '#ff6183',
  'color-3-light': '#ffadbc',
  'color-4-dark': '#f6a11e',
  'color-4-light': '#fed77b',
  'color-5-dark': '#3c76ec',
  'color-5-light': '#9ec8f3',
  'color-6-dark': '#ab47bc',
  'color-6-light': '#ce93d8',
  'color-7-dark': '#956f66',
  'color-7-light': '#cdbbb7',
  'color-8-dark': '#5e7d8c',
  'color-8-light': '#b2c0c9',
  'color-alert': '#ff8712',
  'color-attention': '#ffc342',
  'color-attention-light': '#fff6e3',
  'color-aux': '#d4eefe',
  'color-danger': '#ff2d2d',
  'color-danger-light': '#fff4f4',
  'color-grad-aqua': '#1463ad',
  'color-grad-blue': '#063097',
  'color-grad-brown': '#581713',
  'color-grad-green': '#036483',
  'color-grad-orange': '#f94949',
  'color-grad-purple': '#392ebd',
  'color-grad-red': '#af0637',
  'color-grey-dark': '#212325',
  'color-grey-light': '#d1d7db',
  'color-grey-medium': '#71767a',
  'color-inf-light': '#e9effb',
  'color-light': '#f2f2f2',
  'color-primary': '#205fd4',
  'color-secondary': '#063097',
  'color-success': '#3ea900',
  'color-success-light': '#ecf6e5',
  'color-white': '#ffffff',
  'color-white-gloss': '#ffffff',
} as const;

export type Colors = keyof typeof colors;

export const colorOptions = Object.keys(colors);
