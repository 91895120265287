<div class="modal-dialog">
  <p-dialog
    [visible]="visible"
    [resizable]="false"
    [draggable]="false"
    [modal]="true"
    [blockScroll]="true"
    [showHeader]="showHeader"
    [closable]="closable"
    closeIcon="icon-close"
    (visibleChange)="onVisibleChange($event)"
  >
    <p-header>
      <agriness-icon [icon]="icon" size="24" color="color-grey-medium"></agriness-icon>
      {{ title }}
    </p-header>

    <p-footer *ngIf="footerQueryList?.first">
      <ng-content select="ag-modal-footer"></ng-content>
    </p-footer>

    <ng-content></ng-content>

    <button
      (click)="onVisibleChange(false)"
      *ngIf="!showHeader && closable"
      data-testid="close"
      class="icon-close"
    ></button>
  </p-dialog>
</div>
