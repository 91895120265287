import { Tank, TankForm } from '@agriness/corp-app/services';
import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'tank-editor',
  templateUrl: './tank-editor.component.html',
  styleUrls: ['./tank-editor.component.scss'],
})
export class TankEditorComponent implements OnInit {
  @Input() visible = false;
  @Input() tankData: Tank;
  @Input() modalStatus = ModalStatusEnum.DEFAULT;

  @Output() closed = new EventEmitter<void>();
  @Output() createTank = new EventEmitter<void>();
  @Output() updateTank = new EventEmitter<void>();

  errorMessages = {
    name: '',
  };

  translationContext = 'agriness.settings.tanks';

  tankForm = new FormGroup({
    name: new FormControl('', Validators.required.bind(this)),
    farm_id: new FormControl(null, Validators.required.bind(this)),
  });

  constructor(@Inject(TRANSLATE_INSTANT) private t: TranslateInstant) {}

  ngOnInit(): void {
    this.updatedFormTank();
    this.listenValueChanges();
  }

  listenValueChanges(): void {
    for (const control in this.tankForm.controls) {
      this.tankForm.controls[control].valueChanges.subscribe(() => {
        const { errors } = this.tankForm.get(control);
        this.errorMessages[control] = errors ? this.t('agriness.settings.validation_required') : '';
      });
    }
  }

  onSubmit(): void {
    if (!this.tankData) {
      this.createTank.emit();
    } else {
      this.updateTank.emit();
    }
  }

  onClosed(): void {
    this.closed.emit();
  }

  updatedFormTank(): void {
    if (this.tankData) {
      const { name, farm } = this.tankData;
      this.tankForm.patchValue({ name, farm_id: farm.id });
    }
  }

  getTankForm(): TankForm {
    return this.tankForm.getRawValue() as TankForm;
  }
}
