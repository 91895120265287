<ul class="nav nav-tabs mb-4" id="nav-tab">
  <li class="nav-item" *ngFor="let tab of data" [hidden]="!tab.visible">
    <a
      [hidden]="!tab.render_router"
      class="nav-link"
      routerLinkActive="active"
      id="tab-desempenho-router"
      routerLink="data.router"
      [ngClass]="{ active: tab.active }"
    >
      <span *ngIf="tab.icon" class="icon icon-18 has-text-blue-5" [ngClass]="tab.icon"></span>
      {{ 'agriness.' + tab.name | translate }}
    </a>

    <a
      [hidden]="tab.render_router"
      class="nav-link"
      id="tab-desempenho"
      (click)="onChange(tab)"
      [ngClass]="{ active: tab.active }"
    >
      <span *ngIf="tab.icon" class="icon icon-18 has-text-blue-5" [ngClass]="tab.icon"></span>
      {{ 'agriness.' + tab.name | translate }}
    </a>
  </li>
</ul>

<div class="tab-content" id="nav-content">
  <ng-content></ng-content>
</div>
