<section class="section is-full">
  <div class="row mb-3">
    <div class="col">
      <div class="page-title d-flex align-items-center">
        <corp-back-button></corp-back-button>
        {{ 'agriness.report.map_indicators' | translate }}
      </div>
    </div>
  </div>
  <!-- /.row -->

  <corp-report-filters
    #reportFilter
    [stage]="stage"
    [availableFilters]="filterIds"
    [periodDefaultValue]="periodDefaultValue"
    (filter)="filter()"
    (ready)="filter()"
    class="mb-3"
  >
  </corp-report-filters>

  <ag-feedback [type]="typeFeedback"> </ag-feedback>

  <ng-container *ngIf="!typeFeedback">
    <div class="card shadow-sm">
      <div class="card-body">
        <div class="card-title d-flex align-items-center">
          {{ 'agriness.map_indicators.title' | translate }}
        </div>

        <p-table *ngIf="rowGroupMetadata" [value]="objectKeys(rowGroupMetadata)">
          <ng-template pTemplate="header">
            <tr>
              <th colspan="7"></th>
              <th class="text-right">{{ 'agriness.target' | translate }}</th>
              <th class="text-right">{{ 'agriness.alert' | translate }}</th>
              <th class="text-right">{{ 'agriness.result' | translate }}</th>
              <th class="text-right">{{ 'agriness.period.month_last_three' | translate }}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
            <tr class="ui-widget-header">
              <td colspan="7" class="text-uppercase">
                {{ 'agriness.indicators.' + rowData | translate }}
              </td>
              <td class="text-right">{{ 'agriness.target' | translate }}</td>
              <td></td>
              <td class="text-right">{{ 'agriness.total_average' | translate }}</td>
              <td class="text-right">{{ 'agriness.average' | translate }}</td>
            </tr>
            <tr *ngFor="let row of rowGroupMetadata[rowData]">
              <td colspan="7">{{ 'agriness.indicators.' + row.index_name | translate }}</td>
              <td class="text-right">
                <ag-format
                  [value]="row?.target_definition?.target_value"
                  [measurementUnit]="row.measurement_unit"
                  [decimalPlaces]="row.decimal_places"
                ></ag-format>
              </td>
              <td class="text-right">
                <ag-target-indicator
                  [status]="row.target_definition?.status"
                  [tooltip]="tipContent"
                ></ag-target-indicator>
              </td>
              <td class="text-right">
                <ag-format
                  [value]="row.value"
                  [measurementUnit]="row.measurement_unit"
                  [decimalPlaces]="row.decimal_places"
                ></ag-format>
              </td>
              <td class="text-right">
                <ag-format
                  [value]="row.average"
                  [measurementUnit]="row.measurement_unit"
                  [decimalPlaces]="row.decimal_places"
                ></ag-format>
              </td>
              <ng-template #tipContent>
                <ag-card-tooltip
                  [decimalPlaces]="row.decimal_places"
                  [measurementUnit]="row.measurement_unit"
                  [minRecommended]="row.target_definition.min_recommended"
                  [maxRecommended]="row.target_definition.max_recommended"
                ></ag-card-tooltip>
              </ng-template>
            </tr>
            <tr class="spacer">
              <td colspan="11"></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </ng-container>
</section>
