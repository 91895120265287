import {
  AnimalGroup,
  AnimalGroupMortalityPeriodPoultry,
  AnimalGroupMortalityPoultry,
  AnimalGroupMortalityValueAccumulated,
  MortalityByPeriodPoultry,
} from '@agriness/corp-app/services';
import { UserStorageService } from '@agriness/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { TableColumn } from '@agriness/ui';
import { Component, Inject, Input, OnChanges, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import { mapValues } from 'lodash';
import { Table } from 'primeng/table';

import { TableService } from '../../../../shared/services/table.service';
import { AnimalGroupService } from '../../animal-group.service';
import {
  AnimalGroupMortalityValueAccumulatedTable,
  displayTypeIcon,
  IndexDisplayType,
  PREFIX_ELIMINATED,
  PREFIX_MORTALITY,
  SanityDataByType,
  TableData,
} from '../sanity.model';

@Component({
  selector: 'corp-sanity-poultry',
  templateUrl: './sanity-poultry.component.html',
})
export class SanityPoultryComponent implements OnChanges {
  @Input() animalGroup: AnimalGroup;
  @Input() mortality: AnimalGroupMortalityPoultry;
  @ViewChild('dtEliminated') pTableEliminated: Table;
  @ViewChild('dtTotalMortality') pTableTotalMortality: Table;

  typeProduction = environment.typeProduction;
  IndexDisplayType = IndexDisplayType;
  chartHeight = '400px';

  sanityDataByIndex: SanityDataByType;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    protected userStorageService: UserStorageService,
    private animalGroupService: AnimalGroupService,
    public tableService: TableService,
  ) {}

  ngOnChanges(): void {
    if (this.animalGroup && this.mortality) {
      this.updateSanityDataByIndex();
    }
  }

  onNextDisplayTypeClick(type: keyof SanityDataByType): void {
    const data = this.sanityDataByIndex[type];
    data.displayType = this.getNextDisplayType(data.displayType);
  }

  nextDisplayTypeIcon(type: keyof SanityDataByType): string {
    const data = this.sanityDataByIndex[type];
    return displayTypeIcon[this.getNextDisplayType(data.displayType)];
  }

  buildColumns(data: AnimalGroupMortalityValueAccumulatedTable[], prefix: string): TableColumn[] {
    const keys = data.length ? Object.keys(data[0]).filter(k => k !== 'percentage') : [];
    return keys.map(key => ({
      field: key,
      header: this.t(prefix + key),
    }));
  }

  hasData(): boolean {
    return this.mortality != null;
  }

  private updateSanityDataByIndex() {
    const totalMortalityByDayChartData = this.getChartData(
      this.mortality.daily,
      v => v.by_reason.death,
    );
    const totalMortalityByDayTableData = this.getTableData(
      this.mortality.daily,
      v => v.by_reason.death,
      PREFIX_MORTALITY,
    );
    const eliminatedByDayChartData = this.getChartData(
      this.mortality.daily,
      v => v.by_reason.eliminated,
    );
    const eliminatedByDayTable = this.getTableData(
      this.mortality.daily,
      v => v.by_reason.eliminated,
      PREFIX_ELIMINATED,
    );
    this.sanityDataByIndex = {
      totalMortality: {
        displayType: IndexDisplayType.CHART,
        downloadFilename: this.getDownloadFilename('agriness.sanity.total_mortality'),
        byDayChartData: totalMortalityByDayChartData,
        byDayTableData: totalMortalityByDayTableData,
      },
      eliminated: {
        displayType: IndexDisplayType.CHART,
        downloadFilename: this.getDownloadFilename('agriness.sanity.eliminated'),
        byDayChartData: eliminatedByDayChartData,
        byDayTableData: eliminatedByDayTable,
      },
    };
  }

  private getDownloadFilename(section: string) {
    return this.animalGroupService.getDownloadFilename(this.animalGroup, section);
  }

  private getNextDisplayType(displayType: IndexDisplayType) {
    return displayType === IndexDisplayType.CHART ? IndexDisplayType.TABLE : IndexDisplayType.CHART;
  }

  private getChartData(
    data: MortalityByPeriodPoultry,
    provider: (item: AnimalGroupMortalityPeriodPoultry) => AnimalGroupMortalityValueAccumulated,
  ): AnimalGroupMortalityValueAccumulated[] {
    const periods = Object.values(data).map(v => v.period);

    if (!periods.length) {
      return [];
    }

    const maxPeriod = Math.max(...periods);

    const extractedData = mapValues(data, v => provider(v));

    const completeData = new Array<AnimalGroupMortalityValueAccumulated>(maxPeriod + 1);
    for (let i = 0; i < completeData.length; i++) {
      const existing = extractedData[i];

      const newItem = existing
        ? { ...existing }
        : {
            value: null,
            value_accumulated: null,
            percentage: null,
            percentage_accumulated: null,
          };

      const previous: Partial<AnimalGroupMortalityValueAccumulated> = completeData[i - 1] || {};
      newItem.value_accumulated = newItem.value_accumulated || previous.value_accumulated || 0;
      newItem.percentage_accumulated =
        newItem.percentage_accumulated || previous.percentage_accumulated || 0;
      completeData[i] = newItem;
    }

    return completeData;
  }

  private getTableData(
    data: MortalityByPeriodPoultry,
    provider: (item: AnimalGroupMortalityPeriodPoultry) => AnimalGroupMortalityValueAccumulated,
    prefix: typeof PREFIX_MORTALITY | typeof PREFIX_ELIMINATED,
  ): TableData {
    const tableData: AnimalGroupMortalityValueAccumulatedTable[] = Object.values(data).map(v => ({
      period: v.period,
      ...provider(v),
    }));
    const columns = this.buildColumns(tableData, prefix);
    return { data: tableData, columns };
  }
}
