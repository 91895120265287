import { StageEnum, TypeProductionEnum } from '@agriness/services';

type IndexByTypeProduction = Record<TypeProductionEnum, string[]>;

export const columnsWithTooltipByProduction: IndexByTypeProduction = {
  [TypeProductionEnum.SWINES]: ['adjusted_feed_conversion_ratio', 'average_date_output'],
  [TypeProductionEnum.POULTRY]: [
    'target_date_output_predicted',
    'predicted_average_weight',
    'predicted_age_output',
  ],
  [TypeProductionEnum.DAIRY]: [],
  [TypeProductionEnum.LAYERS]: [],
};

export interface FrozenColumn {
  stage: StageEnum;
  name: string;
  hasCheckbox?: boolean;
}

export const frozenColumnByProduction: Record<TypeProductionEnum, FrozenColumn[]> = {
  [TypeProductionEnum.SWINES]: [
    { stage: StageEnum.REPRODUCTIVE, name: 'farm_name' },
    { stage: StageEnum.NURSERY, name: 'animal_group_name' },
    { stage: StageEnum.FINISHING, name: 'animal_group_name' },
    { stage: StageEnum.WEAN_TO_FINISH, name: 'animal_group_name' },
  ],
  [TypeProductionEnum.POULTRY]: [{ stage: StageEnum.GROW_OUT, name: 'animal_group_name' }],
  [TypeProductionEnum.DAIRY]: [{ stage: StageEnum.MILKING, name: 'producer_name' }],
  [TypeProductionEnum.LAYERS]: [
    { hasCheckbox: true, stage: StageEnum.LAYERS_PRODUCTION, name: 'animal_group_name' },
    { stage: StageEnum.LAYERS_PRODUCTION, name: 'site_name' },
    { stage: StageEnum.LAYERS_PRODUCTION, name: 'house_name' },
  ],
};

export const columnWidth: Record<StageEnum, Record<string, string>> = {
  [StageEnum.REPRODUCTIVE]: {},
  [StageEnum.NURSERY]: {
    animal_group_name: '240px',
    producer_name: '150px',
    adjusted_feed_conversion_ratio: '100px',
    daily_weight_gain: '100px',
  },
  [StageEnum.FINISHING]: {
    animal_group_name: '240px',
    producer_name: '150px',
    adjusted_feed_conversion_ratio: '100px',
    daily_weight_gain: '100px',
  },
  [StageEnum.WEAN_TO_FINISH]: {
    animal_group_name: '240px',
    producer_name: '150px',
    adjusted_feed_conversion_ratio: '100px',
    daily_weight_gain: '100px',
  },
  [StageEnum.GROW_OUT]: {
    animal_group_name: '240px',
  },
  [StageEnum.MILKING]: {
    producer_name: '240px',
  },
  [StageEnum.LAYERS_PRODUCTION]: {},
};

export const hiddenColumnsClosedByProduction: IndexByTypeProduction = {
  [TypeProductionEnum.SWINES]: ['prediction_date_output', 'current_days_in_stage', 'balance'],
  [TypeProductionEnum.POULTRY]: [
    'mortality_rate_total',
    'predicted_average_weight',
    'predicted_age_output',
    'target_date_output_predicted',
  ],
  [TypeProductionEnum.DAIRY]: [],
  [TypeProductionEnum.LAYERS]: [
    'two_weeks_total_feed',
    'four_weeks_total_feed',
    'balance',
    'average_body_weight',
    'body_weight_uniformity',
  ],
};

export const hiddenColumnsOpenByProduction: IndexByTypeProduction = {
  [TypeProductionEnum.SWINES]: [
    'average_date_output',
    'average_weight_output',
    'days_in_stage',
    'contribution_value',
    'total_animal_number_output',
    'producer_payment',
    'adjusted_feed_conversion_ratio',
    'carcass_average_weight',
    'carcass_daily_weight_gain',
    'percentage_ideal_weight',
    'daily_weight_gain',
    'feed_conversion_ratio',
  ],
  [TypeProductionEnum.POULTRY]: [
    'transport_deaths',
    'average_age_output',
    'mortality_rate_by_harvested',
    'total_animal_number_output',
    'producer_payment',
    'total_cost_per_weight',
    'final_date_output',
    'europe_efficience_index',
    'average_weight_output',
    'daily_weight_gain',
    'adjusted_feed_conversion_ratio',
  ],
  [TypeProductionEnum.DAIRY]: [],
  [TypeProductionEnum.LAYERS]: [
    'closed_date',
    'eggs_per_hen_housed_until_60',
    'eggs_per_hen_housed_until_100',
    'eggs_per_hen_housed_until_110',
  ],
};

export enum IsOpenEnum {
  ALL = '',
  CLOSED = 'false',
  OPEN = 'true',
}

type isOpen = { [k in IsOpenEnum]?: string };
type performanceIndicator = { [k in string]?: string };

type reportByStage = { [k in StageEnum]?: isOpen | performanceIndicator };

export const orderIndicator: Record<TypeProductionEnum, reportByStage> = {
  [TypeProductionEnum.DAIRY]: {},
  [TypeProductionEnum.POULTRY]: {},
  [TypeProductionEnum.SWINES]: {
    [StageEnum.NURSERY]: {
      days_in_stage: 'days_in_stage,animal_group_name',
      count_animal_group: 'animal_group_name',
      total_animal_number_output: 'average_date_output,animal_group_name',
    },
  },
  [TypeProductionEnum.LAYERS]: {},
};

export const orderDefault: Record<TypeProductionEnum, reportByStage> = {
  [TypeProductionEnum.DAIRY]: {
    [StageEnum.MILKING]: {
      [IsOpenEnum.ALL]: 'producer_name',
    },
  },
  [TypeProductionEnum.POULTRY]: {
    [StageEnum.GROW_OUT]: {
      [IsOpenEnum.OPEN]: 'target_date_output_predicted,animal_group_name',
      [IsOpenEnum.CLOSED]: '-final_date_output,animal_group_name',
    },
  },
  [TypeProductionEnum.SWINES]: {
    [StageEnum.REPRODUCTIVE]: {
      [IsOpenEnum.ALL]: 'farm_name',
    },
    [StageEnum.NURSERY]: {
      [IsOpenEnum.OPEN]: 'average_date_output,animal_group_name',
      [IsOpenEnum.CLOSED]: '-average_date_output,animal_group_name',
    },
    [StageEnum.FINISHING]: {
      [IsOpenEnum.OPEN]: 'average_date_output,animal_group_name',
      [IsOpenEnum.CLOSED]: '-average_date_output,animal_group_name',
    },
    [StageEnum.WEAN_TO_FINISH]: {
      [IsOpenEnum.OPEN]: 'average_date_output,animal_group_name',
      [IsOpenEnum.CLOSED]: '-average_date_output,animal_group_name',
    },
  },
  // TODO: Remove on product separation
  [TypeProductionEnum.LAYERS]: {
    [StageEnum.LAYERS_PRODUCTION]: {
      [IsOpenEnum.ALL]: 'animal_group_name',
      [IsOpenEnum.OPEN]: 'animal_group_name',
      [IsOpenEnum.CLOSED]: 'animal_group_name',
    },
  },
};
