<div class="indexes-table-scroll">
  <div class="indexes-table">
    <div class="columns is-gapless mb-3">
      <div class="column" *ngFor="let col of columns">
        <span class="title-table text-center">
          <p class="text is-small has-text-grey-4" data-testid="column-title">
            {{ col.name | translate }}
          </p>
        </span>
      </div>
    </div>

    <div *ngFor="let row of rowsWithCells" data-testid="row-group">
      <div class="header d-flex align-items-center justify-content-between mb-1">
        <h6 class="title font-weight-bold" data-testid="row-title">
          <ng-container *ngFor="let index of row.indexes; let last = last">
            {{ indexNameTranslatePrefix + index.index_name | translate }}{{ last ? '' : ' / ' }}
          </ng-container>
        </h6>
        <span class="header-details" *ngIf="row.hasAnyValue">
          <ng-container
            *ngTemplateOutlet="headerValueTemplate; context: { indexes: row.indexes }"
          ></ng-container>
        </span>
      </div>
      <div class="columns is-gapless mb-3">
        <div class="column" *ngFor="let cell of row.cells; let i = index">
          <ng-container *ngIf="cell.link; else cellTable">
            <a
              *ngIf="cell.values.length > 1"
              [routerLink]="cell.link.url"
              [queryParams]="cell.link.queryParams"
              target="_blank"
            >
              <span class="cell-table" [ngClass]="cellStatusClass[cell.status]">
                <ng-container
                  *ngTemplateOutlet="
                    cellTemplate;
                    context: { values: cell.values, indexes: row.indexes }
                  "
                ></ng-container>
                <span class="icon icon-open-new-tab"></span>
              </span>
            </a>
          </ng-container>
          <ng-template #cellTable>
            <span class="cell-table" [ngClass]="cellStatusClass[cell.status]">
              <ng-container
                *ngTemplateOutlet="
                  cellTemplate;
                  context: { values: cell.values, indexes: row.indexes }
                "
              ></ng-container>
            </span>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="footer d-flex align-items-center justify-content-end mb-3">
      <div *ngFor="let legend of legends" class="media align-items-center">
        <span class="cell-icon mr-1" [ngClass]="cellStatusClass[legend]"></span>
        <div class="media-body">
          <p class="text is-small" data-testid="cell-status">
            {{ 'agriness.' + legend | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
