<div class="ag-layout-menu-apps" *ngIf="apps">
  <div ngbDropdown>
    <button
      ngbDropdownToggle
      type="button"
      class="btn-icon dropdown-toggle--no-trigger"
      id="dropdown-menu-button-apps"
    >
      <span class="icon icon-apps"></span>
    </button>
    <div ngbDropdownMenu>
      <a
        class="dropdown-item"
        [ngClass]="{ active: app.appActive }"
        [attr.href]="app.link"
        target="_blank"
        *ngFor="let app of apps"
      >
        <span class="icon" [ngClass]="app.icon"></span>
        <div class="ml-2">
          <div [innerHTML]="app.name"></div>
          <div class="text-small" [innerHTML]="app.description"></div>
        </div>
      </a>
    </div>
  </div>
</div>
