<div
  class="form-group has-feedback"
  [ngClass]="{ 'has-error': errorMessage }"
  [formGroup]="group"
  [ngStyle]="{ minWidth: inputWidth, width: inputWidth }"
>
  <input
    class="form-control"
    type="number"
    [formControlName]="controlName"
    [name]="inputId"
    [attr.disabled]="disabled ? '' : null"
    pInputText
    [pattern]="hasDecimal ? '^\d*(\.\d{0,2})?$' : ''"
    [min]="minValue"
  />
  <label class="control-label">{{ label }}</label>
  <div class="input-feedback" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
</div>
