import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ag-number-field',
  templateUrl: './ag-number-field.component.html',
})
export class AgNumberFieldComponent {
  @Input() controlName = '';
  @Input() group: FormGroup;
  @Input() inputId = '';
  @Input() inputWidth = '65px';
  @Input() label = '';
  @Input() errorMessage?: string;
  @Input() disabled = false;
  @Input() hasDecimal = false;
  @Input() minValue: number;

  constructor(private cd: ChangeDetectorRef) {}

  // workaround for https://github.com/primefaces/primeng/issues/10716
  ngAfterViewInit() {
    this.cd.detectChanges();
  }
}
