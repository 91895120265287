import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AgrinessGuardModule } from './guard/agriness.guard.module';
import { LoadingInterceptor } from './interceptor/loading.interceptor';
import { AgrinessServicesConfig } from './models/services-config.model';
import { HoldingAbstractService } from './services/abstract/holding-abstract.service';
import { ProductAbstractService } from './services/abstract/product-abstract.service';
import { ApiMyAccountService } from './services/common/api-myaccount.service';
import { BaseApiAbstractService } from './services/common/base-api-abstract.service';
import { SERVICES_CONFIG } from './services/common/services-config.service';
import { TagManagerService } from './services/common/tag-manager.service';
import { FarmService } from './services/domain/farm/farm.service';
import { HoldingService } from './services/domain/holding/holding.service';
import { ProductService } from './services/domain/product/product.service';
import { DateService } from './utils/date.service';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
];

@NgModule({
  imports: [AgrinessGuardModule],
  providers: [
    httpInterceptorProviders,
    DateService,
    ApiMyAccountService,
    BaseApiAbstractService,
    TagManagerService,
    FarmService,
    { provide: HoldingAbstractService, useClass: HoldingService },
    { provide: ProductAbstractService, useClass: ProductService },
  ],
})
export class AgrinessServicesModule {
  static forRoot(config: AgrinessServicesConfig): ModuleWithProviders<AgrinessServicesModule> {
    return {
      ngModule: AgrinessServicesModule,
      providers: [
        {
          provide: SERVICES_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
