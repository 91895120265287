<div class="input-content has-feedback" *ngIf="filterForm" [ngStyle]="{ width: inputWidth }">
  <div
    [ngClass]="{ 'has-error': showPeriodLimitationFeedback }"
    class="form-group datepicker has-feedback"
    id="filter-{{ kebabCase(beginDate.controlName) }}-form-group"
  >
    <ag-calendar
      [group]="filterForm"
      [controlName]="beginDate.controlName"
      [validation]="invalidateByControlName(beginDate.controlName)"
      [label]="beginDate.labelKey"
      [dateFormat]="dateFormat"
      inputId="filter-{{ kebabCase(beginDate.controlName) }}"
      [errorMessage]="!showDateLimitationFeedback ? errorMessage : null"
    ></ag-calendar>
  </div>
  <div class="input-feedback" *ngIf="showPeriodLimitationFeedback">
    {{ 'agriness.filter.period.limitation_feedback' | translate: { n: rangeLimitInDays } }}
    <button class="link" (click)="adjustPeriodRange()">
      {{ 'agriness.filter.period.limitation_feedback_link' | translate }}
    </button>
  </div>
</div>
<div class="input-content has-feedback" *ngIf="filterForm" [ngStyle]="{ width: inputWidth }">
  <div
    [ngClass]="{ 'has-error': showDateLimitationFeedback }"
    class="form-group datepicker"
    id="filter-{{ kebabCase(endDate.controlName) }}-form-group"
  >
    <ag-calendar
      [group]="filterForm"
      [controlName]="endDate.controlName"
      [validation]="invalidateByFutureDate(endDate.controlName)"
      [label]="endDate.labelKey"
      [dateFormat]="dateFormat"
      [maxDate]="setMaxDate()"
      inputId="filter-{{ kebabCase(endDate.controlName) }}"
      [errorMessage]="showDateLimitationFeedback ? errorMessage : null"
    ></ag-calendar>
  </div>
</div>
