import { DietSelectionService } from '@agriness/corp-app/services/diet-selection/diet-selection.service';
import {
  DietFormData,
  DietOptions,
  FeedSearch,
} from '@agriness/corp-app/services/models/diet-selection.model';
import { LoaderUserPreference } from '@agriness/corp-app/shared/component/loader-user-preference';
import { UserStorageService } from '@agriness/services';
import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'diet-selection-form',
  templateUrl: './diet-selection-form.component.html',
  styleUrls: ['./diet-selection-form.styles.scss'],
})
export class DietSelectionFormComponent extends LoaderUserPreference implements OnChanges {
  @Input() options: DietOptions;
  @Input() isEditing: boolean;
  @Input() currentAge: number;
  @Input() stage: string;
  @Input() animalGroupId: string;

  translationContext = 'agriness.diet_selection';
  subscription = Subscription.EMPTY;

  dietForm: FormGroup;
  diets: string[] = [];

  constructor(
    private dietSelectionService: DietSelectionService,
    protected userStorageService: UserStorageService,
  ) {
    super(userStorageService);
  }

  ngOnChanges(): void {
    this.dietForm = new FormGroup({
      age: new FormControl(this.currentAge),
      proposed_diet: new FormControl(''),
      comments: new FormControl(''),
    });
  }

  getFormData(): DietFormData {
    return this.dietForm.value as DietFormData;
  }

  searchDiets(event: { query: string }): void {
    this.subscription.unsubscribe();

    this.subscription = this.dietSelectionService
      .searchDiets({
        holdingId: this.holdingId,
        stage: this.stage,
        id: this.animalGroupId,
        query: event.query,
      })
      .subscribe(data => this.setDiets(data));
  }

  setDiets(search: FeedSearch): void {
    if (!search) return null;

    const diets = search?.results;
    const proposedDiets = diets.map(diet => diet.proposed_diet);
    const currentDiets = diets.map(diet => diet.current_diet);

    this.diets = [...new Set([...proposedDiets, ...currentDiets])];
  }
}
