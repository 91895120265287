import { Farm, FarmForm } from '@agriness/corp-app/services';
import { TypeProductionEnum } from '@agriness/services';
import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '@env/environment';

@Component({
  selector: 'farm-editor',
  templateUrl: './farm-editor.component.html',
  styleUrls: ['./farm-editor.component.scss'],
})
export class FarmEditorComponent implements OnInit {
  @Input() visible = false;
  @Input() farmData: Farm;
  @Input() modalStatus = ModalStatusEnum.DEFAULT;

  @Output() closed = new EventEmitter<void>();
  @Output() updateFarm = new EventEmitter<void>();

  translationContext = 'agriness.settings.farms';
  farmForm: FormGroup;
  showRoute = false;
  typeProduction = environment.typeProduction;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    protected $formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.farmForm = this.initializeForm();
    this.updatedFormFarm();

    this.showRoute = this.typeProduction === TypeProductionEnum.DAIRY;
  }

  initializeForm(): FormGroup {
    return this.$formBuilder.group({
      name: [''],
      producer: [''],
      technician: [''],
      region: [''],
      route: [''],
    });
  }

  onSubmit(): void {
    if (this.farmData) {
      this.updateFarm.emit();
    }
  }

  onClosed(): void {
    this.closed.emit();
  }

  updatedFormFarm(): void {
    const { name, producer, technician, region, route } = this.farmData;

    this.farmForm.patchValue({
      name,
      producer: producer?.id || null,
      technician: technician?.id || null,
      region: region?.id || null,
      route: route?.id || null,
    });
  }

  getFarmForm(): FarmForm {
    this.farmForm.removeControl('name');
    return this.farmForm.getRawValue() as FarmForm;
  }
}
