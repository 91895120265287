<div class="filter-bar--actions d-flex">
  <button type="submit" class="btn btn-primary is-small" [disabled]="hasError">
    {{'agriness.filter.action' | translate}}
  </button>
  <div *ngIf="isDropdownActive" ngbDropdown class="d-inline-block">
    <a ngbDropdownToggle class="dropdown-toggle--no-trigger">
      <span class="icon icon-more_horiz is-24 has-text-grey-4"></span>
    </a>
    <div ngbDropdownMenu>
      <ul>
        <li (click)="cleanFilter()">
          {{'agriness.filter.clear' | translate}}
        </li>
        <li (click)="rollbackDefaultFilter()">
          {{'agriness.filter.rollback' | translate}}
        </li>
      </ul>
    </div>
  </div>
</div>
