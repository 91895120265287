import { TargetDetail, TargetPeriodType } from '@agriness/corp-app/services';
import { TargetIndexService } from '@agriness/corp-app/services/target/target-index.service';
import {
  ControlErrors,
  RangeValidation,
} from '@agriness/corp-app/settings/target-section/targets/targets.model';
import { RadioOption } from '@agriness/corp-app/shared/component/corp-radio-options/corp-radio-options.model';
import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { ModalStatusEnum, RepresentationTypeEnum, TargetSubtitlesService } from '@agriness/ui';
import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { max, min } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';

import { MainForm, PeriodTypeEnum } from '../target-editor-form.model';

@Component({
  selector: 'app-target-config-rules',
  templateUrl: './target-config-rules.component.html',
  styleUrls: ['./target-config-rules.component.scss'],
  providers: [TargetIndexService],
})
export class TargetConfigRulesComponent implements AfterContentChecked, OnInit {
  @Input() formData: BehaviorSubject<MainForm> = null;
  @Output() sendData = new EventEmitter<MainForm>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() isInvalid = new EventEmitter<boolean>();
  targetPeriodTypes: TargetPeriodType;
  periodTypeEnum = PeriodTypeEnum;
  targetValuesSubscriptions: Subscription[] = [];

  translationContext = 'agriness.settings.targets.editor';

  formDataSubscribed: MainForm = null;

  editorModalVisible = false;
  editorModalStatus = ModalStatusEnum.DEFAULT;

  form: FormGroup;

  targetValues = new FormArray([], Validators.required.bind(this));

  representationType = RepresentationTypeEnum;

  typeVerification = RepresentationTypeEnum.RANGE_BAD_ATTENTION_GOOD;

  periodOptions: RadioOption[];
  targetFormErrors = [];
  showPeriodInfo: boolean;

  orderedValueKeys = [
    'min_allowed',
    'min_recommended',
    'minTargetWithVariation',
    'maxTargetWithVariation',
    'max_recommended',
    'max_allowed',
  ];
  decimalPlaces: number;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private formBuilder: FormBuilder,
    private serviceIndex: TargetIndexService,
    private targetSubtitlesService: TargetSubtitlesService,
  ) {}

  ngOnInit(): void {
    this.formData.subscribe(res => {
      this.formDataSubscribed = res;
      if (this.formDataSubscribed?.target_detail?.length) {
        this.updatedFormConfig();
      } else {
        this.addConfigRange();
      }
    });

    this.form = this.formBuilder.group({
      period_type: [null, Validators.required.bind(this)],
    });

    this.checkTargetPeriodType();

    this.form.valueChanges.subscribe(() => {
      this.validate();
    });

    this.targetValues.valueChanges.subscribe(() => {
      this.validate();
    });
  }

  checkTargetPeriodType(): void {
    const target_index = this.formDataSubscribed.target_index.id;
    this.serviceIndex.checkPeriodType(target_index).subscribe(res => {
      this.targetPeriodTypes = res;
      this.setPeriodOption();
      if (Object.values(this.targetPeriodTypes).some(val => val === true)) {
        this.showPeriodInfo = true;
      }
    });
  }

  ngAfterContentChecked(): void {
    this.isFormValid();
  }

  validate(): void {
    if (this.form.valid && this.targetValues.valid) {
      this.isValid.emit(true);
    } else {
      this.isValid.emit(false);
    }
  }

  isFormValid(): void {
    this.validate();
  }

  receiveFormData(event: MainForm): void {
    this.formDataSubscribed = event;
  }

  setFormData(): void {
    const targetValues = this.targetValues.getRawValue() as TargetDetail[];
    const formData: MainForm = {
      ...this.formDataSubscribed,
      period_type: this.form.get('period_type').value as string,
      target_detail: targetValues,
    };

    this.sendData.emit(formData);
  }

  createConfig(): void {
    this.sendData.emit(this.formDataSubscribed);
    this.isFormValid();
  }

  onPeriodTypeSelected(option: RadioOption): void {
    if (option.value == PeriodTypeEnum.GENERAL) {
      this.targetValues.controls.length = 1;
      this.targetValues.controls[0].get('period').setValue(0);
    }
  }

  setPeriodOption(): void {
    this.periodOptions = [
      {
        label: this.t('agriness.targets.period_type.general'),
        value: PeriodTypeEnum.GENERAL,
        disabled:
          this.formDataSubscribed?.period_type == PeriodTypeEnum.GENERAL
            ? false
            : this.targetPeriodTypes.GENERAL,
      },
      {
        label: this.t('agriness.targets.period_type.daily'),
        value: PeriodTypeEnum.DAILY,
        disabled:
          this.formDataSubscribed?.period_type == PeriodTypeEnum.DAILY
            ? false
            : this.targetPeriodTypes.DAILY,
      },
      {
        label: this.t('agriness.targets.period_type.weekly'),
        value: PeriodTypeEnum.WEEKLY,
        disabled:
          this.formDataSubscribed?.period_type == PeriodTypeEnum.WEEKLY
            ? false
            : this.targetPeriodTypes.WEEKLY,
      },
    ];
  }

  updatedFormConfig(): void {
    this.formDataSubscribed.target_detail.forEach(item => {
      this.targetValues.push(
        this.formBuilder.group({
          min_allowed: [
            this.formDataSubscribed.target_index.min_allowed,
            Validators.required.bind(this),
          ],
          min_recommended: [item.min_recommended, Validators.required.bind(this)],
          target_value: [item.target_value, Validators.required.bind(this)],
          max_recommended: [item.max_recommended, Validators.required.bind(this)],
          max_allowed: [
            this.formDataSubscribed.target_index.max_allowed,
            Validators.required.bind(this),
          ],
          value_variation_rate: [item.value_variation_rate, Validators.required.bind(this)],
          period: [item.period, Validators.required.bind(this)],
        }),
      );

      this.updateTargetValuesValidation();
      this.listenValueChanges();
    });
  }

  addConfigRange(): void {
    this.targetValues.push(
      this.formBuilder.group({
        min_allowed: [
          this.formDataSubscribed.target_index.min_allowed,
          Validators.required.bind(this),
        ],
        min_recommended: [null, [Validators.required.bind(this)]],
        target_value: [null, Validators.required.bind(this)],
        max_recommended: [null, Validators.required.bind(this)],
        max_allowed: [
          this.formDataSubscribed.target_index.max_allowed,
          Validators.required.bind(this),
        ],
        value_variation_rate: [null, Validators.required.bind(this)],
        period: [null, Validators.required.bind(this)],
      }),
    );

    this.updateTargetValuesValidation();
    this.listenValueChanges();
  }

  updateTargetValuesValidation(): void {
    this.targetValues.controls.forEach((control, index) => {
      this.targetValues.controls[index]
        .get('target_value')
        .setValidators([Validators.required.bind(this), this.rangeValidationTargetValue(index)]);

      this.targetValues.controls[index]
        .get('value_variation_rate')
        .setValidators([
          Validators.required.bind(this),
          this.rangeValidationValueVariationRate(index),
        ]);

      this.targetValues.controls[index]
        .get('min_recommended')
        .setValidators([
          Validators.required.bind(this),
          this.rangeValidationRecommended('min_recommended', index),
        ]);

      this.targetValues.controls[index]
        .get('max_recommended')
        .setValidators([
          Validators.required.bind(this),
          this.rangeValidationRecommended('max_recommended', index),
        ]);
    });
  }

  cantRemove(): boolean {
    return this.targetValues.length <= 1;
  }

  remove(index: number): void {
    if (this.cantRemove()) {
      return;
    }
    this.targetValues.removeAt(index);
    this.targetFormErrors.splice(index, 1);
    this.targetValuesSubscriptions.splice(index, 1);
    this.listenValueChanges();
    this.updateTargetValuesValidation();
  }

  listenValueChanges(): void {
    this.targetValues.controls.forEach((group, index) => {
      this.targetValuesSubscriptions[index]?.unsubscribe();
      this.targetValuesSubscriptions[index] = new Subscription();

      for (const control in this.targetValues.controls[index]['controls']) {
        this.targetValuesSubscriptions[index].add(
          (this.targetValues.controls[index]['controls'][
            control
          ] as FormControl).valueChanges.subscribe(() => {
            for (const key in this.targetValues.controls[index]['controls']) {
              (this.targetValues.controls[index]['controls'][
                key
              ] as FormControl).updateValueAndValidity({ emitEvent: false });
            }

            this.updateErrorMessages(index);
          }),
        );
      }
    });
  }

  getFieldErrorMessage(controlErrors: ControlErrors): string {
    const { range, required } = controlErrors;

    if (required) return this.t('agriness.settings.validation_required');

    if (range) {
      const { min, max } = range;

      if (min == max) {
        return this.t(`${this.translationContext}.validation_feedback_equal`, {
          value: min,
        });
      } else if (min < max) {
        return this.t(`${this.translationContext}.validation_feedback`, {
          min,
          max,
        });
      }
    }
  }

  rangeValidationTargetValue(index: number): ValidatorFn {
    return (): RangeValidation | null => {
      if (!this.targetValues.controls.length) return;

      const formValues = this.targetValues.getRawValue()[index] as TargetDetail;
      const { value_variation_rate = 0 } = formValues;

      const [
        minTargetWithVariation,
        maxTargetWithVariation,
      ] = this.targetSubtitlesService.calculateTargetWithVariation(formValues);

      const [previous] = this.splitArrayAt(this.orderedValueKeys, 'minTargetWithVariation');
      const [, next] = this.splitArrayAt(this.orderedValueKeys, 'maxTargetWithVariation');
      const previousMax = max(this.getValidFieldsValues(previous, index));
      const nextMin = min(this.getValidFieldsValues(next, index));

      // Mix/max suggestion based on previous, next and varition rate
      const minTargetValue = this.toIndexPrecision(
        previousMax / (-value_variation_rate / 100 + 1),
        Math.ceil,
      );
      const maxTargetValue = this.toIndexPrecision(
        nextMin / (value_variation_rate / 100 + 1),
        Math.floor,
      );

      let minValue = max([minTargetValue, previousMax]);
      let maxValue = min([maxTargetValue, nextMin]);
      if (minValue > maxValue) {
        minValue = previousMax;
        maxValue = nextMin;
      }

      return minTargetWithVariation < previousMax || maxTargetWithVariation > nextMin
        ? {
            range: {
              min: minValue,
              max: maxValue,
            },
          }
        : null;
    };
  }

  rangeValidationValueVariationRate(index: number): ValidatorFn {
    return (): RangeValidation | null => {
      if (!this.targetValues.controls.length) return;

      const formValues = this.targetValues.getRawValue()[index] as TargetDetail;
      const { target_value, value_variation_rate = 0 } = formValues;

      const [
        minTargetWithVariation,
        maxTargetWithVariation,
      ] = this.targetSubtitlesService.calculateTargetWithVariation(formValues);

      const [previous] = this.splitArrayAt(this.orderedValueKeys, 'minTargetWithVariation');
      const [, next] = this.splitArrayAt(this.orderedValueKeys, 'maxTargetWithVariation');
      const previousMax = max(this.getValidFieldsValues(previous, index));
      const nextMin = min(this.getValidFieldsValues(next, index));

      // Mix/max suggestion based on previous, next and variation rate
      const minValueVariationRate = this.toIndexPrecision(
        (-(previousMax / target_value) + 1) * 100,
        Math.ceil,
        2,
      );
      const maxValueVariationRate = this.toIndexPrecision(
        (nextMin / target_value - 1) * 100,
        Math.floor,
        2,
      );

      const minValue = 0;
      const maxValue = min([minValueVariationRate, maxValueVariationRate]);

      const outsideMinMax = () =>
        value_variation_rate < minValue || value_variation_rate > maxValue;
      const outsidePreviousNext = () =>
        minTargetWithVariation < previousMax || maxTargetWithVariation > nextMin;

      return outsideMinMax() || outsidePreviousNext()
        ? {
            range: {
              min: minValue,
              max: maxValue,
            },
          }
        : null;
    };
  }

  toIndexPrecision(value: number, roundFn: (x: number) => number, places?: number): number {
    const adjust = 10 ** (places ?? this.decimalPlaces);
    return roundFn(value * adjust) / adjust;
  }

  rangeValidationRecommended(controlName: string, index: number): ValidatorFn {
    return (): RangeValidation | null => {
      if (!this.targetValues.controls.length) return;
      const value = this.targetValues.controls[index]['controls'][controlName].value as number;
      const [previous, next] = this.splitArrayAt(this.orderedValueKeys, controlName);
      const previousMax = max(this.getValidFieldsValues(previous, index));
      const nextMin = min(this.getValidFieldsValues(next, index));
      return value < previousMax || value > nextMin
        ? {
            range: {
              min: previousMax,
              max: nextMin,
            },
          }
        : null;
    };
  }

  getValidFieldsValues(names: string[], index: number): number[] {
    return names
      .filter(name => {
        if (['minTargetWithVariation', 'maxTargetWithVariation'].includes(name)) {
          return (
            this.targetValues.controls[index]['controls']['target_value'].valid &&
            this.targetValues.controls[index]['controls']['value_variation_rate'].valid
          );
        }
        return this.targetValues.controls[index]['controls'][name].valid;
      })
      .map(name => this.getValues(index)[name]);
  }

  getValues(index: number): { [k: string]: number } {
    const formValues = this.targetValues.getRawValue()[index] as TargetDetail;
    const [
      minTargetWithVariation,
      maxTargetWithVariation,
    ] = this.targetSubtitlesService.calculateTargetWithVariation(formValues);
    return {
      ...formValues,
      minTargetWithVariation,
      maxTargetWithVariation,
    };
  }

  splitArrayAt<T>(array: T[], element: T): T[][] {
    const index = array.indexOf(element);
    return [array.slice(0, index), array.slice(index + 1)];
  }

  private updateErrorMessages(index: number): void {
    for (const control in this.targetValues.controls[index]['controls']) {
      const errors = this.targetValues.controls[index]['controls'][control].errors as ControlErrors;
      if (!this.targetFormErrors[index]) {
        this.targetFormErrors.push({});
      }
      this.targetFormErrors[index][control] = errors ? this.getFieldErrorMessage(errors) : null;
    }
  }
}
