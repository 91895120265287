import { Technician, TechnicianFilter } from '@agriness/corp-app/services';
import { TechnicianService } from '@agriness/corp-app/services/technician/technician.service';
import { CorpFiltersContainerComponent } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.component';
import { CorpFiltersContainerService } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.service';
import {
  ActionEnum,
  CorpRecordTableComponent,
} from '@agriness/corp-app/shared/component/corp-record-table/corp-record-table.component';
import {
  ReportFilter,
  ReportFilterQueryKeys,
} from '@agriness/corp-app/shared/model/report-filter.model';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, Inject, ViewChild } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

import { defaultTechnicianOrder } from '../../../../services/models/technician.model';
import { PaginationRequest } from '../../../../shared/model/table.model';
import { DeletionTableData } from '../../../deletion-table/deletion-table.model';
import { TechnicianEditorComponent } from './technician-editor/technician-editor.component';
import { techniciansDeletionTableFields, techniciansTableColumns } from './technicians.model';
@Component({
  templateUrl: './technicians.component.html',
  providers: [CorpFiltersContainerService.provider],
})
export class TechniciansComponent {
  @ViewChild('filtersContainer', { static: true }) reportFilter: CorpFiltersContainerComponent;
  @ViewChild('table', { static: true }) table: CorpRecordTableComponent<Technician>;
  @ViewChild('editor') technicianEditor: TechnicianEditorComponent;

  translationContext = 'agriness.settings.technicians';

  tableColumns = techniciansTableColumns;
  defaultOrdering = defaultTechnicianOrder;
  availableActions = [ActionEnum.EDIT, ActionEnum.DELETE];
  technicians: Technician[];
  totalRecords: number;
  rows = 10;

  editorModalVisible = false;
  editorModalStatus = ModalStatusEnum.DEFAULT;
  selectedTechnician: Technician;
  deletionModalVisible = false;
  deletionModalStatus = ModalStatusEnum.DEFAULT;
  deletionTableData: DeletionTableData;

  error = false;
  loading = true;
  currentFilter: TechnicianFilter = {};
  filterEntity: keyof ReportFilter = 'technician';
  queryParamName: ReportFilterQueryKeys = 'technician_name';

  private queryCorpOnly = true;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private technicianService: TechnicianService,
  ) {}

  createTechnician(): void {
    const formValues = this.technicianEditor.getTechnicianForm();
    this.editorModalStatus = ModalStatusEnum.LOADING;

    this.technicianService
      .save(formValues)
      .pipe(
        first(),
        map(() => (this.editorModalStatus = ModalStatusEnum.SUCCESS)),
        catchError(({ error }) => {
          this.editorModalStatus = ModalStatusEnum.FAILED;
          return throwError(error);
        }),
      )
      .subscribe(() => this.filter());
  }

  filter(): void {
    this.table.reset();
  }

  filterReset(): void {
    this.reportFilter.onFilter();
  }

  onAddTechnician(): void {
    this.selectedTechnician = null;
    this.editorModalVisible = true;
  }

  onDeletionModalClose(): void {
    this.selectedTechnician = null;
    this.deletionModalVisible = false;
    this.deletionModalStatus = ModalStatusEnum.DEFAULT;
  }

  onDeletionModalDelete(): void {
    this.deletionModalStatus = ModalStatusEnum.LOADING;

    this.technicianService.delete(this.selectedTechnician).subscribe({
      complete: () => {
        this.deletionModalStatus = ModalStatusEnum.SUCCESS;
        this.filter();
      },
      error: error => {
        this.deletionModalStatus = ModalStatusEnum.FAILED;
        throw error;
      },
    });
  }

  onEditorClosed(): void {
    this.selectedTechnician = null;
    this.editorModalVisible = false;
    this.editorModalStatus = ModalStatusEnum.DEFAULT;
  }

  onItemAction(obj: { action: ActionEnum; item: Technician }): void {
    switch (obj.action) {
      case ActionEnum.EDIT:
        this.selectedTechnician = obj.item;
        this.editorModalVisible = true;
        break;
      case ActionEnum.DELETE:
        this.selectedTechnician = obj.item;
        this.updateDeletionTableData(this.selectedTechnician);
        this.deletionModalVisible = true;
    }
  }

  onPageRequest(request: PaginationRequest): void {
    this.loading = true;
    this.updateCurrentFilter(request);
    this.loadTechnicians();
  }

  shouldBlockAction(obj: { action: ActionEnum; item: Technician }): boolean {
    if (obj.action == ActionEnum.DELETE) {
      return obj.item.farm_count > 0 || obj.item.animalgroup_count > 0;
    }
    return false;
  }

  updateTechnician(): void {
    if (this.selectedTechnician) {
      const { id } = this.selectedTechnician;
      const formValues = this.technicianEditor.getTechnicianForm();
      this.editorModalStatus = ModalStatusEnum.LOADING;

      this.technicianService
        .update(formValues, id)
        .pipe(
          first(),
          map(() => (this.editorModalStatus = ModalStatusEnum.SUCCESS)),
          catchError(({ error }) => {
            this.editorModalStatus = ModalStatusEnum.FAILED;
            return throwError(error);
          }),
        )
        .subscribe(() => this.filter());
    }
  }

  private loadTechnicians(): void {
    this.technicianService
      .list(this.currentFilter)
      .pipe(
        first(),
        map(({ count, results }) => {
          this.totalRecords = count;
          this.loading = false;
          this.technicians = results;
        }),
        catchError(error => {
          this.error = true;
          return throwError(error);
        }),
      )
      .subscribe();
  }

  private updateCurrentFilter(request: PaginationRequest): void {
    const name = this.reportFilter.getCurrentFilterInQueryFormat().technician_name;
    const has_farm = {
      has_farm: this.reportFilter.getCurrentFilterInQueryFormat().has_farm,
    };

    this.currentFilter = {
      ...this.currentFilter,
      ...request,
      name,
      queryCorpOnly: this.queryCorpOnly,
      ...has_farm,
    };
  }

  private updateDeletionTableData(technician: Technician): void {
    this.deletionTableData = techniciansDeletionTableFields.map(field => {
      return {
        field: this.t(`agriness.settings.technicians.table.${field}`),
        value: technician[field] as string,
      };
    });
  }
}
