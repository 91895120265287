import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiCorpService } from '../api-corp.service';
import { AnimalGroupMonitorPerformance } from '../models/animal-group-performance.model';
import {
  PerformanceAnalysisUrlFilter,
  SearchFilterPerformanceAnalysis,
} from '../models/performance-analysis.model';
import {
  PerformanceItem,
  PerformanceItems,
  PerformanceItemsRaw,
} from '../models/performance.model';

@Injectable()
export class PerformanceAnalysisService {
  private pathTemplate = `holdings/{holdingId}/{typeProduction}/{stage}/performance-analysis/{report}/`;

  constructor(private apiCorpService: ApiCorpService) {}

  getPerformanceAnalysisGraph(
    urlFilter: PerformanceAnalysisUrlFilter,
    searchFilter: SearchFilterPerformanceAnalysis,
  ): Observable<PerformanceItems> {
    const url = this.apiCorpService.translateUrl(this.pathTemplate, urlFilter);
    return this.apiCorpService
      .get(url, searchFilter)
      .pipe(map(object => this.formatPerformanceItems(object as PerformanceItemsRaw)));
  }

  loadPerformanceCards(
    urlFilter: PerformanceAnalysisUrlFilter,
    searchFilter: SearchFilterPerformanceAnalysis,
  ): Observable<AnimalGroupMonitorPerformance[]> {
    const url = this.apiCorpService.translateUrl(this.pathTemplate, urlFilter);
    return this.apiCorpService.get(url, searchFilter);
  }

  private formatPerformanceItems(performanceRaw: PerformanceItemsRaw): PerformanceItems {
    return performanceRaw.kpis.map(
      row => merge(row, ...performanceRaw.targets) as PerformanceItem,
    ) as PerformanceItems;
  }
}
