import { kebabCase, isPlainObject } from 'lodash';

export function textToElementId(...input: string[]): string {
  return kebabCase(input.join('-'));
}

type AnyObject = Record<string, unknown>;
export function objectKeysToLowerCase(input: AnyObject): AnyObject {
  return Object.entries(input).reduce((acc, [key, value]) => {
    acc[key.toLowerCase()] = isPlainObject(value)
      ? objectKeysToLowerCase(value as AnyObject)
      : value;
    return acc;
  }, {} as AnyObject);
}

export const maybeParseInt = (value: string): number | null => {
  const result = parseInt(value, 10);

  return isNaN(result) ? null : result;
};
