<div class="ag-layout-menu-farms" *ngIf="holdingMenu">
  <div ngbDropdown>
    <button ngbDropdownToggle type="button" class="dropdown-toggle--no-trigger">
      <div class="text-truncate has-text-grey-5">{{ holdingMenu.activeHolding.name }}</div>
      <div class="text-small text-uppercase">
        {{ getHoldingTypeText(holdingMenu.activeHolding) }}
      </div>
    </button>

    <div ngbDropdownMenu>
      <a class="dropdown-item active">
        <div class="text-truncate">{{ holdingMenu.activeHolding.name }}</div>
        <div class="text-small text-uppercase">
          {{ getHoldingTypeText(holdingMenu.activeHolding) }}
        </div>
      </a>
      <a
        (click)="changeActiveHolding(holding)"
        [hidden]="holding.id == holdingMenu.activeHolding.id"
        class="dropdown-item"
        *ngFor="let holding of holdingMenu.holdings"
      >
        <div>{{ holding.name }}</div>
        <div class="text-small text-uppercase">{{ getHoldingTypeText(holding) }}</div>
      </a>
    </div>
  </div>
</div>
