import { Search, TypeProductionService, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { SearchAnimalGroupListParams, AnimalGroupListFilter } from './animal-group-list.model';

@Injectable()
export class AnimalGroupListService {
  private basePath = 'holdings/{holding_id}/animal-groups/';
  private defaultPageSize = 10;

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {}

  list(params: AnimalGroupListFilter): Observable<Search<SearchAnimalGroupListParams>> {
    const url = this.apiCorpService.translateUrl(this.basePath, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
    });

    return this.apiCorpService.get<Search<SearchAnimalGroupListParams>>(url, {
      ...params,
      page_size: this.defaultPageSize,
      system_type: this.typeProductionService.get(),
    });
  }
}
