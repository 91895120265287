import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BasicRecordsSectionComponent } from './basic-records-section/basic-records-section.component';
import { BasicRecordsGeneralComponent } from './basic-records-section/general/general.component';
import { ProducerComponent } from './basic-records-section/general/producer/producer.component';
import { RegionsComponent } from './basic-records-section/general/regions/regions.component';
import { RoutesComponent } from './basic-records-section/general/routes/routes.component';
import { TanksComponent } from './basic-records-section/general/tanks/tanks.component';
import { TechniciansComponent } from './basic-records-section/general/technicians/technicians.component';
import { FarmSectionComponent } from './farm-section/farm-section.component';
import { FarmComponent } from './farm-section/farm/farm.component';
import { PaymentRuleSectionComponent } from './payment-rule-section/payment-rule-section.component';
import { PaymentRuleComponent } from './payment-rule-section/payment-rule/payment-rule.component';
import { RuleEditorComponent } from './payment-rule-section/payment-rule/rule-editor/rule-editor.component';
import { PresenceSectionComponent } from './presence-section/presence-section.component';
import { ChecklistsTabComponent } from './presence-section/tools/checklists-tab/checklists-tab.component';
import { ToolsComponent } from './presence-section/tools/tools.component';
import { ProductionParamsSectionComponent } from './production-params-section/production-params-section.component';
import { ProductionParamsGuard } from './production-params-section/production-params.guard';
import { ProductionTabComponent } from './production-params-section/production/production-tab/production-tab.component';
import { ProductionComponent } from './production-params-section/production/production.component';
import { TargetSectionComponent } from './target-section/target-section.component';
import { TargetsComponent } from './target-section/targets/targets.component';
import { TargetSectionComponent as TargetSectionComponentV2 } from './targets-in-dev/target-section.component';
import { TargetGuard } from './targets-in-dev/target.guard';
import { TargetEditorComponent } from './targets-in-dev/targets/targets-tab/target-editor/target-editor.component';
import { TargetViewComponent } from './targets-in-dev/targets/targets-tab/target-view/target-view.component';
import { TargetsTabComponent } from './targets-in-dev/targets/targets-tab/targets-tab.component';
import { TargetsComponent as TargetComponentV2 } from './targets-in-dev/targets/targets.component';

const routes: Routes = [
  {
    path: 'basic-records',
    component: BasicRecordsSectionComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'general' },
      {
        path: 'general',
        component: BasicRecordsGeneralComponent,
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'technicians' },
          { path: 'technicians', component: TechniciansComponent },
          { path: 'producers', component: ProducerComponent },
          { path: 'regions', component: RegionsComponent },
          { path: 'routes', component: RoutesComponent },
          { path: 'tanks', component: TanksComponent },
        ],
      },
    ],
  },
  {
    path: 'farms',
    component: FarmSectionComponent,
    children: [{ path: '', pathMatch: 'full', component: FarmComponent }],
  },
  {
    path: 'targets',
    component: TargetSectionComponent,
    children: [{ path: '', pathMatch: 'full', component: TargetsComponent }],
  },
  {
    path: 'targetsnews',
    component: TargetSectionComponentV2,
    canActivate: [TargetGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'target' },
      {
        path: 'target',
        component: TargetComponentV2,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'reproductive',
            children: [],
          },
          {
            path: 'reproductive',
            children: [
              { path: '', pathMatch: 'full', component: TargetsTabComponent },
              { path: 'add', component: TargetEditorComponent },
              { path: 'edit', component: TargetEditorComponent },
              { path: 'view', component: TargetViewComponent },
            ],
          },
          {
            path: 'nursery',
            children: [
              { path: '', pathMatch: 'full', component: TargetsTabComponent },
              { path: 'add', component: TargetEditorComponent },
              { path: 'edit', component: TargetEditorComponent },
              { path: 'view', component: TargetViewComponent },
            ],
          },
          {
            path: 'finishing',
            children: [
              { path: '', pathMatch: 'full', component: TargetsTabComponent },
              { path: 'add', component: TargetEditorComponent },
              { path: 'edit', component: TargetEditorComponent },
              { path: 'view', component: TargetViewComponent },
            ],
          },
          {
            path: 'grow-out',
            children: [
              { path: '', pathMatch: 'full', component: TargetsTabComponent },
              { path: 'add', component: TargetEditorComponent },
              { path: 'edit', component: TargetEditorComponent },
              { path: 'view', component: TargetViewComponent },
            ],
          },
          { path: 'add', component: TargetEditorComponent },
          { path: 'edit', component: TargetEditorComponent },
          { path: 'view', component: TargetViewComponent },
        ],
      },
    ],
  },

  {
    path: 'bonification',
    component: PaymentRuleSectionComponent,
    children: [
      { path: '', pathMatch: 'full', component: PaymentRuleComponent },
      { path: 'add', component: RuleEditorComponent },
      { path: 'edit', component: RuleEditorComponent },
    ],
  },
  {
    path: 'production-params',
    component: ProductionParamsSectionComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'production' },
      {
        path: 'production',
        component: ProductionComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            children: [],
            canActivate: [ProductionParamsGuard],
          },
          { path: 'nursery', component: ProductionTabComponent },
          { path: 'finishing', component: ProductionTabComponent },
          { path: 'grow-out', component: ProductionTabComponent },
        ],
      },
    ],
  },
  {
    path: 'presence',
    component: PresenceSectionComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'tools' },
      {
        path: 'tools',
        component: ToolsComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'checklist',
          },
          { path: 'checklist', component: ChecklistsTabComponent },
        ],
      },
    ],
  },
];
export const routerAnalysis: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);

@NgModule({
  imports: [routerAnalysis],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
