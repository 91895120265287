import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthGuard } from './auth.guard';
import { FeatureGuard } from './feature.guard';
import { PageLeaveConfirmationGuard } from './page-leave-confirmation.guard';

@NgModule({
  providers: [AuthGuard, FeatureGuard, PageLeaveConfirmationGuard],
  imports: [CommonModule],
})
export class RouterGuardModule {}
