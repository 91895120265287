import { Component, Input } from '@angular/core';

@Component({
  selector: 'ag-table-action-cell',
  templateUrl: './ag-table-action-cell.component.html',
  styleUrls: ['./ag-table-action-cell.component.scss'],
})
export class AgTableActionCellComponent {
  @Input() icon: string;
  @Input() event: () => void;
}
