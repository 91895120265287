export interface SidebarCell {
  id: string;
  getContent(): string;
}

export interface CalendarCell {
  date: CalendarDate;
  getContent(): string;
}

export interface CalendarRow {
  id: string;
  cells: Array<CalendarCell>;
}

const DAYS = {
  SUNDAY: 0,
  SATURDAY: 6,
};
export class CalendarDate extends Date {
  week_names = [
    'agriness.date.week.long.sunday',
    'agriness.date.week.long.monday',
    'agriness.date.week.long.tuesday',
    'agriness.date.week.long.wednesday',
    'agriness.date.week.long.thursday',
    'agriness.date.week.long.friday',
    'agriness.date.week.long.saturday',
  ];
  getWeekName(): string {
    return this.week_names[this.getDay()];
  }

  getDateTitle(): string {
    return `${this.getDate()}/${this.getMonth() + 1}`;
  }

  isWeekend(): boolean {
    return this.getDay() === DAYS.SUNDAY || this.getDay() === DAYS.SATURDAY;
  }
}

export class ScheduleCell implements CalendarCell {
  date: CalendarDate;
  technician: string;
  technician_user_id: string;
  total: number;
  finished: number;

  constructor({
    date,
    technician,
    technician_user_id,
    schedules_total = 0,
    schedules_finished = 0,
  }: {
    date: CalendarDate;
    technician: string;
    technician_user_id: string;
    schedules_total?: number;
    schedules_finished?: number;
  }) {
    this.date = date;
    this.technician = technician;
    this.technician_user_id = technician_user_id;
    this.total = schedules_total;
    this.finished = schedules_finished;
  }

  getContent(): string {
    return `${this.total || '-'}/${this.finished || '-'}`;
  }
}

export class TechnicianRow implements CalendarRow {
  id: string;
  user_id: string;
  cells: Array<ScheduleCell>;
  constructor(id: string, user_id: string, cells: Array<ScheduleCell>) {
    this.id = id;
    this.user_id = user_id;
    this.cells = cells;
  }
}

export class TechnicianCell implements SidebarCell {
  id: string;
  user_id: string;
  name: string;
  constructor(id: string, user_id: string, name: string) {
    this.id = id;
    this.user_id = user_id;
    this.name = name;
  }
  getContent(): string {
    return this.name.toLowerCase();
  }
}
