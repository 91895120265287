import { TypeProductionService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import {
  AnimalGroupMonitorOpenedPerformance,
  AnimalGroupMonitorPerformance,
  AnimalGroupMonitorPerformanceByPeriod,
  AnimalGroupPerformance,
} from '../models/animal-group-performance.model';
import { FilterMonitor } from '../models/monitor.model';
import { PreferenceId, ReportEnum } from '../models/user.model';
import {
  GetMonitorPerformanceArgs,
  GetMonitorPerformanceByPeriodArgs,
  MonitorAbstractService,
} from './monitor-abstract.service';

@Injectable()
export class MonitorService extends MonitorAbstractService {
  private pathMonitorPerformance =
    'holdings/{holding_id}/{type_production}/{stage}/reports/{report}/';
  private pathMonitorPerformanceByPeriod =
    'holdings/{holding_id}/{type_production}/{stage}/reports/animal-group-opened-monitor/';
  private pathMonitorOpenedPerformance =
    'holdings/{holding_id}/{type_production}/{stage}/reports/animal-group-opened-monitor/';

  constructor(
    private apiCorpService: ApiCorpService,
    private typeProductionService: TypeProductionService,
  ) {
    super();
  }

  getMonitorPerformance(
    idHoldingOrArgs: string | GetMonitorPerformanceArgs,
    stage?: string,
    filter?: FilterMonitor,
  ): Observable<AnimalGroupMonitorPerformance[]> {
    let holdingId: string;
    let report: PreferenceId;
    if (typeof idHoldingOrArgs === 'string') {
      holdingId = idHoldingOrArgs;
      report = ReportEnum.MONITOR_CLOSED;
    } else {
      ({ holdingId, stage, filter, report } = idHoldingOrArgs);
    }
    const url = this.apiCorpService.translateUrl(this.pathMonitorPerformance, {
      holding_id: holdingId,
      stage,
      report,
    });
    const params = {
      ...filter,
      system_type: this.typeProductionService.get(),
    };
    return this.apiCorpService.get<AnimalGroupPerformance[]>(url, params);
  }

  getMonitorPerformanceByPeriod({
    holdingId,
    stage,
    filter,
  }: GetMonitorPerformanceByPeriodArgs): Observable<AnimalGroupMonitorPerformanceByPeriod> {
    const url = this.apiCorpService.translateUrl(this.pathMonitorPerformanceByPeriod, {
      holding_id: holdingId,
      stage,
    });
    const params = {
      ...filter,
      system_type: this.typeProductionService.get(),
    };
    return this.apiCorpService.get<AnimalGroupMonitorPerformanceByPeriod>(url, params);
  }

  getMonitorPerformanceOpened(
    idHolding: string,
    filter: FilterMonitor,
  ): Observable<AnimalGroupMonitorOpenedPerformance[]> {
    const url = this.apiCorpService.translateUrl(this.pathMonitorOpenedPerformance, {
      holding_id: idHolding,
    });
    const params = {
      ...filter,
      system_type: this.typeProductionService.get(),
    };
    return this.apiCorpService.get<AnimalGroupMonitorOpenedPerformance[]>(url, params);
  }
}
