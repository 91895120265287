import { ReportTabDairyEnum } from '@agriness/corp-app/services';

export type SimulationType =
  | 'lactating_cows'
  | 'dry_matter'
  | 'date_lactating_cows'
  | 'date_dry_matter';

export type WalkthroughSimulationType = Record<SimulationType, string>;

export const simulationSuffixByLength: Record<number, string> = {
  1: 'title_single',
  2: 'title_double',
};

export const simulationByTab: Record<string, SimulationType[]> = {
  [ReportTabDairyEnum.EFFICIENCY]: ['lactating_cows', 'dry_matter'],
  [ReportTabDairyEnum.FAT]: ['lactating_cows'],
  [ReportTabDairyEnum.MUN]: [],
  [ReportTabDairyEnum.PROTEIN]: ['lactating_cows'],
  [ReportTabDairyEnum.SHIPPED]: ['lactating_cows'],
  [ReportTabDairyEnum.SSC]: [],
  [ReportTabDairyEnum.BC]: [],
};
