import { Search, systemType, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { Location, LocationFilter } from '../models/location.model';
import { LocationAbstractService } from './location-abstract.service';

@Injectable()
export class LocationService extends LocationAbstractService {
  private pathBase = 'holdings/{holding_id}/locations/';

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
  ) {
    super();
  }

  list(filters: LocationFilter): Observable<Search<Location>> {
    const url = this.apiCorpService.translateUrl(this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
    });
    const params = {
      ...filters,
      system_type: systemType[environment.typeProduction],
    };
    return this.apiCorpService.get<Search<Location>>(url, params);
  }
}
