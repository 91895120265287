import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { CorpAppComponent } from './corp-app.component';
import { CorpServicesModule } from './services/corp-services.module';
import { SharedModule } from './shared/shared.module';

// @dynamic
@NgModule({
  declarations: [CorpAppComponent],
  imports: [SharedModule, CorpServicesModule],
  exports: [CorpAppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CorpAppModule {}
