import { SectionModel } from '@agriness/ui';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class BasicRecordsSectionService {
  getSections(): SectionModel[] {
    const prefix = `/${environment.typeProduction}/settings/basic-records`;
    const sections: SectionModel[] = [
      {
        label: 'agriness.settings.basic_records.general',
        id: 'section-basic-records-general',
        routerLink: `${prefix}/general`,
      },
      // {
      //   label: 'agriness.settings.basic_records.supply',
      //   id: 'section-basic-records-sypply',
      //   routerLink: `${prefix}/supply`,
      // },
    ];
    return sections;
  }
}
