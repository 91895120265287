<corp-modal-form
  [title]="translationContext + '.title' | translate"
  [modalStatus]="modalStatus"
  [visible]="visible"
  [formValid]="ranges.valid"
  (closed)="onClosed()"
  (formSubmit)="onSubmit()"
  contentWidth="width: 100%;"
  *ngIf="ranges.length > 0"
>
  <div>
    <p class="subtitle">
      {{ translationContext + '.index-name' | translate }}
    </p>
    <ag-text-field class="name-input" [disabled]="true" [label]="configData.name"></ag-text-field>
  </div>

  <div>
    <p class="subtitle">
      {{ translationContext + '.values' | translate }}
    </p>

    <div class="columns">
      <div class="column is-11">
        <div class="columns" *ngFor="let rangeControl of ranges.controls; let i = index">
          <div class="column">
            <ag-number-field
              [group]="rangeControl"
              controlName="range"
              label="{{ translationContext + '.range' | translate }}"
              inputWidth="100%"
              [errorMessage]="
                rangeControl.get('range')?.valid
                  ? null
                  : ('agriness.settings.validation_required' | translate)
              "
              minValue="0"
            ></ag-number-field>
          </div>

          <div class="column">
            <ag-number-field
              [group]="rangeControl"
              controlName="percentage"
              inputWidth="100%"
              label="{{ translationContext + '.percentage' | translate }}"
              [errorMessage]="
                rangeControl.get('percentage')?.valid
                  ? ''
                  : ('agriness.settings.validation_required' | translate)
              "
            ></ag-number-field>
          </div>
          <div class="column is-1 px-0">
            <button class="btn btn-fab-primary mt-2 mr-1">
              <span class="icon icon-add-circle-outline" (click)="addConfigRange()"></span>
            </button>
            <button class="btn btn-fab-primary mt-2 mr-1" [disabled]="cantRemove()">
              <span class="icon icon-remove-circle-outline" (click)="remove(i)"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</corp-modal-form>
