<corp-modal-form
  [title]="translationContext + '.editor.title' | translate"
  [modalStatus]="modalStatus"
  [visible]="visible"
  [formValid]="tankForm.valid"
  (closed)="onClosed()"
  (formSubmit)="onSubmit()"
  contentWidth="width: 100%;"
>
  <div>
    <p class="subtitle">
      {{ translationContext + '.editor.subtitle' | translate }}
    </p>
  </div>

  <form>
    <div class="mb-2">
      <corp-farm-select
        class="form-field"
        [form]="tankForm"
        [inputWidth]="'100%'"
      ></corp-farm-select>
    </div>
    <div>
      <ag-text-field
        [formGroup]="tankForm"
        controlName="name"
        [errorMessage]="errorMessages.name"
        [label]="translationContext + '.editor.fields.name.label' | translate"
      >
      </ag-text-field>
    </div>
  </form>
</corp-modal-form>
