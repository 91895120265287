import { Component, Input, Inject } from '@angular/core';

import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { IndexTranslateService } from '@agriness/corp-app/shared/services/index-translate.service';
import { Target } from '@agriness/corp-app/services';

@Component({
  selector: 'ag-card-target',
  templateUrl: './ag-card-target.component.html',
  styleUrls: ['./ag-card-target.component.scss'],
})
export class AgCardTargetComponent {
  @Input() target: Target;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private indexTranslate: IndexTranslateService,
  ) {}

  getIndexName(target: Target): string {
    return this.indexTranslate.instant(
      target.index.key,
      ['agriness.performances', 'agriness.animal_group_list'],
      target.stage,
    );
  }

  getMeasurementUnit({ index: { measurement_unit } }: Target): string {
    if (measurement_unit == 'percentage') {
      const translated = this.t(`agriness.unit.${measurement_unit}`);
      return translated ? `(${translated})` : '';
    }
  }
}
