import { AgrinessTranslateModule } from '@agriness/services';
import { AgrinessUiModule } from '@agriness/ui';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';

import { SharedModule } from '../shared/shared.module';
import { PresenceCalendarComponent } from './presence-calendar/presence-calendar.component';
import { PresenceModalComponent } from './presence-modal/presence-modal.component';
import { PresenceComponent } from './presence.component';
import { ScheduleMonitorCardsComponent } from './schedule-monitor-cards/schedule-monitor-cards.component';

@NgModule({
  declarations: [
    PresenceComponent,
    PresenceModalComponent,
    PresenceCalendarComponent,
    ScheduleMonitorCardsComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    CalendarModule,
    HttpClientModule,
    AgrinessTranslateModule,
    DialogModule,
    SharedModule,
    AgrinessUiModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PresenceModule {}
