import {
  DecimalPointByDecimalSeparator,
  ThousandsPointByDecimalSeparator,
  DecimalSeparatorEnum,
} from '@agriness/services';
import { AgrinessTranslateService, TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Inject, Injectable } from '@angular/core';
import * as ImportedHighcharts from 'highcharts';
import { Options as HighchartsOptions } from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import ExportingModule from 'highcharts/modules/exporting';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import StockModule from 'highcharts/modules/stock';

export { Options as HighchartsOptions } from 'highcharts';

export type Highcharts = typeof ImportedHighcharts;

@Injectable()
export class HighchartsService {
  HighchartsInstance: Highcharts;
  private decimalSeparator = ',';
  private thousandsSep = '.';

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private translateService?: AgrinessTranslateService,
  ) {
    if (this.translateService) {
      const locale = this.translateService.getDecimalSeparator() as DecimalSeparatorEnum;
      if (locale) {
        this.decimalSeparator = DecimalPointByDecimalSeparator[locale];
        this.thousandsSep = ThousandsPointByDecimalSeparator[locale];
      }
    }
  }

  getHighchartsInstance(): Highcharts {
    if (!this.HighchartsInstance) {
      this.HighchartsInstance = ImportedHighcharts;
      ExportingModule(this.HighchartsInstance);
      HighchartsMore(this.HighchartsInstance);
      HighchartsNoData(this.HighchartsInstance);
      StockModule(this.HighchartsInstance);
      this.HighchartsInstance.setOptions(this.getGlobalOptions());
    }

    return this.HighchartsInstance;
  }

  /**
   * Short month labels usually exhibited
   * in charts that are linear and limited to one
   * year of interval
   */
  getShortMonths(): string[] {
    return this.getHighchartsInstance().getOptions().lang.shortMonths;
  }

  setSvgSymbolsToCustomRectangle(): void {
    // Define a custom symbol path
    // This function has to redraw a custom symbol for the graph
    // X and Y are the svg axes
    // W and H are the svg width and height
    // M and L within the return are Moveto and Lineto of the path svg
    this.HighchartsInstance.SVGRenderer.prototype.symbols.rectangle = (
      x: number,
      y: number,
      w: number,
      h: number,
    ) => ['M', x, y + h / 2, 'L', x + w, y + h / 2];
  }

  private getGlobalOptions(): HighchartsOptions {
    return {
      time: {
        // Default is 0, but we need to set
        // the current timezone for UTC based charts
        // (useUTC: true) that will exhibit dates,
        // otherwise we get a 3 hour (for GMT-3) displacement
        // for these charts
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      lang: {
        decimalPoint: this.decimalSeparator,
        thousandsSep: this.thousandsSep,
        shortMonths: [
          this.t('agriness.date.month.january.short'),
          this.t('agriness.date.month.february.short'),
          this.t('agriness.date.month.march.short'),
          this.t('agriness.date.month.april.short'),
          this.t('agriness.date.month.may.short'),
          this.t('agriness.date.month.june.short'),
          this.t('agriness.date.month.july.short'),
          this.t('agriness.date.month.august.short'),
          this.t('agriness.date.month.september.short'),
          this.t('agriness.date.month.october.short'),
          this.t('agriness.date.month.november.short'),
          this.t('agriness.date.month.december.short'),
        ],
        months: [
          this.t('agriness.date.month.january.long'),
          this.t('agriness.date.month.february.long'),
          this.t('agriness.date.month.march.long'),
          this.t('agriness.date.month.april.long'),
          this.t('agriness.date.month.may.long'),
          this.t('agriness.date.month.june.long'),
          this.t('agriness.date.month.july.long'),
          this.t('agriness.date.month.august.long'),
          this.t('agriness.date.month.september.long'),
          this.t('agriness.date.month.october.long'),
          this.t('agriness.date.month.november.long'),
          this.t('agriness.date.month.december.long'),
        ],
        weekdays: [
          this.t('agriness.date.week.long.sunday'),
          this.t('agriness.date.week.long.monday'),
          this.t('agriness.date.week.long.tuesday'),
          this.t('agriness.date.week.long.wednesday'),
          this.t('agriness.date.week.long.thursday'),
          this.t('agriness.date.week.long.friday'),
          this.t('agriness.date.week.long.saturday'),
        ],
      },
    };
  }
}
