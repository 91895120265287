<section class="section is-full">
  <div class="page-title mb-3">
    {{ titleKey | translate }}
  </div>

  <ng-container *ngIf="tabs.length > 1">
    <div class="tabs">
      <ul class="nav nav-tabs nav-inline mb-4" id="nav-tab">
        <li class="nav-item" *ngFor="let tab of tabs">
          <a class="nav-link" routerLinkActive="active" [id]="tab.router" [routerLink]="tab.router">
            {{ tab.translateKey | translate }}
          </a>
        </li>
      </ul>
    </div>

    <div class="tab-content" id="nav-content">
      <router-outlet></router-outlet>
    </div>
  </ng-container>

  <ng-container *ngIf="tabs.length === 1">
    <corp-animal-group-monitor-tab
      [animalGroupStatus]="tabs[0].router"
    ></corp-animal-group-monitor-tab>
  </ng-container>
</section>
