import { defaultFarmOrder, Farm, FarmFilter, ModalStatusEnum } from '@agriness/corp-app/services';
import { FarmService } from '@agriness/corp-app/services/farm/farm.service';
import { CorpFiltersContainerComponent } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.component';
import { CorpFiltersContainerService } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.service';
import {
  ActionEnum,
  CorpRecordTableComponent,
} from '@agriness/corp-app/shared/component/corp-record-table/corp-record-table.component';
import { SectionAbstractComponent } from '@agriness/corp-app/shared/component/section-abstract.component';
import {
  ReportFilter,
  ReportFilterQueryKeys,
} from '@agriness/corp-app/shared/model/report-filter.model';
import { PaginationRequest } from '@agriness/corp-app/shared/model/table.model';
import { TranslateInstant, TRANSLATE_INSTANT, TypeProductionService } from '@agriness/services';
import { TypeProductionEnum } from '@agriness/services';
import { SectionModel, SectionService } from '@agriness/ui';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

import { FarmEditorComponent } from './farm-editor/farm-editor.component';
import { farmsTableColumns } from './farm.model';

@Component({
  templateUrl: './farm.component.html',
  providers: [CorpFiltersContainerService.provider],
})
export class FarmComponent extends SectionAbstractComponent implements OnInit {
  @ViewChild('filtersContainer', { static: true }) reportFilter: CorpFiltersContainerComponent;
  @ViewChild('table', { static: true }) table: CorpRecordTableComponent<Farm>;
  @ViewChild('editor') farmEditor: FarmEditorComponent;

  translationContext = 'agriness.settings.farms';

  tableColumns = farmsTableColumns;
  defaultOrdering = defaultFarmOrder;
  availableActions = [ActionEnum.EDIT];
  farms: Farm[];
  totalRecords: number;
  rows = 10;

  editorModalVisible = false;
  editorModalStatus = ModalStatusEnum.DEFAULT;
  selectedFarm: Farm;

  error = false;
  loading = true;
  currentFilter: FarmFilter = {};
  filterEntity: keyof ReportFilter = 'farm';
  queryParamName: ReportFilterQueryKeys = 'farm_name';

  private verbose = true;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    protected sectionService: SectionService,
    private farmService: FarmService,
    private typeProduction: TypeProductionService,
  ) {
    super(sectionService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (
      this.typeProduction.get() !== TypeProductionEnum.DAIRY &&
      this.tableColumns.some(column => column.field === 'route.name')
    ) {
      this.tableColumns.splice(
        this.tableColumns.findIndex(column => column.field === 'route.name'),
        1,
      );
    }
  }

  filter(): void {
    this.table.reset();
  }

  filterReset(): void {
    this.reportFilter.onFilter();
  }

  onEditorClosed(): void {
    this.selectedFarm = null;
    this.editorModalVisible = false;
    this.editorModalStatus = ModalStatusEnum.DEFAULT;
  }

  onItemAction(obj: { action: ActionEnum; item: Farm }): void {
    switch (obj.action) {
      case ActionEnum.EDIT:
        this.selectedFarm = obj.item;
        this.editorModalVisible = true;
        break;
    }
  }

  onPageRequest(request: PaginationRequest): void {
    this.loading = true;
    this.updateCurrentFilter(request);
    this.loadFarms();
  }

  updateFarm(): void {
    if (this.selectedFarm) {
      const { id } = this.selectedFarm;
      const formValues = this.farmEditor.getFarmForm();
      this.editorModalStatus = ModalStatusEnum.LOADING;
      this.farmService
        .update(formValues, id)
        .pipe(
          first(),
          map(() => (this.editorModalStatus = ModalStatusEnum.SUCCESS)),
          catchError(({ error }) => {
            this.editorModalStatus = ModalStatusEnum.FAILED;
            return throwError(error);
          }),
        )
        .subscribe(() => this.filter());
    }
  }

  protected getSections(): SectionModel[] {
    return [];
  }

  private loadFarms(): void {
    this.farmService
      .list(this.currentFilter)
      .pipe(
        first(),
        map(({ count, results }) => {
          this.totalRecords = count;
          this.loading = false;
          this.farms = results;
        }),
        catchError(error => {
          this.error = true;
          return throwError(error);
        }),
      )
      .subscribe();
  }

  private updateCurrentFilter(request: PaginationRequest): void {
    const name = this.reportFilter.getCurrentFilterInQueryFormat().farm_name;
    const producer = this.reportFilter.getCurrentFilterInQueryFormat().producer_id;
    const region = this.reportFilter.getCurrentFilterInQueryFormat().region_id;
    const technician = this.reportFilter.getCurrentFilterInQueryFormat().technician_id;

    const farmOnlyFilters = {
      technician: this.fixFormatData(technician),
      producer: this.fixFormatData(producer),
      region: this.fixFormatData(region),
    };

    this.currentFilter = {
      ...this.currentFilter,
      ...request,
      name,
      verbose: this.verbose,
      ...farmOnlyFilters,
    };
  }

  private fixFormatData(data: string): string {
    return data
      ? data
          .split(',')
          .filter(e => e)
          .toString()
      : null;
  }
}
