import { TargetService } from '@agriness/corp-app/services';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FarmService } from '../services/farm/farm.service';
import { ProducerService } from '../services/producer/producer.service';
import { RegionService } from '../services/region/region.service';
import { RoutesService } from '../services/routes/routes.service';
import { TechnicianService } from '../services/technician/technician.service';
import { SharedModule } from '../shared/shared.module';
import { BasicRecordsSectionComponent } from './basic-records-section/basic-records-section.component';
import { BasicRecordsSectionService } from './basic-records-section/basic-records-section.service';
import { BasicRecordsGeneralComponent } from './basic-records-section/general/general.component';
import { ProducerEditorComponent } from './basic-records-section/general/producer/producer-editor/producer-editor.component';
import { ProducerComponent } from './basic-records-section/general/producer/producer.component';
import { RegionEditorComponent } from './basic-records-section/general/regions/region-editor/region-editor.component';
import { RegionsComponent } from './basic-records-section/general/regions/regions.component';
import { RouteEditorComponent } from './basic-records-section/general/routes/route-editor/route-editor.component';
import { RoutesComponent } from './basic-records-section/general/routes/routes.component';
import { TankEditorComponent } from './basic-records-section/general/tanks/tank-editor/tank-editor.component';
import { TanksComponent } from './basic-records-section/general/tanks/tanks.component';
import { TechnicianEditorComponent } from './basic-records-section/general/technicians/technician-editor/technician-editor.component';
import { TechniciansComponent } from './basic-records-section/general/technicians/technicians.component';
import { DeletionTableComponent } from './deletion-table/deletion-table.component';
import { FarmSectionComponent } from './farm-section/farm-section.component';
import { FarmEditorComponent } from './farm-section/farm/farm-editor/farm-editor.component';
import { FarmComponent } from './farm-section/farm/farm.component';
import { PaymentRuleSectionComponent } from './payment-rule-section/payment-rule-section.component';
import { PaymentRuleComponent } from './payment-rule-section/payment-rule/payment-rule.component';
import { RuleEditorComponent } from './payment-rule-section/payment-rule/rule-editor/rule-editor.component';
import { IndexConfiguratorComponent } from './payment-rule-section/payment-rule/rule-editor/step-config/index-configurator/index-configurator.component';
import { StepConfigComponent } from './payment-rule-section/payment-rule/rule-editor/step-config/step-config.component';
import { StepMainComponent } from './payment-rule-section/payment-rule/rule-editor/step-main/step-main.component';
import { StepRulesComponent } from './payment-rule-section/payment-rule/rule-editor/step-rules/step-rules.component';
import { PresenceSectionComponent } from './presence-section/presence-section.component';
import { PresenceSectionService } from './presence-section/presence-section.service';
import { ChecklistEditorComponent } from './presence-section/tools/checklists-tab/checklist-editor/checklist-editor.component';
import { ChecklistsTabComponent } from './presence-section/tools/checklists-tab/checklists-tab.component';
import { ToolsComponent } from './presence-section/tools/tools.component';
import { ProductionParamsSectionComponent } from './production-params-section/production-params-section.component';
import { ProductionParamsSectionService } from './production-params-section/production-params-section.service';
import { ProductionTabComponent } from './production-params-section/production/production-tab/production-tab.component';
import { ProductionComponent } from './production-params-section/production/production.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';
import { TargetSectionComponent } from './target-section/target-section.component';
import { TargetEditorComponent } from './target-section/targets/target-editor/target-editor.component';
import { TargetListComponent } from './target-section/targets/target-list/target-list.component';
import { TargetsComponent } from './target-section/targets/targets.component';
import { TargetSectionComponent as TargetSectionComponentV2 } from './targets-in-dev/target-section.component';
import { TargetConfigEndComponent } from './targets-in-dev/targets/targets-tab/target-editor/target-config-end/target-config-end.component';
import { TargetConfigGeneralDataComponent } from './targets-in-dev/targets/targets-tab/target-editor/target-config-general-data/target-config-general-data.component';
import { TargetConfigRulesComponent } from './targets-in-dev/targets/targets-tab/target-editor/target-config-rules/target-config-rules.component';
import { TargetEditorComponent as TargetEditorComponentV2 } from './targets-in-dev/targets/targets-tab/target-editor/target-editor.component';
import { TargetViewComponent } from './targets-in-dev/targets/targets-tab/target-view/target-view.component';
import { TargetsTabComponent } from './targets-in-dev/targets/targets-tab/targets-tab.component';
import { TargetsComponent as TargetComponentV2 } from './targets-in-dev/targets/targets.component';

@NgModule({
  imports: [ReactiveFormsModule, SettingsRoutingModule, SharedModule],
  providers: [
    RegionService,
    TargetService,
    TechnicianService,
    ProducerService,
    FarmService,
    RoutesService,
    BasicRecordsSectionService,
    ProductionParamsSectionService,
    PresenceSectionService,
  ],
  declarations: [
    SettingsComponent,
    BasicRecordsSectionComponent,
    BasicRecordsGeneralComponent,
    FarmSectionComponent,
    PaymentRuleSectionComponent,
    TargetSectionComponent,
    RegionEditorComponent,
    RegionsComponent,
    TargetComponentV2,
    TargetEditorComponentV2,
    TargetsTabComponent,
    TargetConfigEndComponent,
    TargetConfigGeneralDataComponent,
    TargetConfigRulesComponent,
    TechniciansComponent,
    TechnicianEditorComponent,
    ProducerComponent,
    ProducerEditorComponent,
    DeletionTableComponent,
    FarmComponent,
    FarmEditorComponent,
    RoutesComponent,
    RouteEditorComponent,
    TanksComponent,
    TankEditorComponent,
    PaymentRuleComponent,
    RuleEditorComponent,
    StepMainComponent,
    StepRulesComponent,
    StepConfigComponent,
    IndexConfiguratorComponent,
    ProductionParamsSectionComponent,
    ProductionComponent,
    ProductionTabComponent,
    PresenceSectionComponent,
    ToolsComponent,
    ChecklistsTabComponent,
    ChecklistEditorComponent,
    TargetViewComponent,
    TargetEditorComponent,
    TargetsComponent,
    TargetSectionComponentV2,
    TargetListComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SettingsModule {}
