import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ag-filter-bar-action',
  templateUrl: './ag-filter-bar-action-component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgFilterBarActionComponent {
  @Output() clearFilter = new EventEmitter();
  @Output() rollbackFilter = new EventEmitter();
  @Output() isDropdownActive = false;
  @Input() hasError = false;

  cleanFilter() {
    this.clearFilter.emit();
  }

  rollbackDefaultFilter() {
    this.rollbackFilter.emit();
  }
}
