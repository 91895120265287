export const deprecatedColors = {
  greenSuccess: '#3ea900',
  yellowAttention: '#ffc342',
  redDanger: '#ff2d2d',
  blue1: '#ecf8ff',
  blue2: '#d4eefe',
  blue3: '#a0cef8',
  blue4: '#4380e0',
  blue5: '#205fd4',
  blue6: '#1145b4',
  blue7: '#063097',
  white: '#fff',
  grey1: '#f2f2f2',
  grey2: '#d1d7db',
  grey3: '#8f959c',
  grey4: '#595c5e',
  grey5: '#212325',
  purple1: '#e8ccf6',
  purple2: '#ad88c4',
  purple3: '#7e5b99',
  purple4: '#542c75',
  pink1: '#ffd8ed',
  pink2: '#ed85b0',
  pink3: '#cb4777',
  pink4: '#ae0536',
  violet1: '#ebdfff',
  violet2: '#be99ff',
  violet3: '#8b4fe0',
  violet4: '#5600a6',
  aqua1: '#d5fdfd',
  aqua2: '#3bd7d7',
  aqua3: '#22b1b1',
  aqua4: '#018787',
  green1: '#d1ffa3',
  green2: '#9cd70f',
  green4: '#007800',
  orange1: '#fff49e',
  orange3: '#ff8712',
  orange4: '#ff6000',
  brown1: '#e8ba90',
  brown2: '#a47051',
  brown3: '#744730',
  brown4: '#572712',
  degradeAgrinessLeft: '#063097',
  degradeAgrinessRight: '#4380e0',
  degradeAgriness: 'linear-gradient(to right, #063097, #4380e0)',
  degradeGreyLeft: '#212325',
  degradeGreyRight: '#6f7479',
  degradeGrey: 'linear-gradient(to right, #212325, #6f7479)',
  degradeIlustrationTop: '#50d4ff',
  degradeIlustrationMiddle: '#1253b4',
  degradeIlustrationBottom: '#003483',
  degradeIlustration: 'linear-gradient(to top left, #50d4ff, #1253b4 55%, #003483)',
  degradePurpleLeft: '#542c75',
  degradePurpleRight: '#7e5b99',
  degradePurple: 'linear-gradient(to right, #542c75, #7e5b99)',
  degradePinkLeft: '#ae0536',
  degradePinkRight: '#cb4777',
  degradePink: 'linear-gradient(to right, #ae0536, #cb4777)',
  degradeVioletLeft: '#5600a6',
  degradeVioletRight: '#8b4fe0',
  degradeViolet: 'linear-gradient(to right, #5600a6, #8b4fe0)',
  degradeAquaLeft: '#018787',
  degradeAquaRight: '#22b1b1',
  degradeAqua: 'linear-gradient(to right, #018787, #22b1b1)',
  degradeGreenLeft: '#007800',
  degradeGreenRight: '#3ea900',
  degradeGreen: 'linear-gradient(to right, #007800, #3ea900)',
  degradeOrangeLeft: '#ff6000',
  degradeOrangeRight: '#ff8712',
  degradeOrange: 'linear-gradient(to right, #ff6000, #ff8712)',
  degradeBrownLeft: '#572712',
  degradeBrownRight: '#744730',
  degradeBrown: 'linear-gradient(to right, #572712, #744730)',
  transparentRed: 'rgba(255, 45, 45, 0.1)',
  transparentGreen: 'rgba(62, 169, 0, 0.1)',
  transparentYellow: 'rgba(255, 195, 66, 0.1)',
  transparentOrange: 'rgba(255, 135, 18, 0.1)',
};
