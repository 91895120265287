<div class="row mb-4" [hidden]="!hasData() && !typeFeedback" [attr.data-testid]="report">
  <div class="col">
    <div class="section-header d-flex align-items-center">
      <span>{{ sectionName | translate }}</span>
    </div>

    <ag-feedback [type]="typeFeedback"></ag-feedback>

    <ng-container *ngIf="!typeFeedback">
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="card-title d-flex align-items-center" data-testid="card-title">
            {{ 'agriness.zootechnical_indexes_by_week' | translate }}
          </div>
          <ag-indexes-table
            [columns]="columns"
            [rows]="performances"
            [cellTemplate]="cellTemplate"
            [headerValueTemplate]="headerValueTemplate"
            indexNameTranslatePrefix="agriness.monitor.performances."
          >
            <ng-template #headerValueTemplate let-indexes="indexes">
              <p *ngFor="let index of indexes" class="text is-small" data-testid="row-header-label">
                {{ headerIndexDescription[index.index_name] }}:
                <span class="font-weight-bold">
                  <ag-format
                    [value]="index.performance.index_value"
                    [measurementUnit]="index.measurement_unit"
                    [decimalPlaces]="index.decimal_places"
                    data-testid="row-header-value"
                  ></ag-format>
                </span>
                <span
                  *ngIf="index.performance.target_value != null"
                  class="has-text-grey-3"
                  data-testid="row-header-label"
                >
                  ( {{ 'agriness.target' | translate | lowercase }}:
                  <ag-format
                    [value]="index.performance.target_value"
                    [measurementUnit]="index.measurement_unit"
                    [decimalPlaces]="index.decimal_places"
                    data-testid="row-header-value"
                  ></ag-format>
                  )
                </span>
              </p>
            </ng-template>

            <ng-template #cellTemplate let-values="values" let-indexes="indexes">
              <p class="text">
                <ng-container *ngFor="let value of values; let last = last; let i = index">
                  <ag-format
                    [value]="value.index_value"
                    [measurementUnit]="indexes[i].measurement_unit"
                    [decimalPlaces]="indexes[i].decimal_places"
                    data-testid="column-value"
                  ></ag-format>
                  <ng-container *ngIf="values.length == 1 && value.target_value != null">
                    (
                    <ag-format
                      [value]="value.target_value"
                      [measurementUnit]="indexes[i].measurement_unit"
                      [decimalPlaces]="indexes[i].decimal_places"
                    ></ag-format>
                    )
                  </ng-container>
                  {{ last ? '' : ' / ' }}
                </ng-container>
              </p>
            </ng-template>
          </ag-indexes-table>
        </div>
      </div>
    </ng-container>
  </div>
</div>
