import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DndModule } from 'ngx-drag-drop';
import { CheckboxModule } from 'primeng/checkbox';

import { SharedModule } from '../../common/shared.module';
import { AgPopoverModule } from '../popover/ag-popover.module';
import { AgToggleColumnComponent } from './ag-toggle-column.component';

@NgModule({
  declarations: [AgToggleColumnComponent],
  exports: [AgToggleColumnComponent],
  imports: [SharedModule, FormsModule, DndModule, CheckboxModule, AgPopoverModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgToggleColumnModule {}
