import { AnimalGroupMortalityValueAccumulated } from '@agriness/corp-app/services';
import { TableColumn } from '@agriness/ui';

export const PREFIX_MORTALITY = 'agriness.sanity.table.total_mortality.';
export const PREFIX_ELIMINATED = 'agriness.sanity.table.eliminated.';

export enum IndexDisplayType {
  CHART = 'CHART',
  TABLE = 'TABLE',
}

export const displayTypeIcon: { [k in IndexDisplayType]: string } = {
  [IndexDisplayType.CHART]: 'icon-report1',
  [IndexDisplayType.TABLE]: 'icon-apps',
};

export interface SanityItemData {
  displayType: IndexDisplayType;
  downloadFilename: string;
  byDayChartData: AnimalGroupMortalityValueAccumulated[];
  byDayTableData: TableData;
}

export interface SanityDataByType {
  totalMortality: SanityItemData;
  eliminated: SanityItemData;
}

export interface TableData {
  data: AnimalGroupMortalityValueAccumulatedTable[];
  columns: TableColumn[];
}

export interface AnimalGroupMortalityValueAccumulatedTable
  extends AnimalGroupMortalityValueAccumulated {
  period: number;
}
