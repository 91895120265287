import { ApiAbstractService, BaseApiAbstractService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class ApiS4FarmService extends ApiAbstractService {
  constructor(baseApiService: BaseApiAbstractService) {
    super(baseApiService, environment.apiS4Farm);
  }
}
