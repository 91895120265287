<section>
  <div class="title">
    {{
      !isEditing
        ? ('agriness.diet_selection.title.new_diet' | translate)
        : ('agriness.diet_selection.title.edit_diet' | translate)
    }}
  </div>
  <div class="form">
    <div class="form-group">
      <ag-select
        class="select-age"
        [group]="dietForm"
        controlName="age"
        [items$]="options.age"
        [isDisabled]="!isEditing"
        [defaultLabel]="'agriness.diet_selection.ages' | translate"
      ></ag-select>
      <label *ngIf="!isEditing; else labelOnEditing" class="control-label label-disabled">
        {{ 'agriness.diet_selection.select_age' | translate }}
      </label>
      <ng-template #labelOnEditing>
        <label class="control-label">
          {{ 'agriness.diet_selection.select_age' | translate }}
        </label>
      </ng-template>
    </div>
    <diet-selection-autocomplete
      data-testid="form-proposed-diet"
      [group]="dietForm"
      controlName="proposed_diet"
      [suggestions]="diets"
      (completing)="searchDiets($event)"
      [placeholder]="'agriness.diet_selection.add_diet' | translate"
    ></diet-selection-autocomplete>
    <ag-input
      data-testid="form-comment"
      [group]="dietForm"
      controlName="comments"
      [placeholder]="'agriness.diet_selection.add_comment' | translate"
    >
    </ag-input>
  </div>
</section>
