import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { WeanToFinishComponent } from './wean-to-finish.component';

@NgModule({
  imports: [ReactiveFormsModule, SharedModule],
  declarations: [WeanToFinishComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WeanToFinishModule {}
