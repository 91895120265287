import { Link, LinkData } from '@agriness/corp-app/shared/model/link-builder.model';
import { TypeProductionEnum, TypeProductionService } from '@agriness/services';
import { Injectable } from '@angular/core';

/**
 * Link builder service
 *
 * This service has methods to create standard links that are usually used in the corp cards
 */
@Injectable({ providedIn: 'root' })
export class LinkBuilderService {
  typeProduction: TypeProductionEnum;

  constructor(private typeProductionService: TypeProductionService) {
    this.typeProduction = this.typeProductionService.get();
  }

  /**
   * Builds a standard URL for cards,
   *
   * Non standard urls can be passed as [additionalPath]
   */
  build({ stage, section, report, queryParams, additionalPath }: LinkData): Link {
    const addPath = additionalPath?.reduce((acc, path) => `${acc}${path}/`, '/') ?? '';
    const url = `/${this.typeProduction}/${stage}/${section}/${report}${addPath}`;

    return { url, queryParams };
  }
}
