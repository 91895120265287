import { TargetStatus } from '@agriness/ui/component/target/target.model';
import { StatusItems } from '@agriness/ui/component/status/status.model';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { targetTypes } from '@agriness/ui/component/target/target-representation/targetTypes';
import { TargetRepresentationType } from '@agriness/corp-app/services';

@Component({
  selector: 'app-ag-table-target-representation',
  templateUrl: './ag-table-target-representation.component.html',
  styleUrls: ['./ag-table-target-representation.component.scss'],
})
export class AgTableTargetRepresentationComponent implements OnInit, OnChanges {
  @Input() target?: TargetRepresentationType;
  @Input() items: StatusItems = [];

  statusClass: { [k in TargetStatus]: string } = {
    [TargetStatus.ATTENTION]: 'status-attention',
    [TargetStatus.BAD]: 'status-bad',
    [TargetStatus.GOOD]: 'status-good',
  };

  defaultStatusClass = 'status-default';
  statusItems = [];
  iconSize = 16;

  ngOnInit(): void {
    this.updateStatusItems();
  }

  ngOnChanges(): void {
    this.updateStatusItems();
  }

  private updateStatusItems() {
    if (this.items?.length) {
      this.statusItems = this.items.map(item => ({ percentage: 100, ...item }));
    } else {
      this.statusItems = targetTypes[this.target];
    }
  }
}
