import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';

import { TargetData } from '../target.model';
import { TargetRepresentationConfig } from './representation-types/target-representation-config.service';
import { TargetItem } from './target-subtitles.model';

@Component({
  selector: 'ag-target-subtitles',
  templateUrl: './ag-target-subtitles.component.html',
  styleUrls: ['./ag-target-subtitles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgTargetSubtitlesComponent implements OnInit, OnChanges {
  @Input() targetData: TargetData;
  @Input() showMeasurementUnit: boolean;

  target: TargetItem[];

  constructor(private targetRepresentationConfig: TargetRepresentationConfig) {}

  ngOnInit(): void {
    this.loadTarget();
  }

  ngOnChanges(): void {
    this.loadTarget();
  }

  loadTarget() {
    this.target = this.targetRepresentationConfig.calculate(
      this.targetData.representation_type,
      this.targetData,
    );
  }
}
