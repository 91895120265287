import { TargetDetail, TargetGroup, TargetIndex } from '@agriness/corp-app/services';

export interface MainForm {
  id?: string;
  stage?: string;
  target_index?: TargetIndex;
  period_type?: string;
  target_group: string;
  target_detail?: TargetDetail[];
}

export interface PatchForm {
  id: string;
  target_detail: TargetDetail[];
  enabled: boolean;
}

export interface TargetConfigDataForm {
  target_group: TargetGroup;
  index: string;
}

export interface TargetConfigRulesForm {
  id: string;
}
export interface Stage {
  id: string;
  name: string;
  system_type: string;
}

export enum PeriodTypeEnum {
  GENERAL = 'GENERAL',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}

export enum TargetGroupsEnum {
  PRODUCTION = 'production',
  NUTRITION = 'nutrition',
  AMBIENCE = 'ambience',
  SANITY = 'sanity',
}

export enum ModalStatusEnum {
  FAILED = 'FAILED',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  DEFAULT = 'DEFAULT',
}
