import { Search, TypeProductionService, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { Site, SiteFilter } from '../models/site.model';

@Injectable()
export class SiteService {
  private pathBase = 'holdings/{holding_id}/sites/';
  private defaultPageSize = 10;

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {}

  list(filter: SiteFilter): Observable<Search<Site>> {
    const url = this.apiCorpService.translateUrl(this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
    });
    const params = {
      page: 1,
      page_size: this.defaultPageSize,
      ...filter,
      system_type: this.typeProductionService.get(),
    };
    return this.apiCorpService.get<Search<Site>>(url, params);
  }
}
