<section class="section is-full">
  <ag-feedback [type]="typeFeedback"> </ag-feedback>

  <div [class.d-none]="showLookerPanel">
    <div class="row mb-3">
      <div class="col">
        <div class="page-title d-flex align-items-center">
          {{ 'agriness.report.quadrants' | translate }}
        </div>
      </div>
    </div>
    <!-- /.row -->

    <corp-report-filters
      #reportFilter
      [stage]="stage"
      [availableFilters]="filters"
      [periodDefaultValue]="reportFilterPeriodDefaultValue"
      (filter)="filter()"
      (ready)="filter()"
      class="mb-3"
    >
    </corp-report-filters>

    <ag-feedback [type]="feedbackTypeScreen"></ag-feedback>

    <section
      *ngIf="!feedbackTypeScreen"
      class="section card shadow-sm"
      [ngClass]="{ 'flex-grow-1': displayType === IdentifyDisplayType.QUADRANT }"
    >
      <header class="header-content columns is-variable is-1 is-mobile is-multiline">
        <div class="column is-full-mobile">
          <span class="title font-weight-bold mr-2">
            {{ sectionTitle | translate }}
          </span>

          <button
            type="button"
            class="btn btn-ghost has-round-hover"
            (click)="onNextDisplayTypeClick()"
          >
            <span class="icon" [ngClass]="nextDisplayTypeIcon()"></span>
          </button>

          <corp-identify-custom-interval
            *ngIf="showCustomInterval()"
            [quadrantsAxes]="defaultQuadrantsAxes"
            [stage]="stage"
            (visualize)="changeCustomInterval($event)"
          >
          </corp-identify-custom-interval>

          <button
            *ngIf="displayType === IdentifyDisplayType.TABLE"
            class="btn btn-ghost has-round-hover"
            (click)="dt.exportCSV()"
          >
            <span class="icon is-16 icon-csv"></span>
          </button>
        </div>

        <div
          *ngIf="displayType === IdentifyDisplayType.TABLE"
          class="column is-one-fifth-fullhd is-one-quarter-desktop is-half-mobile"
        >
          <div class="form-group">
            <label class="control-label bg-white">
              {{ 'agriness.identify.quadrant_select.label' | translate }}
            </label>
            <ag-multiselect
              [group]="quadrantFilterForm"
              controlName="quadrant"
              msgItemsSelects="agriness.identify.quadrant_select.multi_selected"
              [items$]="quadrantOptions"
              (selectValueFilter)="updateSelectedQuadrants($event)"
              defaultLabel="agriness.identify.quadrant_select.all"
            >
            </ag-multiselect>
          </div>
        </div>

        <div class="column is-one-fifth-fullhd is-one-quarter-desktop is-half-mobile">
          <div class="form-group">
            <label class="control-label">
              {{ 'agriness.identify.quadrant.report' | translate }}
            </label>
            <p-dropdown
              [(ngModel)]="selectedReport"
              [options]="reports"
              (onChange)="changeReport($event)"
              data-testid="select-indicators"
            >
              <ng-template let-item pTemplate="selectedItem">
                <span class="{{ item.label }}">{{
                  'agriness.report.' + item.label | translate
                }}</span>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <span class="{{ item.label }}" [attr.data-testid]="item.label">{{
                  'agriness.report.' + item.label | translate
                }}</span>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </header>

      <ag-feedback
        [type]="feedbackTypeSection"
        [position]="FeedbackPosition.CENTER_PARENT"
      ></ag-feedback>

      <div
        [hidden]="displayType !== IdentifyDisplayType.QUADRANT"
        [ngClass]="
          feedbackTypeSection || displayType !== IdentifyDisplayType.QUADRANT
            ? 'invisible'
            : 'd-flex flex-grow-1'
        "
      >
        <corp-identify-quadrants
          class="flex-grow-1"
          [stage]="stage"
          [quadrantAnimalGroupList]="quadrantAnimalGroupList"
          [selectedReport]="selectedReport"
          [groupQuadrants]="currentFilter.last_animal_group > 1"
        ></corp-identify-quadrants>
      </div>

      <div
        [hidden]="displayType !== IdentifyDisplayType.TABLE"
        [ngClass]="feedbackTypeSection ? 'invisible' : ''"
      >
        <corp-identify-producer-list
          #dt
          [stage]="stage"
          [quadrantAnimalGroupList]="quadrantAnimalGroupList"
          [selectedReport]="selectedReport"
          [quadrantOptions]="quadrantOptions.getValue()"
          [selectedQuadrants]="selectedQuadrants"
          [translateParams]="translateParams"
          [groupQuadrants]="currentFilter.last_animal_group > 1"
        ></corp-identify-producer-list>
      </div>
    </section>
  </div>
</section>
