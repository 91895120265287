import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MainForm, PeriodTypeEnum } from '../target-editor-form.model';

@Component({
  selector: 'target-config-end',
  templateUrl: './target-config-end.component.html',
  styleUrls: ['./target-config-end.component.scss'],
})
export class TargetConfigEndComponent implements OnInit {
  @Input() formData: BehaviorSubject<MainForm> = null;
  stage: string;
  formDataSubscribed: MainForm = null;
  cols = [];

  ngOnInit(): void {
    this.stage = window.location.pathname.split('/')[5];

    this.formData.subscribe(res => {
      this.formDataSubscribed = res;
    });

    this.cols = [
      { field: 'min_allowed', header: 'agriness.settings.targets.editor.table.min_allowed' },
      {
        field: 'min_recommended',
        header: 'agriness.settings.targets.editor.table.min_recommended',
      },
      { field: 'target_value', header: 'agriness.settings.targets.editor.table.target_value' },
      {
        field: 'value_variation_rate',
        header: 'agriness.settings.targets.editor.table.value_variation_rate',
      },
      {
        field: 'max_recommended',
        header: 'agriness.settings.targets.editor.table.max_recommended',
      },
      { field: 'max_allowed', header: 'agriness.settings.targets.editor.table.max_allowed' },
    ];

    if (this.formDataSubscribed.period_type == PeriodTypeEnum.DAILY) {
      this.cols.unshift({
        field: 'period',
        header: 'agriness.settings.targets.editor.table.daily',
      });
    }

    if (this.formDataSubscribed.period_type == PeriodTypeEnum.WEEKLY) {
      this.cols.unshift({
        field: 'period',
        header: 'agriness.settings.targets.editor.table.weekly',
      });
    }
  }

  getStatusIndicator(field: string): string {
    if (field == 'min_allowed' || field == 'max_allowed') {
      return 'bad';
    } else if (field == 'min_recommended' || field == 'max_recommended') {
      return 'attention';
    } else {
      return 'good';
    }
  }
}
