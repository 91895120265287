import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { ChevronButtonComponent } from './chevron-button.component';
import { AgrinessIconModule } from '../icon/agriness-icon.module';

@NgModule({
  declarations: [ChevronButtonComponent],
  imports: [SharedModule, AgrinessIconModule],
  exports: [ChevronButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChevronButtonModule {}
