import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { ValueFormatStyle } from '../../../pipe/value-format/value-format.model';
import { Header, HeaderValue } from '../status.model';

@Component({
  selector: 'ag-status-header',
  templateUrl: './ag-status-header.component.html',
})
export class AgStatusHeaderComponent implements OnInit, OnChanges {
  @Input() header: Header;

  defaultHeaderValue: HeaderValue = {
    value: '-',
    measurementUnit: ValueFormatStyle.TEXT,
    decimalPlaces: 0,
  };

  headerItems = [];
  constructor() {}

  ngOnInit() {
    this.updateHeaderItems();
  }

  ngOnChanges() {
    this.updateHeaderItems();
  }

  private updateHeaderItems() {
    if (this.header.values.length) {
      this.headerItems = this.header.values;
    } else {
      this.headerItems = [this.defaultHeaderValue, this.defaultHeaderValue];
    }
  }
}
