<agriness-card [contentTemplate]="contentTemplate"></agriness-card>

<ng-template #contentTemplate>
  <div class="full-width d-flex content-container">
    <div class="title">
      <span>{{ translationContext + '.form.adjustment_weight' | translate }}</span>
    </div>
    <div class="form-section">
      <form>
        <div class="full-width">
          <div class="form-field">
            <ag-number-field
              [group]="caajWeightForm"
              controlName="adjustment_weight"
              inputWidth="100%"
              [minValue]="0"
              [label]="translationContext + '.form.adjustment_weight_label' | translate"
            >
            </ag-number-field>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="full-width content-container" *ngIf="productionType === isPoultry">
    <div class="title">
      <span>{{ translationContext + '.form.sexing_adjustment_weight' | translate }}</span>
    </div>
    <div class="form-section d-flex">
      <form class="full-width">
        <div class="full-width d-flex">
          <div class="is-one-third form-field">
            <ag-number-field
              [group]="sexingCaajWeightForm"
              controlName="adjustment_factor_male"
              inputWidth="100%"
              [minValue]="0"
              [label]="translationContext + '.form.adjustment_factor_male_label' | translate"
              class="is-one-third"
            >
            </ag-number-field>
          </div>

          <div class="is-one-third form-field">
            <ag-number-field
              [group]="sexingCaajWeightForm"
              controlName="adjustment_factor_female"
              inputWidth="100%"
              [minValue]="0"
              [label]="translationContext + '.form.adjustment_factor_female_label' | translate"
            >
            </ag-number-field>
          </div>
          <div class="is-one-third form-field">
            <ag-number-field
              [group]="sexingCaajWeightForm"
              controlName="adjustment_factor_mix"
              inputWidth="100%"
              [minValue]="0"
              [label]="translationContext + '.form.adjustment_factor_mix_label' | translate"
            >
            </ag-number-field>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="full-width content-container" *ngIf="productionType === isPoultry">
    <div class="title">
      <span>{{ translationContext + '.form.goal_slaughter_age' | translate }}</span>
    </div>
    <div class="form-section d-flex">
      <form class="full-width">
        <div class="full-width d-flex">
          <div class="is-one-third form-field">
            <ag-number-field
              [group]="goalSlaughterAgeForm"
              controlName="goal_slaughter_age"
              inputWidth="100%"
              [minValue]="0"
              [label]="translationContext + '.form.goal_slaughter_age' | translate"
              class="is-one-third"
            >
            </ag-number-field>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="button-container full-width">
    <div class="divider"></div>
    <div class="button-row full-width d-flex">
      <div class="button-box">
        <button class="btn btn-primary" (click)="saveForm()" [disabled]="caajWeightForm.invalid">
          {{ translationContext + '.form.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
