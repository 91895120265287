import {
  AnimalGroupAbstractService,
  QuadrantAnimalGroup,
  QuadrantsAxes,
  ReportDashboardAbstractService,
  SearchFilterQuadrantAnimalGroup,
} from '@agriness/corp-app/services';
import { LookerPanelService } from '@agriness/corp-app/services/looker/looker-panel.service';
import { LookerPanel } from '@agriness/corp-app/services/models/looker-panel.model';
import { StageEnum, TypeProductionService, UserStorageService } from '@agriness/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { PropertyName, SiteByStage } from '@agriness/services/analytics/analytics.model';
import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import { FeedbackEnum, FeedbackPosition } from '@agriness/ui';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import {
  getIndexesFromReportName,
  QuadrantAxisName,
  reportInfoByStage,
} from '../../model/identify.model';
import {
  PeriodInterval,
  PeriodUnit,
  ReportFilterPeriodDefaultValue,
  ReportFilterType,
} from '../../model/report-filter.model';
import { CorpReportFiltersComponent } from '../corp-report-filters/corp-report-filters.component';
import { LoaderUserPreference } from '../loader-user-preference';
import { displayTypeIcon, IdentifyDisplayType, Interval } from './corp-identify.model';

@Component({
  selector: 'corp-identify',
  templateUrl: './corp-identify.component.html',
})
export class CorpIdentifyComponent extends LoaderUserPreference implements OnInit {
  @Input() stage: StageEnum;
  @ViewChild('reportFilter', { static: true }) reportFilter: CorpReportFiltersComponent;
  showLookerPanel = true;
  typeFeedback = FeedbackEnum.LOADING;

  IdentifyDisplayType = IdentifyDisplayType;
  FeedbackPosition = FeedbackPosition;
  reportFilterPeriodDefaultValue: ReportFilterPeriodDefaultValue = [
    PeriodInterval.LAST,
    PeriodUnit.YEARS,
    1,
  ];

  rootReport: string;
  defaultReport: string;
  filters: ReportFilterType[];
  sectionTitle = 'agriness.identify.quadrant.title';

  currentFilter: SearchFilterQuadrantAnimalGroup = {};
  reports: SelectItem[];
  selectedReport: string;
  customInterval?: Interval;
  defaultQuadrantsAxes: QuadrantsAxes;
  translateParams: QuadrantAxisName;
  quadrantAnimalGroupList: QuadrantAnimalGroup;
  displayType = IdentifyDisplayType.QUADRANT;
  feedbackTypeInterval = FeedbackEnum.LOADING;
  feedbackTypeScreen = FeedbackEnum.LOADING;
  feedbackTypeSection = FeedbackEnum.NOT_RESULT;

  quadrantFilterForm: FormGroup;
  quadrantOptions = new BehaviorSubject([] as { id: string; name: string }[]);
  selectedQuadrants: string[] = [];
  cards: LookerPanel[];

  constructor(
    protected userStorageService: UserStorageService,
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private reportService: ReportDashboardAbstractService,
    private animalGroupService: AnimalGroupAbstractService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AmplitudeAnalyticsService,
    private lookerPanel: LookerPanelService,
    // FIXME(gabriel.rohden): review with kam this. it was
    //  being used in the view but it was declared private
    public typeProductionService: TypeProductionService,
  ) {
    super(userStorageService);

    this.quadrantFilterForm = this.formBuilder.group({
      quadrant: [],
    });
  }

  ngOnInit(): void {
    this.searchLookerPanels();
    const reportInfo = reportInfoByStage[this.stage];
    this.rootReport = reportInfo.rootReport;
    this.defaultReport = reportInfo.defaultReport;
    this.filters = reportInfo.filters;
    this.selectedReport = this.defaultReport;
  }

  searchLookerPanels(): void {
    this.stage = this.route.snapshot.data.stage as StageEnum;
    this.lookerPanel.list(this.stage, 'identify', {}).subscribe(response => {
      this.cards = response;
      this.typeFeedback = null;
      if (this.cards.length) {
        this.router.navigate(['looker-panel'], {
          relativeTo: this.route,
          state: { data: this.cards[0] },
        });
        return;
      }

      this.showLookerPanel = false;
      this.analyticsService.logTotalView(SiteByStage[this.typeProductionService.get()][this.stage]);
      this.eventCall();
    });
  }

  showCustomInterval(): boolean {
    return !this.feedbackTypeInterval && this.displayType === IdentifyDisplayType.QUADRANT;
  }

  filter(): void {
    this.loadData();
  }

  changeReport(event: { value: string }): void {
    this.selectedReport = event.value;
    this.customInterval = null;
    this.feedbackTypeInterval = FeedbackEnum.LOADING;
    this.loadQuadrants().subscribe();
  }

  changeCustomInterval(interval: Interval): void {
    this.customInterval = interval;
    this.loadQuadrants().subscribe();
  }

  onNextDisplayTypeClick(): void {
    this.displayType = this.getNextDisplayType(this.displayType);
    this.eventCall();
  }

  nextDisplayTypeIcon(): string {
    return displayTypeIcon[this.getNextDisplayType(this.displayType)];
  }

  updateCurrentFilter(): void {
    this.currentFilter = {
      report: this.selectedReport ?? this.defaultReport,
      ...this.getCustomIntervalAsString(),
      ...this.reportFilter?.getCurrentFilterInQueryFormat(),
    };
  }

  updateSelectedQuadrants(event: { id: string }[] = []): void {
    this.selectedQuadrants = event.map<string>(e => e.id);
  }

  private loadData(): void {
    this.feedbackTypeScreen = FeedbackEnum.LOADING;
    const subSearchReports = this.reportService
      .getReports({ sub_report: this.rootReport }, this.stage)
      .pipe(
        tap(data => {
          this.reports = _.map(data.results, result => {
            return { label: result.internal_name, value: result.internal_name };
          });
        }),
      );
    const subQuandrat = this.loadQuadrants();
    forkJoin([subSearchReports, subQuandrat])
      .pipe(
        tap(() => {
          this.feedbackTypeScreen = this.feedbackTypeSection;
        }),
        catchError(error => {
          this.feedbackTypeScreen = FeedbackEnum.ERROR;
          return throwError(error);
        }),
      )
      .subscribe();
  }

  private loadQuadrants(): Observable<QuadrantAnimalGroup> {
    this.updateCurrentFilter();
    return of(null).pipe(
      tap(() => {
        this.feedbackTypeSection = FeedbackEnum.LOADING;

        this.quadrantFilterForm.reset();
        this.quadrantFilterForm.controls.quadrant.disable();
      }),
      switchMap(() =>
        this.animalGroupService.getQuadrantAnimalGroupList(
          this.holdingId,
          this.stage,
          this.currentFilter,
        ),
      ),
      tap(data => {
        this.quadrantAnimalGroupList = data;
        this.defaultQuadrantsAxes = data.quadrants_axes;

        this.updateQuadrantOptions();
        this.quadrantFilterForm.controls.quadrant.enable();

        this.feedbackTypeSection = !this.hasData() ? FeedbackEnum.NOT_RESULT_SEARCH : null;
        this.feedbackTypeInterval = null;
      }),
    );
  }

  private getNextDisplayType(displayType: IdentifyDisplayType) {
    return displayType === IdentifyDisplayType.QUADRANT
      ? IdentifyDisplayType.TABLE
      : IdentifyDisplayType.QUADRANT;
  }

  private updateQuadrantOptions() {
    const reportName = this.t('agriness.report.' + this.selectedReport);
    this.translateParams = getIndexesFromReportName(reportName);

    const quadrantOptions: { id: string; name: string }[] = [];

    if (!this.quadrantAnimalGroupList.producer_list_columns.includes('QUADRANT')) {
      return;
    }

    const quadrantsWithData: string[] = [];
    for (const [id, quadrant] of Object.entries(this.quadrantAnimalGroupList.quadrants)) {
      if (quadrant.count > 0) {
        quadrantsWithData.push(id);
      }
    }

    for (const item of quadrantsWithData) {
      const translateKey = `agriness.identify.quadrant.${item}_short`;
      const translation = this.t(translateKey, this.translateParams);
      quadrantOptions.push({
        id: item,
        name: translation,
      });
    }

    this.quadrantOptions.next(_.sortBy(quadrantOptions, i => i.name));
    this.selectedQuadrants = [];
  }

  private hasData() {
    return this.quadrantAnimalGroupList.producer_list.length > 0;
  }

  private getCustomIntervalAsString() {
    return {
      x_min: this.customInterval?.x_min?.toString(),
      x_max: this.customInterval?.x_max?.toString(),
      y_min: this.customInterval?.y_min?.toString(),
      y_max: this.customInterval?.y_max?.toString(),
    };
  }

  private eventCall(): void {
    if (this.displayType === IdentifyDisplayType.TABLE) {
      this.analyticsService.logTableQuadrants(
        PropertyName.TABLE_VIEW,
        SiteByStage[this.typeProductionService.get()][this.stage],
      );
    }
  }
}
