<svg viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M18.5813 4.68701L2.73127 36.387C2.01727 37.815 3.05527 39.494 4.65127 39.494H36.3513C37.9473 39.494 38.9853 37.814 38.2713 36.387L22.4213 4.68701C21.6303 3.10401 19.3723 3.10401 18.5813 4.68701Z"
    fill="url(#paint0_linear)"
  />
  <path
    d="M36.3512 40.994H4.65125C3.37825 40.994 2.21825 40.348 1.54825 39.265C0.878253 38.182 0.819253 36.855 1.38825 35.716L17.2383 4.016C17.8603 2.772 19.1103 2 20.5003 2C20.5003 2 20.5003 2 20.5013 2C21.8913 2 23.1413 2.772 23.7643 4.016L39.6143 35.717C40.1833 36.856 40.1242 38.183 39.4543 39.266C38.7843 40.348 37.6242 40.994 36.3512 40.994ZM20.5013 5C20.3523 5 20.0783 5.047 19.9233 5.358L4.07325 37.058C3.93225 37.339 4.03125 37.573 4.10125 37.688C4.17225 37.802 4.33725 37.995 4.65125 37.995H36.3512C36.6652 37.995 36.8313 37.803 36.9023 37.688C36.9733 37.573 37.0713 37.338 36.9303 37.058L21.0803 5.358C20.9243 5.046 20.6503 5 20.5013 5ZM18.5813 4.687H18.5913H18.5813Z"
    fill="#1B3858"
  />
  <path
    d="M20.5012 27.997C19.3972 27.997 18.5012 27.101 18.5012 25.997V17.997C18.5012 16.893 19.3972 15.997 20.5012 15.997C21.6052 15.997 22.5012 16.893 22.5012 17.997V25.997C22.5012 27.102 21.6052 27.997 20.5012 27.997Z"
    fill="url(#paint1_linear)"
  />
  <path
    d="M20.5012 34.497C21.8819 34.497 23.0012 33.3777 23.0012 31.997C23.0012 30.6163 21.8819 29.497 20.5012 29.497C19.1205 29.497 18.0012 30.6163 18.0012 31.997C18.0012 33.3777 19.1205 34.497 20.5012 34.497Z"
    fill="white"
  />
  <defs>
    <linearGradient
      id="paint0_linear"
      x1="20.5013"
      y1="39.4942"
      x2="20.5013"
      y2="3.49981"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#FF6659" />
      <stop offset="0.3479" stop-color="#E94D3F" />
      <stop offset="0.7516" stop-color="#D53627" />
      <stop offset="1" stop-color="#CD2D1E" />
    </linearGradient>
    <linearGradient
      id="paint1_linear"
      x1="20.5012"
      y1="27.9971"
      x2="20.5012"
      y2="15.9971"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="white" />
      <stop offset="1" stop-color="white" />
    </linearGradient>
  </defs>
</svg>
