import { LookerPanel } from '@agriness/corp-app/services/models/looker-panel.model';
import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CardLookerModel } from './card-looker.model';

@Component({
  selector: 'ag-card-looker',
  templateUrl: './ag-card-looker.component.html',
})
export class AgCardLookerComponent {
  @Input() data: LookerPanel;
  @Output() favorite = new EventEmitter<CardLookerModel>();

  looker_panel = 'looker-panel';
  analysisCard = ['animal_group_list', 'animal_group_list_reproductive'];
  stage: string;
  propertyName: Record<string, string>;

  constructor(private route: ActivatedRoute, private analyticsService: AmplitudeAnalyticsService) {}

  onOpen(): void {
    this.stage = this.route.snapshot.data.stage;
    if (this.analysisCard.includes(this.data.name)) {
      if (this.stage == 'reproductive') {
        this.analyticsService.logFarmList();
      }
    }
    // this.router.navigate(['looker-panel'], {
    //   relativeTo: this.route,
    //   state: {"data": this.data }});
  }
}
