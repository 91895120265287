import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare global {
  interface Window {
    fcWidget: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ChatWidgetService {
  private positionRight = new BehaviorSubject<number>(0);

  watch(container: HTMLElement): BehaviorSubject<number> {
    const { fcWidget } = window;
    if (!fcWidget) {
      return this.positionRight;
    }

    let fcFrameElement;
    const getFcPositionLeft = () => fcFrameElement.getBoundingClientRect().left;
    const getContainerWidth = () => container.getBoundingClientRect().width;

    const handleFcWidthChange = () => {
      fcFrameElement = fcFrameElement || document.querySelector('#fc_frame');
      const overlayContainerWidth = getContainerWidth();
      let fcPositionLeft = getFcPositionLeft();
      let isStable = false;

      const interval = setInterval(() => {
        const currentFcPositionLeft = getFcPositionLeft();
        if (fcPositionLeft !== currentFcPositionLeft) {
          fcPositionLeft = currentFcPositionLeft;
        } else {
          clearInterval(interval);
          isStable = true;
        }
      }, 100);

      const updatePosition = () => {
        this.positionRight.next(overlayContainerWidth - getFcPositionLeft());
        if (!isStable) {
          requestAnimationFrame(updatePosition);
        }
      };

      requestAnimationFrame(updatePosition);
    };
    fcWidget.on('widget:loaded', handleFcWidthChange);
    fcWidget.on('widget:opened', handleFcWidthChange);
    fcWidget.on('widget:closed', handleFcWidthChange);
    handleFcWidthChange();
    return this.positionRight;
  }
}
