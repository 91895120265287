import { Holding, TypeProductionEnum, UserStorageService } from '@agriness/services';
import { Injectable, Injector } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { environment } from '@env/environment';
import { from, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { appInfoByProduction, productIds } from '../corp-app.model';

@Injectable()
export class AuthGuard implements CanLoad {
  constructor(private userStorageService: UserStorageService, private injector: Injector) {}

  get router(): Router {
    return this.injector.get(Router);
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.isUserProduct(segments[0].path as TypeProductionEnum);
  }

  redirectIfNotUserProduct(
    typeProduction = environment.typeProduction,
  ): Observable<TypeProductionEnum> {
    if (!this.isUserProduct(typeProduction)) {
      return this.redirectToUserProduct();
    }
    return of(typeProduction);
  }

  hasAccessToCorp(holding: Holding): boolean {
    return holding?.products && holding.products.some(p => productIds.has(p.product_id));
  }

  private isUserProduct(typeProduction: TypeProductionEnum) {
    const appInfo = appInfoByProduction[typeProduction];
    const holdingProducts = this.userStorageService.getCurrentHolding()?.products;

    const products = [];

    holdingProducts.forEach(i => {
      products.push(i.product_id);
    });

    return !holdingProducts || (appInfo && products.includes(appInfo.productId));
  }

  private redirectToUserProduct(): Observable<TypeProductionEnum> {
    const holdingProducts = this.userStorageService.getCurrentHolding().products;
    const products = [];

    holdingProducts.forEach(i => {
      products.push(i.product_id);
    });
    const corpUserProduct = Object.values(appInfoByProduction).find(app =>
      products.includes(app.productId),
    );

    return from(this.router.navigate(['/', corpUserProduct.typeProduction])).pipe(
      switchMap(success =>
        success ? of(corpUserProduct.typeProduction) : throwError('navigation error'),
      ),
    );
  }
}
