import { Indexes } from '@agriness/corp-app/settings/payment-rule-section/payment-rule/rule-editor/rule-editor-form.model';
import {
  BaseEntityFilter,
  ContextFilter,
  PageNumberPagination,
  QueryCorp,
} from '@agriness/services';

export enum WeightType {
  TOTAL = 'total_weight',
}

export enum KpiType {
  MANUAL = 'manual',
  AVALIABLE = 'avaliable',
}

export interface Stage {
  id: string;
  name: string;
}

export interface Rule {
  id?: string;
  name: string;
  holding_id: string;
  stage: Stage;
  stage_name?: string;
  type_weight: string;
  indexes: Indexes[];
  can_delete: boolean;
  enabled: boolean;
}
export interface Ranges {
  range: number;
  percentage: number;
}

export interface KPIList {
  id: string;
  key: string;
  name: string;
  stage: string;
}

export const defaultRuleOrder: (keyof Rule)[] = ['name'];

export const defaultKPIListOrder: (keyof KPIList)[] = ['name'];

export type RuleFilter = BaseEntityFilter & PageNumberPagination & ContextFilter & QueryCorp;

export type KPIListFilter = BaseEntityFilter & PageNumberPagination & ContextFilter & QueryCorp;
