import { Highcharts } from '@agriness/corp-app/shared/services/highcharts.service';
import { FactoryProvider } from '@angular/core';

/**
 * Handles the resize logic for the highcharts
 * instances
 *
 * Consumers of this service are expected to create an instance
 * of it **per chart**. see the [chartResizeServiceProvider] usages
 * for examples.
 */
export class ChartResizeService {
  private checkInterval?: ReturnType<typeof setInterval>;
  private scheduleTimeout?: ReturnType<typeof setTimeout>;
  private chartInstance: Highcharts.Chart;
  private lastKnownWidth = 0;

  destroy(): void {
    this.clearCheckInterval();
    this.clearScheduleTimeout();
  }

  registerChartInstance(chart: Highcharts.Chart): void {
    this.chartInstance = chart;
    this.checkForResizes();
  }

  private scheduleResize() {
    this.clearScheduleTimeout();
    // We need a schedule to avoid highcharts bugs
    // with very often resize calls
    this.scheduleTimeout = setTimeout(() => {
      const rect = this.getContainerRect();
      if (!rect) {
        return;
      }

      this.chartInstance?.setSize(rect.width, rect.height);
    }, 300);
  }

  private checkForResizes() {
    this.clearCheckInterval();
    this.lastKnownWidth = this.getContainerRect()?.width;

    // We either use a polyfill for ResizeObserver or this setInterval
    this.checkInterval = setInterval(() => {
      const rect = this.getContainerRect();

      if (this.lastKnownWidth !== rect?.width && rect?.width) {
        this.scheduleResize();
      }

      this.lastKnownWidth = rect?.width;
    }, 200);
  }

  private getContainerRect() {
    return this.chartInstance?.container?.parentElement?.getBoundingClientRect();
  }

  private clearCheckInterval() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  }

  private clearScheduleTimeout() {
    if (this.scheduleTimeout) {
      clearTimeout(this.scheduleTimeout);
    }
  }
}

export const chartResizeServiceProvider: FactoryProvider = {
  provide: ChartResizeService,
  useFactory: (): ChartResizeService => new ChartResizeService(),
};
