import {
  Checklist,
  ChecklistFilter,
  ChecklistForm,
  defaultChecklistOrder,
  ModalStatusEnum,
} from '@agriness/corp-app/services';
import { ChecklistsService } from '@agriness/corp-app/services/checklists/checklists.service';
import { DeletionTableData } from '@agriness/corp-app/settings/deletion-table/deletion-table.model';
import {
  CorpRecordTableComponent,
  ActionEnum,
} from '@agriness/corp-app/shared/component/corp-record-table/corp-record-table.component';
import {
  ReportFilter,
  ReportFilterQueryKeys,
} from '@agriness/corp-app/shared/model/report-filter.model';
import { PaginationRequest } from '@agriness/corp-app/shared/model/table.model';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { QueueItem, QueueManagerService } from '@agriness/ui';
import { Component, Inject, ViewChild } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

import { ChecklistEditorComponent } from './checklist-editor/checklist-editor.component';
import { checklistsTableColumns, checklistDeletionTableFields } from './checklist.model';

@Component({
  selector: 'app-checklists-tab',
  templateUrl: './checklists-tab.component.html',
  styleUrls: ['./checklists-tab.component.scss'],
})
export class ChecklistsTabComponent {
  @ViewChild('table', { static: true }) table: CorpRecordTableComponent<Checklist>;
  @ViewChild('editor') checklistEditor: ChecklistEditorComponent;

  translationContext = 'agriness.settings.presence.tools.tabs.checklist.table';

  tableColumns = checklistsTableColumns;
  defaultOrdering = defaultChecklistOrder;
  availableActions = [ActionEnum.EDIT, ActionEnum.TOGGLE];
  checklists: Checklist[];
  totalRecords: number;
  rows = 10;

  editorModalVisible = false;
  editorModalStatus = ModalStatusEnum.DEFAULT;
  selectedChecklist: Checklist;
  deletionModalVisible = false;
  deletionModalStatus = ModalStatusEnum.DEFAULT;
  deletionTableData: DeletionTableData;

  error = false;
  loading = true;
  currentFilter: ChecklistFilter = {};
  filterEntity: keyof ReportFilter = 'checklists';
  queryParamName: ReportFilterQueryKeys = 'checklist_name';

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private checklistService: ChecklistsService,
    private queueManager: QueueManagerService,
  ) {}

  createChecklist(): void {
    const formValues = this.checklistEditor.getChecklistForm() as ChecklistForm;
    this.editorModalStatus = ModalStatusEnum.LOADING;

    let valueCreate = null as ChecklistForm;

    valueCreate = {
      ...formValues,
      required: formValues.deadline_days ? true : false,
      start_days_wait_required: formValues.start_days ? true : false,
      stage_ids: formValues.stage_ids.filter(i => i !== ''),
    };

    this.checklistService
      .save(valueCreate)
      .pipe(
        first(),
        map(() => (this.editorModalStatus = ModalStatusEnum.SUCCESS)),
        catchError(({ error }) => {
          this.editorModalStatus = ModalStatusEnum.FAILED;
          return throwError(error);
        }),
      )
      .subscribe(() => this.filter());
  }

  filter(): void {
    this.table.reset();
  }

  onAddChecklist(): void {
    this.selectedChecklist = null;
    this.editorModalVisible = true;
  }

  onDeletionModalClose(): void {
    this.selectedChecklist = null;
    this.deletionModalVisible = false;
    this.deletionModalStatus = ModalStatusEnum.DEFAULT;
  }

  onDeletionModalDelete(): void {
    this.deletionModalStatus = ModalStatusEnum.LOADING;

    this.checklistService.delete(this.selectedChecklist).subscribe({
      complete: () => {
        this.deletionModalStatus = ModalStatusEnum.SUCCESS;
        this.filter();
      },
      error: error => {
        this.deletionModalStatus = ModalStatusEnum.FAILED;
        throw error;
      },
    });
  }

  onEditorClosed(): void {
    this.selectedChecklist = null;
    this.editorModalVisible = false;
    this.editorModalStatus = ModalStatusEnum.DEFAULT;
  }

  onItemAction(obj: { action: ActionEnum; item: Checklist; checked: boolean }): void {
    switch (obj.action) {
      case ActionEnum.EDIT:
        this.selectedChecklist = obj.item;
        this.editorModalVisible = true;
        break;
      case ActionEnum.DELETE:
        this.selectedChecklist = obj.item;
        this.updateDeletionTableData(this.selectedChecklist);
        this.deletionModalVisible = true;
        break;
      case ActionEnum.TOGGLE:
        this.updateEnableChecklist(obj.item, obj.checked);
        break;
    }
  }

  updateEnableChecklist(item: Checklist, enabled: boolean): void {
    const stage_ids: string[] = [];

    item.stage.forEach(i => {
      stage_ids.push(i.external_id);
    });

    this.addOperationToQueue(
      this.checklistService.update({ ...item, enabled: enabled, stage_ids: stage_ids }, item.id),
    );
  }

  addOperationToQueue(checklistObservable: Observable<Checklist>): void {
    const t = (key: string): string => this.t(`${this.translationContext}.feedback.${key}`);
    this.queueManager
      .add(checklistObservable)
      .pipe(
        switchMap(({ payload: checklist, actions }: QueueItem) => {
          return (checklist as Observable<Checklist>).pipe(
            tap((checklist: Checklist) => {
              actions.success(checklist.enabled ? t('checklist_enabled') : t('checklist_disabled'));
            }),
            catchError(err => {
              return throwError(err);
            }),
          );
        }),
        catchError(err => {
          return throwError(err);
        }),
      )
      .subscribe();
  }

  onPageRequest(request: PaginationRequest): void {
    this.loading = true;
    this.updateCurrentFilter(request);
    this.loadChecklists();
  }

  shouldBlockAction(obj: { action: ActionEnum; item: Checklist }): boolean {
    if (obj.action == ActionEnum.DELETE) {
      return obj.item.id === null;
    }
    return false;
  }

  updateChecklist(): void {
    if (this.selectedChecklist) {
      const { id } = this.selectedChecklist;
      const formValues = this.checklistEditor.getChecklistForm() as ChecklistForm;
      this.editorModalStatus = ModalStatusEnum.LOADING;
      let valueCreate = null as ChecklistForm;

      valueCreate = {
        ...formValues,
        required: formValues.deadline_days ? true : false,
        start_days_wait_required: formValues.start_days ? true : false,
        stage_ids: formValues.stage_ids.filter(i => i !== ''),
      };
      this.checklistService
        .update(valueCreate, id)
        .pipe(
          first(),
          map(() => (this.editorModalStatus = ModalStatusEnum.SUCCESS)),
          catchError(({ error }) => {
            this.editorModalStatus = ModalStatusEnum.FAILED;
            return throwError(error);
          }),
        )
        .subscribe(() => this.filter());
    }
  }

  private loadChecklists(): void {
    this.checklistService
      .list(this.currentFilter)
      .pipe(
        first(),
        map(({ count, results }) => {
          this.totalRecords = count;
          this.loading = false;
          this.checklists = results;
        }),
        catchError(error => {
          this.error = true;
          return throwError(error);
        }),
      )
      .subscribe();
  }

  private updateCurrentFilter(request: PaginationRequest): void {
    this.currentFilter = {
      ...this.currentFilter,
      ...request,
    };
  }

  private updateDeletionTableData(checklist: Checklist): void {
    this.deletionTableData = checklistDeletionTableFields.map(field => {
      return {
        field: this.t(this.translationContext + `.${field}`),
        value: checklist[field] as string,
      };
    });
  }
}
