import { commonDeletionTableFields } from '@agriness/corp-app/settings/deletion-table/deletion-table.model';
import { textColumn, actionColumn } from '@agriness/ui';

const tanksKey = 'agriness.settings.tanks.table';

export const tanksTableColumns = [
  {
    ...actionColumn,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'name',
    header: `${tanksKey}.name`,
    width: '500px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'farm.name',
    header: `${tanksKey}.farm`,
    width: '500px',
    sortable: true,
    fixed: true,
  },
];

export const tanksDeletionTableFields: string[] = [...commonDeletionTableFields];
