import { Component, Input } from '@angular/core';
import html2canvas from 'html2canvas';

import { DOMSelectors, DrawOptions, Size } from './performance-exporter.model';

@Component({
  selector: 'performance-exporter',
  templateUrl: './performance-exporter.component.html',
})
export class PerformanceExporterComponent {
  @Input() filename: string;

  async export(): Promise<void> {
    const graph = document.querySelector(`#${DOMSelectors.PERFORMANCE_GRAPH}`);
    const animalGroupInfo = document.querySelector(`#${DOMSelectors.ANIMAL_GROUP_INFO}`);

    const graphBoundingRect = graph.getBoundingClientRect();
    const animalGroupInfoBoundingRect = animalGroupInfo.getBoundingClientRect();

    const configurations = {
      animalGroupInfo: {
        width: graphBoundingRect.width,
        heigt: animalGroupInfoBoundingRect.height,
        ignoreElements: (element: HTMLElement) => element.id === DOMSelectors.ANIMAL_GROUP_DIET,
      },
      graph: {
        width: graphBoundingRect.width,
        height: graphBoundingRect.height,
      },
    };

    const animalGroupInfoImg = await html2canvas(
      animalGroupInfo as HTMLElement,
      configurations.animalGroupInfo,
    );
    const graphImg = await html2canvas(graph as HTMLElement, configurations.graph);

    const size = this.calculateCanvasSize([animalGroupInfoImg, graphImg]);
    const canvas = this.createCanvas(size);

    const drawOptions = {
      animalGroupInfo: {
        image: animalGroupInfoImg,
        x: 0,
        y: 0,
      },
      graph: {
        image: graphImg,
        x: 0,
        y: animalGroupInfoBoundingRect.height,
      },
    };

    this.drawImages(canvas, drawOptions);
    this.downloadImg(canvas);
  }

  private calculateCanvasSize(images: HTMLCanvasElement[]) {
    const size = { width: 0, height: 0 };

    for (const img of images) {
      if (img.width > size.width) {
        size.width = img.width;
      }

      size.height += img.height;
    }

    return size;
  }

  private createCanvas(size: Size) {
    const canvas = document.createElement('canvas');
    canvas.width = size.width;
    canvas.height = size.height;

    return canvas;
  }

  private drawImages(canvas: HTMLCanvasElement, drawOptions: DrawOptions) {
    const ctx = canvas.getContext('2d');

    for (const obj of Object.values(drawOptions)) {
      const { image, x, y } = obj;
      ctx.drawImage(image, x, y);
    }
  }

  private downloadImg(canvas: HTMLCanvasElement) {
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpg');
    a.download = `${this.filename}.jpg`;
    a.click();
  }
}
