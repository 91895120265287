import { ValueFormatStyle } from '../../pipe/value-format/value-format.model';
import { TargetStatus } from '../target/target.model';

export interface CardModel {
  name?: string;
  value?: string | number;
  style?: ValueFormatStyle | string;
  decimal_places: number;
  status?: TargetStatus;
  target_representation_type?: string;
  target?: number | string;
  target_min_recommended?: number | string;
  target_max_recommended?: number | string;
  action?: boolean;
  id?: string;
  link?: CardLink;

  /**
   * @deprecated use {@link style}
   */
  measurement_unit?: string;
  /**
   * @deprecated use {@link target_representation_type}
   */
  target_name?: string;
  /**
   * @deprecated use {@link style}
   */
  target_measurement_unit?: string;
}

export interface CardModelPerformances {
  name?: string;
  style?: ValueFormatStyle | string;
  decimal_places: number;
  status?: TargetStatus;
  target_representation_type?: string;
  target_min_recommended?: number | string;
  target_max_recommended?: number | string;
  action?: boolean;
  id?: string;
  cardPerformances: CardPerformance[];
  barChart?: BarChart;

  /**
   * @deprecated use {@link style}
   */
  measurement_unit?: string;
}

export interface CardPerformance {
  name?: string;
  style?: ValueFormatStyle | string;
  decimal_places?: number;
  value?: string | number;
  target?: number | string;
  value_partial?: boolean;
}

export interface BarChartItem {
  title: string;
  percentage: number;
  status: TargetStatus;
  route?: string | any[];
  routeText?: string;
  queryParams?: { [key: string]: any };
}

export type BarChart = BarChartItem[];

/**
 * @deprecated usar TargetStatus
 */
export enum CardStatus {
  UP = 'up',
  DOWN = 'down',
}

export interface CardLink {
  url: any[] | string;
  queryParams: {
    [k: string]: any;
  };
}
