import {
  AnimalGroupMonitorPerformanceByPeriod,
  AnimalGroupPerformanceByPeriod,
  FilterMonitor,
  MonitorAbstractService,
  Period,
  ReportEnum,
} from '@agriness/corp-app/services';
import { StageEnum, UserStorageService } from '@agriness/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import {
  FeedbackEnum,
  IndexesTableCellLink,
  IndexesTableColumn,
  IndexesTableIndex,
  IndexesTableRow,
} from '@agriness/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { CorpReportComponent } from '../../model/report.model';
import { LoaderUserPreference } from '../loader-user-preference';

@Component({
  selector: 'corp-report-indexes-table-by-period',
  templateUrl: './corp-report-indexes-table-by-period.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpReportIndexesTableByPeriodComponent extends LoaderUserPreference
  implements CorpReportComponent {
  @Input() sectionName: string;
  @Input() stage: StageEnum;
  @Input() report: ReportEnum;
  @Input() filter: FilterMonitor;
  @Input() indexLabelPrefix = 'agriness.monitor.performances.';
  @Input() cellLink: IndexesTableCellLink;

  performances: IndexesTableRow[] = [];
  periods: Period[] = [];
  columns: IndexesTableColumn[] = [];
  links: IndexesTableCellLink[] = [];
  indexesGroups: string[][] = [
    ['COUNT_ANIMAL_GROUP', 'BALANCE'],
    ['LOCATION_DEATHS', 'MORTALITY_RATE'],
  ];
  linkAtIndex = 'COUNT_ANIMAL_GROUP';
  headerIndexDescription: { [k: string]: string } = {};
  typeFeedback = FeedbackEnum.LOADING;

  constructor(
    protected userStorageService: UserStorageService,
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private monitorService: MonitorAbstractService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(userStorageService);
  }

  loadPreferences(): Observable<null> {
    return EMPTY;
  }

  loadPerformances(
    filter: FilterMonitor,
    withFeedback = true,
  ): Observable<AnimalGroupMonitorPerformanceByPeriod> {
    this.filter = filter;
    this.setPerformances({ performances: [], periods: [] });
    if (withFeedback) {
      this.typeFeedback = FeedbackEnum.LOADING;
    }

    return this.monitorService
      .getMonitorPerformanceByPeriod({
        holdingId: this.holdingId,
        stage: this.stage,
        filter: this.filter,
      })
      .pipe(
        tap(performances => {
          return this.setPerformances(performances);
        }),
        tap(({ performances }) => this.updateHeaderIndexDescription(performances)),
        tap(performances => this.setColumns(performances.periods)),
        tap(() => {
          this.typeFeedback = !this.hasData() ? FeedbackEnum.NOT_RESULT_SEARCH : null;
        }),
        catchError(err => {
          console.error(`error on '${this.report}' load`, err);
          this.typeFeedback = FeedbackEnum.ERROR;
          return throwError(err);
        }),
        finalize(() => this.changeDetectorRef.markForCheck()),
      );
  }

  hasData(): boolean {
    return this.performances.length > 0;
  }

  updateHeaderIndexDescription(performances: AnimalGroupPerformanceByPeriod[]): void {
    this.headerIndexDescription = performances.reduce((descriptions, performance) => {
      descriptions[performance.index_name] = this.t([
        `agriness.monitor.${performance.index_name}_total`,
        'agriness.monitor.general_average',
      ]);
      return descriptions;
    }, {});
  }

  private parsePerformancesByPeriodToRowIndexes(
    performances: AnimalGroupPerformanceByPeriod[],
    indexesGroups: string[][] = [],
  ): IndexesTableIndex[][] {
    const indexes: IndexesTableIndex[][] = [];
    while (performances.length) {
      let foundInGroup;

      indexesGroups
        .filter(group => group.includes(performances[0].index_name))
        .map(group => {
          foundInGroup = true;
          indexes.push(
            group.map(index => {
              if (performances.find(p => index === p.index_name)) {
                const performance = performances.find(p => index === p.index_name);
                performances.splice(performances.indexOf(performance), 1);
                return performance;
              }
            }),
          );
        });
      if (!foundInGroup) {
        indexes.push([performances.shift()]);
      }
    }
    return indexes;
  }

  private setPerformances({ performances, periods }: AnimalGroupMonitorPerformanceByPeriod): void {
    this.performances = this.parsePerformancesByPeriodToRowIndexes(
      performances,
      this.indexesGroups,
    ).map(indexes => ({
      indexes,
      links: this.buildLinks(indexes, periods),
    }));
    this.periods = periods;
  }

  private shouldAddLinks(indexes: IndexesTableIndex[]): boolean {
    return indexes.some(index => {
      if (index) {
        return index.index_name === this.linkAtIndex;
      }
      return;
    });
  }

  private buildLinks(indexes: IndexesTableIndex[], periods: Period[]): IndexesTableCellLink[] {
    if (!this.shouldAddLinks(indexes) || !this.cellLink) {
      return null;
    }
    return periods.map(period => ({
      ...this.cellLink,
      queryParams: {
        ...this.cellLink.queryParams,
        ...this.filter,
        ...period,
        is_open: true,
      },
    }));
  }

  private setColumns(periods: Period[]) {
    this.columns = periods.map((a, i) => ({
      name: `agriness.monitor.period.${i + 1}`,
    }));
  }
}
