import { CorpFiltersContainerService } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.service';
import { Component, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'corp-report-filters-subtitle',
  styleUrls: ['./corp-report-filters-subtitle.component.scss'],
  templateUrl: './corp-report-filters-subtitle.component.html',
})
export class CorpReportFiltersSubtitleComponent implements OnInit {
  subtitles: [string, string][] = [];

  constructor(private containerService: CorpFiltersContainerService) {}

  ngOnInit(): void {
    this.containerService.subtitles.pipe(filter(it => it !== null)).subscribe(subtitles => {
      this.subtitles = subtitles;
    });
  }

  resetFilterForm(): void {
    this.containerService.onResetFilter.emit();
  }
}
