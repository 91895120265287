import {
  FilterMonitor,
  ReportEnum,
  TypeViewPreferenceUser,
  ScheduleMonitorAbstractService,
} from '@agriness/corp-app/services';
import { StageEnum, UserStorageService, TypeProductionService } from '@agriness/services';
import { FeedbackEnum } from '@agriness/ui';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { throwError, Observable, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

import { MonitorPerformance } from '../../finishing/monitor/monitor.model';
import { CorpPreferenceToggleColumnComponent } from '../../shared/component/corp-preference-toggle-column/corp-preference-toggle-column.component';
import { LoaderUserPreference } from '../../shared/component/loader-user-preference';
import { CardBuilderService } from '../../shared/services/card-builder.service';

@Component({
  selector: 'schedule-monitor-cards',
  templateUrl: './schedule-monitor-cards.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleMonitorCardsComponent extends LoaderUserPreference {
  @Input() sectionName: string;
  @Input() stage: StageEnum;
  @Input() report: ReportEnum;
  @Input() filter: FilterMonitor;
  @Input() indexLabelPrefix = 'agriness.monitor.performances.';
  @Input() preferenceLabelPrefix = 'agriness.monitor.performances.';

  performances: MonitorPerformance[] = [];
  preferences: CorpPreferenceToggleColumnComponent;
  typeFeedback = FeedbackEnum.LOADING;

  constructor(
    private typeProductionService: TypeProductionService,
    protected userStorageService: UserStorageService,
    private cardBuilder: CardBuilderService,
    private monitorService: ScheduleMonitorAbstractService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(userStorageService);
  }

  getPreferences(value: CorpPreferenceToggleColumnComponent): void {
    this.preferences = value;
  }

  loadPreferences(): Observable<TypeViewPreferenceUser> {
    return this.preferences.load();
  }

  onPreferenceChange(): void {
    this.loadPerformances(this.filter).subscribe();
  }

  loadPerformances(filter: FilterMonitor, withFeedback = true): Observable<MonitorPerformance[]> {
    this.filter = filter;
    this.performances = [];
    if (withFeedback) {
      this.typeFeedback = FeedbackEnum.LOADING;
    }
    this.changeDetectorRef.markForCheck();
    if (!this.preferences.allPreferences.user.some(user => user.checked)) {
      this.typeFeedback = FeedbackEnum.NO_ITEM_SELECTED;
      return of<MonitorPerformance[]>([]);
    }
    return this.monitorService
      .getMonitorPerformance({
        holdingId: this.holdingId,
        report: this.report,
        system_type: this.typeProductionService.get(),
        stage: this.stage,
        filter: this.filter,
      })
      .pipe(
        map(performances =>
          this.cardBuilder.parsePerformancesToMonitorPerformances(
            performances,
            this.indexLabelPrefix,
            this.filter,
            this.stage,
          ),
        ),
        tap(cards => (this.performances = cards)),
        tap(() => {
          this.typeFeedback =
            this.performances.length === 0 ? FeedbackEnum.NOT_RESULT_SEARCH : null;
        }),
        catchError(err => {
          console.error(`error on '${this.report}' load`, err);
          this.typeFeedback = FeedbackEnum.ERROR;
          return throwError(err);
        }),
        finalize(() => this.changeDetectorRef.markForCheck()),
      );
  }
}
