import { DocumentType } from '@agriness/corp-app/services/models/document-type.model';
import { Interval } from '@agriness/corp-app/shared/component/corp-identify/corp-identify.model';
import { ReportFilterQuery } from '@agriness/corp-app/shared/model/report-filter.model';
import { Search, SearchFilter } from '@agriness/services';

import { AnimalGroupPerformance } from './animal-group-performance.model';
import { Index } from './report-dashboard.model';

export class SearchFilterAnimalGroupList extends SearchFilter {
  constructor(
    public begin_date?: string,
    public end_date?: string,
    public technician_id?: string,
    public is_open?: string,
  ) {
    super();
  }
}

export class SearchFilterQuadrantAnimalGroup extends SearchFilter {
  constructor(
    public technician_id?: string,
    public start_date?: string,
    public end_date?: string,
    public last_animal_group?: number,
    public report?: string,
    public system_type?: string,
    public interval?: Interval,
  ) {
    super();
  }
}

export class AnimalGroupList {
  constructor(
    public animal_group?: AnimalGroups,
    public index_configuration?: IndexConfiguration,
    public performances?: AnimalGroupPerformance[],
  ) {}
}

export class SearchAnimalGroupList extends Search<AnimalGroupList> {
  constructor() {
    super();
  }
}

export class AnimalGroups {
  constructor(
    public animal_group_name?: string,
    public id?: string,
    public is_open?: boolean,
    public location_name?: string,
    public producer_name?: string,
    public technician_name?: string,
    public farm_id?: string,
    public farm_name?: string,
    public site_name?: string,
  ) {}
}

export enum QuadrantEnum {
  BAD_GREAT = 'bad_great',
  BAD_GOOD = 'bad_good',
  BAD_BAD = 'bad_bad',
  GOOD_GREAT = 'good_great',
  GOOD_GOOD = 'good_good',
  GOOD_BAD = 'good_bad',
  GREAT_GREAT = 'great_great',
  GREAT_GOOD = 'great_good',
  GREAT_BAD = 'great_bad',
}

export interface QuadrantAnimalGroup {
  quadrants: Quadrants;
  quadrants_axes?: QuadrantsAxes;
  producer_list?: any[];
  producer_list_columns?: string[];
  quadrant_choices?: string[];
}

export interface Quadrants {
  [key: string]: Quadrant;
}

export interface QuadrantsAxes {
  axis_x: QuadrantsAxis;
  axis_y: QuadrantsAxis;
}

export interface QuadrantsAxis {
  index: Index;
  intervals: {
    great: QuadrantsAxisInterval[];
    good: QuadrantsAxisInterval[];
    bad: QuadrantsAxisInterval[];
  };
}

export interface Configuration {
  [key: string]: number;
}

export interface IndexConfiguration {
  [index: string]: Configuration;
}

export type QuadrantsAxisInterval = [QuadrantAxisIntervalType, ...number[]];

export type QuadrantAxisIntervalType = 'LESS_THAN' | 'INCLUSIVE_RANGE' | 'GREATER_THAN';

export interface QuadrantProducer {
  producer_name?: string;
  total_number_output?: number;
  location?: string;
  animalgroup?: { id: string; name: string }[];
  farm_id?: string;
  technician_name?: string;
  quadrant?: string;
}

export interface Quadrant {
  count?: number;
  index_name_x?: string;
  index_name_y?: string;
  producers?: QuadrantProducer[];
}

export interface AnimalGroupListDownloadArgs extends ReportFilterQuery {
  holding_id: string;
  system_type?: string;
  stage: string;
  index_keys?: string;
  data_format: DocumentType;
  all?: boolean;
}

export type QueryParams = Omit<
  { [k in keyof AnimalGroupListDownloadArgs]: unknown },
  'holding_id' | 'stage'
>;
