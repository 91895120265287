import { Search } from '@agriness/services';
import { Observable } from 'rxjs';

import { Producer, ProducerFilter, ProducerForm } from '../models/producer.model';

export abstract class ProducerAbstractService {
  abstract list(producerFilter: ProducerFilter): Observable<Search<Producer>>;
  abstract save(form: ProducerForm): Observable<Producer>;
  abstract update(form: ProducerForm, producerId: string): Observable<Producer>;
  abstract delete(producer: Producer): Observable<void>;
}
