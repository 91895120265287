import { SiteEnum, SiteSystemType } from '@agriness/services';
import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  templateUrl: './finishing-identify.component.html',
})
export class FinishingIdentifyComponent {
  stage = SiteSystemType[environment.typeProduction][SiteEnum.SITE_3];
}
