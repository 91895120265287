import { Region, RegionForm } from '@agriness/corp-app/services';
import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'region-editor',
  templateUrl: './region-editor.component.html',
  styleUrls: ['./region-editor.component.scss'],
})
export class RegionEditorComponent implements OnInit {
  @Input() visible = false;
  @Input() regionData: Region;
  @Input() modalStatus = ModalStatusEnum.DEFAULT;

  @Output() closed = new EventEmitter<void>();
  @Output() createRegion = new EventEmitter<void>();
  @Output() updateRegion = new EventEmitter<void>();

  errorMessages = {
    name: '',
  };

  translationContext = 'agriness.settings.regions';

  regionForm = new FormGroup({
    name: new FormControl('', Validators.required.bind(this)),
  });

  constructor(@Inject(TRANSLATE_INSTANT) private t: TranslateInstant) {}

  ngOnInit(): void {
    this.updatedFormRegion();
    this.listenValueChanges();
  }

  listenValueChanges(): void {
    for (const control in this.regionForm.controls) {
      this.regionForm.controls[control].valueChanges.subscribe(() => {
        const { errors } = this.regionForm.get(control);
        this.errorMessages[control] = errors ? this.t('agriness.settings.validation_required') : '';
      });
    }
  }

  onSubmit(): void {
    if (!this.regionData) {
      this.createRegion.emit();
    } else {
      this.updateRegion.emit();
    }
  }

  onClosed(): void {
    this.closed.emit();
  }

  updatedFormRegion(): void {
    if (this.regionData) {
      const { name } = this.regionData;
      this.regionForm.patchValue({ name });
    }
  }

  getRegionForm(): RegionForm {
    return this.regionForm.getRawValue() as RegionForm;
  }
}
