import { StageEnum, TypeProductionEnum } from '@agriness/services';

export type AnalysisInterval = 'week' | 'month';

export interface SearchFilterPerformanceAnalysis {
  site_id?: string;
  begin_date?: string;
  end_date?: string;
  strain_id?: string;
  production_system?: string;
  current_cycle?: string;
  is_open?: string;
  index_key?: string;
  group_by: string;
  periods: string;
  begin_age?: number;
  end_age?: number;
  interval?: AnalysisInterval;
}

export interface PerformanceAnalysisUrlFilter {
  holdingId: string;
  typeProduction: TypeProductionEnum;
  stage: StageEnum;
  report: Report;
}

export enum Report {
  GRAPH = 'graph',
  TABLE = 'table',
  CARDS_FOR_STRAIN = 'cards-for-strain-tab',
  CARDS_FOR_PERIOD = 'cards-for-period-tab',
}
