import { deprecatedColors } from '@agriness/assets/deprecated';
import { DisplayOption } from '@agriness/corp-app/record/animal-group/performance/performance.model';
import { ItemProperties } from '@agriness/corp-app/services';
import { DateService } from '@agriness/services';
import { AgFeedbackService, FeedbackEnum, ValueFormatPipe } from '@agriness/ui';
import { Injectable } from '@angular/core';
import { TooltipFormatterContextObject } from 'highcharts';
import { defaultsDeep } from 'lodash';

@Injectable()
export class CorpPerformanceGraphService {
  constructor(
    private dateService: DateService,
    private valueFormat: ValueFormatPipe,
    private feedbackService: AgFeedbackService,
  ) {}

  getGraphColors(): string[] {
    return [
      deprecatedColors.purple3,
      deprecatedColors.aqua4,
      deprecatedColors.brown3,
      deprecatedColors.pink3,
      deprecatedColors.orange4,
      deprecatedColors.green2,
    ];
  }

  makeStandardFormatter(
    displayOption: DisplayOption,
  ): (context: TooltipFormatterContextObject) => string {
    return this.makeFormatter(current =>
      displayOption === DisplayOption.BY_DATE
        ? this.dateService.formatDate(new Date(current.key))
        : current.key,
    );
  }

  getXAxisLabel(displayOption: DisplayOption): string {
    if (displayOption === DisplayOption.BY_AGE) {
      return 'agriness.performances.age';
    }

    if (displayOption === DisplayOption.BY_MONTH) {
      return 'agriness.performances.month';
    }

    return 'agriness.performances.date';
  }

  addDefaultChartOptions(options: Highcharts.Options): Highcharts.Options {
    return defaultsDeep(
      {
        colors: this.getGraphColors(),
        credits: { enabled: false },
        exporting: { enabled: false },
        lang: { noData: this.feedbackService.getFeedbackMessage(FeedbackEnum.NOT_RESULT) },
        legend: {
          align: 'left',
          verticalAlign: 'top',
          layout: 'horizontal',
        },
        navigation: {
          buttonOptions: { enabled: false },
        },
        plotOptions: {
          series: {
            label: { enabled: false },
            marker: { enabled: false },
            selected: true,
          },
        },
        title: { text: '' },
        tooltip: {
          shared: true,
        },
      },
      options,
    ) as Highcharts.Options;
  }

  parseYValue(yValue: ItemProperties): number {
    const value = yValue.value as number;

    return value ? parseFloat(value.toFixed(yValue.decimal_places)) : value;
  }

  formatterYValue(yValue: ItemProperties): string {
    return `{y:.${String(yValue.decimal_places | 0)}f}`;
  }

  /**
   * Creates a formatter function given the
   * getHeader function to obtain the header value
   *
   *
   * returns the function to be used as the tooltip formatter for highcharts
   */
  makeFormatter = (getHeader: (current: TooltipFormatterContextObject) => string | number) => (
    context: TooltipFormatterContextObject,
  ): string => {
    const current = context.points.find(({ x }) => x === context.x);
    const header = getHeader(current);

    return [
      `<span style="font-size: 10px">${header}</span>`,
      ...context.points.map(point => {
        const y = this.valueFormat.transform(
          point.y,
          current.series.chart.options.lang.decimalPoint,
        );

        return this.formatterTemplate(point.point.color as string, point.series.name, y);
      }),
    ].join('<br/>');
  };

  private formatterTemplate(color: string, seriesName: string, yValue: string): string {
    return `<span style="color:${color}">\u25CF</span> ${seriesName}: <b>${yValue}</b>`;
  }
}
