import { Search } from '@agriness/services';
import { Observable } from 'rxjs';

import { Technician, TechnicianForm, TechnicianFilter } from '../models/technician.model';

export abstract class TechnicianAbstractService {
  abstract list(technicianFilter: TechnicianFilter): Observable<Search<Technician>>;
  abstract save(technicianForm: TechnicianForm): Observable<Technician>;
  abstract update(technicianForm: TechnicianForm, technicianId: string): Observable<Technician>;
  abstract delete(technician: Technician): Observable<void>;
}
