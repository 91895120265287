/* eslint-disable */
import { Injectable, InjectionToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type TranslateGet = (
  key: string | Array<string>,
  interpolateParams?: Object,
) => Observable<string | any>;
export type TranslateInstant = (key: string | Array<string>, interpolateParams?: Object) => string;
export type TranslateInstantWithFallback = TranslateInstant;

export const TRANSLATE_INSTANT = new InjectionToken<TranslateInstant>('TRANSLATE_INSTANT');

const languageSubject = new BehaviorSubject<string>(null);

// @dynamic
@Injectable({ providedIn: 'root' })
export class AgrinessTranslateService {
  private decimalSeparator: string;

  constructor(private translateService: TranslateService) {}

  static configTranslate(translateService: TranslateService): any {
    return (): Promise<any> => {
      return new Promise((resolve, reject) => {
        translateService.addLangs(['en', 'pt', 'es']);
        languageSubject.asObservable().subscribe(inputLang => {
          if (!inputLang) {
            return;
          }

          const match = inputLang.match(/en|pt|es/);
          const lang = match ? match[0] : 'pt';
          translateService.use(lang);
          resolve();
        });
      });
    };
  }

  setLanguage(lang: string) {
    languageSubject.next(lang);
  }

  getLanguage(): string {
    return this.translateService.currentLang;
  }

  setDecimalSeparator(decimalSeparator: string) {
    this.decimalSeparator = decimalSeparator;
  }

  getDecimalSeparator(): string {
    return this.decimalSeparator;
  }

  get: TranslateGet = (key, interpolateParams?) => {
    return this.translateService.get(key, interpolateParams);
  };

  /**
   * @deprecated use {@link instantWithFallback}
   */
  instant: TranslateInstant = (key, interpolateParams?) => {
    return this.translateService.instant(key, interpolateParams);
  };

  /**
   * Returns translation of the first key that has a translation declared
   */
  instantWithFallback: TranslateInstantWithFallback = (keys, interpolateParams?) => {
    if (!Array.isArray(keys)) {
      return this.translateService.instant(keys, interpolateParams);
    }
    for (const key of keys) {
      if (!key) {
        continue;
      }
      const translation = this.translateService.instant(key, interpolateParams);
      if (key !== translation) {
        return translation;
      }
    }
    return keys[0];
  };
}
