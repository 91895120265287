import { TypeProductionEnum } from '@agriness/services';

import { ReportMonitorTabsEnum } from '../../shared/model/report.model';

export interface MonitorTab {
  router: string;
  translateKey: string;
}

export const monitorTabs = {
  [TypeProductionEnum.SWINES]: [
    {
      router: ReportMonitorTabsEnum.INDICATORS,
      translateKey: 'agriness.monitor_indicators',
    },
    {
      router: ReportMonitorTabsEnum.DIAGNOSTIC_TREE,
      translateKey: 'agriness.monitor_diagnostic_tree',
    },
  ],
};
