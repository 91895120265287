import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';

import { DirectivesModule } from '../../directive/directives.module';
import { CorpSwitchButtonComponent } from '../corp-switch-button/corp-switch-button.component';
import { AgFeedbackModule } from '../feedback/ag-feedback.module';
import { AgFormatModule } from '../format/ag-format.module';
import { AgTableActionCellComponent } from './cell-content/ag-table-action-cell.component';
import { AgTableActionDeleteToggleCellComponent } from './cell-content/ag-table-action-delete-toggle-cell.component';
import { AgTableCellContentComponent } from './cell-content/ag-table-cell-content.component';
import { AgTableCellTagComponent } from './cell-content/ag-table-cell-tag-cell.component';
import { AgTableTargetRepresentationComponent } from './cell-content/ag-table-target-representation/ag-table-target-representation.component';
import { AgTableComponent } from './simple/ag-table.component';

@NgModule({
  declarations: [
    AgTableComponent,
    AgTableCellContentComponent,
    AgTableActionCellComponent,
    AgTableActionDeleteToggleCellComponent,
    CorpSwitchButtonComponent,
    AgTableCellTagComponent,
    AgTableTargetRepresentationComponent,
  ],
  exports: [
    AgTableComponent,
    AgTableCellContentComponent,
    AgTableActionCellComponent,
    AgTableActionDeleteToggleCellComponent,
    TableModule,
    InputSwitchModule,
    CorpSwitchButtonComponent,
    AgTableCellTagComponent,
    AgTableTargetRepresentationComponent,
  ],
  imports: [
    CommonModule,
    TableModule,
    AgFormatModule,
    DirectivesModule,
    TranslateModule,
    AgFeedbackModule,
    InputSwitchModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgTableModule {}
