import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { SearchTag } from '../models/tag.model';
import { TagAbstractService } from './tag-abstract.service';

@Injectable()
export class TagService extends TagAbstractService {
  pathSearchTags = 'tags/';

  constructor(private apiCorpService: ApiCorpService) {
    super();
  }

  searchTag(term: string, pageSize: number): Observable<SearchTag> {
    const params = { search: term, page_size: pageSize };
    return this.apiCorpService.get<SearchTag>(this.pathSearchTags, params);
  }
}
