import { A11yModule } from '@angular/cdk/a11y';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { AgStepComponent } from './ag-step.component';
import { AgStepsComponent } from './ag-steps.component';

@NgModule({
  declarations: [AgStepsComponent, AgStepComponent, AgStepComponent],
  exports: [AgStepsComponent, AgStepComponent, AgStepComponent],
  imports: [A11yModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgStepsModule {}
