import {
  BaseEntityFilter,
  PageNumberPagination,
  QueryCorp,
  ContextFilter,
} from '@agriness/services';

export interface Region {
  id: string;
  name: string;
  farm_count: number;
  animalgroup_count: number;
}

export interface RegionForm {
  name: string;
}

export type RegionFilter = BaseEntityFilter & PageNumberPagination & ContextFilter & QueryCorp;

export const defaultRegionOrder: (keyof Region)[] = ['name', 'id'];
