import { TypeProductionEnum } from '@agriness/services';
import { Environment } from '@env/environment.model';

declare global {
  interface Window {
    agrinessAppEnv: Environment;
  }
}

export const environment: Environment = {
  production: true,
  get typeProduction() {
    return location.pathname.split('/')[1] as TypeProductionEnum;
  },
  ...window.agrinessAppEnv,
};
