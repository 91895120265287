import { CorpReportFiltersComponent } from '@agriness/corp-app/shared/component/corp-report-filters/corp-report-filters.component';
import { DateService, TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { AgPopoverComponent } from '@agriness/ui';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { capitalize } from 'lodash';

import { PerformanceAnalysisTabReport } from '../corp-performance-analysis.model';
import {
  simulationByTab,
  simulationSuffixByLength,
  SimulationType,
  WalkthroughSimulationType,
} from './corp-performance-analysis-simulation.model';

@Component({
  selector: 'corp-performance-analysis-simulation',
  templateUrl: './corp-performance-analysis-simulation.component.html',
  styleUrls: ['./corp-performance-analysis-simulation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpPerformanceAnalysisSimulationComponent implements OnInit, OnChanges {
  @ViewChild('popover') popOver: AgPopoverComponent;
  @Input() currentTab: PerformanceAnalysisTabReport;
  @Input() filter: CorpReportFiltersComponent;
  @Output() visualize = new EventEmitter<WalkthroughSimulationType>();

  dateFormat: string;
  form: FormGroup;
  simulationTypes: SimulationType[];
  suffix: string;
  translateKey = 'agriness.performance_analysis.simulation.';

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private dateService: DateService,
  ) {
    this.dateFormat = dateService.getDateFormat();
  }

  ngOnInit(): void {
    this.onChanges();
    this.subscribeToFilterChanges();
  }

  ngOnChanges(): void {
    this.onChanges();
  }

  onVisualize(): void {
    const values = this.form.value as WalkthroughSimulationType;
    this.visualize.emit(this.formatDates(values));
    this.popOver.close();
  }

  onReset(): void {
    this.visualize.emit();
    this.onClose();
  }

  onClose(): void {
    this.popOver.close();
  }

  getTitle(): string {
    const title = this.t(
      `${this.translateKey}${this.suffix}`,
      this.simulationTypes.map(type => this.t(this.translateKey + type)),
    );
    return capitalize(title);
  }

  private formatDates(simulation: WalkthroughSimulationType): WalkthroughSimulationType {
    if (simulation.date_lactating_cows) {
      simulation.date_lactating_cows = this.dateService.formatAsIsoDate(
        simulation.date_lactating_cows,
      );
    }

    if (simulation.date_dry_matter) {
      simulation.date_dry_matter = this.dateService.formatAsIsoDate(simulation.date_dry_matter);
    }

    return simulation;
  }

  private onChanges(): void {
    this.simulationTypes = simulationByTab[this.currentTab.report];
    this.suffix = simulationSuffixByLength[this.simulationTypes.length];

    this.form = this.buildForm();
  }

  private buildForm(): FormGroup {
    const beginDate = this.getCurrentFilterBeginDate();
    const endDate = this.getCurrentFilterEndDate();
    const form = new FormGroup({});

    this.simulationTypes.forEach(type => {
      form.addControl(
        type,
        new FormControl(1, [Validators.min(1), Validators.required.bind(this)]),
      );
      form.addControl(
        `date_${type}`,
        new FormControl(beginDate, this.isDateBetween(beginDate, endDate)),
      );
    });

    return form;
  }

  private isDateBetween(beginDate: Date, endDate: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const isValid = control.value >= beginDate && control.value <= endDate;
      if (!isValid) {
        return { error: true };
      }
    };
  }

  private getCurrentFilterBeginDate(): Date {
    return this.filter.container.filterForm.get('beginDate').value as Date;
  }

  private getCurrentFilterEndDate(): Date {
    return this.filter.container.filterForm.get('endDate').value as Date;
  }

  private subscribeToFilterChanges(): void {
    this.filter.container.filterForm
      .get('beginDate')
      .valueChanges.subscribe(() => this.onChanges());
    this.filter.container.filterForm.get('endDate').valueChanges.subscribe(() => this.onChanges());
  }
}
