import { TypeProductionEnum } from '@agriness/services';
import { CardModel, ValueFormatStyle } from '@agriness/ui';
export interface PerformanceModel {
  title: string;
  cards: CardModel[];
}

export enum Report {
  WEIGHT = 'weight',
  FEED_BY_LOAD = 'feed_by_load',
  FEED_BY_PERIOD = 'feed_by_period',
  WATER = 'water',
}

export const nutritionReportsByProduction: {
  [key in TypeProductionEnum]: Set<string>;
} = {
  [TypeProductionEnum.SWINES]: new Set([
    Report.WEIGHT,
    Report.FEED_BY_LOAD,
    Report.FEED_BY_PERIOD,
    Report.WATER,
  ]),
  [TypeProductionEnum.POULTRY]: new Set([Report.WEIGHT, Report.FEED_BY_PERIOD]),
  [TypeProductionEnum.DAIRY]: new Set([]),
  [TypeProductionEnum.LAYERS]: new Set([]),
};

export const valueFormatByReport = {
  [Report.FEED_BY_PERIOD]: ValueFormatStyle.KILOGRAM,
  [Report.WEIGHT]: ValueFormatStyle.KILOGRAM,
  [Report.FEED_BY_LOAD]: ValueFormatStyle.KILOGRAM,
  [Report.WATER]: ValueFormatStyle.LITER,
};

export const valueKeyByReport = {
  [Report.FEED_BY_PERIOD]: 'total_feed_per_animal',
  [Report.WEIGHT]: 'average_sample_weighing',
  [Report.WATER]: 'consumption_per_animal',
};

export const tableOrderByReport = {
  [Report.FEED_BY_PERIOD]: ['period', 'value_accumulated', 'value_accumulated_target'],
  [Report.WEIGHT]: ['period', 'value_accumulated', 'value_accumulated_target'],
  [Report.FEED_BY_LOAD]: [
    'begin_date',
    'end_date',
    'nutrition_type',
    'days',
    'balance',
    'total_feed',
    'average_daily_consumption',
    'average_daily_consumption_target',
    'difference',
    'difference_percentage',
  ],
  [Report.WATER]: [
    'target_index',
    'consumption_per_day',
    'balance',
    'consumption_per_animal',
    'target_plan',
  ],
};

export const translateByReport = {
  [Report.FEED_BY_PERIOD]: {
    title: 'agriness.nutrition.feed_by_period.title',
    chart: {
      period: 'agriness.nutrition.feed_by_period.chart.period',
      series_actual: 'agriness.nutrition.feed_by_period.chart.series_actual',
      series_target: 'agriness.nutrition.feed_by_period.chart.series_target',
      value: 'agriness.nutrition.feed_by_period.chart.value',
    },
    table: {
      period: 'agriness.nutrition.feed_by_period.table.period',
      value_accumulated: 'agriness.nutrition.feed_by_period.table.value_accumulated',
      value_accumulated_target: 'agriness.nutrition.feed_by_period.table.value_accumulated_target',
    },
  },
  [Report.WEIGHT]: {
    title: 'agriness.nutrition.body_weight.title',
    chart: {
      period: 'agriness.nutrition.body_weight.chart.period',
      series_actual: 'agriness.nutrition.body_weight.chart.series_actual',
      series_target: 'agriness.nutrition.body_weight.chart.series_target',
      value: 'agriness.nutrition.body_weight.chart.value',
    },
    table: {
      period: 'agriness.nutrition.body_weight.table.period',
      value_accumulated: 'agriness.nutrition.body_weight.table.value_accumulated',
      value_accumulated_target: 'agriness.nutrition.body_weight.table.value_accumulated_target',
    },
  },
  [Report.FEED_BY_LOAD]: {
    title: 'agriness.nutrition.feed_by_load.title',
    chart: {
      period: 'agriness.nutrition.feed_by_load.chart.period',
      series_actual: 'agriness.nutrition.feed_by_load.chart.series_actual',
      series_target: 'agriness.nutrition.feed_by_load.chart.series_target',
      value: 'agriness.nutrition.feed_by_load.chart.value',
    },
    table: {
      begin_date: 'agriness.nutrition.feed_by_load.table.begin_date',
      end_date: 'agriness.nutrition.feed_by_load.table.end_date',
      days: 'agriness.nutrition.feed_by_load.table.days',
      difference: 'agriness.nutrition.feed_by_load.table.difference',
      difference_percentage: 'agriness.nutrition.feed_by_load.table.difference_percentage',
      balance: 'agriness.nutrition.feed_by_load.table.balance',
      total_feed: 'agriness.nutrition.feed_by_load.table.total_feed',
      average_daily_consumption: 'agriness.nutrition.feed_by_load.table.average_daily_consumption',
      average_daily_consumption_target:
        'agriness.nutrition.feed_by_load.table.average_daily_consumption_target',
      nutrition_type: 'agriness.nutrition.feed_by_load.table.nutrition_type',
    },
  },
  [Report.WATER]: {
    title: 'agriness.nutrition.water_consumption.title',
    chart: {
      period: 'agriness.nutrition.water_consumption.chart.period',
      series_actual: 'agriness.nutrition.water_consumption.chart.series_actual',
      series_target: 'agriness.nutrition.water_consumption.chart.series_target',
      value: 'agriness.nutrition.water_consumption.chart.value',
    },
    table: {
      target_index: 'agriness.nutrition.water_consumption.table.target_index',
      consumption_per_day: 'agriness.nutrition.water_consumption.table.consumption_per_day',
      balance: 'agriness.nutrition.water_consumption.table.balance',
      consumption_per_animal: 'agriness.nutrition.water_consumption.table.consumption_per_animal',
      target_plan: 'agriness.nutrition.water_consumption.table.target_plan',
    },
  },
};

export const tableByFieldByReport = {
  [Report.FEED_BY_PERIOD]: {
    period: {
      header: translateByReport[Report.FEED_BY_PERIOD].table.period,
      width: '112px',
    },
    value_accumulated: {
      header: translateByReport[Report.FEED_BY_PERIOD].table.value_accumulated,
    },
    value_accumulated_target: {
      header: translateByReport[Report.FEED_BY_PERIOD].table.value_accumulated_target,
    },
  },
  [Report.WEIGHT]: {
    period: {
      header: translateByReport[Report.WEIGHT].table.period,
      width: '112px',
    },
    value_accumulated: {
      header: translateByReport[Report.WEIGHT].table.value_accumulated,
    },
    value_accumulated_target: {
      header: translateByReport[Report.WEIGHT].table.value_accumulated_target,
    },
  },
  [Report.FEED_BY_LOAD]: {
    begin_date: {
      header: translateByReport[Report.FEED_BY_LOAD].table.begin_date,
    },
    end_date: {
      header: translateByReport[Report.FEED_BY_LOAD].table.end_date,
    },
    days: {
      header: translateByReport[Report.FEED_BY_LOAD].table.days,
    },
    difference: {
      header: translateByReport[Report.FEED_BY_LOAD].table.difference,
    },
    difference_percentage: {
      header: translateByReport[Report.FEED_BY_LOAD].table.difference_percentage,
    },
    balance: {
      header: translateByReport[Report.FEED_BY_LOAD].table.balance,
    },
    total_feed: {
      header: translateByReport[Report.FEED_BY_LOAD].table.total_feed,
    },
    average_daily_consumption: {
      header: translateByReport[Report.FEED_BY_LOAD].table.average_daily_consumption,
    },
    average_daily_consumption_target: {
      header: translateByReport[Report.FEED_BY_LOAD].table.average_daily_consumption_target,
    },
    nutrition_type: {
      header: translateByReport[Report.FEED_BY_LOAD].table.nutrition_type,
    },
  },
  [Report.WATER]: {
    target_index: {
      header: translateByReport[Report.WATER].table.target_index,
      width: '103px',
    },
    consumption_per_day: {
      header: translateByReport[Report.WATER].table.consumption_per_day,
      width: '114px',
    },
    balance: {
      header: translateByReport[Report.WATER].table.balance,
      width: '110px',
    },
    consumption_per_animal: {
      header: translateByReport[Report.WATER].table.consumption_per_animal,
      width: '130px',
    },
    target_plan: {
      header: translateByReport[Report.WATER].table.target_plan,
    },
  },
};
