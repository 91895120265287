import { EmbedReportTab } from '@agriness/corp-app/shared/component/corp-embed-report/corp-embed-report-tab.model';
import { Component } from '@angular/core';

@Component({
  templateUrl: './onepage-report.component.html',
})
export class OnePageReportComponent {
  pageTitle = 'agriness.onepage_report.title';
  tabs: EmbedReportTab[] = [
    {
      router: 'overview',
      translateKey: 'agriness.onepage_report.overview',
    },
    {
      router: 'farm_compare',
      translateKey: 'agriness.onepage_report.farm_compare',
    },
    {
      router: 'farm_status',
      translateKey: 'agriness.onepage_report.farm_status',
    },
    {
      router: 'farm_sensors',
      translateKey: 'agriness.onepage_report.farm_sensors',
    },
  ];
}
