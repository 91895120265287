import { FilterMonitor, MonitorAbstractService, ReportEnum } from '@agriness/corp-app/services';
import { StageEnum, UserStorageService } from '@agriness/services';
import { FeedbackEnum, NodeVector, RelationModel } from '@agriness/ui';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

import { MonitorPerformance } from '../../../finishing/monitor/monitor.model';
import { CardBuilderService } from '../../services/card-builder.service';
import { LoaderUserPreference } from '../loader-user-preference';

@Component({
  selector: 'corp-swine-diagnostic-tree',
  templateUrl: './corp-swine-diagnostic-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpSwineDiagnosticTreeComponent extends LoaderUserPreference {
  @Input() stage: StageEnum;
  @Input() report: ReportEnum;
  @Input() filter: FilterMonitor;

  cards: MonitorPerformance[] = [];
  typeFeedback = FeedbackEnum.LOADING;

  cardPivots: { [kpi: string]: NodeVector } = {
    'pigs-weaned-mated-female-year': { row: 10, col: 60 },
    'litters-female-year': { row: 30, col: 33 },
    'average-weaned-pigs': { row: 30, col: 91 },
    'average-gestation-length': { row: 50, col: 13 },
    'average-lactating-length': { row: 50, col: 33 },
    'average-non-productive-days': { row: 50, col: 53 },
    'average-born-alive': { row: 50, col: 79 },
    'weaning-mortality-related': { row: 50, col: 102 },
    'average-wmi-days-npd': { row: 70, col: 53 },
    'percentage-born-losses': { row: 80, col: 79 },
    'percentage-born-mummified': { row: 88, col: 102 },
    'percentage-born-stillborn': { row: 72, col: 102 },
    'farrowing-rate': { row: 126, col: 10 },
    'farrowing-rate-heat-repetition': { row: 90, col: 33 },
    'farrowing-rate-abortion': { row: 108, col: 33 },
    'farrowing-rate-fake-pregnancy': { row: 126, col: 33 },
    'farrowing-rate-death-gestation': { row: 144, col: 33 },
    'farrowing-rate-culling-gestation': { row: 162, col: 33 },
    'average-heat-repetition-npd': { row: 90, col: 53 },
    'average-abortion-npd': { row: 108, col: 53 },
    'average-fake-pregnancy-npd': { row: 126, col: 53 },
    'average-death-pregnancy-npd': { row: 144, col: 53 },
    'average-culling-pregnancy-npd': { row: 162, col: 53 },
    'average-born': { row: 110, col: 79 },
    'average-death-empty-npd': { row: 182, col: 53 },
    'average-culling-empty-npd': { row: 200, col: 53 },
    'average-idle-days-npd': { row: 218, col: 53 },
  };

  relations: RelationModel[] = [
    {
      fromKPI: 'litters-female-year',
      toKPI: 'pigs-weaned-mated-female-year',
      directRelation: true,
      isVertical: false,
    },
    {
      fromKPI: 'average-weaned-pigs',
      toKPI: 'pigs-weaned-mated-female-year',
      directRelation: true,
      isVertical: false,
    },
    {
      fromKPI: 'average-born-alive',
      toKPI: 'average-weaned-pigs',
      directRelation: true,
      isVertical: false,
    },
    {
      fromKPI: 'average-born-alive',
      toKPI: 'average-weaned-pigs',
      directRelation: true,
      isVertical: false,
    },
    {
      fromKPI: 'weaning-mortality-related',
      toKPI: 'average-weaned-pigs',
      directRelation: true,
      isVertical: false,
    },
    {
      fromKPI: 'percentage-born-losses',
      toKPI: 'average-born-alive',
      directRelation: true,
      isVertical: false,
    },
    {
      fromKPI: 'percentage-born-stillborn',
      toKPI: 'percentage-born-losses',
      directRelation: true,
      isVertical: true,
    },
    {
      fromKPI: 'percentage-born-mummified',
      toKPI: 'percentage-born-losses',
      directRelation: true,
      isVertical: true,
    },
    {
      fromKPI: 'average-born',
      toKPI: 'percentage-born-losses',
      directRelation: false,
      isVertical: false,
    },
    {
      fromKPI: 'average-gestation-length',
      toKPI: 'litters-female-year',
      directRelation: true,
      isVertical: false,
    },
    {
      fromKPI: 'average-lactating-length',
      toKPI: 'litters-female-year',
      directRelation: true,
      isVertical: false,
    },
    {
      fromKPI: 'average-non-productive-days',
      toKPI: 'litters-female-year',
      directRelation: true,
      isVertical: false,
    },
    {
      fromKPI: 'average-wmi-days-npd',
      toKPI: 'average-non-productive-days',
      directRelation: true,
      isVertical: false,
    },
    {
      fromKPI: 'average-heat-repetition-npd',
      toKPI: 'average-wmi-days-npd',
      directRelation: false,
      isVertical: false,
    },
    {
      fromKPI: 'average-abortion-npd',
      toKPI: 'average-heat-repetition-npd',
      directRelation: false,
      isVertical: false,
    },
    {
      fromKPI: 'average-fake-pregnancy-npd',
      toKPI: 'average-abortion-npd',
      directRelation: false,
      isVertical: false,
    },
    {
      fromKPI: 'average-death-pregnancy-npd',
      toKPI: 'average-fake-pregnancy-npd',
      directRelation: false,
      isVertical: false,
    },
    {
      fromKPI: 'average-culling-pregnancy-npd',
      toKPI: 'average-death-pregnancy-npd',
      directRelation: false,
      isVertical: false,
    },
    {
      fromKPI: 'average-death-empty-npd',
      toKPI: 'average-culling-pregnancy-npd',
      directRelation: false,
      isVertical: false,
    },
    {
      fromKPI: 'average-culling-empty-npd',
      toKPI: 'average-death-empty-npd',
      directRelation: false,
      isVertical: false,
    },
    {
      fromKPI: 'average-idle-days-npd',
      toKPI: 'average-culling-empty-npd',
      directRelation: false,
      isVertical: false,
    },
    {
      fromKPI: 'average-heat-repetition-npd',
      toKPI: 'farrowing-rate-heat-repetition',
      directRelation: false,
      isVertical: true,
    },
    {
      fromKPI: 'average-abortion-npd',
      toKPI: 'farrowing-rate-abortion',
      directRelation: false,
      isVertical: true,
    },
    {
      fromKPI: 'average-fake-pregnancy-npd',
      toKPI: 'farrowing-rate-fake-pregnancy',
      directRelation: false,
      isVertical: true,
    },
    {
      fromKPI: 'average-death-pregnancy-npd',
      toKPI: 'farrowing-rate-death-gestation',
      directRelation: false,
      isVertical: true,
    },
    {
      fromKPI: 'average-culling-pregnancy-npd',
      toKPI: 'farrowing-rate-culling-gestation',
      directRelation: false,
      isVertical: true,
    },
    {
      fromKPI: 'farrowing-rate-abortion',
      toKPI: 'farrowing-rate-heat-repetition',
      directRelation: false,
      isVertical: false,
    },
    {
      fromKPI: 'farrowing-rate-fake-pregnancy',
      toKPI: 'farrowing-rate-abortion',
      directRelation: false,
      isVertical: false,
    },
    {
      fromKPI: 'farrowing-rate-death-gestation',
      toKPI: 'farrowing-rate-fake-pregnancy',
      directRelation: false,
      isVertical: false,
    },
    {
      fromKPI: 'farrowing-rate-culling-gestation',
      toKPI: 'farrowing-rate-death-gestation',
      directRelation: false,
      isVertical: false,
    },
    {
      fromKPI: 'farrowing-rate-heat-repetition',
      toKPI: 'farrowing-rate',
      directRelation: true,
      isVertical: true,
    },
    {
      fromKPI: 'farrowing-rate-abortion',
      toKPI: 'farrowing-rate',
      directRelation: true,
      isVertical: true,
    },
    {
      fromKPI: 'farrowing-rate-fake-pregnancy',
      toKPI: 'farrowing-rate',
      directRelation: true,
      isVertical: true,
    },
    {
      fromKPI: 'farrowing-rate-death-gestation',
      toKPI: 'farrowing-rate',
      directRelation: true,
      isVertical: true,
    },
    {
      fromKPI: 'farrowing-rate-culling-gestation',
      toKPI: 'farrowing-rate',
      directRelation: true,
      isVertical: true,
    },
  ];

  private indexLabelPrefix = 'agriness.monitor.performances.';

  constructor(
    protected userStorageService: UserStorageService,
    private cardBuilder: CardBuilderService,
    private monitorService: MonitorAbstractService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(userStorageService);
  }

  loadPerformances(filter: FilterMonitor, withFeedback = true): Observable<MonitorPerformance[]> {
    this.filter = filter;
    this.cards = [];
    if (withFeedback) {
      this.typeFeedback = FeedbackEnum.LOADING;
    }
    this.changeDetectorRef.markForCheck();

    return this.monitorService
      .getMonitorPerformance({
        holdingId: this.holdingId,
        stage: this.stage,
        report: this.report,
        filter: this.filter,
      })
      .pipe(
        map(performances =>
          this.cardBuilder.parsePerformancesToMonitorPerformances(
            performances,
            this.indexLabelPrefix,
            this.filter,
            this.stage,
          ),
        ),
        tap(cards => (this.cards = cards[0].cards)),
        tap(() => {
          this.typeFeedback = this.cards.length === 0 ? FeedbackEnum.NOT_RESULT_SEARCH : null;
        }),
        catchError(err => {
          console.error(`error on '${this.report}' load`, err);
          this.typeFeedback = FeedbackEnum.ERROR;
          return throwError(err);
        }),
        finalize(() => this.changeDetectorRef.markForCheck()),
      );
  }
}
