import { StageEnum } from '@agriness/services';
import { Observable } from 'rxjs';

import {
  PreferenceId,
  TypeViewPreference,
  TypeViewPreferenceDefault,
  TypeViewPreferenceUser,
} from '../models/user.model';

export abstract class UserPreferencesAbstractService {
  abstract getViewPreference(
    type: PreferenceId,
    stage: StageEnum,
  ): Observable<TypeViewPreference[]>;

  abstract getDefaultViewPreference(
    type: PreferenceId,
    stage: StageEnum,
  ): Observable<TypeViewPreferenceDefault>;

  abstract getViewPreferences(
    type: PreferenceId,
    stage: StageEnum,
  ): Observable<TypeViewPreferenceUser>;

  abstract saveViewPreference(
    type: PreferenceId,
    typeViewPreference: TypeViewPreference[],
    stage: StageEnum,
  ): Observable<void>;

  abstract deleteViewPreference(type: PreferenceId, stage: StageEnum): Observable<void>;
}
