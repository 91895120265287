import {
  DietSelectionEntries,
  DietSelectionKeys,
  PerformanceKeys,
} from '@agriness/corp-app/services/models/diet-selection.model';
import { AgrinessTranslateService } from '@agriness/services';
import { TableColumn } from '@agriness/ui';
import { Component, ViewChild, Input, OnChanges } from '@angular/core';
import { Table } from 'primeng/table';

@Component({
  selector: 'diet-selection-table',
  templateUrl: './diet-selection-table.component.html',
})
export class DietSelectionTableComponent implements OnChanges {
  private static COLUMN_HEADER_PREFIX = 'agriness.diet_selection.content.';

  @ViewChild('table') table: Table;

  @Input() data?: DietSelectionEntries;

  columns: TableColumn[] = [];
  height = '252px';

  constructor(private translate: AgrinessTranslateService) {}

  ngOnChanges(): void {
    this.loadColumns();
  }

  private loadColumns(): void {
    const customWidthsByKeys: Partial<Record<DietSelectionKeys | PerformanceKeys, string>> = {
      [PerformanceKeys.CURRENT_AGE]: '53px',
      [DietSelectionKeys.CURRENT_DIET]: '90px',
      [DietSelectionKeys.PROPOSED_DIET]: '100px',
      [DietSelectionKeys.COMMENTS]: '40px',
    };

    this.columns = this.getAllColumnsOrdered().map(key => ({
      field: key,
      header: key !== DietSelectionKeys.COMMENTS ? this.getIndexName(key) : null,
      width: customWidthsByKeys[key],
    }));
  }

  private getAllColumnsOrdered(): (DietSelectionKeys | PerformanceKeys)[] {
    return [
      PerformanceKeys.CURRENT_AGE,
      PerformanceKeys.EGG_PRODUCTION_HEN_DAY,
      PerformanceKeys.CASE_WEIGHT,
      PerformanceKeys.AVERAGE_BODY_WEIGHT,
      PerformanceKeys.CUMULATIVE_MORTALITY_RATE_TOTAL,
      PerformanceKeys.WEEKLY_TOTAL_FEED,
      DietSelectionKeys.CURRENT_DIET,
      DietSelectionKeys.PROPOSED_DIET,
      DietSelectionKeys.COMMENTS,
    ];
  }

  private getIndexName(indexKey: string): string {
    return this.translate.instantWithFallback([
      DietSelectionTableComponent.COLUMN_HEADER_PREFIX + indexKey,
    ]);
  }
}
