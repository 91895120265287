import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../common/shared.module';
import { AgLinkComponent } from './ag-link.component';

@NgModule({
  declarations: [AgLinkComponent],
  imports: [SharedModule, RouterModule],
  exports: [AgLinkComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgLinkModule {}
