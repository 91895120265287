<div
  data-testid="status-target-representation-container"
  class="status-target-representation-container"
>
  <div
    *ngFor="let item of statusItems; let index = index"
    class="status-target-representation-items"
    [ngStyle]="item.overflow ? { overflow: 'visible', 'z-index': index + 1 } : {}"
  >
    <div>
      <ag-target-indicator
        *ngIf="item.iconPosition"
        [class]="'icon-content-' + item.iconPosition"
        [attr.data-testid]="'status-target-representation-icon-' + item.status"
        [status]="item.status"
        [iconSize]="iconSize"
      >
      </ag-target-indicator>
    </div>
    <div
      [ngStyle]="item.overflow ? { width: item.overflow + '%' } : {}"
      [class]="item.noBarSeparator ? 'bar-content-without-separator' : 'bar-content'"
      [attr.data-testid]="'status-target-representation-bar-' + item.status"
    >
      <div *ngIf="statusItems.length" class="horizontal-status-bar">
        <div
          [attr.data-testid]="'horizontal-status-bar-' + item.status"
          [title]="item.description ? item.description : ''"
          [style.width.%]="item.percentage"
          [ngClass]="statusClass[item.status] || defaultStatusClass"
        ></div>
      </div>
    </div>
  </div>
</div>
