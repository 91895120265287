import {
  ModalStatusEnum,
  DietFormData,
} from '@agriness/corp-app/services/models/diet-selection.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'diet-selection-status-component',
  templateUrl: './diet-selection-status.component.html',
  styleUrls: ['./diet-selection-status.styles.scss'],
})
export class DietSelectionStatusComponent {
  @Input() status: ModalStatusEnum;
  @Input() message: string;
  @Input() isEditing: boolean;
  @Output() back = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  ModalStatusEnum = ModalStatusEnum;
  formData: DietFormData;

  translationContext = 'agriness.diet_selection.status.';

  backToDietSelection(): void {
    this.back.emit();
  }

  closeDialog(): void {
    this.cancel.emit();
  }
}
