import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LookerPanel, LookerPanelFilter } from '../models/looker-panel.model';

@Injectable({
  providedIn: 'root',
})
export abstract class LookerPanelAbstractService {
  abstract list(
    stage: string,
    type: string,
    lookwePanelFilter: LookerPanelFilter,
  ): Observable<LookerPanel[]>;
}
