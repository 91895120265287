import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CorpIndexMapGeneratorComponent } from '../../shared/component/corp-index-map-generator/corp-index-map-generator.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PerformanceAnalysisComponent } from './performance-analysis/performance-analysis.component';
import { RecordListComponent } from './record-list/record-list.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'animal-group-list', component: RecordListComponent },
  { path: 'index-map-generator', component: CorpIndexMapGeneratorComponent },
  { path: 'performance-analysis', component: PerformanceAnalysisComponent },
];

export const routerAnalysis: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);

@NgModule({
  imports: [routerAnalysis],
  exports: [RouterModule],
})
export class AnalysisRoutingModule {}
