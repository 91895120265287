<section class="section is-full">
  <div class="page-title mb-3">
    {{ titleKey | translate }}
  </div>

  <div class="tabs">
    <ul class="nav nav-tabs nav-inline mb-4" id="nav-tab">
      <li class="nav-item" *ngFor="let tab of tabs">
        <a class="nav-link" routerLinkActive="active" [id]="tab.router" [routerLink]="tab.router">
          {{ tab.translateKey | translate }}
        </a>
      </li>
    </ul>
  </div>

  <div class="tab-content" id="nav-content">
    <router-outlet></router-outlet>
  </div>
</section>
