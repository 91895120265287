import { SectionAbstractComponent } from '@agriness/corp-app/shared/component/section-abstract.component';
import { SectionModel, SectionService } from '@agriness/ui';
import { Component } from '@angular/core';

@Component({
  selector: 'farm-section',
  templateUrl: './farm-section.component.html',
})
export class FarmSectionComponent extends SectionAbstractComponent {
  constructor(protected sectionService: SectionService) {
    super(sectionService);
  }

  protected getSections(): SectionModel[] {
    return [];
  }
}
