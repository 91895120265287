<div class="mb-3">
  <corp-filters-container
    #filtersContainer
    (filter)="onFilter()"
    (ready)="ready.emit()"
    (valid)="valid.emit($event)"
    [showFilterButton]="showFilterButton"
  >
    <ng-container *ngFor="let filter of visibleFilters">
      <ng-container [ngSwitch]="filter.type">
        <corp-age-filter *ngSwitchCase="'AGE'"></corp-age-filter>

        <corp-animal-group-filter *ngSwitchCase="'ANIMAL_GROUP'"></corp-animal-group-filter>

        <corp-company-filter *ngSwitchCase="'COMPANY'"></corp-company-filter>

        <corp-current-cycle-filter *ngSwitchCase="'CURRENT_CYCLE'"></corp-current-cycle-filter>

        <corp-farm-filter *ngSwitchCase="'FARM'" [stage]="stage"></corp-farm-filter>

        <corp-is-open-filter
          *ngSwitchCase="'IS_OPEN'"
          [animalGroupStatus]="filter.animalGroupStatus || animalGroupStatus"
          [nullable]="filter.nullable"
          [initialValue]="filter.initialValue"
        ></corp-is-open-filter>

        <corp-last-animal-group-filter *ngSwitchCase="'LAST_ANIMAL_GROUP'">
        </corp-last-animal-group-filter>

        <corp-nutrition-type-filter *ngSwitchCase="'NUTRITION_TYPE'"> </corp-nutrition-type-filter>

        <corp-period-filter
          *ngSwitchCase="'PERIOD'"
          [rangeLimitInDays]="filter.rangeLimit"
          [periodDefaultValue]="filter.periodDefaultValue || periodDefaultValue"
          [periodLabels]="filter.periodLabels || periodLabels"
          [hasMaxDate]="hasMaxDate"
        ></corp-period-filter>

        <corp-producer-filter *ngSwitchCase="'PRODUCER'" [stage]="stage"> </corp-producer-filter>

        <corp-production-method-filter *ngSwitchCase="'PRODUCTION_METHOD'">
        </corp-production-method-filter>

        <corp-production-system-filter *ngSwitchCase="'PRODUCTION_SYSTEM'">
        </corp-production-system-filter>

        <corp-region-filter *ngSwitchCase="'REGION'" [stage]="stage"></corp-region-filter>

        <corp-route-filter *ngSwitchCase="'ROUTE'"></corp-route-filter>

        <corp-site-filter *ngSwitchCase="'SITE'"></corp-site-filter>

        <corp-strain-filter *ngSwitchCase="'STRAIN'"></corp-strain-filter>

        <corp-target-status-kpi-filter *ngSwitchCase="'TARGET_STATUS_KPI'" [stage]="stage">
        </corp-target-status-kpi-filter>

        <corp-technician-filter
          *ngSwitchCase="'TECHNICIAN'"
          [hasUser]="filter.hasUser"
          [stage]="stage"
        ></corp-technician-filter>
      </ng-container>
    </ng-container>
  </corp-filters-container>

  <div>
    <corp-report-filters-subtitle></corp-report-filters-subtitle>
  </div>

  <corp-warning-feedback *ngIf="limitedPeriod">
    {{
      'agriness.performance_analysis.period_limitation_warning' | translate: { n: limitedPeriod }
    }}
  </corp-warning-feedback>
</div>
