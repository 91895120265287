<div
  #step
  class="step"
  tabindex="0"
  [ngClass]="{ 'is-filled': done, 'focus-within': hasFocusWithin }"
  (click)="onClick()"
>
  <div class="step-indicator mr-3">
    <span class="step-indicator-text">{{ indicator }}</span>
  </div>
  <div class="step-body wrapper">
    <h5 class="step-title title font-weight-bold">{{ label }}</h5>
    <h6 *ngIf="description" class="step-description text is-regular mb-3">{{ description }}</h6>
    <div class="step-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
