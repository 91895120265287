<div class="row pb-3">
  <div class="col">
    <div class="card shadow-sm">
      <div class="card-body">
        <div class="d-flex flex-grow-1">
          <ng-container *ngTemplateOutlet="topContent"></ng-container>
        </div>
        <div class="d-flex flex-row" [style.min-height]="contentMinHeight">
          <ng-container *ngTemplateOutlet="leftContent"></ng-container>

          <div class="position-relative flex-grow-1 overflow-hidden">
            <ng-container *ngTemplateOutlet="rightContent"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
