import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { SharedModule } from '../../common/shared.module';
import { AgAutocompleteComponent } from './ag-autocomplete.component';

@NgModule({
  imports: [SharedModule, FormsModule, AutoCompleteModule],
  declarations: [AgAutocompleteComponent],
  exports: [AgAutocompleteComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgAutocompleteModule {}
