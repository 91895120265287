import {
  AnimalGroupAbstractService,
  AnimalGroupList,
  AnimalGroupPerformance,
  SearchFilterAnimalGroupList,
  TypeViewPreference,
  ViewPreferenceEnum,
  IndexConfiguration,
  AnimalGroups,
} from '@agriness/corp-app/services';
import { DisplayMode } from '@agriness/corp-app/services/models/diet-selection.model';
import { DocumentType } from '@agriness/corp-app/services/models/document-type.model';
import { DownloadStatus } from '@agriness/corp-app/services/models/download-status.model';
import { AgrinessTranslateService } from '@agriness/services';
import {
  DateService,
  StageEnum,
  UserStorageService,
  TypeProductionEnum,
  TypeProductionService,
  TranslateInstant,
  TRANSLATE_INSTANT,
} from '@agriness/services';
import {
  TableColumn,
  FeedbackEnum,
  checkboxColumn,
  BulkActionsService,
  BulkActionsItem,
  BulkActionsButton,
  QueueManagerService,
  QueueItem,
  TargetStatus,
} from '@agriness/ui';
import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { kebabCase, get } from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, tap, catchError, switchMap } from 'rxjs/operators';

import { objectKeysToLowerCase } from '../../../utils/functions.util';
import { ReportFilterType } from '../../model/report-filter.model';
import { Action, RowData, RowObject } from '../../model/table.model';
import { IndexTranslateService } from '../../services/index-translate.service';
import { TableService, DataTableExport } from '../../services/table.service';
import { LabelPrefixOrProvider } from '../../services/view-preference.service';
import { CorpPreferenceToggleColumnComponent } from '../corp-preference-toggle-column/corp-preference-toggle-column.component';
import { CorpReportFiltersComponent } from '../corp-report-filters/corp-report-filters.component';
import { DietSelectionComponent } from '../diet-selection/diet-selection.component';
import { LoaderUserPreference } from '../loader-user-preference';
import {
  columnsWithTooltipByProduction,
  columnWidth,
  hiddenColumnsClosedByProduction,
  hiddenColumnsOpenByProduction,
  frozenColumnByProduction,
  FrozenColumn,
  orderDefault,
  IsOpenEnum,
  orderIndicator,
} from './corp-animal-group-list.model';

@Component({
  selector: 'corp-animal-group-list',
  templateUrl: './corp-animal-group-list.component.html',
})
export class CorpAnimalGroupListComponent extends LoaderUserPreference
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() filterIds: ReportFilterType[];
  @ViewChild('reportFilter', { static: true }) reportFilter: CorpReportFiltersComponent;
  @ViewChild('preferences', { static: true }) preferences: CorpPreferenceToggleColumnComponent;
  @ViewChild('dt', { static: true }) pTable: DataTableExport;
  // TODO: Remove on product separation
  @ViewChild('dietSelection', { static: true }) dietSelection: DietSelectionComponent;

  FeedbackEnum = FeedbackEnum;
  DocumentType = DocumentType;

  pageTitle = 'agriness.animal_group_list.title';
  sectionTitle = 'agriness.animal_group_list.selected';
  preferenceType = ViewPreferenceEnum.ANIMALGROUP_LIST;
  preferenceLabelProvider = this.getIndexName.bind(this) as LabelPrefixOrProvider;
  columnsWithTooltip: string[];
  frozenColumns: FrozenColumn[];
  stage: StageEnum;
  typeProduction: TypeProductionEnum;
  defaultTableWidth = '140px';
  downloadFilename: string;

  currentFilter: SearchFilterAnimalGroupList = {};
  loading = true;
  error = false;
  totalRecords: number;
  rows: number;
  rowsPerPageOptions = [5, 10, 20, 40, 60, 100];
  dateFormat: string;
  allTableColumns: TableColumn[] = [];
  frozenTableColumns: TableColumn[] = [];
  scrollableTableColumns: TableColumn[] = [];
  hiddenColumns: string[];
  animalGroupList: RowData<AnimalGroupList>[];
  selectedAnimalGroup: RowData<AnimalGroupList>[] =
    this.typeProductionService.get() === TypeProductionEnum.LAYERS ? [] : null;
  indexWithStatus: string[];
  periodLabels = {
    startDateLabelKey: 'agriness.animal_group_list.start_date',
    endDateLabelKey: 'agriness.animal_group_list.end_date',
  };
  displayModeList = Object.values(DisplayMode);
  trackEvent = true;
  indexConfiguration = new BehaviorSubject<IndexConfiguration>({});

  sortNumber = 1;
  sortData = undefined;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    public tableService: TableService,
    protected userStorageService: UserStorageService,
    private animalGroupService: AnimalGroupAbstractService,
    private bulkAction: BulkActionsService,
    private dateService: DateService,
    private indexTranslate: IndexTranslateService,
    private queueManager: QueueManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: AgrinessTranslateService,
    private typeProductionService: TypeProductionService,
  ) {
    super(userStorageService);
    this.typeProduction = this.typeProductionService.get();
    this.columnsWithTooltip = columnsWithTooltipByProduction[this.typeProduction];
  }

  ngOnInit(): void {
    this.stage = this.route.snapshot.data.stage as StageEnum;
    this.frozenColumns = frozenColumnByProduction[this.typeProduction].filter(
      column => column.stage === this.stage,
    );
    this.dateFormat = this.dateService.getDateFormat();
  }

  ngOnDestroy(): void {
    this.closeBulkAction();
  }

  ngAfterViewInit(): void {
    this.loadPreferences().subscribe(() => this.onPreferenceChange());
  }

  filter(): void {
    this.pTable.reset();
  }

  onPreferenceChange(): void {
    this.loadColumns();
    this.filter();
  }

  loadLazy(event: LazyLoadEvent): void {
    this.error = false;
    this.loading = true;
    this.rows = (event ? event.rows : this.rows) || 10;
    this.updateCurrentFilter(event);
    this.updateDownloadFilename();
    this.loadColumns();
    this.loadAnimalGroupList().subscribe();
  }

  onCheckboxChange(): void {
    this.openBulkAction();
  }

  onHeaderCheckboxToggle(): void {
    this.openBulkAction();
  }

  getIndexName(indexKey: string): string {
    const prefix = ['agriness.animal_group_list.'];

    return this.indexTranslate.instant(indexKey, prefix, this.stage);
  }

  loadPreferences(): Observable<unknown> {
    return this.preferences.load();
  }

  getPreferences(): TypeViewPreference[] {
    this.preferences.setHiddenItems(this.hiddenColumns);
    return this.preferences.getPreferences();
  }

  getCurrentFilter(): SearchFilterAnimalGroupList {
    return this.currentFilter;
  }

  updateCurrentFilter(event?: LazyLoadEvent): void {
    let page = 1;

    if (event.sortField === this.sortData && event.sortOrder === this.sortNumber) {
      page = (event ? event.first / (event?.rows || 1) : 0) + 1;
      this.sortData = event.sortField;
      this.sortNumber = event.sortOrder;
    } else {
      page = 1;
      this.sortData = event.sortField;
      this.sortNumber = event.sortOrder;
    }
    this.currentFilter = this.reportFilter.getCurrentFilterInQueryFormat();
    const indicatorFromUrl = this.route.snapshot.queryParamMap.get('performance_indicator');
    const sortField = event.sortField
      ? event.sortOrder > 0
        ? event.sortField
        : '-' + event.sortField
      : this.getOrderDefault(indicatorFromUrl);

    this.currentFilter = {
      ...this.currentFilter,
      page: page,
      per_page: this.rows,
      order: sortField,
    };
  }

  download(documentType: DocumentType): void {
    const indexKey = this.allTableColumns
      .map(({ field }) => field)
      .join(',')
      .toUpperCase();
    this.addDownloadToQueue(
      this.animalGroupService.downloadList({
        ...this.getCurrentFilter(),
        holding_id: this.holdingId,
        stage: this.stage,
        index_keys: indexKey,
        data_format: documentType,
      }),
    );
  }

  private addDownloadToQueue(downloadObservable: Observable<DownloadStatus>): void {
    const t = (key: string): string => this.t(`agriness.download_feedback.${key}`);
    this.queueManager
      .add(downloadObservable, t('waiting'))
      .pipe(
        tap(({ actions }: QueueItem) => actions.update(t('in_progress'))),
        switchMap(({ payload: download, actions }: QueueItem) => {
          return (download as Observable<DownloadStatus>).pipe(
            tap((status: DownloadStatus) => {
              if (status === DownloadStatus.NO_CONTENT) {
                actions.error(t('no_content'));
              } else {
                actions.success(t('success'));
              }
            }),
            catchError(err => {
              actions.error(t('error'));
              return throwError(err);
            }),
          );
        }),
      )
      .subscribe();
  }

  private getOrderDefault(performanceIndicator?: string): string {
    const orderByIndicator = orderIndicator[this.typeProduction][this.stage]?.[
      performanceIndicator
    ] as string;
    if (orderByIndicator) return orderByIndicator;

    const isOpen = (this.currentFilter.is_open || IsOpenEnum.ALL) as IsOpenEnum;
    return orderDefault[this.typeProduction][this.stage][isOpen];
  }

  private openBulkAction(): void {
    const bulkActionButtons: Record<string, BulkActionsButton> = {
      chartView: {
        icon: 'icon-visibility',
        action: () => this.openChartView(),
        text: this.t('agriness.animal_group_list.charts_view'),
      },
      dietSelection: {
        icon: 'icon-feed',
        action: () => this.addDiet(),
        text: this.t('agriness.diet_selection.buttons.add_diet'),
      },
    };

    const bulkAction: BulkActionsItem = {
      quantity: this.pTable.selectedData?.length,
      total: this.totalRecords,
      buttons: Object.values(bulkActionButtons),
      clean: () => this.closeBulkAction(),
    };

    this.bulkAction.add(bulkAction);
  }

  private closeBulkAction() {
    this.pTable.selectedData = [];
    this.selectedAnimalGroup = [];
    this.onCheckboxChange();
  }

  // TODO: Remove on product separation
  private addDiet(): void {
    const animalGroups = this.pTable.selectedData.map(
      (animalGroupRowData: RowData<AnimalGroupList>) => animalGroupRowData.object.animal_group,
    );

    this.dietSelection.displayDialogInFlockList(DisplayMode.ADDING, animalGroups);
  }

  private openChartView() {
    const flockList = this.pTable.selectedData
      .map((data: RowData<AnimalGroupList>) => data.object.animal_group.id)
      .join(',');

    void this.router.navigate(['/', this.typeProduction, 'analysis', 'chartview', flockList]);
  }

  private updateDownloadFilename() {
    this.downloadFilename = [
      `${this.t(this.pageTitle)}`,
      this.dateService.formatAsIsoDate(this.currentFilter.begin_date),
      this.dateService.formatAsIsoDate(this.currentFilter.end_date),
    ].join(' - ');
  }

  private loadAnimalGroupList(): Observable<RowData<AnimalGroupList>[]> {
    return this.animalGroupService
      .getAnimalGroupList(this.holdingId, this.getCurrentFilter(), this.stage)
      .pipe(
        map(searchResult => {
          this.totalRecords = searchResult.count;
          return searchResult.results;
        }),
        tap(data => {
          if (data.length) this.indexConfiguration.next(data[0].index_configuration);
        }),
        map(data => this.parseAnimalGroupListToRowData(data)),
        tap(rowDatas => {
          this.animalGroupList = rowDatas;
          this.loading = false;
        }),
        catchError(err => {
          this.error = true;
          return throwError(err);
        }),
      );
  }

  private loadColumns() {
    this.indexConfiguration.subscribe(indexConfiguration => {
      this.frozenTableColumns = this.updateColumnsTooltips(
        indexConfiguration,
        this.frozenTableColumns,
      );
      this.scrollableTableColumns = this.updateColumnsTooltips(
        indexConfiguration,
        this.scrollableTableColumns,
      );
    });

    this.hiddenColumns =
      this.currentFilter.is_open === 'true'
        ? hiddenColumnsOpenByProduction[this.typeProduction]
        : hiddenColumnsClosedByProduction[this.typeProduction];

    this.allTableColumns = this.getPreferences()
      .filter(p => this.isColumnsVisible(p))
      .map(preference => ({
        field: preference.index_name,
        header: this.getIndexName(preference.index_name),
        width:
          columnWidth[this.stage][preference.index_name.toLowerCase()] || this.defaultTableWidth,
        sortable: true,
        tooltip: this.getTranslateTooltip(preference.index_name.toLowerCase()),
      }));

    const frozenTableColumns = [];
    for (const frozenColumn of this.frozenColumns) {
      if (frozenColumn.hasCheckbox) {
        frozenTableColumns.push(checkboxColumn);
      }
      for (const column of this.allTableColumns) {
        if (frozenColumn.name === column.field.toLowerCase()) {
          frozenTableColumns.push(column);
        }
      }
    }

    const actionColumn = this.buildDietActionColumn();
    this.frozenTableColumns = this.fixActionAsSecondColumn(frozenTableColumns, actionColumn);

    this.scrollableTableColumns = this.allTableColumns.filter(
      item => !this.frozenTableColumns.includes(item),
    );
  }

  private fixActionAsSecondColumn(
    frozenColumns: TableColumn[],
    actionColumn: TableColumn,
  ): TableColumn[] {
    if (!actionColumn) return frozenColumns;

    const frozenColumnsCopy = [...frozenColumns];
    frozenColumnsCopy.splice(1, 0, actionColumn);

    return frozenColumnsCopy;
  }

  private buildDietActionColumn(): TableColumn {
    if (this.stage !== StageEnum.LAYERS_PRODUCTION) return null;

    return {
      field: 'ACTION',
      width: '84px',
      sortable: false,
      header: 'Action',
    };
  }

  private isColumnsVisible(preference: TypeViewPreference) {
    return preference.enable && !this.hiddenColumns.includes(preference.index_name);
  }

  private getTranslateTooltip(indexName: string, interpolateParams?: Record<string, unknown>) {
    if (!this.columnsWithTooltip.includes(indexName)) {
      return null;
    }
    const customPrefix = 'agriness.animal_group_list.tooltip.';

    return this.indexTranslate.instant(indexName, customPrefix, this.stage, interpolateParams);
  }

  private parseAnimalGroupListToRowData(data: AnimalGroupList[]): RowData<AnimalGroupList>[] {
    const result: RowData<AnimalGroupList>[] = [];
    for (const animalGroup of data) {
      const performanceByIndex = this.getPerformanceByIndex(animalGroup.performances);
      const obj: RowData<AnimalGroupList> = {};
      for (const column of this.allTableColumns) {
        obj[column.field] = this.getValueFromField(column.field, performanceByIndex);
      }
      obj.object = animalGroup;
      obj.actions = this.buildDietActions(animalGroup.animal_group);
      result.push(obj);
    }
    return result;
  }

  private buildDietActions(animalGroup: AnimalGroups): { [key in string]: Action } {
    const actions: Record<string, Action> = {};
    const iconMapping: Record<DisplayMode, string> = {
      [DisplayMode.ADDING]: 'icon icon-feed is-16 feed',
      [DisplayMode.EDITING]: 'icon icon-edit is-16 edit',
    };

    for (const mode of this.displayModeList) {
      actions[mode] = {
        icon: iconMapping[mode],
        event: () => this.dietSelection.displayDialogInFlockList(mode, [animalGroup]),
      };
    }

    return actions;
  }

  private getPerformanceByIndex(
    performances: AnimalGroupPerformance[],
  ): { [k: string]: AnimalGroupPerformance } {
    const result = {};
    for (const p of performances) {
      result[p.index_name.toLowerCase()] = p;
    }
    return result;
  }

  private getValueFromField(
    field: string,
    performanceByIndex: { [k: string]: AnimalGroupPerformance },
  ): RowObject {
    const performance = get(performanceByIndex, field?.toLowerCase());
    if (!performance) {
      return null;
    }

    return {
      id: kebabCase(field),
      value: performance.index_value,
      measurement_unit: performance.measurement_unit,
      decimal_places: performance.decimal_places,
      status: this.getStatus(performance),
    };
  }

  private getStatus(performance: AnimalGroupPerformance): TargetStatus {
    const isLayers = this.typeProductionService.isLayers();
    const shouldShowOnPoultry =
      this.typeProductionService.isPoultry() && this.currentFilter.is_open === 'true';
    const shouldShowStatus = isLayers || shouldShowOnPoultry;

    if (shouldShowStatus) return performance.status;
  }

  private updateColumnsTooltips(indexConfiguration: IndexConfiguration, columns: TableColumn[]) {
    const keysTarget: IndexConfiguration = objectKeysToLowerCase(
      indexConfiguration,
    ) as IndexConfiguration;

    const changeValueTooltip = columns.map(column => {
      const columnFieldName = column.field.toLowerCase();

      if (keysTarget[columnFieldName]) {
        return {
          ...column,
          tooltip: this.getTranslateTooltip(columnFieldName, keysTarget[columnFieldName]),
        };
      }
      return column;
    });
    return changeValueTooltip;
  }
}
