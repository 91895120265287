import { Icons } from '@agriness/assets';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'corp-modal-deletion',
  templateUrl: './corp-modal-deletion.component.html',
  styleUrls: ['./corp-modal-deletion.component.scss'],
})
export class CorpModalDeletionComponent {
  @Input() modalStatus = ModalStatusEnum.DEFAULT;
  @Input() titleIcon: Icons = null;
  @Input() title = '';
  @Input() visible = false;
  @Input() translationContext = 'agriness.feedback_deletion_modal';

  @Output() cancel = new EventEmitter<null>();
  @Output() delete = new EventEmitter<null>();

  ModalStatusEnum = ModalStatusEnum;

  onCancel(): void {
    this.cancel.emit();
  }

  onDelete(): void {
    this.delete.emit();
  }
}
