import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { MainForm } from '../rule-editor-form.model';

@Component({
  selector: 'app-step-rules',
  templateUrl: './step-rules.component.html',
  styleUrls: ['./step-rules.component.scss'],
})
export class StepRulesComponent implements OnInit {
  @Input() formData: BehaviorSubject<MainForm>;

  data: MainForm;

  labels: string[];

  constructor(private t: TranslateService) {}

  ngOnInit(): void {
    this.formData.subscribe(res => {
      this.data = res;
    });

    this.labels = [
      'agriness.payment-rule-configuration.config.modal.range',
      'agriness.payment-rule-configuration.config.modal.percentage',
    ];
  }
}
