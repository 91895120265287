import { AnimalGroup } from '@agriness/corp-app/services';
import { DateService } from '@agriness/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { ValueFormatPipe } from '@agriness/ui';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class AnimalGroupService {
  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private dateService: DateService,
    private valueFormat: ValueFormatPipe,
  ) {}

  getDownloadFilename(animalGroup: AnimalGroup, section: string): string {
    return [
      `${this.t('agriness.record_animal_group')} ${animalGroup.name}`,
      this.t(section),
      this.dateService.formatAsIsoDate(new Date()),
    ].join(' - ');
  }

  showNumberAsDifference(n: number): string {
    return `${n > 0 ? '+' : ''}${this.valueFormat.transform(n, 'DECIMAL')}`;
  }
}
