import { Component, Input, OnInit, OnChanges } from '@angular/core';

import { TargetStatus } from '../../target/target.model';
import { StatusItems } from '../status.model';

@Component({
  selector: 'ag-horizontal-status-bar',
  templateUrl: './ag-horizontal-status-bar.component.html',
})
export class AgHorizontalStatusBarComponent implements OnInit, OnChanges {
  @Input() items: StatusItems = [];

  defaultStatusClass = 'status-default';
  statusClass: { [k in TargetStatus]: string } = {
    [TargetStatus.ATTENTION]: 'status-attention',
    [TargetStatus.BAD]: 'status-bad',
    [TargetStatus.GOOD]: 'status-good',
  };
  statusItems = [];

  ngOnInit(): void {
    this.updateStatusItems();
  }

  ngOnChanges(): void {
    this.updateStatusItems();
  }

  private sumOfPercentages() {
    return this.items.reduce((percentage, item) => percentage + item.percentage, 0);
  }

  private updateStatusItems() {
    const percentageTotal = this.sumOfPercentages();

    if (percentageTotal < 100) {
      this.statusItems = [
        ...this.items,
        {
          percentage: 100 - percentageTotal,
          status: this.defaultStatusClass,
        },
      ];
    } else {
      this.statusItems = this.items;
    }
  }
}
