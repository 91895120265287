import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../common/shared.module';
import { AgLineComponent } from './ag-line.component';

@NgModule({
  declarations: [AgLineComponent],
  imports: [SharedModule, RouterModule],
  exports: [AgLineComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgLineModule {}
