export enum IdentifyDisplayType {
  QUADRANT = 'QUADRANT',
  TABLE = 'TABLE',
}

export const displayTypeIcon: { [k in IdentifyDisplayType]: string } = {
  [IdentifyDisplayType.QUADRANT]: 'icon-report1',
  [IdentifyDisplayType.TABLE]: 'icon-column-view',
};

export type Interval = {
  x_min: number;
  x_min_readonly?: string;
  x_max: number;
  x_max_readonly?: string;
  y_min: number;
  y_min_readonly?: string;
  y_max: number;
  y_max_readonly?: string;
};
