import { ProductionMethod } from '@agriness/corp-app/services/models/production-method';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, SelectComponent } from '../corp-filter-abstractions';
import { FixedLoaderService, SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-production-method-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.select.html',
  providers: [{ provide: FilterComponent, useExisting: CorpProductionMethodFilterComponent }],
})
export class CorpProductionMethodFilterComponent extends SelectComponent {
  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(translate, containerService, activeRoute);

    this.assignDefaults(
      'productionMethod',
      new SelectLoader(
        new FixedLoaderService(
          [
            [ProductionMethod.CONVENTIONAL, 'agriness.filter.production_method.conventional'],
            [ProductionMethod.ORGANIC, 'agriness.filter.production_method.organic'],
          ],
          translate,
        ),
      ),
    );
  }
}
