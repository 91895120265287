<div class="d-flex flex-fill justify-content-center">
  <div class="feedback">
    <img
      [class]="feedBack.iconClass"
      [attr.data-testid]="feedBack.id"
      [attr.src]="'assets/media/img/' + feedBack.icon"
      alt=""
    />
  </div>
</div>
