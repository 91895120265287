import {
  MainForm,
  PatchForm,
} from '@agriness/corp-app/settings/payment-rule-section/payment-rule/rule-editor/rule-editor-form.model';
import { UserStorageService, TypeProductionService, Search } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { KPIList, Rule, RuleFilter, Stage } from '../models/bonification.model';
import { BonificationAbstractService } from './bonification-abstract.service';

@Injectable({
  providedIn: 'root',
})
export class BonificationService extends BonificationAbstractService {
  private pathBase = 'holdings/{holding_id}/payment-rules/';
  private pathRuleId = 'holdings/{holding_id}/payment-rules/{rule_id}/';
  private pathKPIList = 'holdings/{holding_id}/payment-rules-kpis-available/';
  private pathStages = 'stages/';
  private defaultPageSize = 10;

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {
    super();
  }

  list({
    page = 1,
    page_size = this.defaultPageSize,
    verbose = false,
    ...filters
  }: RuleFilter): Observable<Search<Rule>> {
    const url = this.apiCorpService.translateUrl(this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
    });

    delete filters.order;
    const params = {
      ...filters,
      page,
      page_size,
      system_type: this.typeProductionService.get(),
      verbose: verbose,
    };
    return this.apiCorpService.get<Search<Rule>>(url, params);
  }

  save(ruleForm: MainForm): Observable<Rule> {
    const url = this.getUrl();
    const body = ruleForm;
    const params = {
      system_type: this.typeProductionService.get(),
    };
    return this.apiCorpService.post<Rule>(url, body, params);
  }

  put(ruleForm: MainForm, ruleId: string): Observable<Rule> {
    const url = this.getUrl(ruleId);
    const body = {
      id: ruleId,
      ...ruleForm,
    };
    const params = {
      system_type: this.typeProductionService.get(),
    };
    return this.apiCorpService.put<Rule>(url, body, params);
  }

  patch(ruleForm: PatchForm, ruleId: string): Observable<Rule> {
    const url = this.getUrl(ruleId);
    const params = {
      system_type: this.typeProductionService.get(),
    };
    return this.apiCorpService.patch<Rule>(url, ruleForm, params);
  }

  delete(rule: Rule): Observable<void> {
    const url = this.getUrl(rule.id);
    const params = {
      system_type: this.typeProductionService.getId(),
    };
    return this.apiCorpService.delete(url, params);
  }

  listKPI(): Observable<Search<KPIList>> {
    const url = this.apiCorpService.translateUrl(this.pathKPIList, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
    });

    return this.apiCorpService.get<Search<KPIList>>(url);
  }

  getStageId(name: string): Observable<Search<Stage>> {
    const url = this.apiCorpService.translateUrl(this.pathStages);
    const body = {
      name: name,
    };

    return this.apiCorpService.get<Search<Stage>>(url, body);
  }

  private getUrl(ruleId?: string): string {
    return this.apiCorpService.translateUrl(ruleId ? this.pathRuleId : this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
      rule_id: ruleId,
    });
  }
}
