<section>
  <div class="full-width">
    <div class="badge-container title-container">
      <p class="subtitle">
        {{ 'agriness.settings.targets.editor.pre-badge' | translate }}
      </p>
      <p class="badge">
        {{ 'agriness.stage.' + stage | translate }}
      </p>
    </div>
  </div>
  <div class="full-width">
    <div class="badge-container title-container">
      <p class="subtitle">
        {{ ('agriness.settings.targets.editor.step_2.target_group' | translate) + ':' }}
      </p>
      <p class="plain-text">
        {{ formDataSubscribed.target_index.group | translate }}
      </p>
    </div>
  </div>
  <div class="full-width">
    <div class="badge-container title-container">
      <p class="subtitle">
        {{ ('agriness.settings.targets.editor.step_2.index' | translate) + ':' }}
      </p>
      <p class="plain-text">
        {{ formDataSubscribed.target_index.name | translate }}
      </p>
    </div>
  </div>
  <div class="full-width">
    <div class="badge-container title-container">
      <p class="subtitle">
        {{ ('agriness.settings.targets.editor.step_2.period_type.title' | translate) + ':' }}
      </p>
      <p class="plain-text">
        {{ 'agriness.targets.period_type.' + formDataSubscribed.period_type | translate }}
      </p>
    </div>
  </div>

  <div class="table-container">
    <p-table [columns]="cols" [value]="formDataSubscribed.target_detail">
      <ng-template pTemplate="header" let-columns>
        <tr class="theader">
          <th *ngFor="let col of columns">
            <span>
              <ag-target-indicator
                *ngIf="col.field !== 'period'"
                class="icon-content-center"
                [attr.data-testid]="
                  'status-target-representation-icon-' + getStatusIndicator(col.field)
                "
                [status]="getStatusIndicator(col.field)"
                [iconSize]="16"
              >
              </ag-target-indicator>
              {{ col.header | translate }}
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-target let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <span>{{ target[col.field] }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</section>
