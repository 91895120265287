import { Injectable } from '@angular/core';

import { Holding } from '../../../models/holding.model';
import { User, UserAgriness, UserPreferences } from '../../../models/user.model';

const KEY = 'userAgriness';

@Injectable({ providedIn: 'root' })
export class UserStorageService {
  getUserAgriness(): UserAgriness {
    const jsonUser = window.localStorage.getItem(KEY);
    return jsonUser ? Object.assign(new UserAgriness(), JSON.parse(jsonUser)) : null;
  }

  getUserPreferences(): UserPreferences {
    const userAgriness = this.getUserAgriness();
    return userAgriness ? userAgriness.userPreferences : null;
  }

  getUserProfile(): User {
    const userAgriness = this.getUserAgriness();
    return userAgriness ? userAgriness.userProfile : null;
  }

  getCurrentHolding(): Holding {
    const userPreferences = this.getUserPreferences();
    return userPreferences ? userPreferences.currentHolding : null;
  }

  isSuperHolding(): boolean {
    const currentHolding = this.getCurrentHolding();
    if (currentHolding) {
      const currentId = currentHolding.id;
      return this.getUserAgriness().holdings.find(({ id }) => id === currentId)['is_super_holding'];
    }
  }

  getLanguage(): string {
    const userProfile = this.getUserProfile();
    return userProfile ? userProfile.language : null;
  }

  getDecimalSeparator(): string {
    const userProfile = this.getUserProfile();

    return userProfile ? userProfile.decimal_separator : null;
  }

  getHelpUserDismiss(): boolean {
    return window.localStorage.getItem('helpUserDismiss') === 'true';
  }

  setHelpUserDismiss(value: boolean): void {
    window.localStorage.setItem('helpUserDismiss', String(value));
  }

  setUserAgriness(userAgriness): void {
    window.localStorage.setItem(KEY, JSON.stringify(userAgriness));
  }

  removeUserAgriness(): void {
    window.localStorage.removeItem(KEY);
  }
}
