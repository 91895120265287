import { NutritionTypeService } from '@agriness/corp-app/services';
import { StageEnum } from '@agriness/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, MultiSelectComponent } from '../corp-filter-abstractions';
import { SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-nutrition-type-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.multiselect.html',
  providers: [{ provide: FilterComponent, useExisting: CorpNutritionTypeFilterComponent }],
})
export class CorpNutritionTypeFilterComponent extends MultiSelectComponent {
  @Input() stage: StageEnum;

  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
    nutritionTypeService: NutritionTypeService,
  ) {
    super(translate, containerService, activeRoute);
    this.assignDefaults(
      'nutritionType',
      new SelectLoader(nutritionTypeService, {
        stage: this.stage,
      }),
    );
  }
}
