import { Stage } from '@agriness/services';

import { MeasurementUnitEnum } from './animal-group-performance.model';
import { ReportCategory } from './report-category.model';
import { Tag } from './tag.model';

export class ReportConfiguration {
  constructor(public id?: string, public stage?: Stage, public report_category?: ReportCategory) {}
}

export interface Index {
  id?: string;
  name?: string;
  key?: string;
  decimal_places?: number;
  measurement_unit?: MeasurementUnitEnum;
  type?: string;
}

export class Report {
  id?: string;
  name?: string;
  internal_name?: string;
  note?: string;
  favorite?: boolean;
  configurations?: ReportConfiguration[];
  tags?: Tag[];
  indexes?: Index[];
}

export class ReportDashboard {
  constructor(
    public count?: number,
    public next?: string,
    public previous?: string,
    public results?: Report[],
  ) {}
}

// idStage: string | number, idCategory: string | number, idTag: string, isFavorites: boolean, sub_report: string
export class ReportFilter {
  constructor(
    public id_stage?: string,
    public id_category?: string,
    public id_tag?: string,
    public is_favorites?: boolean,
    public sub_report?: string,
    public system_type?: string,
  ) {}
}
