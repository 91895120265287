import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'corp-switch-button',
  templateUrl: './corp-switch-button.component.html',
  styleUrls: ['./corp-switch-button.component.scss'],
})
export class CorpSwitchButtonComponent {
  @Output() clickEvent = new EventEmitter<boolean>();
  @Input() checked: boolean = false;

  click(checked: boolean): void {
    this.clickEvent.emit(checked);
  }
}
