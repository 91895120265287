import { LoaderUserPreference } from '@agriness/corp-app/shared/component/loader-user-preference';
import { UserStorageService, DateService } from '@agriness/services';
import { FeedbackEnum } from '@agriness/ui';
import { Component } from '@angular/core';
import { isEmpty } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ScheduleResponse } from '../presence.model';
import { PresenceService } from '../presence.service';
import { Schedule, Cell, ScheduleStatus } from './presence-modal.model';

@Component({
  selector: 'presence-modal',
  templateUrl: './presence-modal.component.html',
  styleUrls: ['./presence-modal.styles.scss'],
})
export class PresenceModalComponent extends LoaderUserPreference {
  display = false;
  feedbackType = FeedbackEnum.LOADING;
  scheduleStatus = ScheduleStatus;
  searchTechnicianSubject: Subject<ScheduleResponse[]>;
  subscription: Subscription;
  schedules: Schedule[];
  total: string;
  date: string;
  today: string;

  constructor(
    protected userStorageService: UserStorageService,
    private dateService: DateService,
    private presenceService: PresenceService,
  ) {
    super(userStorageService);
  }

  displayDialog(cell: Cell): void {
    this.display = !this.display;

    this.loadSchedules(cell);

    this.date = this.dateService.formatDate(cell.date);
    this.today = this.dateService.formatDate(new Date());
  }

  closeDialog(): void {
    this.display = false;
    this.subscription.unsubscribe();
  }

  private loadSchedules(cell: Cell): void {
    const holding_id = this.userStorageService.getCurrentHolding().id;
    this.feedbackType = FeedbackEnum.LOADING;

    const observable = this.presenceService
      .searchTechnicianSchedule(holding_id, cell.date, cell.technician)
      .pipe(
        tap(response => {
          this.feedbackType = isEmpty(response) ? FeedbackEnum.NOT_RESULT : null;

          this.schedules = response.map(schedule => {
            const { status, description, allotment } = schedule;

            return {
              allotment,
              status,
              description,
            };
          });

          this.total = `${response.length}`.padStart(2, '0');
        }),
      )
      .subscribe();

    this.subscription = observable;
  }
}
