import { AgrinessTranslateService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { snakeCase } from 'lodash';

import { FeedbackContent, FeedbackEnum } from './ag-feedback.model';

type Feedback = {
  [k in FeedbackEnum]?: FeedbackContent;
};

@Injectable({
  providedIn: 'root',
})
export class AgFeedbackService {
  private feedback: Feedback = {
    ...this.createFeedback(FeedbackEnum.ERROR, {
      icon: 'icon-refresh.svg',
    }),
    ...this.createFeedback(FeedbackEnum.LOADING, {
      icon: 'icon-filter.svg',
      iconClass: 'icon-glass-searching',
    }),
    ...this.createFeedback(FeedbackEnum.NOT_RESULT, {
      icon: 'icon-filter-attention.svg',
    }),
    ...this.createFeedback(FeedbackEnum.NOT_RESULT_SEARCH, {
      icon: 'icon-filter-attention.svg',
    }),
    ...this.createFeedback(FeedbackEnum.SEARCH, {
      icon: 'icon-filter.svg',
    }),
    ...this.createFeedback(FeedbackEnum.RECORD_SEARCH, {
      icon: 'icon-filter.svg',
    }),
    ...this.createFeedback(FeedbackEnum.NO_DATA_CHART, {
      icon: 'icon-filter-no-data-chart.svg',
    }),
    ...this.createFeedback(FeedbackEnum.LOCK, {
      icon: 'icon-lock.svg',
    }),
    ...this.createFeedback(FeedbackEnum.NO_ITEM_SELECTED, {
      icon: 'icon-filter-attention.svg',
    }),
    ...this.createFeedback(FeedbackEnum.NO_LOOKER_PANEL, {
      icon: 'icon-filter-attention.svg',
    }),
    ...this.createFeedback(FeedbackEnum.INFO, {
      icon: 'icon-info.svg',
    }),
    ...this.createFeedback(FeedbackEnum.PROCESSING, {
      icon: 'icon-feedback-loading.svg',
    }),
  };

  constructor(private translate: AgrinessTranslateService) {}

  getFeedback(feedbackEnum: FeedbackEnum): FeedbackContent {
    return this.feedback[feedbackEnum];
  }

  getFeedbackMessage(feedbackEnum: FeedbackEnum): string {
    return this.translate.instant(this.feedback[feedbackEnum].msg);
  }

  private createFeedback(
    key: FeedbackEnum,
    options: Partial<FeedbackContent> & Pick<FeedbackContent, 'icon'>,
  ): { [k in FeedbackEnum]?: FeedbackContent } {
    const keySnakeCase = snakeCase(key);
    return {
      [key]: {
        id: `icon-${keySnakeCase}`,
        iconClass: '',
        msg: `agriness.feedback.${key.toLowerCase()}`,
        ...options,
      },
    };
  }
}
