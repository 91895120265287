import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { DownloadFileService } from '../download-file/download-file.service';
import { DownloadStatus } from '../models/download-status.model';
import {
  IndexMapGeneratorAbstractService,
  IndexMapGeneratorDownloadArgs,
} from './index-map-generator-abstract.service';

type QueryParams = Omit<
  { [k in keyof IndexMapGeneratorDownloadArgs]: unknown },
  'holding_id' | 'stage'
>;

@Injectable()
export class IndexMapGeneratorService extends IndexMapGeneratorAbstractService {
  private pathBase = 'holdings/{holding_id}/{type_production}/{stage}/reports/index-map-generator/';

  constructor(
    private apiCorpService: ApiCorpService,
    private downloadFileService: DownloadFileService,
  ) {
    super();
  }

  download(params: IndexMapGeneratorDownloadArgs): Observable<DownloadStatus> {
    const { holding_id, stage, ...queryParams } = params;
    const url = this.apiCorpService.translateUrl(this.pathBase, { holding_id, stage });
    return this.downloadFileService.download(url, params.data_format, queryParams as QueryParams);
  }
}
