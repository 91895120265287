import {
  BaseEntityFilter,
  PageNumberPagination,
  ContextFilter,
  QueryCorp,
  StageEnum,
} from '@agriness/services';

export enum ruleEnum {
  BASED = 1,
}

export interface Checklist {
  id?: string;
  name: string;
  items: ChecklistItem[];
  enabled?: boolean;
  stage?: StagePresence[];
  stage_description: string;
  stage_ids: string[];
  start_days_wait_required?: boolean;
  start_days?: number;
  required?: boolean;
  deadline_rule?: ruleEnum;
  deadline_days?: number;
  system_type: string;
  rule_type: string;
  rule_description: string;
  can_delete: boolean;
}

export interface StagePresence {
  id?: string;
  external_id: string;
  name: string;
}

export interface ChecklistItem {
  id?: string;
  name: string;
  score?: number;
  enabled?: boolean;
}

export interface ChecklistForm {
  name: string;
  items: ChecklistItem[];
  stage_ids: string[];
  start_days?: number;
  required?: boolean;
  deadline_rule?: ruleEnum;
  deadline_days?: number;
  enabled: boolean;
  start_days_wait_required?: boolean;
}

export const ExternalPresenceStageID: { [K in StageEnum]: string } = {
  [StageEnum.FINISHING]: '80fc0a69-d0c1-44bb-8d26-de930330289d',
  [StageEnum.GROW_OUT]: '7849cb21-0cff-4049-a97c-1fc4f5e1b259',
  [StageEnum.MILKING]: 'f2ec1581-cbfc-49ab-8a69-a75116ee26d5',
  [StageEnum.NURSERY]: '38d3a5be-9f2e-4fce-8e16-f0b86f592942',
  [StageEnum.LAYERS_PRODUCTION]: '',
  [StageEnum.REPRODUCTIVE]: 'a08accdc-a63a-4f59-ac60-3f04e81a974f',
  [StageEnum.WEAN_TO_FINISH]: '',
};

export const defaultChecklistOrder: (keyof Checklist)[] = ['name'];

export type ChecklistFilter = BaseEntityFilter & PageNumberPagination & ContextFilter & QueryCorp;
