import { textColumn, actionColumn } from '@agriness/ui';

const farmKey = 'agriness.settings.farms.table';

export const farmsTableColumns = [
  {
    ...actionColumn,
    width: '70px',
    fixed: true,
  },
  {
    ...textColumn,
    field: 'name',
    header: `${farmKey}.name`,
    width: '300px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'producer.name',
    header: `${farmKey}.producer_name`,
    width: '300px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'region.name',
    header: `${farmKey}.region_name`,
    width: '300px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'technician.name',
    header: `${farmKey}.technician_name`,
    width: '300px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'route.name',
    header: `${farmKey}.route_name`,
    width: '300px',
    sortable: true,
    fixed: true,
  },
];
