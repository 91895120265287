import { AllotmentType } from '../presence.model';
export interface Schedule {
  allotment?: Allotment;
  status: ScheduleStatus;
  description: string;
}

export interface Allotment {
  id: string;
  name: string;
  external_id: string;
  farm_id: string;
  farm_name: string;
  producer_id?: string;
  producer_name?: string;
  type: AllotmentType;
  finished: boolean;
  walkthrough_available: boolean;
}

export interface Cell {
  date: Date;
  technician: string;
  finished: number;
  total: number;
}

export enum ScheduleStatus {
  FINISHED = 'finished',
  OPENED = 'opened',
}
