import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SearchFilterHolding, SearchHoldings } from '../../../models/holding.model';
import { HoldingAbstractService } from '../../abstract/holding-abstract.service';
import { ApiMyAccountService } from '../../common/api-myaccount.service';

@Injectable()
export class HoldingService extends HoldingAbstractService {
  private pathSearchHolding = 'holdings/';

  constructor(private apiMyAccountService: ApiMyAccountService) {
    super();
  }

  searchHoldings(searchFilter?: SearchFilterHolding): Observable<SearchHoldings> {
    return this.apiMyAccountService.get<SearchHoldings>(this.pathSearchHolding, searchFilter);
  }
}
