<agriness-card [contentTemplate]="contentTemplate"></agriness-card>

<ng-template #contentTemplate>
  <div class="corp-record-table-header">
    <h6 class="title">
      {{ title }}
    </h6>

    <div *ngIf="addClick.observers.length" class="button-container">
      <guavi-button
        icon="add"
        size="medium"
        variant="primary"
        format="circle"
        (onClick)="handleAddClick()"
      ></guavi-button>
    </div>
  </div>

  <ag-table
    #agTable
    [frozenColumns]="frozenTableColumns"
    [columns]="scrollableTableColumns"
    [data]="data"
    [rows]="rows"
    [loading]="loading"
    [error]="error"
    (lazyLoad)="loadLazy($event)"
    [rowsPerPageOptions]="rowsPerPageOptions"
    [totalRecords]="totalRecords"
    [alwaysShowPagination]="true"
  >
    <ng-template pTemplate="bodycell" let-column="column" let-data="data" let-rowData="rowData">
      <ng-container
        *ngIf="column.field === 'ACTION'; then actionsCellTemplate; else defaultCellTemplate"
      ></ng-container>

      <ng-template #actionsCellTemplate>
        <td>
          <div class="corp-record-table-action-column">
            <ng-container *ngFor="let action of actions">
              <ag-table-action-cell
                *ngIf="
                  (action !== 'toggle' &&
                    (actions.includes('toggle') ? rowData.can_delete : true)) ||
                  (hasEditActionOnToggle && action !== 'toggle')
                "
                [icon]="loadActionIcon(rowData, action)"
                [event]="loadActionEvent(rowData, action)"
                [title]="loadActionTooltip(rowData, action)"
              >
              </ag-table-action-cell>
              <ag-table-action-delete-toggle-cell
                *ngIf="action === 'toggle'"
                [item]="rowData"
                [hasToggle]="!rowData.can_delete"
                [checked]="rowData.enabled"
                [eventDelete]="loadActionEvent(rowData, 'delete')"
                (eventToggle)="onToggle($event)"
                [title]="loadActionTooltip(rowData, rowData.can_delete ? 'delete' : action)"
              >
              </ag-table-action-delete-toggle-cell>
            </ng-container>
          </div>
        </td>
      </ng-template>

      <ng-template #defaultCellTemplate>
        <td *ngIf="column.id === 'column-farm-count'">
          <ag-table-cell-tag
            [data]="loadRowCell(rowData, column.field)"
            [column]="column"
          ></ag-table-cell-tag>
        </td>
        <td *ngIf="column.id === 'column-target-index.representation-type'">
          <app-ag-table-target-representation [target]="rowData.target_index.representation_type">
          </app-ag-table-target-representation>
        </td>
        <td
          [ngSwitch]="rowData?.id"
          *ngIf="
            column.id !== 'column-farm-count' &&
            column.id !== 'column-target-index.representation-type'
          "
        >
          <ag-table-cell-content
            *ngSwitchDefault
            [data]="loadRowCell(rowData, column.field)"
            [column]="column"
          ></ag-table-cell-content>
        </td>
      </ng-template>
    </ng-template>
  </ag-table>
</ng-template>
