<corp-modal-form
  [translationContext]="translationContext"
  [modalStatus]="modalStatus"
  [visible]="visible"
  [formValid]="targetForm.valid"
  [title]="title"
  (closed)="onClosed()"
  (formSubmit)="onSubmit()"
  (formRestore)="onRestore()"
>
  <div class="content-wrapper">
    <div class="container-targets">
      <ag-status-target-representation [target]="target"></ag-status-target-representation>

      <form class="target-form" [formGroup]="targetForm">
        <div class="first-row-inputs">
          <ag-number-field
            class="mr-2"
            [inputWidth]="inputWidth"
            [group]="targetForm"
            controlName="min_allowed"
            [disabled]="true"
            [label]="translationContext + '.fields.min_allowed.label' | translate"
          >
          </ag-number-field>
          <ag-number-field
            class="mr-2"
            [inputWidth]="inputWidth"
            [group]="targetForm"
            controlName="min_recommended"
            [errorMessage]="targetFormErrors.min_recommended"
            [label]="translationContext + '.fields.min_recommended.label' | translate"
          ></ag-number-field>

          <ag-number-field
            class="mr-2"
            [inputWidth]="inputWidth"
            [group]="targetForm"
            controlName="target_value"
            [errorMessage]="targetFormErrors.target_value"
            [label]="translationContext + '.fields.target_value.label' | translate"
          >
          </ag-number-field>

          <ag-number-field
            class="mr-2"
            [inputWidth]="inputWidth"
            [group]="targetForm"
            controlName="max_recommended"
            [errorMessage]="targetFormErrors.max_recommended"
            [label]="translationContext + '.fields.max_recommended.label' | translate"
          ></ag-number-field>

          <ag-number-field
            [inputWidth]="inputWidth"
            [group]="targetForm"
            controlName="max_allowed"
            [disabled]="true"
            [label]="translationContext + '.fields.max_allowed.label' | translate"
          ></ag-number-field>
        </div>
        <div class="last-row-inputs" *ngIf="target === representationType.RANGE_BAD_ATTENTION_GOOD">
          <ag-number-field
            [inputWidth]="inputWidth"
            [group]="targetForm"
            controlName="value_variation_rate"
            [errorMessage]="targetFormErrors.value_variation_rate"
            [label]="translationContext + '.fields.value_variation_rate.label' | translate"
          ></ag-number-field>
        </div>
      </form>

      <div class="wrapper-target-subtitles">
        <ag-target-subtitles [targetData]="targetSubtitles"></ag-target-subtitles>
      </div>
    </div>
  </div>
</corp-modal-form>
