import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';

let browserSupportsFocusWithin = true;
try {
  document.querySelector(':focus-within');
} catch (error) {
  browserSupportsFocusWithin = false;
}

@Component({
  selector: 'ag-step',
  templateUrl: './ag-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgStepComponent implements OnDestroy, AfterViewInit {
  constructor(private focusMonitor: FocusMonitor, private changeDetectorRef: ChangeDetectorRef) {}

  @ViewChild('step', { static: true }) step: ElementRef<HTMLElement>;

  @Input() label: string;
  @Input() description: string;
  @Input() indicator: number;
  @Input() done: boolean;

  hasFocusWithin = false;

  ngAfterViewInit() {
    if (!browserSupportsFocusWithin) {
      this.focusMonitor.monitor(this.step, true).subscribe(origin => {
        this.hasFocusWithin = Boolean(origin);
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  ngOnDestroy() {
    this.focusMonitor.stopMonitoring(this.step);
  }

  onClick() {
    if (document.activeElement === document.body) {
      this.focus();
    }
  }

  focus() {
    this.step.nativeElement.focus();
  }
}
