<div *ngIf="form" [hidden]="hidden" class="input-content" [ngStyle]="{ width: inputWidth }">
  <div class="form-group" id="{{ kebabCase(controlName) }}-form-group">
    <label class="control-label"> {{ labelKey | translate }}{{ labelKeySuffix || '' }} </label>
    <ag-select
      [group]="form"
      [controlName]="controlName"
      [items$]="loader.options"
      (searchChange)="loader.filter($event)"
      (scrollToEnd)="loader.paginate()"
      [loading]="loader.loading"
      [defaultLabel]="emptySelectionKey"
      [isSearchable]="isSearchable"
      [clearable]="true"
      [errorMessage]="errorMessage"
    ></ag-select>
  </div>
</div>
