import { TargetService, TargetV2 } from '@agriness/corp-app/services';
import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PeriodTypeEnum } from '../target-editor/target-editor-form.model';

@Component({
  selector: 'target-view',
  templateUrl: './target-view.component.html',
  styleUrls: ['./target-view.component.scss'],
})
export class TargetViewComponent implements OnInit {
  pageTitle = 'agriness.settings.targets.title';

  targetId: string;
  target: TargetV2;
  cols = [];
  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private router: Router,
    private route: ActivatedRoute,
    private targetService: TargetService,
  ) {}

  ngOnInit(): void {
    this.getRoutParams();
    this.getTarget();
  }

  loadColumns(): void {
    this.cols = [
      { field: 'min_allowed', header: 'agriness.settings.targets.editor.table.min_allowed' },
      {
        field: 'min_recommended',
        header: 'agriness.settings.targets.editor.table.min_recommended',
      },
      { field: 'target_value', header: 'agriness.settings.targets.editor.table.target_value' },
      {
        field: 'value_variation_rate',
        header: 'agriness.settings.targets.editor.table.value_variation_rate',
      },
      {
        field: 'max_recommended',
        header: 'agriness.settings.targets.editor.table.max_recommended',
      },
      { field: 'max_allowed', header: 'agriness.settings.targets.editor.table.max_allowed' },
    ];

    const daily = this.t('agriness.targets.period_type.' + PeriodTypeEnum.DAILY);
    const weekly = this.t('agriness.targets.period_type.' + PeriodTypeEnum.WEEKLY);

    if (this.target.period_type == daily) {
      this.cols.unshift({
        field: 'period',
        header: 'agriness.settings.targets.editor.table.daily',
      });
    }

    if (this.target.period_type == weekly) {
      this.cols.unshift({
        field: 'period',
        header: 'agriness.settings.targets.editor.table.weekly',
      });
    }
  }

  getRoutParams(): void {
    this.targetId = this.route.snapshot.queryParams['id'] as string;
  }

  getTarget(): void {
    this.targetService.getV2(this.targetId, true).subscribe(target => {
      const targetDetail = target.target_detail.map(item => {
        return {
          ...item,
          min_allowed: target.target_index.min_allowed,
          max_allowed: target.target_index.max_allowed,
        };
      });
      this.target = target;
      this.target.target_detail = targetDetail;
      this.loadColumns();
    });
  }

  editTarget(): void {
    const urlSplitted = this.router.url.split('/');
    urlSplitted.pop();
    const url = urlSplitted.join('/');
    void this.router.navigate([url + '/edit'], { state: this.target });
  }

  getStatusIndicator(field: string): string {
    if (field == 'min_allowed' || field == 'max_allowed') {
      return 'bad';
    } else if (field == 'min_recommended' || field == 'max_recommended') {
      return 'attention';
    } else {
      return 'good';
    }
  }
}
