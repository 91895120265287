import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SearchFilterProduct, SearchProduct } from '../../../models/product.model';
import { ProductAbstractService } from '../../abstract/product-abstract.service';
import { ApiMyAccountService } from '../../common/api-myaccount.service';

@Injectable()
export class ProductService extends ProductAbstractService {
  private pathSearchProduct = 'products/';

  constructor(private apiMyAccountService: ApiMyAccountService) {
    super();
  }

  searchProducts(searchFilter?: SearchFilterProduct): Observable<SearchProduct> {
    return this.apiMyAccountService.get<SearchProduct>(this.pathSearchProduct, searchFilter);
  }
}
