import {
  DefaultForm,
  ProductionParam,
  SexingForm,
  SlaughterAgeForm,
} from '@agriness/corp-app/services/models/production-params.model';
import { ProductionParamsAbstractService } from '@agriness/corp-app/services/production-params/production-params-abstract.service';
import { ProductionParamsService } from '@agriness/corp-app/services/production-params/production-params.service';
import {
  TranslateInstant,
  TRANSLATE_INSTANT,
  TypeProductionEnum,
  TypeProductionService,
} from '@agriness/services';
import { QueueItem, QueueManagerService } from '@agriness/ui';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-production-tab',
  templateUrl: './production-tab.component.html',
  styleUrls: ['./production-tab.component.scss'],
})
export class ProductionTabComponent implements OnInit {
  stage: string;
  formIsValid: boolean;

  productionType: TypeProductionEnum;
  translationContext = 'agriness.settings.production_params.production';

  isPoultry = TypeProductionEnum.POULTRY;

  caajWeightForm = new FormGroup({
    adjustment_weight: new FormControl('', Validators.required.bind(this)),
  });

  sexingCaajWeightForm = new FormGroup({
    adjustment_factor_male: new FormControl(),
    adjustment_factor_female: new FormControl(),
    adjustment_factor_mix: new FormControl(),
  });

  goalSlaughterAgeForm = new FormGroup({
    goal_slaughter_age: new FormControl(),
  });

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private typeProductionService: TypeProductionService,
    private productionParamsService: ProductionParamsAbstractService,
    private service: ProductionParamsService,
    private route: ActivatedRoute,
    private queueManager: QueueManagerService,
  ) {}

  ngOnInit(): void {
    this.productionType = this.typeProductionService.get();
    this.stage = this.route.snapshot.routeConfig.path;
    this.service.get(this.stage).subscribe(res => {
      res.forEach(item => {
        if (item.key === 'adjustment_weight') {
          this.caajWeightForm.controls['adjustment_weight'].setValue(item.value);
        } else if (item.key === 'goal_slaughter_age') {
          this.goalSlaughterAgeForm.controls['goal_slaughter_age'].setValue(item.value);
        } else {
          this.sexingCaajWeightForm.controls[item.key].setValue(item.value);
        }
      });
    });
  }

  saveForm(): void {
    const defaultForm = this.caajWeightForm.getRawValue() as DefaultForm;
    const sexingForm = this.sexingCaajWeightForm.getRawValue() as SexingForm;
    const slaughterAgeForm = this.goalSlaughterAgeForm.getRawValue() as SlaughterAgeForm;

    const data: ProductionParam[] = [];

    data.push({
      key: 'adjustment_weight',
      value: defaultForm.adjustment_weight,
    });

    if (this.productionType === TypeProductionEnum.POULTRY) {
      for (const [key, value] of Object.entries(sexingForm)) {
        data.push({
          key: key,
          value: value as number,
        });
      }

      for (const [key, value] of Object.entries(slaughterAgeForm)) {
        data.push({
          key: key,
          value: value as number,
        });
      }
    }

    this.addOperationToQueue(this.service.save(data, this.stage));
  }

  addOperationToQueue(paramObservable: Observable<ProductionParam[]>): void {
    const t = (key: string): string => this.t(`${this.translationContext}.feedback.${key}`);
    this.queueManager
      .add(paramObservable)
      .pipe(
        switchMap(({ payload: param, actions }: QueueItem) => {
          return (param as Observable<ProductionParam>).pipe(
            tap(() => {
              actions.success(t('success'));
            }),
            catchError(err => {
              return throwError(err);
            }),
          );
        }),
        switchMap(() => this.productionParamsService.fetchParamsTranslate()),
        catchError(err => {
          return throwError(err);
        }),
      )
      .subscribe();
  }
}
