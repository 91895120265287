import { PerformanceAnalysisTabReport } from '@agriness/corp-app/shared/component/corp-performance-analysis/corp-performance-analysis.model';
import { ReportFilterType } from '@agriness/corp-app/shared/model/report-filter.model';
import { Component } from '@angular/core';

import { tabs } from './performance-analysis.model';

@Component({
  templateUrl: './performance-analysis.component.html',
})
export class PerformanceAnalysisComponent {
  pageTitle = 'agriness.performance_analysis.title';
  filterIds: ReportFilterType[] = [];
  reportTabs: PerformanceAnalysisTabReport[] = tabs;
}
