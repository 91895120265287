import {
  AnimalGroupMortalityPeriodSwines,
  AnimalGroupMortalitySwines,
  MortalityByPeriodSwines,
} from '@agriness/corp-app/services';
import { Stage, StageEnum } from '@agriness/services';
import { Injectable } from '@angular/core';
import { isNumber, mergeWith } from 'lodash';

@Injectable()
export class SanitySwinesService {
  private defaultNumberOfWeeks = {
    [StageEnum.NURSERY]: 7,
    [StageEnum.FINISHING]: 16,
    [StageEnum.WEAN_TO_FINISH]: 16,
  };

  getNumberOfWeeks(stage: Stage): number {
    return this.defaultNumberOfWeeks[stage.name || StageEnum.FINISHING] as number;
  }

  getMortalityWithMaxWeeks(
    stage: Stage,
    mortality: AnimalGroupMortalitySwines,
  ): AnimalGroupMortalitySwines {
    const numberOfWeeks = this.getNumberOfWeeks(stage);
    const lastWeekData: AnimalGroupMortalityPeriodSwines = {
      period: numberOfWeeks,
      by_reason: {},
      total: {
        value: 0,
        percentage: 0,
      },
    };

    const newWeekly: MortalityByPeriodSwines = {
      [numberOfWeeks]: lastWeekData,
    };

    for (const weekData of Object.values(mortality.weekly)) {
      if (weekData.period < numberOfWeeks) {
        newWeekly[weekData.period] = weekData;
      } else {
        addToLastWeekData(weekData);
      }
    }

    return {
      ...mortality,
      weekly: newWeekly,
    };

    function addToLastWeekData(toAdd: AnimalGroupMortalityPeriodSwines) {
      mergeWith(lastWeekData, toAdd, lastWeekDataMergeCustomizer);
    }

    function lastWeekDataMergeCustomizer(objValue: string, toAddValue: string, key: string) {
      if (key === 'period') {
        return objValue;
      }
      if (isNumber(objValue)) {
        return objValue + toAddValue;
      }
    }
  }
}
