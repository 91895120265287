import { UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiCorpService } from '../api-corp.service';
import { ProductionParam, ProductionParamTranslate } from '../models/production-params.model';
import { ProductionParamsAbstractService } from './production-params-abstract.service';

@Injectable({
  providedIn: 'root',
})
export class ProductionParamsService extends ProductionParamsAbstractService {
  private pathBase = 'holdings/{holding_id}/parameter-production/{stage}/';
  private pathTranslate = 'holdings/{holding_id}/parameter-production/';

  private productionParamTranslate: ProductionParamTranslate;

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
  ) {
    super();
  }

  get(stage: string): Observable<ProductionParam[]> {
    const url = this.getUrl(stage);

    return this.apiCorpService.get<ProductionParam[]>(url);
  }

  save(form: ProductionParam[], stage: string): Observable<ProductionParam[]> {
    const url = this.getUrl(stage);
    const params = form;

    return this.apiCorpService.put<ProductionParam[]>(url, params);
  }

  fetchParamsTranslate(): Observable<ProductionParamTranslate> {
    const params = { holding_id: this.userStorageService.getCurrentHolding()?.id };
    const url = this.apiCorpService.translateUrl(this.pathTranslate, params);
    return this.apiCorpService
      .get<ProductionParamTranslate>(url)
      .pipe(tap(value => (this.productionParamTranslate = value)));
  }

  getParamsTranslate(): ProductionParamTranslate {
    return this.productionParamTranslate;
  }

  private getUrl(stage: string): string {
    return this.apiCorpService.translateUrl(this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
      stage: stage,
    });
  }
}
