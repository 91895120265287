import { AnimalGroupStatus, StageEnum, TypeProductionEnum } from '@agriness/services';

import { filterIdsByStageAndStatus } from '../../shared/model/filter.model';
import { ReportFilterType } from '../../shared/model/report-filter.model';

export const filterIdsAnimalGroupListByProduction: {
  [key in TypeProductionEnum]?: ReportFilterType[];
} = {
  [TypeProductionEnum.SWINES]: [
    ...filterIdsByStageAndStatus[StageEnum.NURSERY][AnimalGroupStatus.CLOSED],
    'IS_OPEN',
  ],
};
