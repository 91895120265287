<ng-template #content>
  <ag-feedback [type]="data.feedback"></ag-feedback>

  <highcharts-chart
    (chartInstance)="registerChart($event)"
    *ngIf="!data.feedback"
    [Highcharts]="Highcharts"
    [options]="data.highchartsOptions"
    style="display: block;"
  ></highcharts-chart>
</ng-template>
