import { AnimalGroup, AnimalGroups } from '@agriness/corp-app/services';
import { DietSelectionService } from '@agriness/corp-app/services/diet-selection/diet-selection.service';
import {
  DietOptions,
  DietSelectionEntries,
  DietSelectionKeys,
  ItemOptions,
  ModalStatusEnum,
  DietFormData,
  DisplayMode,
} from '@agriness/corp-app/services/models/diet-selection.model';
import { Diet } from '@agriness/corp-app/services/models/diet.model';
import { LoaderUserPreference } from '@agriness/corp-app/shared/component/loader-user-preference';
import { StageEnum, UserStorageService } from '@agriness/services';
import { FeedbackEnum } from '@agriness/ui';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { isEmpty, last } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';

import { DietSelectionFormComponent } from './form/diet-selection-form.component';

@Component({
  selector: 'diet-selection',
  templateUrl: './diet-selection.component.html',
  styleUrls: ['./diet-selection.styles.scss'],
})
export class DietSelectionComponent extends LoaderUserPreference {
  @ViewChild('form') form: DietSelectionFormComponent;
  @Input() stage: StageEnum;
  @Input() animalGroup: AnimalGroup & AnimalGroups;
  @Input() animalGroupList: AnimalGroups[] = [];
  @Output() dietChanged = new EventEmitter<Diet>();

  display = false;
  displayMode: DisplayMode;
  feedbackType = FeedbackEnum.LOADING;
  animalGroupCounter: number;

  tableSubscription = Subscription.EMPTY;
  formSubscription = Subscription.EMPTY;

  performances: DietSelectionEntries;
  currentAge: number;

  dietOptions: DietOptions = {
    age: new BehaviorSubject<ItemOptions[]>([]),
    proposed_diet: new BehaviorSubject<ItemOptions[]>([]),
    comments: new BehaviorSubject<string>(''),
  };

  modalStatus: ModalStatusEnum;
  formData: DietFormData;

  translationContext = 'agriness.diet_selection';

  constructor(
    private dietSelectionService: DietSelectionService,
    protected userStorageService: UserStorageService,
  ) {
    super(userStorageService);
  }

  displayDialog(mode: DisplayMode): void {
    this.display = !this.display;
    this.displayMode = mode;

    this.resetModalStatus();
    this.loadPerformances();
  }

  displayDialogInFlockList(mode: DisplayMode, animalGroups: AnimalGroup[] & AnimalGroups[]): void {
    this.display = !this.display;
    this.displayMode = mode;

    if (animalGroups.length === 1) {
      this.setAnimalGroup(animalGroups[0]);
    } else {
      this.setAnimalGroupFromBulkAction(animalGroups);
    }

    this.resetModalStatus();
    this.loadPerformances();
  }

  closeDialog(): void {
    this.display = false;

    this.loadPerformances();

    this.tableSubscription.unsubscribe();
    this.formSubscription.unsubscribe();
  }

  isEditing(): boolean {
    return this.displayMode === DisplayMode.EDITING;
  }

  cancelSaving(): void {
    this.resetModalStatus();
  }

  saveDiet(): void {
    const formData = this.form.getFormData();
    const { age, proposed_diet, comments } = formData;
    const formWasFilled = age && (proposed_diet || comments);

    if (formWasFilled) {
      this.modalStatus = ModalStatusEnum.LOADING;

      this.formSubscription = this.dietSelectionService
        .saveDiet({
          holdingId: this.holdingId,
          stage: this.stage,
          id: this.getPerformanceItemFromAge(age, DietSelectionKeys.FEED_ID),
          data: this.buildRequestData(formData),
        })
        .subscribe(
          data => {
            this.modalStatus = ModalStatusEnum.SUCCESS;
            this.dietChanged.emit(data);
          },
          error => {
            this.modalStatus = ModalStatusEnum.FAIL;
            console.error(error);
          },
          () => (isEmpty(this.animalGroupList) ? this.closeAfterSaving() : this.loadNextOrClose()),
        );

      return;
    }

    this.rememberFormInWarning(formData);
    this.toggleWarningScreen();
  }

  private setAnimalGroup(animalGroup: AnimalGroups): void {
    this.animalGroup = animalGroup;
  }

  private setAnimalGroupFromBulkAction(animalGroups: AnimalGroups[]): void {
    this.animalGroupList = animalGroups;
    this.animalGroupCounter = 0;
    this.animalGroup = animalGroups[this.animalGroupCounter];
  }

  private resetModalStatus() {
    this.modalStatus = null;
    this.formData = null;
  }

  private getPerformanceItemFromAge(age: number, key: DietSelectionKeys) {
    const targetPerformance = this.performances.find(
      performance => performance?.current_age.value == age,
    );

    return targetPerformance?.[key]?.value;
  }

  private buildRequestData(formData: DietFormData) {
    const { age } = formData;

    if (!this.isEditing()) {
      return this.checkProposedDiet(formData);
    }

    const formDataCopy = { ...formData };
    const { proposed_diet, comments } = formDataCopy;
    const performance = this.getPerformanceFromAge(age);

    if (!comments && proposed_diet) {
      formDataCopy.comments = performance?.comments.value;
    }

    if (!proposed_diet && comments) {
      formDataCopy.proposed_diet = performance?.proposed_diet.value;
    }

    return formDataCopy;
  }

  private checkProposedDiet(formData: DietFormData) {
    if (!formData.proposed_diet) {
      return {
        ...formData,
        proposed_diet: this.getLastProposedDiet(),
      };
    }

    return formData;
  }

  private getLastProposedDiet() {
    const lastPerformanceWithProposedDiet = last(
      this.getDataWithGivenDietKey(this.performances, DietSelectionKeys.PROPOSED_DIET),
    );

    return lastPerformanceWithProposedDiet?.proposed_diet.value;
  }

  private getPerformanceFromAge(age) {
    return this.performances.find(performance => performance?.current_age.value == age);
  }

  private rememberFormInWarning(formData: DietFormData): void {
    this.formData = formData;
  }

  private toggleWarningScreen(): void {
    this.modalStatus = ModalStatusEnum.WARNING;
  }

  private closeAfterSaving() {
    // Has 50ms of timeout because when the request
    // is done the dialog closes while it is still
    // showing the loading status
    setTimeout(() => {
      this.closeDialog();
    }, 50);
  }

  private loadNextOrClose(): void {
    // Has 50ms of timeout because when the request
    // is done the dialog closes while it is still
    // showing the loading status
    setTimeout(() => {
      const isLastAnimalGroup = this.animalGroupCounter === this.animalGroupList.length - 1;

      if (isLastAnimalGroup) {
        return this.closeAfterSaving();
      }

      this.animalGroupCounter++;
      this.animalGroup = this.animalGroupList[this.animalGroupCounter];

      this.resetModalStatus();
      this.loadPerformances();
    }, 50);
  }

  private loadPerformances(): void {
    this.tableSubscription = this.dietSelectionService
      .getPerformances({
        holdingId: this.holdingId,
        stage: this.stage,
        id: this.animalGroup.id,
      })
      .subscribe(data => {
        this.feedbackType = isEmpty(data) ? FeedbackEnum.NOT_RESULT : null;
        this.performances = data;

        this.setCurrentAge(data);

        this.dietOptions.age.next(
          this.getAges(
            !this.isEditing()
              ? [last(data)]
              : this.getDataWithGivenDietKey(data, DietSelectionKeys.PROPOSED_DIET),
          ),
        );
      });
  }

  private setCurrentAge(data: DietSelectionEntries): void {
    this.currentAge = last(data)?.current_age.value as number;
  }

  private getDataWithGivenDietKey(data: DietSelectionEntries, key: DietSelectionKeys) {
    return data.filter(performance => performance?.[key]?.value);
  }

  private getAges(data: DietSelectionEntries) {
    return data.map(performance => {
      const age = performance?.current_age.value;

      return { id: age, name: age };
    });
  }
}
