<form
  *ngIf="filterForm"
  [formGroup]="filterForm"
  (submit)="onFilter($event)"
  class="filter-content"
>
  <div
    class="mr-2 mb-2 d-flex flex-wrap filter-slot-content"
    [ngStyle]="{ maxWidth: filtersAreaWidth, minWidth: filtersMinArea }"
    data-testid="filter-slot-content"
  >
    <ng-content> </ng-content>
  </div>

  <div class="mt-1 ng-star-inserted" *ngIf="hasWrappedFilters()">
    <ag-toggle-button
      class="mr-3"
      untoggledIconClass="icon-filter-add"
      toggledIconClass="icon-filter"
      [isToggled]="!isCollapsed"
      (toggle)="toggleVisibility()"
      data-testid="filter-toggle-button"
    >
    </ag-toggle-button>
  </div>

  <ag-filter-bar-action
    class="mt-1"
    data-testid="filter-bar-button"
    *ngIf="showFilterButton"
    [hasError]="filterForm.errors"
    (clearFilter)="resetFilterForm()"
    (rollbackFilter)="resetFilterForm()"
  ></ag-filter-bar-action>
</form>
