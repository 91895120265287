import { AppInitializerModule } from '@agriness/corp-app';
import {
  AgrinessServicesModule,
  AppInitializerModule as ServicesInitializerModule,
} from '@agriness/services';
import { AgrinessUiModule } from '@agriness/ui';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePT from '@angular/common/locales/pt';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppTranslateModule } from './app-translate.module';
import { AppComponent } from './app.component';

registerLocaleData(localePT);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppInitializerModule,
    AppRoutingModule,
    AppTranslateModule,
    AgrinessServicesModule.forRoot({
      production: environment.production,
      apiMyAccount: environment.apiMyAccount,
      keycloak: environment.keycloak,
      tagManagerId: environment.tagManagerId,
    }),
    ServicesInitializerModule,
    AgrinessUiModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {
    trace;
  }
}
