import { ExportType } from '@agriness/corp-app/shared/model/highcharts.model';
import { Injectable } from '@angular/core';
import { Chart } from 'highcharts';

@Injectable()
export class GraphExporterService {
  asPDF(filename: string, chart: Chart): void {
    this.exportAs(chart, filename, ExportType.PDF);
  }

  asPNG(filename: string, chart: Chart): void {
    this.exportAs(chart, filename, ExportType.PNG);
  }

  asJPG(filename: string, chart: Chart): void {
    this.exportAs(chart, filename, ExportType.JPG);
  }

  asSVG(filename: string, chart: Chart): void {
    this.exportAs(chart, filename, ExportType.SVG);
  }

  private exportAs(chart: Chart, filename: string, type: ExportType): void {
    chart.exportChart({ filename, type, scale: 4 }, chart.options);
  }
}
