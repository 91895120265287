import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

import { SharedModule } from '../shared/shared.module';
import { NurseryIdentifyComponent } from './identify/nursery-identify.component';
import { NurseryComponent } from './nursery.component';

@NgModule({
  imports: [ReactiveFormsModule, DropdownModule, SharedModule],
  declarations: [NurseryComponent, NurseryIdentifyComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NurseryModule {}
