<div class="content">
  <div>
    <ng-container *ngIf="status === ModalStatusEnum.LOADING; then loading"></ng-container>
    <ng-container *ngIf="status === ModalStatusEnum.SUCCESS; then success"></ng-container>
    <ng-container *ngIf="status === ModalStatusEnum.FAIL; then fail"></ng-container>
    <ng-container *ngIf="status === ModalStatusEnum.WARNING; then warning"></ng-container>
  </div>
</div>

<ng-template #loading>
  <diet-selection-icon-loading></diet-selection-icon-loading>
  <div class="message">{{ translationContext + status | translate }}</div>
</ng-template>

<ng-template #success>
  <diet-selection-icon-success></diet-selection-icon-success>
  <div class="message">{{ translationContext + status | translate }}</div>
</ng-template>

<ng-template #fail>
  <diet-selection-icon-fail></diet-selection-icon-fail>
  <div *ngIf="!isEditing" class="message">
    {{ translationContext + status + '_on_add' | translate }}
  </div>
  <div *ngIf="isEditing" class="message">
    {{ translationContext + status + '_on_edit' | translate }}
  </div>
  <ng-container *ngTemplateOutlet="buttonActions"></ng-container>
</ng-template>

<ng-template #warning>
  <diet-selection-icon-warning></diet-selection-icon-warning>
  <div *ngIf="!isEditing" class="message">
    {{ translationContext + status + '_on_add' | translate }}
  </div>
  <div *ngIf="isEditing" class="message">
    {{ translationContext + status + '_on_edit' | translate }}
  </div>
  <ng-container *ngTemplateOutlet="buttonActions"></ng-container>
</ng-template>

<ng-template #buttonActions>
  <div class="actions">
    <button class="btn btn-ghost-light is-medium" (click)="backToDietSelection()">
      {{ 'agriness.diet_selection.buttons.back' | translate }}
    </button>
    <button class="btn btn-primary is-medium" (click)="closeDialog()">
      {{ 'agriness.diet_selection.buttons.close' | translate }}
    </button>
  </div>
</ng-template>
