import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

import { CardStatus } from '../../card/card.model';
import { TargetStatus } from '../target.model';

@Component({
  selector: 'ag-target-indicator',
  templateUrl: './ag-target-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgTargetIndicatorComponent {
  @Input() status: TargetStatus | CardStatus;
  @Input() wrapperClass: string;
  @Input() iconSize: string;
  @Input() tooltip: string | TemplateRef<any>;

  statusClass: { [k in TargetStatus | CardStatus]: string } = {
    [TargetStatus.GOOD]: 'icon-good has-text-green-success',
    [CardStatus.UP]: 'icon-good has-text-green-success',
    [TargetStatus.ATTENTION]: 'icon-attention has-text-yellow-attention',
    [TargetStatus.BAD]: 'icon-danger has-text-red-danger',
    [CardStatus.DOWN]: 'icon-danger has-text-red-danger',
  };
}
