import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AgAutocompleteModule } from './component/autocomplete/ag-autocomplete.module';
import { AgCalendarModule } from './component/calendar/ag-calendar.module';
import { AgCardReportModule } from './component/card-report/ag-card-report.module';
import { AgCardModule } from './component/card/ag-card.module';
import { AgCopyButtonModule } from './component/copy-button/ag-copy-button.module';
import { AgDeathTableModule } from './component/death-table/ag-death-table.module';
import { AgDualListBoxModule } from './component/dual-list-box/ag-dual-list-box.module';
import { AgFeedbackModule } from './component/feedback/ag-feedback.module';
import { AgFilterBarActionModule } from './component/filter-bar-action/ag-filter-bar-action.module';
import { AgFormatModule } from './component/format/ag-format.module';
import { AgGridModule } from './component/grid/ag-grid.module';
import { AgIndicesModule } from './component/indexes-table/ag-indexes-table.module';
import { AgInputModule } from './component/input/ag-input.module';
import { AgLineModule } from './component/line/ag-line.module';
import { AgLinkModule } from './component/link/ag-link.module';
import { AgModalModule } from './component/modal/ag-modal.module';
import { AgMultiselectModule } from './component/multiselect/ag-multiselect.module';
import { AgNumberFieldModule } from './component/number-field/ag-number-field.module';
import { AgPeriodConfigurationModule } from './component/period-configuration/ag-period-configuration.module';
import { AgPopoverModule } from './component/popover/ag-popover.module';
import { AgQueueManagerModule } from './component/queue-manager/ag-queue-manager.module';
import { AgRedirectModalModule } from './component/redirect-modal/ag-redirect-modal.module';
import { AgSelectModule } from './component/select/ag-select.module';
import { AgStatusModule } from './component/status/ag-status.module';
import { AgStepsModule } from './component/steps/ag-steps.module';
import { AgTableModule } from './component/table/ag-table.module';
import { AgTabsModule } from './component/tabs/ag-tabs.module';
import { AgTargetModule } from './component/target/ag-target.module';
import { AgTargetIndicatorModule } from './component/target/target-indicator/ag-target-indicator.module';
import { AgTextFieldModule } from './component/text-field/ag-text-field.module';
import { AgTimeFieldModule } from './component/time-field/ag-time-field.module';
import { AgToggleButtonModule } from './component/toggle-button/ag-toggle-button.module';
import { AgToggleColumnModule } from './component/toggle-column/ag-toggle-column.module';
import { AgTreeModule } from './component/tree/ag-tree.module';
import { DirectivesModule } from './directive/directives.module';
import { AgLayoutModule } from './layout/ag-layout.module';
import { PipesModule } from './pipe/pipes.module';

import { GuaviButtonModule } from './guavi/button/guavi-button.module';
import { GuaviGhostButtonModule } from './guavi/ghost-button/guavi-ghost-button.module';

import { AgrinessCardModule } from './guavi/card/agriness-card.module';
import { AgrinessIconModule } from './guavi/icon/agriness-icon.module';
import { ChevronButtonModule } from './guavi/chevron-button/chevron-button.module';
import { AgCardLookerModule } from './component/card-looker/ag-card-looker.module';

@NgModule({
  imports: [
    AgAutocompleteModule,
    AgCalendarModule,
    AgCardModule,
    AgCardReportModule,
    AgCardLookerModule,
    AgCopyButtonModule,
    AgDeathTableModule,
    AgDualListBoxModule,
    AgFeedbackModule,
    AgFilterBarActionModule,
    AgFormatModule,
    AgGridModule,
    AgIndicesModule,
    AgInputModule,
    AgLayoutModule,
    AgLineModule,
    AgLinkModule,
    AgModalModule,
    AgMultiselectModule,
    AgNumberFieldModule,
    AgPeriodConfigurationModule,
    AgPopoverModule,
    AgQueueManagerModule,
    AgRedirectModalModule,
    AgSelectModule,
    AgStatusModule,
    AgStepsModule,
    AgTableModule,
    AgTabsModule,
    AgTargetIndicatorModule,
    AgTextFieldModule,
    AgTimeFieldModule,
    AgToggleButtonModule,
    AgToggleColumnModule,
    AgTreeModule,
    AgTargetModule,
    DirectivesModule,
    PipesModule,

    GuaviButtonModule,
    GuaviGhostButtonModule,

    AgrinessCardModule,
    AgrinessIconModule,
    ChevronButtonModule,
  ],
  exports: [
    AgAutocompleteModule,
    AgCalendarModule,
    AgCardModule,
    AgCardReportModule,
    AgCardLookerModule,
    AgCopyButtonModule,
    AgDeathTableModule,
    AgDualListBoxModule,
    AgFeedbackModule,
    AgFilterBarActionModule,
    AgFormatModule,
    AgGridModule,
    AgIndicesModule,
    AgInputModule,
    AgLayoutModule,
    AgLineModule,
    AgLinkModule,
    AgModalModule,
    AgMultiselectModule,
    AgNumberFieldModule,
    AgPeriodConfigurationModule,
    AgPopoverModule,
    AgQueueManagerModule,
    AgRedirectModalModule,
    AgSelectModule,
    AgStatusModule,
    AgStepsModule,
    AgTableModule,
    AgTabsModule,
    AgTargetIndicatorModule,
    AgTextFieldModule,
    AgTimeFieldModule,
    AgToggleButtonModule,
    AgToggleColumnModule,
    AgTreeModule,
    AgTargetModule,
    DirectivesModule,
    PipesModule,

    GuaviButtonModule,
    GuaviGhostButtonModule,

    AgrinessCardModule,
    AgrinessIconModule,
    ChevronButtonModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgrinessUiModule {}
