<div id="root" class="ag-layout-root">
  <div
    id="layout-panels"
    class="ag-layout-panels"
    [ngClass]="{ 'ag-layout-collapsed': panelCollapse }"
  >
    <div id="panel-left" class="ag-layout-panel-left">
      <!-- header-side-bar -->
      <div class="ag-layout-header">
        <div class="ag-logo">
          <span class="icon is-28 icon-{{ iconTitle }}"></span>
        </div>
        <div class="ag-logo-label ml-2">
          <h5 class="title text-truncate"><span class="font-weight-bold">Agriness</span> Corp</h5>
        </div>
      </div>

      <div class="ag-layout-content">
        <ng-content select="[side-menu]"></ng-content>
      </div>

      <button
        type="button"
        id="btnSidebarToggle"
        class="ag-layout-panel-left__toggle"
        (click)="toggleCollapse()"
      >
        <span class="icon icon-chevron-left"></span>
      </button>

      <div class="ag-layout-footer">
        <a href="http://www.agriness.com" target="_blank" class="ag-footer__logo">
          <svg class="ag-footer__logo-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 13">
            <path
              d="M5.853 1.988l2.505 6.191H3.371l2.482-6.191zM5.147.228L0 12.795h1.571l1.39-3.427h5.875l1.367 3.427h1.548L6.581.23H5.148zM21.158 7.425v3.29c0 .366-.479.685-1.23.891-.046 0-.091.023-.114.023h-.091a6.21 6.21 0 0 1-.638.114c-.068 0-.136.023-.182.023h-.091c-.182.023-.364.023-.547.023h-.022c-2.984 0-5.056-2.193-5.056-5.278 0-3.107 2.027-5.277 4.942-5.277 1.184 0 2.391.411 3.62 1.096l.57-1.073C20.862.434 19.404 0 17.947 0c-3.712 0-6.309 2.696-6.309 6.511 0 3.702 2.528 6.352 6.15 6.489h.341c2.437-.023 4.395-1.005 4.418-2.194V6.26h-4.532v1.165h3.143zM24.936 12.794h1.48V7.791h2.688l3.439 4.98h1.685l-3.644-5.209c1.89-.251 3.143-1.69 3.143-3.587 0-2.193-1.685-3.746-4.03-3.746h-4.76v12.565zM26.44 1.44h3.052c1.617 0 2.756 1.074 2.756 2.582 0 1.53-1.139 2.559-2.756 2.559H26.44V1.44zM55.295.229v10.44L47.665.23h-1.502v12.565h1.434V2.376l7.607 10.418h1.526V.23h-1.435zM59.394.229v12.565h8.29v-1.21H60.92V6.944h5.876V5.78H60.92V1.44h6.468V.228h-7.994zM40.97.229l-5.146 12.565h3.438L44.41.23H40.97zM70.712 3.45c0-1.28 1.184-2.102 2.983-2.102 1.025 0 2.187.251 3.417.754l.455-1.143V.937C76.155.365 74.811.09 73.56.09c-2.596 0-4.373 1.371-4.373 3.359 0 4.638 7.288 2.741 7.288 6.031 0 1.303-1.184 2.194-3.029 2.194-1.139 0-2.55-.366-3.963-.937v1.348c1.458.548 2.87.845 4.077.845 2.642 0 4.44-1.393 4.44-3.427.023-4.683-7.287-2.764-7.287-6.054zM80.712 3.45c0-1.28 1.184-2.102 2.984-2.102 1.025 0 2.186.251 3.416.754L87.59.96C86.178.388 84.834.114 83.582.114c-2.596 0-4.373 1.371-4.373 3.359 0 4.638 7.288 2.741 7.288 6.031 0 1.303-1.184 2.194-3.03 2.194-1.115 0-2.481-.366-3.87-.892l-.479 1.143c1.594.662 3.12.982 4.441.982 2.642 0 4.44-1.393 4.44-3.427.024-4.683-7.287-2.764-7.287-6.054z"
            />
          </svg>
        </a>

        <a href="http://www.agriness.com" target="_blank" class="ag-footer__logo-mini">
          <svg class="ag-footer__logo-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 62">
            <polygon class="st0" points="25.8,3.6 3.2,58.6 18.3,58.6 40.9,3.6 " />
          </svg>
        </a>
      </div>
    </div>

    <div id="panel-right" class="ag-layout-panel-right">
      <div class="ag-layout-header">
        <nav class="ag-layout-header-nav navbar navbar-expand-sm">
          <div class="pl-3">
            <ag-section></ag-section>
          </div>
          <div class="ml-auto"></div>
          <div class="vertical-divider">
            <ng-content select="[help-menu]"></ng-content>
          </div>
          <div class="vertical-divider">
            <ag-holding-select></ag-holding-select>
          </div>
          <div class="vertical-divider">
            <ag-app-menu></ag-app-menu>
          </div>
          <div>
            <ng-content select="[user-menu]"></ng-content>
          </div>
        </nav>
      </div>

      <div class="ag-layout-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
