<p-dialog [(visible)]="display" styleClass="dialog">
  <p-header>
    <div>
      <span class="icon icon-calendar-information is-24 calendar-information"></span>
      {{ 'agriness.presence.schedules' | translate }} {{ date }}
    </div>
  </p-header>
  <div *ngIf="feedbackType" class="content">
    <ag-feedback [type]="feedbackType" position="center-parent"></ag-feedback>
  </div>
  <div *ngIf="!feedbackType" class="content">
    <div class="content-title">
      {{ total }} {{ 'agriness.presence.scheduled_visit' | translate }}
    </div>
    <div *ngFor="let schedule of schedules" class="content-info">
      <div *ngIf="date < today" class="icon-container">
        <span
          [ngClass]="{
            'icon icon-good is-14': schedule.status === scheduleStatus.FINISHED,
            'icon icon-danger is-14': schedule.status === scheduleStatus.OPENED
          }"
        ></span>
      </div>
      <div class="content-info__name">
        <div class="content-allotment" *ngIf="schedule.allotment">
          <div *ngIf="schedule.allotment.producer_name">{{ schedule.allotment.producer_name }}</div>
          <div>{{ schedule.allotment.farm_name }}</div>
          <span>-</span>
          <div>{{ 'agriness.presence.allotment' | translate }}: {{ schedule.allotment.name }}</div>
        </div>
        <div class="content-no-producer" *ngIf="!schedule.allotment">
          <div>{{ 'agriness.presence.no_producer' | translate }}</div>
        </div>
      </div>
      <span>|</span>
      <div class="content-info__description">{{ schedule.description }}</div>
    </div>
  </div>
  <p-footer>
    <button class="btn btn-primary is-medium" (click)="closeDialog()">
      {{ 'agriness.presence.close' | translate }}
    </button>
  </p-footer>
</p-dialog>
