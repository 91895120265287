import { TargetForm } from '@agriness/corp-app/settings/target-section/targets/targets.model';
import { PatchForm } from '@agriness/corp-app/settings/targets-in-dev/targets/targets-tab/target-editor/target-editor-form.model';
import { UserStorageService, TypeProductionService, Search } from '@agriness/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import {
  Target,
  TargetFilter,
  TargetForm as TargetFormV2,
  TargetIndex,
  Targets,
  TargetV2,
} from '../models/target.model';
import { TargetAbstractService } from './target-abstract.service';

@Injectable()
export class TargetService extends TargetAbstractService {
  private pathTargets = 'holdings/{holding_id}/targets/';
  private pathTargetId = 'holdings/{holding_id}/targets/{target_id}/';

  private pathTargetsV2 = 'v2/holdings/{holding_id}/targets/';
  private pathTargetIdV2 = 'v2/holdings/{holding_id}/targets/{target_id}/';
  private pathTargetIndexes = 'v2/holdings/{holding_id}/target-indexes/';

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {
    super();
  }

  list(): Observable<Targets> {
    const url = this.pathForService('v1');

    const params = {
      system_type: this.typeProductionService.getId(),
    };

    return this.apiCorpService.get<Targets>(url, params);
  }

  listV2(targetFilter: TargetFilter): Observable<Targets> {
    const url = this.pathForService('v2');

    const params = {
      system_type: this.typeProductionService.getId(),
      ...targetFilter,
    };

    return this.apiCorpService.get<Targets>(url, params);
  }

  listKPI(): Observable<Search<TargetIndex>> {
    const url = this.apiCorpService.translateUrl(this.pathTargetIndexes, {
      holding_id: this.userStorageService.getCurrentHolding().id,
    });

    const params = {
      system_type: this.typeProductionService.getId(),
    };

    return this.apiCorpService.get<Search<TargetIndex>>(url, params);
  }

  save(form: TargetFormV2): Observable<Target> {
    const url = this.pathForService('v2');
    const params = {
      ...form,
      system_type: this.typeProductionService.getId(),
    } as unknown;

    return this.apiCorpService.post<Target>(url, params);
  }

  get(target_id: string, is_default: boolean): Observable<Target> {
    const url = this.pathForService('v1', target_id);

    const params = {
      system_type: this.typeProductionService.getId(),
      is_default,
    };
    return this.apiCorpService.get<Target>(url, params);
  }

  getV2(target_id: string, is_default: boolean): Observable<TargetV2> {
    const url = this.pathForService('v2', target_id);

    const params = {
      system_type: this.typeProductionService.getId(),
      is_default,
    };
    return this.apiCorpService.get<TargetV2>(url, params);
  }

  update(targetId: string, targetData: TargetForm): Observable<Target[]> {
    const url = this.pathForService('v1', targetId);

    return this.apiCorpService.post<Target[]>(url, targetData);
  }

  updateV2(targetForm: TargetFormV2, targetId: string): Observable<Target> {
    const url = this.pathForService('v2', targetId);
    return this.apiCorpService.put<Target>(url, targetForm);
  }

  delete(target: Target): Observable<void> {
    return target ? null : null;
  }

  patch(targetForm: PatchForm, targetId: string): Observable<TargetV2> {
    const url = this.pathForService('v2', targetId);
    const params = {
      system_type: this.typeProductionService.get(),
    };
    return this.apiCorpService.patch<TargetV2>(url, targetForm, params);
  }

  pathForService(version: string, targetId?: string): string {
    const pathTargetId = version === 'v2' ? this.pathTargetIdV2 : this.pathTargetId;
    const pathTargets = version === 'v2' ? this.pathTargetsV2 : this.pathTargets;
    return this.apiCorpService.translateUrl(targetId ? pathTargetId : pathTargets, {
      holding_id: this.userStorageService.getCurrentHolding().id,
      target_id: targetId,
    });
  }
}
