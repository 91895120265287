<div class="ag-layout-menu-user" *ngIf="user">
  <div ngbDropdown>
    <button
      ngbDropdownToggle
      type="button"
      class="btn-icon status-on"
      id="dropdown-menu-button-user"
    >
      <img [attr.src]="user.photo" alt="..." />
    </button>

    <div ngbDropdownMenu>
      <a class="dropdown-item ag-userbox" [attr.href]="appMyAccount" target="_blank">
        <div class="d-flex align-items-stretch">
          <div class="ag-menu-avatar ag-menu-avatar-lg">
            <img [attr.src]="user.photo" alt="..." class="rounded-circle" />
          </div>
          <div class="ml-2">
            <div>{{ user.name }}</div>
            <div class="text-small">{{ user.email }}</div>
            <button type="button" id="my_account" class="link">
              {{ 'agriness.myaccount' | translate }}
            </button>
          </div>
        </div>
      </a>
      <div class="dropdown-item active">
        <div>{{ user.company }}</div>
        <div class="text-small">
          {{ 'agriness.owner' | translate }}: {{ user.ownerEmail || user.owerEmail }}
        </div>
        <div class="text-small" *ngIf="user.profileName">
          {{ 'agriness.profile' | translate }} : {{ 'agriness.' + user.profileName | translate }}
        </div>
      </div>

      <button type="button" class="btn-logout" title="Sair" (click)="onLogout()">
        <span class="icon icon-sign-out"></span>
        <span>{{ 'agriness.exit' | translate }}</span>
      </button>
    </div>
  </div>
</div>
