import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { SectionModel } from './section.model';

@Injectable({ providedIn: 'root' })
export class SectionService {
  private sectionSubject = new Subject<SectionModel[]>();

  constructor() {}

  applyLinksSection(data: SectionModel[]) {
    this.sectionSubject.next(data);
  }

  getSection(): Observable<SectionModel[]> {
    return this.sectionSubject.asObservable();
  }
}
