import {
  ReportDashboardAbstractService,
  ReportFavoriteAbstractService,
  Tag,
  TagAbstractService,
  ReportFilter,
} from '@agriness/corp-app/services';
import { FeatureName } from '@agriness/corp-app/shared/model/feature-toggle.model';
import { FeatureToggleService } from '@agriness/corp-app/shared/services/feature-toggle.service';
import { SiteSystemType, SiteEnum } from '@agriness/services';
import { CardReportModel, TabsModel } from '@agriness/ui';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  tabs: TabsModel[];
  cards: CardReportModel[];
  text: string;
  filteredTags: Tag[];
  pageSize = 5;
  routes: Record<string, string> = {
    animal_group_list: 'animal-group-list',
    index_map_generator: 'index-map-generator',
    map_indicators: 'indicators',
    onepage_report: 'onepage-report',
  };
  filterFeature: string[] = ['onepage_report'];
  favorites: string[];
  selectedTab = 'all';
  filterReport: ReportFilter;
  stage = SiteSystemType[environment.typeProduction][SiteEnum.SITE_2];

  constructor(
    private reportDashboardService: ReportDashboardAbstractService,
    private tagService: TagAbstractService,
    private reportFavoriteService: ReportFavoriteAbstractService,
    private featureToggleService: FeatureToggleService,
  ) {}

  ngOnInit(): void {
    this.favorites = [];
    this.tabs = [
      // {name: 'favorites', render_router: false, active: true, visible: true},
      { name: 'all', render_router: false, active: true, visible: true },
    ]; // TODO: Hoje nao utilizamos as categorias do backend
    this.filterReport = new ReportFilter();
    this.filterReport.is_favorites = this.selectedTab === 'favorites';
    // this.reportDashboardService.getReportsCategories('x').subscribe(response => this.data = response.results); Não utilizamos por categoria ainda
    this.searchReports(this.filterReport); // FIXME: Ainda nao filtramos por categoria
  }

  searchReports(filter: ReportFilter): void {
    this.reportDashboardService
      .getReports(filter, this.stage)
      .subscribe(response => this.populateCards(Object.assign([], response.results)));
  }

  search(event: { query: string }): void {
    if (!event.query) {
      this.filteredTags = [];
      return;
    }
    this.tagService.searchTag(event.query, this.pageSize).subscribe(data => {
      this.filteredTags = [...data.results];
    });
  }

  selectTag(): void {
    this.filterReport.is_favorites = this.selectedTab === 'favorites';
    this.searchReports(this.filterReport); // FIXME: mockado, alterar de acordo que o back for implementado
  }

  unselectTag(): void {
    this.filterReport.is_favorites = this.selectedTab === 'favorites';
    this.searchReports(this.filterReport); // FIXME: mockado, alterar de acordo que o back for implementado
  }

  onChange(tab: TabsModel): void {
    this.selectedTab = tab.name;
    this.filterReport.is_favorites = this.selectedTab === 'favorites';
    this.searchReports(this.filterReport);
    this.tabs.map(item => (item.active = item.name === this.selectedTab));
  }

  onFavorite(card: CardReportModel): void {
    card.favorite
      ? this.favorites.push(card.id)
      : this.favorites.splice(this.favorites.indexOf(card.id), 1);
    card.favorite
      ? this.reportFavoriteService.saveReportFavorite(card.id).subscribe()
      : this.reportFavoriteService.deleteReportFavorite(card.id).subscribe();
    this.populateTabs();
  }

  // FIXME: será removido quando implementado melhor lógica de popular tabs de acordo com as categorias do backend
  // hoje é provisórios tabs "todos" e "favoritos"
  populateTabs(): void {
    if (this.favorites.length > 0) {
      this.selectedTab = 'favorites';
      this.tabs[0].visible = this.tabs[0].active = true;
      this.tabs[1].active = false;
      this.filterReport.is_favorites = true;
      this.searchReports(this.filterReport);
    }

    if (!this.favorites.length) {
      this.selectedTab = 'all';
      this.tabs[1].active = true;
      this.tabs[0].visible = this.tabs[0].active = false;
      this.filterReport.is_favorites = false;
      this.searchReports(this.filterReport);
    }
  }

  private populateCards(cards: CardReportModel[]): void {
    this.cards = cards
      .filter(item =>
        this.filterFeature.includes(item.internal_name)
          ? this.featureToggleService.isEnabled(`reports.${item.internal_name}` as FeatureName)
          : true,
      )
      .map(item => this.buildCard(item));
  }

  private buildCard(card: CardReportModel): CardReportModel {
    card.route = this.routes[card.internal_name];
    return card;
  }
}
