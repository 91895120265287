import { Component, ChangeDetectionStrategy, Input, SimpleChanges, OnChanges } from '@angular/core';

import { ValueFormatStyle } from '../../pipe/value-format/value-format.model';
import {
  DeathTableRow,
  DeathTableColumn,
  DeathTableStatus,
  DeathTableData,
  DeathTableFooter,
} from './ag-death-table.model';

@Component({
  selector: 'ag-death-table',
  templateUrl: './ag-death-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgDeathTableComponent implements OnChanges {
  @Input() rows: DeathTableRow[];
  @Input() columns: DeathTableColumn[];
  @Input() rowsData: DeathTableData;
  @Input() footerData: DeathTableFooter;

  deathTableStatus = Object.keys(DeathTableStatus);
  valueFormatStyle = ValueFormatStyle;
  highest = 0;

  statusClasses = {
    [DeathTableStatus.GOOD]: 'is-good',
    [DeathTableStatus.ATTENTION_1]: 'is-attention-1',
    [DeathTableStatus.ATTENTION_2]: 'is-attention-2',
    [DeathTableStatus.DANGER]: 'is-danger',
  };

  private blockSize = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rowsData) {
      this.updateHighest();
    }
  }

  getStatus(value) {
    let statusIndex;
    if (this.highest === 0) {
      statusIndex = 0;
    } else if (value === this.highest) {
      statusIndex = this.deathTableStatus.length - 1;
    } else {
      statusIndex = Math.floor(value / this.blockSize);
    }
    return this.deathTableStatus[statusIndex];
  }

  private updateHighest() {
    const getRowValues = row => this.columns.map(({ field }) => row[field].value);
    const highestByRow = Object.values(this.rowsData).map(row => Math.max(...getRowValues(row)));
    this.highest = Math.max(...highestByRow);
    this.blockSize = this.highest / this.deathTableStatus.length;
  }
}
