export class TypeViewPreference {
  constructor(
    public index_name?: string,
    public order?: number,
    public enable?: boolean,
    public read_only?: boolean,
    public frozen?: boolean,
    public type?: ViewPreferenceEnum,
    public parent?: string,
  ) {}
}

export class TypeViewPreferenceDefault {
  constructor(
    public configurations?: TypeViewPreference[],
    public id?: string,
    public key?: ViewPreferenceEnum,
  ) {}
}

export interface TypeViewPreferenceUser {
  default?: TypeViewPreference[];
  user?: TypeViewPreference[];
}

export enum ReportEnum {
  MONITOR_OPENED = 'monitor-opened',
  MONITOR_CLOSED = 'monitor',
  ANIMALGROUP_LIST = 'animal-group-list',
  INDEX_MAP_GENERATOR = 'index-map-generator',
  PRODUCER_LIST = 'producer-list',
  MULTIPLE_FLOCK_ANALYSIS = 'multiple-flock-analysis',
  OPEN_ANIMAL_GROUP_RECORD = 'open-animal-group-record',
  CLOSED_ANIMAL_GROUP_RECORD = 'closed-animal-group-record',
  PERFORMANCE_ANALYSIS = 'performance-analysis',
  PERFORMANCE_ANALYSIS_CARDS = 'performance-analysis-cards',
}

export enum ReportTabReproductiveEnum {
  TOTAL_SERVICES = 'performance-analysis-swine-services',
  BORN_ALIVE = 'performance-analysis-swine-born-alive',
  DNP = 'performance-analysis-swine-dnp',
  MORTALITY = 'performance-analysis-swine-mortality',
  MAX_PRODUCTIVE_POTENTIAL = 'performance-analysis-swine-max-productive-potential',
  REPRODUCTIVE_LOSSES = 'performance-analysis-swine-reproductive-losses',
  WEANED = 'performance-analysis-swine-weaned',
}

export enum ReportCardReproductiveEnum {
  TOTAL_SERVICES = 'performance-analysis-cards-services',
  BORN_ALIVE = 'performance-analysis-cards-born-alive',
  DNP = 'performance-analysis-cards-dnp',
  MORTALITY = 'performance-analysis-cards-mortality',
  MAX_PRODUCTIVE_POTENTIAL = 'performance-analysis-cards-max-productive-potential',
  REPRODUCTIVE_LOSSES = 'performance-analysis-cards-reproductive-losses',
  WEANED = 'performance-analysis-cards-weaned',
}

export enum ReportTabDairyEnum {
  EFFICIENCY = 'performance-analysis-dairy-efficiency',
  FAT = 'performance-analysis-dairy-fat',
  MUN = 'performance-analysis-dairy-mun',
  PROTEIN = 'performance-analysis-dairy-protein',
  SHIPPED = 'performance-analysis-dairy-shipped',
  SSC = 'performance-analysis-dairy-scc',
  BC = 'performance-analysis-dairy-bc',
}

export enum ReportCardDairyEnum {
  EFFICIENCY = 'performance-analysis-cards-efficiency',
  FAT = 'performance-analysis-cards-fat',
  MUN = 'performance-analysis-cards-mun',
  PROTEIN = 'performance-analysis-cards-protein',
  SHIPPED = 'performance-analysis-cards-shipped',
  SSC = 'performance-analysis-cards-scc',
  BC = 'performance-analysis-cards-bc',
}

export enum ViewPreferenceEnum {
  MONITOR_OPENED = 'monitor_opened',
  MONITOR_CLOSED = 'monitor',
  ANIMALGROUP_LIST = 'animal_group_list',
  PRODUCER_LIST = 'producer_list',
  PERFORMANCE_GRAPH = 'performance_graph',
}

export type PreferenceId =
  | ReportEnum
  | ReportTabDairyEnum
  | ReportCardDairyEnum
  | ReportTabReproductiveEnum
  | ReportCardReproductiveEnum
  | ViewPreferenceEnum;
