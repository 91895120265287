import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DirectivesModule } from '../../../directive/directives.module';
import { AgTargetIndicatorComponent } from './ag-target-indicator.component';

@NgModule({
  declarations: [AgTargetIndicatorComponent],
  imports: [CommonModule, DirectivesModule],
  exports: [AgTargetIndicatorComponent],
})
export class AgTargetIndicatorModule {}
