import { TypeViewPreference, TypeViewPreferenceUser } from '@agriness/corp-app/services';
import { ToggleColumnItem } from '@agriness/ui';
import { Injectable } from '@angular/core';
import { isString } from 'lodash';

import { textToElementId } from '../../utils/functions.util';
import { ToggleColumnPreferenceUser } from '../model/view-preferences';

export type LabelPrefixOrProvider = string | ((s: string) => string);

@Injectable({ providedIn: 'root' })
export class ViewPreferenceService {
  convertUserPreferenceToToggleColumnPreference(
    preferences: TypeViewPreferenceUser,
    labelPrefixOrProvider: LabelPrefixOrProvider,
  ): ToggleColumnPreferenceUser {
    return {
      default: this.convertPreferencesToToggleColumnItem(
        preferences.default,
        labelPrefixOrProvider,
      ),
      user: this.convertPreferencesToToggleColumnItem(preferences.user, labelPrefixOrProvider),
    };
  }

  convertPreferencesToToggleColumnItem(
    preferences: TypeViewPreference[],
    labelPrefixOrProvider: LabelPrefixOrProvider,
  ): ToggleColumnItem[] {
    return preferences.map(p => this.convertPreferenceToToggleColumnItem(p, labelPrefixOrProvider));
  }

  convertPreferenceToToggleColumnItem(
    preference: TypeViewPreference,
    labelPrefixOrProvider: LabelPrefixOrProvider,
  ): ToggleColumnItem {
    return {
      id: textToElementId('preference', preference.index_name),
      readOnly: preference.read_only,
      frozen: preference.frozen,
      checked: preference.enable,
      label: isString(labelPrefixOrProvider)
        ? labelPrefixOrProvider + preference.index_name
        : labelPrefixOrProvider(preference.index_name),
      order: preference.order,
      effectAllowed: 'move',
      object: preference,
    };
  }
}
