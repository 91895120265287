import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Inject,
  Injector,
  Input,
  NgZone,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { NgbTooltip, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

type Placement =
  | 'auto'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'
  | 'left-top'
  | 'left-bottom'
  | 'right-top'
  | 'right-bottom';
type PlacementArray = Placement | Array<Placement> | string;

@Directive({ selector: '[agTooltip]', exportAs: 'agTooltip' })
export class AgTooltipDirective extends NgbTooltip {
  @Input()
  set agTooltip(value: string | TemplateRef<any>) {
    this.ngbTooltip = value;
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    injector: Injector,
    componentFactoryResolver: ComponentFactoryResolver,
    viewContainerRef: ViewContainerRef,
    config: NgbTooltipConfig,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: any,
    private changeDetector: ChangeDetectorRef,
    applicationRef: ApplicationRef,
  ) {
    super(
      elementRef,
      renderer,
      injector,
      componentFactoryResolver,
      viewContainerRef,
      config,
      ngZone,
      document,
      changeDetector,
      applicationRef,
    );

    config.container = 'body';
  }
}
