import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgFeedbackModule } from '../../component/feedback/ag-feedback.module';
import { AgTableModule } from '../../component/table/ag-table.module';
import { CorpRecordTableComponent } from '@agriness/corp-app/shared/component/corp-record-table/corp-record-table.component';
import { AgrinessCardModule } from '@agriness/ui/guavi/card/agriness-card.module';
import { GuaviButtonModule } from '../../guavi/button/guavi-button.module';

@NgModule({
  declarations: [CorpRecordTableComponent],
  imports: [AgFeedbackModule, CommonModule, AgTableModule, GuaviButtonModule, AgrinessCardModule],
  exports: [CorpRecordTableComponent],
})
export class CorpRecordTableModule {}
