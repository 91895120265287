<section>
  <div class="full-width">
    <div class="badge-container title-container">
      <p class="subtitle">
        {{ 'agriness.payment-rule-configuration.rules.pre-badge' | translate }}
      </p>
      <p class="badge">
        {{ 'agriness.stage.' + data.stage | translate }}
      </p>
    </div>
    <div class="title-container">
      <p class="subtitle">
        {{ 'agriness.payment-rule-configuration.rules.title' | translate }}
      </p>
    </div>
    <div class="list-container row">
      <div class="column is-one-third" *ngFor="let item of data.indexes">
        <div class="title-card">
          <p class="subtitle">
            {{ item.name }}
          </p>
        </div>
        <div class="list-box">
          <p-table
            [columns]="labels"
            [value]="item.ranges"
            [scrollable]="true"
            scrollHeight="300px"
          >
            <ng-template pTemplate="header" let-columns>
              <tr class="theader">
                <th *ngFor="let col of columns">
                  {{ col | translate }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-ranges let-columns="columns">
              <tr>
                <td>
                  {{ ranges.range }}
                </td>
                <td>
                  {{ ranges.percentage }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</section>
