import { TypeViewPreference } from '@agriness/corp-app/services';
import { AgrinessTranslateService } from '@agriness/services';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { SeriesLineOptions, PointOptionsObject, SeriesOptionsType } from 'highcharts';
import { isEmpty } from 'lodash';

import { Legend } from './corp-graph-legend.model';

@Component({
  selector: 'corp-graph-legend',
  templateUrl: './corp-graph-legend.component.html',
})
export class CorpGraphLegendComponent implements OnInit, OnChanges {
  @Input()
  options: Highcharts.Options;

  @Input()
  preferences: TypeViewPreference[];

  @Input()
  translatePrefix: string;

  @Output()
  changeSelectedSeries = new EventEmitter<SeriesOptionsType[]>();

  legends: Legend[];
  selectedLegends: string[] = [];

  constructor(private translate: AgrinessTranslateService) {}

  ngOnInit(): void {
    this.loadGraphLegend();
    this.loadGraphSeries();
  }

  ngOnChanges(): void {
    this.loadGraphLegend();
  }

  onLegendChanges({ checked }: { checked: boolean }, value: string): void {
    if (!checked) {
      this.selectedLegends = this.selectedLegends.filter(legend => legend !== value);
    } else {
      this.selectedLegends = [...this.selectedLegends, value];
    }

    this.loadGraphSeries();
  }

  private loadGraphLegend() {
    this.legends = this.preferences ? this.getLegendsByPreferences() : this.getLegends();

    if (isEmpty(this.selectedLegends)) {
      this.selectedLegends = this.legends.map(legend => legend.value);
    }
  }

  private loadGraphSeries(): void {
    const series: SeriesOptionsType[] = this.options.series.map(serie => ({
      ...serie,
      visible: this.selectedLegends.includes(serie.name),
    }));

    this.changeSelectedSeries.emit(series);
  }

  private getLegends(): Legend[] {
    return ((this.options.series || []) as SeriesLineOptions[]).map(serie => ({
      color: serie.color as string,
      dashStyle: serie.dashStyle,
      hasData: this.hasData(serie),
      label: serie.name,
      value: serie.name,
    }));
  }

  private getLegendsByPreferences(): Legend[] {
    const preferenceOrderByTranslatedName: { [name: string]: number } = this.preferences.reduce(
      (acc, preference) => {
        const label = this.translate.instantWithFallback(
          this.translatePrefix + preference.index_name,
        );
        acc[label] = preference.order;

        return acc;
      },
      {},
    ) as { [name: string]: number };

    return ((this.options.series || []) as SeriesLineOptions[])
      .map(serie => ({
        color: serie.color as string,
        dashStyle: serie.dashStyle,
        hasData: this.hasData(serie),
        label: serie.name,
        order: preferenceOrderByTranslatedName[serie.name],
        value: serie.name,
      }))
      .sort((a, b) => a.order - b.order);
  }

  private hasData(serie: SeriesLineOptions) {
    return (
      serie.data.map(({ y }: PointOptionsObject) => y).filter(value => Boolean(value)).length > 0
    );
  }
}
