import { FeedbackEnum } from '@agriness/ui';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { ReportSectionItem } from './corp-report-section.model';

@Component({
  selector: 'corp-report-section',
  templateUrl: './corp-report-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpReportSectionComponent implements AfterContentInit, ReportSectionItem {
  @Input() sectionTitle?: string;
  @Input() feedback?: FeedbackEnum;

  @Input()
  set isCard(isCard: boolean) {
    this.propIsCard = isCard;
  }

  get isCard(): boolean {
    return this.propIsCard != null ? this.propIsCard : this.currentContent.parentAsCard;
  }

  @ContentChild('actions', { static: true }) childActions: TemplateRef<unknown>;

  @ContentChildren('content')
  set externalChildrenContent(content: QueryList<ReportSectionItem>) {
    this.childrenContent = content.toArray();
  }

  @ViewChild('content', { static: true }) content: TemplateRef<unknown>;

  childrenContent: ReportSectionItem[];
  currentContent: ReportSectionItem;
  parentAsCard = false;

  private propIsCard: boolean;
  private nextContent: ReportSectionItem;

  get actions(): null {
    return null;
  }

  get currentActions(): TemplateRef<unknown> {
    return this.childActions || this.currentContent.actions;
  }

  ngAfterContentInit(): void {
    this.currentContent = this.childrenContent[0];
    this.nextContent = this.getNextContent(this.currentContent);
  }

  showHeader(): boolean {
    return Boolean(this.sectionTitle || this.showNextContentButton() || this.currentActions);
  }

  isCurrentContent(content: ReportSectionItem): boolean {
    return this.currentContent === content;
  }

  showNextContentButton(): boolean {
    return this.childrenContent.length > 1;
  }

  onNextContentClick(): void {
    this.currentContent = this.getNextContent(this.currentContent);
    this.nextContent = this.getNextContent(this.currentContent);
  }

  nextContentIcon(): string {
    return this.nextContent ? this.nextContent.icon : null;
  }

  private getNextContent(someContent: ReportSectionItem): ReportSectionItem {
    const someContentIndex = this.childrenContent.indexOf(someContent);
    const nextContentIndex = (someContentIndex + 1) % this.childrenContent.length;
    return this.childrenContent[nextContentIndex];
  }
}
