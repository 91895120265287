/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { TypeProductionEnum } from '@agriness/services';

import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  apiCorp: 'https://corp-backend.agriness-dev.com/',
  apiMyAccount: 'https://myaccount-api.agriness-dev.com/',
  appMyAccount: 'https://myaccount.agriness-dev.com/',
  apiS4Farm: 'https://s4farm-api.agriness-dev.com/',
  appS4Farm: 'https://s4farm.agriness-dev.com/#/',
  features: {
    'presence.scheduling': true,
    'traceability.health-report': true,
    'reports.onepage_report': true,
    'reports.looker_dashboard': true,
    'section.labs': true,
    'help.show-button-header': true,
    'help.show-tooltip': true,
    'filter.nutrition_type': true,
    target_new: true,
  },
  keycloak: {
    url: 'https://is.agriness-dev.com/auth',
    realm: 'agriness-dev',
    clientId: 's4corp',
  },
  sentryDsn: null,
  tagManagerId: null,
  amplitudeKey: '26e7618571970eac2da0234ebea8ac61',

  /**
   * @deprecated use TypeProductionService
   */
  get typeProduction() {
    return location.pathname.split('/')[1] as TypeProductionEnum;
  },
  newRelic: {},
  environment: 'development',
};
