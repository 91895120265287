import { AnimalGroupStatus, StageEnum } from '@agriness/services';

export enum MonitorExhibitionType {
  TABLE = 'table',
  CARDS = 'cards',
}

export type Index = {
  internalName: string;
  name?: string;
};

export const subReportsByStageAndStatus: {
  [key in StageEnum]?: {
    [key in AnimalGroupStatus]?: Index[];
  };
} = {
  [StageEnum.FINISHING]: {
    [AnimalGroupStatus.OPEN]: [
      {
        internalName: 'animal-group-opened-monitor',
      },
    ],
    [AnimalGroupStatus.CLOSED]: [
      {
        internalName: 'monitor-closed-zootechnical',
        name: 'agriness.performance_zootechnical',
      },
      {
        internalName: 'monitor-closed-economic',
        name: 'agriness.performance_economic',
      },
    ],
  },
  [StageEnum.WEAN_TO_FINISH]: {
    [AnimalGroupStatus.OPEN]: [
      {
        internalName: 'animal-group-opened-monitor',
      },
    ],
    [AnimalGroupStatus.CLOSED]: [
      {
        internalName: 'monitor-closed-zootechnical',
        name: 'agriness.performance_zootechnical',
      },
    ],
  },
  [StageEnum.GROW_OUT]: {
    [AnimalGroupStatus.OPEN]: [
      {
        internalName: 'animal-group-opened-monitor',
      },
    ],
    [AnimalGroupStatus.CLOSED]: [
      {
        internalName: 'monitor',
        name: 'agriness.closed_animal_group',
      },
    ],
  },
  [StageEnum.MILKING]: {
    [AnimalGroupStatus.CLOSED]: [
      {
        internalName: 'monitor-quality-volume',
        name: 'agriness.quality_volume',
      },
      {
        internalName: 'monitor-quality',
        name: 'agriness.quality',
      },
      {
        internalName: 'monitor-quality-nutritional',
        name: 'agriness.quality_nutritional',
      },
      {
        internalName: 'monitor-economic',
        name: 'agriness.economic',
      },
    ],
  },
  [StageEnum.NURSERY]: {
    [AnimalGroupStatus.OPEN]: [
      {
        internalName: 'animal-group-opened-monitor',
      },
    ],
    [AnimalGroupStatus.CLOSED]: [
      {
        internalName: 'monitor-closed-zootechnical',
        name: 'agriness.performance_zootechnical',
      },
      {
        internalName: 'monitor-closed-economic',
        name: 'agriness.performance_economic',
      },
    ],
  },
};

export const subReportsByStage: {
  [key in StageEnum]?: Index[];
} = {
  [StageEnum.LAYERS_PRODUCTION]: [
    {
      internalName: 'monitor-opened',
      name: 'agriness.active_flock_inventory',
    },
    {
      internalName: 'monitor-performance-indicators',
      name: 'agriness.performance_indicators',
    },
  ],
};
