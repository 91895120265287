import { Component } from '@angular/core';

@Component({
  selector: 'corp-warning-feedback',
  styleUrls: ['./corp-warning-feedback.component.scss'],
  template: `
    <div class="warning-feedback d-flex align-items">
      <i class="icon-alert-outline d-flex align-items-center"></i>
      <span><ng-content></ng-content></span>
    </div>
  `,
})
export class CorpWarningFeedbackComponent {}
