import { commonDeletionTableFields } from '@agriness/corp-app/settings/deletion-table/deletion-table.model';
import { textColumn, actionColumn } from '@agriness/ui';

const checklistKey = 'agriness.settings.presence.tools.tabs.checklist.table';

export const checklistsTableColumns = [
  {
    ...actionColumn,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'name',
    header: `${checklistKey}.name`,
    width: '200px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'system_type',
    header: `${checklistKey}.production_type`,
    width: '100px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'stage_description',
    header: `${checklistKey}.stage`,
    width: '200px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'rule_type',
    header: `${checklistKey}.rule_type`,
    width: '200px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'rule_description',
    header: `${checklistKey}.rule`,
    width: '250px',
    sortable: true,
    fixed: true,
  },
];

export const checklistDeletionTableFields: string[] = [...commonDeletionTableFields];
