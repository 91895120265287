import { AgrinessUiModule } from '@agriness/ui';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';

import { SharedModule } from '../../shared/shared.module';
import { ReproductiveMonitorRoutingModule } from './monitor-routing.module';
import { ReproductiveMonitorComponent } from './monitor.component';

@NgModule({
  imports: [
    SharedModule,
    ReproductiveMonitorRoutingModule,
    DropdownModule,
    TableModule,
    ReactiveFormsModule,
    AgrinessUiModule,
  ],
  declarations: [ReproductiveMonitorComponent],
  exports: [ReproductiveMonitorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReproductiveMonitorModule {}
