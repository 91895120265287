import { Component, Input } from '@angular/core';

@Component({
  selector: 'ag-card-tooltip',
  template: `
    <div>
      {{ 'agriness.min_recommended' | translate }}:
      <b>
        <ag-format
          [value]="minRecommended"
          [measurementUnit]="measurementUnit"
          [decimalPlaces]="decimalPlaces"
        ></ag-format>
      </b>
    </div>
    <div>
      {{ 'agriness.max_recommended' | translate }}:
      <b>
        <b>
          <ag-format
            [value]="maxRecommended"
            [measurementUnit]="measurementUnit"
            [decimalPlaces]="decimalPlaces"
          ></ag-format>
        </b>
      </b>
    </div>
  `,
})
export class AgCardTooltipComponent {
  @Input() minRecommended: number | string;
  @Input() maxRecommended: number | string;
  @Input() measurementUnit: string;
  @Input() decimalPlaces: number;
}
