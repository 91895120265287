<section class="section is-full">
  <div *ngIf="showTabs" class="tabs mb-4">
    <ul class="nav nav-tabs nav-inline">
      <li class="nav-item" *ngFor="let tab of tabs">
        <a class="nav-link" routerLinkActive="active" [id]="tab.id" [routerLink]="tab.routerLink">
          {{ tab.label | translate }}
        </a>
      </li>
    </ul>
  </div>

  <div class="tab-content" id="nav-content">
    <router-outlet></router-outlet>
  </div>
</section>
