import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ag-link',
  templateUrl: './ag-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgLinkComponent {
  @Input() route: any[] | string;
  @Input() query: { [key: string]: any };
  @Input() newTab = true;
  @Input() iconSize = 14;

  target(): string {
    return this.newTab ? '_blank' : '_self';
  }

  showNewTabIcon(): boolean {
    return this.newTab;
  }
}
