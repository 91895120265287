import { PageLeaveConfirmation } from '@agriness/corp-app/router-guard/page-leave-confirmation.guard';
import {
  TraceabilityDocument,
  TraceabilityDocumentTypeEnum,
} from '@agriness/corp-app/services/models/traceability-documents.model';
import { TraceabilityDocumentService } from '@agriness/corp-app/services/traceability-document/traceability-document.service';
import { LoaderUserPreference } from '@agriness/corp-app/shared/component/loader-user-preference';
import { AgrinessTranslateService } from '@agriness/services';
import { UserStorageService, DateService } from '@agriness/services';
import { FeedbackEnum, ModalStatusEnum, QueueItem, QueueManagerService } from '@agriness/ui';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { max, times, defaultsDeep } from 'lodash';
import { iif, Observable, of, Subscriber, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { FormEntry, FormEntries } from './traceability.model';

@Component({
  templateUrl: './traceability.component.html',
})
export class TraceabilityComponent extends LoaderUserPreference
  implements OnInit, OnDestroy, PageLeaveConfirmation {
  subscriptions: Subscription[] = [];
  pageLeaveSubscriber: Subscriber<boolean>;
  documentData: TraceabilityDocument;
  modalStatus: ModalStatusEnum;
  feedbackType: FeedbackEnum;
  animalGroupId: string;
  farmId: string;
  documentId: string;
  maxPigletsLoads = 11;
  maxPigletsSlaughter = 11;
  maxDrugs = 13;
  maxVaccines = 13;
  animalGroupName: string;
  dateFormat: string;

  feedbackVisible = false;

  translationContext = 'agriness.animal_group_record.traceability';
  translationContextModal = `${this.translationContext}.feedback_modal`;

  formGroup = this.fb.group({
    producer: this.fb.group({
      name: '',
    }),
    farm: this.fb.group({
      name: '',
    }),
    technician: this.fb.group({
      name: '',
      state_defense_agency_register_number: '',
    }),
    georeferencing: '',
    city: '',
    federative_unit: '',
    information_responsible: 'official_veterinarian',
    traceability_type: 'complete_cycle',
    piglets_loads: this.fb.array([]),
    piglets_slaughter: this.fb.array([]),
    remaining_animal_count: '',
    mortality: '',
    clinical_signs: '',
    drugs: this.fb.array([]),
    vaccines: this.fb.array([]),
    last_feed_date: '',
    last_feed_hour: '',
  });

  get pigletsLoads(): FormArray {
    return this.formGroup.get('piglets_loads') as FormArray;
  }

  get pigletsSlaughter(): FormArray {
    return this.formGroup.get('piglets_slaughter') as FormArray;
  }

  get drugs(): FormArray {
    return this.formGroup.get('drugs') as FormArray;
  }

  get vaccines(): FormArray {
    return this.formGroup.get('vaccines') as FormArray;
  }

  constructor(
    protected translate: AgrinessTranslateService,
    protected userStorageService: UserStorageService,
    private queueManager: QueueManagerService,
    private traceabilityDocumentService: TraceabilityDocumentService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private dateService: DateService,
  ) {
    super(userStorageService);
  }

  ngOnInit(): void {
    this.addPigletsLoads();
    this.addPigletsSlaughter();
    this.addDrugs();
    this.addVaccines();
    this.dateFormat = this.dateService.getDateFormat();

    this.route.queryParamMap
      .pipe(tap(queryParams => (this.animalGroupName = queryParams.get('animalGroupName'))))
      .subscribe()
      .unsubscribe();

    const routeSubscription = this.route.paramMap
      .pipe(
        switchMap(params => {
          if (isEmpty(params['params'])) {
            return of(null);
          }

          this.farmId = params.get('idFarm');
          this.animalGroupId = params.get('idAnimalGroup');
          this.documentId = params.get('documentId');

          this.feedbackType = FeedbackEnum.LOADING;

          return this.loadHealthReport(this.documentId).pipe(
            tap(() => {
              this.feedbackType = null;
            }),
          );
        }),
        catchError(error => {
          this.feedbackType = FeedbackEnum.ERROR;

          return throwError(error);
        }),
      )
      .subscribe();
    this.subscriptions.push(routeSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  cantAddPigletsLoads(): boolean {
    return this.pigletsLoads.length >= this.maxPigletsLoads;
  }

  addPigletsLoads(): void {
    if (this.cantAddPigletsLoads()) {
      return;
    }
    this.pigletsLoads.push(
      this.fb.group({
        registration_id: '',
        registration_amount: '',
        deaths: '',
        incoming_date: '',
        animal_number: '',
      }),
    );
  }

  cantRemovePigletsLoads(): boolean {
    return this.pigletsLoads.length <= 1;
  }

  removePigletsLoads(): void {
    if (this.cantRemovePigletsLoads()) {
      return;
    }
    this.pigletsLoads.removeAt(this.pigletsLoads.length - 1);
  }

  cantAddPigletsSlaughter(): boolean {
    return this.pigletsSlaughter.length >= this.maxPigletsSlaughter;
  }

  addPigletsSlaughter(): void {
    if (this.cantAddPigletsSlaughter()) {
      return;
    }
    this.pigletsSlaughter.push(
      this.fb.group({
        registration_id: '',
        registration_date: '',
        outgoing_date: '',
        animal_number: '',
        mortality_rate: '',
      }),
    );
  }

  cantRemovePigletsSlaughter(): boolean {
    return this.pigletsSlaughter.length <= 1;
  }

  removePigletsSlaughter(): void {
    if (this.cantRemovePigletsSlaughter()) {
      return;
    }
    this.pigletsSlaughter.removeAt(this.pigletsSlaughter.length - 1);
  }

  cantAddDrugs(): boolean {
    return this.drugs.length >= this.maxDrugs;
  }

  addDrugs(): void {
    if (this.cantAddDrugs()) {
      return;
    }
    this.drugs.push(
      this.fb.group({
        active_principle: '',
        start_date: '',
        slaughter_clearance_date: '',
        slaughter_clearance: '',
      }),
    );
  }

  cantRemoveDrugs(): boolean {
    return this.drugs.length <= 1;
  }

  removeDrugs(): void {
    if (this.cantRemoveDrugs()) {
      return;
    }
    this.drugs.removeAt(this.drugs.length - 1);
  }

  cantAddVaccines(): boolean {
    return this.vaccines.length >= this.maxVaccines;
  }

  addVaccines(): void {
    if (this.cantAddVaccines()) {
      return;
    }
    this.vaccines.push(
      this.fb.group({
        vaccination_date: '',
        vaccine_name: '',
      }),
    );
  }

  cantRemoveVaccines(): boolean {
    return this.vaccines.length <= 1;
  }

  removeVaccines(): void {
    if (this.cantRemoveVaccines()) {
      return;
    }
    this.vaccines.removeAt(this.vaccines.length - 1);
  }

  saveDraft(): void {
    const t = (key: string) =>
      this.translate.instantWithFallback(
        `agriness.animal_group_record.traceability.feedback.${key}`,
      );
    const data = this.formGroup.getRawValue() as FormEntry;
    const documentData: TraceabilityDocument = {
      document_type: TraceabilityDocumentTypeEnum.SWINE_BRA_HEALTH_REPORT,
      data,
    };

    this.queueManager
      .add({}, '')
      .pipe(
        tap(({ actions }: QueueItem) => {
          actions.update(t('waiting'));
          this.traceabilityDocumentService
            .save(this.holdingId, this.animalGroupId, documentData, this.documentId)
            .pipe(
              map(result => (this.documentId = result.id)),
              catchError(({ error }) => {
                actions.error(t('error'));
                return throwError(error);
              }),
              tap(() => {
                actions.success(t('success'));
                this.formGroup.markAsPristine();
                this.formGroup.markAsUntouched();
              }),
            )
            .subscribe();
        }),
      )
      .subscribe();
  }

  downloadDraft(): void {
    this.traceabilityDocumentService
      .downloadDraft(this.holdingId, this.animalGroupId, this.documentId)
      .subscribe();
  }

  publishToBlockchain(): void {
    this.showLoadingModal();

    const documentData: TraceabilityDocument = {
      document_type: TraceabilityDocumentTypeEnum.SWINE_BRA_HEALTH_REPORT,
      data: this.formGroup.getRawValue() as FormEntry,
    };

    this.callTraceabilityService(
      this.traceabilityDocumentService.publish(this.holdingId, this.animalGroupId, documentData),
    );
  }

  callTraceabilityService(observable: Observable<TraceabilityDocument>): Subscription {
    return observable
      .pipe(
        map(result => (this.documentId = result.id)),
        catchError(({ error }) => {
          this.showFailModal();
          return throwError(error);
        }),
      )
      .subscribe(data => {
        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();

        this.showSuccessModal();
        return data;
      });
  }

  t(key: string): string {
    return this.translate.instantWithFallback(`${this.translationContext}.${key}`);
  }

  confirmPublish(): void {
    this.showConfirmModal();
  }

  confirmPageLeave(): Observable<boolean> {
    if (this.formGroup.dirty) {
      this.feedbackVisible = true;
      return new Observable<boolean>(subscriber => {
        this.pageLeaveSubscriber = subscriber;
      });
    }
    return of(true);
  }

  onPageLeave(leave: boolean): void {
    this.pageLeaveSubscriber.next(leave);
    this.feedbackVisible = false;
  }

  hideModal(): void {
    this.modalStatus = null;
  }

  private resizeFormGroup(data: FormEntry): FormEntry {
    const pigletsLoadsData = data['piglets_loads'] as FormEntries;
    const pigletsSlaughterData = data['piglets_slaughter'] as FormEntries;
    const drugsData = data['drugs'] as FormEntries;
    const vaccinesData = data['vaccines'] as FormEntries;

    const addInPigletsLoadsData = max([pigletsLoadsData.length - this.pigletsLoads.length, 0]);
    const addInPigletsSlaughterData = max([
      pigletsSlaughterData.length - this.pigletsSlaughter.length,
      0,
    ]);
    const addInDrugsData = max([drugsData.length - this.drugs.length, 0]);
    const addInVaccinesData = max([vaccinesData.length - this.vaccines.length, 0]);

    times(addInPigletsLoadsData, () => this.addPigletsLoads());
    times(addInPigletsSlaughterData, () => this.addPigletsSlaughter());
    times(addInDrugsData, () => this.addDrugs());
    times(addInVaccinesData, () => this.addVaccines());

    return data;
  }

  private showConfirmModal() {
    this.modalStatus = ModalStatusEnum.CONFIRMED;
  }

  private showLoadingModal() {
    this.modalStatus = ModalStatusEnum.LOADING;
  }

  private showSuccessModal() {
    this.modalStatus = ModalStatusEnum.SUCCESS;
  }

  private showFailModal() {
    this.modalStatus = ModalStatusEnum.FAILED;
  }

  private castFormTypes(data: FormEntry): FormEntry {
    const pigletsLoadsData = data['piglets_loads'] as FormEntries;
    const pigletsSlaughterData = data['piglets_slaughter'] as FormEntries;
    const drugsData = data['drugs'] as FormEntries;
    const vaccinesData = data['vaccines'] as FormEntries;

    this.castDateType('last_feed_date', data);

    pigletsLoadsData.forEach(record => {
      this.castDateType('incoming_date', record);
    });

    pigletsSlaughterData.forEach(record => {
      this.castDateType('registration_date', record);
      this.castDateType('outgoing_date', record);
    });

    drugsData.forEach(record => {
      this.castDateType('start_date', record);
      this.castDateType('slaughter_clearance_date', record);
    });

    vaccinesData.forEach(record => {
      this.castDateType('vaccination_date', record);
    });

    return data;
  }

  private castDateType(field: string, obj: FormEntry) {
    if (obj[field] === '') {
      return;
    }
    obj[field] = obj[field] === null ? '' : this.dateService.toDate(obj[field] as string);
  }

  private loadHealthReport(documentId: string): Observable<FormEntry> {
    return iif(
      () => Boolean(documentId),
      this.traceabilityDocumentService
        .get(this.holdingId, this.animalGroupId, documentId)
        .pipe(map(({ data }) => data)),
      this.loadDefaultHealthReportData(),
    ).pipe(tap(data => this.formGroup.patchValue(this.castFormTypes(this.resizeFormGroup(data)))));
  }

  private loadDefaultHealthReportData(): Observable<FormEntry> {
    const documentType: TraceabilityDocumentTypeEnum =
      TraceabilityDocumentTypeEnum.SWINE_BRA_HEALTH_REPORT;

    return this.traceabilityDocumentService
      .getDocumentDefaultData(this.holdingId, this.animalGroupId, documentType.toLowerCase())
      .pipe(map(result => defaultsDeep({}, result, this.formGroup.getRawValue()) as FormEntry));
  }
}
