<div *ngFor="let legend of legends">
  <p-checkbox
    name="graph-legend"
    [value]="legend.value"
    [label]="legend.label"
    [disabled]="!legend.hasData"
    [(ngModel)]="selectedLegends"
    (onChange)="onLegendChanges($event, legend.label)"
  ></p-checkbox>
</div>
