<corp-report-cards
  preferenceLabelPrefix="agriness.performances."
  sectionName="agriness.performance_indicators"
  [feedbackType]="feedbackType"
  [performances]="getOverviews()"
  [report]="preferenceType"
  [stage]="stage"
  (getPreferences)="this.setPreferences($event)"
  (preferenceChange)="this.onPreferenceChange()"
></corp-report-cards>
