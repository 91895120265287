import { TargetStatus } from '@agriness/ui';

export class AnimalGroupResume {
  constructor(public title?: string, public performances?: AnimalGroupPerformance[]) {}
}

export class AnimalGroupPerformance {
  constructor(
    public index_name?: string,
    public label?: string,
    public index_value?: number | string,
    public measurement_unit?: MeasurementUnitEnum,
    public decimal_places?: number,
    public target_definition?: TargetDefinition,
    public performance?: Performance[],
    public status?: TargetStatus,
  ) {}
}

export class AnimalGroupMonitorPerformance {
  constructor(
    public id?: string,
    public index_name?: string,
    public label?: string,
    public grouping_name?: GroupMonitorEnum,
    public performance?: Performance[],
    public measurement_unit?: MeasurementUnitEnum,
    public decimal_places?: number,
    public target_definition?: TargetDefinition,
    public animal_group_count_by_target?: { [k in TargetStatus]: number },
  ) {}
}

export class AnimalGroupMonitorPerformanceByPeriod {
  constructor(public performances: AnimalGroupPerformanceByPeriod[], public periods: Period[]) {}
}

export class AnimalGroupPerformanceByPeriod {
  constructor(
    public performances_by_period: Performance[],
    public index_name: string,
    public performance?: Performance,
    public measurement_unit?: MeasurementUnitEnum,
    public decimal_places?: number,
  ) {}
}

export class Period {
  begin_date: string;
  end_date: string;
}

export class AnimalGroupMonitorOpenedPerformance {
  constructor(public group_type?: GroupTypeEnum, public performances?: AnimalGroupPerformance[]) {}
}

export type AnimalGroupCountByTarget = {
  attention?: number;
  bad?: number;
  good?: number;
};

export class Performance {
  constructor(
    public animal_group_count_by_target?: AnimalGroupCountByTarget,
    public name?: NamePerformanceGroupEnum,
    public type?: PerformanceType,
    public index_value?: number | string,
    public target_value?: number | string,
    public grouping?: number,
    public index_value_partial?: boolean,
    public status?: TargetStatus,
    public period?: Period,
    public measurement_unit?: MeasurementUnitEnum,
    public decimal_places?: number,
  ) {}
}

export class TargetDefinition {
  constructor(
    public status?: TargetStatus,
    public target_value?: number | string,
    public target_name?: TargetNameEnum,
    public target_measurement_unit?: MeasurementUnitEnum,
    public min_recommended?: number | string,
    public max_recommended?: number | string,
    public representation_type?: TargetRepresentationType,
  ) {}
}

export enum NamePerformanceGroupEnum {
  DAY = 'day',
  MONTH = 'month',
}

export enum GroupTypeEnum {
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
  FOURTH = 'fourth',
  FIFTH = 'fifth',
  SIXTH = 'sixth',
  SEVENTH = 'seventh',
  EIGHTH = 'eighth',
  NINTH = 'ninth',
  TENTH = 'tenth',
  ELEVENTH = 'eleventh',
  TWELFTH = 'twelfth',
  THIRTEENTH = 'thirteenth',
  FOURTEENTH = 'fourteenth',
  FIFTEENTH = 'fifteenth',
  SIXTEENTH = 'sixteenth',
}

export enum TargetNameEnum {
  TARGET = 'target',
  GROUP = 'group',
  SAMPLE = 'sample',
  PREDICTION = 'prediction',
}

export enum MeasurementUnitEnum {
  NONE = '',
  PERCENTAGE = 'percentage',
  MONETARY = 'monetary',
  KILOGRAM = 'kilogram',
  GRAM = 'gram',
  LITER = 'liter',
  UNITARY = 'unitary',
  DECIMAL = 'decimal',
  DATE = 'date',
  DAYS = 'days',
  GROUP = 'group',
  WEEKS = 'weeks',
}

export enum TargetRepresentationType {
  RANGE_BAD_GOOD = 'RANGE_BAD_GOOD',
  RANGE_BAD_ATTENTION_GOOD = 'RANGE_BAD_ATTENTION_GOOD',
  SCALE_GOOD_ATTENTION_BAD = 'SCALE_GOOD_ATTENTION_BAD',
  SCALE_BAD_ATTENTION_GOOD = 'SCALE_BAD_ATTENTION_GOOD',
  SCALE_ATTENTION_GOOD_BAD = 'SCALE_ATTENTION_GOOD_BAD',
  SCALE_BAD_GOOD = 'SCALE_BAD_GOOD',
}

export enum GroupMonitorEnum {
  ECONOMIC = 'economic',
  QUALITY_VOLUME = 'quality_volume',
}

export type PerformanceType = 'average' | 'efficiency' | 'goal' | 'target';
