<section class="section is-full">
  <div class="row mb-3">
    <div class="col">
      <div class="page-title d-flex align-items-center">
        <corp-back-button></corp-back-button>
        {{ 'agriness.report.index_map_generator' | translate }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card shadow-sm">
        <form [formGroup]="form" class="card-body" autocomplete="off">
          <ag-steps>
            <ag-step
              data-testid="step-filters"
              [label]="'agriness.index_map_generator.filters.title' | translate"
              [done]="steps.filters.done"
            >
              <corp-report-filters
                #reportFilter
                [stage]="stage"
                [availableFilters]="filterIds"
                (ready)="onFiltersReady()"
                (valid)="onfilterValid($event)"
                class="mb-3"
              ></corp-report-filters>
            </ag-step>
            <ag-step
              data-testid="step-indexes"
              [label]="'agriness.index_map_generator.index_picker.title' | translate"
              [description]="'agriness.index_map_generator.index_picker.description' | translate"
              [done]="steps.indexes.done"
            >
              <ag-dual-list-box
                class="indexes"
                [items]="optionsIndex"
                formControlName="indexes"
                [labels]="{
                  available: {
                    title: 'agriness.index_map_generator.index_picker.available.title' | translate,
                    emptyTitle:
                      'agriness.index_map_generator.index_picker.available.empty_title' | translate,
                    emptyDescription:
                      'agriness.index_map_generator.index_picker.available.empty_description'
                      | translate,
                    filterLabel:
                      'agriness.index_map_generator.index_picker.available.filter_label' | translate
                  },
                  selected: {
                    title: 'agriness.index_map_generator.index_picker.selected.title' | translate,
                    emptyTitle:
                      'agriness.index_map_generator.index_picker.selected.empty_title' | translate,
                    emptyDescription:
                      'agriness.index_map_generator.index_picker.selected.empty_description'
                      | translate,
                    filterLabel:
                      'agriness.index_map_generator.index_picker.selected.filter_label' | translate
                  }
                }"
              ></ag-dual-list-box>
            </ag-step>
            <ag-step
              data-testid="step-group-by"
              [label]="'agriness.index_map_generator.group_by.title' | translate"
              [description]="'agriness.index_map_generator.group_by.description' | translate"
              [done]="steps.groupBy.done"
            >
              <div data-testid="group-by-section">
                <div class="btn-group-toggle">
                  <label
                    *ngFor="let option of groupByOptions"
                    class="btn mr-3"
                    [ngClass]="form.value.groupBy === option ? 'btn-primary' : 'btn-secondary'"
                  >
                    <input
                      type="radio"
                      [id]="option.value"
                      formControlName="groupBy"
                      [value]="option"
                    />
                    {{ option.label | translate }}
                  </label>
                </div>
              </div>
            </ag-step>
            <ag-step
              data-testid="step-download"
              [label]="'agriness.index_map_generator.download.title' | translate"
              [done]="steps.download.done"
            >
              <div>
                <button
                  [disabled]="!steps.download.enabled"
                  (click)="download(DocumentType.XLS)"
                  class="btn btn-primary mr-3"
                >
                  {{ 'agriness.index_map_generator.download.xls' | translate }}
                </button>
                <button
                  [disabled]="!steps.download.enabled"
                  (click)="download(DocumentType.CSV)"
                  class="btn btn-primary mr-3"
                >
                  {{ 'agriness.index_map_generator.download.csv' | translate }}
                </button>
              </div>
            </ag-step>
          </ag-steps>
        </form>
      </div>
    </div>
  </div>
</section>
