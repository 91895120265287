import { AnimalGroupStatus, StageEnum } from '@agriness/services';

import { ReportFilterType } from './report-filter.model';

export const filterIdsByStageAndStatus: {
  [key in StageEnum]?: { [key in AnimalGroupStatus]?: ReportFilterType[] };
} = {
  [StageEnum.FINISHING]: {
    [AnimalGroupStatus.OPEN]: [
      'COMPANY',
      'FARM',
      'REGION',
      'TECHNICIAN',
      'PRODUCER',
      'NUTRITION_TYPE',
    ],
    [AnimalGroupStatus.CLOSED]: [
      'COMPANY',
      'PERIOD',
      'FARM',
      'REGION',
      'TECHNICIAN',
      'PRODUCER',
      'NUTRITION_TYPE',
    ],
  },
  [StageEnum.WEAN_TO_FINISH]: {
    [AnimalGroupStatus.OPEN]: [
      'COMPANY',
      'FARM',
      'REGION',
      'TECHNICIAN',
      'PRODUCER',
      'NUTRITION_TYPE',
    ],
    [AnimalGroupStatus.CLOSED]: [
      'COMPANY',
      'PERIOD',
      'FARM',
      'REGION',
      'TECHNICIAN',
      'PRODUCER',
      'NUTRITION_TYPE',
    ],
  },
  [StageEnum.GROW_OUT]: {
    [AnimalGroupStatus.OPEN]: ['COMPANY', 'FARM', 'REGION', 'PRODUCER', 'TECHNICIAN'],
    [AnimalGroupStatus.CLOSED]: ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'PRODUCER', 'TECHNICIAN'],
  },
  [StageEnum.MILKING]: {
    [AnimalGroupStatus.CLOSED]: ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'TECHNICIAN'],
  },
  [StageEnum.NURSERY]: {
    [AnimalGroupStatus.OPEN]: ['COMPANY', 'FARM', 'REGION', 'TECHNICIAN', 'PRODUCER'],
    [AnimalGroupStatus.CLOSED]: ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'TECHNICIAN', 'PRODUCER'],
  },
  [StageEnum.LAYERS_PRODUCTION]: {
    [AnimalGroupStatus.OPEN]: [
      'COMPANY',
      'FARM',
      'SITE',
      'STRAIN',
      'PRODUCTION_SYSTEM',
      'PRODUCTION_METHOD',
      'CURRENT_CYCLE',
    ],
    [AnimalGroupStatus.CLOSED]: [
      'COMPANY',
      'PERIOD',
      'FARM',
      'SITE',
      'STRAIN',
      'PRODUCTION_SYSTEM',
      'PRODUCTION_METHOD',
      'CURRENT_CYCLE',
    ],
  },
};
