import { Search, UserStorageService } from '@agriness/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { TargetStatusKpi, TargetStatusKpiFilter } from '../models/target-status-kpi.model';

@Injectable()
export class TargetStatusKpiService {
  private pathBase = 'holdings/{holding_id}/target-indexes/{stage}/';

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
  ) {}

  list(params: TargetStatusKpiFilter): Observable<Search<TargetStatusKpi>> {
    const url = this.apiCorpService.translateUrl(this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
      stage: params.stage,
    });
    return this.apiCorpService.get<Search<TargetStatusKpi>>(url, params);
  }
}
