import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { AgrinessIconComponent } from './agriness-icon.component';

@NgModule({
  declarations: [AgrinessIconComponent],
  imports: [SharedModule],
  exports: [AgrinessIconComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgrinessIconModule {}
