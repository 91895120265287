import {
  Component,
  Input,
  TemplateRef,
  ChangeDetectionStrategy,
  Pipe,
  PipeTransform,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { TargetStatus } from '../target/target.model';
import {
  IndexesTableColumn,
  IndexesTableRow,
  IndexesTableRowWithCells,
  IndexesTablePerformance,
} from './ag-indexes-table.model';

@Component({
  selector: 'ag-indexes-table',
  templateUrl: './ag-indexes-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgIndexesTableComponent implements OnChanges {
  @Input() columns: IndexesTableColumn[];
  @Input() rows: IndexesTableRow[];
  @Input() cellTemplate: TemplateRef<any>;
  @Input() headerValueTemplate: TemplateRef<any>;
  @Input() indexNameTranslatePrefix = 'agriness.';
  rowsWithCells: IndexesTableRowWithCells[] = [];
  cellStatusClass: { [k in TargetStatus]: string } = {
    [TargetStatus.BAD]: 'is-danger',
    [TargetStatus.ATTENTION]: 'is-attention',
    [TargetStatus.GOOD]: 'is-success',
  };
  legends = Object.values(TargetStatus);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.row || changes.columns) {
      this.rowsWithCells = this.buildCells(this.rows);
    }
  }

  private buildCells(rows: IndexesTableRow[]): IndexesTableRowWithCells[] {
    return rows.map(row => ({
      ...row,
      hasAnyValue: row.indexes.some(i => i.performance.index_value !== null && i !== undefined),
      cells: this.columns.map((a, i) => {
        const values = row.indexes.map(index => index.performances_by_period[i]);
        return {
          values,
          status: this.firstAvailableStatus(values),
          link: row.links ? row.links[i] : null,
        };
      }),
    }));
  }

  private firstAvailableStatus(values: IndexesTablePerformance[]): TargetStatus {
    return values.map(index => index.status).filter(s => s)[0];
  }
}
