import {
  AnimalGroup,
  AnimalGroupAbstractService,
  AnimalGroupResume,
  ReportEnum,
  TypeViewPreferenceUser,
} from '@agriness/corp-app/services';
import { LoaderUserPreference } from '@agriness/corp-app/shared/component/loader-user-preference';
import {
  StageEnum,
  UserStorageService,
  TypeProductionEnum,
  TypeProductionService,
} from '@agriness/services';
import { FeedbackEnum } from '@agriness/ui';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { snakeCase } from 'lodash';
import { throwError, Observable, Subscription, forkJoin } from 'rxjs';
import { switchMap, tap, catchError } from 'rxjs/operators';

import { CorpPreferenceToggleColumnComponent } from '../../../shared/component/corp-preference-toggle-column/corp-preference-toggle-column.component';
import { CardBuilderService } from '../../../shared/services/card-builder.service';
import { AnimalGroupStateService } from '../animal-group-state.service';
import { OverviewModel } from './overview.model';

type TypeProductionCallback = (
  animalGroup: AnimalGroup,
) => Observable<AnimalGroupResume | AnimalGroupResume[]>;

@Component({
  templateUrl: './overview.component.html',
})
export class OverviewComponent extends LoaderUserPreference implements OnInit, OnDestroy {
  feedbackType?: FeedbackEnum = FeedbackEnum.LOADING;
  filteredOverviews: OverviewModel[] = [];
  hiddenColumns: string[];
  overviews: OverviewModel[] = [];
  preferences: CorpPreferenceToggleColumnComponent;
  preferenceType: ReportEnum;
  stage: StageEnum;

  private preferencesEnabled = [TypeProductionEnum.LAYERS, TypeProductionEnum.SWINES];
  private subscription = Subscription.EMPTY;
  private subscriptionByTypeProduction: { [k in TypeProductionEnum]?: TypeProductionCallback[] } = {
    [TypeProductionEnum.LAYERS]: [this.subscriptionPerformanceLayers.bind(this)],
    [TypeProductionEnum.POULTRY]: [this.subscriptionPerformancePoultry.bind(this)],
    [TypeProductionEnum.SWINES]: [this.subscriptionPerformanceSwine.bind(this)],
  };

  constructor(
    protected userStorageService: UserStorageService,
    private animalGroupStateService: AnimalGroupStateService,
    private animalService: AnimalGroupAbstractService,
    private cardBuilderService: CardBuilderService,
    private typeProductionService: TypeProductionService,
  ) {
    super(userStorageService);
  }

  ngOnInit(): void {
    this.feedbackType = FeedbackEnum.LOADING;

    this.subscription = this.animalGroupStateService
      .getAnimalGroup()
      .pipe(
        switchMap(animalGroup => {
          this.stage = animalGroup.stage.name;
          this.preferenceType = animalGroup.is_open
            ? ReportEnum.OPEN_ANIMAL_GROUP_RECORD
            : ReportEnum.CLOSED_ANIMAL_GROUP_RECORD;

          return forkJoin(this.getObservable(animalGroup));
        }),
        tap(() => {
          if (this.hasPreferences()) {
            this.loadPreferences().subscribe(() => this.onPreferenceChange());
          } else {
            this.onPreferenceChange();
          }
        }),
        catchError(err => {
          this.feedbackType = FeedbackEnum.ERROR;

          return throwError(err);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setPreferences(preferences: CorpPreferenceToggleColumnComponent): void {
    this.preferences = preferences;
  }

  onPreferenceChange(): void {
    if (this.hasPreferences() && !this.preferences.getPreferences().some(p => p.enable)) {
      this.feedbackType = FeedbackEnum.NO_ITEM_SELECTED;
      return;
    }

    const enabledPreferences = this.preferences
      .getPreferences()
      .filter(({ enable }) => enable)
      .map(({ index_name }) => index_name.toLowerCase());

    const preferencesOrder = enabledPreferences.reduce<Record<string, number>>(
      (orders, id, index) => ({
        ...orders,
        [id]: index,
      }),
      {},
    );

    this.filteredOverviews = this.overviews.map(overview => ({
      ...overview,
      cards: overview.cards
        .filter(({ id }) => enabledPreferences.includes(snakeCase(id)))
        .sort((a, b) => {
          const aIndex = preferencesOrder[snakeCase(a.id)];
          const bIndex = preferencesOrder[snakeCase(b.id)];
          return aIndex - bIndex;
        }),
    }));

    this.feedbackType = !this.hasData() ? FeedbackEnum.NOT_RESULT : null;
  }

  hasPreferences(): boolean {
    const typeProduction = this.typeProductionService.get();

    return this.preferencesEnabled.includes(typeProduction);
  }

  getOverviews(): OverviewModel[] {
    return this.hasPreferences() ? this.filteredOverviews : this.overviews;
  }

  private getObservable(
    animalGroup: AnimalGroup,
  ): Observable<AnimalGroupResume[] | AnimalGroupResume>[] {
    const typeProduction = this.typeProductionService.get();
    const list = this.subscriptionByTypeProduction[typeProduction];

    return list.map(method => {
      return method.call(this, animalGroup) as ReturnType<TypeProductionCallback>;
    });
  }

  private parseToOverviewModel(
    animalGroupResume: AnimalGroupResume,
    prefixTranslateKey?: string,
  ): OverviewModel {
    return {
      title: animalGroupResume.title,
      cards: animalGroupResume.performances.map(performance =>
        this.cardBuilderService.parsePerformanceToCardModel(
          performance,
          prefixTranslateKey,
          this.stage,
        ),
      ),
    };
  }

  private loadPreferences(): Observable<TypeViewPreferenceUser> {
    return this.preferences.load();
  }

  private subscriptionPerformanceLayers(animalGroup: AnimalGroup): Observable<AnimalGroupResume[]> {
    return this.animalService
      .getAnimalGroupPerformances(this.holdingId, animalGroup.farm.id, animalGroup.id)
      .pipe(
        tap(animalGroupResumes => {
          this.overviews = animalGroupResumes.map(animalGroupResume =>
            this.parseToOverviewModel(animalGroupResume),
          );
        }),
      );
  }

  private subscriptionPerformancePoultry(
    animalGroup: AnimalGroup,
  ): Observable<AnimalGroupResume[]> {
    const prefixKeyTranslate = animalGroup.is_open
      ? 'agriness.performances.open_animal_group.'
      : null;

    return this.animalService
      .getAnimalGroupPerformances(this.holdingId, animalGroup.farm.id, animalGroup.id)
      .pipe(
        tap(animalGroupResumes => {
          this.overviews = animalGroupResumes.map(animalGroupResume =>
            this.parseToOverviewModel(animalGroupResume, prefixKeyTranslate),
          );
        }),
      );
  }

  private subscriptionPerformanceSwine(animalGroup: AnimalGroup): Observable<AnimalGroupResume> {
    return this.animalService
      .getAnimalGroupPerformance(this.holdingId, animalGroup.farm.id, animalGroup.id)
      .pipe(
        tap(animalGroupResume => {
          this.overviews = [this.parseToOverviewModel(animalGroupResume)];
        }),
      );
  }

  private hasData() {
    return (this.getOverviews() || []).some(p => p.cards.length > 0);
  }
}
