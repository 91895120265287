<ag-table #table [data]="data" [columns]="columns" [scrollHeight]="height" [isModal]="true">
  <ng-template pTemplate="bodycell" let-col="column" let-data="data">
    <ng-container
      *ngIf="col.field === 'comments'; then commentCell; else defaultCell"
    ></ng-container>

    <ng-template #commentCell>
      <td>
        <ng-container *ngIf="data?.value">
          <ag-copy-button
            icon="icon icon-report is-16"
            [value]="data.value"
            [tooltip]="data.value"
          ></ag-copy-button>
        </ng-container>
      </td>
    </ng-template>

    <ng-template #defaultCell>
      <td>
        <ag-table-cell-content
          [data]="data"
          [column]="col"
          [showSymbol]="false"
        ></ag-table-cell-content>
      </td>
    </ng-template>
  </ng-template>
</ag-table>
