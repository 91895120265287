import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ShowSymbol, ValueFormatStyle } from '../../pipe/value-format/value-format.model';

@Component({
  selector: 'ag-format',
  template: ` {{ value | agValueFormat: style:decimalPlaces:showSymbolOpt }} `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgFormatComponent {
  @Input() value: any;
  @Input() style: ValueFormatStyle | string;
  @Input() decimalPlaces: number;
  @Input() set showSymbol(value: boolean) {
    this.showSymbolOpt = value ? 'show-symbol' : 'hide-symbol';
  }

  /**
   * @deprecated use {@link style}
   */
  @Input() set measurementUnit(value: string) {
    // if (value != 'kilogram') this.style = value;
  }

  showSymbolOpt: ShowSymbol;
}
