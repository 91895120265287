import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AgrinessCardComponent } from './agriness-card.component';
import { AgrinessIconModule } from './../icon/agriness-icon.module';

@NgModule({
  imports: [CommonModule, AgrinessIconModule],
  declarations: [AgrinessCardComponent],
  exports: [AgrinessCardComponent],
})
export class AgrinessCardModule {}
