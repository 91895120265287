import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CorpReportMonitorTabsComponent } from '../../shared/component/corp-report-monitor-tabs/corp-report-monitor-tabs.component';
import { ReproductiveMonitorComponent } from './monitor.component';

const routes: Routes = [
  {
    path: '',
    component: ReproductiveMonitorComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'indicators' },
      { path: 'indicators', component: CorpReportMonitorTabsComponent },
      { path: 'diagnostic-tree', component: CorpReportMonitorTabsComponent },
    ],
  },
];

export const routerReproductiveMonitor: ModuleWithProviders<RouterModule> = RouterModule.forChild(
  routes,
);

@NgModule({
  imports: [routerReproductiveMonitor],
  exports: [RouterModule],
})
export class ReproductiveMonitorRoutingModule {}
