import { TypeProductionEnum } from '@agriness/services';
import { TabModel } from '@agriness/ui';

const swineTabs: TabModel[] = [
  {
    label: 'agriness.stage.nursery',
    id: 'tab-nursery',
    routerLink: `nursery`,
  },
  {
    label: 'agriness.stage.finishing',
    id: 'tab-finishing',
    routerLink: `finishing`,
  },
];

const poultryTabs: TabModel[] = [
  {
    label: 'agriness.stage.grow-out',
    id: 'tab-nursery',
    routerLink: `grow-out`,
  },
];

export const generalTabs: { [key in TypeProductionEnum]: TabModel[] } = {
  [TypeProductionEnum.SWINES]: swineTabs,
  [TypeProductionEnum.POULTRY]: poultryTabs,
  [TypeProductionEnum.DAIRY]: [],
  [TypeProductionEnum.LAYERS]: [],
};
