import {
  BonificationService,
  Target,
  TargetForm,
  TargetService,
  TargetV2,
} from '@agriness/corp-app/services';
import { CorpWizzard } from '@agriness/corp-app/shared/component/corp-wizzard/corp-wizzard.model';
import { TranslateInstant, TRANSLATE_INSTANT, UserStorageService } from '@agriness/services';
import { QueueItem, QueueManagerService } from '@agriness/ui';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { TargetConfigGeneralDataComponent } from './target-config-general-data/target-config-general-data.component';
import { TargetConfigRulesComponent } from './target-config-rules/target-config-rules.component';
import { MainForm, PeriodTypeEnum } from './target-editor-form.model';

@Component({
  selector: 'app-target-editor',
  templateUrl: './target-editor.component.html',
  styleUrls: ['./target-editor.component.scss'],
})
export class TargetEditorComponent implements OnInit, AfterViewInit {
  @ViewChild(TargetConfigGeneralDataComponent) main: TargetConfigGeneralDataComponent;
  @ViewChild(TargetConfigRulesComponent) config: TargetConfigRulesComponent;

  translationContext = 'agriness.settings.targets';

  stepValid = new BehaviorSubject(false);
  pageTitle = 'agriness.settings.targets.editor.title';

  data = new BehaviorSubject<MainForm>(null);
  form: MainForm = null;

  activeIndex: number;
  isValid = false;
  validStep: number;

  wizzardItems: CorpWizzard[];
  isEdit = false;
  editId: string;

  stage: string;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private targetService: TargetService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private userStorageService: UserStorageService,
    private bonitificationService: BonificationService,
    private queueManager: QueueManagerService,
  ) {
    this.getDataFromRoute();
  }

  ngOnInit(): void {
    this.stage = window.location.pathname.split('/')[5];
    this.wizzardItems = [
      {
        items: {
          label: this.t('agriness.settings.targets.tabs.step_1.title'),
          command: () => {
            this.activeIndex = 0;
          },
        },
        nextButtonLable: 'agriness.settings.targets.tabs.step_1.next_button',
      },
      {
        items: {
          label: this.t('agriness.settings.targets.tabs.step_2.title'),
          command: () => {
            this.activeIndex = 1;
          },
        },
        nextButtonLable: 'agriness.settings.targets.tabs.step_2.next_button',
      },
      {
        items: {
          label: this.t('agriness.settings.targets.tabs.step_3.title'),
          command: () => {
            this.activeIndex = 2;
          },
        },
        saveButtonLable: 'agriness.settings.targets.tabs.step_3.save_button',
      },
    ];
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  getDataFromRoute(): void {
    if (this.router.getCurrentNavigation().extras.state) {
      const routerData = this.router.getCurrentNavigation().extras.state as TargetV2;
      this.isEdit = true;
      this.editId = routerData.id;

      const period_type = Object.values(PeriodTypeEnum).find(
        val => this.t('agriness.targets.period_type.' + val) == routerData.period_type,
      );
      if (!this.data.getValue()) {
        this.data = new BehaviorSubject({
          id: routerData.id,
          stage: routerData.stage.name,
          target_index: routerData.target_index,
          target_group: routerData.target_index.group,
          period_type,
          target_detail: routerData.target_detail,
        });
      }
    }
  }

  setValid(event: boolean, index: number): void {
    this.stepValid.next(this.canNext(event, index));
  }

  canNext(event: boolean, step: number): boolean {
    if (this.activeIndex === step) {
      return event;
    }
    return false;
  }

  setActiveIndex(index: number): void {
    this.activeIndex = index;
  }

  saveForm(): void {
    let data: TargetForm;

    this.bonitificationService.getStageId(this.stage).subscribe(res => {
      this.data.subscribe(target => {
        data = {
          id: target.id,
          name: 'target',
          holding_id: this.userStorageService.getCurrentHolding().id,
          stage: res.results[0].id,
          target_index: target.target_index.id,
          period_type: target.period_type,
          target_group: target.target_group,
          target_detail: target.target_detail,
          enabled: true,
        };
      });

      if (this.isEdit) {
        this.editTarget(data);
      } else {
        this.saveTarget(data);
      }
    });
  }

  saveTarget(data: TargetForm): void {
    this.addOperationToQueue(this.targetService.save(data));
  }

  editTarget(data: TargetForm): void {
    this.addOperationToQueue(this.targetService.updateV2(data, data.id));
  }

  addOperationToQueue(targetObservable: Observable<Target>): void {
    this.queueManager
      .add(targetObservable)
      .pipe(
        switchMap(({ payload: target, actions }: QueueItem) => {
          return (target as Observable<Target>).pipe(
            tap(() => {
              void this.router.navigate([
                environment.typeProduction,
                'settings',
                'targets',
                'target',
                this.stage,
              ]);
              actions.success(this.t(`${this.translationContext}.editor.success.message`));
            }),
            catchError(err => {
              actions.error(this.t(`${this.translationContext}.editor.failed.message`));
              return throwError(err);
            }),
          );
        }),
        catchError(err => {
          return throwError(err);
        }),
      )
      .subscribe();
  }

  setData(event: MainForm): void {
    const hasData = this.data.getValue();

    if (hasData) {
      this.data.next(event);
    } else {
      this.data = new BehaviorSubject(event);
    }
  }

  next(): void {
    switch (this.activeIndex) {
      case 1:
        this.main.setFormData();
        break;
      case 2:
        this.config.setFormData();
        break;
      default:
        break;
    }
  }
}
