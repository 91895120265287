<ag-feedback [type]="typeFeedback"> </ag-feedback>

<div *ngIf="!typeFeedback">
  <ng-container *ngTemplateOutlet="temperature"></ng-container>
  <ng-container *ngTemplateOutlet="humidity"></ng-container>
  <ng-container *ngTemplateOutlet="waterConsumption"></ng-container>
  <ng-container *ngTemplateOutlet="waterTemperature"></ng-container>
</div>

<ng-template #humidity>
  <div class="tab-pane fade show active pb-4" id="nav-humidity">
    <div class="row">
      <div class="col">
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="wrapper d-flex justify-content-between">
              <div class="card-title">
                {{ 'agriness.comfort.avg_humidity' | translate }}

                <corp-export-button
                  *ngIf="!isEmpty(chartOptionsHumidityTime)"
                  [filename]="'agriness.comfort.avg_humidity' | translate"
                  [lazyInstance]="chartHumidity?.chart | lazy"
                >
                </corp-export-button>
              </div>
              <!-- /.card-title -->
              <!-- Radio Buttons -->
              <p-selectButton
                [options]="types"
                [(ngModel)]="this.filterHumidity.strategy"
                (onChange)="selectTypeHumidity($event)"
                class="float-right text-uppercase"
              ></p-selectButton>
            </div>

            <div class="row">
              <div class="col-4 has-divisor">
                <highcharts-chart
                  #chartHumidity
                  *ngIf="!isEmpty(chartOptionsHumidityTime); else elseHumidity"
                  [Highcharts]="Highcharts"
                  [options]="chartOptionsHumidityTime"
                  style="width: 100%; height: 400px; display: block;"
                ></highcharts-chart>
              </div>

              <div class="col-8">
                <corp-report-section [isCard]="false">
                  <corp-report-section-line-graph #content [data]="chartOptionsHumidity">
                  </corp-report-section-line-graph>
                </corp-report-section>
              </div>
            </div>
            <!-- Chart -->

            <ng-template #elseHumidity>
              <ag-feedback
                *ngIf="!chartOptionsHumidity"
                [type]="FeedbackEnum.LOADING"
                [position]="FeedbackPosition.CENTER_PARENT"
              ></ag-feedback>
              <ag-feedback
                *ngIf="chartOptionsHumidity"
                [type]="FeedbackEnum.NOT_RESULT"
                [position]="FeedbackPosition.CENTER_PARENT"
              ></ag-feedback>
            </ng-template>
          </div>
        </div>
        <!-- /.card -->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #temperature>
  <div class="tab-pane fade show active pb-4" id="nav-temperature">
    <!-- Graph Temperature -->
    <div class="row">
      <div class="col">
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="wrapper d-flex justify-content-between">
              <div class="card-title">
                {{ 'agriness.comfort.avg_temperature' | translate }}

                <corp-export-button
                  *ngIf="!isEmpty(chartOptionsTemperatureTime)"
                  [filename]="'agriness.comfort.avg_temperature' | translate"
                  [lazyInstance]="chartTemperature?.chart | lazy"
                >
                </corp-export-button>
              </div>
              <!-- /.card-title -->

              <!-- Radio Buttons -->
              <p-selectButton
                [options]="types"
                [(ngModel)]="this.filterTemperature.strategy"
                (onChange)="selectTypeTemperature($event)"
                class="float-right text-uppercase"
              ></p-selectButton>
            </div>

            <!-- Chart -->
            <div class="row">
              <div class="col-4 has-divisor">
                <highcharts-chart
                  #chartTemperature
                  *ngIf="!isEmpty(chartOptionsTemperatureTime); else elseTemperature"
                  [Highcharts]="Highcharts"
                  [options]="chartOptionsTemperatureTime"
                  style="width: 100%; height: 400px; display: block;"
                ></highcharts-chart>
              </div>

              <div class="col-8">
                <corp-report-section [isCard]="false">
                  <corp-report-section-line-graph
                    #lgTemperature
                    #content
                    [data]="chartOptionsTemperature"
                  >
                  </corp-report-section-line-graph>
                </corp-report-section>
              </div>
            </div>

            <ng-template #elseTemperature>
              <ag-feedback
                *ngIf="!chartOptionsTemperature"
                [type]="FeedbackEnum.LOADING"
                [position]="FeedbackPosition.CENTER_PARENT"
              ></ag-feedback>
              <ag-feedback
                *ngIf="chartOptionsTemperature"
                [type]="FeedbackEnum.NOT_RESULT"
                [position]="FeedbackPosition.CENTER_PARENT"
              ></ag-feedback>
            </ng-template>
          </div>
        </div>
        <!-- /.card -->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #waterConsumption>
  <div class="tab-pane fade show active pb-4" id="nav-temperature">
    <!-- Graph water consumption -->
    <div class="row">
      <div class="col">
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="wrapper d-flex justify-content-between">
              <!-- /.card-title -->
              <div class="card-title">
                {{ 'agriness.comfort.title_water_consumption' | translate }}

                <corp-export-button
                  *ngIf="!isEmpty(chartOptionsTemperatureTime)"
                  [filename]="'agriness.comfort.title_water_consumption' | translate"
                  [lazyInstance]="chartWaterConsumption?.chart | lazy"
                >
                </corp-export-button>
              </div>

              <!-- Radio Buttons -->
              <p-selectButton
                [options]="types"
                [(ngModel)]="this.filterWaterConsumption.strategy"
                (onChange)="selectTypeWaterConsumption($event)"
                class="float-right text-uppercase"
              ></p-selectButton>
            </div>

            <div class="col">
              <corp-report-section [isCard]="false">
                <corp-report-section-line-graph
                  #chartWaterConsumption
                  #content
                  [data]="chartOptionsWaterConsumption"
                >
                </corp-report-section-line-graph>
              </corp-report-section>
            </div>

            <ng-template #elseWaterConsumption>
              <ag-feedback
                *ngIf="!chartOptionsWaterConsumption"
                [type]="FeedbackEnum.LOADING"
                [position]="FeedbackPosition.CENTER_PARENT"
              ></ag-feedback>
              <ag-feedback
                *ngIf="chartOptionsWaterConsumption"
                [type]="FeedbackEnum.NOT_RESULT"
                [position]="FeedbackPosition.CENTER_PARENT"
              ></ag-feedback>
            </ng-template>
          </div>
        </div>
        <!-- /.card -->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #waterTemperature>
  <div class="tab-pane fade show active pb-4" id="nav-humidity">
    <div class="row">
      <div class="col">
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="wrapper d-flex justify-content-between">
              <div class="card-title">
                {{ 'agriness.comfort.title_water_temperature' | translate }}

                <corp-export-button
                  *ngIf="!isEmpty(chartOptionsWaterTemperatureTime)"
                  [filename]="'agriness.comfort.title_water_temperature' | translate"
                  [lazyInstance]="chartWaterTemperature?.chart | lazy"
                >
                </corp-export-button>
              </div>

              <p-selectButton
                [options]="types"
                [(ngModel)]="this.filterWaterTemperature.strategy"
                (onChange)="selectTypeWaterTemperature($event)"
                class="float-right text-uppercase"
              ></p-selectButton>
            </div>

            <div class="row">
              <div class="col-4 has-divisor">
                <highcharts-chart
                  #chartWaterTemperature
                  *ngIf="!isEmpty(chartOptionsWaterTemperatureTime); else elseWaterTemperature"
                  [Highcharts]="Highcharts"
                  [options]="chartOptionsWaterTemperatureTime"
                  style="width: 100%; height: 400px; display: block;"
                ></highcharts-chart>
              </div>

              <div class="col-8">
                <corp-report-section [isCard]="false">
                  <corp-report-section-line-graph #content [data]="chartOptionsWaterTemperature">
                  </corp-report-section-line-graph>
                </corp-report-section>
              </div>
            </div>

            <ng-template #elseWaterTemperature>
              <ag-feedback
                *ngIf="!chartOptionsWaterTemperature"
                [type]="FeedbackEnum.LOADING"
                [position]="FeedbackPosition.CENTER_PARENT"
              ></ag-feedback>
              <ag-feedback
                *ngIf="chartOptionsHumidity"
                [type]="FeedbackEnum.NOT_RESULT"
                [position]="FeedbackPosition.CENTER_PARENT"
              ></ag-feedback>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
