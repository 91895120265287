import { KPIList, KPIListService } from '@agriness/corp-app/services';
import { SelectLoader } from '@agriness/corp-app/shared/component/corp-filters/corp-filter.service';
import { CorpFiltersContainerService } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.service';
import {
  SiteSystemType,
  StageEnum,
  TranslateInstant,
  TRANSLATE_INSTANT,
  TypeProductionService,
  UserStorageService,
} from '@agriness/services';
import { AgMultiselectComponent } from '@agriness/ui';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Indexes, MainForm, TypeIndex, TypeWeight } from '../rule-editor-form.model';

export interface StageSelect {
  name: string;
  key: StageEnum | string;
  selected?: boolean;
}

export interface WeightSelect {
  name: string;
  key: string;
}

export interface IndexesSelect {
  name: string;
  key: string;
}

@Component({
  selector: 'app-step-main',
  templateUrl: './step-main.component.html',
  styleUrls: ['./step-main.component.scss'],
  providers: [CorpFiltersContainerService.provider],
})
export class StepMainComponent implements OnInit {
  @ViewChild(AgMultiselectComponent) multiselect: AgMultiselectComponent;
  @Output() sendData = new EventEmitter<MainForm>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() isInvalid = new EventEmitter<boolean>();
  @Input() formData: BehaviorSubject<MainForm> = null;

  formDataSubcribed: MainForm = {
    holding_id: null,
    indexes: [],
    name: null,
    stage: null,
    type_weight: null,
    enabled: null,
  };

  SelectedIndexes: string[] = [];

  form: FormGroup;
  KPIform: FormGroup;

  data: MainForm = null;

  errorMessages = {
    name: '',
  };

  stageOptions: SelectLoader<unknown> = new SelectLoader<unknown>(null);
  weightOptions: SelectLoader<unknown> = new SelectLoader<unknown>(null);
  kpiOption: KPIList[];
  kpiMultiselectData: SelectLoader<KPIList> = new SelectLoader(null);
  KPIFilterData: SelectLoader<KPIList> = new SelectLoader(null);
  stageSelectData: BehaviorSubject<StageSelect[]>;
  stageWeightData: BehaviorSubject<WeightSelect[]>;
  protected loadSubscription = Subscription.EMPTY;

  constructor(
    private formBuilder: FormBuilder,
    private service: KPIListService,
    private typeProduction: TypeProductionService,
    private translateService: TranslateService,
    private userStorageService: UserStorageService,
    private cdr: ChangeDetectorRef,
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
  ) {}

  ngOnInit(): void {
    this.setStageOptions();
    this.setWeightOptions();

    this.formData.subscribe(res => {
      this.formDataSubcribed = res;
    });

    this.KPIform = this.formBuilder.group({
      kpiList: [null],
    });

    this.form = this.formBuilder.group(
      {
        name: [null, Validators.required.bind(this)],
        stage: [null],
        indexes: [this.KPIform.controls.kpiList.value],
        type_weight: [null],
      },
      { updateOn: 'blur' },
    );

    if (this.formDataSubcribed !== null) {
      this.form.controls.name.setValue(this.formDataSubcribed.name);
      this.form.controls.stage.setValue(this.formDataSubcribed.stage);
      this.fillKPIS({ key: this.formDataSubcribed.stage });
      this.form.controls.type_weight.setValue(this.formDataSubcribed.type_weight);
    }

    this.nameValidator();

    this.form.valueChanges.subscribe(() => {
      this.validate();
    });
  }
  validate(): void {
    if (this.isFormValid() && this.form.valid) {
      this.isValid.emit(true);
    } else {
      this.isValid.emit(false);
    }
  }

  nameValidator(): void {
    for (const control in this.form.controls) {
      this.form.controls[control].valueChanges.subscribe(() => {
        const { errors } = this.form.get(control);
        this.errorMessages[control] = errors ? this.t('agriness.settings.validation_required') : '';
      });
    }
  }

  isFormValid(): boolean {
    const data: MainForm = this.form.getRawValue() as MainForm;

    const check: boolean[] = [];

    Object.keys(data).forEach(key => {
      if (key === 'indexes' && data.indexes.length > 0) {
        check.push(true);
      } else {
        if (data[key] !== null && key !== 'indexes') {
          check.push(true);
        } else {
          check.push(false);
        }
      }
    });

    return !check.includes(false);
  }

  setStageOptions(): void {
    const data: StageSelect[] = [];
    const type_production = SiteSystemType[environment.typeProduction];

    Object.values(type_production).forEach(val => {
      if (val !== StageEnum.REPRODUCTIVE) {
        data.push({ name: this.translateService.instant('agriness.' + val) as string, key: val });
      }
    });

    this.stageSelectData = new BehaviorSubject(data);
  }

  fillKPIS(stage: { key: string }): void {
    this.kpiMultiselectData = new SelectLoader(this.service, {
      stage: stage.key,
    });

    this.kpiMultiselectData.loadInitialValues(['kpi_list']).pipe(first()).subscribe();
    this.loadSubscription = this.kpiMultiselectData
      .loadOnFilter()
      .pipe(
        map(res => {
          const data = cloneDeep(res);

          if (this.formDataSubcribed?.indexes.find(a => a.type === TypeIndex.MANUAL)) {
            this.formDataSubcribed.indexes.forEach(kpi => {
              if (kpi.type === TypeIndex.MANUAL) {
                data.results.unshift({
                  id: kpi.index_available,
                  key: kpi.name,
                  name: kpi.name,
                  stage: this.formDataSubcribed.stage,
                });
              }
            });
          }

          if (this.formDataSubcribed !== null) {
            this.SelectedIndexes = [];
            this.formDataSubcribed.indexes.forEach(item => {
              this.SelectedIndexes.unshift(item.name);
            });

            data.results.map(item => {
              if (this.formDataSubcribed.indexes.find(k => k.name === item.name)) {
                return { ...item, selected: true };
              }
            });
          }

          if (data.count >= 1) {
            this.kpiMultiselectData.options.next(data.results);
          }
          return data;
        }),
      )
      .subscribe();
  }

  setWeightOptions(): void {
    const data: WeightSelect[] = [];
    const type_weight = TypeWeight;

    Object.values(type_weight).forEach(val => {
      data.push({
        name: this.translateService.instant(
          'agriness.payment-rule-configuration.main.total_weight_options.' + val,
        ) as string,
        key: val,
      });
    });

    this.stageWeightData = new BehaviorSubject(data);
  }

  setFormData(): void {
    const data: MainForm = this.form.getRawValue() as MainForm;

    this.data = {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
      name: data.name,
      stage: data.stage,
      type_weight: data.type_weight,
      indexes: this.indexesVerification(
        this.form.controls['indexes'].value as string[],
        data.indexes,
      ),
      enabled: data.enabled,
    };

    this.formDataSubcribed = this.data;

    this.sendData.emit(this.data);
  }

  indexesVerification(form: string[], request: Indexes[]): Indexes[] {
    const mergedIndexes: Indexes[] = [];

    request.forEach(item => {
      if (item.type === TypeIndex.MANUAL && form.includes(item.name)) {
        mergedIndexes.push(item);
      }
    });

    const unrefinedIndexes: string[] = [];

    form.forEach(kpi => {
      if (!mergedIndexes.find(a => a.name === kpi)) {
        unrefinedIndexes.push(kpi);
      }
    });

    void this.formatKpis(unrefinedIndexes).then(res => {
      res.forEach(item => {
        mergedIndexes.push(item);
      });
    });

    return mergedIndexes;
  }

  formatKpis(items: string[]): Promise<Indexes[]> {
    return new Promise(resolve => {
      const data: MainForm = this.form.getRawValue() as MainForm;
      const indexesSubscribed: Indexes[] = this.formDataSubcribed?.indexes;
      const formated: Indexes[] = [];

      this.KPIFilterData = new SelectLoader(this.service, {
        stage: data.stage,
      });

      this.KPIFilterData.loadInitialValues(['kpi_list']).pipe(first()).subscribe();
      this.KPIFilterData.loadOnFilter()
        .pipe()
        .subscribe(dt => {
          items.forEach(item => {
            const index: Indexes = indexesSubscribed?.find(index => index.name == item);
            if (dt.results.find((kpi: KPIList) => kpi.name === item)) {
              const kpi: KPIList = dt.results.find((kpi: KPIList) => kpi.name === item);
              formated.push({
                type: TypeIndex.AVAILABLE,
                name: kpi.name,
                index_available: kpi.id,
                ranges: index?.ranges ?? null,
              });
            } else {
              formated.push({
                type: TypeIndex.MANUAL,
                name: item,
                index_available: null,
                ranges: index?.ranges ?? null,
              });
            }
          });

          resolve(formated);
        });
    });
  }
}
