import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MenuItem } from 'primeng/api';

import { CorpWizzard } from './corp-wizzard.model';

@Component({
  selector: 'app-corp-wizzard',
  templateUrl: './corp-wizzard.component.html',
  styleUrls: ['./corp-wizzard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CorpWizzardComponent implements OnInit, OnChanges {
  @Input() items: CorpWizzard[];
  @Input() isClickable = false;
  @Input() isComplete = false;
  @Input() canNext = false;

  @Output() emitChangeIndex = new EventEmitter<number>();
  @Output() saveForm = new EventEmitter<void>();
  @Output() emitNext = new EventEmitter<void>();
  @Output() emitBack = new EventEmitter<void>();

  nextEnabled = false;
  nextStepEnable = false;

  steps: MenuItem[] = [];
  titles: string[] = [];

  index = 0;

  ngOnInit(): void {
    this.items.forEach((res: CorpWizzard) => {
      this.steps.push(res.items);
    });
    this.emitChangeIndex.emit(this.index);
  }

  ngOnChanges(): void {
    if (this.canNext) {
      this.nextStepEnable = true;
    } else {
      this.nextStepEnable = false;
    }
  }

  previous(): void {
    this.index = this.index - 1;
    this.emitChangeIndex.emit(this.index);
    this.emitBack.emit();
  }

  next(): void {
    this.index = this.index + 1;
    this.emitChangeIndex.emit(this.index);
    this.emitNext.emit();
  }

  save(): void {
    this.saveForm.emit();
  }
}
