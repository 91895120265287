<form [formGroup]="form" [class]="isHorizontal ? 'horizontal' : ''">
  <container class="d-flex align-items-center" *ngFor="let option of options">
    <input
      *ngIf="!option.disabled"
      class="radio-button"
      type="radio"
      [formControlName]="controlName"
      [id]="option.value"
      [value]="option.value"
      (change)="optionSelected.emit(option)"
    />
    <div class="radio-button-disabled" *ngIf="option.disabled">
      <div class="line"></div>
    </div>
    <label [for]="option.value" class="radio-label mr-3" [class.disabled]="option.disabled">
      {{ option.label }}
    </label>
    <br />
  </container>
</form>
