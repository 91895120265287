import { ElementRef } from '@angular/core';
import * as _ from 'lodash';

/**
 * Utility to avoid a page scroll when the page size changes
 */
export class ParentScrollPin {
  parent: HTMLElement;
  scrollPlaceholder: HTMLElement;
  minHeight: number;
  pinned: boolean;
  removeScrollListener = _.noop;

  constructor(private elementRef: ElementRef<HTMLElement>) {
    this.minHeight = 0;
  }

  private getParent() {
    // based on https://gist.github.com/twxia/bb20843c495a49644be6ea3804c0d775#gistcomment-2899112
    const regexScrollParent = /^(visible|hidden)/;

    if (typeof window.getComputedStyle !== 'function') {
      return document.body;
    }

    let current = this.elementRef.nativeElement;

    while (
      current !== document.body &&
      (current.scrollHeight === current.clientHeight ||
        regexScrollParent.test(window.getComputedStyle(current).overflowY || 'visible'))
    ) {
      current = current.parentElement;
    }
    // @ts-ignore
    current.style.overflowAnchor = 'none';
    return current;
  }

  private getScrollPlaceholder() {
    const placeholder = document.createElement('div');
    placeholder.style.cssFloat = 'left';
    placeholder.className = 'parent-scroll-pin-placeholder';
    this.parent.appendChild(placeholder);
    this.parent.insertAdjacentElement('afterbegin', placeholder);
    return placeholder;
  }

  private setup() {
    if (!this.parent) {
      this.parent = this.getParent();
    }

    if (!this.scrollPlaceholder) {
      this.scrollPlaceholder = this.getScrollPlaceholder();
    }

    this.removeScrollListener();

    const listener = _.debounce(() => this.updatePlaceholder(), 150);
    this.parent.addEventListener('scroll', listener);
    this.removeScrollListener = () => this.parent.removeEventListener('scroll', listener);
  }

  pin() {
    this.pinned = true;
    this.setup();
    this.updatePin();
  }

  updatePin() {
    this.updatePlaceholder();
  }

  unpin() {
    this.pinned = false;
    this.updatePlaceholder();
  }

  private updatePlaceholder() {
    if (!this.scrollPlaceholder) {
      return;
    }
    const scrollBottom = this.parent.scrollTop + this.parent.clientHeight;
    this.setMinHeight(scrollBottom);

    if (!this.pinned) {
      setTimeout(() => {
        if (this.parent != null && this.parent.scrollHeight > this.minHeight) {
          this.destroy();
        }
      });
    }
  }

  private setMinHeight(height: number) {
    this.minHeight = height;
    this.scrollPlaceholder.style.height = `${height}px`;
  }

  destroy() {
    if (this.scrollPlaceholder) {
      this.removeScrollListener();
      this.removeScrollListener = _.noop;
      this.minHeight = 0;
      this.parent = null;
      this.scrollPlaceholder.remove();
      this.scrollPlaceholder = null;
    }
  }
}
