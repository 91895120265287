import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { BulkActionsItem, SERVICE_BULK } from './ag-bulk-actions.model';

@Component({
  templateUrl: './ag-bulk-actions.component.html',
  styleUrls: ['./ag-bulk-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgBulkActionsComponent implements OnInit, OnDestroy {
  constructor(@Inject(SERVICE_BULK) public serviceData: any, private ngZone: NgZone) {}

  items: BulkActionsItem[] = [];
  itemsSubscription: Subscription;
  chatWidth = '0px';

  ngOnInit() {
    this.itemsSubscription = this.serviceData.item
      .pipe(
        tap((item: BulkActionsItem) => (this.items = [item])),
        filter((it: BulkActionsItem) => it.quantity === 0),
        tap(() => (this.items = [])),
      )
      .subscribe();

    this.serviceData.positionRight
      .pipe(tap((value: string) => this.ngZone.run(() => (this.chatWidth = value))))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.itemsSubscription.unsubscribe();
  }

  changeWidthSize(chatWidth) {
    return `calc(100vw - 208px - ${chatWidth})`;
  }
}
