import { TypeProductionService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { generalTabs } from './production/production.model';

@Injectable({ providedIn: 'root' })
export class ProductionParamsGuard implements CanActivate {
  constructor(private router: Router, private typeProductionService: TypeProductionService) {}

  canActivate(): UrlTree {
    const routerlink = generalTabs[this.typeProductionService.get()][0].routerLink;
    return this.router.parseUrl(
      `/${this.typeProductionService.get()}/settings/production-params/production/${routerlink}`,
    );
  }
}
