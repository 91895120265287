import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ag-popover',
  templateUrl: './ag-popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgPopoverComponent {
  @ViewChild('bPopover', { static: true }) bPopover: NgbPopover;
  @Input() id: string;
  @Input() title: string;
  @Input() template: any;
  @Input() placement = 'right';
  @Input() styleClass = 'btn-link';
  @Input() autoClose: string;
  @Input() iconClass: string;
  @Input() buttonText: string;
  // tslint:disable-next-line:no-output-on-prefix no-output-rename
  @Output('open') onOpen = new EventEmitter<void>();
  // tslint:disable-next-line:no-output-on-prefix no-output-rename
  @Output('close') onClose = new EventEmitter<void>();

  open(): void {
    this.bPopover.open();
  }

  close(): void {
    this.bPopover.close();
  }

  emitOpen(): void {
    this.onOpen.emit();
  }

  emitClose(): void {
    this.onClose.emit();
  }
}
