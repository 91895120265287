import { Chart } from 'highcharts';

export enum ExportType {
  PDF = 'application/pdf',
  JPG = 'image/jpeg',
  PNG = 'image/png',
  SVG = 'image/svg+xml',
}

export type HighchartsChart = {
  chart: Chart;
};
