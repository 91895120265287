<div class="wrapper mb-4" [hidden]="!hasData() && !feedbackType" [attr.data-testid]="report">
  <div class="section-header d-flex align-items-center">
    <span class="mr-2" [innerHTML]="sectionName | translate"></span>

    <corp-preference-toggle-column
      #preferences
      buttonIconClass="icon-cards-settings"
      [labelProvider]="preferenceLabelProvider"
      [preferenceType]="report"
      [stage]="stage"
      (changes)="this.onPreferenceChange()"
    ></corp-preference-toggle-column>

    <div class="tip-right mt-2" *ngIf="hasComplexCard()">
      {{ helpText }}
    </div>
  </div>

  <ag-feedback [type]="feedbackType"></ag-feedback>

  <ng-container *ngIf="!feedbackType">
    <div *ngFor="let performance of performances">
      <div class="section-subheader" *ngIf="performance.name">
        {{ 'agriness.' + performance.name | translate }}
      </div>

      <div class="columns is-multiline is-variable is-1">
        <ng-container *ngFor="let card of performance.cards">
          <div
            *ngIf="isComplexCard(card); else simpleCard"
            class="column is-one-third-desktop is-one-half-tablet"
          >
            <ag-card-performance [data]="card" [link]="card.link"> </ag-card-performance>
          </div>
          <ng-template #simpleCard>
            <div
              class="column is-half-tablet is-one-third-desktop is-one-quarter-widescreen is-one-fifth-fullhd"
            >
              <ag-card [data]="card" [link]="card.link"></ag-card>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
