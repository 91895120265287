import {
  AnimalGroupAbstractService,
  AnimalGroupList,
  AnimalGroupPerformance,
  SearchFilterAnimalGroupList,
  TypeViewPreference,
  ViewPreferenceEnum,
} from '@agriness/corp-app/services';
import { DocumentType } from '@agriness/corp-app/services/models/document-type.model';
import { DownloadStatus } from '@agriness/corp-app/services/models/download-status.model';
import { LabelPrefixOrProvider } from '@agriness/corp-app/shared/services/view-preference.service';
import { AgrinessTranslateService } from '@agriness/services';
import {
  DateService,
  TypeProductionEnum,
  UserStorageService,
  StageEnum,
  TypeProductionService,
} from '@agriness/services';
import { FeedbackEnum, QueueItem, QueueManagerService, TableColumn } from '@agriness/ui';
import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { kebabCase } from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, throwError } from 'rxjs';
import { map, tap, catchError, switchMap } from 'rxjs/operators';

import {
  FrozenColumn,
  frozenColumnByProduction,
  columnsWithTooltipByProduction,
  orderDefault,
  IsOpenEnum,
} from '../../../shared/component/corp-animal-group-list/corp-animal-group-list.model';
import { CorpPreferenceToggleColumnComponent } from '../../../shared/component/corp-preference-toggle-column/corp-preference-toggle-column.component';
import { CorpReportFiltersComponent } from '../../../shared/component/corp-report-filters/corp-report-filters.component';
import { LoaderUserPreference } from '../../../shared/component/loader-user-preference';
import { ReportFilterType } from '../../../shared/model/report-filter.model';
import { RowData, RowObject } from '../../../shared/model/table.model';
import { TableService } from '../../../shared/services/table.service';
import { configTableAnimalGroup, indexesIgnoredOpenAnimalGroup } from './record-list.model';

@Component({
  templateUrl: './record-list.component.html',
})
export class RecordListComponent extends LoaderUserPreference implements OnInit, AfterViewInit {
  @ViewChild('reportFilter', { static: true })
  reportFilter: CorpReportFiltersComponent;
  @ViewChild('preferences', { static: true })
  preferences: CorpPreferenceToggleColumnComponent;
  @ViewChild('dt', { static: true }) pTable: Table;

  FeedbackEnum = FeedbackEnum;
  pageTitle = 'agriness.animal_group_list.title_reproductive';
  sectionTitle = 'agriness.farms_selected';
  preferenceType = ViewPreferenceEnum.ANIMALGROUP_LIST;
  preferenceLabelProvider = this.getIndexName.bind(this) as LabelPrefixOrProvider;
  columnsWithTooltip: string[];
  frozenColumns: FrozenColumn[];
  stage: StageEnum;
  typeProduction: TypeProductionEnum;
  defaultTableWidth = '140px';
  downloadFilename: string;
  DocumentType = DocumentType;

  currentFilter: SearchFilterAnimalGroupList = {};
  error = false;
  loading = true;
  totalRecords: number;
  rows = 10;
  rowsPerPageOptions = [5, 10, 20, 40, 60, 100];
  allTableColumns: TableColumn[] = [];
  frozenTableColumns: TableColumn[] = [];
  scrollableTableColumns: TableColumn[] = [];
  hiddenColumns: string[];
  animalGroupList: RowData<AnimalGroupList>[];
  trackEvent = true;

  filterIds: ReportFilterType[] = ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'TECHNICIAN'];
  columnMappingOnOpenAnimalGroup: { [k: string]: string } = {
    average_date_output: 'prediction_date_output',
    final_date_output: 'prediction_date_output',
    mortality_rate_by_harvested: 'mortality_rate_total',
  };
  hiddenColumnsOpenAnimalGroup = [
    ...Object.values(this.columnMappingOnOpenAnimalGroup),
    ...Array.from(indexesIgnoredOpenAnimalGroup),
  ];
  hiddenColumnsClosedAnimalGroup = [
    ...Object.values(this.columnMappingOnOpenAnimalGroup),
    'balance',
  ];

  constructor(
    public tableService: TableService,
    protected userStorageService: UserStorageService,
    private animalGroupService: AnimalGroupAbstractService,
    private dateService: DateService,
    private queueManager: QueueManagerService,
    private route: ActivatedRoute,
    private translateService: AgrinessTranslateService,
    private typeProductionService: TypeProductionService,
  ) {
    super(userStorageService);
    this.typeProduction = this.typeProductionService.get();
    this.columnsWithTooltip = columnsWithTooltipByProduction[this.typeProduction];
    this.frozenColumns = frozenColumnByProduction[this.typeProduction].filter(
      column => column.stage === StageEnum.REPRODUCTIVE,
    );
  }

  ngOnInit(): void {
    this.stage = this.route.snapshot.data.stage as StageEnum;
  }

  ngAfterViewInit(): void {
    this.loadPreferences().subscribe(() => this.onPreferenceChange());
  }

  filter(): void {
    this.pTable.reset();
  }

  onPreferenceChange(): void {
    this.loadColumns();
    this.filter();
  }

  loadLazy(event: LazyLoadEvent): void {
    this.loading = true;
    this.rows = event ? event.rows : this.rows ? this.rows : 25;
    this.updateCurrentFilter(event);
    this.updateDownloadFilename();
    this.loadColumns();
    this.loadAnimalGroupList().subscribe();
  }

  getIndexName(indexKey: string): string {
    return this.translateService.instantWithFallback([
      'agriness.animal_group_list.' + indexKey,
      'agriness.performances.' + indexKey,
    ]);
  }

  loadPreferences(): Observable<unknown> {
    return this.preferences.load();
  }

  getPreferences(): TypeViewPreference[] {
    this.preferences.setHiddenItems(this.hiddenColumns);
    return this.preferences.getPreferences();
  }

  getCurrentFilter(): SearchFilterAnimalGroupList {
    return this.currentFilter;
  }

  updateCurrentFilter(event?: LazyLoadEvent): void {
    const page = (event ? event.first / event.rows : 0) + 1;
    this.currentFilter = this.reportFilter.getCurrentFilterInQueryFormat();
    const sortField = event.sortField
      ? event.sortOrder > 0
        ? event.sortField
        : '-' + event.sortField
      : this.getOrderDefault();
    this.currentFilter = {
      ...this.currentFilter,
      page: page,
      per_page: this.rows,
      order: sortField,
    };
  }

  isFrozenColumn(column: TableColumn): boolean {
    return this.frozenTableColumns?.includes(column) === true;
  }

  download(documentType: DocumentType): void {
    const indexKey = this.allTableColumns
      .map(({ field }) => field)
      .join(',')
      .toUpperCase();
    this.addDownloadToQueue(
      this.animalGroupService.downloadList({
        ...this.getCurrentFilter(),
        holding_id: this.holdingId,
        stage: this.stage,
        index_keys: indexKey,
        data_format: documentType,
      }),
    );
  }

  private addDownloadToQueue(downloadObservable: Observable<DownloadStatus>): void {
    const t = (key: string): string =>
      this.translateService.instantWithFallback(`agriness.download_feedback.${key}`);
    this.queueManager
      .add(downloadObservable, t('waiting'))
      .pipe(
        tap(({ actions }: QueueItem) => actions.update(t('in_progress'))),
        switchMap(({ payload: download, actions }: QueueItem) => {
          return (download as Observable<DownloadStatus>).pipe(
            tap((status: DownloadStatus) => {
              if (status === DownloadStatus.NO_CONTENT) {
                actions.error(t('no_content'));
              } else {
                actions.success(t('success'));
              }
            }),
            catchError(err => {
              actions.error(t('error'));
              return throwError(err);
            }),
          );
        }),
      )
      .subscribe();
  }

  private getOrderDefault(): string {
    const isOpen = (this.currentFilter.is_open || IsOpenEnum.ALL) as IsOpenEnum;
    return orderDefault[this.typeProduction][this.stage][isOpen];
  }

  private updateDownloadFilename() {
    this.downloadFilename = [
      `${this.translateService.instant(this.pageTitle)}`,
      this.dateService.formatAsIsoDate(this.currentFilter.begin_date),
      this.dateService.formatAsIsoDate(this.currentFilter.end_date),
    ].join(' - ');
  }

  private loadAnimalGroupList(): Observable<unknown> {
    return this.animalGroupService
      .getAnimalGroupList(this.holdingId, this.getCurrentFilter(), this.stage)
      .pipe(
        map(searchResult => {
          this.totalRecords = searchResult.count;
          return searchResult.results;
        }),
        map(data => this.parseAnimalGroupListToRowData(data)),
        tap(rowData => {
          this.animalGroupList = rowData;
          this.loading = false;
        }),
        catchError(err => {
          this.error = true;
          return throwError(err);
        }),
      );
  }

  private loadColumns() {
    this.hiddenColumns = this.currentFilter.is_open
      ? this.hiddenColumnsOpenAnimalGroup
      : this.hiddenColumnsClosedAnimalGroup;

    this.allTableColumns = this.getPreferences()
      .filter(p => this.isColumnsVisible(p))
      .map(preference => ({
        field: preference.index_name,
        header: this.getIndexName(preference.index_name),
        width:
          configTableAnimalGroup[preference.index_name.toLowerCase()] || this.defaultTableWidth,
        sortable: true,
        tooltip: this.getKeyTranslateTooltip(preference.index_name),
      }));

    const frozenColumnsNames = this.frozenColumns.map(column => column.name);
    this.frozenTableColumns = this.allTableColumns.filter(column =>
      frozenColumnsNames.includes(column.field.toLowerCase()),
    );

    this.scrollableTableColumns = this.allTableColumns.filter(
      item => !this.frozenTableColumns.includes(item),
    );
  }

  private isColumnsVisible(preference: TypeViewPreference) {
    return preference.enable && !this.hiddenColumns.includes(preference.index_name);
  }

  private getKeyTranslateTooltip(indexName: string) {
    indexName = indexName.toLowerCase();
    if (!this.columnsWithTooltip.includes(indexName)) {
      return null;
    }
    const translateKey = `agriness.animal_group_list.tooltip.${indexName}`;
    const translation = this.translateService.instant(translateKey);
    return translation !== translateKey ? translation : null;
  }

  private parseAnimalGroupListToRowData(data: AnimalGroupList[]): RowData<AnimalGroupList>[] {
    const result: RowData<AnimalGroupList>[] = [];
    for (const animalGroup of data) {
      const performanceByIndex = this.getPerformanceByIndex(animalGroup.performances);
      const obj: RowData<AnimalGroupList> = {};
      for (const column of this.allTableColumns) {
        obj[column.field] = this.getValueFromField(column.field, performanceByIndex);
      }
      obj.object = animalGroup;
      result.push(obj);
    }
    return result;
  }

  private getPerformanceByIndex(
    performances: AnimalGroupPerformance[],
  ): { [k: string]: AnimalGroupPerformance } {
    const result = {};
    for (const p of performances) {
      result[p.index_name.toLowerCase()] = p;
    }
    return result;
  }

  private getValueFromField(
    field: string,
    performanceByIndex: { [k: string]: AnimalGroupPerformance },
  ): RowObject {
    const isOpen = this.getCurrentFilter().is_open;

    field = field ? field.toLowerCase() : '';
    if (isOpen) {
      const mappedField = this.columnMappingOnOpenAnimalGroup[field];
      field = mappedField || field;
    }

    const performance = performanceByIndex[field];
    if (!performance) {
      return null;
    }
    return {
      id: kebabCase(field),
      value: performance.index_value,
      measurement_unit: performance.measurement_unit,
      decimal_places:
        kebabCase(field) === 'average-daily-weight-gain' && this.stage === StageEnum.REPRODUCTIVE
          ? 3
          : performance.decimal_places,
    };
  }
}
