import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { BackDirective } from './back.directive';
import { AgTooltipDirective } from './tooltip.directive';

@NgModule({
  imports: [NgbTooltipModule],
  declarations: [BackDirective, AgTooltipDirective],
  exports: [BackDirective, AgTooltipDirective],
})
export class DirectivesModule {}
