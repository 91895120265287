import { Producer, ProducerForm } from '@agriness/corp-app/services';
import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'producer-editor',
  templateUrl: './producer-editor.component.html',
  styleUrls: ['./producer-editor.component.scss'],
})
export class ProducerEditorComponent implements OnInit {
  @Input() visible = false;
  @Input() producerData: Producer;
  @Input() modalStatus = ModalStatusEnum.DEFAULT;

  @Output() closed = new EventEmitter<void>();
  @Output() createProducer = new EventEmitter<void>();
  @Output() updateProducer = new EventEmitter<void>();

  errorMessages = {
    name: '',
  };

  translationContext = 'agriness.settings.producers';

  producerForm = new FormGroup({
    name: new FormControl('', Validators.required.bind(this)),
  });

  constructor(@Inject(TRANSLATE_INSTANT) private t: TranslateInstant) {}

  ngOnInit(): void {
    this.updatedFormProducer();
    this.listenValueChanges();
  }

  listenValueChanges(): void {
    for (const control in this.producerForm.controls) {
      this.producerForm.controls[control].valueChanges.subscribe(() => {
        const { errors } = this.producerForm.get(control);
        this.errorMessages[control] = errors ? this.t('agriness.settings.validation_required') : '';
      });
    }
  }

  onSubmit(): void {
    if (!this.producerData) {
      this.createProducer.emit();
    } else {
      this.updateProducer.emit();
    }
  }

  onClosed(): void {
    this.closed.emit();
  }

  updatedFormProducer(): void {
    if (this.producerData) {
      const { name } = this.producerData;

      this.producerForm.patchValue({ name });
    }
  }

  getProducerForm(): ProducerForm {
    return this.producerForm.getRawValue() as ProducerForm;
  }
}
