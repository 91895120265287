<div class="d-flex flex-fill justify-content-center">
  <div class="feedback">
    <div class="content-wrapper" *ngIf="status != ModalStatusEnum.LOADING; else loading">
      <img
        [class]="feedback.iconClass"
        [attr.data-testid]="feedback.id"
        [attr.src]="'assets/media/img/' + feedback.icon"
      />
    </div>
    <ng-template #loading>
      <svg
        [class]="feedback.iconClass"
        [attr.data-testid]="feedback.id"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 56 56"
      >
        <circle
          cx="28"
          cy="28"
          r="18.385"
          stroke="#D4EEFE"
          stroke-width="4"
          transform="rotate(-45 28 28)"
        />
        <path
          class="spinner"
          stroke="#205FD4"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="4"
          d="M34.693 10.807c-9.432-3.76-20.126.84-23.886 10.271-3.76 9.432.84 20.126 10.271 23.886"
        />
        <path fill="#2A64E8" d="M29.189 20L22 37h4.811L34 20h-4.811z" />
      </svg>
    </ng-template>
  </div>
</div>
