import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';

import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LabsRoutingModule } from './labs-routing.module';
import { LabsComponent } from './labs.component';

@NgModule({
  imports: [
    SharedModule,
    HighchartsChartModule,
    DropdownModule,
    TableModule,
    ReactiveFormsModule,
    LabsRoutingModule,
  ],
  declarations: [LabsComponent, DashboardComponent],
  exports: [LabsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LabsModule {}
