<div *ngIf="filterForm" [hidden]="hidden" class="input-content">
  <div class="px-1 d-flex align-items-center">
    <span>{{ labelKey.ageFrom | translate }}</span>

    <ag-number-field
      class="mx-2"
      inputId="filter-{{ kebabCase(beginAge.controlName) }}"
      [controlName]="beginAge.controlName"
      [group]="filterForm"
    ></ag-number-field>

    <span>{{ labelKey.to | translate }}</span>

    <ag-number-field
      class="mx-2"
      inputId="filter-{{ kebabCase(endAge.controlName) }}"
      [controlName]="endAge.controlName"
      [group]="filterForm"
    ></ag-number-field>

    <span>{{ labelKey.weeks | translate }}</span>
  </div>
</div>
