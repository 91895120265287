import { AgrinessTranslateService } from '@agriness/services';
import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { ValueFormatPipe } from '../../../pipe/value-format/value-format.pipe';
import { CardLink, CardModel } from '../card.model';

@Component({
  selector: 'ag-card',
  templateUrl: './ag-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgCardComponent implements OnChanges {
  @Input() data: CardModel;
  @Input() link: CardLink;

  targetText: string;
  hasNavigation = false;

  constructor(
    private translateService: AgrinessTranslateService,
    private valueFormat: ValueFormatPipe,
    private analyticsService: AmplitudeAnalyticsService,
  ) {}

  ngOnChanges(): void {
    this.hasNavigation = Boolean(this.data.action && this.link);
    this.updateTargetText();
  }

  onEvent(): void {
    this.analyticsService.logKPIClick(this.data.id);
  }

  private updateTargetText() {
    this.targetText = this.getTargetText();
  }

  private getTargetText() {
    const representationType = this.data.target_representation_type;
    if (!representationType) {
      return null;
    }
    return this.translateService.instant(
      `agriness.target_representation_type.${representationType}`,
      {
        min_recommended: this.formatValue(this.data.target_min_recommended),
        max_recommended: this.formatValue(this.data.target_max_recommended),
        target: this.formatValue(this.data.target),
      },
    );
  }

  private formatValue(value): string {
    return this.valueFormat.transform(
      value,
      this.data.style || this.data.measurement_unit,
      this.data.decimal_places,
    );
  }
}
