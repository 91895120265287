import { ReportFilterQueryKeys } from '@agriness/corp-app/shared/model/report-filter.model';
import { maybeParseInt } from '@agriness/corp-app/utils/functions.util';
import { TranslateInstant, TRANSLATE_INSTANT, DateService } from '@agriness/services';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent } from '../corp-filter-abstractions';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

type NumberFieldProps = Partial<{
  controlName: string;
  queryParamName: string;
  initialValue: number | string | null;
}>;

@Component({
  selector: 'corp-age-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './corp-age-filter.component.html',
  providers: [{ provide: FilterComponent, useExisting: CorpAgeFilterComponent }],
})
export class CorpAgeFilterComponent extends FilterComponent {
  beginAge: NumberFieldProps = {
    controlName: 'beginAge',
    queryParamName: 'begin_age',
  };
  endAge: NumberFieldProps = {
    controlName: 'endAge',
    queryParamName: 'end_age',
  };
  labelKey = {
    ageFrom: 'agriness.filter.age.age_from',
    to: 'agriness.filter.age.to',
    weeks: 'agriness.filter.age.weeks',
  };

  constructor(
    @Inject(TRANSLATE_INSTANT) private translate: TranslateInstant,
    private dateService: DateService,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(containerService, activeRoute);
    this.assignDefaults();
  }

  async getInitialGroupData(): Promise<Record<string, [unknown]>> {
    const { beginAge, endAge } = this.getAgeFromQueryOrDefaults();

    return Promise.resolve({
      [this.beginAge.controlName]: [beginAge],
      [this.endAge.controlName]: [endAge],
    });
  }

  async getResetData(): Promise<Record<string, unknown>> {
    const { beginAge, endAge } = this.getDefaultAge();

    return Promise.resolve({
      [this.beginAge.controlName]: beginAge,
      [this.endAge.controlName]: endAge,
    });
  }

  getValuesInQueryFormat(): { [key in ReportFilterQueryKeys]?: number } {
    const begin = this.getFormValue(this.beginAge.controlName);
    const end = this.getFormValue(this.endAge.controlName);

    return {
      [this.beginAge.queryParamName]: begin,
      [this.endAge.queryParamName]: end,
    };
  }

  collectSubtitle(): [string, string] {
    return [
      this.translate('agriness.filter.age.label'),
      this.translate('agriness.filter.age.age_range', {
        begin_age: this.getFormValue<number>(this.beginAge.controlName),
        end_age: this.getFormValue<number>(this.endAge.controlName),
      }),
    ];
  }

  private assignDefaults() {
    const { beginAge, endAge } = this.getAgeFromQueryOrDefaults();

    this.beginAge.initialValue = beginAge;
    this.endAge.initialValue = endAge;
  }

  private getDefaultAge() {
    return { beginAge: 0, endAge: 0 };
  }

  private getAgeFromQueryOrDefaults() {
    const defaults = this.getDefaultAge();
    const beginAgeParam = maybeParseInt(this.getQueryParam(this.beginAge.queryParamName));
    const endAgeParam = maybeParseInt(this.getQueryParam(this.endAge.queryParamName));

    const beginAge = beginAgeParam || defaults.beginAge;
    const endAge = endAgeParam || defaults.endAge;

    return { beginAge, endAge };
  }
}
