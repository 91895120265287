import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { AgIndexesTableComponent } from './ag-indexes-table.component';

@NgModule({
  declarations: [AgIndexesTableComponent],
  exports: [AgIndexesTableComponent],
  imports: [SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgIndicesModule {}
