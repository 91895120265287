import { InjectionToken } from '@angular/core';
import { AsyncSubject } from 'rxjs';

export const SERVICE_BULK = new InjectionToken<{}>('SERVICE_BULK');

export interface BulkActionsButton {
  action: () => void;
  text: string;
  icon?: string;
}

export interface BulkActionsItem {
  quantity: number;
  total?: number;
  buttons?: BulkActionsButton[];
  clean?: () => void;
}
