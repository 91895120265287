<div class="status-card">
  <a
    *ngIf="header && header.route"
    [queryParams]="header.query"
    [routerLink]="header.route"
    class="status-card-header-link-content"
    target="target()"
  >
    <ag-status-header class="status-card-header" [header]="header"></ag-status-header>
    <span class="status-card-header-link-icon icon-open-new-tab-small"></span>
  </a>

  <div *ngIf="header && !header.route" class="status-card-header-content">
    <ag-status-header class="status-card-header" [header]="header"></ag-status-header>
  </div>

  <div *ngIf="items" class="status-card-body">
    <div class="horizontal-status-bar-content">
      <ag-horizontal-status-bar [items]="items"> </ag-horizontal-status-bar>
    </div>
    <ag-status-legend [items]="items"></ag-status-legend>
  </div>
</div>
