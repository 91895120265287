import { Observable } from 'rxjs';

import { SearchReportCategory } from '../models/report-category.model';
import { Report, ReportDashboard, ReportFilter } from '../models/report-dashboard.model';
import { ReportEnum } from '../models/user.model';

export abstract class ReportDashboardAbstractService {
  abstract getReports(filter: ReportFilter, stage: string): Observable<ReportDashboard>;

  abstract getReport(reportInternalName: ReportEnum, stageName: string): Observable<Report>;

  abstract getReportsCategories(idStage: string): Observable<SearchReportCategory>;
}
