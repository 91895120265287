import { DateService } from '@agriness/services';
import { DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';

import { SharedModule } from '../../common/shared.module';
import { AgCalendarComponent } from './ag-calendar.component';
import { AgCalendarService } from './ag-calendar.service';
import { AgWeekCalendarComponent } from './week/ag-week-calendar.component';

@NgModule({
  imports: [CalendarModule, SharedModule, ReactiveFormsModule, FormsModule],
  declarations: [AgCalendarComponent, AgWeekCalendarComponent],
  exports: [AgCalendarComponent, AgWeekCalendarComponent, CalendarModule],
  providers: [AgCalendarService, DateService, DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgCalendarModule {}
