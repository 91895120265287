import { DisplayOption } from '@agriness/corp-app/record/animal-group/performance/performance.model';
import { AnalysisInterval } from '@agriness/corp-app/services/models/performance-analysis.model';
import { PerformanceAnalysisTabReport } from '@agriness/corp-app/shared/component/corp-performance-analysis/corp-performance-analysis.model';
import {
  GraphSeriesType,
  DefaultTickIntervals,
} from '@agriness/corp-app/shared/component/corp-performance-graph/corp-performance-graph.model';
import { ReportFilterType } from '@agriness/corp-app/shared/model/report-filter.model';
import { Component } from '@angular/core';

import { tabs } from './performance-analysis.model';

@Component({
  templateUrl: './performance-analysis.component.html',
})
export class PerformanceAnalysisComponent {
  pageTitle = 'agriness.performance_analysis.title';
  filterIds: ReportFilterType[] = [];
  reportTabs: PerformanceAnalysisTabReport[] = tabs;
  byDateTicksInterval = DefaultTickIntervals.ONE_MONTH;
  analysisInterval: AnalysisInterval = 'month';

  getSeriesType(displayOption: DisplayOption): GraphSeriesType {
    if (displayOption === DisplayOption.BY_DATE) {
      return 'column';
    }

    return 'line';
  }
}
