<ag-popover
  #popover
  [template]="popoverTemplate"
  styleClass="btn btn-ghost has-round-hover"
  placement="bottom-left"
  [iconClass]="buttonIconClass"
  (open)="onOpen()"
  autoClose="outside"
>
</ag-popover>
<ng-template #popoverTemplate>
  <div
    class="toggle-column"
    [dndDropzone]="[]"
    [dndHorizontal]="true"
    (dndDrop)="onDrop($event, itemsUser)"
  >
    <div dndPlaceholderRef class="dndPlaceholder"></div>

    <div
      class="toggle-column-item"
      [ngClass]="{ disabled: item.readOnly, freezed: item.frozen }"
      *ngFor="let item of itemsUser"
      [attr.data-testid]="item?.id"
      [dndDraggable]="item"
      [dndDisableIf]="item.frozen"
      [dndEffectAllowed]="item.effectAllowed"
      (dndMoved)="onDragged(item, itemsUser, 'move')"
    >
      <span class="drag-marker icon icon-dragdrop"></span>
      <p-checkbox
        class="toggle-column-item-checkbox"
        [(ngModel)]="item.checked"
        [binary]="true"
        [label]="item.label | translate"
        [disabled]="item.readOnly"
      ></p-checkbox>
    </div>
  </div>

  <div class="popover-footer p-3 d-flex align-items-center justify-content-between">
    <button class="btn btn-modal" (click)="revertToggleColumn()" data-testid="revert">
      <span class="icon is-18 icon-restore"></span>
    </button>

    <button
      type="button"
      class="btn btn-primary is-small"
      (click)="saveToggleColumn()"
      data-testid="save"
    >
      {{ 'agriness.save' | translate }}
    </button>
  </div>
</ng-template>
