import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { Observable } from 'rxjs';

import { FeatureToggleService } from '../shared/services/feature-toggle.service';

const featureName = 'TECHNICAL_SUPPORT';

@Injectable({
  providedIn: 'root',
})
export class PresenceGuard implements CanLoad {
  constructor(private featureToggleService: FeatureToggleService) {}
  canLoad(): boolean | Observable<boolean> | Promise<boolean> {
    return this.featureToggleService.hasFeature(featureName);
  }
}
