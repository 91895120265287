import {
  ExportButtonClass,
  ExportHandler,
} from '@agriness/corp-app/shared/component/corp-export-button/corp-export-button.model';
import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'highcharts';

import { ExportType } from '../../model/highcharts.model';
import { GraphExporterService } from '../../services/graph-exporter.service';

@Component({
  selector: 'corp-export-button',
  templateUrl: './corp-export-button.component.html',
})
export class CorpExportButtonComponent implements OnInit {
  @Input() type = ExportType.JPG;
  @Input() filename: string;
  @Input() lazyInstance: () => Chart;

  handlers: ExportHandler;
  classes: ExportButtonClass;
  iconClass: string;

  constructor(private exporter: GraphExporterService) {}

  ngOnInit(): void {
    this.handlers = {
      [ExportType.JPG]: () => this.exporter.asJPG(this.filename, this.lazyInstance()),
      [ExportType.PNG]: () => this.exporter.asPNG(this.filename, this.lazyInstance()),
      [ExportType.SVG]: () => this.exporter.asSVG(this.filename, this.lazyInstance()),
      [ExportType.PDF]: () => this.exporter.asPDF(this.filename, this.lazyInstance()),
    };

    this.classes = {
      [ExportType.JPG]: 'icon-jpg',
      [ExportType.PNG]: 'icon-png',
      [ExportType.SVG]: 'icon-svg',
      [ExportType.PDF]: 'icon-pdf',
    };

    this.iconClass = this.classes[this.type];
  }

  export(): void {
    this.handlers[this.type]();
  }
}
