<section class="is-full">
  <corp-report-filters
    #reportFilter
    [availableFilters]="filterIds"
    [stage]="stage"
    [animalGroupStatus]="animalGroupStatus"
    (filter)="filter()"
    (ready)="loadData()"
  ></corp-report-filters>

  <ag-feedback [type]="typeFeedback"> </ag-feedback>

  <div [hidden]="typeFeedback != null">
    <ng-container [ngSwitch]="selectedTab">
      <ng-container *ngSwitchCase="'diagnostic-tree'">
        <div class="diagnostic-tree-wrapper" *ngFor="let report of closedSubReports">
          <corp-swine-diagnostic-tree
            #report
            [stage]="stage"
            [report]="report.internalName"
            [filter]="currentFilter"
            data-testid="diagnostic-tree"
          ></corp-swine-diagnostic-tree>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngFor="let report of closedSubReports">
          <corp-report-monitor-cards
            #report
            [sectionName]="report.name"
            [stage]="stage"
            [report]="report.internalName"
            [filter]="currentFilter"
          ></corp-report-monitor-cards>
        </ng-container>

        <ng-container *ngFor="let report of openSubReports">
          <corp-report-indexes-table-by-period
            #report
            [sectionName]="report.name"
            [stage]="stage"
            [report]="report.internalName"
            [filter]="currentFilter"
            [cellLink]="linkAnimalGroupList"
          ></corp-report-indexes-table-by-period>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</section>
