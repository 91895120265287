import { Component, Input } from '@angular/core';

import { EmbedReportTab } from './corp-embed-report-tab.model';

@Component({
  selector: 'corp-embed-report',
  templateUrl: './corp-embed-report.component.html',
})
export class CorpEmbedReportComponent {
  @Input()
  tabs: EmbedReportTab[] = [];

  @Input()
  pageTitle: string;

  @Input()
  hasHeader = false;
}
