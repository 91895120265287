import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

import { SharedModule } from '../shared/shared.module';
import { FinishingComponent } from './finishing.component';
import { FinishingIdentifyComponent } from './identify/finishing-identify.component';

@NgModule({
  imports: [ReactiveFormsModule, DropdownModule, SharedModule],
  declarations: [FinishingComponent, FinishingIdentifyComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FinishingModule {}
