import { Component, ViewEncapsulation, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription, Subject } from 'rxjs';

import { FeedbackEnum } from '../feedback/ag-feedback.model';
import { REDIRECT_DATA, RedirectItemData, RedirectItemFeedback } from './ag-redirect-modal.model';

@Component({
  templateUrl: './ag-redirect-modal.component.html',
  styleUrls: ['./ag-redirect-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgRedirectModalComponent implements OnInit, OnDestroy {
  constructor(@Inject(REDIRECT_DATA) public redirectData: Subject<RedirectItemData>) {}

  service: string;
  feedback: RedirectItemFeedback;
  url: string;
  visible: boolean;

  currentRedirectSubscription: Subscription;

  ngOnInit(): void {
    this.currentRedirectSubscription = this.redirectData.subscribe((data: RedirectItemData) => {
      if (data.url) {
        this.url = data.url;
        this.hideModal();
      } else if (data.feedback) {
        this.feedback = { icon: FeedbackEnum.ERROR, ...data.feedback };
      } else if (data.service) {
        this.service = data.service;
        this.visible = true;
      }
    });
  }

  resetModalData(): void {
    if (this.url) {
      window.open(this.url, '_newtab');
    }
    this.service = null;
    this.feedback = null;
    this.url = null;
  }

  hideModal(): void {
    this.visible = false;
  }

  ngOnDestroy(): void {
    this.currentRedirectSubscription.unsubscribe();
  }
}
