import { Overlay, OverlayContainer } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { AsyncSubject, BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { AgQueueManagerComponent } from './ag-queue-manager.component';
import {
  QueueActions,
  QueueItem,
  QueueManagerStatus,
  QueueManagerUiItem,
  SERVICE_DATA,
} from './ag-queue-manager.model';
import { ChatWidgetService } from './chat-widget.service';

@Injectable({
  providedIn: 'root',
})
export class QueueManagerService {
  private queue: QueueItem[] = [];
  private current: QueueItem = null;

  queueUpdates = new Subject<QueueManagerUiItem[]>();
  currentUpdates = new Subject<QueueManagerUiItem>();

  positionRight = new BehaviorSubject<string>('0px');

  constructor(
    private overlay: Overlay,
    private overlayContainer: OverlayContainer,
    private injector: Injector,
    private chatWidgetService: ChatWidgetService,
  ) {
    this.createOverlay();
    this.chatWidgetService
      .watch(this.overlayContainer.getContainerElement())
      .subscribe(value => this.positionRight.next(`${value}px`));
  }

  private createOverlay(): void {
    const positionStrategy = this.overlay
      .position()
      .global()
      .top('50px')
      .right(this.positionRight.value);

    this.positionRight.subscribe(value => positionStrategy.right(value).apply());

    const overlayRef = this.overlay.create({
      positionStrategy,
    });

    const QueueManagerPortal = new ComponentPortal(
      AgQueueManagerComponent,
      null,
      this.createInjector({
        queueUpdates: this.queueUpdates,
        currentUpdates: this.currentUpdates,
      }),
    );

    overlayRef.attach(QueueManagerPortal);
  }

  private createInjector(data): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(SERVICE_DATA, data);
    return new PortalInjector(this.injector, injectorTokens);
  }

  add(payload, message?: string) {
    const subject = new Subject();
    const queueSubject = new AsyncSubject();

    const initialData = message
      ? { label: message, status: QueueManagerStatus.WAITING }
      : { label: '', status: QueueManagerStatus.NO_STATUS };

    this.queue.push({
      payload,
      data: initialData,
      queueSubject,
      actions: this.actionsFactory(subject),
    });
    this.queueUpdates.next(this.queue.map(item => item.data));

    if (!this.current) {
      this.next();
    }

    subject
      .pipe(
        tap((data: QueueManagerUiItem) => {
          this.current.data = data;
          this.currentUpdates.next(data);
        }),
        catchError(data => {
          this.current.data = data;
          this.currentUpdates.next(data);
          return of(null);
        }),
        finalize(() => this.next()),
      )
      .subscribe();

    return queueSubject;
  }

  private next() {
    if (this.queue[0]) {
      this.current = this.queue.shift();
      this.current.queueSubject.next(this.current);
      this.current.queueSubject.complete();
      this.queueUpdates.next(this.queue.map(item => item.data));
      this.currentUpdates.next(this.current.data);
    } else {
      this.current = null;
      this.currentUpdates.next();
    }
  }

  private actionsFactory(subject: Subject<any>): QueueActions {
    return {
      success: label => {
        subject.next({
          label,
          status: QueueManagerStatus.SUCCESS,
        });
        subject.complete();
      },
      error: label =>
        subject.error({
          label,
          status: QueueManagerStatus.ERROR,
        }),
      update: label =>
        subject.next({
          label,
          status: QueueManagerStatus.IN_PROGRESS,
        }),
    };
  }
}
