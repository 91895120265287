import { Component, Input } from '@angular/core';

import { Colors, Icons, icons, colors } from '@agriness/assets';

@Component({
  selector: 'agriness-icon',
  templateUrl: './agriness-icon.component.html',
  styleUrls: ['./agriness-icon.component.scss'],
})
export class AgrinessIconComponent {
  @Input() icon: Icons;
  @Input() size: number;
  @Input() color: Colors;

  getIcon(variant: Icons): string {
    return icons[variant];
  }

  getColor(variant: Colors): string {
    return colors[variant] ?? 'inherit';
  }
}
