import { InjectionToken } from '@angular/core';
import { AsyncSubject, Subject } from 'rxjs';

export const SERVICE_DATA = new InjectionToken<{}>('SERVICE_DATA');

export enum QueueManagerStatus {
  IN_PROGRESS = 'in-progress',
  WAITING = 'waiting',
  SUCCESS = 'success',
  ERROR = 'error',
  NO_STATUS = 'no-status',
}

export interface QueueManagerUiItem {
  label: string;
  status: QueueManagerStatus;
}

export interface QueueActions {
  success: (message: string) => void;
  error: (message: string) => void;
  update: (message: string) => void;
}

export interface QueueItem {
  payload: any;
  data: QueueManagerUiItem;
  queueSubject: AsyncSubject<any>;
  actions: QueueActions;
}

export interface SeviceData {
  currentUpdates: Subject<QueueManagerUiItem>;
  queueUpdates: Subject<QueueManagerUiItem[]>;
}
