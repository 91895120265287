import { Search } from '@agriness/services';
import { Observable } from 'rxjs';

import { Routes, RoutesFilter, RoutesForm } from '../models/routes.model';

export abstract class RoutesAbstractService {
  abstract list(routeFilter: RoutesFilter): Observable<Search<Routes>>;
  abstract save(routeForm: RoutesForm): Observable<Routes>;
  abstract update(routeForm: RoutesForm, routeId: string): Observable<Routes>;
  abstract delete(route: Routes): Observable<void>;
}
