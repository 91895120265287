import {
  PeriodInterval,
  PeriodUnit,
  ReportFilterPeriodDefaultValue,
  ReportFilterQuery,
  ReportFilterQueryKeys,
} from '@agriness/corp-app/shared/model/report-filter.model';
import {
  TRANSLATE_INSTANT,
  TranslateInstant,
  DateService,
  TypeProductionEnum,
  TypeProductionService,
} from '@agriness/services';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  addDays,
  differenceInDays,
  format,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';

import { FilterComponent } from '../corp-filter-abstractions';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

export type PeriodLabels = {
  startDateLabelKey: string;
  endDateLabelKey: string;
};

type NumberFieldProps<P extends ReportFilterQueryKeys> = Partial<{
  controlName: string;
  queryParamName: P;
  labelKey: string;
}>;

@Component({
  selector: 'corp-period-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './corp-period-filter.component.html',
  providers: [{ provide: FilterComponent, useExisting: CorpPeriodFilterComponent }],
})
export class CorpPeriodFilterComponent extends FilterComponent implements OnInit {
  @Input() rangeLimitInDays?: number;
  @Input() periodDefaultValue?: ReportFilterPeriodDefaultValue;
  @Input() periodLabels: PeriodLabels = {
    startDateLabelKey: 'agriness.start_date',
    endDateLabelKey: 'agriness.end_date',
  };
  @Input() hasMaxDate: boolean;
  @Input() startDefaultDate: Date;
  @Input() endDefaultDate: Date;

  errorMessage = '';

  inputsNumber = 2;
  dateFormat: string;
  showPeriodLimitationFeedback = false;
  periodMaxDate: Date | null = null;
  periodMinDate: Date | null = null;
  beginDate: NumberFieldProps<'begin_date'> = {
    controlName: 'beginDate',
    queryParamName: 'begin_date',
  };

  endDate: NumberFieldProps<'end_date'> = {
    controlName: 'endDate',
    queryParamName: 'end_date',
  };
  showDateLimitationFeedback: boolean;
  maxDate = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);

  constructor(
    @Inject(TRANSLATE_INSTANT) private translate: TranslateInstant,
    private dateService: DateService,
    private typeProductionService: TypeProductionService,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(containerService, activeRoute);
    this.dateFormat = dateService.getDateFormat();

    this.beginDate.labelKey = this.periodLabels.startDateLabelKey;
    this.endDate.labelKey = this.periodLabels.endDateLabelKey;
  }

  ngOnInit(): void {
    this.containerService.formGroup.subscribe(form => {
      this.filterForm = form;
      if (this.filterForm != null) {
        this.addDateValidator();
        this.listenValueChanges();
      }
    });
  }

  addDateValidator(): void {
    // TODO: in angular 12 exits a addValidator, change this when updating.

    this.filterForm?.setValidators((formGroup: FormGroup): {
      invalidBeginDate?: boolean;
      invalidEndDate?: boolean;
    } | null => {
      const beginDate = formGroup.get('beginDate')?.value as Date;
      const endDate = formGroup.get('endDate')?.value as Date;

      if (endDate < beginDate) {
        return { invalidBeginDate: true };
      }

      if (endDate > this.maxDate && this.hasMaxDate) {
        this.showDateLimitationFeedback = true;
        return { invalidEndDate: true };
      } else {
        this.showDateLimitationFeedback = false;
      }

      return null;
    });
    this.filterForm.updateValueAndValidity();
  }

  listenValueChanges(): void {
    this.filterForm?.valueChanges.subscribe(() => {
      const { errors } = this.filterForm;
      if (errors?.invalidBeginDate) {
        this.errorMessage = this.translate(
          'agriness.filter.period.errors.begin_date_greater_then_end_date',
        );
      } else if (errors?.invalidEndDate) {
        this.errorMessage = this.translate(
          'agriness.filter.period.errors.end_date_greater_then_yesterday',
        );
      } else {
        this.errorMessage = '';
      }
    });
  }

  collectSubtitle(): [string, string] {
    return [
      this.translate('agriness.filter.period.label'),
      [
        this.getFormValue<Date>(this.beginDate.controlName),
        this.getFormValue<Date>(this.endDate.controlName),
      ]
        .map(date => {
          try {
            return this.dateService.formatDate(date);
          } catch (error) {
            return '';
          }
        })
        .join(' - '),
    ];
  }

  async getInitialGroupData(): Promise<Record<string, [unknown]>> {
    const defaults = this.getDefaultPeriod();
    const query = this.getPeriodFromQuery();

    const initial = {
      beginDate: query.beginDate ?? defaults.beginDate,
      endDate: query.endDate ?? defaults.endDate,
    };

    if (
      this.rangeLimitInDays &&
      differenceInDays(initial.beginDate, initial.endDate) > this.rangeLimitInDays + 1
    ) {
      initial.endDate = addDays(initial.endDate, this.rangeLimitInDays);
    }

    return Promise.resolve({
      [this.beginDate.controlName]: [initial.beginDate],
      [this.endDate.controlName]: [initial.endDate],
    });
  }

  async getResetData(): Promise<Record<string, unknown>> {
    const { beginDate, endDate } = this.getDefaultPeriod();

    return Promise.resolve({
      [this.beginDate.controlName]: beginDate,
      [this.endDate.controlName]: endDate,
    });
  }

  getValuesInQueryFormat(): { [key in ReportFilterQueryKeys]?: string } {
    return {
      [this.beginDate.queryParamName]: this.getFormDateForQuery(this.beginDate.controlName),
      [this.endDate.queryParamName]: this.getFormDateForQuery(this.endDate.controlName),
    };
  }

  adjustPeriodRange(): void {
    this.showPeriodLimitationFeedback = false;
    this.filterForm.patchValue({
      [this.beginDate.controlName]: this.periodMinDate,
      [this.endDate.controlName]: this.periodMaxDate,
    });

    this.containerService.requestFilter.emit();
  }

  invalidate(query: ReportFilterQuery): boolean {
    return this.invalidateByPeriod(
      this.dateService.toDate(query[this.beginDate.queryParamName]),
      this.dateService.toDate(query[this.endDate.queryParamName]),
    );
  }

  // Custom validator used in ag-calendar
  invalidateByControlName(controlName: string): (value?: Date) => boolean {
    return (value?: Date): boolean => {
      const getDate = (name: string) =>
        name === controlName ? value : this.getFormValue<Date>(name);
      return this.invalidateByPeriod(
        getDate(this.beginDate.controlName),
        getDate(this.endDate.controlName),
        controlName,
      );
    };
  }

  invalidateByFutureDate(controlName: string): (value?: Date) => boolean {
    return (value?: Date): boolean => {
      const isValid = controlName === this.endDate.controlName && value > this.maxDate;

      return isValid;
    };
  }

  setMaxDate(): Date {
    if (!this.hasMaxDate) {
      return null;
    }

    return this.maxDate;
  }

  private invalidateByPeriod(beginDate: Date, endDate: Date, controlName: string = null) {
    if (!this.rangeLimitInDays) {
      return false;
    }
    const diffInDays = differenceInDays(endDate, beginDate);

    if (diffInDays > this.rangeLimitInDays + 1) {
      if (controlName === this.beginDate.controlName) {
        this.periodMinDate = beginDate;
        this.periodMaxDate = addDays(beginDate, this.rangeLimitInDays);
      }

      if (controlName === this.endDate.controlName) {
        this.periodMaxDate = endDate;
        this.periodMinDate = subDays(endDate, this.rangeLimitInDays);
      }
    }

    const isValid = diffInDays <= this.rangeLimitInDays + 1;

    return (this.showPeriodLimitationFeedback = !isValid);
  }

  private getFormDateForQuery(controlName: string) {
    // We explicitly use the date-fns format instead of .toISOString()
    // to avoid timezone issues, since this conversion shouldn't consider
    // timezone differences
    if (this.getFormValue<Date>(controlName) !== null) {
      return format(this.getFormValue<Date>(controlName), 'yyyy-MM-dd');
    }
  }

  private getDefaultPeriod() {
    const today = this.maxDate;
    const [interval = PeriodInterval.CURRENT, unit = PeriodUnit.MONTHS, value] =
      this.periodDefaultValue || this.getDefaultPeriodByTypeProduction();

    let beginDate: Date;
    switch (interval) {
      case PeriodInterval.CURRENT: {
        const currentMapping: { [key in PeriodUnit]?: (value: Date) => Date } = {
          [PeriodUnit.MONTHS]: startOfMonth,
          [PeriodUnit.YEARS]: startOfYear,
        };
        beginDate = currentMapping[unit](today);
        break;
      }
      case PeriodInterval.LAST: {
        const lastMapping: { [key in PeriodUnit]: (date: Date, value: number) => Date } = {
          [PeriodUnit.DAYS]: subDays,
          [PeriodUnit.MONTHS]: subMonths,
          [PeriodUnit.YEARS]: subYears,
        };
        beginDate = lastMapping[unit](today, value);
        break;
      }
    }

    return { beginDate: this.startDefaultDate ?? beginDate, endDate: this.endDefaultDate ?? today };
  }

  private getDefaultPeriodByTypeProduction(): ReportFilterPeriodDefaultValue {
    if (this.periodDefaultValue) {
      return this.periodDefaultValue;
    }

    switch (this.typeProductionService.get()) {
      case TypeProductionEnum.LAYERS:
        return [PeriodInterval.LAST, PeriodUnit.MONTHS, 30];
      case TypeProductionEnum.POULTRY:
        return [PeriodInterval.LAST, PeriodUnit.DAYS, 55];
      case TypeProductionEnum.SWINES:
        return [PeriodInterval.LAST, PeriodUnit.DAYS, 130];
      default:
        return [PeriodInterval.LAST, PeriodUnit.MONTHS, 1];
    }
  }

  private getPeriodFromQuery() {
    const beginDateParam = this.getQueryParam(this.beginDate.queryParamName);
    const endDateParam = this.getQueryParam(this.endDate.queryParamName);

    const beginDate = beginDateParam ? this.dateService.toDate(beginDateParam) : null;
    const endDate = endDateParam ? this.dateService.toDate(endDateParam) : null;

    return { beginDate, endDate };
  }
}
