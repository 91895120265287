import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ag-input',
  templateUrl: './ag-input.component.html',
})
export class AgInputComponent {
  @Input() group: FormGroup;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() readOnly: boolean;
}
