<section>
  <div class="title-container">
    <p class="subtitle">
      {{ 'agriness.payment-rule-configuration.config.title' | translate }}
    </p>
  </div>
  <div class="table-container">
    <p-table [columns]="cols" [value]="formDataSubcribed.indexes">
      <ng-template pTemplate="header" let-columns>
        <tr class="theader">
          <th *ngFor="let col of columns">
            {{ col.header | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-indexes let-columns="columns">
        <tr>
          <td>
            {{ indexes.name }}
          </td>
          <td>
            <span class="action-table" (click)="onAddConfig(indexes)">{{
              'agriness.payment-rule-configuration.config.table.action-button' | translate
            }}</span>
          </td>
          <td>
            <span [ngClass]="{ 'green-badge': indexes.ranges, 'grey-badge': !indexes.ranges }">{{
              !indexes.ranges
                ? ('agriness.payment-rule-configuration.config.table.status-false' | translate)
                : ('agriness.payment-rule-configuration.config.table.status-true' | translate)
            }}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <app-index-configurator
    #configurator
    *ngIf="editorModalVisible"
    [visible]="editorModalVisible"
    [configData]="selectedIndex"
    [modalStatus]="editorModalStatus"
    (closed)="onEditorClosed()"
    (createConfig)="createConfig()"
  ></app-index-configurator>
</section>
