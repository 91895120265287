import { AgrinessServicesModule } from '@agriness/services';
import { AgrinessTranslateModule } from '@agriness/services';
import { AgrinessUiModule } from '@agriness/ui';
import { CorpRecordTableModule } from '@agriness/ui/corp/record-table/corp-record-table.module';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HighchartsChartModule } from 'highcharts-angular';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';

import { DietSelectionService } from '../services/diet-selection/diet-selection.service';
import { MonitorService } from '../services/monitor/monitor.service';
import { ScheduleMonitorService } from '../services/schedule-monitor/schedule-monitor.service';
import { CorpAnimalGroupHealthReportComponent } from './component/corp-animal-group-health-report/corp-animal-group-health-report.component';
import { CorpAnimalGroupLinkComponent } from './component/corp-animal-group-link/corp-animal-group-link.component';
import { CorpAnimalGroupListComponent } from './component/corp-animal-group-list/corp-animal-group-list.component';
import { CorpAnimalGroupMonitorTabsComponent } from './component/corp-animal-group-monitor-tabs/corp-animal-group-monitor-tabs.component';
import { CorpAnimalGroupPaymentReportComponent } from './component/corp-animal-group-payment-report/corp-animal-group-payment-report.component';
import { CorpBackButtonComponent } from './component/corp-back-button/corp-back-button.component';
import { CorpChartTableTemplateComponent } from './component/corp-chart-table-template/corp-chart-table-template.component';
import { CorpSwineDiagnosticTreeComponent } from './component/corp-diagnostic-tree/corp-swine-diagnostic-tree.component';
import { CorpEmbedReportTabComponent } from './component/corp-embed-report/corp-embed-report-tab.component';
import { CorpEmbedReportComponent } from './component/corp-embed-report/corp-embed-report.component';
import { CorpExportButtonComponent } from './component/corp-export-button/corp-export-button.component';
import { CorpFarmSelectComponent } from './component/corp-field-selects/corp-farm-select.component';
import { CorpNutritionTypeSelectComponent } from './component/corp-field-selects/corp-nutrition-type-select.component';
import { CorpProducerSelectComponent } from './component/corp-field-selects/corp-producer-select.component';
import { CorpRegionSelectComponent } from './component/corp-field-selects/corp-region-select.component';
import { CorpRouteSelectComponent } from './component/corp-field-selects/corp-route-select.component';
import { CorpTechnicianSelectComponent } from './component/corp-field-selects/corp-technician-select.component';
import { CorpFiltersContainerComponent } from './component/corp-filters/corp-filters-container.component';
import { CorpAgeFilterComponent } from './component/corp-filters/inputs/corp-age-filter.component';
import { CorpAnimalGroupFilterComponent } from './component/corp-filters/inputs/corp-animal-group-filter.component';
import { CorpCompanyFilterComponent } from './component/corp-filters/inputs/corp-company-filter.component';
import { CorpCurrentCycleFilterComponent } from './component/corp-filters/inputs/corp-current-cycle-filter.component';
import { CorpFarmBondFilterComponent } from './component/corp-filters/inputs/corp-farm-bond-filter.component';
import { CorpFarmFilterComponent } from './component/corp-filters/inputs/corp-farm-filter.component';
import { CorpIsOpenFilterComponent } from './component/corp-filters/inputs/corp-is-open-filter.component';
import { CorpLastAnimalGroupFilterComponent } from './component/corp-filters/inputs/corp-last-animal-group-filter.component';
import { CorpLocationFilterComponent } from './component/corp-filters/inputs/corp-location-filter.component';
import { CorpNutritionTypeFilterComponent } from './component/corp-filters/inputs/corp-nutrition-type-filter.component';
import { CorpPeriodFilterComponent } from './component/corp-filters/inputs/corp-period-filter.component';
import { CorpProducerFilterComponent } from './component/corp-filters/inputs/corp-producer-filter.component';
import { CorpProductionMethodFilterComponent } from './component/corp-filters/inputs/corp-production-method-filter.component';
import { CorpProductionSystemFilterComponent } from './component/corp-filters/inputs/corp-production-system-filter.component';
import { CorpRegionFilterComponent } from './component/corp-filters/inputs/corp-region-filter.component';
import { CorpRouteFilterComponent } from './component/corp-filters/inputs/corp-route-filter.component';
import { CorpSiteFilterComponent } from './component/corp-filters/inputs/corp-site-filter.component';
import { CorpStrainComponent } from './component/corp-filters/inputs/corp-strain-filter.component';
import { CorpStructureProductionFilterComponent } from './component/corp-filters/inputs/corp-structure-production-filter.component';
import { CorpTargetGroupFilterComponent } from './component/corp-filters/inputs/corp-target-group-filter.component';
import { CorpTargetKPIListFilterComponent } from './component/corp-filters/inputs/corp-target-kpi-list-filter.component';
import { CorpTargetPeriodTypeFilterComponent } from './component/corp-filters/inputs/corp-target-period-type-filter.component';
import { CorpTargetStatusKpiFilterComponent } from './component/corp-filters/inputs/corp-target-status-kpi-filter.component';
import { CorpTechnicianFilterComponent } from './component/corp-filters/inputs/corp-technician-filter.component';
import { CorpTextSearchFilterComponent } from './component/corp-filters/inputs/corp-textsearch-filter.component';
import { CorpGraphLegendComponent } from './component/corp-graph-legend/corp-graph-legend.component';
import { CorpIdentifyCustomIntervalComponent } from './component/corp-identify/corp-identify-custom-interval/corp-identify-custom-interval.component';
import { CorpIdentifyProducerListComponent } from './component/corp-identify/corp-identify-producer-list/corp-identify-producer-list.component';
import { CorpIdentifyQuadrantsComponent } from './component/corp-identify/corp-identify-quadrants/corp-identify-quadrants.component';
import { CorpIdentifyComponent } from './component/corp-identify/corp-identify.component';
import { CorpIndexMapGeneratorComponent } from './component/corp-index-map-generator/corp-index-map-generator.component';
import { CorpModalDeletionComponent } from './component/corp-modal-deletion/corp-modal-deletion.component';
import { CorpModalFormComponent } from './component/corp-modal-form/corp-modal-form.component';
import { CorpPerformanceAnalysisSimulationComponent } from './component/corp-performance-analysis/corp-performance-analysis-simulation/corp-performance-analysis-simulation.component';
import { CorpPerformanceAnalysisComponent } from './component/corp-performance-analysis/corp-performance-analysis.component';
import { CorpReportPerformanceAnalysisCardsComponent } from './component/corp-performance-analysis/corp-report-performance-analysis-cards/corp-report-performance-analysis-cards.component';
import { CorpPerformanceGraphComponent } from './component/corp-performance-graph/corp-performance-graph.component';
import { CorpPlanningCapacityAlertComponent } from './component/corp-planning-capacity-alert/corp-planning-capacity-alert.component';
import { CorpPreferenceToggleColumnComponent } from './component/corp-preference-toggle-column/corp-preference-toggle-column.component';
import { CorpRadioOptionsComponent } from './component/corp-radio-options/corp-radio-options.component';
import { CorpRecordListFarmLinkComponent } from './component/corp-record-list-farm-link/corp-record-list-farm-link.component';
import { CorpReportCardsByPeriodComponent } from './component/corp-report-cards-by-period/corp-report-cards-by-period.component';
import { CorpReportCardsComponent } from './component/corp-report-cards/corp-report-cards.component';
import { CorpReportFiltersSubtitleComponent } from './component/corp-report-filters-subtitle/corp-report-filters-subtitle.component';
import { CorpReportFiltersComponent } from './component/corp-report-filters/corp-report-filters.component';
import { CorpReportIndexesTableByPeriodComponent } from './component/corp-report-indexes-table-by-period/corp-report-indexes-table-by-period.component';
import { CorpReportMonitorCardsComponent } from './component/corp-report-monitor-cards/corp-report-monitor-cards.component';
import { CorpReportMonitorTabsComponent } from './component/corp-report-monitor-tabs/corp-report-monitor-tabs.component';
import { CorpReportSectionComponent } from './component/corp-report-section/corp-report-section.component';
import { CorpReportSectionLineGraphComponent } from './component/corp-report-section/line-graph/corp-report-section-line-graph.component';
import { CorpReportSectionTableComponent } from './component/corp-report-section/table/corp-report-section-table.component';
import { CorpWarningFeedbackComponent } from './component/corp-warning-feedback/corp-warning-feedback.component';
import { CorpWizzardComponent } from './component/corp-wizzard/corp-wizzard.component';
import { DietSelectionAutoCompleteComponent } from './component/diet-selection/autocomplete/diet-selection-autocomplete.component';
import { DietSelectionComponent } from './component/diet-selection/diet-selection.component';
import { DietSelectionFormComponent } from './component/diet-selection/form/diet-selection-form.component';
import { DietSelectionIconFailComponent } from './component/diet-selection/icons/fail/diet-selection-icon-fail.component';
import { DietSelectionIconLoadingComponent } from './component/diet-selection/icons/loading/diet-selection-icon-loading.component';
import { DietSelectionIconSuccessComponent } from './component/diet-selection/icons/success/diet-selection-icon-success.component';
import { DietSelectionIconWarningComponent } from './component/diet-selection/icons/warning/diet-selection-icon-warning.component';
import { DietSelectionStatusComponent } from './component/diet-selection/status/diet-selection-status.component';
import { DietSelectionTableComponent } from './component/diet-selection/table/diet-selection-table.component';
import { LazyPipe } from './pipe/lazy.pipe';
import { GraphExporterService } from './services/graph-exporter.service';
import { HighchartsService } from './services/highcharts.service';
import { ReportSectionLineGraphService } from './services/report-section/report-section-line-graph.service';
import { ReportSectionTableService } from './services/report-section/report-section-table.service';

@NgModule({
  declarations: [
    CorpAnimalGroupHealthReportComponent,
    CorpAnimalGroupLinkComponent,
    CorpAnimalGroupListComponent,
    CorpAnimalGroupMonitorTabsComponent,
    CorpBackButtonComponent,
    CorpChartTableTemplateComponent,
    CorpExportButtonComponent,
    CorpSwineDiagnosticTreeComponent,
    CorpGraphLegendComponent,
    CorpIdentifyComponent,
    CorpIdentifyCustomIntervalComponent,
    CorpIdentifyProducerListComponent,
    CorpPerformanceAnalysisComponent,
    CorpPerformanceAnalysisSimulationComponent,
    CorpIdentifyQuadrantsComponent,
    CorpIndexMapGeneratorComponent,
    CorpPerformanceGraphComponent,
    CorpPreferenceToggleColumnComponent,
    CorpRadioOptionsComponent,
    CorpRecordListFarmLinkComponent,
    CorpReportCardsByPeriodComponent,
    CorpReportCardsComponent,
    CorpReportFiltersComponent,
    CorpReportIndexesTableByPeriodComponent,
    CorpReportMonitorCardsComponent,
    CorpReportMonitorTabsComponent,
    CorpReportSectionComponent,
    CorpReportSectionLineGraphComponent,
    CorpReportSectionTableComponent,
    CorpReportPerformanceAnalysisCardsComponent,
    CorpWarningFeedbackComponent,
    CorpFiltersContainerComponent,
    CorpCompanyFilterComponent,
    CorpStrainComponent,
    CorpFarmFilterComponent,
    CorpSiteFilterComponent,
    CorpProductionSystemFilterComponent,
    CorpProductionMethodFilterComponent,
    CorpAnimalGroupFilterComponent,
    CorpCurrentCycleFilterComponent,
    CorpAgeFilterComponent,
    CorpPeriodFilterComponent,
    CorpProducerFilterComponent,
    CorpTechnicianFilterComponent,
    CorpRegionFilterComponent,
    CorpTargetStatusKpiFilterComponent,
    CorpNutritionTypeFilterComponent,
    CorpIsOpenFilterComponent,
    CorpLastAnimalGroupFilterComponent,
    CorpRouteFilterComponent,
    CorpLocationFilterComponent,
    CorpTextSearchFilterComponent,
    DietSelectionComponent,
    DietSelectionAutoCompleteComponent,
    DietSelectionFormComponent,
    DietSelectionIconFailComponent,
    DietSelectionIconLoadingComponent,
    DietSelectionIconSuccessComponent,
    DietSelectionIconWarningComponent,
    DietSelectionStatusComponent,
    DietSelectionTableComponent,
    LazyPipe,
    CorpReportFiltersSubtitleComponent,
    CorpEmbedReportComponent,
    CorpEmbedReportTabComponent,
    CorpModalFormComponent,
    CorpModalDeletionComponent,
    CorpNutritionTypeSelectComponent,
    CorpProducerSelectComponent,
    CorpTechnicianSelectComponent,
    CorpRegionSelectComponent,
    CorpRouteSelectComponent,
    CorpFarmSelectComponent,
    CorpWizzardComponent,
    CorpTargetKPIListFilterComponent,
    CorpAnimalGroupPaymentReportComponent,
    CorpFarmBondFilterComponent,
    CorpPlanningCapacityAlertComponent,
    CorpTargetGroupFilterComponent,
    CorpTargetPeriodTypeFilterComponent,
    CorpStructureProductionFilterComponent,
  ],
  imports: [
    AgrinessServicesModule,
    AgrinessTranslateModule,
    AgrinessUiModule,
    CheckboxModule,
    CommonModule,
    DialogModule,
    DropdownModule,
    FormsModule,
    HighchartsChartModule,
    ReactiveFormsModule,
    RouterModule,
    TableModule,
    AutoCompleteModule,
    CorpRecordTableModule,
    StepsModule,
  ],
  exports: [
    AgrinessServicesModule,
    AgrinessTranslateModule,
    AgrinessUiModule,
    CommonModule,
    CorpAnimalGroupHealthReportComponent,
    CorpAnimalGroupLinkComponent,
    CorpAnimalGroupListComponent,
    CorpAnimalGroupMonitorTabsComponent,
    CorpBackButtonComponent,
    CorpChartTableTemplateComponent,
    CorpExportButtonComponent,
    CorpSwineDiagnosticTreeComponent,
    CorpGraphLegendComponent,
    CorpIdentifyComponent,
    CorpPerformanceAnalysisComponent,
    CorpPerformanceGraphComponent,
    CorpPreferenceToggleColumnComponent,
    CorpRadioOptionsComponent,
    CorpRecordListFarmLinkComponent,
    CorpRecordTableModule,
    CorpReportCardsComponent,
    CorpReportFiltersComponent,
    CorpReportIndexesTableByPeriodComponent,
    CorpReportMonitorCardsComponent,
    CorpReportMonitorTabsComponent,
    CorpReportSectionComponent,
    CorpReportSectionLineGraphComponent,
    CorpReportSectionTableComponent,
    FormsModule,
    RouterModule,
    CorpReportPerformanceAnalysisCardsComponent,
    CorpWarningFeedbackComponent,
    CorpFiltersContainerComponent,
    CorpCompanyFilterComponent,
    CorpStrainComponent,
    CorpFarmFilterComponent,
    CorpSiteFilterComponent,
    CorpProductionSystemFilterComponent,
    CorpProductionMethodFilterComponent,
    CorpAnimalGroupFilterComponent,
    CorpCurrentCycleFilterComponent,
    CorpAgeFilterComponent,
    CorpPeriodFilterComponent,
    CorpProducerFilterComponent,
    CorpTechnicianFilterComponent,
    CorpRegionFilterComponent,
    CorpTargetStatusKpiFilterComponent,
    CorpNutritionTypeFilterComponent,
    CorpIsOpenFilterComponent,
    CorpLastAnimalGroupFilterComponent,
    CorpRouteFilterComponent,
    CorpLocationFilterComponent,
    CorpTextSearchFilterComponent,
    DietSelectionAutoCompleteComponent,
    DietSelectionComponent,
    DietSelectionFormComponent,
    DietSelectionIconFailComponent,
    DietSelectionIconLoadingComponent,
    DietSelectionIconSuccessComponent,
    DietSelectionIconWarningComponent,
    DietSelectionStatusComponent,
    DietSelectionTableComponent,
    LazyPipe,
    CorpReportFiltersSubtitleComponent,
    CorpEmbedReportComponent,
    CorpEmbedReportTabComponent,
    CorpModalFormComponent,
    CorpModalDeletionComponent,
    CorpNutritionTypeSelectComponent,
    CorpProducerSelectComponent,
    CorpTechnicianSelectComponent,
    CorpRegionSelectComponent,
    CorpRouteSelectComponent,
    CorpFarmSelectComponent,
    CorpWizzardComponent,
    CorpTargetKPIListFilterComponent,
    CorpAnimalGroupPaymentReportComponent,
    CorpFarmBondFilterComponent,
    CorpPlanningCapacityAlertComponent,
    CorpTargetGroupFilterComponent,
    CorpTargetPeriodTypeFilterComponent,
    CorpStructureProductionFilterComponent,
  ],
  providers: [
    DietSelectionService,
    ReportSectionLineGraphService,
    ReportSectionTableService,
    HighchartsService,
    GraphExporterService,
    MonitorService,
    ScheduleMonitorService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
