import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { AppMenuModel } from './app-menu.model';
import { AppMenuService } from './app-menu.service';

@Component({
  selector: 'ag-app-menu',
  templateUrl: './app-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMenuComponent implements OnInit, OnDestroy {
  appMenu$: Subscription;
  apps: AppMenuModel[];

  constructor(
    private appMenuService: AppMenuService,
    private changeDetectionRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.appMenu$ = this.appMenuService.getAppMenuModel().subscribe(apps => {
      this.apps = apps;
      this.changeDetectionRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.appMenu$.unsubscribe();
  }
}
