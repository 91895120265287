import { AgrinessIconModule } from '@agriness/ui/guavi/icon/agriness-icon.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';

import { AgModalFooterComponent } from './ag-modal-footer/ag-modal-footer.component';
import { AgModalComponent } from './ag-modal.component';
import { AgModalFeedback } from './ag-modal-feedback/ag-modal-feedback.component';
import { AgModalFeedbackIconComponent } from './ag-modal-feedback/icon/ag-modal-feedback-icon.component';

@NgModule({
  declarations: [
    AgModalComponent,
    AgModalFooterComponent,
    AgModalFeedback,
    AgModalFeedbackIconComponent,
  ],
  imports: [CommonModule, TranslateModule, DialogModule, AgrinessIconModule],
  exports: [AgModalComponent, AgModalFooterComponent, AgModalFeedback],
})
export class AgModalModule {}
