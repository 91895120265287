<div>
  <p-calendar
    [(ngModel)]="value"
    [inputId]="inputId"
    [name]="inputId"
    class="form-control-wrapper"
    inputStyleClass="form-control"
    styleClass="w-100"
    showTime="true"
    timeOnly="true"
    (onBlur)="onChange()"
    (onClose)="onChange()"
  ></p-calendar>
  <label class="control-label">
    {{ label | translate }}
  </label>
  <span class="control-icon icon icon-clock"></span>
</div>
