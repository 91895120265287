import { TargetStatus } from '../target.model';

export const targetTypes = {
  RANGE_BAD_ATTENTION_GOOD: [
    {
      status: TargetStatus.BAD,
      iconPosition: 'left',
      percentage: 100,
    },
    {
      status: TargetStatus.ATTENTION,
      iconPosition: 'left',
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'center',
      percentage: 100,
    },
    {
      status: TargetStatus.ATTENTION,
      iconPosition: 'right',
      percentage: 100,
    },
    {
      status: TargetStatus.BAD,
      iconPosition: 'right',
      percentage: 100,
    },
  ],
  SCALE_GOOD_ATTENTION_BAD: [
    {
      status: TargetStatus.GOOD,
      iconPosition: 'left',
      noBarSeparator: true,
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'left',
      noBarSeparator: true,
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'center',
      percentage: 100,
    },
    {
      status: TargetStatus.ATTENTION,
      iconPosition: 'right',
      percentage: 100,
    },
    {
      status: TargetStatus.BAD,
      iconPosition: 'right',
      percentage: 100,
    },
  ],
  SCALE_BAD_ATTENTION_GOOD: [
    {
      status: TargetStatus.BAD,
      iconPosition: 'left',
      percentage: 100,
    },
    {
      status: TargetStatus.ATTENTION,
      iconPosition: 'left',
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'center',
      noBarSeparator: true,
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'right',
      noBarSeparator: true,
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'right',
      percentage: 100,
    },
  ],
  SCALE_ATTENTION_GOOD_BAD: [
    {
      status: TargetStatus.ATTENTION,
      iconPosition: 'left',
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'left',
      noBarSeparator: true,
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'center',
      noBarSeparator: true,
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'right',
      percentage: 100,
    },
    {
      status: TargetStatus.BAD,
      iconPosition: 'right',
      percentage: 100,
    },
  ],
  RANGE_BAD_GOOD: [
    {
      status: TargetStatus.BAD,
      iconPosition: 'left',
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'left',
      noBarSeparator: true,
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'center',
      noBarSeparator: true,
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'right',
      percentage: 100,
    },
    {
      status: TargetStatus.BAD,
      iconPosition: 'right',
      percentage: 100,
    },
  ],
  SCALE_BAD_GOOD: [
    {
      status: TargetStatus.BAD,
      iconPosition: 'left',
      noBarSeparator: true,
      percentage: 100,
    },
    {
      status: TargetStatus.BAD,
      iconPosition: 'left',
      percentage: 100,
      overflow: 150,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'center',
      noBarSeparator: true,
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'right',
      noBarSeparator: true,
      percentage: 100,
    },
    {
      status: TargetStatus.GOOD,
      iconPosition: 'right',
      percentage: 100,
    },
  ],
};
