<p-autoComplete
  inputStyleClass="form-control"
  [styleClass]="suggestions?.length ? 'w-100' : 'w-100 ag-autocomplete-empty'"
  scrollHeight="320px"
  [inputId]="inputId"
  [(ngModel)]="model"
  [suggestions]="suggestions"
  [multiple]="multiple"
  [placeholder]="placeholder | translate"
  [emptyMessage]="getEmptyMessage() | translate"
  [forceSelection]="forceSelection"
  (completeMethod)="search($event)"
  (onSelect)="selectItem($event)"
  (onUnselect)="unselectItem($event)"
  (onBlur)="onFocusOut($event)"
  (onClear)="onClear()"
  [field]="field"
>
  <ng-template *ngIf="templateItem" let-item pTemplate="item">
    <ng-container *ngTemplateOutlet="templateItem; context: { $implicit: item }"></ng-container>
  </ng-template>
</p-autoComplete>
