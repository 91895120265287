import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { DietFormData, DietSelectionEntries, FeedSearch } from '../models/diet-selection.model';
import { Diet } from '../models/diet.model';

export type RequestParams = {
  holdingId: string;
  stage: string;
  id: string;
  query?: string;
};

export type PostRequestParams = RequestParams & { data: DietFormData };

@Injectable()
export class DietSelectionService {
  private dietSelectionPath = 'holdings/{holdingId}/layers/{stage}/diet-selection/{id}/';
  private feedSearchPath = 'holdings/{holdingId}/layers/{stage}/feed-search/{id}/';

  constructor(private apiCorpService: ApiCorpService) {}

  getPerformances({ holdingId, stage, id }: RequestParams): Observable<DietSelectionEntries> {
    return this.apiCorpService.get<DietSelectionEntries>(
      this.getUrlForDietSelection({ holdingId, stage, id }),
    );
  }

  saveDiet({ holdingId, stage, id, data }: PostRequestParams): Observable<Diet> {
    return this.apiCorpService.put(this.getUrlForDietSelection({ holdingId, stage, id }), data);
  }

  searchDiets({ holdingId, stage, id, query }: RequestParams): Observable<FeedSearch> {
    const url = this.apiCorpService.translateUrl(this.feedSearchPath, { holdingId, stage, id });
    const params = { search: query };
    return this.apiCorpService.get(url, params);
  }

  private getUrlForDietSelection({ holdingId, stage, id }: RequestParams): string {
    return this.apiCorpService.translateUrl(this.dietSelectionPath, {
      holdingId,
      stage,
      id,
    });
  }
}
