import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { PipesModule } from '../../pipe/pipes.module';
import { AgFormatModule } from '../format/ag-format.module';
import { AgCardReportComponent } from './ag-card-report.component';

@NgModule({
  imports: [SharedModule, PipesModule, AgFormatModule],
  declarations: [AgCardReportComponent],
  exports: [AgCardReportComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgCardReportModule {}
