import {
  ReportFilter,
  ReportFilterQuery,
  ReportFilterQueryKeys,
} from '@agriness/corp-app/shared/model/report-filter.model';
import { TranslateInstant } from '@agriness/services';
import { Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { kebabCase, snakeCase } from 'lodash';
import { Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { SelectLoader, IdName } from './corp-filter.service';
import { CorpFiltersContainerService } from './corp-filters-container.service';

export abstract class FilterComponent implements OnInit, OnDestroy {
  inputWidth = '25%';
  inputsNumber = 1;
  hidden = false;
  kebabCase = kebabCase;
  filterForm?: FormGroup;
  queryParamName: ReportFilterQueryKeys;
  private formGroupSubscription = Subscription.EMPTY;

  protected constructor(
    protected containerService: CorpFiltersContainerService,
    private activeRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.formGroupSubscription = this.containerService.formGroup.subscribe(
      form => (this.filterForm = form),
    );
  }

  ngOnDestroy(): void {
    this.formGroupSubscription.unsubscribe();
  }

  /**
   * Implementers of this this function
   * are expected to validate its input data
   * and perform the needed rendering/side effects
   * to represent an validation error
   *
   * @param query - the current value of all available
   * inputs present in the current container
   *
   * @return wheter or not the inputs were invalidated
   * (true=one or more inputs are invalid)
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  invalidate(query: ReportFilterQuery): boolean {
    return false;
  }

  /**
   * Returns the current value associated with the given controlName
   *
   * Note: type T is to avoid a cast, it's not guaranteed to be T,
   * so don't trust the type.
   */
  protected getFormValue<T>(controlName: string): T | undefined {
    return this.filterForm?.get(controlName)?.value as T;
  }

  /**
   * Gets a query param from the current active route snapshot
   */
  protected getQueryParam(name: string): string {
    return this.activeRoute.snapshot.queryParamMap.get(name);
  }

  /**
   * Returns the values to be used for the subtitle/summary
   * of the current filter (label, and value)
   */
  abstract collectSubtitle(): [string, string];

  /**
   * All inputs must support deserialization from a query
   * url param
   */
  abstract getValuesInQueryFormat(): { [key in ReportFilterQueryKeys]?: string | number };

  /**
   * Implementers of this class must return
   * their control names along with their
   * initial values that will be used to construct the form group
   * for the form control instance
   */
  abstract getInitialGroupData(): Promise<Record<string, [unknown]>>;

  /**
   * All date filter inputs must provide a value for a reset call
   */
  abstract getResetData(): Promise<Record<string, unknown>>;
}

export abstract class MultiSelectComponent<T extends IdName = IdName> extends FilterComponent
  implements OnInit, OnDestroy {
  @Input() eager = true;
  queryParamWithId = true;
  controlName: string;
  multiSelectedKey: string;
  allSelectedKey: string;
  labelKey: string;
  loader: SelectLoader<T>;

  subtitlesCache: Record<string, string> = {};
  protected loadSubscription = Subscription.EMPTY;

  protected constructor(
    private translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(containerService, activeRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // The order of the following function calls is important
    this.listenForNamesCache();
    this.loader
      .loadInitialValues(this.getValueFromRoute())
      .subscribe(() => this.containerService.requestSubtitleCollection.next());

    if (this.eager) {
      this.loadSubscription = this.loader.loadOnFilter().subscribe(() => this.trimValues());
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.loadSubscription.unsubscribe();
  }

  collectSubtitle(): [string, string] {
    const label = this.translate(this.labelKey);
    const ids = this.getFormValue<(string | number)[]>(this.controlName) || [];
    const selectedNames = ids
      .filter(id => this.subtitlesCache[id])
      .map(id => this.subtitlesCache[id]);
    if (selectedNames.length > 2) {
      return [label, this.translate(this.multiSelectedKey, [selectedNames.length])];
    }

    if (!selectedNames.length) {
      return [label, this.translate(this.allSelectedKey)];
    }

    return [label, selectedNames.join(', ')];
  }

  getValuesInQueryFormat(): { [key in ReportFilterQueryKeys]?: string } {
    const values: string[] = this.getFormValue(this.controlName);

    return { [this.queryParamName]: values ? values.join(',') : null };
  }

  getInitialGroupData(): Promise<Record<string, [unknown]>> {
    return Promise.resolve({ [this.controlName]: [this.getValueFromRoute()] });
  }

  getResetData(): Promise<Record<string, unknown>> {
    return Promise.resolve({ [this.controlName]: [[]] });
  }

  protected getValueFromRoute(): string[] {
    const ids = this.getQueryParam(this.queryParamName);

    return ids?.split(',').filter(id => id.length) ?? [];
  }

  protected assignDefaults(name: keyof ReportFilter, loader: SelectLoader<T>): void {
    const snakeName = snakeCase(name);
    const i18nBase = `agriness.filter.${snakeName}`;

    this.controlName = name;
    if (this.queryParamWithId) {
      this.queryParamName = `${snakeName}_id` as ReportFilterQueryKeys;
    } else {
      this.queryParamName = snakeName as ReportFilterQueryKeys;
    }

    this.loader = loader;
    this.multiSelectedKey = `${i18nBase}.multi_selected`;
    this.allSelectedKey = `${i18nBase}.all`;
    this.labelKey = `${i18nBase}.label`;
  }

  private listenForNamesCache(): void {
    this.loader.options.subscribe(options => {
      options.forEach(it => {
        this.subtitlesCache[it.id] = it.name;
      });
    });
  }

  private trimValues(): void {
    const ids = this.getFormValue<(string | number)[]>(this.controlName) || [];
    const filteredIds = ids.filter(id => this.subtitlesCache[id]);

    if (filteredIds.length != ids.length) {
      this.filterForm.controls[this.controlName]?.setValue(filteredIds);
      this.containerService.requestFilter.emit();
    }
  }
}

export abstract class SelectComponent<T extends IdName = IdName> extends FilterComponent
  implements OnInit, OnDestroy {
  eager = true;
  controlName: string;
  firstAsInitial = false;
  isSearchable = true;
  labelKey: string;

  emptySelectionKey: string;
  loader: SelectLoader<T>;

  protected loadSubscription = Subscription.EMPTY;

  protected constructor(
    private translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(containerService, activeRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.eager) {
      this.loadSubscription = this.loader.loadOnFilter().subscribe();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.loadSubscription.unsubscribe();
  }

  collectSubtitle(): [string, string] {
    const id = this.getFormValue<string>(this.controlName);
    const items = this.loader.options.value;
    const name = items.find(it => it.id === id)?.name;
    const label = this.translate(this.labelKey);

    if (!this.emptySelectionKey && !name) {
      return [label, ''];
    }

    return [label, name || this.translate(this.emptySelectionKey)];
  }

  getValuesInQueryFormat(): { [key in ReportFilterQueryKeys]?: string } {
    const value = this.getFormValue<string>(this.controlName);

    return { [this.queryParamName]: value };
  }

  async getInitialGroupData(): Promise<Record<string, [unknown]>> {
    const queryValue = this.getQueryParam(this.queryParamName);

    let initial: string | null = null;
    if (queryValue) {
      initial = queryValue;
    } else if (this.firstAsInitial) {
      initial = await this.getFirstResult();
    }

    return Promise.resolve({ [this.controlName]: [initial] });
  }

  async getResetData(): Promise<Record<string, unknown>> {
    let initial: string | null = null;

    if (this.firstAsInitial) {
      initial = await this.getFirstResult();
    }

    return Promise.resolve({ [this.controlName]: initial });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected takeId(entity: T | undefined): string {
    throw new Error('Take id should be implemented by descendants');
  }

  protected getFirstResult(): Promise<string> {
    return this.loader
      .loadOnFilter()
      .pipe(
        map(value => this.takeId(value?.results?.[0])),
        first(),
      )
      .toPromise();
  }

  protected assignDefaults(name: keyof ReportFilter, loader: SelectLoader<T>): void {
    const snakeName = snakeCase(name);

    this.controlName = name;
    this.queryParamName = snakeName as ReportFilterQueryKeys;
    this.loader = loader;
    this.labelKey = `agriness.filter.${snakeName}.label`;
    this.emptySelectionKey = `agriness.filter.${snakeName}.all`;
  }
}

export abstract class InputTextComponent extends FilterComponent {
  controlName: string;
  labelKey: string;
  emptySelectionKey: string;

  protected constructor(
    private translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(containerService, activeRoute);
  }

  collectSubtitle(): [string, string] {
    const name = this.getFormValue<string>(this.controlName);
    const label = this.translate(this.labelKey);

    if (!this.emptySelectionKey && !name) {
      return [label, ''];
    }

    return [label, name || this.translate(this.emptySelectionKey)];
  }

  getValuesInQueryFormat(): { [key in ReportFilterQueryKeys]?: string } {
    const value = this.getFormValue<string>(this.controlName);

    return { [this.queryParamName]: value };
  }

  getInitialGroupData(): Promise<Record<string, [unknown]>> {
    return Promise.resolve({
      [this.controlName]: [this.getQueryParam(this.queryParamName)],
    });
  }

  getResetData(): Promise<Record<string, unknown>> {
    return Promise.resolve({ [this.controlName]: '' });
  }

  protected assignDefaults(name: keyof ReportFilter): void {
    const snakeName = snakeCase(name);

    this.controlName = name;
    this.queryParamName = this.queryParamName ?? (snakeName as ReportFilterQueryKeys);
    this.labelKey = `agriness.filter.${snakeName}.search`;
    this.emptySelectionKey = `agriness.filter.${snakeName}.all`;
  }
}
