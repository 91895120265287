import { getValueStyle, ValueFormatStyle } from '../../../pipe/value-format/value-format.model';
import { TableColumn } from '../simple/ag-table.model';

export function getCellRawValue(data: any): any {
  if (data && data.hasOwnProperty('value')) {
    return data.value;
  }
  return data;
}

export function getCellValueStyle(
  col: TableColumn,
  data: any,
  defaultStyle: ValueFormatStyle | string,
): ValueFormatStyle {
  let style = defaultStyle;
  if (data && data.style) {
    style = data.style;
  } else if (data && data.measurement_unit) {
    style = data.measurement_unit;
  } else if (col && col.style) {
    style = col.style;
  }
  return getValueStyle(getCellRawValue(data), style);
}

export function getCellDecimalPlaces(col: TableColumn, data: any): number {
  if (data && data.styleDecimalPlaces != null) {
    return data.styleDecimalPlaces;
  } else if (data && data.decimal_places != null) {
    return data.decimal_places;
  } else if (col && col.styleDecimalPlaces != null) {
    return col.styleDecimalPlaces;
  }
  return null;
}
