import { FilterMonitor } from '@agriness/corp-app/services';
import { Observable } from 'rxjs';

export interface CorpReportComponent {
  loadPreferences(): Observable<unknown>;
  loadPerformances(filter: FilterMonitor, withFeedback?: boolean): Observable<unknown>;
  hasData(): boolean;
}

export enum ReportMonitorTabsEnum {
  INDICATORS = 'indicators',
  DIAGNOSTIC_TREE = 'diagnostic-tree',
}
