import { SectionModel, SectionService } from '@agriness/ui';
import { OnInit } from '@angular/core';

export abstract class SectionAbstractComponent implements OnInit {
  constructor(protected sectionService: SectionService) {}

  ngOnInit(): void {
    this.sectionService.applyLinksSection(this.getSections());
  }

  protected abstract getSections(): SectionModel[];
}
