import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { BarChart, BarChartItem } from '../card.model';

@Component({
  selector: 'ag-card-bar-chart-links',
  templateUrl: './ag-card-bar-chart-links.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgCardBarChartLinksComponent implements OnChanges {
  @Input() items: BarChart;
  itemsWithRoute: BarChart;

  ngOnChanges(changes: SimpleChanges) {
    this.itemsWithRoute = this.items?.filter(this.hasLink);
  }

  hasLink(item: BarChartItem): boolean {
    return item.route !== '' && item.routeText != null;
  }
}
