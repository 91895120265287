<div class="column is-narrow border-left pl-4">
  <strong class="is-uppercase text is-small">{{
    'agriness.health_report.health_report' | translate
  }}</strong>
  <div class="is-narrow pb-3 pt-1">
    <p class="text is-small has-text-grey-4">
      {{ 'agriness.health_report.status' | translate }}:
      <strong class="text is-small has-text-grey-4">{{ statusTranslateKey() | translate }}</strong>
    </p>
  </div>
  <div>
    <button
      class="btn btn-primary is-small text is-xsmall"
      data-testid="form-group-card"
      [routerLink]="['traceability', documentId || '']"
      [queryParams]="{ animalGroupName: animalGroupName }"
    >
      {{ 'agriness.health_report.form' | translate }}
    </button>
    <a *ngIf="publishedUrl" class="btn btn-ghost-light" [href]="publishedUrl" target="_blank">
      <i class="icon icon-open-new-tab pl-3"></i>
      <strong class="text is-xsmall pl-1">
        {{ 'agriness.health_report.published_document' | translate }}
      </strong>
    </a>
  </div>
</div>
