<corp-modal-form
  [title]="translationContext + (checklistData ? '.edit_title' : '.add_title') | translate"
  [modalStatus]="modalStatus"
  [visible]="visible"
  [formValid]="
    checklistForm.valid &&
    checklistItems.valid &&
    checklistItemsEditor.valid &&
    checklistForm.get('stage_ids').value &&
    deadlineRuleIsValid
  "
  (closed)="onClosed()"
  (formSubmit)="onSubmit()"
  [icon]="'presence'"
>
  <form>
    <div class="form-section">
      <div>
        <p class="subtitle">
          {{ translationContext + '.fields.information.title' | translate }}
        </p>
      </div>

      <div class="form-container full-width">
        <div class="form-group form-field">
          <ag-text-field
            [formGroup]="checklistForm"
            controlName="name"
            [errorMessage]="errorMessages.name"
            [label]="translationContext + '.fields.information.name' | translate"
          >
          </ag-text-field>
        </div>
        <div class="form-group form-field">
          <label class="control-label config-input">
            {{ translationContext + '.fields.information.stage' | translate }}
          </label>
          <ag-multiselect
            [group]="checklistForm"
            controlName="stage_ids"
            [items$]="stageSelectData"
            dataKey="key"
            [itemsSelect]="selectedStages"
            [errorMessage]="errorMessages.stage_ids"
          >
          </ag-multiselect>
        </div>
      </div>
    </div>
    <div class="form-section">
      <div>
        <p class="subtitle">
          {{ translationContext + '.fields.rules.title' | translate }}
        </p>
      </div>
      <div class="form-container full-width">
        <div class="form-group form-field">
          <label class="control-label config-input">
            {{ translationContext + '.fields.rules.type.label' | translate }}
          </label>
          <ag-select
            [group]="checklistForm"
            controlName="deadline_rule"
            [items$]="ruleTypeSelectData"
            dataKey="id"
            optionLabel="name"
            [itemsSelect]="selectedRuleType"
            [errorMessage]="errorMessages.deadline_rule"
          ></ag-select>
        </div>
        <div class="form-group is-one-quarter form-field">
          <ag-number-field
            [group]="checklistForm"
            controlName="start_days"
            inputWidth="100%"
            [disabled]="checklistForm.get('deadline_rule')?.value !== '1'"
            label="{{ translationContext + '.fields.rules.start_days' | translate }}"
            [errorMessage]="errorMessages.start_days"
          ></ag-number-field>
        </div>
        <div class="form-group is-one-quarter form-field">
          <ag-number-field
            [group]="checklistForm"
            controlName="deadline_days"
            [disabled]="checklistForm.get('deadline_rule')?.value !== '1'"
            inputWidth="100%"
            label="{{ translationContext + '.fields.rules.deadline_days' | translate }}"
            [errorMessage]="errorMessages.deadline_days"
          ></ag-number-field>
        </div>
      </div>
    </div>
    <div class="form-section">
      <div>
        <p class="subtitle">
          {{ translationContext + '.fields.items.title' | translate }}
        </p>
      </div>
      <div class="columns">
        <div class="column is-11">
          <div
            class="columns"
            *ngFor="let checklistControl of checklistItems.controls; let i = index"
          >
            <div class="column is-8">
              <ag-text-field
                [formGroup]="checklistControl"
                controlName="name"
                [errorMessage]="errorMessages.name"
                [label]="translationContext + '.fields.items.description' | translate"
              >
              </ag-text-field>
            </div>

            <div class="column is-3">
              <ag-number-field
                [group]="checklistControl"
                controlName="score"
                inputWidth="100%"
                label="{{ translationContext + '.fields.items.score' | translate }}"
                [errorMessage]="errorMessages.score"
                minValue="1"
              ></ag-number-field>
            </div>
            <div [ngClass]="{ 'items-options': checklistData }" class="column is-1">
              <div class="px-0 d-flex">
                <button *ngIf="!checklistData" class="btn btn-fab-primary mt-2 mr-1">
                  <span class="icon icon-add-circle-outline" (click)="addItem()"></span>
                </button>
                <button
                  *ngIf="!checklistData"
                  class="btn btn-fab-primary mt-2 mr-1"
                  [disabled]="cantRemove()"
                >
                  <span class="icon icon-remove-circle-outline" (click)="remove(i)"></span>
                </button>
              </div>
              <div *ngIf="checklistData" class="px-0 d-flex">
                <div class="corp-switch-button" [formGroup]="checklistControl">
                  <label class="switch">
                    <input
                      #checkbox
                      type="checkbox"
                      [checked]="checklistControl.controls['enabled'].value"
                      formControlName="enabled"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
                <span class="toggle-text">{{
                  translationContext + '.fields.items.toggle_text' | translate
                }}</span>
              </div>
            </div>
          </div>
          <div class="mb-3" (click)="addItem()">
            <button *ngIf="checklistData" class="btn btn-fab-primary mr-1">
              <span class="icon icon-add-circle-outline"></span>
            </button>
            <span class="add-button" *ngIf="checklistData">
              {{ translationContext + '.fields.add_button' | translate }}
            </span>
          </div>

          <div
            class="columns"
            *ngFor="let checklistControl of checklistItemsEditor.controls; let i = index"
          >
            <div class="column is-8">
              <ag-text-field
                [formGroup]="checklistControl"
                controlName="name"
                [errorMessage]="errorMessages.name"
                [label]="translationContext + '.fields.items.description' | translate"
              >
              </ag-text-field>
            </div>

            <div class="column is-3">
              <ag-number-field
                [group]="checklistControl"
                controlName="score"
                inputWidth="100%"
                label="{{ translationContext + '.fields.items.score' | translate }}"
                [errorMessage]="errorMessages.score"
                minValue="1"
              ></ag-number-field>
            </div>
            <div [ngClass]="{ 'items-options': checklistData }" class="column is-1">
              <div class="px-0 d-flex">
                <button class="btn btn-fab-primary mt-2 mr-1">
                  <span class="icon icon-add-circle-outline" (click)="addItem()"></span>
                </button>
                <button class="btn btn-fab-primary mt-2 mr-1" [disabled]="cantRemove()">
                  <span class="icon icon-remove-circle-outline" (click)="remove(i)"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</corp-modal-form>
