<div class="row ml-1 mr-1 slots" *ngIf="itemsWithRoute?.length > 0">
  <ag-link
    *ngFor="let item of itemsWithRoute"
    class="slot"
    [route]="item.route"
    [query]="item.queryParams"
  >
    {{ item.routeText }}
  </ag-link>
</div>
