import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppMenuModel } from './app-menu.model';

@Injectable({ providedIn: 'root' })
export class AppMenuService {
  private appMenuSubject = new BehaviorSubject<AppMenuModel[]>(null);

  constructor() {}

  applyAppMenu(data: AppMenuModel[]) {
    this.appMenuSubject.next(data);
  }

  getAppMenuModel(): Observable<AppMenuModel[]> {
    return this.appMenuSubject.asObservable();
  }
}
