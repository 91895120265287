import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { HoldingMenuModel } from './holding-menu.model';

@Injectable({ providedIn: 'root' })
export class HoldingMenuService {
  private holdingSubject = new BehaviorSubject<HoldingMenuModel>(null);

  applyHolding(data: HoldingMenuModel) {
    this.holdingSubject.next(data);
  }

  getHoldingSelectModel(): Observable<HoldingMenuModel> {
    return this.holdingSubject.asObservable();
  }
}
