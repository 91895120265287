<div *ngIf="visible" class="feedback-box feedback-box-attention">
  <button (click)="onCancel()" class="icon-close"></button>
  <p class="feedback-message">
    {{ translationContext + '.message' | translate }}
  </p>
  <div class="action-btns">
    <button class="btn btn-primary is-medium text-uppercase mr-3" (click)="onCancel()">
      {{ translationContext + '.close' | translate }}
    </button>
    <button class="btn is-medium text-uppercase btn-secondary" (click)="onConfirm()">
      {{ translationContext + '.action' | translate }}
    </button>
  </div>
</div>
