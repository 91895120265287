import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpService {
  private requestSubject = new BehaviorSubject<boolean>(null);

  private requests = 0;

  pushRequest() {
    this.requests += 1;
    this.hasPendingRequests();
  }

  popRequest() {
    this.requests -= 1;
    this.hasPendingRequests();
  }

  hasPendingRequests() {
    this.requestSubject.next(this.requests > 0);
  }

  hasRequest() {
    return this.requestSubject.asObservable();
  }
}
