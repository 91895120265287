import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { systemType, TypeProductionEnum } from '../../models/system.model';

@Injectable({ providedIn: 'root' })
export class TypeProductionService {
  private name = new BehaviorSubject<TypeProductionEnum>(null);
  private id = new BehaviorSubject<string>(null);

  getObservable() {
    return this.name.asObservable().pipe(filter(v => v != null));
  }

  get() {
    return this.name.getValue();
  }

  getId() {
    return this.id.getValue();
  }

  set(typeProduction: TypeProductionEnum) {
    this.name.next(typeProduction);
    this.id.next(systemType[typeProduction]);
  }

  isDairy() {
    return this.get() === TypeProductionEnum.DAIRY;
  }

  isLayers() {
    return this.get() === TypeProductionEnum.LAYERS;
  }

  isPoultry() {
    return this.get() === TypeProductionEnum.POULTRY;
  }

  isSwine() {
    return this.get() === TypeProductionEnum.SWINES;
  }
}
