import { Search, TypeProductionService, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { Farm, FarmFilter, FarmForm } from '../models/farm.model';
import { FarmAbstractService } from './farm-abstract.service';

@Injectable()
export class FarmService extends FarmAbstractService {
  private pathBase = 'holdings/{holding_id}/farms/';
  private pathFarmId = 'holdings/{holding_id}/farms/{farm_id}/';
  private defaultPageSize = 10;

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {
    super();
  }

  list({
    page = 1,
    page_size = this.defaultPageSize,
    verbose = false,
    ...filters
  }: FarmFilter): Observable<Search<Farm>> {
    const url = this.apiCorpService.translateUrl(this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
    });
    const params = {
      ...filters,
      page,
      page_size,
      system_type: this.typeProductionService.get(),
      verbose: verbose,
    };
    return this.apiCorpService.get<Search<Farm>>(url, params);
  }

  update(farmForm: FarmForm, farmId: string): Observable<Farm> {
    const url = this.getUrl(farmId);
    const body = {
      id: farmId,
      ...farmForm,
    };
    const params = {
      system_type: this.typeProductionService.get(),
    };
    return this.apiCorpService.put<Farm>(url, body, params);
  }

  private getUrl(farmId?: string): string {
    return this.apiCorpService.translateUrl(farmId ? this.pathFarmId : this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
      farm_id: farmId,
    });
  }
}
