import { Routes, RoutesForm } from '@agriness/corp-app/services';
import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'route-editor',
  templateUrl: './route-editor.component.html',
  styleUrls: ['./route-editor.component.scss'],
})
export class RouteEditorComponent implements OnInit {
  @Input() visible = false;
  @Input() routesData: Routes;
  @Input() modalStatus = ModalStatusEnum.DEFAULT;

  @Output() closed = new EventEmitter<void>();
  @Output() createRoutes = new EventEmitter<void>();
  @Output() updateRoutes = new EventEmitter<void>();

  errorMessages = {
    name: '',
  };

  translationContext = 'agriness.settings.routes';

  routesForm = new FormGroup({
    name: new FormControl('', Validators.required.bind(this)),
  });

  constructor(@Inject(TRANSLATE_INSTANT) private t: TranslateInstant) {}

  ngOnInit(): void {
    this.updatedFormRoutes();
    this.listenValueChanges();
  }

  listenValueChanges(): void {
    for (const control in this.routesForm.controls) {
      this.routesForm.controls[control].valueChanges.subscribe(() => {
        const { errors } = this.routesForm.get(control);
        this.errorMessages[control] = errors ? this.t('agriness.settings.validation_required') : '';
      });
    }
  }

  onSubmit(): void {
    if (!this.routesData) {
      this.createRoutes.emit();
    } else {
      this.updateRoutes.emit();
    }
  }

  onClosed(): void {
    this.closed.emit();
  }

  updatedFormRoutes(): void {
    if (this.routesData) {
      const { name } = this.routesData;
      this.routesForm.patchValue({ name });
    }
  }

  getRoutesForm(): RoutesForm {
    return this.routesForm.getRawValue() as RoutesForm;
  }
}
