import { DateService, UserStorageService } from '@agriness/services';
import { AgrinessTranslateService } from '@agriness/services';
import { FeedbackEnum, AgCalendarService } from '@agriness/ui';
import { Component, OnDestroy } from '@angular/core';
import { ViewChild } from '@angular/core';
import { addDays, startOfWeek } from 'date-fns';
import { Subscription } from 'rxjs';

import { PresenceModalComponent } from './../presence-modal/presence-modal.component';
import { PresenceService } from './../presence.service';
import {
  CalendarDate,
  TechnicianCell,
  TechnicianRow,
  ScheduleCell,
} from './presence-calendar.model';

@Component({
  selector: 'corp-presence-calendar',
  templateUrl: './presence-calendar.component.html',
  styleUrls: ['./presence.styles.scss'],
})
export class PresenceCalendarComponent implements OnDestroy {
  @ViewChild('modal') modal: PresenceModalComponent;

  sectionCalendarTitle = 'agriness.presence.section_calendar_title';
  calendarColumnName = 'agriness.presence.calendar_column_name';

  filterDay: Date;
  firstDate: CalendarDate;
  lastDate: CalendarDate;

  dateFormat: string;
  language: string;

  holding_id: string;
  weeks: Array<CalendarDate[]> = [];
  rows: TechnicianRow[] = [];
  technicians: TechnicianCell[] = [];
  schedules: ScheduleCell[] = [];
  counter: number;
  subscription: Subscription;

  FeedbackEnum = FeedbackEnum;
  feedbackType = FeedbackEnum.LOADING;

  constructor(
    protected presenceService: PresenceService,
    protected userStorageService: UserStorageService,
    protected calendarService: AgCalendarService,
    protected dateService: DateService,
    private translate: AgrinessTranslateService,
  ) {
    this.dateFormat = dateService.getDateFormat();
    this.language = this.translate.getLanguage();
    this.holding_id = this.userStorageService.getCurrentHolding().id;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadCalendar(firstDate?: string, lastDate?: string, technician_id?: string): void {
    this.feedbackType = FeedbackEnum.LOADING;
    this.subscription?.unsubscribe();

    const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

    this.firstDate = new CalendarDate(new Date(firstDate).getTime() + timezoneOffset);
    this.lastDate = new CalendarDate(new Date(lastDate).getTime() + timezoneOffset);

    const days = this.getDaysOfWeek(this.lastDate);
    const [firstDay, lastDay] = this.getFirstAndLastDay(days);

    this.filterDay = this.lastDate;
    this.counter = 0;
    this.weeks = [days];

    const subscription = this.presenceService
      .getData(this.holding_id, firstDay, lastDay, technician_id)
      .subscribe(
        ([scheduleCells, technicianCells]) => {
          const schedules = scheduleCells;
          if (technician_id) {
            this.technicians = technicianCells.filter(technician =>
              technician_id.split(',').includes(technician.id),
            );
          } else {
            this.technicians = technicianCells;
          }
          if (!technicianCells.length) {
            this.feedbackType = FeedbackEnum.NOT_RESULT;
            return;
          }
          this.rows = this.parseDatesToRow(days, schedules);
          this.feedbackType = null;
        },
        () => {
          this.feedbackType = FeedbackEnum.ERROR;
        },
      );
    this.subscription = subscription;
  }

  onChangeDatepicker(event: CalendarDate[]): void {
    const week = event;
    this.feedbackType = FeedbackEnum.LOADING;

    const [firstDay, lastDay] = this.getFirstAndLastDay(week);
    this.filterDay = lastDay;

    const subscription = this.presenceService.getData(this.holding_id, firstDay, lastDay).subscribe(
      ([scheduleCells, technicianCells]) => {
        this.counter = 0;
        this.weeks = [week];
        const schedules = scheduleCells;
        if (!technicianCells.length) {
          this.feedbackType = FeedbackEnum.NOT_RESULT;
          return;
        }
        this.rows = this.parseDatesToRow(week, schedules);
        this.feedbackType = null;
      },
      () => {
        this.feedbackType = FeedbackEnum.ERROR;
      },
    );
    this.subscription = subscription;
  }

  getDaysOfWeek(date: CalendarDate): CalendarDate[] {
    const weekStart = startOfWeek(date);

    return new Array(7)
      .fill(weekStart)
      .map((date, index) => addDays(date, index))
      .map(date => new CalendarDate(date));
  }

  getDays(): CalendarDate[] {
    return this.weeks.flat();
  }

  canMove(direction: 'backward' | 'forward'): boolean {
    const firstDateOfVisibleList = new CalendarDate(this.weeks[this.counter][0]);
    const week = this.getDaysOfWeek(firstDateOfVisibleList);
    const [firstDay, lastDay] = this.getFirstAndLastDay(week);

    if (direction === 'forward') {
      if (!this.lastDate || !lastDay) {
        return false;
      }
      return true;
    }

    if (!this.firstDate || !firstDay) {
      return false;
    }
    return this.firstDate < firstDay;
  }

  move(counter: number, date: CalendarDate, direction: 'backward' | 'forward'): void {
    const newDate: CalendarDate = new CalendarDate(date);
    newDate.setDate(newDate.getDate() + (direction === 'forward' ? 1 : -1));
    const week = this.getDaysOfWeek(newDate);
    this.feedbackType = FeedbackEnum.LOADING;

    const [firstDay, lastDay] = this.getFirstAndLastDay(week);
    this.filterDay = lastDay;

    const subscription = this.presenceService
      .searchTechnicianGroupedSchedule(this.holding_id, firstDay, lastDay)
      .subscribe(
        schedules => {
          this.counter = direction === 'forward' ? counter : 0;
          this.weeks[direction === 'forward' ? 'push' : 'unshift'](week);
          this.rows = this.attachDatesToRow(schedules, week, direction === 'forward');
          this.feedbackType = null;
        },
        () => {
          this.feedbackType = FeedbackEnum.ERROR;
        },
      );
    this.subscription = subscription;
  }

  prev(): void {
    const counter: number = this.counter - 1;
    if (counter < 0) {
      const firstDateOfVisibleList = new CalendarDate(this.weeks[counter + 1][0]);
      this.move(counter, firstDateOfVisibleList, 'backward');
    } else {
      this.counter = counter;
    }
  }

  next(): void {
    const counter: number = this.counter + 1;
    if (counter > this.weeks.length - 1) {
      const lastDateOfVisibleList = new CalendarDate(this.weeks[counter - 1].slice(-1)[0]);
      this.move(counter, lastDateOfVisibleList, 'forward');
    } else {
      this.counter = counter;
    }
  }

  parseDatesToRow(days: CalendarDate[], schedules: ScheduleCell[]): TechnicianRow[] {
    return this.technicians.map(technician => {
      const schedulesCells = this.parseDaysAndTechnicianToScheduleCell(
        days,
        schedules,
        technician.user_id,
        technician.id,
      );
      return new TechnicianRow(technician.id, technician.user_id, schedulesCells);
    });
  }

  parseDaysAndTechnicianToScheduleCell(
    days: CalendarDate[],
    schedules: ScheduleCell[],
    technician_user_id: string,
    technician_id: string,
  ): ScheduleCell[] {
    return days.map(day => {
      const scheduleCell: ScheduleCell = schedules.filter(
        schedule =>
          schedule.date.getFullYear() === day.getFullYear() &&
          schedule.date.getMonth() === day.getMonth() &&
          schedule.date.getDate() === day.getDate() &&
          schedule.technician_user_id === technician_user_id,
      )[0];
      if (scheduleCell) {
        scheduleCell.technician = technician_id;
        return scheduleCell;
      }
      return new ScheduleCell({
        date: day,
        technician: technician_id,
        technician_user_id: technician_user_id,
      });
    });
  }

  attachDatesToRow(schedules: ScheduleCell[], days: CalendarDate[], next = true): TechnicianRow[] {
    return this.rows.map(technician => {
      const scheduleDays = this.parseDaysAndTechnicianToScheduleCell(
        days,
        schedules,
        technician.user_id,
        technician.id,
      );
      next ? technician.cells.push(...scheduleDays) : technician.cells.unshift(...scheduleDays);
      return technician;
    });
  }

  getCellBackgroundClass(cell: ScheduleCell): Record<string, boolean> {
    const isWeekend = cell.date.isWeekend();
    return {
      'calendar-cell--bg-gray': isWeekend,
      'calendar-cell--bg-light-gray': !isWeekend && cell.total === 0,
      'calendar-cell--bg-light-purple': !isWeekend && cell.total > 0 && cell.total <= 2,
      'calendar-cell--bg-purple': !isWeekend && cell.total > 2 && cell.total <= 4,
      'calendar-cell--bg-dark-purple': !isWeekend && cell.total > 4,
    };
  }

  private getFirstAndLastDay<CalendarDate>(data: CalendarDate[]): [CalendarDate, CalendarDate] {
    return [data[0], data[data.length - 1]];
  }
}
