import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ag-time-field',
  templateUrl: './ag-time-field.component.html',
})
export class AgTimeFieldComponent implements OnChanges, OnDestroy {
  @Input() group: FormGroup;
  @Input() controlName: string;
  @Input() inputId: string;
  @Input() label: string;

  formSubscription = Subscription.EMPTY;
  value: Date;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group && this.group) {
      this.formSubscription.unsubscribe();

      this.value = this.getValueFromForm();
      this.updateFormValue();

      this.formSubscription = this.getValueControl().valueChanges.subscribe(() => {
        this.value = this.getValueFromForm();
      });
    }
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

  onChange() {
    this.updateFormValue();
  }

  private getValueFromForm(): Date {
    const formValue = this.getFormRawValue();
    if (!formValue) {
      return null;
    }
    const valueParts = formValue.split(':');
    if (valueParts.length < 2) {
      return null;
    }

    const [hours, minutes] = valueParts;
    const date = new Date();
    date.setHours(Number(hours), Number(minutes));
    return date;
  }

  private updateFormValue() {
    let newValue: string = null;
    if (this.value) {
      const hours = `${this.value.getHours()}`.padStart(2, '0');
      const minutes = `${this.value.getMinutes()}`.padStart(2, '0');
      newValue = `${hours}:${minutes}`;
    }

    const currentValue = this.getFormRawValue();
    if (currentValue !== newValue) {
      this.getValueControl().patchValue(newValue);
    }
  }

  private getValueControl(): AbstractControl {
    return this.group.get(this.controlName);
  }

  private getFormRawValue(): string {
    const formValue = this.getValueControl().value as string | string[];
    return Array.isArray(formValue) ? formValue[0] : formValue;
  }
}
