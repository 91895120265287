import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { LocaleSettings } from 'primeng/calendar';

import { CalendarDateFormat } from '../ag-calendar.model';
import { AgCalendarService } from '../ag-calendar.service';
import { CalendarDate } from './ag-week-calendar.model';

@Component({
  selector: 'ag-week-calendar',
  templateUrl: './ag-week-calendar.component.html',
  styleUrls: ['./ag-week-calendar.styles.scss'],
})
export class AgWeekCalendarComponent implements OnInit, OnChanges {
  @Input() day: Date;
  @Input() language: string;
  @Input() dateFormat: string;
  @Input() labelDateFormat: string;

  @Output() select = new EventEmitter<any>();

  calendarLocale: LocaleSettings;
  calendarDateFormat: CalendarDateFormat;

  days: CalendarDate[];
  values: CalendarDate[];
  weeks: Array<CalendarDate[]>;

  constructor(protected calendarService: AgCalendarService, protected datePipe: DatePipe) {}

  ngOnChanges(): void {
    this.days = this.getDaysOfWeek(new CalendarDate(this.day));
    this.weeks = [this.days];
    this.values = [this.days[0], this.days[this.days.length - 1]];
    this.calendarLocale = this.calendarService.getPrimeNgCalendarLocale(this.language);
    this.calendarDateFormat = this.parseDateFormatToCalendar();
  }

  ngOnInit() {}

  weekToString(week: CalendarDate[]): string {
    if (week == null) {
      return '';
    }
    const { 0: sunday, [week.length - 1]: saturday } = week;

    const sundayText = this.datePipe.transform(
      sunday,
      this.labelDateFormat,
      undefined,
      this.language,
    );
    const saturdayText = this.datePipe.transform(
      saturday,
      this.labelDateFormat,
      undefined,
      this.language,
    );

    return `${sundayText} - ${saturdayText}`;
  }

  getDaysOfWeek(date: CalendarDate): CalendarDate[] {
    const week: CalendarDate[] = [];

    for (let i = 0; i <= 6; i++) {
      const first = date.getDate() - date.getDay() + i;
      const day = new CalendarDate(date.setDate(first));
      week.push(day);
    }

    return week;
  }

  onChangeDatepicker(event: CalendarDate): void {
    const week = this.getDaysOfWeek(event);
    this.weeks = [week];
    this.values = [week[0], week[week.length - 1]];

    this.select.emit(week);
  }

  private parseDateFormatToCalendar(): CalendarDateFormat {
    const dateFormat = this.dateFormat
      ? this.dateFormat.replace('yyyy', 'yy').toLowerCase()
      : this.calendarLocale.dateFormat;

    return dateFormat as CalendarDateFormat;
  }
}
