<p-dialog
  [(visible)]="display"
  styleClass="dialog"
  maskStyleClass="mask"
  [modal]="true"
  [dismissableMask]="true"
>
  <p-header>
    <div>
      <span class="icon icon-feed is-24 feed"></span>
      {{
        !isEditing()
          ? (translationContext + '.title.new_diet' | translate)
          : (translationContext + '.title.edit_diet' | translate)
      }}
      - {{ animalGroup?.name || animalGroup?.animal_group_name }}
    </div>
  </p-header>

  <ag-feedback [type]="feedbackType" position="center-parent"></ag-feedback>

  <ng-container *ngIf="!feedbackType && !modalStatus">
    <div class="table">
      <diet-selection-table
        data-testid="diet-selection-table"
        *ngIf="!feedbackType"
        [data]="performances"
      ></diet-selection-table>
    </div>
    <div>
      <diet-selection-form
        #form
        data-testid="diet-selection-form"
        [options]="dietOptions"
        [isEditing]="isEditing()"
        [currentAge]="this.currentAge"
        [stage]="stage"
        [animalGroupId]="animalGroup?.id"
      ></diet-selection-form>
    </div>
  </ng-container>

  <ng-container *ngIf="modalStatus">
    <diet-selection-status-component
      data-testid="diet-selection-status"
      [status]="modalStatus"
      [isEditing]="isEditing()"
      (back)="cancelSaving()"
      (cancel)="closeDialog()"
    >
    </diet-selection-status-component>
  </ng-container>

  <p-footer *ngIf="!feedbackType && !modalStatus">
    <button
      data-testid="close-modal-button"
      class="btn btn-ghost-light is-medium mr-3"
      (click)="closeDialog()"
    >
      {{ translationContext + '.buttons.close' | translate }}
    </button>
    <ng-container *ngIf="!this.animalGroupList?.length; then save; else saveAndContinue">
    </ng-container>
    <ng-template #save>
      <button
        data-testid="save-diet-button"
        class="btn btn-primary is-medium"
        [disabled]="feedbackType"
        (click)="saveDiet()"
      >
        {{ translationContext + '.buttons.save' | translate }}
      </button>
    </ng-template>
    <ng-template #saveAndContinue>
      <button
        data-testid="save-diet-button"
        class="btn btn-primary is-medium"
        [disabled]="feedbackType"
        (click)="saveDiet()"
      >
        {{ translationContext + '.buttons.continue' | translate }}
      </button>
    </ng-template>
  </p-footer>
</p-dialog>
