import { UserStorageService, TypeProductionService, Search } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { ChecklistFilter, Checklist, ChecklistForm } from '../models/checklists.model';
import { ChecklistAbstractService } from './checklist-abstract.service';

@Injectable({
  providedIn: 'root',
})
export class ChecklistsService extends ChecklistAbstractService {
  private pathBase = 'holdings/{holding_id}/checklist/';
  private pathChecklistId = 'holdings/{holding_id}/checklist/{checkist_id}/';
  private defaultPageSize = 10;

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {
    super();
  }

  list({
    page = 1,
    page_size = this.defaultPageSize,
    queryCorpOnly = false,
    ...filters
  }: ChecklistFilter): Observable<Search<Checklist>> {
    const url = this.getUrl();
    const params = {
      ...filters,
      page,
      page_size,
      system_type: this.typeProductionService.getId(),
      query_corp_only: queryCorpOnly,
    };
    return this.apiCorpService.get<Search<Checklist>>(url, params);
  }

  save(form: ChecklistForm): Observable<Checklist> {
    const url = this.getUrl();
    const params = {
      ...form,
    };

    return this.apiCorpService.post<Checklist>(url, params);
  }

  update(form: ChecklistForm, checklistId: string): Observable<Checklist> {
    const url = this.getUrl(checklistId);
    const params = {
      ...form,
    };

    return this.apiCorpService.put<Checklist>(url, params);
  }

  delete(checklist: Checklist): Observable<void> {
    const url = this.getUrl(checklist.id);
    return this.apiCorpService.delete(url);
  }

  private getUrl(checklistId?: string): string {
    return this.apiCorpService.translateUrl(checklistId ? this.pathChecklistId : this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
      checkist_id: checklistId,
    });
  }
}
