import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AgQueueManagerComponent } from './ag-queue-manager.component';

@NgModule({
  declarations: [AgQueueManagerComponent],
  imports: [CommonModule, OverlayModule],
  entryComponents: [AgQueueManagerComponent],
})
export class AgQueueManagerModule {}
