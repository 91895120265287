<div class="card-wrapper">
  <a *ngIf="clickable; else baseTemplate">
    <ng-container *ngTemplateOutlet="baseTemplate"></ng-container>
  </a>
</div>

<ng-template #baseTemplate>
  <div class="value-card">
    <ng-container *ngIf="!!cardTemplate; then cardTemplate; else defaultCardTemplate">
    </ng-container>
  </div>
</ng-template>

<ng-template #defaultCardTemplate>
  <ng-container *ngIf="!!titleTemplate; then titleTemplate"></ng-container>
  <div *ngIf="!!titleText && !titleTemplate">
    <div>
      <h6 class="title">{{ titleText }}</h6>
    </div>
  </div>

  <ng-container *ngIf="!!contentTemplate; then contentTemplate"></ng-container>
  <div *ngIf="!!contentText && !contentTemplate">
    <div class="content">
      <p>{{ contentText }}</p>
    </div>
  </div>

  <agriness-icon [icon]="iconName" [size]="iconSize" [color]="iconColor"></agriness-icon>
</ng-template>
