import { TypeProductionService } from '@agriness/services';
import { AgrinessTranslateModule } from '@agriness/services';
import { Injectable, NgModule } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

type JsonImport = Promise<Record<string, unknown>>;

@Injectable()
export class CorpTranslateLoader implements TranslateLoader {
  constructor(private typeProductionService: TypeProductionService) {}

  getTranslation(lang: string): Observable<Record<string, unknown>> {
    return this.typeProductionService.getObservable().pipe(
      tap(typeProduction => console.log(`Downloading '${lang}' translation for ${typeProduction}`)),
      switchMap(
        typeProduction => import(`../assets/i18n/${typeProduction}/${lang}.json`) as JsonImport,
      ),
    );
  }
}

@NgModule({
  imports: [
    AgrinessTranslateModule.forRoot({
      loader: { provide: TranslateLoader, useExisting: CorpTranslateLoader },
    }),
  ],
  providers: [CorpTranslateLoader],
})
export class AppTranslateModule {}
