import { AgrinessTranslateService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { LocaleSettings } from 'primeng/calendar';

import { calendarLocaleByLanguage } from './ag-calendar.model';

@Injectable()
export class AgCalendarService {
  constructor(private translateService: AgrinessTranslateService) {}

  getPrimeNgCalendarLocale(language = null): LocaleSettings {
    language = language || this.translateService.getLanguage();
    return calendarLocaleByLanguage[language];
  }

  suggest(value: string, dateFormat: string): string {
    const splitAt = dateFormat.replace(/[a-z]/g, '').charAt(0);
    const dateFormatParts = dateFormat.split(splitAt);
    const valueParts = this.dateAsArray(value, dateFormatParts[0] === 'yy');
    const today = new Date();
    return dateFormatParts
      .map((dateFormatPart, i) => this.suggestPart(valueParts[i], dateFormatPart, today))
      .join(splitAt);
  }

  private suggestPart(val: string, dateFormatPart: string, baseDate: Date): number {
    const options = {
      dd: day => day || baseDate.getDate(),
      mm: month => month || baseDate.getMonth() + 1,
      yy: year => {
        if (year && year.length === 2) {
          return '20' + year;
        } else {
          return year || baseDate.getFullYear();
        }
      },
    };
    return options[dateFormatPart](val);
  }

  private dateAsArray(value: string, yearFirst: boolean): string[] {
    if (yearFirst) {
      return [
        value.match(/\d{1,4}/g).shift(),
        ...(value.length > 4 ? value.substring(4).match(/\d{1,2}/g) : []),
      ];
    }
    const [p1 = '', p2 = '', ...p3] = value.match(/\d{1,2}/g) || [];
    return [p1, p2, p3.join('')];
  }
}
