export enum TargetStatus {
  BAD = 'bad',
  ATTENTION = 'attention',
  GOOD = 'good',
}

export enum OperatorsEnum {
  LOWER_THEN = '<',
  LOWER_THEN_OR_EQUAL = '≤',
  HIGHER_THEN = '>',
  HIGHER_THEN_OR_EQUAL = '≥',
}

export enum RepresentationTypeEnum {
  RANGE_BAD_ATTENTION_GOOD = 'RANGE_BAD_ATTENTION_GOOD',
  RANGE_BAD_GOOD = 'RANGE_BAD_GOOD',
  SCALE_ATTENTION_GOOD_BAD = 'SCALE_ATTENTION_GOOD_BAD',
  SCALE_BAD_ATTENTION_GOOD = 'SCALE_BAD_ATTENTION_GOOD',
  SCALE_BAD_GOOD = 'SCALE_BAD_GOOD',
  SCALE_GOOD_ATTENTION_BAD = 'SCALE_GOOD_ATTENTION_BAD',
}

export interface TargetData {
  target_value: number | null;
  value_variation_rate: number | null;
  min_allowed: number | null;
  max_allowed: number | null;
  min_recommended: number | null;
  max_recommended: number | null;
  representation_type: string;
  index: {
    key: string | null;
    decimal_places: number | null;
    measurement_unit: string | null;
  };
}
