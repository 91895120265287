import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { AgFormatModule } from '../format/ag-format.module';
import { AgLinkModule } from '../link/ag-link.module';
import { AgTargetIndicatorModule } from '../target/target-indicator/ag-target-indicator.module';
import { AgStatusTargetRepresentationComponent } from '../target/target-representation/ag-status-target-representation.component';
import { AgStatusCardComponent } from './card/ag-status-card.component';
import { AgStatusHeaderComponent } from './header/ag-status-header.component';
import { AgHorizontalStatusBarComponent } from './horizontal-bar/ag-horizontal-status-bar.component';
import { AgStatusLegendComponent } from './legend/ag-status-legend.component';

@NgModule({
  imports: [AgFormatModule, AgLinkModule, AgTargetIndicatorModule, SharedModule],
  declarations: [
    AgHorizontalStatusBarComponent,
    AgStatusLegendComponent,
    AgStatusCardComponent,
    AgStatusHeaderComponent,
    AgStatusTargetRepresentationComponent,
  ],
  exports: [
    AgHorizontalStatusBarComponent,
    AgStatusLegendComponent,
    AgStatusCardComponent,
    AgStatusHeaderComponent,
    AgStatusTargetRepresentationComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgStatusModule {}
