import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { TableColumn } from '@agriness/ui';
import { Inject, Injectable } from '@angular/core';

import {
  TableDisplayData,
  TableDisplayDataItem,
  TableDisplayInput,
} from './report-section-table.model';

@Injectable()
export class ReportSectionTableService {
  constructor(@Inject(TRANSLATE_INSTANT) private t: TranslateInstant) {}

  asDisplayData(options: TableDisplayInput): TableDisplayData {
    const columns = this.getColumns(options);
    const data = this.getData(options, columns);
    return {
      hasData: data.length > 0,
      columns: columns,
      data: data,
      downloadFilename: options.configuration.downloadFilename,
    };
  }

  private getColumns({ configuration }: TableDisplayInput): TableColumn[] {
    const { order, byField } = configuration.fields.columns;
    return order
      .map(field => {
        const columnConfig = byField[field];
        if (!columnConfig) {
          return null;
        }
        const result: TableColumn = {
          ...columnConfig,
          id: columnConfig.id || field,
          field: `column_${field}`,
          header: this.t(columnConfig.header),
        };
        return result;
      })
      .filter(c => c != null);
  }

  private getData({ data }: TableDisplayInput, columns: TableColumn[]): TableDisplayDataItem[] {
    const fieldMapping = columns.map(({ field }) => ({
      dataField: field.replace('column_', ''),
      resultField: field,
    }));

    const result: TableDisplayDataItem[] = [];
    for (const { columns: columnsData } of data) {
      const resultItem: TableDisplayDataItem = {};
      for (const { dataField, resultField } of fieldMapping) {
        resultItem[resultField] = columnsData[dataField];
      }
      result.push(resultItem);
    }
    return result;
  }
}
