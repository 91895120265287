import { SectionModel, SectionService } from '@agriness/ui';
import { Component } from '@angular/core';

import { SectionAbstractComponent } from '../shared/component/section-abstract.component';

@Component({
  templateUrl: './record.component.html',
})
export class RecordComponent extends SectionAbstractComponent {
  constructor(protected sectionService: SectionService) {
    super(sectionService);
  }

  protected getSections(): SectionModel[] {
    return [];
  }
}
