import { StageEnum } from '@agriness/services';

export interface AnimalGroupTab {
  router: string;
  translateKey: string;
  type?: string;
}

const overview: AnimalGroupTab = {
  router: 'overview',
  translateKey: 'agriness.resume',
};
const movement: AnimalGroupTab = {
  router: 'movement',
  translateKey: 'agriness.movements',
};
const nutrition: AnimalGroupTab = {
  router: 'nutrition',
  translateKey: 'agriness.nutrition.title',
};
const sanity: AnimalGroupTab = {
  router: 'sanity',
  translateKey: 'agriness.sanity.title',
};
const comfort: AnimalGroupTab = {
  router: 'comfort',
  translateKey: 'agriness.comforts',
};

const performance: AnimalGroupTab = {
  router: 'performance',
  translateKey: 'agriness.performance',
};

export const defaultAnimalGroupTabs: AnimalGroupTab[] = [
  overview,
  movement,
  nutrition,
  sanity,
  comfort,
];

export const animalGroupTabsByStage: { [k in StageEnum]?: AnimalGroupTab[] } = {
  [StageEnum.NURSERY]: [...defaultAnimalGroupTabs],
  [StageEnum.FINISHING]: [...defaultAnimalGroupTabs],
  [StageEnum.WEAN_TO_FINISH]: [...defaultAnimalGroupTabs],
  [StageEnum.GROW_OUT]: [...defaultAnimalGroupTabs],
  [StageEnum.LAYERS_PRODUCTION]: [overview, performance],
};
