import {
  Checklist,
  ChecklistForm,
  ExternalPresenceStageID,
  ruleEnum,
} from '@agriness/corp-app/services';
import { StageSelect } from '@agriness/corp-app/settings/payment-rule-section/payment-rule/rule-editor/step-main/step-main.component';
import {
  TRANSLATE_INSTANT,
  TranslateInstant,
  StageEnum,
  TypeProductionService,
  SiteSystemType,
} from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

export interface ChecklistItem {
  name: string;
  score: number;
  id?: string;
  enabled?: boolean;
}

export interface selectData {
  name: string;
  key: string;
}

@Component({
  selector: 'checklist-editor',
  templateUrl: './checklist-editor.component.html',
  styleUrls: ['./checklist-editor.component.scss'],
})
export class ChecklistEditorComponent implements OnInit {
  @Input() visible = false;
  @Input() checklistData: Checklist;
  @Input() modalStatus = ModalStatusEnum.DEFAULT;

  @Output() closed = new EventEmitter<void>();
  @Output() createChecklist = new EventEmitter<void>();
  @Output() updateChecklist = new EventEmitter<void>();

  errorMessages = {
    name: '',
    stage_ids: '',
    start_days: '',
    deadline_rule: '',
    deadline_days: '',
    score: '',
  };

  translationContext = 'agriness.settings.presence.tools.tabs.checklist.editor';

  checklistForm = new FormGroup({
    name: new FormControl('', Validators.required.bind(this)),
    stage_ids: new FormControl(null),
    start_days: new FormControl(null),
    deadline_rule: new FormControl(null),
    deadline_days: new FormControl(null),
  });

  checklistItems = new FormArray([]);
  checklistItemsEditor = new FormArray([]);

  checklistItem = new FormGroup({
    name: new FormControl('', Validators.required.bind(this)),
    score: new FormControl(null, [Validators.required.bind(this), Validators.min(1)]),
  });

  stageSelectData: BehaviorSubject<StageSelect[]>;
  recurrencySelectData: BehaviorSubject<selectData[]>;
  ruleTypeSelectData: BehaviorSubject<selectData[]>;

  selectedStages = [];

  selectedRuleType: string[];
  selectedStagesEdit: string[];
  deadlineRuleIsValid = true;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private typeProductionService: TypeProductionService,
  ) {}

  ngOnInit(): void {
    this.addChecklistItem();
    this.setStageOptions();
    this.setRuleTypeOptions();
    this.updatedFormChecklist();
    this.listenValueChanges();

    this.checklistForm.get('deadline_rule').valueChanges.subscribe((value: string) => {
      if (value === '1') {
        this.deadlineRuleIsValid =
          this.checklistForm.get('start_days').value ||
          this.checklistForm.get('deadline_days').value
            ? true
            : false;

        this.checklistForm.get('start_days').valueChanges.subscribe(() => {
          this.deadlineRuleIsValid =
            this.checklistForm.get('start_days').value ||
            this.checklistForm.get('deadline_days').value
              ? true
              : false;
        });
        this.checklistForm.get('deadline_days').valueChanges.subscribe(() => {
          this.deadlineRuleIsValid =
            this.checklistForm.get('start_days').value ||
            this.checklistForm.get('deadline_days').value
              ? true
              : false;
        });
      }
    });
  }

  listenValueChanges(): void {
    for (const control in this.checklistForm.controls) {
      this.checklistForm.controls[control].valueChanges.subscribe(() => {
        const { errors } = this.checklistForm.get(control);
        this.errorMessages[control] = errors ? this.t('agriness.settings.validation_required') : '';
      });
    }
  }

  onSubmit(): void {
    if (!this.checklistData) {
      this.createChecklist.emit();
    } else {
      this.updateChecklist.emit();
    }
  }

  onClosed(): void {
    this.closed.emit();
  }

  updatedFormChecklist(): void {
    if (this.checklistData) {
      const { name, stage, deadline_days, start_days, deadline_rule } = this.checklistData;

      stage.forEach(item => {
        this.selectedStages.push(item.external_id);
      });

      this.checklistForm.patchValue({
        name,
        stage_ids: this.selectedStages,
        deadline_days,
        start_days,
        deadline_rule,
      });
      this.selectedRuleType = [deadline_rule.toString()];

      this.checklistForm.controls['deadline_rule'].setValue(deadline_rule.toString());
      this.checklistForm.controls['stage_ids'].setValue(this.selectedStages);
    }
  }

  getChecklistForm(): unknown {
    let data = {};
    const form = this.checklistForm.getRawValue() as ChecklistForm;
    const formArray = [
      ...(this.checklistItems.getRawValue() as ChecklistItem[]),
      ...(this.checklistItemsEditor.getRawValue() as ChecklistItem[]),
    ];

    data = { ...form, items: formArray };

    return data;
  }

  setStageOptions(): void {
    const data: StageSelect[] = [];
    const type_production = SiteSystemType[this.typeProductionService.get()];
    Object.values(type_production).forEach(val => {
      const key = Object.keys(ExternalPresenceStageID).find(k => k === val) as StageEnum;

      if (val !== StageEnum.WEAN_TO_FINISH) {
        data.push({ name: this.t('agriness.stage.' + val), key: ExternalPresenceStageID[key] });
      }
    });

    this.stageSelectData = new BehaviorSubject(data);
  }

  setRuleTypeOptions(): void {
    const data: selectData[] = [];
    const rule_type = ruleEnum;

    Object.values(rule_type).forEach(val => {
      if (val === 1) {
        data.push({
          name: this.t(this.translationContext + '.fields.rules.type.' + val.toString()),
          key: val.toString(),
        });
      }
    });

    this.ruleTypeSelectData = new BehaviorSubject(data);
  }

  addChecklistItem(): void {
    if (this.checklistData?.items.length > 0) {
      this.updatedChecklistItems();
    } else {
      this.checklistItems.push(this.checklistItem);
    }
  }

  addItem(): void {
    const checklist = this.checklistData ? this.checklistItemsEditor : this.checklistItems;
    checklist.push(
      new FormGroup({
        name: new FormControl('', Validators.required.bind(this)),
        score: new FormControl(null, [Validators.required.bind(this), Validators.min(1)]),
        enabled: new FormControl(true),
      }),
    );
  }

  cantRemove(): boolean {
    return this.checklistData ? false : this.checklistItems.length <= 1;
  }

  remove(index: number): void {
    const checklist = this.checklistData ? this.checklistItemsEditor : this.checklistItems;
    if (this.cantRemove()) {
      return;
    }
    checklist.removeAt(index);
  }

  updatedChecklistItems(): void {
    this.checklistData.items.forEach(item => {
      this.checklistItems.push(
        new FormGroup({
          name: new FormControl(item.name, Validators.required.bind(this)),
          score: new FormControl(item.score, [Validators.required.bind(this), Validators.min(1)]),
          id: new FormControl(item.id),
          enabled: new FormControl(item.enabled),
        }),
      );
    });
  }
}
