import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { AgCardModule } from '../card/ag-card.module';
import { AgGridModule } from '../grid/ag-grid.module';
import { AgLineModule } from '../line/ag-line.module';
import { AgDiagnosticTreeComponent } from './ag-diagnostic-tree.component';
import { AgTreeComponent } from './ag-tree.component';

@NgModule({
  declarations: [AgTreeComponent, AgDiagnosticTreeComponent],
  imports: [CommonModule, SharedModule, AgLineModule, AgGridModule, AgCardModule],
  exports: [AgTreeComponent, AgDiagnosticTreeComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgTreeModule {}
