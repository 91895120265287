<div *ngFor="let item of statusItems">
  <div class="d-flex">
    <p *ngIf="item.label" [attr.data-testid]="'ag-status-legend-title-' + item.status" class="text">
      {{ item.label }}:
    </p>
    <ag-link
      *ngIf="item.route; else WithouLink"
      [query]="item.queryParams"
      [route]="item.route"
      class="ml-1"
      iconSize="10px"
      [attr.data-testid]="'ag-status-legend-percentage-link-' + item.status"
    >
      <ag-format
        [attr.data-testid]="'ag-status-legend-percentage-' + item.status"
        [decimalPlaces]="item.decimalPlaces"
        [style]="item.measurementUnit"
        [value]="item.percentage"
      >
      </ag-format>
    </ag-link>
    <ng-template #WithouLink>
      <p
        class="ml-1 text"
        [attr.data-testid]="'ag-status-legend-percentage-without-link-' + item.status"
      >
        <ag-format
          [attr.data-testid]="'ag-status-legend-percentage-' + item.status"
          [decimalPlaces]="item.decimalPlaces"
          [style]="item.measurementUnit"
          [value]="item.percentage"
        >
        </ag-format>
      </p>
    </ng-template>
  </div>
</div>
