<agriness-card
  title="{{ getIndexName(target) }} {{ getMeasurementUnit(target) }}"
  [contentTemplate]="contentTemplate"
  icon="edit"
  iconSize="24"
  clickable
>
</agriness-card>

<ng-template #contentTemplate>
  <ag-target-subtitles [showMeasurementUnit]="false" [targetData]="target"></ag-target-subtitles>
</ng-template>
