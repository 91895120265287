import { StageEnum } from '@agriness/services';
import { Observable } from 'rxjs';

import { ProductionParam, ProductionParamTranslate } from '../models/production-params.model';

export abstract class ProductionParamsAbstractService {
  abstract get(stage: StageEnum): Observable<ProductionParam[]>;
  abstract save(form: ProductionParam[], stage: StageEnum): Observable<ProductionParam[]>;
  abstract fetchParamsTranslate(): Observable<ProductionParamTranslate>;
  abstract getParamsTranslate(): ProductionParamTranslate;
}
