import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: environment.sentryDsn,
    attachStacktrace: true,
    release: environment.version,
    environment: environment.environment,
    beforeBreadcrumb: breadcrumb => {
      const url = breadcrumb.data?.url as string;
      if (breadcrumb.category === 'xhr' && url && url.includes('amplitude.com')) {
        return null;
      }
      return breadcrumb;
    },
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    preserveWhitespaces: true,
  })
  .catch(err => console.error(err));
