<div class="ag-layout-sidebar">
  <ul class="ag-menu-level-1">
    <li *ngFor="let item of itensSideMenu" [title]="item.name | translate">
      <ng-container *ngIf="item.subItem?.length; else elseBlock">
        <a
          [id]="'side-' + item.id"
          class="dropdown-toggle"
          [ngClass]="{ active: rla.isActive, collapsed: !item.show ? !rla.isActive : false }"
          (click)="rla.isActive ? '' : click(item)"
        >
          <ng-container *ngTemplateOutlet="menuItem; context: { $implicit: item }"></ng-container>
        </a>
        <ul
          class="ag-menu-level-2"
          routerLinkActive="show"
          [ngClass]="{ collapse: !item.show }"
          #rla="routerLinkActive"
        >
          <li *ngFor="let subItem of item.subItem" [title]="subItem?.name | translate">
            <a
              *ngIf="subItem"
              [routerLink]="subItem.link"
              [id]="'side-' + subItem?.id"
              routerLinkActive="active"
            >
              <ng-container
                *ngTemplateOutlet="menuItem; context: { $implicit: subItem }"
              ></ng-container>
            </a>
          </li>
        </ul>
      </ng-container>

      <ng-template #elseBlock>
        <a [routerLink]="item.link" [id]="'side-' + item.id" routerLinkActive="active">
          <ng-container *ngTemplateOutlet="menuItem; context: { $implicit: item }"></ng-container>
        </a>
      </ng-template>
    </li>
  </ul>
</div>

<ng-template #menuItem let-item>
  <span class="icon" [ngClass]="item.iconClass"></span>
  <span class="ag-menu-label">{{ item.name | translate }}</span>
</ng-template>
