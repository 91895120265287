import {
  AnimalGroup,
  AnimalGroupAbstractService,
  AnimalGroupMortality,
} from '@agriness/corp-app/services';
import { UserStorageService, TypeProductionEnum } from '@agriness/services';
import { FeedbackEnum } from '@agriness/ui';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { LoaderUserPreference } from '../../../shared/component/loader-user-preference';
import { AnimalGroupStateService } from '../animal-group-state.service';

@Component({
  templateUrl: './sanity.component.html',
})
export class SanityComponent extends LoaderUserPreference implements OnInit, OnDestroy {
  typeProduction = environment.typeProduction;
  TypeProduction = TypeProductionEnum;

  animalGroup: AnimalGroup;
  mortality: AnimalGroupMortality;
  typeFeedback = FeedbackEnum.LOADING;
  subscription = Subscription.EMPTY;

  constructor(
    protected userStorageService: UserStorageService,
    private animalService: AnimalGroupAbstractService,
    private animalGroupStateService: AnimalGroupStateService,
  ) {
    super(userStorageService);
  }

  ngOnInit(): void {
    this.typeFeedback = FeedbackEnum.LOADING;
    this.subscription = this.animalGroupStateService
      .getAnimalGroup()
      .pipe(
        switchMap(animalGroup => {
          return forkJoin([of(animalGroup), ...this.getObservables(animalGroup)]);
        }),
        tap(([animalGroup, mortality]) => {
          this.animalGroup = animalGroup as AnimalGroup;
          this.mortality = mortality as AnimalGroupMortality;

          this.typeFeedback = !this.hasData() ? FeedbackEnum.NOT_RESULT : null;
        }),
        catchError(err => {
          this.typeFeedback = FeedbackEnum.ERROR;
          return throwError(err);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  hasData(): boolean {
    return this.mortality != null;
  }

  private getObservables(animalGroup: AnimalGroup): Observable<AnimalGroupMortality>[] {
    return [this.subMortality(animalGroup)];
  }

  private subMortality(animalGroup: AnimalGroup): Observable<AnimalGroupMortality> {
    return this.animalService.getAnimalGroupSanityMortality(
      environment.typeProduction,
      this.holdingId,
      animalGroup.farm.id,
      animalGroup.id,
    );
  }
}
