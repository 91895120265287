import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';

import { ValueFormatStyle } from '../../pipe/value-format/value-format.model';
import { CardModel } from '../card/card.model';
import { LineModel } from './line.model';
import { NodeModel } from './node.model';
import { RelationModel } from './relation.model';
import { NodeVector } from './vector.model';

@Component({
  selector: 'ag-diagnostic-tree',
  templateUrl: './ag-diagnostic-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgDiagnosticTreeComponent implements OnChanges {
  @Input() cards: CardModel[];
  @Input() relations: RelationModel[];
  @Input() cardPivots: { [kpi: string]: NodeVector };
  @Input() cols: number;
  @Input() rows: number;

  nodes: NodeModel[];
  nodeSize: NodeVector = { row: 14, col: 18 };
  lines: LineModel[];

  private cardMap: { [kpi: string]: CardModel } = {};

  ngOnChanges() {
    this.cards.forEach(card => (this.cardMap[card.id] = card));
    this.nodes = Object.entries(this.cardPivots).map<NodeModel>(([kpi, _]) => this.buildNode(kpi));
    this.lines = this.relations.map<LineModel>(relation => this.buildLine(relation));
  }

  private buildNode(kpi: string): NodeModel {
    return { card: this.buildCard(kpi), position: this.positionPivot(this.cardPivots[kpi]) };
  }

  private buildCard(kpi = ''): CardModel {
    return this.cardMap[kpi] || this.defaultCard(kpi);
  }

  private defaultCard(defaultName = 'agriness.generic_kpi.decimal'): CardModel {
    return {
      name: defaultName,
      style: ValueFormatStyle.DECIMAL,
      value: 0,
      decimal_places: 3,
    };
  }

  private buildLine(relation: RelationModel): LineModel {
    const fromCard: NodeVector = this.cardPivots[relation.fromKPI];
    const toCard: NodeVector = this.cardPivots[relation.toKPI];

    const start = {
      row: fromCard.row - (relation.isVertical ? 0 : 7),
      col: fromCard.col - (relation.isVertical ? 9 : 0),
    };
    const end = {
      row: toCard.row + (relation.isVertical ? 0 : 8),
      col: toCard.col + (relation.isVertical ? 9 : 0),
    };
    return {
      start,
      end,
      arrowStart: false,
      arrowEnd: relation.directRelation,
    };
  }

  private positionPivot(pivot: NodeVector): NodeVector {
    return {
      row: pivot.row - Math.trunc(this.nodeSize.row / 2),
      col: pivot.col - Math.trunc(this.nodeSize.col / 2),
    };
  }
}
