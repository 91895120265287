import { TypeProductionService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { omit, pick } from 'lodash';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { ComfortResult, FilterComfort } from '../models/comfort.model';

@Injectable()
export class ComfortService {
  private pathBase =
    'holdings/{holding_id}/{stage}/animal-groups/{animal_group_id}/comfort/{index}/';
  private pathBaseParams = ['holding_id', 'stage', 'animal_group_id', 'index'];

  constructor(
    private apiCorpService: ApiCorpService,
    private typeProductionService: TypeProductionService,
  ) {}

  get(filter: FilterComfort): Observable<ComfortResult> {
    const urlParams = pick(filter, this.pathBaseParams);
    const queryParams = omit(filter, this.pathBaseParams);
    const url = this.apiCorpService.translateUrl(this.pathBase, urlParams);
    return this.apiCorpService.get<ComfortResult>(url, {
      ...queryParams,
      system_type: this.typeProductionService.get(),
    });
  }
}
