import { TypeProductionService, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { isBoolean } from 'lodash';

import {
  FeatureContext,
  FeatureName,
  FeatureRule,
  FeatureRuleItem,
} from '../model/feature-toggle.model';

export enum lookerDashboard {
  LOOKER = 'looker_dashboard',
}

@Injectable({ providedIn: 'root' })
export class FeatureToggleService {
  constructor(
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {}

  isEnabled(name: FeatureName, defaultValue = false): boolean {
    if (!name) {
      return true;
    }

    if (!this.isEnabledStaticRules(name)) {
      return false;
    }

    const value: FeatureRule = environment.features[name];

    if (value == null) {
      console.warn(
        `Feature "${name}" wasn't configured, using default value "${String(defaultValue)}"`,
      );
      return defaultValue;
    }

    if (isBoolean(value)) {
      return value;
    }

    const context = this.getContext();
    return value.some(r => this.isRuleItemEnabled(r, context));
  }

  hasFeature(featureName: string): boolean {
    const holding = this.userStorageService.getCurrentHolding();
    return holding?.modules?.includes(featureName);
  }

  setLabsForPoultry(feature: FeatureName): boolean {
    return this.typeProductionService.isPoultry() ? this.isEnabled(feature) : false;
  }

  private isEnabledStaticRules(name: FeatureName): boolean {
    switch (name) {
      case 'traceability.health-report':
        return this.typeProductionService.isSwine();
      default:
        return true;
    }
  }

  private isRuleItemEnabled(rule: FeatureRuleItem, context: FeatureContext): boolean {
    return rule.holdingIds?.includes(context.holdingId);
  }

  private getContext(): FeatureContext {
    return {
      holdingId: this.userStorageService.getCurrentHolding()?.id,
    };
  }
}
