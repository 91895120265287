import {
  AnimalGroup,
  AnimalGroupPerformance,
  MeasurementUnitEnum,
  QuadrantAnimalGroup,
} from '@agriness/corp-app/services';
import { StageEnum, TypeProductionService, UserStorageService } from '@agriness/services';
import { AgrinessTranslateService, TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { EventName, SiteByStage } from '@agriness/services/analytics/analytics.model';
import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import { TableColumn } from '@agriness/ui';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEqual, keyBy } from 'lodash';
import { Table } from 'primeng/table';

import { textToElementId } from '../../../../utils/functions.util';
import {
  getFrozenColumnsByStage,
  QuadrantAxisName,
  quadrantColors,
  reportInfoByStage,
} from '../../../model/identify.model';
import { onSortRowObject, RowData, RowObject } from '../../../model/table.model';
import { DataTableExport, TableService } from '../../../services/table.service';
import { LoaderUserPreference } from '../../loader-user-preference';

@Component({
  selector: 'corp-identify-producer-list',
  templateUrl: './corp-identify-producer-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpIdentifyProducerListComponent extends LoaderUserPreference implements OnChanges {
  @Input() stage: StageEnum;
  @Input() quadrantAnimalGroupList: QuadrantAnimalGroup;
  @Input() selectedReport: string;
  @Input() quadrantOptions: { id: string; name: string }[] = [];
  @Input() selectedQuadrants: string[];
  @Input() groupQuadrants: boolean;
  @Input() translateParams: QuadrantAxisName;
  @ViewChildren('dt') pTables: QueryList<Table>;

  onSort = onSortRowObject;
  frozenColumns: Set<string>;
  frozenColumnsTable: TableColumn[] = [];
  scrollableColumnsTable: TableColumn[] = [];
  allProducerList: RowData<unknown>[] = [];
  filteredProducerList: RowData<unknown>[] = [];
  quadrantColors = quadrantColors;
  quadrantNames: { [s: string]: string };
  configTable: { [s: string]: string };

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    public tableService: TableService,
    protected userStorageService: UserStorageService,
    private translateService: AgrinessTranslateService,
    private typeProductionService: TypeProductionService,
    private route: ActivatedRoute,
    private analyticsService: AmplitudeAnalyticsService,
  ) {
    super(userStorageService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.configTable = reportInfoByStage[this.stage].tableConfig;
    this.frozenColumns = new Set<string>(getFrozenColumnsByStage(this.stage));

    if (changes.quadrantAnimalGroupList) {
      this.loadData();
    }
    if (changes.selectedQuadrants) {
      this.filterByQuadrant();
    }
    if (changes.quadrantOptions) {
      this.updateQuadrantNames();
    }
  }

  filterByQuadrant(): void {
    this.filteredProducerList = this.allProducerList;
    if (this.selectedQuadrants.length) {
      this.filteredProducerList = this.filteredProducerList.filter(p =>
        this.selectedQuadrants.includes(p.QUADRANT.value),
      );
    }
  }

  updateQuadrantNames(): void {
    this.quadrantNames = {};
    for (const q of this.quadrantOptions) {
      this.quadrantNames[q.id] = q.name;
    }
  }

  exportCSV(): void {
    this.analyticsService.logCSV(
      EventName.QUADRANTS,
      SiteByStage[this.typeProductionService.get()][this.stage],
    );

    this.tableService.exportCSV(this.pTables.first, (cellData: DataTableExport) => {
      return this.formatTableValues(cellData);
    });
  }

  formatTableValues(cellData: DataTableExport): string {
    if (cellData?.field === 'QUADRANT') {
      return this.t(`agriness.identify.quadrant.${cellData.data.value}`, this.translateParams);
    }

    return this.tableService.evalValueExportTable(cellData);
  }

  showTooltip(column: TableColumn, type: string): boolean {
    if (!this.frozenColumnsTable || type === 'quadrant') {
      return false;
    }

    return this.frozenColumnsTable.includes(column);
  }

  private loadData(): void {
    this.loadColumns();
    this.parseProducerListToRowData();
    this.filterByQuadrant();
  }

  private loadColumns() {
    const frozenColumnsTable: TableColumn[] = [];
    const scrollableColumnsTable: TableColumn[] = [];

    for (const column of this.quadrantAnimalGroupList.producer_list_columns) {
      let translateKeys: string[] = null;

      if (column === 'ANIMALGROUP' && !this.groupQuadrants) {
        translateKeys = ['agriness.animal_group'];
      } else {
        translateKeys = [
          'agriness.identify.producer_list.' + column,
          'agriness.performances.' + column,
        ];
      }

      const header = this.translateService.instantWithFallback(translateKeys);

      let width = this.configTable[column.toLowerCase()];
      if (!width) {
        console.warn(`width for column '${column}' not defined`);
        width = '120px';
      }
      const columnTable: TableColumn = {
        id: textToElementId('column', column),
        field: column,
        header,
        width,
        sortable: true,
      };
      if (this.frozenColumns.has(column)) {
        frozenColumnsTable.push(columnTable);
      } else {
        scrollableColumnsTable.push(columnTable);
      }
    }

    if (!isEqual(this.frozenColumnsTable, frozenColumnsTable)) {
      this.frozenColumnsTable = frozenColumnsTable;
    }

    if (!isEqual(this.scrollableColumnsTable, scrollableColumnsTable)) {
      this.scrollableColumnsTable = scrollableColumnsTable;
    }
  }

  private parseProducerListToRowData() {
    this.allProducerList = [];
    for (const producer of this.quadrantAnimalGroupList.producer_list) {
      const producerDataByIndex = keyBy(producer, o => o?.index_name.toLowerCase());

      const obj: RowData<any> = {};
      for (const column of this.frozenColumnsTable) {
        obj[column.field] = this.getValueFromField(column.field, producerDataByIndex);
      }
      for (const column of this.scrollableColumnsTable) {
        obj[column.field] = this.getValueFromField(column.field, producerDataByIndex);
      }

      if (!this.groupQuadrants && producerDataByIndex['animalgroup']) {
        obj.route = {
          value: [
            '/',
            this.typeProductionService.get(),
            'record',
            producerDataByIndex['farm_id']?.index_value,
            producerDataByIndex['animalgroup']?.index_value[0].id,
          ],
        };
      }

      obj.object = producerDataByIndex;
      this.allProducerList.push(obj);
    }
  }

  private getValueFromField(
    fieldName: string,
    producer: Record<string, AnimalGroupPerformance>,
  ): RowObject {
    const field = producer[fieldName?.toLowerCase()];
    if (!field) return null;

    if (field.index_name === 'ANIMALGROUP') {
      const values = (field.index_value as unknown) as AnimalGroup[];
      return this.groupQuadrants
        ? { value: values.length, type: 'default' }
        : { value: values[0].name, type: 'link' };
    }

    if (field.index_name === 'QUADRANT') {
      return { value: field.index_value, type: 'quadrant' };
    }

    const specificUnits = [MeasurementUnitEnum.UNITARY, MeasurementUnitEnum.DATE];
    const measurementUnit = specificUnits.includes(field.measurement_unit)
      ? field.measurement_unit
      : MeasurementUnitEnum.DECIMAL;

    return {
      value: field.index_value,
      measurement_unit: measurementUnit,
      decimal_places: field.decimal_places,
      type: 'default',
    };
  }
}
