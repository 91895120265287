<svg viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M19.5801 4.68701L3.73005 36.387C3.01605 37.815 4.05405 39.494 5.65005 39.494H37.3501C38.9461 39.494 39.9841 37.814 39.2701 36.387L23.4201 4.68701C22.6291 3.10401 20.3711 3.10401 19.5801 4.68701Z"
    fill="url(#paint0_linear)"
  />
  <path
    d="M37.3499 40.994H5.64991C4.37691 40.994 3.21691 40.348 2.54791 39.265C1.87891 38.182 1.81891 36.855 2.38791 35.715L18.2379 4.015C18.8599 2.772 20.1099 2 21.4999 2C22.8909 2 24.1399 2.772 24.7619 4.017L40.6119 35.716C41.1809 36.854 41.1229 38.181 40.4539 39.264C39.7829 40.348 38.6229 40.994 37.3499 40.994ZM21.4999 5C21.3509 5 21.0779 5.046 20.9219 5.357L5.07191 37.057C4.93091 37.338 5.02891 37.573 5.09991 37.687C5.16991 37.801 5.33591 37.994 5.64991 37.994H37.3499C37.6639 37.994 37.8299 37.802 37.9009 37.687C37.9719 37.572 38.0689 37.338 37.9289 37.058L22.0779 5.358C21.9219 5.046 21.6489 5 21.4999 5ZM19.5799 4.687H19.5899H19.5799Z"
    fill="#1B3858"
  />
  <path
    d="M21.5 27.997C20.396 27.997 19.5 27.101 19.5 25.997V17.997C19.5 16.893 20.396 15.997 21.5 15.997C22.604 15.997 23.5 16.893 23.5 17.997V25.997C23.5 27.102 22.604 27.997 21.5 27.997Z"
    fill="url(#paint1_linear)"
  />
  <path
    d="M21.5 34.497C22.8807 34.497 24 33.3777 24 31.997C24 30.6163 22.8807 29.497 21.5 29.497C20.1193 29.497 19 30.6163 19 31.997C19 33.3777 20.1193 34.497 21.5 34.497Z"
    fill="white"
  />
  <defs>
    <linearGradient
      id="paint0_linear"
      x1="21.5001"
      y1="39.494"
      x2="21.5001"
      y2="3.49971"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#FDC336" />
      <stop offset="0.3511" stop-color="#FAA825" />
      <stop offset="0.7528" stop-color="#F69015" />
      <stop offset="1" stop-color="#F5870F" />
    </linearGradient>
    <linearGradient
      id="paint1_linear"
      x1="21.5"
      y1="27.9971"
      x2="21.5"
      y2="15.9971"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="white" />
      <stop offset="1" stop-color="white" />
    </linearGradient>
  </defs>
</svg>
