<div [attr.data-testid]="data?.id" class="card-full-height">
  <a
    *ngIf="hasNavigation; else cardContent"
    [routerLink]="link.url"
    [queryParams]="link.queryParams"
    target="_blank"
  >
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </a>
</div>

<ng-template #cardContent>
  <div class="card-performance card-performance-duplo card shadow-sm">
    <header class="card-header">
      <p class="card-title text-uppercase" data-testid="card-name">
        {{ data.name | translate }}
      </p>

      <ag-target-indicator
        [status]="data.status"
        [tooltip]="tipContent"
        iconSize="16px"
        wrapperClass="card-status"
      ></ag-target-indicator>
    </header>

    <div class="card-body">
      <div class="card-panel" *ngFor="let performance of data.cardPerformances">
        <div class="card-label" data-testid="card-section-name">
          {{ performance.name | translate }}
        </div>
        <div class="card-value" data-testid="card-value">
          <ag-format
            [value]="performance.value"
            [measurementUnit]="performance.style || data.style || data.measurement_unit"
            [decimalPlaces]="performance.decimal_places || data.decimal_places"
          ></ag-format>
          {{ performance.value_partial ? '*' : '' }}
        </div>

        <div class="card-details" *ngIf="performance.target" data-testid="card-target">
          <span [innerHTML]="getTargetText(performance.target)"></span>
        </div>
        <div
          class="card-details"
          *ngIf="performance.target && !data.target_representation_type"
          data-testid="card-target"
        >
          {{ 'agriness.target' | translate }}:
          <ag-format
            [value]="performance.target"
            [measurementUnit]="performance.style || data.style || data.measurement_unit"
            [decimalPlaces]="performance.decimal_places || data.decimal_places"
          ></ag-format>
        </div>
      </div>

      <a
        *ngIf="data.action && link"
        [routerLink]="link.url"
        [queryParams]="link.queryParams"
        target="_blank"
        class="card-link-icon link"
      >
        <span class="icon icon-open-new-tab is-12 has-text-grey-3"></span>
      </a>
    </div>
    <ag-card-bar-chart *ngIf="data?.barChart" [items]="data.barChart"></ag-card-bar-chart>
  </div>
</ng-template>

<ng-template #tipContent>
  <ag-card-tooltip
    [decimalPlaces]="data.decimal_places"
    [measurementUnit]="data.style || data.measurement_unit"
    [minRecommended]="data.target_min_recommended"
    [maxRecommended]="data.target_max_recommended"
  ></ag-card-tooltip>
</ng-template>
