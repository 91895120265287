import { SectionAbstractComponent } from '@agriness/corp-app/shared/component/section-abstract.component';
import { SectionService, SectionModel } from '@agriness/ui';
import { Component } from '@angular/core';

@Component({
  selector: 'app-targets-section',
  templateUrl: './target-section.component.html',
  styleUrls: ['./target-section.component.scss'],
})
export class TargetSectionComponent extends SectionAbstractComponent {
  constructor(protected sectionService: SectionService) {
    super(sectionService);
  }

  protected getSections(): SectionModel[] {
    return [];
  }
}
