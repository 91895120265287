import { SectionAbstractComponent } from '@agriness/corp-app/shared/component/section-abstract.component';
import { SectionService, SectionModel } from '@agriness/ui';
import { Component } from '@angular/core';

import { PresenceSectionService } from './presence-section.service';

@Component({
  selector: 'app-checklist-section',
  templateUrl: './presence-section.component.html',
  styleUrls: ['./presence-section.component.scss'],
})
export class PresenceSectionComponent extends SectionAbstractComponent {
  constructor(
    protected sectionService: SectionService,
    protected presenceService: PresenceSectionService,
  ) {
    super(sectionService);
  }

  protected getSections(): SectionModel[] {
    return this.presenceService.getSections();
  }
}
