import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

import { AgSideMenuModel } from './ag-side-menu.model';

@Component({
  selector: 'ag-side-menu',
  templateUrl: 'ag-side-menu.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AgSideMenuComponent {
  @Input() itensSideMenu: AgSideMenuModel[];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  click(i) {
    if (i.show === null) {
      i.show = true;
    } else {
      i.show = !i.show;
    }
  }
}
