import { UserStorageService, TypeProductionService, Search } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { KPIList, KPIListFilter } from '../models/bonification.model';
import { KPIListAbstractService } from './kpi-list-abstract.service';

@Injectable({
  providedIn: 'root',
})
export class KPIListService extends KPIListAbstractService {
  private pathBase = 'payment-rules-kpis-available/';
  private defaultPageSize = 10;

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {
    super();
  }

  list({ ...filters }: KPIListFilter): Observable<Search<KPIList>> {
    const url = this.apiCorpService.translateUrl(this.pathBase);
    const params = {
      ...filters,
      stage: filters.stage,
    };
    return this.apiCorpService.get<Search<KPIList>>(url, params);
  }

  private getUrl(): string {
    return this.apiCorpService.translateUrl(this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
    });
  }
}
