<section>
  <div class="full-width">
    <div class="badge-container title-container">
      <p class="subtitle">
        {{ 'agriness.settings.targets.editor.pre-badge' | translate }}
      </p>
      <p class="badge">
        {{ 'agriness.stage.' + stage | translate }}
      </p>
    </div>
  </div>
  <div class="title-container">
    <p class="subtitle">
      {{ 'agriness.settings.targets.editor.step_1.title' | translate }}
    </p>
  </div>
  <div id="first-form-group" class="form-container">
    <div class="form-group is-half">
      <label class="control-label config-input">
        {{ 'agriness.settings.targets.editor.step_1.target_group' | translate }}
      </label>
      <ag-select
        class="select-age form-field"
        [group]="form"
        controlName="target_group"
        [items$]="targetGroupSelectData"
        dataKey="key"
        (selectValueFilter)="fillKPIS()"
      ></ag-select>
    </div>

    <div class="form-group is-half">
      <label class="control-label config-input">
        {{ 'agriness.settings.targets.editor.step_1.index' | translate }}
      </label>
      <ag-select
        class="select-age form-field"
        [group]="form"
        controlName="index"
        [isDisabled]="form.controls.target_group.value === null"
        [items$]="indexData"
        dataKey="key"
      ></ag-select>
    </div>
  </div>
</section>
