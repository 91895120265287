import { Component, Input, OnInit, OnChanges } from '@angular/core';

import { StatusItems, TargetRepresentationType } from './status-target-representantion.model';
import { targetTypes } from './targetTypes';
@Component({
  selector: 'ag-status-target-representation',
  templateUrl: './ag-status-target-representation.component.html',
  styleUrls: ['./ag-status-target-representation.module.scss'],
})
export class AgStatusTargetRepresentationComponent implements OnInit, OnChanges {
  @Input() target?: TargetRepresentationType;
  @Input() items?: StatusItems = [];
  statusItems = [];
  iconSize = 16;

  ngOnInit(): void {
    this.updateStatusItems();
  }

  ngOnChanges(): void {
    this.updateStatusItems();
  }

  private updateStatusItems() {
    if (this.items?.length) {
      this.statusItems = this.items.map(item => ({ percentage: 100, ...item }));
    } else {
      this.statusItems = targetTypes[this.target];
    }
  }
}
