import { TypeProductionService } from '@agriness/services';
import {
  EventCategory,
  EventName,
  PropertyName,
  SiteByStage,
} from '@agriness/services/analytics/analytics.model';
import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CardReportModel, Tag } from './card-report.model';

@Component({
  selector: 'ag-card-report',
  templateUrl: './ag-card-report.component.html',
})
export class AgCardReportComponent {
  @Input() data: CardReportModel;
  @Output() favorite = new EventEmitter<CardReportModel>();
  internalName: string;
  analysisCard = ['animal_group_list', 'animal_group_list_reproductive'];
  stage: string;
  propertyName: Record<string, string>;

  constructor(
    private readonly route: ActivatedRoute,
    private analyticsService: AmplitudeAnalyticsService,
    private typeProductionService: TypeProductionService,
  ) {}

  toggleFavorite(): void {
    this.data.favorite = !this.data.favorite;
    this.favorite.emit(this.data);
  }

  onOpen(): void {
    this.stage = this.route.snapshot.data.stage;
    if (this.analysisCard.includes(this.data.internal_name)) {
      if (this.stage == 'reproductive') {
        this.analyticsService.logFarmList();
      }
    }
  }

  arrayToString(array: Tag[]): string {
    return array.map(item => item.name).join(', ');
  }
}
