import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Calendar, LocaleSettings } from 'primeng/calendar';

import { CalendarDateFormat } from './ag-calendar.model';
import { AgCalendarService } from './ag-calendar.service';

@Component({
  selector: 'ag-calendar',
  templateUrl: './ag-calendar.component.html',
})
export class AgCalendarComponent implements OnInit {
  @ViewChild('calendar', { static: true }) calendar: Calendar;

  @Input() language: string;
  @Input() group: FormGroup;
  @Input() inputId: string;
  @Input() label: string;
  @Input() controlName: string;
  @Input() selectionMode = 'single';
  @Input() numberOfMonths = 1;
  @Input() dateFormat: string;
  @Input() defaultDate;
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() validation: (value: string | Date) => boolean = null;
  @Input() errorMessage?: string;

  yearRange = '2000:2030';
  calendarLocale: LocaleSettings;
  calendarDateFormat: CalendarDateFormat;
  preProcessedDate = '';

  constructor(private calendarService: AgCalendarService) {}

  ngOnInit(): void {
    this.calendarLocale = this.calendarService.getPrimeNgCalendarLocale(this.language);
    this.calendarDateFormat = this.parseDateFormatToCalendar();
  }

  onBlur(): void {
    // Ensures the input will never be empty after the input loses focus.
    if (!this.getInputValue()) {
      this.setDate(new Date());
      return;
    }
    // Stores the input value BEFORE being processed by calendar widget
    this.preProcessedDate = this.getInputValue();
    // If the calendar popup is not visible, trigger calendar `onClose`.
    // Since calendar's `onClose` triggers when a date is selected on
    // the calendar popup, we programatically trigger it here in case
    // the popup was not used.
    if (!this.calendar.overlayVisible) {
      this.onClose();
    }
  }

  onClose(): void {
    // onClose triggers in two situations:
    // A. User clicks outside the input when the calendar popup is HIDDEN.
    // B. User clicks in a valid date from the calendar popup when it's VISIBLE.
    this.ensureValidDate(this.getInputValue());
    // Here we reset the `preProcessedDate` since the user selected/confirmed a date.
    this.preProcessedDate = '';
  }

  toggleCalendar(): void {
    this.calendar.toggle();
  }

  private getInputValue(): string {
    // Here we can have 3 returns, in the following order:
    // 1. If the text input from the calendar widget is acessible and contains something. Return it.
    // 2. If we have a `preProcessedDate` from `onBlur` event. Return it.
    // 3. Return an empty string.
    //
    // The main problem here, is that the Calendar Widget will CLEAR the input date right before the
    // `onClose` event if:
    //
    // A. The user types an invalid date on the input when the calendar popup is VISIBLE, and
    //    clicks outside the input, the `onClose` triggers and it empties the text input before
    //    being accessed by the `getInputValue`. So we need to use `preProcessedDate`, which
    //    is set during the `onBlur` event, where the date is still present on the input,
    //    even if invalid, allowing us to suggest one to the user.
    //
    const input = this.calendar.inputfieldViewChild;
    return input.nativeElement.value
      ? input.nativeElement.value
      : this.preProcessedDate
      ? this.preProcessedDate
      : '';
  }

  private ensureValidDate(value: string): void {
    const suggestion = this.calendarService.suggest(value, this.calendar.getDateFormat());
    try {
      this.setDate(this.calendar.parseValueFromString(suggestion) as Date);
    } catch (e) {
      this.setDate(new Date());
    }
  }

  private setDate(date: Date): void {
    this.group.patchValue({
      [this.controlName]: date,
    });
  }

  private parseDateFormatToCalendar(): CalendarDateFormat {
    const dateFormat = this.dateFormat
      ? this.dateFormat.replace('yyyy', 'yy').toLowerCase()
      : this.calendarLocale.dateFormat;

    return dateFormat as CalendarDateFormat;
  }
}
