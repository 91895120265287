import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { ModalStatusEnum } from '../ag-modal.model';
import { ModalFeedbackContent } from './ag-modal-feedback.model';

import { AgModalFeedbackService } from './ag-modal-feedback.service';
@Component({
  selector: 'ag-modal-feedback',
  templateUrl: './ag-modal-feedback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgModalFeedback implements OnChanges {
  @Input() translationContext = 'agriness.feedback_modal';
  @Input() status: ModalStatusEnum;

  @Output() hide = new EventEmitter();
  @Output() confirm = new EventEmitter();
  @Output() publish = new EventEmitter();

  modal: ModalFeedbackContent;
  translationContextStatus: string;

  constructor(private modalService: AgModalFeedbackService) {}

  ngOnChanges(): void {
    this.modal = this.modalService.getModal(this.status);
    this.translationContextStatus = `${this.translationContext}.${this.status?.toLowerCase()}`;
  }

  onAction(): void {
    if (this.status == ModalStatusEnum.CONFIRMED) this.confirm.emit();
    else if (this.status == ModalStatusEnum.PUBLISHING) this.publish.emit();
    else this.hide.emit();
  }
}
