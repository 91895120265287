import { UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';

@Injectable()
export class HelpAgrinessService {
  private urlLocaleExceptions: Record<string, string> = {
    'es-AR': 'es',
    'en-US': 'en',
  };

  constructor(private userStorageService: UserStorageService) {}

  getUrl(): string {
    const langUser = this.userStorageService.getLanguage();
    const langHelp = this.urlLocaleExceptions[langUser] || langUser;
    return `https://ajuda.agriness.com/${langHelp}/support/home`;
  }
}
