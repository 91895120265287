import {
  AnimalGroupMonitorPerformance,
  AnimalGroupPerformance,
  Performance,
} from '@agriness/corp-app/services';
import { reportLinkByTypeProduction } from '@agriness/corp-app/shared/model/card-builder.model';
import {
  DateService,
  StageEnum,
  TypeProductionEnum,
  TypeProductionService,
} from '@agriness/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import {
  BarChart,
  CardLink,
  CardModel,
  CardModelPerformances,
  CardPerformance,
  TargetStatus,
} from '@agriness/ui';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { format, subYears } from 'date-fns';
import * as _ from 'lodash';

import { MonitorPerformance } from '../../finishing/monitor/monitor.model';
import { ReportFilterQuery } from '../model/report-filter.model';
import { IndexTranslateService } from './index-translate.service';

const periodPerformanceTotalByProduction: { [k in TypeProductionEnum]?: string[] } = {
  [TypeProductionEnum.POULTRY]: [
    'count_animal_group',
    'total_animal_number_output',
    'total_weight_output',
  ],
};
const periodPerformanceTotal = periodPerformanceTotalByProduction[environment.typeProduction] || [];

type ChartOptions = {
  stage: StageEnum;
  filter: ReportFilterQuery;
};

@Injectable({ providedIn: 'root' })
export class CardBuilderService {
  typeProduction: TypeProductionEnum;
  stage: StageEnum;
  reportFilter?: ReportFilterQuery;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private dateService: DateService,
    private indexTranslate: IndexTranslateService,
    private typeProductionService: TypeProductionService,
  ) {
    this.typeProduction = this.typeProductionService.get();
  }

  parsePerformanceToCardModel(
    animalGroupPerformance: AnimalGroupPerformance,
    prefixKeyTranslate?: string | string[],
    stage?: string,
  ): CardModel {
    const {
      label,
      target_definition,
      index_name,
      index_value,
      decimal_places,
      measurement_unit,
      performance,
    } = animalGroupPerformance;

    const targetDefinition = target_definition;
    const indexKey = index_name.toLowerCase();
    return {
      name: label ?? this.indexTranslate.instant(indexKey, prefixKeyTranslate, stage),
      id: indexKey.replace(/_/g, '-'),
      decimal_places: decimal_places,
      style: measurement_unit,
      status: targetDefinition?.status,
      value: performance?.[0]?.index_value ?? index_value,
      target_representation_type: targetDefinition?.representation_type,
      target: targetDefinition?.target_value,
      target_min_recommended: targetDefinition?.min_recommended,
      target_max_recommended: targetDefinition?.max_recommended,
      action: this.hasLink(indexKey),
      link: this.getLink(indexKey),
    };
  }

  parsePerformancesToMonitorPerformances(
    performances: AnimalGroupMonitorPerformance[],
    prefixKeyTranslate?: string,
    filter?: ReportFilterQuery,
    stage?: StageEnum,
  ): MonitorPerformance[] {
    if (_.isEmpty(performances)) {
      return [];
    }

    return [
      {
        name: '',
        cards: performances.map(performance =>
          this.parsePerformanceToCardMonitorPerformances(
            performance,
            prefixKeyTranslate,
            filter,
            stage,
          ),
        ),
      },
    ];
  }

  parsePerformanceToCardMonitorPerformances(
    animalGroupMonitorPerformance: AnimalGroupMonitorPerformance,
    prefixKeyTranslate?: string,
    filter?: ReportFilterQuery,
    stage?: StageEnum,
  ): CardModelPerformances | CardModel {
    const {
      label,
      index_name,
      decimal_places,
      measurement_unit,
      performance,
    } = animalGroupMonitorPerformance;

    this.stage = stage;
    this.reportFilter = filter;
    const prefix = [prefixKeyTranslate, 'agriness.monitor.performances.'];
    const targetDefinition = animalGroupMonitorPerformance.target_definition;
    const indexKey = label ?? index_name.toLowerCase();
    if (performance.length === 1) {
      const first = performance[0];
      const animalGroup = new AnimalGroupPerformance(
        indexKey,
        label,
        first.index_value,
        measurement_unit,
        decimal_places,
        targetDefinition,
        [first],
      );
      return this.parsePerformanceToCardModel(animalGroup, prefix, stage);
    }
    return {
      name: this.indexTranslate.instant(indexKey, prefix, stage),
      id: indexKey.replace(/_/g, '-'),
      decimal_places: decimal_places,
      style: measurement_unit,
      status: targetDefinition?.status,
      target_representation_type: targetDefinition?.representation_type,
      target_min_recommended: targetDefinition?.min_recommended,
      target_max_recommended: targetDefinition?.max_recommended,
      action: this.hasLink(index_name),
      cardPerformances: performance.map(item =>
        this.parsePerformanceToCardPerformance(indexKey, item),
      ),
      barChart: this.parseBarChart(animalGroupMonitorPerformance, { stage, filter }),
    };
  }

  private parseBarChart(
    performance: AnimalGroupMonitorPerformance,
    options: ChartOptions,
  ): BarChart {
    const { animal_group_count_by_target } = performance;
    if (!animal_group_count_by_target) {
      return null;
    }
    const { bad, good } = animal_group_count_by_target;

    if (!_.isNumber(bad) || !_.isNumber(good)) {
      return null;
    }
    const total = bad + good;
    if (total === 0) {
      return [
        {
          percentage: 100,
          title: this.t('agriness.performances.flocks', { value: 0, percentage: 0 }),
          status: null,
        },
      ];
    }
    return [
      this.buildBarChartItem(performance, options, (bad / total) * 100, TargetStatus.BAD),
      this.buildBarChartItem(performance, options, (good / total) * 100, TargetStatus.GOOD),
    ];
  }

  private buildBarChartItem(
    performance: AnimalGroupMonitorPerformance,
    options: ChartOptions,
    percentage: number,
    status: TargetStatus,
  ) {
    const name = performance.index_name;
    const { stage, filter } = options;
    const routeTexts = {
      [TargetStatus.GOOD]: 'agriness.target_status.open_animal_group.good',
      [TargetStatus.ATTENTION]: 'agriness.target_status.attention',
      [TargetStatus.BAD]: 'agriness.target_status.bad',
    };

    const value = performance.animal_group_count_by_target[status];

    let routeInfo = {};
    if (status && percentage) {
      routeInfo = {
        route: `/${this.typeProduction}/${stage}/analysis/animal-group-list`,
        routeText: this.t(routeTexts[status]),
        queryParams: {
          ...filter,
          is_open: true,
          begin_date: format(subYears(new Date(), 3), 'yyyy-MM-dd'),
          target_status_kpi: `${name}_EFFICIENCY`,
          target_status: status,
        },
      };
    }

    return {
      title: this.t('agriness.performances.flocks', {
        value,
        percentage: percentage.toFixed(2),
      }),
      percentage,
      status,
      ...routeInfo,
    };
  }

  private getNamePerformancePeriod(indexName: string, { type, period }: Performance): string {
    if (type) {
      return 'agriness.performances.' + type;
    }
    if (period && period.begin_date === period.end_date) {
      return this.dateService.formatDate(period.begin_date);
    }
    if (periodPerformanceTotal.includes(indexName)) {
      return 'agriness.period.total';
    }
    return 'agriness.period.average';
  }

  private parsePerformanceToCardPerformance(
    indexName: string,
    performance: Performance,
  ): CardPerformance {
    if (!performance) {
      return null;
    }
    const cardPerformance: CardPerformance = {
      name: this.getNamePerformancePeriod(indexName, performance),
      target: performance.target_value,
      value: performance.index_value,
      value_partial: performance.index_value_partial,
      style: performance.measurement_unit,
      decimal_places: performance.decimal_places,
    };
    return cardPerformance;
  }

  private hasLink(indexName: string): boolean {
    return Object.keys(reportLinkByTypeProduction[this.typeProduction]).includes(indexName);
  }

  private getLink(indexName: string): CardLink {
    if (this.hasLink(indexName)) {
      const info = reportLinkByTypeProduction[this.typeProduction][indexName];

      return {
        url: `/${this.typeProduction}/${this.stage}/analysis/${info.report}`,
        queryParams: {
          ...this.reportFilter,
          performance_indicator: info.indicator || indexName,
        },
      };
    }
    return { url: '', queryParams: {} };
  }
}
