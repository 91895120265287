<ag-root [headerLabel]="'agriness.corp' | translate" [iconTitle]="iconTitle">
  <ag-side-menu side-menu *ngIf="sideMenuItems" [itensSideMenu]="sideMenuItems"></ag-side-menu>
  <ag-user-menu
    user-menu
    [user]="userMenu"
    [appMyAccount]="appMyAccount"
    (logout)="onLogout()"
  ></ag-user-menu>
  <ag-help-menu help-menu [url]="helpUrl" [showTooltip]="helpShowTooltip"></ag-help-menu>
</ag-root>
