import { MeasurmentUnits } from '@agriness/corp-app/services';
import { CheckPayment } from '@agriness/corp-app/services/models/payment-record.model';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Inject, Input } from '@angular/core';

import { PaymentReportDocumentStatus } from '../corp-animal-group-payment-report/corp-animal-group-payment-report.model';

@Component({
  selector: 'corp-animal-group-payment-report',
  templateUrl: './corp-animal-group-payment-report.component.html',
})
export class CorpAnimalGroupPaymentReportComponent {
  @Input() status: string = PaymentReportDocumentStatus.LOADING;
  @Input() documentId: string;
  @Input() animalGroupName: string;
  @Input() paymentCheck: CheckPayment = null;
  @Input() holdingId: string;
  @Input() stage: string;
  @Input() isOpen: boolean;
  @Input() measurmentUnits: MeasurmentUnits;

  constructor(@Inject(TRANSLATE_INSTANT) private t: TranslateInstant) {}

  statusTranslateKey(): string {
    const translateKey = `agriness.payment_report.${
      this.status ? this.status : PaymentReportDocumentStatus.LOADING
    }`;
    return this.t(translateKey);
  }
}
