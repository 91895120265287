import { deprecatedColors } from '@agriness/assets/deprecated';
import {
  AnimalGroup,
  AnimalGroupAbstractService,
  AnimalGroupDailyData,
  AnimalGroupResume,
} from '@agriness/corp-app/services';
import { AnimalGroupNutritionFeed } from '@agriness/corp-app/services/models/animal-group-nutrition.models';
import { WaterFlowTableItem } from '@agriness/corp-app/services/models/water-flow-table.model';
import { UserStorageService, DateService, TypeProductionService } from '@agriness/services';
import { FeedbackEnum, ValueFormatStyle } from '@agriness/ui';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { LoaderUserPreference } from '../../../shared/component/loader-user-preference';
import { CardBuilderService } from '../../../shared/services/card-builder.service';
import {
  LineGraphConfiguration,
  LineGraphDataItem,
  LineGraphDisplayData,
} from '../../../shared/services/report-section/report-section-line-graph.model';
import { ReportSectionLineGraphService } from '../../../shared/services/report-section/report-section-line-graph.service';
import {
  TableConfiguration,
  TableDataItem,
  TableDisplayData,
} from '../../../shared/services/report-section/report-section-table.model';
import { ReportSectionTableService } from '../../../shared/services/report-section/report-section-table.service';
import { AnimalGroupStateService } from '../animal-group-state.service';
import { AnimalGroupService } from '../animal-group.service';
import {
  PerformanceModel,
  Report,
  nutritionReportsByProduction,
  tableByFieldByReport,
  tableOrderByReport,
  translateByReport,
  valueFormatByReport,
  valueKeyByReport,
} from './nutrition.model';

@Component({
  templateUrl: './nutrition.component.html',
})
export class NutritionComponent extends LoaderUserPreference implements OnInit, OnDestroy {
  performances: PerformanceModel[] = [];

  feedByPeriodSectionFeedback: FeedbackEnum;
  feedByPeriodGraphDisplayData: LineGraphDisplayData;
  feedByPeriodTableDisplayData: TableDisplayData;

  feedByLoadSectionFeedback: FeedbackEnum;
  feedByLoadGraphDisplayData: AnimalGroupDailyData;
  feedByLoadTableDisplayData: TableDisplayData;

  waterConsumptionSectionFeedback: FeedbackEnum;
  waterConsumptionGraphDisplayData: LineGraphDisplayData;
  waterConsumptionTableDisplayData: TableDisplayData;

  weightSectionFeedback: FeedbackEnum;
  weightGraphDisplayData: LineGraphDisplayData;
  weightTableDisplayData: TableDisplayData;

  subscription: Subscription;
  typeFeedback = FeedbackEnum.LOADING;

  weightIndex = Report.WEIGHT;
  waterIndex = Report.WATER;
  feedByPeriodIndex = Report.FEED_BY_PERIOD;
  feedByLoadIndex = Report.FEED_BY_LOAD;
  tableIndexes: Set<string>;

  constructor(
    protected userStorageService: UserStorageService,
    protected dateService: DateService,
    private animalService: AnimalGroupAbstractService,
    private cardBuilderService: CardBuilderService,
    private animalGroupService: AnimalGroupService,
    private animalGroupStateService: AnimalGroupStateService,
    private lineGraphService: ReportSectionLineGraphService,
    private tableService: ReportSectionTableService,
    private typeProductionService: TypeProductionService,
  ) {
    super(userStorageService);
  }

  ngOnInit(): void {
    this.subscription = this.animalGroupStateService
      .getAnimalGroup()
      .pipe(
        switchMap(animalGroup => {
          return this.loadData(animalGroup);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadData(animalGroup: AnimalGroup): Observable<unknown> {
    this.tableIndexes = nutritionReportsByProduction[this.typeProductionService.get()];

    const observables = this.getObservables(animalGroup).map(obs =>
      obs.pipe(
        catchError((error: unknown) => {
          console.error(error);
          return of({ error });
        }),
      ),
    );

    return forkJoin(observables).pipe(
      tap(results => {
        if (results.every(item => 'error' in item)) {
          this.typeFeedback = FeedbackEnum.ERROR;
        } else if (!this.hasData()) {
          this.typeFeedback = FeedbackEnum.NOT_RESULT;
        } else {
          this.typeFeedback = null;
        }
      }),
      catchError(error => {
        this.typeFeedback = FeedbackEnum.ERROR;
        return of({ error });
      }),
    );
  }

  private getObservables(animalGroup: AnimalGroup): Observable<any>[] {
    return [
      this.subNutrition(animalGroup),
      this.subFeed(animalGroup),
      this.subWeight(animalGroup),
      this.subWater(animalGroup),
    ];
  }

  private subWater(animalGroup: AnimalGroup): Observable<WaterFlowTableItem[]> {
    return this.animalService
      .getWaterFlow(this.holdingId, animalGroup.farm.id, animalGroup.id)
      .pipe(
        tap(data => {
          const dataWithDaily: AnimalGroupDailyData = { daily: data as [] };
          this.waterConsumptionTableDisplayData = this.getTableDisplayData(
            Report.WATER,
            animalGroup,
            dataWithDaily,
          );
          this.waterConsumptionGraphDisplayData = this.getGraphDisplayData(
            Report.WATER,
            dataWithDaily,
          );
        }),
        tap(() => {
          if (
            !this.waterConsumptionGraphDisplayData.feedback &&
            !this.waterConsumptionTableDisplayData.hasData
          ) {
            this.waterConsumptionSectionFeedback = FeedbackEnum.NO_DATA_CHART;
          } else {
            this.waterConsumptionSectionFeedback = null;
          }
        }),
        catchError(error => {
          this.waterConsumptionSectionFeedback = FeedbackEnum.ERROR;
          return throwError(error);
        }),
      );
  }

  private subNutrition(animalGroup: AnimalGroup): Observable<AnimalGroupResume> {
    return this.animalService
      .getAnimalGroupNutritionPerformance(this.holdingId, animalGroup.farm.id, animalGroup.id)
      .pipe(
        tap(animalGroupResume => {
          this.performances = [this.parseToNutritionModel(animalGroupResume)];
        }),
      );
  }

  private parseToNutritionModel(
    { title, performances = [] }: AnimalGroupResume,
    prefixKeyTranslate?: string | string[],
  ): PerformanceModel {
    return {
      title,
      cards: performances.map(performance =>
        this.cardBuilderService.parsePerformanceToCardModel(performance, prefixKeyTranslate),
      ),
    };
  }

  private subFeed(animalGroup: AnimalGroup): Observable<AnimalGroupNutritionFeed> {
    return this.animalService
      .getAnimalGroupNutritionFeed(this.holdingId, animalGroup.farm.id, animalGroup.id)
      .pipe(
        tap(data => {
          const datafeedByPeriod: AnimalGroupDailyData = {
            daily: data.nutrition_cumulative_feed_by_period as [],
            target_definition: data.target_definition,
          };
          this.feedByPeriodGraphDisplayData = this.getGraphDisplayData(
            Report.FEED_BY_PERIOD,
            datafeedByPeriod,
          );
          this.feedByPeriodTableDisplayData = this.getTableDisplayData(
            Report.FEED_BY_PERIOD,
            animalGroup,
            datafeedByPeriod,
          );

          const datafeedByLaad: AnimalGroupDailyData = {
            daily: data.nutrition_feed_by_load as [],
            target_definition: data.target_definition,
          };
          this.feedByLoadTableDisplayData = this.getTableDisplayData(
            Report.FEED_BY_LOAD,
            animalGroup,
            datafeedByLaad,
          );
          this.feedByLoadGraphDisplayData = datafeedByLaad;
        }),
        tap(() => {
          if (!this.feedByPeriodGraphDisplayData.feedback && !this.feedByPeriodTableDisplayData) {
            this.feedByPeriodSectionFeedback = FeedbackEnum.NO_DATA_CHART;
          } else {
            this.feedByPeriodSectionFeedback = null;
          }
          if (this.feedByLoadGraphDisplayData && this.feedByLoadTableDisplayData.hasData) {
            this.feedByLoadSectionFeedback = null;
          } else {
            this.feedByLoadSectionFeedback = FeedbackEnum.NO_DATA_CHART;
          }
        }),
        catchError(error => {
          this.feedByPeriodSectionFeedback = FeedbackEnum.ERROR;
          this.feedByLoadSectionFeedback = FeedbackEnum.ERROR;
          return throwError(error);
        }),
      );
  }

  private subWeight(animalGroup: AnimalGroup): Observable<AnimalGroupDailyData> {
    return this.animalService
      .getAnimalGroupNutritionWeight(this.holdingId, animalGroup.farm.id, animalGroup.id)
      .pipe(
        tap(data => {
          this.weightGraphDisplayData = this.getGraphDisplayData(Report.WEIGHT, data);
          this.weightTableDisplayData = this.getTableDisplayData(Report.WEIGHT, animalGroup, data);
        }),
        tap(() => {
          if (this.weightGraphDisplayData.feedback && !this.weightTableDisplayData.hasData) {
            this.weightSectionFeedback = FeedbackEnum.NO_DATA_CHART;
          } else {
            this.weightSectionFeedback = null;
          }
        }),
        catchError(error => {
          this.weightSectionFeedback = FeedbackEnum.ERROR;
          return throwError(error);
        }),
      );
  }

  private getGraphDisplayData(report: Report, data: AnimalGroupDailyData): LineGraphDisplayData {
    return this.lineGraphService.asDisplayData({
      configuration: this.getGraphConfiguration(report, this.setGraphMesurementUnit(report, data)),
      data: this.getGraphData(report, data),
    });
  }

  private setGraphMesurementUnit(report: Report, data: AnimalGroupDailyData): ValueFormatStyle {
    let unit: ValueFormatStyle = valueFormatByReport[report];

    data.daily.map(item => {
      switch (item.weight_measurement_unit) {
        case 'pounds':
          unit = ValueFormatStyle.POUNDS as ValueFormatStyle;
          break;
        case 'kilogram':
          unit = ValueFormatStyle.KILOGRAM;
          break;
        default:
          unit = valueFormatByReport[report];
          break;
      }
    });

    return unit;
  }

  private getGraphConfiguration(report: Report, unit?: ValueFormatStyle): LineGraphConfiguration {
    return {
      fields: {
        category: {
          name: translateByReport[report].chart.period,
          style: ValueFormatStyle.UNITARY,
          markerInterval: 7,
        },
        series: {
          style: ValueFormatStyle.TEXT,
          order: [translateByReport[report].chart.series_actual],
          bySeries: {
            [translateByReport[report].chart.series_actual]: {
              color: deprecatedColors.purple2,
              markerSymbol: 'circle',
            },
            [translateByReport[report].chart.series_target]: {
              color: deprecatedColors.blue7,
              connectNulls: true,
              markerEnabled: false,
            },
          },
        },
        value: {
          name: translateByReport[report].chart.value,
          style: unit,
          report: report,
        },
      },
    };
  }

  private getGraphData(report: Report, data: AnimalGroupDailyData): LineGraphDataItem[] {
    switch (report) {
      case Report.WATER: {
        return [
          ...data.daily.map(item => ({
            category: item.target_index,
            series: translateByReport[report].chart.series_actual,
            value: item.consumption_per_animal,
          })),
          ...data.daily.map(item => ({
            category: item.target_index,
            series: translateByReport[report].chart.series_target,
            value: item.target_plan,
          })),
        ];
      }
      default: {
        return [
          ...data.daily.map(item => ({
            category: item.period,
            series: translateByReport[report].chart.series_actual,
            value: item[valueKeyByReport[report]] as number,
          })),
          ...Object.values(data.target_definition?.daily || []).map(item => ({
            category: item.period,
            series: translateByReport[report].chart.series_target,
            value: item.targets.good?.equal || 0,
          })),
        ];
      }
    }
  }

  private getTableDisplayData(
    report: Report,
    animalGroup: AnimalGroup,
    data: AnimalGroupDailyData,
  ) {
    return this.tableService.asDisplayData({
      configuration: this.getTableConfiguration(report, animalGroup),
      data: this.getTableData(report, data),
    });
  }

  private getTableConfiguration(report: Report, animalGroup: AnimalGroup): TableConfiguration {
    return {
      fields: {
        columns: {
          order: tableOrderByReport[report],
          byField: tableByFieldByReport[report],
        },
      },
      downloadFilename: this.animalGroupService.getDownloadFilename(
        animalGroup,
        translateByReport[report].title,
      ),
    };
  }

  private getTableData(report: Report, data: AnimalGroupDailyData): TableDataItem[] {
    switch (report) {
      case Report.FEED_BY_LOAD: {
        return data.daily.map(item => ({
          columns: {
            begin_date: this.dateService.formatDate(item.begin_date),
            end_date: this.dateService.formatDate(item.end_date),
            days: item.days,
            difference:
              item.difference && this.animalGroupService.showNumberAsDifference(item.difference),
            difference_percentage:
              item.difference_percentage &&
              `${this.animalGroupService.showNumberAsDifference(item.difference_percentage)}%`,
            balance: item.balance,
            total_feed: item.feed_per_animal * item.balance,
            average_daily_consumption: item.average_daily_consumption,
            average_daily_consumption_target: item.average_daily_consumption_target,
            nutrition_type: item.nutrition_type,
          },
        }));
      }
      case Report.WATER: {
        return data.daily.map(item => ({
          columns: {
            target_index: item.target_index,
            consumption_per_day: item.consumption_per_day,
            balance: item.balance,
            consumption_per_animal: item.consumption_per_animal,
            target_plan: item.target_plan,
          },
        }));
      }
      default: {
        return data.daily.map(item => {
          let value_accumulated_target: number;
          const matching_target = Object.values(data.target_definition?.daily || []).find(
            t_data => t_data.period == item.period,
          );
          if (matching_target) value_accumulated_target = matching_target.targets.good?.equal;
          return {
            columns: {
              period: item.period,
              value_accumulated: item[valueKeyByReport[report]] as number,
              value_accumulated_target,
            },
          };
        });
      }
    }
  }

  private hasData() {
    return this.performances.some(p => p.cards.length > 0);
  }
}
