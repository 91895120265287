<div [class]="'balance-card ' + balanceStatus">
  <div *ngFor="let obj of cell | keyvalue: returnZero">
    <div [class]="'balance-item ' + obj.key">
      <div class="d-flex align-items-center">
        <span class="balance-icon" [class]="loadBalanceIcon(obj.key)"></span>
        <span class="balance-key"
          >{{ 'agriness.planning.movements.balance.' + obj.key | translate }}
          <span
            *ngIf="obj.key == balanceEnum.HATCHABLES"
            class="status-icon icon icon-check-outline"
            [ngClass]="hatchingStatus?.toLowerCase()"
          ></span>
          <span
            *ngIf="obj.key == balanceEnum.INCUBATED"
            class="status-icon icon icon-check-outline"
            [ngClass]="incubatedStatus?.toLowerCase()"
          ></span>
        </span>
      </div>
      <span *ngIf="isValidValue(obj.value)" class="balance-value">{{ obj.value | number }}</span>
      <span *ngIf="!isValidValue(obj.value)" class="balance-value">-</span>
    </div>
  </div>
</div>
