<section class="section is-full">
  <div class="page-title d-flex align-items-center mb-3">
    <corp-back-button></corp-back-button>
    {{ pageTitle | translate }}
  </div>

  <corp-report-filters
    #reportFilter
    [availableFilters]="filterIds"
    [stage]="stage"
    (filter)="filter()"
    class="mb-3"
    [periodLabels]="periodLabels"
  ></corp-report-filters>

  <div class="row mb-5">
    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="card-title d-flex align-items-center">
            {{ sectionTitle | translate }}

            <button (click)="download(DocumentType.CSV)" class="btn btn-ghost has-round-hover ml-2">
              <span class="icon is-16 icon-csv"></span>
            </button>

            <corp-preference-toggle-column
              #preferences
              [trackEvent]="true"
              [stage]="stage"
              [preferenceType]="preferenceType"
              buttonIconClass="icon-column-settings"
              [labelProvider]="preferenceLabelProvider"
              [hiddenItems]="hiddenColumns"
              (changes)="this.onPreferenceChange()"
            ></corp-preference-toggle-column>
          </div>

          <ag-table
            #dt
            [exportFilename]="downloadFilename"
            [loading]="loading"
            [error]="error"
            (lazyLoad)="loadLazy($event)"
            (rowSelect)="onCheckboxChange()"
            (rowUnselect)="onCheckboxChange()"
            (headerCheckboxToggle)="onHeaderCheckboxToggle()"
            [frozenColumns]="frozenTableColumns"
            [columns]="scrollableTableColumns"
            [data]="animalGroupList"
            [rows]="rows"
            [rowsPerPageOptions]="rowsPerPageOptions"
            [totalRecords]="totalRecords"
            [noResultFeedback]="FeedbackEnum.NOT_RESULT_SEARCH"
            [selectedData]="selectedAnimalGroup"
            [trackEvent]="trackEvent"
          >
            <ng-template
              pTemplate="bodycell"
              let-col="column"
              let-data="data"
              let-rowData="rowData"
            >
              <ng-container
                *ngIf="col.field === 'ACTION'; then actionCell; else defaultCell"
              ></ng-container>

              <ng-template #actionCell>
                <td>
                  <ng-container *ngFor="let mode of displayModeList">
                    <ag-table-action-cell
                      [attr.data-testid]="mode + '-diet-action'"
                      [icon]="rowData.actions[mode]?.icon"
                      [event]="rowData.actions[mode]?.event"
                    ></ag-table-action-cell>
                  </ng-container>
                </td>
              </ng-template>

              <ng-template #defaultCell>
                <td [ngSwitch]="data?.id" [attr.data-testid]="data?.id">
                  <corp-animal-group-link
                    *ngSwitchCase="'animal-group-name'"
                    [animalGroup]="rowData.object.animal_group"
                  ></corp-animal-group-link>
                  <ag-table-cell-content
                    *ngSwitchDefault
                    [data]="data"
                    [column]="col"
                    [showSymbol]="false"
                    [dateFormat]="dateFormat"
                    [ngClass]="{ 'font-weight-bold': data?.id === 'producer-name' }"
                  ></ag-table-cell-content>
                </td>
              </ng-template>
            </ng-template>
          </ag-table>
        </div>
      </div>
    </div>
  </div>

  <diet-selection #dietSelection [stage]="stage"> </diet-selection>
</section>
