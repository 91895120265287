import { DragDropModule } from '@angular/cdk/drag-drop';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';

import { SharedModule } from '../../common/shared.module';
import { AgDualListBoxComponent } from './ag-dual-list-box.component';
import { AgListBoxComponent } from './ag-list-box.component';

@NgModule({
  declarations: [AgDualListBoxComponent, AgListBoxComponent],
  exports: [AgDualListBoxComponent, AgListBoxComponent],
  imports: [DragDropModule, InputTextModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgDualListBoxModule {}
