import {
  MainForm,
  PatchForm,
} from '@agriness/corp-app/settings/payment-rule-section/payment-rule/rule-editor/rule-editor-form.model';
import { Search } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { RuleFilter, Rule, KPIList } from '..';
import { Stage } from '../models/bonification.model';

@Injectable({
  providedIn: 'root',
})
export abstract class BonificationAbstractService {
  abstract list(farmFilter: RuleFilter): Observable<Search<Rule>>;
  abstract save(form: MainForm): Observable<Rule>;
  abstract put(form: MainForm, ruleId: string): Observable<Rule>;
  abstract patch(form: PatchForm, ruleId: string): Observable<Rule>;
  abstract getStageId(name: string): Observable<Search<Stage>>;
  abstract listKPI(): Observable<Search<KPIList>>;
}
