import { ReportEnum } from '@agriness/corp-app/services';
import { TypeProductionEnum } from '@agriness/services';

type ReportLinkInfo = {
  report: ReportEnum;
  indicator?: string;
};

type ReportLinkInfoMapper = {
  [k: string]: ReportLinkInfo;
};

type IndexesByTypeProduction = Record<TypeProductionEnum, ReportLinkInfoMapper>;

export const reportLinkByTypeProduction: IndexesByTypeProduction = {
  [TypeProductionEnum.SWINES]: {
    average_born_alive: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_born: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_non_productive_days: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_weaned_pigs: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    farrowing_rate: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    litters_female_year: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    percentage_born_losses: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    percentage_reproductive_losses: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    pigs_weaned_mated_female_year: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    total_farms: { report: ReportEnum.ANIMALGROUP_LIST },
    total_farrowings: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    total_primiparous_sows: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    total_rebreeding_sows: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    total_services: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    total_weaning: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    weaning_mortality_related: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    count_animal_group: { report: ReportEnum.ANIMALGROUP_LIST },
    days_in_stage: { report: ReportEnum.ANIMALGROUP_LIST },
    total_animal_number_output: { report: ReportEnum.ANIMALGROUP_LIST },
  },
  [TypeProductionEnum.DAIRY]: {
    count_animal_group: { report: ReportEnum.ANIMALGROUP_LIST },
    total_farm: { report: ReportEnum.ANIMALGROUP_LIST },
    average_volume_milk_per_collection_day_calculated: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_volume_milk_per_collection_day: {
      report: ReportEnum.PERFORMANCE_ANALYSIS,
      indicator: 'total_shipped_milk',
    },
    average_shipped_milk_per_lactating_cows_per_day: {
      report: ReportEnum.PERFORMANCE_ANALYSIS,
      indicator: 'average_shipped_milk_per_cow',
    },
    average_shipped_milk_per_cow_calculated: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_result_fat: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_weight_fat: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_result_protein: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_weight_protein: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_weight_fat_calculated: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_weight_fat_per_cow_calculated: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_weight_protein_calculated: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_weight_protein_per_cow: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_weight_protein_per_cow_calculated: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_result_ccs: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_result_urea: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_result_cbt: { report: ReportEnum.PERFORMANCE_ANALYSIS },
    average_feed_efficience_calculated: {
      report: ReportEnum.PERFORMANCE_ANALYSIS,
      indicator: 'feed_efficience_calculated',
    },
    average_components_efficience_calculated: {
      report: ReportEnum.PERFORMANCE_ANALYSIS,
      indicator: 'components_efficience_calculated',
    },
  },
  [TypeProductionEnum.POULTRY]: {},
  [TypeProductionEnum.LAYERS]: {},
};
