import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as amplitude from 'amplitude-js';
import { toLower, snakeCase } from 'lodash';
import { EventCategory, EventName, PropertyName } from './analytics.model';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeAnalyticsService {
  amplitudeInstance = amplitude.getInstance();

  constructor() {
    this.amplitudeInstance.init(environment.amplitudeKey);
  }

  logSiteClick(site: string): void {
    this.amplitudeInstance.logEvent(EventCategory.SITE, {
      'Event Name': EventName.SITE_VIEW,
      'Property Name': site,
    });
  }

  logKPIClick(name: string): void {
    this.amplitudeInstance.logEvent(EventCategory.SITE, {
      'Event Name': EventName.CLICK_KPI,
      'Property Name': `${PropertyName.KPI}_${snakeCase(name)}`,
    });
  }

  logPreference(eventName: string): void {
    this.amplitudeInstance.logEvent(EventCategory.SITE, {
      'Event Name': eventName,
    });
  }

  logFilters(site: string, filter: string): void {
    this.amplitudeInstance.logEvent(EventCategory.SITE, {
      'Event Name': EventName.FILTERS_USED,
      'Property Name': `${PropertyName.FILTERS}_${site}_${snakeCase(filter)}`,
    });
  }

  logFarmList(): void {
    this.amplitudeInstance.logEvent(EventCategory.ANALYSIS, {
      'Event Name': EventName.FARM_LIST,
      'Property Name': `${PropertyName.VIEW_FARM_LIST}`,
    });
  }

  logPreferenceSave(eventName: string, site: string): void {
    this.amplitudeInstance.logEvent(EventCategory.ANALYSIS, {
      'Event Name': eventName,
      'Property Name': `${PropertyName.KPIS_PREFERENCE_USED}_${site}`,
    });
  }

  logTotalView(site: string): void {
    this.amplitudeInstance.logEvent(EventCategory.SITE, {
      'Event Name': EventName.QUADRANTS,
      'Property Name': `${PropertyName.TOTAL_VIEWS}_${site}`,
    });
  }

  logCSV(eventName: string, site: string): void {
    this.amplitudeInstance.logEvent(EventCategory.SITE, {
      'Event Name': eventName,
      'Property Name': `${PropertyName.EXPORT_CSV}_${site}`,
    });
  }

  logTableQuadrants(eventProperty: string, site: string): void {
    this.amplitudeInstance.logEvent(EventCategory.SITE, {
      'Event Name': EventName.QUADRANTS,
      'Property Name': `${eventProperty}_${site}`,
    });
  }

  logSimulateQuadrants(eventProperty: string, site: string): void {
    this.amplitudeInstance.logEvent(EventCategory.SITE, {
      'Event Name': EventName.QUADRANTS,
      'Property Name': `${eventProperty}_${site}`,
    });
  }

  logFarmClickOrdering(column: string): void {
    this.amplitudeInstance.logEvent(EventCategory.ANALYSIS, {
      'Event Name': EventName.FARM_LIST,
      'Property Name': `${PropertyName.KPI_ORDER}_${EventName.FARM_LIST}_${toLower(column)}`,
    });
  }

  logAnimalGroupClickOrdering(site: string, column: string): void {
    this.amplitudeInstance.logEvent(EventCategory.ANALYSIS, {
      'Event Name': EventName.ANIMALGROUP_LIST,
      'Property Name': `${PropertyName.KPI_ORDER}_${site}_${toLower(column)}`,
    });
  }

  logRecord(): void {
    this.amplitudeInstance.logEvent(EventCategory.ANALYSIS, {
      'Event Name': EventName.ANIMALGROUP_RECORD,
      'Property Name': `${PropertyName.VIEW_ANIMALGROUP_RECORD}`,
    });
  }

  logRecordByAnimalGroup(site: string): void {
    this.amplitudeInstance.logEvent(EventCategory.ANALYSIS, {
      'Event Name': EventName.ANIMALGROUP_LIST,
      'Property Name': `${PropertyName.VIEW_ANIMALGROUP_LIST}_${site}`,
    });
  }
}
