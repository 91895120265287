import {
  AgrinessKeycloakConfigService,
  TypeProductionService,
  UserAbstractService,
  UserStorageService,
} from '@agriness/services';
import { APP_INITIALIZER, FactoryProvider, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { defer } from 'lodash';
import { of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

import { AuthGuard } from '../router-guard/auth.guard';
import { ProductionParamsAbstractService } from '../services/production-params/production-params-abstract.service';

@Injectable()
export class AppInitializerService {
  static provider: FactoryProvider = {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (service: AppInitializerService) => {
      return service.init.bind(service) as AppInitializerService;
    },
    deps: [AppInitializerService],
  };

  constructor(
    private userStorageService: UserStorageService,
    private userService: UserAbstractService,
    private productionParamsService: ProductionParamsAbstractService,
    private keycloakService: AgrinessKeycloakConfigService,
    private authGuard: AuthGuard,
    private typeProductionService: TypeProductionService,
  ) {}

  init(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.keycloakService.isAuthenticated
        .pipe(
          filter(isAuthenticated => Boolean(isAuthenticated)),
          tap(() => this.changeToHoldingWithAccess()),
          switchMap(() => this.authGuard.redirectIfNotUserProduct()),
          tap(typeProduction => this.typeProductionService.set(typeProduction)),
          switchMap(() => this.productionParamsService.fetchParamsTranslate()),
          tap(() => resolve()),
          catchError(error => {
            console.error('Initialization failed:', error);
            reject(error);
            return of(null);
          }),
        )
        .subscribe();
    });
  }

  changeToHoldingWithAccess(): void {
    const userAgriness = this.userStorageService.getUserAgriness();
    let holding = this.userStorageService.getCurrentHolding();
    if ((holding && !this.authGuard.hasAccessToCorp(holding)) || !holding) {
      holding = userAgriness.holdings.find(h => this.authGuard.hasAccessToCorp(h));
    }
    if (holding) {
      this.userService.setCurrentHolding(holding.id);
    } else {
      defer(() => (window.location.href = environment.appMyAccount));
      throw new Error("None of user's holdings have access to product.");
    }
  }
}
