import { Search, TypeProductionService, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { Routes, RoutesFilter, RoutesForm } from '../models/routes.model';
import { RoutesAbstractService } from './routes-abstract.service';

@Injectable({
  providedIn: 'root',
})
export class RoutesService extends RoutesAbstractService {
  private pathBase = 'holdings/{holding_id}/routes/';
  private pathRoutesId = 'holdings/{holding_id}/routes/{routes_id}/';
  private defaultPageSize = 10;

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {
    super();
  }

  list({
    page = 1,
    page_size = this.defaultPageSize,
    queryCorpOnly = false,
    stage,
    ...filters
  }: RoutesFilter): Observable<Search<Routes>> {
    const url = this.getUrl();
    const params = {
      ...filters,
      page,
      page_size,
      system_type: this.typeProductionService.get(),
      stage,
      query_corp_only: queryCorpOnly,
    };

    return this.apiCorpService.get<Search<Routes>>(url, params);
  }

  save(routesForm: RoutesForm): Observable<Routes> {
    const url = this.getUrl();
    const params = {
      ...routesForm,
      system_type: this.typeProductionService.getId(),
    };

    return this.apiCorpService.post<Routes>(url, params);
  }

  update(routesForm: RoutesForm, routesId: string): Observable<Routes> {
    const url = this.getUrl(routesId);
    const params = {
      ...routesForm,
      system_type: this.typeProductionService.getId(),
    };

    return this.apiCorpService.put<Routes>(url, params);
  }

  delete(routes: Routes): Observable<void> {
    const url = this.getUrl(routes.id);
    const params = {
      system_type: this.typeProductionService.getId(),
    };
    return this.apiCorpService.delete(url, params);
  }

  private getUrl(routesId?: string): string {
    return this.apiCorpService.translateUrl(routesId ? this.pathRoutesId : this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
      routes_id: routesId,
    });
  }
}
