<corp-modal-form
  [title]="translationContext + '.editor.title' | translate"
  [modalStatus]="modalStatus"
  [visible]="visible"
  [formValid]="routesForm.valid"
  (closed)="onClosed()"
  (formSubmit)="onSubmit()"
>
  <div>
    <p class="subtitle">
      {{ translationContext + '.editor.subtitle' | translate }}
    </p>
  </div>

  <form>
    <div>
      <ag-text-field
        [formGroup]="routesForm"
        controlName="name"
        [errorMessage]="errorMessages.name"
        [label]="translationContext + '.editor.fields.name.label' | translate"
      >
      </ag-text-field>
    </div>
  </form>
</corp-modal-form>
