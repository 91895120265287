<div class="quadrant">
  <div class="axis">
    <span class="star icon icon-star"></span>

    <div class="horizontal-axis">
      <div *ngIf="quadrantsAxisX" class="text font-weight-bold axis-label">
        {{ quadrantHeaderAxisX }}
      </div>
      <ng-container
        *ngTemplateOutlet="
          quadrantAxisSection;
          context: { quadrantsAxis: quadrantsAxisX, quadrantKey: 'bad' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          quadrantAxisSection;
          context: { quadrantsAxis: quadrantsAxisX, quadrantKey: 'good' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          quadrantAxisSection;
          context: { quadrantsAxis: quadrantsAxisX, quadrantKey: 'great' }
        "
      ></ng-container>
      <div class="arrow">
        <span class="icon icon-arrow-forward"></span>
      </div>
    </div>

    <div class="vertical-axis">
      <div *ngIf="quadrantsAxisY" class="text font-weight-bold axis-label">
        {{ quadrantHeaderAxisY }}
      </div>
      <ng-container
        *ngTemplateOutlet="
          quadrantAxisSection;
          context: { quadrantsAxis: quadrantsAxisY, quadrantKey: 'great' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          quadrantAxisSection;
          context: { quadrantsAxis: quadrantsAxisY, quadrantKey: 'good' }
        "
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="
          quadrantAxisSection;
          context: { quadrantsAxis: quadrantsAxisY, quadrantKey: 'bad' }
        "
      ></ng-container>
      <div class="arrow">
        <span class="icon icon-arrow-upward"></span>
      </div>
    </div>
  </div>

  <div class="columns is-multiline is-variable is-1">
    <ng-container
      *ngTemplateOutlet="quadrant; context: { $implicit: QuadrantEnum.BAD_GREAT }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="quadrant; context: { $implicit: QuadrantEnum.GOOD_GREAT }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="quadrant; context: { $implicit: QuadrantEnum.GREAT_GREAT }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="quadrant; context: { $implicit: QuadrantEnum.BAD_GOOD }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="quadrant; context: { $implicit: QuadrantEnum.GOOD_GOOD }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="quadrant; context: { $implicit: QuadrantEnum.GREAT_GOOD }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="quadrant; context: { $implicit: QuadrantEnum.BAD_BAD }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="quadrant; context: { $implicit: QuadrantEnum.GOOD_BAD }"
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="quadrant; context: { $implicit: QuadrantEnum.GREAT_BAD }"
    ></ng-container>
  </div>
</div>

<ng-template #quadrantAxisSection let-quadrantsAxis="quadrantsAxis" let-quadrantKey="quadrantKey">
  <span class="axis-section">
    <span class="axis-section-content">
      <span class="axis-section-title">
        {{ 'agriness.identify.quadrant.' + quadrantKey | translate }}
      </span>
      <span class="axis-section-subtitle">
        {{ translateAxisInterval(quadrantsAxis, quadrantKey) }}
      </span>
    </span>
  </span>
</ng-template>

<ng-template #quadrant let-quadrant>
  <div class="column is-one-third" [attr.data-testid]="'quadrant-' + quadrant">
    <div class="group-card" [ngClass]="quadrantColors[quadrant]">
      <header class="group-card-header">
        <h6 class="title is-small font-weight-bold ml-4">
          <span>{{
            'agriness.identify.quadrant.' + quadrant | translate: quadrantNameTranslateParams
          }}</span>
          <span *ngIf="quadrants[quadrant]?.count"> ({{ quadrants[quadrant].count }})</span>
        </h6>
      </header>

      <div class="group-card-body" *ngIf="quadrants[quadrant]?.producers?.length; else elseBlock">
        <ng-container *ngFor="let producer of quadrants[quadrant].producers">
          <ng-container
            [ngTemplateOutlet]="
              showQuadrantItemAsLink(producer) ? quadrantItemLink : quadrantItemPlain
            "
            [ngTemplateOutletContext]="{ producer: producer, indexes: quadrants[quadrant] }"
          ></ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #quadrantItemLink let-producer="producer" let-indexes="indexes">
  <ng-container
    [ngTemplateOutlet]="shouldRedirectToS4() ? S4LinkTemplate : AnimalGroupTemplate"
    [ngTemplateOutletContext]="{ producer: producer, indexes: indexes }"
  ></ng-container>
</ng-template>

<ng-template #S4LinkTemplate let-producer="producer" let-indexes="indexes">
  <a (click)="redirect(producer)" class="farmer-card farmer-card-link">
    <ng-container
      *ngTemplateOutlet="quadrantItemContent; context: { producer: producer, indexes: indexes }"
    ></ng-container>
  </a>
</ng-template>

<ng-template #AnimalGroupTemplate let-producer="producer" let-indexes="indexes">
  <a [routerLink]="redirect(producer)" target="_blank" class="farmer-card farmer-card-link">
    <ng-container
      *ngTemplateOutlet="quadrantItemContent; context: { producer: producer, indexes: indexes }"
    ></ng-container>
  </a>
</ng-template>

<ng-template #quadrantItemPlain let-producer="producer" let-indexes="indexes">
  <div class="farmer-card">
    <ng-container
      *ngTemplateOutlet="quadrantItemContent; context: { producer: producer, indexes: indexes }"
    ></ng-container>
  </div>
</ng-template>

<ng-template #quadrantItemContent let-producer="producer" let-indexes="indexes">
  <div class="media">
    <header>
      <img src="" alt="" class="avatar mr-2" />
    </header>

    <div class="media-body">
      <h6 class="title is-default has-text-grey-5 font-weight-bold">
        {{ producer.producer_name }}
      </h6>
      <div class="d-flex pt-1">
        <div class="farmer-card-index text">
          <span class="quadrant-header pr-1"> {{ quadrantHeaderAxisX }}: </span>
          <span class="farmer-card-value">
            <ag-format
              [value]="producer.data[indexes.index_name_x.toLowerCase()].index_value"
              [measurementUnit]="producer.data[indexes.index_name_x.toLowerCase()].measurement_unit"
              [decimalPlaces]="producer.data[indexes.index_name_x.toLowerCase()].decimal_places"
            ></ag-format>
          </span>
        </div>

        <div class="farmer-card-index text">
          <span class="quadrant-header pr-1"> {{ quadrantHeaderAxisY }}: </span>
          <span class="farmer-card-value">
            <ag-format
              [value]="producer.data[indexes.index_name_y.toLowerCase()].index_value"
              [measurementUnit]="producer.data[indexes.index_name_y.toLowerCase()].measurement_unit"
              [decimalPlaces]="producer.data[indexes.index_name_y.toLowerCase()].decimal_places"
            ></ag-format>
          </span>
        </div>

        <div
          *ngIf="showQuadrantItemAsLink(producer)"
          class="d-flex justify-content-end flex-grow-1"
        >
          <span class="d-flex icon icon-open-new-tab-small"></span>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #elseBlock>
  <content class="group-card-body is-empty">
    <p
      class="text font-weight-bold text-center"
      [innerHTML]="'agriness.identify.quadrant.empty' | translate"
    ></p>
  </content>
</ng-template>
