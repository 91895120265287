import { systemType } from '@agriness/services';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { SearchReportCategory } from '../models/report-category.model';
import { Report, ReportDashboard, ReportFilter } from '../models/report-dashboard.model';
import { ReportEnum } from '../models/user.model';
import { ReportDashboardAbstractService } from './report-dashboard-abstract.service';

@Injectable()
export class ReportDashboardService extends ReportDashboardAbstractService {
  private pathReportDashboardFarm = 'reports/{stage}/';
  private pathReportMetadata = 'reports/{stage}/{report}/';
  private pathReportCategoryFarm = 'reports/categories/';

  constructor(private apiCorpService: ApiCorpService) {
    super();
  }

  getReports(filter: ReportFilter, stage: string): Observable<ReportDashboard> {
    filter.system_type = systemType[environment.typeProduction];
    const url = this.apiCorpService.translateUrl(this.pathReportDashboardFarm, { stage });
    return this.apiCorpService.get<ReportDashboard>(url, filter);
  }

  getReport(reportInternalName: ReportEnum, stageName: string): Observable<Report> {
    const url = this.apiCorpService.translateUrl(this.pathReportMetadata, {
      stage: stageName,
      report: reportInternalName,
    });
    return this.apiCorpService.get<Report>(url);
  }

  getReportsCategories(idStage: string): Observable<SearchReportCategory> {
    const params = { stage: idStage };
    const url = this.apiCorpService.translateUrl(this.pathReportCategoryFarm, params);
    return this.apiCorpService.get<SearchReportCategory>(url);
  }
}
