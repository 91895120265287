import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import {
  TranslateLoader,
  TranslateModule,
  TranslateModuleConfig,
  TranslateParser,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AgrinessTranslateParserService } from './translate-parser.service';
import { AgrinessTranslateInterceptor } from './translate.interceptor';
import { TranslatePipe } from './translate.pipe';
import { AgrinessTranslateService, TRANSLATE_INSTANT } from './translate.service';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function TranslateInstantFactory(service: AgrinessTranslateService) {
  return service.instantWithFallback;
}

@NgModule({
  exports: [TranslatePipe],
  declarations: [TranslatePipe],
})
export class AgrinessTranslateModule {
  static forRoot(
    config: TranslateModuleConfig = {},
  ): ModuleWithProviders<AgrinessTranslateModuleWithProviders> {
    return {
      ngModule: AgrinessTranslateModuleWithProviders,
      providers: TranslateModule.forRoot({
        loader: config.loader || {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
        parser: config.parser || {
          provide: TranslateParser,
          useClass: AgrinessTranslateParserService,
        },
        ...config,
      }).providers,
    };
  }

  static forChild(
    config: TranslateModuleConfig = {},
  ): ModuleWithProviders<AgrinessTranslateModuleWithProviders> {
    return {
      ngModule: AgrinessTranslateModuleWithProviders,
      providers: TranslateModule.forChild({
        loader: config.loader || {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
        parser: config.parser || {
          provide: TranslateParser,
          useClass: AgrinessTranslateParserService,
        },
        ...config,
      }).providers,
    };
  }
}

@NgModule({
  imports: [TranslateModule, AgrinessTranslateModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AgrinessTranslateService.configTranslate,
      multi: true,
      deps: [TranslateService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AgrinessTranslateInterceptor,
      multi: true,
    },
    {
      provide: TRANSLATE_INSTANT,
      deps: [AgrinessTranslateService],
      useFactory: TranslateInstantFactory,
    },
  ],
})
export class AgrinessTranslateModuleWithProviders {}
