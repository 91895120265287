export enum TypeProductionEnum {
  SWINES = 'swines',
  POULTRY = 'poultry',
  DAIRY = 'dairy',
  LAYERS = 'layers',
}

export const systemType: { [K in TypeProductionEnum]: string } = {
  [TypeProductionEnum.SWINES]: '95a5b8c5-fd06-4718-a440-d3024ff161e5',
  [TypeProductionEnum.POULTRY]: '32990f9d-43a5-4554-a645-e36720141df7',
  [TypeProductionEnum.DAIRY]: '6bc88e6c-3eb6-4fc2-86b9-58818ff945a1',
  [TypeProductionEnum.LAYERS]: '74901aea-b4ed-45db-8a42-6c98e34d78fc',
};
