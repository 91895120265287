import { Component } from '@angular/core';
import { environment } from '@env/environment.dev';

import { generalTabs } from './general.model';

@Component({
  templateUrl: './general.component.html',
})
export class BasicRecordsGeneralComponent {
  tabs = generalTabs[environment.typeProduction];
}
