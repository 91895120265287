export const configTableAnimalGroup: { [k: string]: string } = {
  farm_name: '240px',
  average_female_inventory: '140px',
  pigs_weaned_mated_female_year: '140px',
  litters_female_year: '140px',
  average_non_productive_days: '140px',
  average_wmi_days: '140px',
  farrowing_rate: '140px',
  average_born: '140px',
  average_born_alive: '140px',
  weaning_mortality_related: '140px',
  average_weaned_pigs: '140px',
  average_gestation_length: '140px',
  average_lactating_length: '140px',
  percentage_born_stillborn: '140px',
  percentage_born_mummified: '140px',
  weaning_mortality_period: '140px',
  percentage_heat_repetition: '140px',
  percentage_abortion: '140px',
  percentage_fake_pregnancy: '140px',
  percentage_death_gestation: '140px',
  average_heat_repetition_npd: '140px',
  average_abortion_npd: '140px',
  average_fake_pregnancy_npd: '140px',
  average_death_pregnancy_npd: '140px',
  average_culling_pregnancy_npd: '140px',
  average_culling_empty_npd: '140px',
  average_death_empty_npd: '140px',
  average_idle_days_npd: '140px',
};

export const indexesIgnoredOpenAnimalGroup = new Set([
  'average_female_inventory',
  'pigs_weaned_mated_female_year',
  'litters_female_year',
  'average_non_productive_days',
  'average_wmi_days',
  'farrowing_rate',
  'average_born',
  'average_born_alive',
  'weaning_mortality_related',
  'average_weaned_pigs',
  'average_gestation_length',
  'average_lactating_length',
  'percentage_born_stillborn',
  'percentage_born_mummified',
  'weaning_mortality_period',
  'percentage_heat_repetition',
  'percentage_abortion',
  'percentage_fake_pregnancy',
  'percentage_death_gestation',
  'average_heat_repetition_npd',
  'average_abortion_npd',
  'average_fake_pregnancy_npd',
  'average_death_pregnancy_npd',
  'average_culling_pregnancy_npd',
  'average_culling_empty_npd',
  'average_death_empty_npd',
  'average_idle_days_npd',
]);
