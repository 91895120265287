import { Search } from '@agriness/services';
import { Observable } from 'rxjs';

import { StructureProduction, StructureProductionFilter } from '../models/movement.model';

export abstract class StructureProductionAbstractService {
  abstract list(
    structureProductionFilter?: StructureProductionFilter,
  ): Observable<Search<StructureProduction>>;
}
