<section class="section is-full">
  <div class="page-title mb-4">
    {{ tabTitle | translate }}
  </div>

  <div *ngIf="getNumberOfStages() > 1" class="tabs mb-4">
    <ul class="nav nav-tabs nav-inline">
      <li class="nav-item" *ngFor="let tab of tabs">
        <a
          class="nav-link"
          [ngClass]="{ active: selectedStage === tab }"
          (click)="updateSelectedStage(tab)"
          [id]="'settings-targets-' + tab"
        >
          {{ tabTranslateKey + tab | translate }}
        </a>
      </li>
    </ul>
  </div>

  <div class="mb-2" *ngFor="let item of getReportsTargetsBySelectedStage()">
    <p class="report-title" *ngIf="getNumberOfReports() > 1">
      {{ reportTranslateKey + item.report | translate }}
    </p>
    <target-list (targetSelect)="handleTargetSelect($event)" [targets]="item.targets"></target-list>
  </div>

  <target-editor
    #editor
    *ngIf="targetSelected"
    [modalStatus]="modalStatus"
    [targetData]="targetSelected"
    (closed)="onEditorClosed()"
    (updateTarget)="updateTarget()"
    (restoreTarget)="restoreTarget()"
  ></target-editor>
</section>
