import { Inject, Injectable, InjectionToken } from '@angular/core';
import { KeycloakConfig } from 'keycloak-angular';

import { AgrinessServicesConfig } from '../../models/services-config.model';

export const SERVICES_CONFIG = new InjectionToken<AgrinessServicesConfig>(
  'AGRINESS_SERVICES_CONFIG',
);

@Injectable({
  providedIn: 'root',
})
export class ServicesConfigService implements AgrinessServicesConfig {
  readonly production: boolean;
  readonly apiMyAccount: string;
  readonly keycloak: KeycloakConfig;
  readonly tagManagerId: string;

  constructor(@Inject(SERVICES_CONFIG) private config: AgrinessServicesConfig) {
    Object.assign(this, config);
  }
}
