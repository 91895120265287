import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ModalStatusEnum } from '../../ag-modal.model';
import { ModalFeedbackContent } from '../ag-modal-feedback.model';
import { AgModalFeedbackService } from '../ag-modal-feedback.service';

@Component({
  selector: 'ag-modal-feedback-icon',
  templateUrl: './ag-modal-feedback-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgModalFeedbackIconComponent {
  private _status: ModalStatusEnum;

  @Input()
  set status(value: ModalStatusEnum) {
    this._status = value;
    this.feedback = this.feedbackService.getModal(value);
  }
  get status(): ModalStatusEnum {
    return this._status;
  }

  feedback: ModalFeedbackContent;
  ModalStatusEnum = ModalStatusEnum;

  constructor(private feedbackService: AgModalFeedbackService) {}
}
