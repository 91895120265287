import { AnimalGroups } from '@agriness/corp-app/services';
import { TypeProductionService, AgrinessTranslateService, StageEnum } from '@agriness/services';
import { SiteByStage } from '@agriness/services/analytics/analytics.model';
import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { getTypeProductionIcon } from '../../model/type-production-icon.model';

@Component({
  selector: 'corp-animal-group-link',
  templateUrl: './corp-animal-group-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpAnimalGroupLinkComponent implements OnChanges {
  @Input() animalGroup: AnimalGroups;
  route: string[];
  iconClass: string;
  iconStatusClass: string;
  stage: StageEnum;

  constructor(
    private typeProductionService: TypeProductionService,
    private translateService: AgrinessTranslateService,
    private analyticsService: AmplitudeAnalyticsService,
    private readonly path: ActivatedRoute,
  ) {}

  ngOnChanges(): void {
    this.route = this.getRoute();
    this.iconClass = this.getIconClass();
    this.iconStatusClass = this.getIconErrorClass();
  }

  // TODO : Revisar essa regra para erro de sincronismo
  hasError(): boolean {
    return this.typeProductionService.isLayers() || !this.animalGroup.farm_id;
  }

  getStatusMessage(): string {
    return this.translateService.instantWithFallback('agriness.feedback.support');
  }

  onClick(): void {
    this.stage = this.path.snapshot.data.stage as StageEnum;
    this.analyticsService.logRecordByAnimalGroup(
      SiteByStage[this.typeProductionService.get()][this.stage],
    );
  }

  private getRoute(): string[] {
    const farmId = this.animalGroup.farm_id;
    const animalGroupId = this.animalGroup.id;
    return ['/', this.typeProductionService.get(), 'record', farmId, animalGroupId];
  }

  private getIconClass(): string {
    return getTypeProductionIcon(this.typeProductionService.get(), this.animalGroup.is_open);
  }

  private getIconErrorClass(): string {
    if (this.typeProductionService.isLayers()) return '';
    return !this.hasError() ? 'icon-sync has-text-blue-5' : 'icon-inf-outline has-text-grey-4';
  }
}
