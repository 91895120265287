<ng-template #actions>
  <button (click)="downloadCsv()" class="btn btn-ghost has-round-hover">
    <span class="icon icon-csv"></span>
  </button>
</ng-template>
<ng-template #content>
  <ag-table
    *ngIf="data"
    #tableInstance
    [data]="data.data"
    [columns]="data.columns"
    [exportFilename]="data.downloadFilename"
    [scrollable]="true"
    [style]="{ width: 'auto' }"
    [rows]="tableRows"
    [noResultFeedback]="noResultFeedback"
    [scrollHeight]="tableHeight"
    [totalRecords]="data.data.length"
  ></ag-table>
</ng-template>
