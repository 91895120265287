import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { isEmpty, toLower } from 'lodash';
import * as _ from 'lodash';
import { LazyLoadEvent, PrimeTemplate, SortEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { ObjectUtils } from 'primeng/utils';

import { ValueFormatStyle } from '../../../pipe/value-format/value-format.model';
import { FeedbackEnum } from '../../feedback/ag-feedback.model';
import { getCellValueStyle } from '../cell-content/ag-table-cell-content.model';
import { TableColumn } from './ag-table.model';
import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import { ActivatedRoute } from '@angular/router';
import { StageEnum, TypeProductionService } from '@agriness/services';
import { SiteByStage } from '@agriness/services/analytics/analytics.model';

@Component({
  selector: 'ag-table',
  templateUrl: './ag-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgTableComponent implements AfterContentInit, OnChanges {
  @ViewChild('dt') table: Table;
  @Input() data: any[];
  @Input() selectedData: any[];
  @Input() isModal: boolean;

  @Input() set frozenColumns(value: TableColumn[]) {
    value.forEach(item => {
      item.id = this.getColumnId(item);
    });
    this._frozenColumns = value;
    this.frozenColumnWidth = `calc(${value.map(({ width }) => width).join(' + ')})`;
  }

  @Input() set columns(value: TableColumn[]) {
    value.forEach(item => {
      item.id = this.getColumnId(item);
    });

    value = this.addSpacerToTableEnd(value);

    this._columns = value;
  }

  /**
   * @deprecated use the style property in #{columns}
   */
  @Input() measurementUnit: string | ValueFormatStyle = ValueFormatStyle.TEXT;
  @Input() paginator = true;
  @Input() alwaysShowPagination = false;
  @Input() scrollable = true;
  @Input() loading = false;
  @Input() error = false;
  @Input() scrollHeight: string;
  @Input() style: string;
  @Input() rows = 10;
  @Input() totalRecords = 0;
  @Input() pageLinks = 10;
  @Input() rowsPerPageOptions = [10, 20, 30];
  @Input() noResultFeedback = FeedbackEnum.NOT_RESULT;
  @Input() dateFormat: string;
  /**
   * @deprecated pass template as a child element with `pTemplate="body"` directive
   */
  @Input() templateBody: TemplateRef<{
    $implicit: any[];
    columns: TableColumn[];
    frozenColumns: TableColumn[];
  }>;
  @Input() exportFunction;
  @Input() exportFilename = 'download';
  @Input() csvSeparator: string;

  @Input() trackEvent = false;

  @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;

  @Output() lazyLoad = new EventEmitter<LazyLoadEvent>();
  @Output() sort = new EventEmitter<SortEvent>();
  @Output() rowSelect = new EventEmitter<SortEvent>();
  @Output() rowUnselect = new EventEmitter<SortEvent>();
  @Output() headerCheckboxToggle = new EventEmitter<SortEvent>();

  private _columns: TableColumn[];
  private _frozenColumns: TableColumn[];

  idSpacerColumn = 'column-ag-table-spacer';
  frozenColumnWidth = '0px';
  showPagination = false;
  templateBodyCell: TemplateRef<{ column: TableColumn; data: any; rowData: any }>;
  templateBodyCellContent: TemplateRef<{ column: TableColumn; data: any; rowData: any }>;
  typeFeedback = FeedbackEnum.LOADING;

  stage: StageEnum;
  stagesOrdering = ['nursery', 'finishing'];

  get columns(): TableColumn[] {
    return this._columns;
  }

  get frozenColumns(): TableColumn[] {
    return this._frozenColumns;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private analyticsService: AmplitudeAnalyticsService,
    public typeProductionService: TypeProductionService,
  ) {}

  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'body':
          this.templateBody = item.template;
          break;
        case 'bodycell':
          this.templateBodyCell = item.template;
          break;
        case 'bodycellcontent':
          this.templateBodyCellContent = item.template;
          break;
      }
    });
    if (this.templateBody) {
      console.warn(
        'Component "ag-table" received template "body". Use "bodycell" or "bodycellcontent" instead.',
      );
    }
  }

  ngOnChanges() {
    if (!isEmpty(this._frozenColumns) && isEmpty(this._columns)) {
      this._columns = this.addSpacerToTableEnd(this._frozenColumns);
      this._frozenColumns = [];
      this.frozenColumnWidth = '0px';
    } else {
      this._columns = this.addSpacerToTableEnd(this._columns);
      this.frozenColumnWidth = `calc(${this._frozenColumns
        ?.map(({ width }) => width)
        .join(' + ')})`;
    }
    this.updateFeedback();
    this.updateShowPagination();
  }

  reset() {
    if (this.table) {
      this.table.reset();
    }
  }

  exportCSV(options?: any) {
    let data = this.table.filteredValue || this.table.value;
    let columns = this.table.columns;
    let csv = '\ufeff';

    if (this.table.frozenValue) {
      data = this.table.frozenValue.concat(data);
    }

    if (this.table.frozenColumns) {
      columns = this.frozenColumns.concat(columns);
    }

    if (options && options.selectionOnly) {
      data = this.table.selection || [];
    }

    // headers
    for (let item = 0; item < columns.length; item++) {
      const column = columns[item];
      if (column.exportable !== false && column.field) {
        csv += '"' + (column.header || column.field) + '"';

        if (item < columns.length - 1) {
          csv += this.csvSeparator;
        }
      }
    }

    // body
    data.forEach(record => {
      csv += '\n';
      for (let item = 0; item < columns.length; item++) {
        const column = columns[item];
        if (column.exportable !== false && column.field) {
          let cellData = ObjectUtils.resolveFieldData(record, column.field);

          if (cellData != null) {
            if (this.exportFunction) {
              cellData = this.exportFunction({
                data: cellData,
                field: column.field,
              });
            } else {
              cellData = String(cellData).replace(/"/g, '""');
            }
          } else {
            cellData = '';
          }

          csv += '"' + cellData + '"';

          if (item < columns.length - 1) {
            csv += this.csvSeparator;
          }
        }
      }
    });

    const blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;',
    });

    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, this.exportFilename + '.csv');
    } else {
      const link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      if (link.download !== undefined) {
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', this.exportFilename + '.csv');
        link.click();
      } else {
        csv = 'data:text/csv;charset=utf-8,' + csv;
        window.open(encodeURI(csv));
      }
      document.body.removeChild(link);
    }
  }

  updateShowPagination() {
    this.showPagination =
      this.alwaysShowPagination ||
      (this.paginator && this.rowsPerPageOptions.some(item => item < this.totalRecords));
  }

  updateFeedback() {
    if (this.error) {
      this.typeFeedback = FeedbackEnum.ERROR;
    } else if (this.loading) {
      this.typeFeedback = FeedbackEnum.LOADING;
    } else if (isEmpty(this.data)) {
      this.typeFeedback = this.noResultFeedback;
    } else {
      this.typeFeedback = null;
    }
  }

  isLazyLoad() {
    return this.lazyLoad.observers.length > 0;
  }

  hasCustomSort() {
    return this.sort.observers.length > 0;
  }

  getColumnId(column: TableColumn): string {
    return column.id ? column.id : `column-${column.field.toLowerCase().replace(/_/g, '-')}`;
  }

  getCellClass(col: TableColumn, data: any): string {
    if (col.width != null) {
      const style = getCellValueStyle(col, data, this.measurementUnit);
      if (style === ValueFormatStyle.UNITARY || style === ValueFormatStyle.DECIMAL) {
        return 'text-right';
      }
    }
  }

  addSpacerToTableEnd(columns: TableColumn[]): TableColumn[] {
    if (!this.templateBody && columns.length && columns.every(item => item.width != null)) {
      return [
        ...columns,
        {
          id: this.idSpacerColumn,
          field: '',
          header: '',
          sortable: false,
        },
      ];
    }
    return columns;
  }

  onLazyLoad(event: LazyLoadEvent) {
    // the timeout avoid a ExpressionChangedAfterItHasBeenCheckedError
    // when some component prop is changes by the lazyLoad callback
    setTimeout(() => this.lazyLoad.emit(event));
  }

  onSort(event: SortEvent) {
    this.sort.emit(event);
  }

  onClick(field: string) {
    if (this.trackEvent) {
      this.stage = this.route.snapshot.data.stage;
      if (this.stagesOrdering.includes(this.stage)) {
        this.analyticsService.logAnimalGroupClickOrdering(
          SiteByStage[this.typeProductionService.get()][this.stage],
          field,
        );
      } else {
        this.analyticsService.logFarmClickOrdering(field);
      }
    }
  }

  onRowSelect(event: SortEvent) {
    this.rowSelect.emit(event);
  }

  onRowUnselect(event: SortEvent) {
    this.rowUnselect.emit(event);
  }

  onHeaderCheckboxToggle(event: SortEvent) {
    this.headerCheckboxToggle.emit(event);
  }

  isFrozenColumn(column: TableColumn) {
    if (!this.frozenColumns) {
      return false;
    }

    return this.frozenColumns.indexOf(column) > -1;
  }

  getTooltipText(data: any): string {
    if (data.value) {
      return data.value;
    }

    return data;
  }
}
