import { Target } from '@agriness/corp-app/services';
import { IndexTranslateService } from '@agriness/corp-app/shared/services/index-translate.service';
import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import {
  TargetSubtitlesService,
  TargetData,
  ValueFormatPipe,
  RepresentationTypeEnum,
  ModalStatusEnum,
} from '@agriness/ui';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { max, min } from 'lodash';

import { ControlErrors, RangeValidation, TargetForm } from '../targets.model';
@Component({
  selector: 'target-editor',
  templateUrl: './target-editor.component.html',
  styleUrls: ['./target-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TargetEditorComponent implements OnInit, OnChanges {
  @Input() targetData: Target;
  @Input() modalStatus: ModalStatusEnum;

  @Output() closed = new EventEmitter<void>();
  @Output() updateTarget = new EventEmitter<void>();
  @Output() restoreTarget = new EventEmitter<void>();

  targetSubtitles: TargetData;

  target: string;
  title: string;
  decimalPlaces: number;

  representationType = RepresentationTypeEnum;

  visible = false;
  inputWidth = '180px';

  translationContext = 'agriness.settings.targets.editor';

  targetFormErrors = {
    target_value: '',
    min_recommended: '',
    max_recommended: '',
    value_variation_rate: '',
  };

  targetForm = new FormGroup({
    target_value: new FormControl(0, [
      Validators.required.bind(this),
      this.rangeValidationTargetValue(),
    ]),
    value_variation_rate: new FormControl(0, [
      Validators.required.bind(this),
      this.rangeValidationValueVariationRate(),
    ]),
    min_allowed: new FormControl(0, [Validators.required.bind(this)]),
    max_allowed: new FormControl(0, [Validators.required.bind(this)]),
    min_recommended: new FormControl(0, [
      Validators.required.bind(this),
      this.rangeValidationRecommended('min_recommended'),
    ]),
    max_recommended: new FormControl(0, [
      Validators.required.bind(this),
      this.rangeValidationRecommended('max_recommended'),
    ]),
  });

  orderedValueKeys = [
    'min_allowed',
    'min_recommended',
    'minTargetWithVariation',
    'maxTargetWithVariation',
    'max_recommended',
    'max_allowed',
  ];

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private indexTranslate: IndexTranslateService,
    private targetSubtitlesService: TargetSubtitlesService,
    private valueFormat: ValueFormatPipe,
  ) {}

  ngOnInit(): void {
    this.loadTargetRepresentationAndSubtitles();
    this.listenValueChanges();
  }

  ngOnChanges(): void {
    this.updatedFormTarget();
    this.loadTargetRepresentationAndSubtitles();
  }

  format(value: number, control?: string): string {
    let { decimal_places, measurement_unit } = this.targetData.index;
    if (control === 'value_variation_rate') {
      decimal_places = 2;
      measurement_unit = 'decimal';
    }

    return this.valueFormat.transform(value, measurement_unit, decimal_places, 'hide-symbol');
  }

  getFieldErrorMessage(controlErrors: ControlErrors, control: string): string {
    const { range, required } = controlErrors;

    if (required) return this.t('agriness.settings.validation_required');

    if (range) {
      const { min, max } = range;

      if (min == max) {
        return this.t(`${this.translationContext}.validation_feedback_equal`, {
          value: this.format(min, control),
        });
      } else if (min < max) {
        return this.t(`${this.translationContext}.validation_feedback`, {
          min: this.format(min, control),
          max: this.format(max, control),
        });
      }
    }
  }

  getForm(): TargetForm {
    return this.targetForm.getRawValue() as TargetForm;
  }

  getIndexName(target: Target): string {
    return this.indexTranslate.instant(
      target.index.key,
      ['agriness.performances', 'agriness.animal_group_list'],
      target.stage,
    );
  }

  getValues(): { [k: string]: number } {
    const formValues = this.targetForm.getRawValue() as TargetForm;
    const [
      minTargetWithVariation,
      maxTargetWithVariation,
    ] = this.targetSubtitlesService.calculateTargetWithVariation(formValues as TargetData);
    return {
      ...formValues,
      minTargetWithVariation,
      maxTargetWithVariation,
    };
  }

  getValidFieldsValues(names: string[]): number[] {
    return names
      .filter(name => {
        if (['minTargetWithVariation', 'maxTargetWithVariation'].includes(name)) {
          return (
            this.targetForm.controls['target_value'].valid &&
            this.targetForm.controls['value_variation_rate'].valid
          );
        }
        return this.targetForm.controls[name].valid;
      })
      .map(name => this.getValues()[name]);
  }

  listenValueChanges(): void {
    for (const control in this.targetForm.controls) {
      this.targetForm.controls[control].valueChanges.subscribe((result: number) => {
        this.targetSubtitles = {
          ...this.targetSubtitles,
          [control]: result,
        };

        for (const key in this.targetForm.controls) {
          this.targetForm.controls[key].updateValueAndValidity({ emitEvent: false });
        }

        this.updateErrorMessages();
      });
    }
  }

  loadTargetRepresentationAndSubtitles(): void {
    this.target = this.targetData.representation_type;
    this.targetSubtitles = this.targetData;

    this.showModal();
  }

  onClosed(): void {
    this.closed.emit();
  }

  onRestore(): void {
    this.restoreTarget.emit();
  }

  onSubmit(): void {
    this.updateTarget.emit();
  }

  onUpdateDecimalPlaces(): void {
    this.decimalPlaces = this.targetData.index.decimal_places;
  }

  onUpdateTitleModal(): void {
    this.title = this.getIndexName(this.targetData);
  }

  rangeValidationRecommended(controlName: string): ValidatorFn {
    return (): RangeValidation | null => {
      if (!this.targetForm) return;

      const value = this.targetForm.controls[controlName].value as number;
      const [previous, next] = this.splitArrayAt(this.orderedValueKeys, controlName);
      const previousMax = max(this.getValidFieldsValues(previous));
      const nextMin = min(this.getValidFieldsValues(next));

      return value < previousMax || value > nextMin
        ? {
            range: {
              min: previousMax,
              max: nextMin,
            },
          }
        : null;
    };
  }

  rangeValidationTargetValue(): ValidatorFn {
    return (): RangeValidation | null => {
      if (!this.targetForm) return;

      const formValues = this.targetForm.getRawValue() as TargetForm;
      const { value_variation_rate = 0 } = formValues;

      const [
        minTargetWithVariation,
        maxTargetWithVariation,
      ] = this.targetSubtitlesService.calculateTargetWithVariation(formValues as TargetData);

      const [previous] = this.splitArrayAt(this.orderedValueKeys, 'minTargetWithVariation');
      const [, next] = this.splitArrayAt(this.orderedValueKeys, 'maxTargetWithVariation');
      const previousMax = max(this.getValidFieldsValues(previous));
      const nextMin = min(this.getValidFieldsValues(next));

      // Mix/max suggestion based on previous, next and varition rate
      const minTargetValue = this.toIndexPrecision(
        previousMax / (-value_variation_rate / 100 + 1),
        Math.ceil,
      );
      const maxTargetValue = this.toIndexPrecision(
        nextMin / (value_variation_rate / 100 + 1),
        Math.floor,
      );

      let minValue = max([minTargetValue, previousMax]);
      let maxValue = min([maxTargetValue, nextMin]);
      if (minValue > maxValue) {
        minValue = previousMax;
        maxValue = nextMin;
      }

      return minTargetWithVariation < previousMax || maxTargetWithVariation > nextMin
        ? {
            range: {
              min: minValue,
              max: maxValue,
            },
          }
        : null;
    };
  }

  rangeValidationValueVariationRate(): ValidatorFn {
    return (): RangeValidation | null => {
      if (!this.targetForm) return;

      const formValues = this.targetForm.getRawValue() as TargetForm;
      const { target_value, value_variation_rate = 0 } = formValues;

      const [
        minTargetWithVariation,
        maxTargetWithVariation,
      ] = this.targetSubtitlesService.calculateTargetWithVariation(formValues as TargetData);

      const [previous] = this.splitArrayAt(this.orderedValueKeys, 'minTargetWithVariation');
      const [, next] = this.splitArrayAt(this.orderedValueKeys, 'maxTargetWithVariation');
      const previousMax = max(this.getValidFieldsValues(previous));
      const nextMin = min(this.getValidFieldsValues(next));

      // Mix/max suggestion based on previous, next and variation rate
      const minValueVariationRate = this.toIndexPrecision(
        (-(previousMax / target_value) + 1) * 100,
        Math.ceil,
        2,
      );
      const maxValueVariationRate = this.toIndexPrecision(
        (nextMin / target_value - 1) * 100,
        Math.floor,
        2,
      );

      const minValue = 0;
      const maxValue = min([minValueVariationRate, maxValueVariationRate]);

      const outsideMinMax = () =>
        value_variation_rate < minValue || value_variation_rate > maxValue;
      const outsidePreviousNext = () =>
        minTargetWithVariation < previousMax || maxTargetWithVariation > nextMin;

      return outsideMinMax() || outsidePreviousNext()
        ? {
            range: {
              min: minValue,
              max: maxValue,
            },
          }
        : null;
    };
  }

  setEditorValues(newTarget: Target): void {
    for (const key in this.targetForm.controls) {
      this.targetForm.controls[key].setValue(newTarget[key]);
    }
  }

  showModal(): void {
    this.visible = true;

    this.onUpdateTitleModal();
    this.onUpdateDecimalPlaces();
  }

  splitArrayAt<T>(array: T[], element: T): T[][] {
    const index = array.indexOf(element);
    return [array.slice(0, index), array.slice(index + 1)];
  }

  toIndexPrecision(value: number, roundFn: (x: number) => number, places?: number): number {
    const adjust = 10 ** (places ?? this.decimalPlaces);
    return roundFn(value * adjust) / adjust;
  }

  updatedFormTarget(): void {
    const targetDataUpdatedForm = this.processDataForUseInForm();

    return this.targetForm.patchValue(targetDataUpdatedForm);
  }

  private updateErrorMessages(): void {
    for (const control in this.targetForm.controls) {
      const { errors } = this.targetForm.controls[control];
      this.targetFormErrors[control] = errors
        ? this.getFieldErrorMessage(errors as ControlErrors, control)
        : '';
    }
  }

  private processDataForUseInForm(): TargetForm {
    if (!this.targetData) return;

    const {
      target_value,
      value_variation_rate,
      min_allowed,
      max_allowed,
      min_recommended,
      max_recommended,
    } = this.targetData;

    return {
      target_value,
      value_variation_rate,
      min_allowed,
      max_allowed,
      min_recommended,
      max_recommended,
    };
  }
}
