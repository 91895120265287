export interface ConsumptionData {
  average_daily_consumption: number[];
  average_daily_consumption_target: number[];
  difference: number[];
  difference_percentage: number[];
  nutrition_type: string[];
  date: string[];
}

export enum ConsumptionDataEnum {
  NUTRITION_TYPE = 'nutrition_type',
  AVERAGE_DAILY_CONSUMPTION = 'average_daily_consumption',
  AVERAGE_DAILY_CONSUMPTION_TARGET = 'average_daily_consumption_target',
  DIFFERENCE = 'difference',
  DIFFERENCE_PERCENTAGE = 'difference_percentage',
}
