import { AnimalGroup, AnimalGroupMortalitySwines } from '@agriness/corp-app/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import {
  DeathTableCell,
  DeathTableColumn,
  DeathTableData,
  DeathTableDataCause,
  DeathTableFooter,
  DeathTableFooterCell,
  DeathTableRow,
} from '@agriness/ui';
import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges } from '@angular/core';
import { sortBy } from 'lodash';

import { SanitySwinesService } from './sanity-swines.service';

@Component({
  selector: 'corp-sanity-swines',
  templateUrl: './sanity-swines.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SanitySwinesComponent implements OnChanges {
  @Input() animalGroup: AnimalGroup;
  @Input() mortality: AnimalGroupMortalitySwines;

  rows: DeathTableRow[];
  columns: DeathTableColumn[];
  rowsData: DeathTableData;
  footerData: DeathTableFooter;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private sanitySwinesService: SanitySwinesService,
  ) {}

  ngOnChanges(): void {
    if (this.mortality) {
      this.updateMortalityWithMaxWeeks();
      this.updateData();
    }
  }

  updateMortalityWithMaxWeeks(): void {
    this.mortality = this.sanitySwinesService.getMortalityWithMaxWeeks(
      this.animalGroup.stage,
      this.mortality,
    );
  }

  updateData(): void {
    this.updateRows();
    this.updateColumns();
    this.updateRowsData();
    this.updateFooterData();
  }

  updateRows(): void {
    this.rows = Object.keys(this.mortality.total.by_reason).map(r => ({
      field: r,
      name: this.t(`agriness.sanity.${r}`),
    }));

    this.rows = sortBy(this.rows, r => (r.field === 'cause_no_group' ? 'zzzz' : r.name));
  }

  updateColumns(): void {
    const numberOfWeeks = this.sanitySwinesService.getNumberOfWeeks(this.animalGroup.stage);

    this.columns = new Array(numberOfWeeks).fill(null).map((x, i) => ({
      field: `${i + 1}`,
    }));
  }

  updateRowsData(): void {
    this.rowsData = {};

    for (const cause of this.rows) {
      this.rowsData[cause.field] = this.getCauseData(cause);
    }
  }

  getCauseData(cause: DeathTableRow): DeathTableDataCause {
    return {
      ...this.getCauseByPeriod(cause),
      total: this.getCauseTotal(cause),
    };
  }

  getCauseByPeriod(cause: DeathTableRow): DeathTableDataCause {
    const causeDataByPeriod: DeathTableDataCause = {};
    for (const period of this.columns) {
      let causeDataOfPeriod: DeathTableCell = {
        value: 0,
      };

      const periodRawData = this.mortality.weekly[period.field];
      if (periodRawData) {
        const causeRawDataOfPeriod = periodRawData.by_reason[cause.field];
        if (causeRawDataOfPeriod) {
          causeDataOfPeriod = {
            value: causeRawDataOfPeriod.value,
          };
        }
      }

      causeDataByPeriod[period.field] = causeDataOfPeriod;
    }
    return causeDataByPeriod;
  }

  private getCauseTotal(cause: DeathTableRow): DeathTableCell {
    return this.mortality.total.by_reason[cause.field];
  }

  private updateFooterData() {
    this.footerData = {
      ...this.getTotalByPeriod(),
      total: this.getTotal(),
    };
  }

  private getTotalByPeriod() {
    const totalByPeriod: { [period: string]: DeathTableFooterCell } = {};
    for (const period of this.columns) {
      let periodTotal: DeathTableFooterCell = {
        value: 0,
        percentage: 0,
      };

      const periodRawData = this.mortality.weekly[period.field];
      if (periodRawData) {
        periodTotal = periodRawData.total;
      }
      totalByPeriod[period.field] = periodTotal;
    }
    return totalByPeriod;
  }

  private getTotal(): DeathTableFooterCell {
    return this.mortality.total;
  }
}
