import { Search, TypeProductionService, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { Region, RegionFilter, RegionForm } from '../models/region.model';
import { RegionAbstractService } from './region-abstract.service';

@Injectable()
export class RegionService extends RegionAbstractService {
  private pathBase = 'holdings/{holding_id}/regions/';
  private pathRegionId = 'holdings/{holding_id}/regions/{region_id}/';
  private defaultPageSize = 10;

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {
    super();
  }

  list({
    page = 1,
    page_size = this.defaultPageSize,
    queryCorpOnly = false,
    stage,
    ...filters
  }: RegionFilter): Observable<Search<Region>> {
    const url = this.getUrl();
    const params = {
      ...filters,
      page,
      page_size,
      system_type: this.typeProductionService.get(),
      stage,
      query_corp_only: queryCorpOnly,
    };

    return this.apiCorpService.get<Search<Region>>(url, params);
  }

  save(regionForm: RegionForm): Observable<Region> {
    const url = this.getUrl();
    const params = {
      ...regionForm,
      system_type: this.typeProductionService.getId(),
    };

    return this.apiCorpService.post<Region>(url, params);
  }

  update(regionForm: RegionForm, regionId: string): Observable<Region> {
    const url = this.getUrl(regionId);
    const params = {
      ...regionForm,
      system_type: this.typeProductionService.getId(),
    };

    return this.apiCorpService.put<Region>(url, params);
  }

  delete(region: Region): Observable<void> {
    const url = this.getUrl(region.id);
    return this.apiCorpService.delete(url);
  }

  private getUrl(regionId?: string): string {
    return this.apiCorpService.translateUrl(regionId ? this.pathRegionId : this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
      region_id: regionId,
    });
  }
}
