import { LookerAttribute } from '@agriness/corp-app/services/models/looker-panel.model';
import { LanguageEnum, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LookerAttributeService {
  email = this.user.getUserProfile().email;
  locale = this.setLocale();
  farmuser_id = this.user.getUserProfile().id;
  holding_id = this.user.getCurrentHolding().id;

  constructor(private user: UserStorageService) {}

  setLocale(): string {
    const locale = Object.values(LanguageEnum).includes(this.user.getUserProfile().language)
      ? this.user.getUserProfile().language
      : LanguageEnum.PTBR;
    return locale.replace('-', '_');
  }

  resolve(atributes: LookerAttribute[]): { [k: string]: unknown } {
    const attributeValue: { [k: string]: unknown } = {};
    for (const item of atributes) {
      attributeValue[item.name] = this[item.name];
    }
    return attributeValue;
  }
}
