import { PerformanceData } from '@agriness/corp-app/record/animal-group/performance/performance.model';
import { ChartResizeService } from '@agriness/corp-app/services/chart-resize/chart-resize.service';
import { CorpPerformanceGraphService } from '@agriness/corp-app/shared/component/corp-performance-graph/corp-performance-graph.service';
import {
  Highcharts,
  HighchartsService,
} from '@agriness/corp-app/shared/services/highcharts.service';
import { AgrinessTranslateService } from '@agriness/services';
import { AgFeedbackService, ValueFormatPipe } from '@agriness/ui';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Chart, SeriesOptionsType } from 'highcharts';

import { ReproductivePerformanceAnalysisGraphService } from './reproductive-performance-analysis-graph.service';

@Component({
  selector: 'reproductive-performance-analysis-graph-component',
  templateUrl: './reproductive-performance-analysis-graph.component.html',
  providers: [CorpPerformanceGraphService, ReproductivePerformanceAnalysisGraphService],
})
export class ReproductivePerformanceAnalysisGraphComponent implements OnChanges, OnDestroy {
  @Input()
  performanceData: PerformanceData;

  @Output()
  chartInstance = new EventEmitter<Chart>();

  Highcharts: Highcharts;
  chartOptions: Highcharts.Options;
  chart: Chart;

  private series: Highcharts.SeriesOptionsType[];

  constructor(
    public chartResizer: ChartResizeService,
    private translate: AgrinessTranslateService,
    private feedbackService: AgFeedbackService,
    private valueFormat: ValueFormatPipe,
    private highchartsService: HighchartsService,
    private chartService: ReproductivePerformanceAnalysisGraphService,
  ) {
    this.Highcharts = highchartsService.getHighchartsInstance();
  }

  ngOnChanges(): void {
    this.updateChartOptions();
  }

  ngOnDestroy(): void {
    this.chartResizer.destroy();
  }

  changeSelectedSeries(event: SeriesOptionsType[]): void {
    this.chartOptions.series = event;
    this.chart.options.series = event;
  }

  registerChart(chart: Chart): void {
    this.chartResizer.registerChartInstance(chart);
    this.chart = chart;
    this.chartInstance.emit(chart);
  }

  private updateChartOptions(): void {
    this.chartOptions = this.chartService.getChartOptions(this.performanceData);
  }
}
