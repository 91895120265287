import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-payment-confirm-modal',
  templateUrl: './payment-confirm-modal.component.html',
  styleUrls: ['./payment-confirm-modal.component.scss'],
})
export class PaymentConfirmModalComponent {
  @Output() confirm = new EventEmitter();
  @Output() reject = new EventEmitter();

  translationContext = 'agriness.animal_group_record.payment-form.modal_confirm';
}
