import { NoBondEnum } from '@agriness/corp-app/services';
import { StageEnum, TypeProductionService } from '@agriness/services';
import { SiteByStage } from '@agriness/services/analytics/analytics.model';
import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ParentScrollPin } from '../../common/parent-scroll-pin';

@Component({
  selector: 'ag-multiselect',
  templateUrl: 'ag-multiselect.component.html',
})
export class AgMultiselectComponent implements OnDestroy, OnChanges, OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('select', { static: true }) select: NgSelectComponent;
  @Output() selectValueFilter = new EventEmitter<any[]>();
  @Output() searchChange = new EventEmitter<string>();
  @Output() scrollToEnd = new EventEmitter<void>();
  @Output() selectAll = new EventEmitter<void>();
  @Input() lastCount: number = 0;
  @Input() loadAll: boolean;
  @Input() itemsSelect: any[] = [];
  @Input() items$: Observable<any> = from([]);
  @Input() msgItemsSelects = 'agriness.multi_select_filter.items_select';
  @Input() controlName: string;
  @Input() disabled = false;
  @Input() optionLabel = 'name';
  @Input() dataKey = 'id';
  @Input() group: FormGroup;
  @Input() inputId: string;
  @Input() styleClass: string;
  @Input() defaultLabel = 'agriness.select';
  @Input() loading = false;
  @Input() notFoundText = 'agriness.no_registry';
  @Input() loadingText = 'agriness.loading';
  @Input() clearable = true;
  @Input() clearText = 'agriness.clear_all';
  @Input() canAddItem = false;
  @Input() isFarmFilter = false;
  @Input() errorMessage: string;
  @Input() subtitlesCache: Record<string, string>;
  items = [];
  allItems = [];
  maxItemsLabel = 1;
  searchValue = '';
  stage: StageEnum;
  stageEvent = ['reproductive', 'nursery', 'finishing'];
  isEmpty = false;
  customItems$: Observable<any> = from([]);
  hasAdded = false;
  manualAddedValues = [];
  isSelectedAll = false;

  private parentScrollPin: ParentScrollPin;

  constructor(
    elementRef: ElementRef<HTMLElement>,
    private readonly route: ActivatedRoute,
    private analyticsService: AmplitudeAnalyticsService,
    public typeProductionService: TypeProductionService,
    private t: TranslateService,
    private cd: ChangeDetectorRef,
  ) {
    this.parentScrollPin = new ParentScrollPin(elementRef);
  }
  ngOnDestroy(): void {
    this.parentScrollPin.destroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.processList();
  }

  ngOnInit(): void {
    this.items$.pipe(map(options => options)).subscribe(options => {
      this.items = options;
      this.allItems = options;

      if (this.allItems.length >= 1) {
        if (this.allItems.length < this.lastCount && this.isSelectAll()) {
          this.selectAll.emit();
        }
      }

      this.cd.detectChanges();
    });
  }

  isSelectAll() {
    return false;
    /*  if (this.controlName == 'farm' || this.controlName == 'producer') return true;
    else return false; */
  }

  processList(): void {
    this.items$
      .pipe(
        map((e: unknown[]) => {
          if (e.length > 0) {
            this.manualAddedValues.forEach(element => {
              if (!e.includes(element) && element.name !== '') {
                e.unshift(element);
              }
            });
          }

          if (
            this.isFarmFilter &&
            !e.includes(this.t.instant('agriness.settings.farms.filters.no-bond-filter'))
          ) {
            e.unshift({
              id: NoBondEnum.NO_BOND,
              name: this.t.instant('agriness.settings.farms.filters.no-bond-filter'),
            });
            this.subtitlesCache[NoBondEnum.NO_BOND] = this.t.instant(
              'agriness.settings.farms.filters.no-bond-filter',
            );
          }

          return e;
        }),
      )
      .subscribe(res => {
        if (res.length === 0 && this.canAddItem && this.searchValue.length > 0) {
          this.isEmpty = true;
          this.customItems$ = new BehaviorSubject([
            {
              name: this.searchValue,
            },
          ]);
        } else {
          this.isEmpty = false;
        }

        if (
          res.filter(
            (a: { id: string; name: string }) =>
              a.name ===
              (this.t.instant('agriness.settings.farms.filters.no-bond-filter') as string),
          ).length > 1
        ) {
          res.splice(0, 1);
        }
      });
  }

  onOpen(): void {
    this.parentScrollPin.pin();
    _.defer(() => this.searchInput.nativeElement.focus());
    this.stage = this.route.snapshot.data.stage;
    if (this.stageEvent.includes(this.stage)) {
      this.analyticsService.logFilters(
        SiteByStage[this.typeProductionService.get()][this.stage],
        this.controlName,
      );
    }
  }

  onChange(value): void {
    this.selectValueFilter.emit(value);
    this.parentScrollPin.updatePin();
    this.verifyIfIsSelectedAll();
  }

  onSelectAll(): void {
    const control = this.group.controls[this.controlName];

    if (control.value.length == this.items.length) {
      control.setValue('');
    } else {
      control.setValue(this.items.map(item => item.id));
    }

    this.verifyIfIsSelectedAll();
  }

  onClose(): void {
    this.parentScrollPin.unpin();
    if (this.isSelectAll()) this.items = this.allItems;
  }

  onRemove(): void {
    this.searchInput.nativeElement.focus();
  }

  onAdd(event?: string) {
    if (event) {
      this.itemsSelect.push(event);
    }
    if (this.isEmpty) {
      this.hasAdded = true;
      this.manualAddedValues.push({
        name: this.searchValue,
      });
      this.onSearch({ target: { value: '' } });
    }
  }

  onSearch(event): void {
    if (this.isSelectAll()) {
      this.items = this.allItems.filter(item =>
        item.name.toLowerCase().includes(event.target.value.toLowerCase()),
      );
      this.verifyIfIsSelectedAll();
    } else {
      this.filter(event.target.value);
    }
  }

  filter(value: string) {
    this.searchValue = value;
    if (this.searchChange.observers.length) {
      this.searchChange.emit(this.searchValue);
    } else {
      this.select.filter(this.searchValue);
    }
  }

  onScrollToEnd(): void {
    if (this.allItems.length >= this.lastCount) return;
    this.scrollToEnd.emit();
  }

  verifyIfIsSelectedAll() {
    const control = this.group.controls[this.controlName];

    if (control.value.length == this.items.length) this.isSelectedAll = true;
    else this.isSelectedAll = false;
  }
}
