import { SectionModel } from '@agriness/ui';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class ProductionParamsSectionService {
  getSections(): SectionModel[] {
    const prefix = `/${environment.typeProduction}/settings/production-params`;
    const sections: SectionModel[] = [
      {
        label: 'agriness.settings.production_params.production.title',
        id: 'section-production-params-production',
        routerLink: `${prefix}/production`,
      },
    ];
    return sections;
  }
}
