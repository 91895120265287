import { StageEnum, TypeProductionEnum, UserHoldingRelationType } from '@agriness/services';
import { AgSideMenuModel } from '@agriness/ui';

const presence: AgSideMenuModel = {
  iconClass: 'icon-presence',
  id: 'presence',
  name: 'agriness.sidebar.support.title',
};

const records: AgSideMenuModel = {
  iconClass: 'icon-report',
  id: 'record',
  link: 'record',
  name: 'agriness.records',
};

const planning: AgSideMenuModel = {
  iconClass: 'icon-checklist',
  id: 'planning',
  link: 'planning',
  name: 'agriness.sidebar.planning.title',
};

const settings: AgSideMenuModel = {
  iconClass: 'icon-settings',
  id: 'settings',
  name: 'agriness.sidebar.settings.title',
  userHoldingRelationType: [UserHoldingRelationType.OWNER, UserHoldingRelationType.PARTNER],
};

const basicRecords: AgSideMenuModel = {
  iconClass: 'icon-guide',
  id: 'basic-records',
  name: 'agriness.sidebar.settings.basic_records',
  link: 'basic-records',
};

const farms: AgSideMenuModel = {
  iconClass: 'icon-farm',
  id: 'farms',
  name: 'agriness.sidebar.settings.farms',
  link: 'farms',
};

const targets: AgSideMenuModel = {
  iconClass: 'icon-health',
  id: 'targets',
  name: 'agriness.sidebar.settings.targets',
  link: 'targets',
};

const targetsV2: AgSideMenuModel = {
  iconClass: 'icon-health',
  id: 'targetsnews',
  name: 'agriness.sidebar.settings.targetsv2',
  link: 'targetsnews',
};

const paymentRules: AgSideMenuModel = {
  iconClass: 'icon-calculators',
  id: 'payment_rules',
  name: 'agriness.sidebar.settings.payment_rules',
  link: 'bonification',
};

const productionParams: AgSideMenuModel = {
  iconClass: 'icon-user-ico',
  id: 'production_params',
  name: 'agriness.sidebar.settings.production_params',
  link: 'production-params',
};

const presence_setting: AgSideMenuModel = {
  iconClass: 'icon-presence',
  id: 'presence_settings',
  name: 'agriness.sidebar.settings.presence',
  link: 'presence',
};

export const IdsByProductionType: { [k in TypeProductionEnum]: string } = {
  [TypeProductionEnum.SWINES]: '6bc88e6c-3eb6-4fc2-86b9-58818ff945a1',
  [TypeProductionEnum.POULTRY]: 'ef8aca88-ebe9-4f39-90c2-88391cf4bc1e',
  [TypeProductionEnum.DAIRY]: '4cb131a0-a6d9-4b0b-882e-4ca17b67dae9',
  [TypeProductionEnum.LAYERS]: '8248950c-3f29-4d1b-8c00-5f2557d7ed91',
};

export const SwineSubmenuOrder: { [k in StageEnum]: number } = {
  [StageEnum.REPRODUCTIVE]: 0,
  [StageEnum.NURSERY]: 1,
  [StageEnum.FINISHING]: 2,
  [StageEnum.WEAN_TO_FINISH]: 3,
  [StageEnum.GROW_OUT]: 4,
  [StageEnum.MILKING]: 5,
  [StageEnum.LAYERS_PRODUCTION]: 6,
};

export const appInfoByProduction: { [k in TypeProductionEnum]: AppInfo } = {
  [TypeProductionEnum.SWINES]: {
    typeProduction: TypeProductionEnum.SWINES,
    productId: IdsByProductionType.swines,
    iconTitle: 'swine-logo',
    itemsMenu: [
      {
        id: 'production',
        name: 'agriness.sidebar.production.title',
        iconClass: 'icon-farm',
        subItem: [
          {
            id: StageEnum.REPRODUCTIVE,
            name: 'agriness.reproductive',
            iconClass: 'icon-farrowing-farrow',
            link: `/${TypeProductionEnum.SWINES}/${StageEnum.REPRODUCTIVE}`,
          },
          {
            id: StageEnum.NURSERY,
            name: 'agriness.nursery',
            iconClass: 'icon-nursery',
            link: `/${TypeProductionEnum.SWINES}/${StageEnum.NURSERY}`,
          },
          {
            id: StageEnum.FINISHING,
            name: 'agriness.finishing',
            iconClass: 'icon-weight',
            link: `/${TypeProductionEnum.SWINES}/${StageEnum.FINISHING}`,
          },
          {
            id: StageEnum.WEAN_TO_FINISH,
            name: 'agriness.wean_to_finish',
            iconClass: 'icon-wean-to-finish',
            link: `/${TypeProductionEnum.SWINES}/${StageEnum.WEAN_TO_FINISH}`,
          },
          {
            ...records,
            link: `/${TypeProductionEnum.SWINES}/${records.link.toString()}`,
          },
        ],
      },
      {
        ...presence,
        subItem: [
          {
            link: `/${TypeProductionEnum.SWINES}/presence`,
            iconClass: 'icon-report1',
            id: 'analisys',
            name: 'agriness.sidebar.support.analisys',
          },
        ],
      },
      {
        ...settings,
        subItem: [
          {
            ...basicRecords,
            link: `/${TypeProductionEnum.SWINES}/settings/${basicRecords.link.toString()}`,
          },
          {
            ...farms,
            link: `/${TypeProductionEnum.SWINES}/settings/${farms.link.toString()}`,
          },
          {
            ...targets,
            link: `/${TypeProductionEnum.SWINES}/settings/${targets.link.toString()}`,
          },
          {
            ...targetsV2,
            link: `/${TypeProductionEnum.SWINES}/settings/${targetsV2.link.toString()}`,
          },
          {
            ...paymentRules,
            link: `/${TypeProductionEnum.SWINES}/settings/${paymentRules.link.toString()}`,
          },
          {
            ...productionParams,
            link: `/${TypeProductionEnum.SWINES}/settings/${productionParams.link.toString()}`,
          },
          {
            ...presence_setting,
            link: `/${TypeProductionEnum.SWINES}/settings/${presence_setting.link.toString()}`,
          },
        ],
      },
    ],
  },
  [TypeProductionEnum.POULTRY]: {
    typeProduction: TypeProductionEnum.POULTRY,
    productId: IdsByProductionType.poultry,
    iconTitle: 'broiler-logo',
    itemsMenu: [
      {
        id: 'production',
        name: 'agriness.sidebar.production.title',
        iconClass: 'icon-farm',
        subItem: [
          {
            id: StageEnum.GROW_OUT,
            name: 'agriness.sidebar.production.grow_out',
            iconClass: 'icon-chicken-drum-stick',
            link: `/${TypeProductionEnum.POULTRY}/${StageEnum.GROW_OUT}`,
          },
          {
            ...records,
            link: `/${TypeProductionEnum.POULTRY}/${records.link.toString()}`,
          },
        ],
      },
      {
        ...planning,
        subItem: [
          {
            id: StageEnum.GROW_OUT,
            name: 'agriness.sidebar.planning.movements',
            iconClass: 'icon-transfer-phase',
            link: `/${TypeProductionEnum.POULTRY}/planning/movements`,
          },
        ],
      },
      {
        ...presence,
        subItem: [
          {
            link: `/${TypeProductionEnum.POULTRY}/presence`,
            iconClass: 'icon-report1',
            id: 'analisys',
            name: 'agriness.sidebar.support.analisys',
          },
        ],
      },
      {
        ...settings,
        subItem: [
          {
            ...basicRecords,
            link: `/${TypeProductionEnum.POULTRY}/settings/${basicRecords.link.toString()}`,
          },
          {
            ...farms,
            link: `/${TypeProductionEnum.POULTRY}/settings/${farms.link.toString()}`,
          },
          {
            ...targets,
            link: `/${TypeProductionEnum.POULTRY}/settings/${targets.link.toString()}`,
          },
          {
            ...targetsV2,
            link: `/${TypeProductionEnum.POULTRY}/settings/${targetsV2.link.toString()}`,
          },
          {
            ...productionParams,
            link: `/${TypeProductionEnum.POULTRY}/settings/${productionParams.link.toString()}`,
          },
          {
            ...presence_setting,
            link: `/${TypeProductionEnum.POULTRY}/settings/${presence_setting.link.toString()}`,
          },
        ],
      },
    ],
  },
  [TypeProductionEnum.DAIRY]: {
    typeProduction: TypeProductionEnum.DAIRY,
    productId: IdsByProductionType.dairy,
    iconTitle: 'dairy-logo',
    itemsMenu: [
      {
        id: 'production',
        name: 'agriness.sidebar.production.title',
        iconClass: 'icon-farm',
        subItem: [
          {
            id: StageEnum.MILKING,
            name: 'agriness.lactation',
            iconClass: 'icon-dairy',
            link: `/${TypeProductionEnum.DAIRY}/${StageEnum.MILKING}`,
          },
        ],
      },
      {
        ...presence,
        subItem: [
          {
            link: `/${TypeProductionEnum.DAIRY}/presence`,
            iconClass: 'icon-report1',
            id: 'analisys',
            name: 'agriness.sidebar.support.analisys',
          },
        ],
      },
      {
        ...settings,
        subItem: [
          {
            ...basicRecords,
            link: `/${TypeProductionEnum.DAIRY}/settings/${basicRecords.link.toString()}`,
          },
          {
            ...farms,
            link: `/${TypeProductionEnum.DAIRY}/settings/${farms.link.toString()}`,
          },
          {
            ...targets,
            link: `/${TypeProductionEnum.DAIRY}/settings/${targets.link.toString()}`,
          },
          {
            ...targetsV2,
            link: `/${TypeProductionEnum.POULTRY}/settings/${targetsV2.link.toString()}`,
          },
          {
            ...presence_setting,
            link: `/${TypeProductionEnum.DAIRY}/settings/${presence_setting.link.toString()}`,
          },
        ],
      },
    ],
  },
  [TypeProductionEnum.LAYERS]: {
    typeProduction: TypeProductionEnum.LAYERS,
    productId: IdsByProductionType.layers,
    iconTitle: 'layers-logo',
    itemsMenu: [
      {
        id: StageEnum.LAYERS_PRODUCTION,
        name: 'agriness.production',
        iconClass: 'icon-layers-logo',
        link: `/${TypeProductionEnum.LAYERS}/${StageEnum.LAYERS_PRODUCTION}`,
      },
      {
        ...records,
        link: `/${TypeProductionEnum.LAYERS}/${records.link.toString()}`,
      },
      {
        ...presence,
        subItem: [
          {
            link: `/${TypeProductionEnum.LAYERS}/presence`,
            iconClass: 'icon-report1',
            id: 'analisys',
            name: 'agriness.sidebar.support.analisys',
          },
        ],
      },
    ],
  },
};

export const productIds = new Set(Object.values(appInfoByProduction).map(i => i.productId));

export interface AppInfo {
  typeProduction: TypeProductionEnum;
  productId: string;
  iconTitle: string;
  itemsMenu: AgSideMenuModel[];
}
