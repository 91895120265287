import { AnimalGroupIndicators, MapIndicatorsAbstractService } from '@agriness/corp-app/services';
import { UserStorageService, SiteSystemType, SiteEnum } from '@agriness/services';
import { FeedbackEnum } from '@agriness/ui';
import { Component, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CorpReportFiltersComponent } from '../../../shared/component/corp-report-filters/corp-report-filters.component';
import { LoaderUserPreference } from '../../../shared/component/loader-user-preference';
import {
  PeriodInterval,
  PeriodUnit,
  ReportFilterPeriodDefaultValue,
  ReportFilterQuery,
  ReportFilterType,
} from '../../../shared/model/report-filter.model';
import { Indicator } from './indicator.model';

@Component({
  templateUrl: './indicators.component.html',
})
export class IndicatorsComponent extends LoaderUserPreference {
  @ViewChild('reportFilter', { static: true }) reportFilter: CorpReportFiltersComponent;
  rowGroupMetadata: Indicator;
  objectKeys = Object.keys;
  typeFeedback = FeedbackEnum.LOADING;
  filterIds: ReportFilterType[] = ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'TECHNICIAN'];
  periodDefaultValue: ReportFilterPeriodDefaultValue = [PeriodInterval.CURRENT, PeriodUnit.MONTHS];
  stage = SiteSystemType[environment.typeProduction][SiteEnum.SITE_2];

  constructor(
    protected userStorageService: UserStorageService,
    private mapIndicatorsService: MapIndicatorsAbstractService,
  ) {
    super(userStorageService);
  }

  filter(): void {
    this.typeFeedback = FeedbackEnum.LOADING;
    this.loadIndicators().subscribe(
      () => (this.typeFeedback = !this.hasData() ? FeedbackEnum.NOT_RESULT_SEARCH : null),
    );
  }

  getCurrentFilter(): ReportFilterQuery {
    return this.reportFilter.getCurrentFilterInQueryFormat();
  }

  updateRowGroupMetaData(indicators: AnimalGroupIndicators[]): Indicator {
    const rowGroupMetadata: Record<string, AnimalGroupIndicators[]> = {};

    indicators.forEach(item => {
      if (item.type) {
        !rowGroupMetadata[item.type]
          ? (rowGroupMetadata[item.type] = [item])
          : rowGroupMetadata[item.type].push(item);
      }
    });
    return rowGroupMetadata;
  }

  private loadIndicators(): Observable<AnimalGroupIndicators> {
    this.rowGroupMetadata = null;
    return this.mapIndicatorsService
      .getIndicators(this.holdingId, this.stage, this.getCurrentFilter())
      .pipe(
        map(indicators => this.updateRowGroupMetaData(indicators)),
        tap((data: Indicator) => (this.rowGroupMetadata = data)),
      );
  }

  private hasData() {
    return !_.isEmpty(this.rowGroupMetadata);
  }
}
