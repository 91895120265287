import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CorpAnimalGroupMonitorTabsComponent } from '../../shared/component/corp-animal-group-monitor-tabs/corp-animal-group-monitor-tabs.component';
import { NurseryMonitorComponent } from './monitor.component';

const routes: Routes = [
  {
    path: '',
    component: NurseryMonitorComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'open' },
      { path: 'open', component: CorpAnimalGroupMonitorTabsComponent },
      { path: 'closed', component: CorpAnimalGroupMonitorTabsComponent },
    ],
  },
];

export const routerNurseryMonitor: ModuleWithProviders<RouterModule> = RouterModule.forChild(
  routes,
);

@NgModule({
  imports: [routerNurseryMonitor],
  exports: [RouterModule],
})
export class NurseryMonitorRoutingModule {}
