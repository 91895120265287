import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { SectionModel } from './section.model';
import { SectionService } from './section.service';

@Component({
  selector: 'ag-section',
  templateUrl: './section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent implements OnInit, OnDestroy {
  sections$: Subscription;
  sections: SectionModel[];

  constructor(
    private sectionService: SectionService,
    private changeDetectionRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.sections$ = this.sectionService.getSection().subscribe(sections => {
      this.sections = sections;
      this.changeDetectionRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.sections$.unsubscribe();
  }
}
