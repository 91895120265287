<section class="section is-full">
  <div class="page-title d-flex align-items-center mb-3">
    <corp-back-button></corp-back-button>
    {{ pageTitle | translate }}
  </div>
  <app-corp-wizzard
    [items]="wizzardItems"
    (emitChangeIndex)="setActiveIndex($event)"
    (saveForm)="saveForm()"
    [isComplete]="isValid"
    (emitNext)="next()"
    [canNext]="stepValid | async"
  >
    <app-step-main
      *ngIf="activeIndex === 0"
      (isValid)="setValid($event, 0)"
      (sendData)="setData($event)"
      [formData]="data.asObservable()"
    ></app-step-main>
    <app-step-config
      *ngIf="activeIndex === 1"
      (isValid)="setValid($event, 1)"
      (sendData)="setData($event)"
      [formData]="data.asObservable()"
    ></app-step-config>
    <app-step-rules *ngIf="activeIndex === 2" [formData]="data.asObservable()"></app-step-rules>
  </app-corp-wizzard>
</section>
