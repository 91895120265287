import { Search, TypeProductionService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { DownloadFileService } from '../download-file/download-file.service';
import {
  CalcPayment,
  CalcPaymentRequest,
  CheckPayment,
  PaymentList,
  PaymentPostRequest,
} from '../models/payment-record.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentReportService {
  private basePath = 'holdings/{holding_id}/payments/';
  private editPath = 'holdings/{holding_id}/payments/{id}/';
  private checkPath =
    'holdings/{holding_id}/farms/{farm_id}/animal-groups/{animal_group_id}/check-payment-available/';
  private pathById = `${this.basePath}{id}/`;
  private calcPath =
    'holdings/{holding_id}/farms/{farm_id}/animal-groups/{animal_group_id}/payment-calc/';

  constructor(
    private apiCorpService: ApiCorpService,
    private downloadFileService: DownloadFileService,
    private typeProductionService: TypeProductionService,
  ) {}

  check(
    holdingId: string,
    animalgroupId: string,
    farmId: string,
    stage: string,
    isOpen: boolean,
  ): Observable<CheckPayment> {
    const params = { holding_id: holdingId, animal_group_id: animalgroupId, farm_id: farmId };
    const url = this.apiCorpService.translateUrl(this.checkPath, params);

    const data = {
      system_type: this.typeProductionService.get(),
      stage: stage,
      is_open: isOpen,
    };

    return this.apiCorpService.post(url, data);
  }

  list(holdingId: string, animalgroupId: string): Observable<Search<PaymentList>> {
    const params = { holding_id: holdingId, animal_group: animalgroupId };
    const url = this.apiCorpService.translateUrl(this.basePath + '?search={animal_group}', params);

    return this.apiCorpService.get<Search<PaymentList>>(url);
  }

  calc(
    holdingId: string,
    animalgroupId: string,
    farmId: string,
    stage: string,
    paymentData: CalcPaymentRequest,
  ): Observable<CalcPayment> {
    const params = { holding_id: holdingId, animal_group_id: animalgroupId, farm_id: farmId };
    const url = this.apiCorpService.translateUrl(this.calcPath, params);

    const data = {
      ...paymentData,
      system_type: this.typeProductionService.get(),
      stage: stage,
    };

    return this.apiCorpService.post(url, data);
  }

  save(
    holdingId: string,
    animalgroupId: string,
    documentData: PaymentPostRequest,
    documentId?: string,
  ): Observable<PaymentList> {
    const params = { holding_id: holdingId, animal_group: animalgroupId, id: documentId || null };
    const url = this.apiCorpService.translateUrl(
      documentId ? this.editPath : this.basePath,
      params,
    );

    return documentId
      ? this.apiCorpService.put<PaymentList>(url, documentData)
      : this.apiCorpService.post<PaymentList>(url, documentData);
  }
}
