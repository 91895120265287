import { RouteAbstractService } from '@agriness/corp-app/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, MultiSelectComponent } from '../corp-filter-abstractions';
import { SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-route-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.multiselect.html',
  providers: [{ provide: FilterComponent, useExisting: CorpRouteFilterComponent }],
})
export class CorpRouteFilterComponent extends MultiSelectComponent {
  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
    routeService: RouteAbstractService,
  ) {
    super(translate, containerService, activeRoute);
    this.assignDefaults('route', new SelectLoader(routeService));
  }
}
