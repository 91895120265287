import { Component, Input } from '@angular/core';

@Component({
  selector: 'ag-copy-button',
  templateUrl: './ag-copy-button.component.html',
})
export class AgCopyButtonComponent {
  @Input() icon: string;
  @Input() value: string;
  @Input() tooltip: string;
}
