import {
  AnimalGroupAbstractService,
  AnimalGroupRecord,
  AnimalGroupRecordDetail,
  mainDetailsByProduction,
  MeasurmentUnits,
  ModalStatusEnum,
  ResultAnimalGroupFarm,
  UserHoldingPermissionService,
} from '@agriness/corp-app/services';
import { DisplayMode } from '@agriness/corp-app/services/models/diet-selection.model';
import { Diet } from '@agriness/corp-app/services/models/diet.model';
import { DownloadStatus } from '@agriness/corp-app/services/models/download-status.model';
import { CheckPayment } from '@agriness/corp-app/services/models/payment-record.model';
import { TraceabilityDocumentTypeEnum } from '@agriness/corp-app/services/models/traceability-documents.model';
import { PaymentReportService } from '@agriness/corp-app/services/payment-report/payment-report.service';
import { TraceabilityDocumentService } from '@agriness/corp-app/services/traceability-document/traceability-document.service';
import { PaymentReportDocumentStatus } from '@agriness/corp-app/shared/component/corp-animal-group-payment-report/corp-animal-group-payment-report.model';
import { DietSelectionComponent } from '@agriness/corp-app/shared/component/diet-selection/diet-selection.component';
import { FeatureToggleService } from '@agriness/corp-app/shared/services/feature-toggle.service';
import {
  AgrinessTranslateService,
  StageEnum,
  TypeProductionEnum,
  UserHoldingRelationType,
} from '@agriness/services';
import { Stage, TypeProductionService, UserStorageService } from '@agriness/services';
import { AgFeedbackService, FeedbackEnum, QueueItem, QueueManagerService } from '@agriness/ui';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment.dev';
import * as _ from 'lodash';
import { EMPTY, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, find, flatMap, first } from 'rxjs/operators';

import { HealthReportDocumentStatus } from '../../shared/component/corp-animal-group-health-report/corp-animal-group-health-report.model';
import { LoaderUserPreference } from '../../shared/component/loader-user-preference';
import { getTypeProductionIcon } from '../../shared/model/type-production-icon.model';
import { AnimalGroupEditorComponent } from './animal-group-editor/animal-group-editor.component';
import { AnimalGroupStateService } from './animal-group-state.service';
import {
  AnimalGroupTab,
  animalGroupTabsByStage,
  defaultAnimalGroupTabs,
} from './animal-group.model';

@Component({
  templateUrl: './animal-group.component.html',
})
export class AnimalGroupComponent extends LoaderUserPreference implements OnInit, OnDestroy {
  @ViewChild('dialog') dialog: DietSelectionComponent;
  @ViewChild('editor') animalGroupEditor: AnimalGroupEditorComponent;

  FeedbackEnum = FeedbackEnum;
  isOpen: boolean;

  editorModalVisible = false;
  editorModalStatus = ModalStatusEnum.DEFAULT;

  StageEnum = StageEnum;
  animalGroupReports: AnimalGroupRecord;
  animalGroupSearchSelect: ResultAnimalGroupFarm;
  filteredAnimalGroup: ResultAnimalGroupFarm[];
  diet: Diet;

  animalGroupId: string;

  headerIconClass: string;
  pageSize = 30;
  detailColumnSize = 5;
  objectKeys = Object.keys;
  tabs: AnimalGroupTab[] = [];
  searchAnimalGroupSubject: Subject<string>;
  subscriptions: Subscription[] = [];
  typeFeedback = FeedbackEnum.RECORD_SEARCH;
  DisplayMode = DisplayMode;
  emptyPageInstructions: string;
  details: AnimalGroupRecordDetail[];
  mainDetails: AnimalGroupRecordDetail;

  hasFeatureHealthReport: boolean;
  hasFeaturePaymentReport: boolean;
  statusHealthReport: HealthReportDocumentStatus;
  statusPaymentReport = PaymentReportDocumentStatus.LOADING;
  healthReportId: string;
  paymentReportId: string;
  healthReportPublishedUrl: string;
  paymentReportPublishedUrl: string;
  paymentCheckData: CheckPayment;

  isConsulting = false;

  farmId: string;

  measurmentUnits: MeasurmentUnits;

  constructor(
    protected userStorageService: UserStorageService,
    private userHoldingPermissionService: UserHoldingPermissionService,
    private route: ActivatedRoute,
    private router: Router,
    private animalGroupService: AnimalGroupAbstractService,
    private traceabilityDocumentService: TraceabilityDocumentService,
    private animalGroupStateService: AnimalGroupStateService,
    private feedbackService: AgFeedbackService,
    private typeProductionService: TypeProductionService,
    private paymentReportService: PaymentReportService,
    private translateService: AgrinessTranslateService,
    private queueManager: QueueManagerService,
    featureToggleService: FeatureToggleService,
  ) {
    super(userStorageService);
    this.emptyPageInstructions = this.feedbackService.getFeedbackMessage(FeedbackEnum.SEARCH);
    this.hasFeatureHealthReport = featureToggleService.isEnabled('traceability.health-report');
    this.hasFeaturePaymentReport = true;
  }

  ngOnInit(): void {
    const routeObservable = this.route.paramMap.pipe(
      switchMap(params => {
        this.animalGroupReports = null;

        if (_.isEmpty(params['params'])) {
          this.typeFeedback = FeedbackEnum.RECORD_SEARCH;
          return of(null);
        }

        this.typeFeedback = FeedbackEnum.LOADING;

        const idFarm = params.get('idFarm');
        this.farmId = idFarm;
        const idAnimalGroup = params.get('idAnimalGroup');
        this.animalGroupId = params.get('idAnimalGroup');

        this.loadHealthReport(idAnimalGroup);

        return this.animalGroupService
          .getAnimalGroup(this.holdingId, this.farmId, idAnimalGroup)
          .pipe(
            catchError(error => {
              console.error('Error on animal group load', error);
              this.typeFeedback = FeedbackEnum.NOT_RESULT_SEARCH;
              return of(null);
            }),
          );
      }),
      tap((animalGroup: AnimalGroupRecord) => {
        if (animalGroup) {
          this.setAnimalGroupRecord(animalGroup);
          if (this.isPaymentSystemTypeEnable()) {
            this.loadPaymentReport(this.animalGroupId, animalGroup.is_open);
          }
        }
      }),

      catchError(error => {
        console.error('Error on animal group route handler', error);
        this.typeFeedback = FeedbackEnum.ERROR;
        return throwError(error);
      }),
    );

    this.searchAnimalGroupSubject = new Subject<string>();
    const searchAnimalGroupObservable = this.searchAnimalGroupSubject.pipe(
      switchMap(term => {
        if (!term) {
          return of<ResultAnimalGroupFarm[]>([]);
        }

        return this.animalGroupService
          .searchAnimalFarmGroup(this.holdingId, term, this.pageSize)
          .pipe(
            map(({ results }) => results),
            catchError(error => {
              console.error('Error on animal group search', error);
              return of([]);
            }),
          );
      }),
      tap(filteredAnimalGroup => (this.filteredAnimalGroup = filteredAnimalGroup)),
    );

    this.subscriptions = [routeObservable.subscribe(), searchAnimalGroupObservable.subscribe()];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  searchAnimalGroup(event: { query: string }): void {
    this.searchAnimalGroupSubject.next(event.query);
  }

  selectAnimalGroupSearch(event: ResultAnimalGroupFarm): void {
    if (event && event.farm) {
      void this.router.navigate([
        '/',
        this.typeProductionService.get(),
        'record',
        event.farm.id,
        event.id,
      ]);
    }
  }

  onBlurSearchAnimalGroup(): void {
    if (this.animalGroupReports) {
      this.animalGroupSearchSelect = Object.assign(
        new ResultAnimalGroupFarm(),
        this.animalGroupReports,
      );
    }
  }

  getIconClass(item: { is_open?: boolean }): string {
    return getTypeProductionIcon(this.typeProductionService.get(), item.is_open);
  }

  setDiet(): void {
    this.diet = _.last(this.animalGroupReports.feed);
  }

  setNewDiet(diet: Diet): void {
    this.diet = diet;
  }

  onEditorClosed(): void {
    this.editorModalVisible = false;
    this.editorModalStatus = ModalStatusEnum.DEFAULT;
  }

  updateAnimalGroup(): void {
    if (this.animalGroupReports) {
      const { id } = this.animalGroupReports;
      const formValues = this.animalGroupEditor.getAnimalGroupForm();
      this.editorModalStatus = ModalStatusEnum.LOADING;
      delete formValues['farm'];
      delete formValues['name'];
      this.animalGroupService
        .update(this.holdingId, this.farmId, formValues, id)
        .pipe(
          first(),
          map(animalGroup => {
            this.setAnimalGroupRecord(animalGroup);
            this.editorModalStatus = ModalStatusEnum.SUCCESS;
          }),
          catchError(({ error }) => {
            this.editorModalStatus = ModalStatusEnum.FAILED;
            return throwError(error);
          }),
        )
        .subscribe();
    }
  }

  showModalEditor(): void {
    this.editorModalVisible = true;
  }

  downloadRecordDocumentPdf(): void {
    this.addDownloadToQueue(
      this.animalGroupService.getAnimalGroupRecordDocumentDowloadFile(
        this.holdingId,
        this.farmId,
        this.animalGroupReports.id,
      ),
    );
  }

  canEditAnimalGroup(): boolean {
    const userHoldingRelationType = [
      UserHoldingRelationType.OWNER,
      UserHoldingRelationType.PARTNER,
    ];
    return this.userHoldingPermissionService.hasOneHoldingRelationType(userHoldingRelationType);
  }

  canEditPayment(): boolean {
    const userHoldingRelationType = [
      UserHoldingRelationType.OWNER,
      UserHoldingRelationType.PARTNER,
      UserHoldingRelationType.EMPLOYEE,
    ];
    return this.userHoldingPermissionService.hasOneHoldingRelationType(userHoldingRelationType);
  }

  isPaymentSystemTypeEnable(): boolean {
    return environment.typeProduction === TypeProductionEnum.SWINES;
  }

  isAnimalGroupPlanned(): boolean {
    return this.animalGroupReports.is_open == true && this.animalGroupReports.planned == true;
  }
  isAnimalGroupOpened(): boolean {
    return this.animalGroupReports.is_open == true && this.animalGroupReports.planned == false;
  }

  isAnimalGroupClosed(): boolean {
    return this.animalGroupReports.is_open == false;
  }

  private addDownloadToQueue(downloadObservable: Observable<DownloadStatus>): void {
    const t = (key: string): string =>
      this.translateService.instantWithFallback(`agriness.download_feedback.${key}`);
    this.queueManager
      .add(downloadObservable, t('waiting'))
      .pipe(
        tap(({ actions }: QueueItem) => actions.update(t('in_progress'))),
        switchMap(({ payload: download, actions }: QueueItem) => {
          return (download as Observable<DownloadStatus>).pipe(
            tap((status: DownloadStatus) => {
              if (status === DownloadStatus.NO_CONTENT) {
                actions.error(t('no_content'));
              } else {
                actions.success(t('success'));
              }
            }),
            catchError(err => {
              actions.error(t('error'));
              return throwError(err);
            }),
          );
        }),
      )
      .subscribe();
  }

  private setAnimalGroupRecord(animalGroup: AnimalGroupRecord): void {
    this.typeFeedback = null;
    this.animalGroupReports = animalGroup;
    this.animalGroupStateService.setAnimalGroup(animalGroup);
    this.tabs = this.getAnimalGroupTab(this.animalGroupReports.stage);
    this.buildHeaderAnimalGroup();
    this.setDiet();
    const { details, mainDetails } = this.splitDetails(animalGroup.detail);
    this.details = details;
    this.mainDetails = mainDetails;
  }

  private splitDetails(
    details: AnimalGroupRecordDetail,
  ): { mainDetails: AnimalGroupRecordDetail; details: AnimalGroupRecordDetail[] } {
    const [mainKeys, extraKeys] = _.partition(
      Object.keys(details),
      (key: keyof AnimalGroupRecordDetail) =>
        mainDetailsByProduction[this.typeProductionService.get()].includes(key),
    );
    return {
      mainDetails: _.pick(details, mainKeys),
      details: _.chunk(extraKeys, this.detailColumnSize).map(keys => _.pick(details, keys)),
    };
  }

  private buildHeaderAnimalGroup() {
    this.headerIconClass = this.getIconClass(this.animalGroupReports);
    this.animalGroupSearchSelect = Object.assign(
      new ResultAnimalGroupFarm(),
      this.animalGroupReports,
    );
  }

  private getAnimalGroupTab(stage?: Stage): AnimalGroupTab[] {
    return stage ? animalGroupTabsByStage[stage.name] : defaultAnimalGroupTabs;
  }

  private loadHealthReport(animalGroupId: string): void {
    if (!this.hasFeatureHealthReport) {
      return;
    }
    this.traceabilityDocumentService
      .list(this.holdingId, animalGroupId)
      .pipe(
        flatMap(({ results }) => results),
        find(data => data.document_type === TraceabilityDocumentTypeEnum.SWINE_BRA_HEALTH_REPORT),
        tap(healthReports => {
          this.statusHealthReport = healthReports?.status ?? HealthReportDocumentStatus.EDITING;
          this.healthReportId = healthReports?.id;
        }),
        switchMap(() => {
          if (!this.healthReportId) {
            return EMPTY;
          }
          return this.traceabilityDocumentService
            .getHealthReportDownloadUrl(this.holdingId, animalGroupId, this.healthReportId)
            .pipe(
              catchError(error => {
                console.error('Error loading document link', error);
                return EMPTY;
              }),
            );
        }),
        tap(url => (this.healthReportPublishedUrl = url)),
      )
      .subscribe();
  }

  private loadPaymentReport(animalGroupId: string, isOpen: boolean): void {
    this.isOpen = isOpen;
    this.measurmentUnits = {
      currency_unit: this.animalGroupReports.farm.currency_unit,
      weight_measurement_unit: this.animalGroupReports.farm.weight_measurement_unit,
    };

    this.paymentReportService
      .check(this.holdingId, animalGroupId, this.farmId, this.animalGroupReports.stage.name, isOpen)
      .pipe(
        tap(paymentReports => {
          this.paymentCheckData = paymentReports;
          this.hasFeaturePaymentReport = true;

          this.paymentReportService
            .list(this.holdingId, animalGroupId)
            .pipe(
              tap(paymentReports => {
                this.statusPaymentReport =
                  paymentReports?.results.length > 0
                    ? PaymentReportDocumentStatus.PUBLISHED
                    : this.paymentCheckData.has_payment_rule
                    ? this.paymentCheckData.has_sale
                      ? PaymentReportDocumentStatus.NOT_PUBLISHED
                      : PaymentReportDocumentStatus.NO_SALE
                    : PaymentReportDocumentStatus.NO_RULE;
                if (paymentReports.results?.length > 0) {
                  this.paymentReportId = paymentReports.results[0].id;
                } else {
                  this.paymentReportId = null;
                }
              }),
              switchMap(() => {
                if (!this.paymentReportId) {
                  return EMPTY;
                }
              }),
            )
            .subscribe();
        }),
      )
      .subscribe();
  }
}
