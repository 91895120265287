import { AgrinessTranslateService } from '@agriness/services';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { TargetStatus } from '../../target/target.model';
import { StatusItems } from '../status.model';

@Component({
  selector: 'ag-status-legend',
  templateUrl: './ag-status-legend.component.html',
})
export class AgStatusLegendComponent implements OnInit, OnChanges {
  @Input() items: StatusItems = [];

  statusItems = [];

  constructor(private translateService: AgrinessTranslateService) {}

  ngOnInit(): void {
    this.updateStatusItems();
  }

  ngOnChanges(): void {
    this.updateStatusItems();
  }

  private updateStatusItems() {
    if (this.items.length) {
      this.statusItems = this.items;
    } else {
      this.statusItems = Object.values(TargetStatus).map(item => ({
        label: `${this.translateService.instantWithFallback(`agriness.${item}`)}`,
        status: item,
        percentage: '-',
        measurementUnit: 'text',
      }));
    }
  }
}
