<div class="step-by-step">
  <div class="step-container">
    <p-steps
      [model]="steps"
      [readonly]="!isClickable"
      [(activeIndex)]="index"
      [ngClass]="{ complete: index === items.length - 1 }"
    >
    </p-steps>
    <div class="step-by-step-body" *ngFor="let item of items; let i = index">
      <div class="step-body-container" *ngIf="i === index">
        <ng-content steps></ng-content>
      </div>
      <div class="step-buttons-container" *ngIf="i === index">
        <div class="step-buttons-container">
          <button class="btn back" [disabled]="index === 0" (click)="previous()">
            {{ 'agriness.wizzard.back' | translate }}
          </button>
          <button
            *ngIf="!(items.length === index + 1)"
            [disabled]="!nextStepEnable"
            class="btn btn-primary"
            (click)="next()"
          >
            {{ item.nextButtonLable || 'agriness.wizzard.confirmAndNext' | translate }}
          </button>
          <button *ngIf="items.length === index + 1" class="btn btn-primary" (click)="save()">
            {{ item.saveButtonLable || 'agriness.wizzard.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
