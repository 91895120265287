import { ReportFilterQuery } from '@agriness/corp-app/shared/model/report-filter.model';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { FilterComponent } from './corp-filter-abstractions';
import { CorpFiltersContainerService } from './corp-filters-container.service';

@Component({
  selector: 'corp-filters-container',
  styleUrls: ['./corp-filters-container.component.scss'],
  templateUrl: './corp-filters-container.component.html',
})
export class CorpFiltersContainerComponent implements AfterContentInit, OnDestroy {
  @HostBinding('class') classes = 'd-flex flex-column flex-md-row';

  @ContentChildren(FilterComponent) children: QueryList<FilterComponent>;

  @Output() filter = new EventEmitter<ReportFilterQuery>();

  @Output() resetFilter = new EventEmitter<void>();

  @Output() ready = new EventEmitter<void>();
  @Output() valid = new EventEmitter<boolean>();

  @Input() showFilterButton = true;

  filtersMinArea = '';
  filtersAreaWidth = '100%';
  filterForm?: FormGroup;
  isCollapsed = false;

  private readonly visibleFiltersSize = 4;
  private filtersSize: number;
  private requestsSubscription = Subscription.EMPTY;
  private subtitleSubscription = Subscription.EMPTY;

  constructor(
    // TODO: use @SkipSelf along with provide for CorpFiltersContainerContext
    //  when we migrate to angular 11, currently, SkipSelf is
    //  not working as expected, so the solution
    //  is to inject the context from a higher component
    //  note: currently the only higher component is the benchmark.component
    //  link for reference: https://github.com/angular/angular/issues/34819
    private containerService: CorpFiltersContainerService,
    private formBuilder: FormBuilder,
  ) {}

  ngAfterContentInit(): void {
    this.toggleVisibility();
    this.calculateFiltersSize();
    this.onResetFilter();

    void this.initAsync();
  }

  ngOnDestroy(): void {
    this.requestsSubscription.unsubscribe();
    this.subtitleSubscription.unsubscribe();
  }

  hasWrappedFilters(): boolean {
    return this.filtersSize > this.visibleFiltersSize;
  }

  async resetFilterForm(): Promise<void> {
    // On reset, should we invoke ready again?
    const values = await Promise.all(this.children.map(child => child.getResetData()));
    const controlsConfig = values.reduce(
      (groups, values) => ({
        ...groups,
        ...values,
      }),
      {},
    );
    this.containerService.formGroup.getValue().setValue(controlsConfig);
  }

  onFilter(event?: Event): void {
    event?.preventDefault();
    const queryFormat = this.collectChildrenQuery();
    const wasAnyInvalidated = this.children.reduce((isInvalid, child) => {
      return child.invalidate(queryFormat) || isInvalid;
    }, false);

    if (!wasAnyInvalidated) {
      void this.maybeRegisterQueryOnRoute(queryFormat);
      this.filter.emit(queryFormat);
    }
  }

  toggleVisibility(): void {
    this.isCollapsed = !this.isCollapsed;

    this.children.reduce((calculatedIdx, child) => {
      // Theoretically we may have a case where we want to hide 1 input
      // from a children that has 2.. this is not handled because
      // the original component didn't handled such case.
      if (calculatedIdx >= this.visibleFiltersSize) {
        child.hidden = this.isCollapsed;
      }

      return calculatedIdx + child.inputsNumber;
    }, 0);
  }

  getCurrentFilterInQueryFormat(): ReportFilterQuery {
    return Object.freeze(this.collectChildrenQuery());
  }

  onResetFilter(): void {
    this.containerService.onResetFilter.subscribe(async () => {
      await this.resetFilterForm();
    });
  }

  private async initAsync() {
    const values = await Promise.all(this.children.map(child => child.getInitialGroupData()));
    const controlsConfig = values.reduce(
      (groups, values) => ({
        ...groups,
        ...values,
      }),
      {},
    );

    this.filterForm = this.formBuilder.group(controlsConfig);

    this.containerService.formGroup.next(this.filterForm);
    this.children.notifyOnChanges();
    this.listenForSubtitle();
    this.listenForChildrenRequests();

    void this.maybeRegisterQueryOnRoute(this.collectChildrenQuery());

    this.ready.emit();

    this.filterForm.valueChanges.subscribe(() => {
      this.valid.emit(this.filterForm.valid);
    });
  }

  private listenForChildrenRequests() {
    this.requestsSubscription = this.containerService.requestFilter.subscribe(() => {
      void this.onFilter();
    });
  }

  private maybeRegisterQueryOnRoute(queryFormat: ReportFilterQuery) {
    if (!this.showFilterButton) {
      return false;
    }

    return this.containerService.registerQueryOnRoute(queryFormat);
  }

  private listenForSubtitle() {
    const collectSubtitles = () => this.children.map(it => it.collectSubtitle());

    this.containerService.subtitles.next(collectSubtitles());
    this.containerService.requestSubtitleCollection.subscribe(() => {
      this.containerService.subtitles.next(collectSubtitles());
    });
    this.filterForm.valueChanges.subscribe(() => {
      this.containerService.subtitles.next(collectSubtitles());
    });
  }

  /**
   * Returns the children current values in the query format
   */
  private collectChildrenQuery(): ReportFilterQuery {
    return this.children
      .map(child => child.getValuesInQueryFormat())
      .reduce<Record<string, unknown>>(
        (queryObject, value) => ({ ...queryObject, ...value }),
        {},
      ) as ReportFilterQuery;
  }

  private calculateFiltersSize() {
    this.filtersSize = this.children.reduce((sum, child) => sum + child.inputsNumber, 0);
    if (this.filtersSize === 1) {
      this.filtersMinArea = 'max-content';
    }
    if (this.filtersSize < this.visibleFiltersSize) {
      this.filtersAreaWidth = '100%';
      this.children.forEach(child => {
        child.inputWidth = `${100 / this.filtersSize}%`;
      });
    } else {
      this.filtersMinArea = 'max-content';
    }
  }
}
