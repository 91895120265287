<div class="target-subtitle-container">
  <div class="subtitles" *ngFor="let item of target; let index = index">
    <ag-target-indicator [status]="item.status"></ag-target-indicator>
    <span *ngFor="let value of item.value; let index = index">
      <span class="value-separators" *ngIf="index > 0"> - </span>
      <span class="target-operator" *ngIf="item.operator"> {{ item.operator }} </span>
      <ag-format
        [showSymbol]="showMeasurementUnit"
        [value]="value"
        [measurementUnit]="targetData.index.measurement_unit"
        [decimalPlaces]="targetData.index.decimal_places"
      >
      </ag-format>
    </span>
  </div>
</div>
