import { LocalStorageService } from '@agriness/corp-app/shared/services/local-storage.service';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { defer, snakeCase } from 'lodash';

import { HoldingModel, HoldingMenuModel } from './holding-menu.model';
import { HoldingMenuService } from './holding-menu.service';

@Component({
  selector: 'ag-holding-select',
  templateUrl: './holding-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoldingMenuComponent implements OnInit {
  holdingMenu: HoldingMenuModel;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private changeDetectionRef: ChangeDetectorRef,
    private holdingMenuService: HoldingMenuService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.holdingMenuService.getHoldingSelectModel().subscribe(holdingMenu => {
      this.holdingMenu = holdingMenu;
      this.changeDetectionRef.markForCheck();
    });
  }

  changeActiveHolding(newHolding: HoldingModel) {
    this.holdingMenu.activeHolding = newHolding;
    this.holdingMenuService.applyHolding(this.holdingMenu);
    defer(() => {
      location.reload();
      this.localStorageService.removeItems();
    });
  }

  getHoldingTypeText({ type }: HoldingModel) {
    const formattedType = snakeCase(type);
    return this.t(`agriness.holding.type.${formattedType}`);
  }
}
