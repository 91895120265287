import {
  BaseEntityFilter,
  ContextFilter,
  PageNumberPagination,
  QueryCorp,
} from '@agriness/services';

import { Producer } from './producer.model';
import { Region } from './region.model';
import { Routes } from './routes.model';
import { Technician } from './technician.model';

export enum NoBondEnum {
  NO_BOND = '86d9abbd-4108-40c5-9479-f1f9ed053e8d',
}

export interface Farm {
  id: string;
  name: string;
  producer: Producer;
  technician: Technician;
  region: Region;
  route: Routes;
  weight_measurement_unit?: string;
  currency_unit?: string;
}

export interface FarmForm {
  name: string;
  producer: string;
  technician: string;
  region: string;
  route: Routes;
}

export interface FarmOnlyFilters {
  technician?: string;
  producer?: string;
  region?: string;
}

export const defaultFarmOrder: (keyof Farm)[] = ['name'];

export type FarmFilter = BaseEntityFilter &
  PageNumberPagination &
  ContextFilter &
  QueryCorp &
  FarmOnlyFilters;
