import { MeasurementUnitEnum } from '..';

export enum Group {
  STRAIN = 'strain',
  COMPANY = 'company',
}

export enum DisplayOption {
  BY_FLOCKS = 'BY_FLOCKS',
  BY_HEN_HOUSED = 'BY_HEN_HOUSED',
}

export enum InventoryRowType {
  FOOTER = 'FOOTER',
}

export enum Section {
  CARDS = 'cards',
  FLOCK_NUMBER_AND_SIZE_PER_COMPANY = 'flock_number_and_size_per_company',
  FLOCK_NUMBER_AND_SIZE_PER_STRAIN = 'flock_number_and_size_per_strain',
  NUMBER_OF_FLOCKS_AND_SIZE_PER_PRODUCTION_SYSTEM = 'number_of_flocks_and_size_per_production_system',
  STRAIN_DISTRIBUTION_PER_COMPANY = 'strain_distribution_per_company',
  PRODUCTION_SYSTEM_PER_STRAIN = 'production_system_per_strain',
  PRODUCTION_SYSTEM_PER_COMPANY = 'production_system_per_company',
}

export type InventoryTable = FlockNumberAndSize & ProductionSystemData & DistributionTable;

export type FlockNumberAndSize = {
  [key in Group]: InventoryData;
} & {
  count: InventoryData;
  count_percentage: InventoryData;
  balance: InventoryData;
  balance_percentage: InventoryData;
};

export type ProductionSystemData = {
  [key in Group]: InventoryData;
} & {
  cage_flocks: InventoryData;
  cage_hen_housed: InventoryData;
  cage_free_flocks: InventoryData;
  cage_free_hen_housed: InventoryData;
  no_info_flocks: InventoryData;
  no_info_hen_housed: InventoryData;
};

export type DistributionTable = StrainData & {
  company: InventoryData;
  total: InventoryGroupedData;
};

export interface Inventory {
  [Section.FLOCK_NUMBER_AND_SIZE_PER_STRAIN]: FlockNumberAndSize[];
  [Section.FLOCK_NUMBER_AND_SIZE_PER_COMPANY]: FlockNumberAndSize[];
  [Section.NUMBER_OF_FLOCKS_AND_SIZE_PER_PRODUCTION_SYSTEM]: FlockNumberAndSize[];
  [Section.STRAIN_DISTRIBUTION_PER_COMPANY]: DistributionTable[];
  [Section.PRODUCTION_SYSTEM_PER_STRAIN]: ProductionSystemData[];
  [Section.PRODUCTION_SYSTEM_PER_COMPANY]: ProductionSystemData[];
}

export interface StrainData {
  [index: string]: InventoryGroupedData;
}

export interface SearchFilterInventory {
  site_id?: string;
  begin_date?: string;
  end_date?: string;
  strain_id?: string;
  production_system?: string;
  current_cycle?: string;
  is_open?: string;
  index_key?: string;
}

export type InventoryGroupedData = {
  [key in DisplayOption]: InventoryData;
};

export interface InventoryData {
  id?: string;
  index_value?: string | number;
  measurement_unit?: MeasurementUnitEnum;
  decimal_places?: number;
  type?: InventoryRowType;
}

export const superHoldingSections = new Set([
  Section.FLOCK_NUMBER_AND_SIZE_PER_COMPANY,
  Section.PRODUCTION_SYSTEM_PER_COMPANY,
  Section.STRAIN_DISTRIBUTION_PER_COMPANY,
]);
