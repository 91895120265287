import {
  DisplayOption,
  PerformanceData,
} from '@agriness/corp-app/record/animal-group/performance/performance.model';
import {
  defaultOptions,
  optionsByKey,
} from '@agriness/corp-app/reproductive/analysis/performance-analysis/graph/reproductive-performance-analysis-graph.model';
import { PerformanceItems } from '@agriness/corp-app/services';
import { CorpPerformanceGraphService } from '@agriness/corp-app/shared/component/corp-performance-graph/corp-performance-graph.service';
import { HighchartsService } from '@agriness/corp-app/shared/services/highcharts.service';
import { DateService, TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Inject, Injectable } from '@angular/core';
import { startOfMonth } from 'date-fns';
import { TooltipFormatterContextObject } from 'highcharts';
import { merge, get, keys } from 'lodash';

@Injectable()
export class ReproductivePerformanceAnalysisGraphService {
  private nonPeriodKeys = new Set(['date', 'month']);
  private translateKey = 'agriness.performance_analysis';

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private highchartsService: HighchartsService,
    private performanceGraphService: CorpPerformanceGraphService,
    private dateService: DateService,
  ) {}

  getChartOptions(performanceData: PerformanceData): Highcharts.Options {
    const display = performanceData.displayOption;
    const isLinearMonthChart = display === DisplayOption.BY_DATE;
    const formatter = isLinearMonthChart
      ? this.makeLinearYearFormatter()
      : this.performanceGraphService.makeStandardFormatter(display);

    const xAxisTitle = {
      title: {
        text: this.t(this.performanceGraphService.getXAxisLabel(DisplayOption.BY_MONTH)),
      },
    };

    return this.performanceGraphService.addDefaultChartOptions({
      yAxis: {
        title: { text: '' },
        labels: { format: '{value}' },
        opposite: true,
      },
      tooltip: {
        formatter: function () {
          return formatter(this);
        },
      },
      ...(isLinearMonthChart
        ? {
            xAxis: {
              ...xAxisTitle,
              type: 'datetime',
              crosshair: true,
              tickmarkPlacement: 'on',
              units: [['month', [1]]],
              title: {
                text: this.t(this.performanceGraphService.getXAxisLabel(DisplayOption.BY_MONTH)),
              },
            },
            series: this.getLinearMonthSeries(performanceData),
          }
        : {
            xAxis: {
              ...xAxisTitle,
              type: 'linear',
              categories: this.highchartsService.getShortMonths(),
            },
            series: this.getYearComparisonSeries(performanceData),
          }),
    });
  }

  private makeLinearYearFormatter(): (context: TooltipFormatterContextObject) => string {
    return this.performanceGraphService.makeFormatter(current => current.point.options.title);
  }

  /**
   * Creates a list of all period keys and their target contained in the chartData
   * (ignoring the 'date' and 'month' keys)
   */
  private collectPeriodKeys(items: PerformanceItems): string[] {
    return [...new Set(items.flatMap(p => keys(p)))].filter(key => !this.nonPeriodKeys.has(key));
  }

  private getOptionsByKey(key: string, chartData: PerformanceItems): Highcharts.SeriesOptionsType {
    const translated = this.t(`${this.translateKey}.${key}`);

    const options = {
      name: translated.startsWith(this.translateKey) ? key : translated,
      data: this.getLinearMonthSeriesData(chartData, key),
    };
    return merge(options, get(optionsByKey, key, defaultOptions)) as Highcharts.SeriesOptionsType;
  }

  private shouldHaveMarker(key: string): boolean {
    return key !== 'target';
  }

  private getLinearMonthSeries({ data }: PerformanceData): Highcharts.SeriesOptionsType[] {
    return this.collectPeriodKeys(data)
      .sort()
      .map(key => this.getOptionsByKey(key, data));
  }

  private getYearComparisonSeries({ data }: PerformanceData): Highcharts.SeriesOptionsType[] {
    const periods = this.collectPeriodKeys(data);

    return periods.map<Highcharts.SeriesOptionsType>(period => ({
      name: period,
      type: 'line',
      dashStyle: 'Solid',
      data: this.getYearMonthsSeriesData(data, period),
    }));
  }

  private getLinearMonthSeriesData(
    chartData: PerformanceItems,
    value: string,
  ): Highcharts.PointOptionsObject[] {
    return chartData.map<Highcharts.PointOptionsObject>(data => {
      const shouldHaveMarker = this.shouldHaveMarker(value);
      const date = this.dateService.toDate(data.date.value as string);

      return {
        x: startOfMonth(date).getTime(),
        y: this.performanceGraphService.parseYValue(data[value]),
        title: this.dateService.formatDate(date),
        dataLabels: {
          enabled: shouldHaveMarker,
          format: this.performanceGraphService.formatterYValue(data[value]),
        },
        marker: { enabled: shouldHaveMarker },
      };
    });
  }

  private getYearMonthsSeriesData(
    chartData: PerformanceItems,
    value: string,
  ): Highcharts.PointOptionsObject[] {
    return chartData.map<Highcharts.PointOptionsObject>(data => {
      const yValue = data[value];

      return {
        x: Number(data.month.value) - 1,
        y: this.performanceGraphService.parseYValue(yValue),
        dataLabels: { enabled: false },
        marker: { enabled: false },
      };
    });
  }
}
