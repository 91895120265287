<div class="flex-grow-1 d-flex align-content-stretch h-100" [ngStyle]="{ minHeight: height }">
  <corp-graph-legend
    id="performance-graph-legend"
    *ngIf="!useDefaultLegend"
    translatePrefix="agriness.performances."
    [options]="chartOptions"
    [preferences]="graphPreferences"
    (changeSelectedSeries)="changeSelectedSeries($event)"
  ></corp-graph-legend>

  <highcharts-chart
    id="performance-graph"
    *ngIf="chartOptions"
    class="d-block flex-grow-1"
    data-testid="corp-performance-graph-renderer"
    (chartInstance)="registerChart($event)"
    [Highcharts]="Highcharts"
    [oneToOne]="true"
    [options]="chartOptions"
    [(update)]="hasUpdated"
  ></highcharts-chart>
</div>
