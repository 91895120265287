<section>
  <div class="full-width">
    <div class="badge-container title-container">
      <p class="subtitle">
        {{ 'agriness.settings.targets.editor.pre-badge' | translate }}
      </p>
      <p class="badge">
        {{ 'agriness.stage.' + formDataSubscribed.stage | translate }}
      </p>
    </div>
  </div>
  <div class="full-width">
    <div class="badge-container title-container">
      <p class="subtitle">
        {{ ('agriness.settings.targets.editor.step_2.target_group' | translate) + ':' }}
      </p>
      <p class="plain-text">
        {{ formDataSubscribed.target_index.group | translate }}
      </p>
    </div>
  </div>
  <div class="full-width">
    <div class="badge-container title-container">
      <p class="subtitle">
        {{ ('agriness.settings.targets.editor.step_2.index' | translate) + ':' }}
      </p>
      <p class="plain-text">
        {{ formDataSubscribed.target_index.name | translate }}
      </p>
    </div>
  </div>
  <div class="full-width">
    <div class="badge-container">
      <div class="period-container">
        <p class="plain-text">
          {{ 'agriness.settings.targets.editor.step_2.period_type.title' | translate }}
        </p>
        <div *ngIf="showPeriodInfo" class="period-info">
          <i class="pi pi-info-circle"></i>
          <span>{{ translationContext + '.step_2.period_type.info' | translate }}</span>
        </div>

        <div class="full-width">
          <corp-radio-options
            (optionSelected)="onPeriodTypeSelected($event)"
            controlName="period_type"
            [form]="form"
            [options]="periodOptions"
            [isHorizontal]="true"
          ></corp-radio-options>
        </div>
      </div>
    </div>
  </div>
  <div class="full-width" *ngIf="form.controls.period_type.value !== null">
    <div class="container-targets">
      <div class="status-bar">
        <div class="is-2"></div>
        <div>
          <ag-status-target-representation
            [target]="formDataSubscribed.target_index.representation_type"
          ></ag-status-target-representation>
        </div>
        <div class="is-2"></div>
      </div>

      <div class="full-width" *ngFor="let targetControl of targetValues.controls; let i = index">
        <div class="input-columns full-width">
          <div class="input-fields">
            <div
              *ngIf="form.controls.period_type.value !== periodTypeEnum.GENERAL"
              class="column px-0 input-column"
            >
              <ag-number-field
                inputWidth="100%"
                [group]="targetControl"
                controlName="period"
                [errorMessage]="targetFormErrors[i]?.period"
                [label]="translationContext + '.step_2.fields.period' | translate"
              >
              </ag-number-field>
            </div>
            <div class="column px-0 input-column">
              <ag-number-field
                [disabled]="true"
                inputWidth="100%"
                [group]="targetControl"
                controlName="min_allowed"
                [errorMessage]="targetFormErrors[i]?.min_allowed"
                [label]="translationContext + '.step_2.fields.min_allowed' | translate"
              >
              </ag-number-field>
            </div>
            <div class="column px-0 input-column">
              <ag-number-field
                inputWidth="100%"
                [group]="targetControl"
                controlName="min_recommended"
                [errorMessage]="targetFormErrors[i]?.min_recommended"
                [label]="translationContext + '.step_2.fields.min_recommended' | translate"
              ></ag-number-field>
            </div>
            <div class="column px-0 input-column">
              <div>
                <ag-number-field
                  inputWidth="100%"
                  [group]="targetControl"
                  controlName="target_value"
                  [errorMessage]="targetFormErrors[i]?.target_value"
                  [label]="translationContext + '.step_2.fields.target_value' | translate"
                >
                </ag-number-field>
              </div>
              <div class="column px-0 input-column">
                <div>
                  <ag-number-field
                    [inputWidth]="inputWidth"
                    [group]="targetControl"
                    controlName="value_variation_rate"
                    [errorMessage]="targetFormErrors[i]?.value_variation_rate"
                    [label]="translationContext + '.step_2.fields.value_variation_rate' | translate"
                  ></ag-number-field>
                </div>
              </div>
            </div>
            <div class="column px-0 input-column">
              <ag-number-field
                inputWidth="100%"
                [group]="targetControl"
                controlName="max_recommended"
                [errorMessage]="targetFormErrors[i]?.max_recommended"
                [label]="translationContext + '.step_2.fields.max_recommended' | translate"
              ></ag-number-field>
            </div>

            <div class="column is-2 px-0">
              <ag-number-field
                [disabled]="true"
                inputWidth="100%"
                [group]="targetControl"
                controlName="max_allowed"
                [errorMessage]="targetFormErrors[i]?.max_allowed"
                [label]="translationContext + '.step_2.fields.max_allowed' | translate"
              ></ag-number-field>
            </div>
            <div
              *ngIf="form.controls.period_type.value != periodTypeEnum.GENERAL"
              class="column is-1 px-0 button-add-remove"
            >
              <button class="btn btn-fab-primary mt-2 mr-1">
                <span class="icon icon-add-circle-outline" (click)="addConfigRange()"></span>
              </button>
              <button class="btn btn-fab-primary mt-2 mr-1" [disabled]="cantRemove()">
                <span class="icon icon-remove-circle-outline" (click)="remove(i)"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
