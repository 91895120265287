import { AnimalGroupTab } from '../../record/animal-group/animal-group.model';

export const defaultAnimalGroupTabs: AnimalGroupTab[] = [
  {
    router: 'open',
    translateKey: 'agriness.open_animal_group',
  },
  {
    router: 'closed',
    translateKey: 'agriness.closed_animal_group',
  },
];
