<div class="card-report card shadow-sm">
  <div class="card-header">
    {{ 'agriness.looker.' + data.name + '.title' | translate }}
  </div>
  <div class="card-body">
    <p>
      {{ 'agriness.looker.' + data.name + '.description' | translate }}
    </p>
  </div>
  <div class="card-footer justify-content-end">
    <a
      class="btn btn-primary"
      id="btn-open-report_{{ data.name }}"
      routerLink="looker-panel"
      [state]="{ data: data }"
      (click)="onOpen()"
      >{{ 'agriness.open' | translate }}</a
    >
  </div>
</div>
