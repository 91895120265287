import { Injectable } from '@angular/core';
import { delay } from 'lodash';

import { ServicesConfigService } from './services-config.service';

@Injectable()
export class TagManagerService {
  constructor(private servicesConfig: ServicesConfigService) {}

  init() {
    if (this.servicesConfig.production && this.servicesConfig.tagManagerId) {
      delay(() => this.googleTagManager(), 3000, 'TAG_MANAGER');
    }
  }

  private googleTagManager() {
    (function (w: any, d: any, s: any, l: any, i: any) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', this.servicesConfig.tagManagerId);
  }
}
