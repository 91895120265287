import {
  AnimalGroup,
  AnimalGroupAbstractService,
  PerformanceItems,
  ViewPreferenceEnum,
  TypeViewPreference,
} from '@agriness/corp-app/services';
import { CorpPerformanceGraphComponent } from '@agriness/corp-app/shared/component/corp-performance-graph/corp-performance-graph.component';
import { TableService } from '@agriness/corp-app/shared/services/table.service';
import { AgrinessTranslateService, TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { DateService, StageEnum, UserStorageService } from '@agriness/services';
import { FeedbackEnum, TableColumn } from '@agriness/ui';
import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { CorpPreferenceToggleColumnComponent } from '../../../shared/component/corp-preference-toggle-column/corp-preference-toggle-column.component';
import { LoaderUserPreference } from '../../../shared/component/loader-user-preference';
import { onSortRowObject } from '../../../shared/model/table.model';
import { AnimalGroupStateService } from '../animal-group-state.service';
import { AnimalGroupService } from '../animal-group.service';
import {
  displayIcon,
  DisplayOption,
  DisplayType,
  PerformanceData,
  columnWidth,
} from './performance.model';

@Component({
  templateUrl: './performance.component.html',
})
export class PerformanceComponent extends LoaderUserPreference implements OnInit, AfterViewInit {
  private static PREFIX_CHART = 'agriness.performances.';
  private static PREFIX_TABLE = 'agriness.animal_group_list.';

  @ViewChild('preferences', { static: true })
  preferences: CorpPreferenceToggleColumnComponent;

  @ViewChild('pg') pg: CorpPerformanceGraphComponent;

  @ViewChildren('dt')
  private pTables: QueryList<Table>;

  pTable: Table;
  chartHeight = '420px';

  animalGroup: AnimalGroup;
  performance: PerformanceItems;

  performanceData: PerformanceData;

  displayOptions: SelectItem[];
  selectedDisplayOption = DisplayOption.BY_AGE;

  feedbackType? = FeedbackEnum.LOADING;

  preferenceType = ViewPreferenceEnum.PERFORMANCE_GRAPH;
  preferenceLabelProvider: string = this.getIndexName.bind(this);
  stage = StageEnum.LAYERS_PRODUCTION;
  hiddenColumns: string[];

  dateFormat: string;
  scrollableTableColumns: TableColumn[] = [];
  fixedIndex = 'CURRENT_AGE';
  frozenTableColumns: TableColumn[] = [];
  frozenColumns = ['date', 'current_age'];
  graphPreferences: TypeViewPreference[] = [];

  onSort = onSortRowObject;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    protected userStorageService: UserStorageService,
    public tableService: TableService,
    private animalService: AnimalGroupAbstractService,
    private animalGroupService: AnimalGroupService,
    private animalGroupStateService: AnimalGroupStateService,
    private dateService: DateService,
    private translateService: AgrinessTranslateService,
  ) {
    super(userStorageService);

    this.t = this.translateService.instantWithFallback;
  }

  ngOnInit(): void {
    this.feedbackType = FeedbackEnum.LOADING;
    this.dateFormat = this.dateService.getDateFormat();

    this.initDisplayOptions();

    this.animalGroupStateService
      .getAnimalGroup()
      .pipe(
        switchMap((animalGroup: AnimalGroup) => {
          return forkJoin([of(animalGroup), this.getObservable(animalGroup)]);
        }),
        tap(([animalGroup, performance]) => {
          this.animalGroup = animalGroup;
          this.performance = performance;

          this.feedbackType = !this.hasData() ? FeedbackEnum.NOT_RESULT : undefined;
          this.updatePerformanceData();
        }),
        catchError(err => {
          this.feedbackType = FeedbackEnum.ERROR;

          return throwError(err);
        }),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.loadPreferences().subscribe(() => this.onPreferenceChange());

    this.pTables.changes.subscribe((item: QueryList<Table>) => (this.pTable = item.first));
  }

  selectDisplayOption(event: { value: DisplayOption }): void {
    this.selectedDisplayOption = event.value;

    this.updatePerformanceData();
  }

  isChart(): boolean {
    return Boolean(this.performanceData && this.performanceData.displayType === DisplayType.CHART);
  }

  isTable(): boolean {
    return Boolean(this.performanceData && this.performanceData.displayType === DisplayType.TABLE);
  }

  nextDisplayIcon(): string {
    return displayIcon[
      this.getNextDisplayType(this.performanceData?.displayType || DisplayType.CHART)
    ];
  }

  onNextDisplayTypeClick(): void {
    this.performanceData.displayType = this.getNextDisplayType(this.performanceData.displayType);
  }

  onPreferenceChange(): void {
    this.loadColumns();

    this.graphPreferences = this.getPreferences().filter(({ enable }) => enable);
  }

  private getIndexName(indexKey: string): string {
    return this.t([PerformanceComponent.PREFIX_TABLE + indexKey]);
  }

  private getNextDisplayType(displayType: DisplayType): DisplayType {
    return displayType === DisplayType.CHART ? DisplayType.TABLE : DisplayType.CHART;
  }

  private initDisplayOptions(): void {
    this.displayOptions = [
      {
        label: this.t(
          `${PerformanceComponent.PREFIX_CHART}flock_performance_over_time.display_option.by_age`,
        ),
        value: DisplayOption.BY_AGE,
      },
      {
        label: this.t(
          `${PerformanceComponent.PREFIX_CHART}flock_performance_over_time.display_option.by_date`,
        ),
        value: DisplayOption.BY_DATE,
      },
    ];
  }

  private getObservable(animalGroup: AnimalGroup): Observable<PerformanceItems> {
    return this.subscribePerformance(animalGroup);
  }

  private subscribePerformance(animalGroup: AnimalGroup): Observable<PerformanceItems> {
    return this.animalService.getAnimalGroupLayersPerformanceGraph(
      this.holdingId,
      animalGroup.farm.id,
      animalGroup.id,
      this.fixedIndex,
    );
  }

  private hasData(): boolean {
    return Boolean(this.performance);
  }

  private updatePerformanceData(): void {
    this.performanceData = {
      displayType: DisplayType.CHART,
      displayOption: this.selectedDisplayOption,
      data: this.performance,
      downloadFilename: this.getDownloadFilename(
        `${PerformanceComponent.PREFIX_CHART}flock_performance_over_time.title`,
      ),
    };
  }

  private getDownloadFilename(section: string): string {
    return this.animalGroupService.getDownloadFilename(this.animalGroup, section);
  }

  private loadColumns(): void {
    const allTableColumns = this.getPreferences()
      .filter(({ enable }) => enable)
      .map(({ index_name }) => ({
        field: index_name.toLowerCase(),
        header: this.getIndexName(index_name),
        sortable: true,
        width: columnWidth[index_name.toLowerCase()] || '140px',
      }));

    this.frozenTableColumns = [];
    allTableColumns.map(column => {
      if (this.frozenColumns.includes(column.field)) {
        this.frozenTableColumns.push(column);
      }
    });

    this.scrollableTableColumns = allTableColumns.filter(
      item => !this.frozenTableColumns.includes(item),
    );
  }

  private loadPreferences() {
    return this.preferences.load();
  }

  private getPreferences() {
    this.preferences.setHiddenItems(this.hiddenColumns);

    return this.preferences.getPreferences();
  }
}
