<section>
  <div class="mb-3">
    <div class="d-flex">
      <corp-filters-container #filtersContainer (filter)="filter()" (resetFilter)="filterReset()">
        <corp-target-group-filter></corp-target-group-filter>
        <corp-target-kpi-list-filter [stage]="stage"></corp-target-kpi-list-filter>
        <corp-target-period-type-filter></corp-target-period-type-filter>
      </corp-filters-container>
    </div>
    <corp-report-filters-subtitle></corp-report-filters-subtitle>
  </div>

  <div class="row mb-5">
    <div class="col">
      <corp-record-table
        #table
        [title]="translationContext + '.table.title' | translate"
        [tableColumns]="tableColumns"
        [defaultOrdering]="defaultOrdering"
        [actions]="availableActions"
        [data]="targets"
        [totalRecords]="totalRecords"
        [loading]="loading"
        [error]="error"
        (addClick)="onAddTarget()"
        (actionClick)="onItemAction($event)"
        (pageRequest)="onPageRequest($event)"
        [shouldBlockAction]="shouldBlockAction"
        [hasEditActionOnToggle]="true"
        [toggleIconTooltipKey]="translationContext + '.table.toggle_tooltip'"
        [blockedIconTooltipKey]="translationContext + '.table.blocked_tooltip'"
      >
      </corp-record-table>
    </div>
  </div>

  <corp-modal-deletion
    [visible]="deletionModalVisible"
    [modalStatus]="deletionModalStatus"
    title="{{ translationContext + '.deletion' | translate }}"
    (delete)="onDeletionModalDelete()"
    (cancel)="onDeletionModalClose()"
  >
    <deletion-table [data]="deletionTableData"></deletion-table>
  </corp-modal-deletion>
</section>
