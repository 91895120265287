import { Injectable } from '@angular/core';

interface ILocalStorage<T> {
  items: T[];
  lastUpdate: Date;
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  setItem<T>(key: string, value: T[]): void {
    localStorage.setItem(
      key,
      JSON.stringify({
        items: [...value],
        lastUpdate: new Date(),
      }),
    );
  }

  getItem<T>(key: string): ILocalStorage<T> {
    return JSON.parse(localStorage.getItem(key)) || [];
  }

  removeItems(): void {
    localStorage.removeItem('producers');
  }
}
