import { userHoldingRelationByName, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';

@Injectable()
export class UserHoldingPermissionService {
  constructor(protected userStorageService: UserStorageService) {}

  hasOneHoldingRelationType(userHoldingRelationType: string[]): boolean {
    const userAgriness = this.userStorageService.getUserAgriness();
    const userRelation = userAgriness.userPreferences.currentHolding.users_relations.find(
      relation => relation.user.id == userAgriness.userProfile.id,
    );
    const userRelationType = userHoldingRelationByName[userRelation?.relation_type?.name];

    if (userHoldingRelationType.includes(userRelationType)) {
      return true;
    }
    return false;
  }
}
