import { SiteEnum, SiteSystemType, UserStorageService } from '@agriness/services';
import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { snakeCase } from 'lodash';

import { AnimalGroupTab } from '../../record/animal-group/animal-group.model';
import { LoaderUserPreference } from '../../shared/component/loader-user-preference';
import { defaultAnimalGroupTabs } from './monitor.model';

@Component({
  templateUrl: './monitor.component.html',
})
export class NurseryMonitorComponent extends LoaderUserPreference {
  stage = SiteSystemType[environment.typeProduction][SiteEnum.SITE_2];
  titleKey = `agriness.monitor_${snakeCase(this.stage)}`;

  tabs: AnimalGroupTab[] = defaultAnimalGroupTabs;

  constructor(protected userStorageService: UserStorageService) {
    super(userStorageService);
  }
}
