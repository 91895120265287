<ag-modal
  [visible]="modalStatus != null"
  [showHeader]="false"
  [showModalStatus]="modalStatus"
  (hide)="hideModal()"
>
  <ag-modal-feedback
    [translationContext]="translationContextModal"
    [status]="modalStatus"
    (confirm)="publishToBlockchain()"
    (hide)="hideModal()"
  ></ag-modal-feedback>
</ag-modal>

<section class="section is-full">
  <div class="page-title d-flex align-items-center mb-3">
    <corp-back-button></corp-back-button>
    {{ t('title') }} {{ animalGroupName }}
    <ag-feedback-leave-page
      [visible]="feedbackVisible"
      [translationContext]="translationContext + '.feedback_leave_page'"
      (cancel)="onPageLeave(false)"
      (confirm)="onPageLeave(true)"
    ></ag-feedback-leave-page>
    <button
      (click)="downloadDraft()"
      class="btn btn-ghost has-round-hover mr-1"
      data-testid="draft-button"
    >
      <span class="icon is-16 icon-pdf has-text-grey-3"></span>
    </button>
  </div>

  <ag-feedback [type]="feedbackType"> </ag-feedback>

  <div *ngIf="!feedbackType" [formGroup]="formGroup" class="card shadow-sm">
    <section class="mx-3 mt-2">
      <div class="section-header mx-2">
        {{ t('producer') }}
      </div>

      <div class="columns">
        <div class="column is-one-quarter">
          <ag-text-field
            data-testid="producer-text-field"
            [formGroup]="formGroup.get('producer')"
            controlName="name"
            [label]="t('producer')"
          >
          </ag-text-field>
        </div>

        <div class="column is-one-quarter">
          <ag-text-field
            data-testid="farm-text-field"
            [formGroup]="formGroup.get('farm')"
            controlName="name"
            [label]="t('farm')"
          ></ag-text-field>
        </div>

        <div class="column is-one-quarter">
          <ag-text-field
            data-testid="technician-name-text-field"
            [formGroup]="formGroup.get('technician')"
            controlName="name"
            [label]="t('technician.name')"
          >
          </ag-text-field>
        </div>

        <div class="column is-one-quarter">
          <ag-text-field
            data-testid="technician-state-defense-agency-register-number-text-field"
            [formGroup]="formGroup.get('technician')"
            controlName="state_defense_agency_register_number"
            [label]="t('technician.state_defense_agency_register_number')"
          ></ag-text-field>
        </div>
      </div>

      <div class="columns">
        <div class="column is-one-quarter">
          <ag-text-field
            data-testid="georeferencing-text-field"
            [formGroup]="formGroup"
            controlName="georeferencing"
            [label]="t('georeferencing')"
          >
          </ag-text-field>
        </div>

        <div class="column is-one-quarter">
          <ag-text-field
            data-testid="city-text-field"
            [formGroup]="formGroup"
            controlName="city"
            [label]="t('city')"
          ></ag-text-field>
        </div>

        <div class="column is-one-quarter">
          <ag-text-field
            data-testid="federative-unit-text-field"
            [formGroup]="formGroup"
            controlName="federative_unit"
            [label]="t('federative_unit')"
          >
          </ag-text-field>
        </div>
      </div>
    </section>

    <section class="mx-3 mt-2">
      <div class="section-header mx-2">
        {{ t('information_responsible.title') }}
      </div>

      <div class="columns mx-2 mt-3">
        <input
          class="radio-button"
          type="radio"
          id="information-responsible-one"
          [formGroup]="formGroup"
          formControlName="information_responsible"
          value="official_veterinarian"
        />
        <label for="information-responsible-one" class="radio-label mr-5">
          {{ t('information_responsible.official_veterinarian') }}
        </label>

        <input
          class="radio-button"
          type="radio"
          id="information-responsible-two"
          [formGroup]="formGroup"
          formControlName="information_responsible"
          value="private_veterinarian"
        />
        <label for="information-responsible-two" class="radio-label mr-5">
          {{ t('information_responsible.private_veterinarian') }}
        </label>

        <input
          class="radio-button"
          type="radio"
          id="information-responsible-three"
          [formGroup]="formGroup"
          formControlName="information_responsible"
          value="owner"
        />
        <label for="information-responsible-three" class="radio-label mr-5">
          {{ t('information_responsible.owner') }}
        </label>
      </div>
    </section>
    <hr class="mx-3" />

    <section class="mx-3 mt-2">
      <div class="section-header mx-2">
        {{ t('traceability.title') }}
      </div>

      <div class="columns mx-2 mt-3">
        <input
          class="radio-button"
          type="radio"
          id="traceability-type-one"
          [formGroup]="formGroup"
          formControlName="traceability_type"
          value="complete_cycle"
        />
        <label for="traceability-type-one" class="radio-label mr-5">
          {{ t('traceability.complete_cycle') }}
        </label>

        <input
          class="radio-button"
          type="radio"
          id="traceability-type-two"
          [formGroup]="formGroup"
          formControlName="traceability_type"
          value="terminator"
        />
        <label for="traceability-type-two" class="radio-label mr-5">
          {{ t('traceability.terminator') }}
        </label>

        <input
          class="radio-button"
          type="radio"
          id="traceability-type-three"
          [formGroup]="formGroup"
          formControlName="traceability_type"
          value="breeders_or_discards"
        />
        <label for="traceability-type-three" class="radio-label mr-5">
          {{ t('traceability.breeders_or_discards') }}
        </label>
      </div>
    </section>

    <section class="mx-3 mt-2">
      <div class="section-header mx-2">
        {{ t('piglets_loads.title') }}
      </div>
      <div class="columns">
        <div class="column is-11">
          <div class="columns" *ngFor="let pigletsLoadControl of pigletsLoads.controls">
            <div class="column is-one-fifth">
              <ag-text-field
                data-testid="piglets-loads-registration-id-text-field"
                [formGroup]="pigletsLoadControl"
                controlName="registration_id"
                [label]="t('piglets_loads.registration_id')"
              ></ag-text-field>
            </div>

            <div class="column">
              <ag-text-field
                data-testid="piglets-loads-registration-amount-text-field"
                [formGroup]="pigletsLoadControl"
                controlName="registration_amount"
                [label]="t('piglets_loads.registration_amount')"
              ></ag-text-field>
            </div>

            <div class="column">
              <ag-text-field
                data-testid="piglets-loads-deaths-text-field"
                [formGroup]="pigletsLoadControl"
                controlName="deaths"
                [label]="t('piglets_loads.deaths')"
              >
              </ag-text-field>
            </div>

            <div class="column">
              <div class="form-group">
                <ag-calendar
                  data-testid="piglets-loads-incoming-date-calendar"
                  [group]="pigletsLoadControl"
                  controlName="incoming_date"
                  [dateFormat]="dateFormat"
                  [label]="t('piglets_loads.incoming_date')"
                ></ag-calendar>
              </div>
            </div>

            <div class="column">
              <ag-text-field
                data-testid="piglets-loads-animal-number-text-field"
                [formGroup]="pigletsLoadControl"
                controlName="animal_number"
                [label]="t('piglets_loads.animal_number')"
              ></ag-text-field>
            </div>
          </div>
        </div>
        <div class="column is-1 px-0">
          <button class="btn btn-fab-primary mt-2 mr-1" [disabled]="cantAddPigletsLoads()">
            <span class="icon icon-add-circle-outline" (click)="addPigletsLoads()"></span>
          </button>
          <button class="btn btn-fab-primary mt-2 mr-1" [disabled]="cantRemovePigletsLoads()">
            <span class="icon icon-remove-circle-outline" (click)="removePigletsLoads()"></span>
          </button>
        </div>
      </div>
    </section>
    <hr class="mx-3" />

    <section class="mx-3 mt-2">
      <div class="section-header mx-2">
        {{ t('piglets_slaughter.title') }}
      </div>

      <div class="columns">
        <div class="column is-11">
          <div class="columns" *ngFor="let pigletsSlaughterControl of pigletsSlaughter.controls">
            <div class="column">
              <ag-text-field
                data-testid="piglets-slaughter-registration-id-text-field"
                [formGroup]="pigletsSlaughterControl"
                controlName="registration_id"
                [label]="t('piglets_slaughter.registration_id')"
              ></ag-text-field>
            </div>

            <div class="column">
              <div class="form-group">
                <ag-calendar
                  data-testid="piglets-slaughter-registration-date-calendar"
                  [group]="pigletsSlaughterControl"
                  controlName="registration_date"
                  [dateFormat]="dateFormat"
                  [label]="t('piglets_slaughter.registration_date')"
                ></ag-calendar>
              </div>
            </div>

            <div class="column">
              <div class="form-group">
                <ag-calendar
                  data-testid="piglets-slaughter-outgoing-date-calendar"
                  [group]="pigletsSlaughterControl"
                  controlName="outgoing_date"
                  [dateFormat]="dateFormat"
                  [label]="t('piglets_slaughter.outgoing_date')"
                ></ag-calendar>
              </div>
            </div>

            <div class="column">
              <ag-text-field
                data-testid="piglets-slaughter-animal-number-text-field"
                [formGroup]="pigletsSlaughterControl"
                controlName="animal_number"
                [label]="t('piglets_slaughter.animal_number')"
              ></ag-text-field>
            </div>

            <div class="column">
              <ag-text-field
                data-testid="piglets-slaughter-mortality-rate-text-field"
                [formGroup]="pigletsSlaughterControl"
                controlName="mortality_rate"
                [label]="t('piglets_slaughter.mortality_rate')"
              ></ag-text-field>
            </div>
          </div>
        </div>

        <div class="column is-1 px-0">
          <button class="btn btn-fab-primary mt-2 mr-1" [disabled]="cantAddPigletsSlaughter()">
            <span class="icon icon-add-circle-outline" (click)="addPigletsSlaughter()"></span>
          </button>
          <button class="btn btn-fab-primary mt-2 mr-1" [disabled]="cantRemovePigletsSlaughter()">
            <span class="icon icon-remove-circle-outline" (click)="removePigletsSlaughter()"></span>
          </button>
        </div>
      </div>
    </section>
    <hr class="mx-3" />

    <section class="mx-3 mt-2">
      <div class="section-header mx-2">
        {{ t('info.title') }}
      </div>
      <div class="columns">
        <div class="column is-11">
          <div class="columns">
            <div class="column is-one-quarter">
              <ag-text-field
                data-testid="info-remaining-animal-count-text-field"
                [formGroup]="formGroup"
                controlName="remaining_animal_count"
                [label]="t('info.remaining_animal_count')"
              ></ag-text-field>
            </div>

            <div class="column is-one-quarter">
              <ag-text-field
                data-testid="info-mortality-text-field"
                [formGroup]="formGroup"
                controlName="mortality"
                [label]="t('info.mortality')"
              >
              </ag-text-field>
            </div>

            <div class="column is-half">
              <ag-text-field
                data-testid="info-clinical-signs-text-field"
                [formGroup]="formGroup"
                controlName="clinical_signs"
                [label]="t('info.clinical_signs')"
              >
              </ag-text-field>
            </div>
          </div>
        </div>
      </div>
    </section>
    <hr class="mx-3" />

    <section class="mx-3 mt-2">
      <div class="section-header mx-2">
        {{ t('drugs.title') }}
      </div>
      <div class="columns">
        <div class="column is-11">
          <div class="columns" *ngFor="let drugsControl of drugs.controls">
            <div class="column">
              <ag-text-field
                data-testid="drugs-active-principle-text-field"
                [formGroup]="drugsControl"
                controlName="active_principle"
                [label]="t('drugs.active_principle')"
              ></ag-text-field>
            </div>

            <div class="column">
              <div class="form-group">
                <ag-calendar
                  data-testid="drugs-start-date-calendar"
                  [group]="drugsControl"
                  controlName="start_date"
                  [dateFormat]="dateFormat"
                  [label]="t('drugs.start_date')"
                >
                </ag-calendar>
              </div>
            </div>

            <div class="column">
              <div class="form-group">
                <ag-calendar
                  data-testid="drugs-slaughter-clearance-date-calendar"
                  [group]="drugsControl"
                  controlName="slaughter_clearance_date"
                  [dateFormat]="dateFormat"
                  [label]="t('drugs.slaughter_clearance_date')"
                ></ag-calendar>
              </div>
            </div>

            <div class="column">
              <ag-text-field
                data-testid="drugs-slaughter-clearance-text-field"
                [formGroup]="drugsControl"
                controlName="slaughter_clearance"
                [label]="t('drugs.slaughter_clearance')"
              ></ag-text-field>
            </div>
          </div>
        </div>
        <div class="column is-1 px-0">
          <button class="btn btn-fab-primary mt-2 mr-1" [disabled]="cantAddDrugs()">
            <span class="icon icon-add-circle-outline" (click)="addDrugs()"></span>
          </button>
          <button class="btn btn-fab-primary mt-2 mr-1" [disabled]="cantRemoveDrugs()">
            <span class="icon icon-remove-circle-outline" (click)="removeDrugs()"></span>
          </button>
        </div>
      </div>
    </section>
    <hr class="mx-3" />

    <section class="mx-3 mt-2">
      <div class="section-header mx-2">
        {{ t('vaccines.title') }}
      </div>
      <div class="columns">
        <div class="column is-6">
          <div class="columns" *ngFor="let vaccinesControl of vaccines.controls">
            <div class="column">
              <div class="form-group">
                <ag-calendar
                  data-testid="vaccines-vaccination-date-calendar"
                  [group]="vaccinesControl"
                  controlName="vaccination_date"
                  [dateFormat]="dateFormat"
                  [label]="t('vaccines.vaccination_date')"
                ></ag-calendar>
              </div>
            </div>
            <div class="column">
              <ag-text-field
                data-testid="vaccines-vaccine-text-field"
                [formGroup]="vaccinesControl"
                controlName="vaccine_name"
                [label]="t('vaccines.vaccine')"
              >
              </ag-text-field>
            </div>
          </div>
        </div>
        <div class="column is-1 px-0">
          <button class="btn btn-fab-primary mt-2 mr-1" [disabled]="cantAddVaccines()">
            <span class="icon icon-add-circle-outline" (click)="addVaccines()"></span>
          </button>
          <button class="btn btn-fab-primary mt-2 mr-1" [disabled]="cantRemoveVaccines()">
            <span class="icon icon-remove-circle-outline" (click)="removeVaccines()"></span>
          </button>
        </div>
      </div>
    </section>

    <hr class="mx-3" />

    <section class="mx-3 mt-2">
      <div class="section-header mx-2">
        {{ t('last_feed.title') }}
      </div>

      <div class="columns">
        <div class="column is-one-quarter">
          <div class="form-group">
            <ag-calendar
              data-testid="last-feed-last-feed-date-calendar"
              [group]="formGroup"
              controlName="last_feed_date"
              [dateFormat]="dateFormat"
              [label]="t('last_feed.last_feed_date')"
            >
            </ag-calendar>
          </div>
        </div>

        <div class="column is-one-quarter">
          <div class="form-group">
            <ag-time-field
              data-testid="last-feed-last-feed-hour-time-field"
              [group]="formGroup"
              controlName="last_feed_hour"
              [label]="t('last_feed.last_feed_hour')"
            >
            </ag-time-field>
          </div>
        </div>
      </div>
    </section>

    <hr />

    <section class="mx-3 my-3">
      <div class="float-right">
        <button (click)="saveDraft()" class="mr-3 btn btn-ghost is-medium text">
          {{ t('save') }}
        </button>
        <button (click)="confirmPublish()" class="btn btn-primary is-medium text">
          {{ t('publish') }}
        </button>
      </div>
    </section>
  </div>
</section>
