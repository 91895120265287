<section class="modal-save">
  <div class="content column">
    <div class="icon-content row">
      <ag-feedback-icon [type]="'info'"> </ag-feedback-icon>
    </div>
    <div class="text-content row">
      <span class="text-modal">{{ translationContext + '.text' | translate }}</span>
    </div>
    <div class="button-content row">
      <div class="button-box">
        <button class="btn btn-ghost" (click)="reject.next()">
          {{ translationContext + '.reject' | translate }}
        </button>
      </div>
      <div class="button-box">
        <button class="btn btn-primary" (click)="confirm.next()">
          {{ translationContext + '.save' | translate }}
        </button>
      </div>
    </div>
  </div>
</section>
