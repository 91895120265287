import { defaultRoutesOrder, RoutesFilter, Routes } from '@agriness/corp-app/services';
import { RoutesService } from '@agriness/corp-app/services/routes/routes.service';
import { DeletionTableData } from '@agriness/corp-app/settings/deletion-table/deletion-table.model';
import { CorpFiltersContainerComponent } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.component';
import { CorpFiltersContainerService } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.service';
import {
  ActionEnum,
  CorpRecordTableComponent,
} from '@agriness/corp-app/shared/component/corp-record-table/corp-record-table.component';
import {
  ReportFilter,
  ReportFilterQueryKeys,
} from '@agriness/corp-app/shared/model/report-filter.model';
import { PaginationRequest } from '@agriness/corp-app/shared/model/table.model';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, ViewChild, Inject } from '@angular/core';
import { throwError } from 'rxjs';
import { first, map, catchError } from 'rxjs/operators';

import { RouteEditorComponent } from './route-editor/route-editor.component';
import { routesDeletionTableFields, routesTableColumns } from './routes.model';

@Component({
  selector: 'app-routes',
  templateUrl: './routes.component.html',
  providers: [CorpFiltersContainerService.provider],
})
export class RoutesComponent {
  @ViewChild('filtersContainer', { static: true }) reportFilter: CorpFiltersContainerComponent;
  @ViewChild('table', { static: true }) table: CorpRecordTableComponent<Routes>;
  @ViewChild('editor') routeEditor: RouteEditorComponent;

  translationContext = 'agriness.settings.routes';

  tableColumns = routesTableColumns;
  defaultOrdering = defaultRoutesOrder;
  availableActions = [ActionEnum.EDIT, ActionEnum.DELETE];
  routes: Routes[];
  totalRecords: number;
  rows = 10;

  editorModalVisible: boolean;
  editorModalStatus = ModalStatusEnum.DEFAULT;
  selectedRoute: Routes;
  deletionModalVisible = false;
  deletionModalStatus = ModalStatusEnum.DEFAULT;
  deletionTableData: DeletionTableData;

  error = false;
  loading = true;
  currentFilter: RoutesFilter = {};
  filterEntity: keyof ReportFilter = 'route';
  queryParamName: ReportFilterQueryKeys = 'routes_name';

  private queryCorpOnly = true;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private routeService: RoutesService,
  ) {}

  createRoutes(): void {
    const formValues = this.routeEditor.getRoutesForm();
    this.editorModalStatus = ModalStatusEnum.LOADING;

    this.routeService
      .save(formValues)
      .pipe(
        first(),
        map(() => (this.editorModalStatus = ModalStatusEnum.SUCCESS)),
        catchError(({ error }) => {
          this.editorModalStatus = ModalStatusEnum.FAILED;
          return throwError(error);
        }),
      )
      .subscribe();
  }

  filter(): void {
    this.table.reset();
  }

  filterReset(): void {
    this.reportFilter.onFilter();
  }

  onAddRoute(): void {
    this.selectedRoute = null;
    this.editorModalVisible = true;
  }

  onDeletionModalClose(): void {
    this.selectedRoute = null;
    this.deletionModalVisible = false;
    this.deletionModalStatus = ModalStatusEnum.DEFAULT;
    this.filter();
  }

  onDeletionModalDelete(): void {
    this.deletionModalStatus = ModalStatusEnum.LOADING;

    this.routeService.delete(this.selectedRoute).subscribe({
      complete: () => {
        this.deletionModalStatus = ModalStatusEnum.SUCCESS;
      },
      error: error => {
        this.deletionModalStatus = ModalStatusEnum.FAILED;
        throw error;
      },
    });
  }

  onEditorClosed(): void {
    this.selectedRoute = null;
    this.editorModalVisible = false;
    this.editorModalStatus = ModalStatusEnum.DEFAULT;
    this.filter();
  }

  onItemAction(obj: { action: ActionEnum; item: Routes }): void {
    switch (obj.action) {
      case ActionEnum.EDIT:
        this.selectedRoute = obj.item;
        this.editorModalVisible = true;
        break;
      case ActionEnum.DELETE:
        this.selectedRoute = obj.item;
        this.updateDeletionTableData(this.selectedRoute);
        this.deletionModalVisible = true;
    }
  }

  onPageRequest(request: PaginationRequest): void {
    this.loading = true;
    this.updateCurrentFilter(request);
    this.loadRoutes();
  }

  updateRoutes(): void {
    if (this.selectedRoute) {
      const { id } = this.selectedRoute;
      const formValues = this.routeEditor.getRoutesForm();
      this.editorModalStatus = ModalStatusEnum.LOADING;

      this.routeService
        .update(formValues, id)
        .pipe(
          first(),
          map(() => (this.editorModalStatus = ModalStatusEnum.SUCCESS)),
          catchError(({ error }) => {
            this.editorModalStatus = ModalStatusEnum.FAILED;
            return throwError(error);
          }),
        )
        .subscribe();
    }
  }

  private loadRoutes(): void {
    this.routeService
      .list(this.currentFilter)
      .pipe(
        first(),
        map(({ count, results }) => {
          this.totalRecords = count;
          this.routes = results;
          this.loading = false;
        }),
        catchError(error => {
          this.error = true;
          return throwError(error);
        }),
      )
      .subscribe();
  }

  private updateCurrentFilter(request: PaginationRequest): void {
    const name = this.reportFilter.getCurrentFilterInQueryFormat().routes_name;

    this.currentFilter = {
      ...this.currentFilter,
      ...request,
      name,
      queryCorpOnly: this.queryCorpOnly,
    };
  }

  private updateDeletionTableData(route: Routes): void {
    this.deletionTableData = routesDeletionTableFields.map(field => {
      return {
        field: this.t(`agriness.settings.routes.table.${field}`),
        value: route[field] as string,
      };
    });
  }
}
