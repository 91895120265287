import { AnimalGroup } from '@agriness/corp-app/services';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AnimalGroupStateService {
  private animalGroupIdSelect = new BehaviorSubject<AnimalGroup>(null);

  getAnimalGroup(): Observable<AnimalGroup> {
    return this.animalGroupIdSelect.asObservable();
  }

  setAnimalGroup(animalGroup: AnimalGroup): void {
    this.animalGroupIdSelect.next(animalGroup);
  }
}
