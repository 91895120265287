import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ButtonSize } from './../button/guavi-button.component';
import { Icons } from '@agriness/assets';

export enum GhostButtonVariant {
  WHITE = 'white',
  GREY = 'grey',
  BLUE = 'blue',
}

@Component({
  selector: 'guavi-ghost-button',
  templateUrl: './guavi-ghost-button.component.html',
  styleUrls: ['./guavi-ghost-button.component.scss'],
})
export class GuaviGhostButtonComponent {
  @Input() variant = GhostButtonVariant.WHITE;
  @Input() icon: Icons;
  @Input() size = ButtonSize.MEDIUM;

  @Output() onClick = new EventEmitter<void>();

  handleOnClick(): void {
    this.onClick.emit();
  }
}
