import { TypeProductionEnum } from '@agriness/services';
import { TabModel } from '@agriness/ui';

const basicTabs: TabModel[] = [
  {
    label: 'agriness.settings.technicians.title',
    id: 'tab-technicians',
    routerLink: `technicians`,
  },
  {
    label: 'agriness.settings.producers.title',
    id: 'tab-producers',
    routerLink: `producers`,
  },
  {
    label: 'agriness.settings.regions.title',
    id: 'tab-regions',
    routerLink: `regions`,
  },
];

export const generalTabs: { [key in TypeProductionEnum]: TabModel[] } = {
  [TypeProductionEnum.SWINES]: basicTabs,
  [TypeProductionEnum.POULTRY]: basicTabs,
  [TypeProductionEnum.DAIRY]: [
    ...basicTabs,
    {
      label: 'agriness.settings.routes.title',
      id: 'tab-routes',
      routerLink: `routes`,
    },
    {
      label: 'agriness.settings.tanks.title',
      id: 'tab-tanks',
      routerLink: `tanks`,
    },
  ],
  [TypeProductionEnum.LAYERS]: [],
};
