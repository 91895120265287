<ag-modal [visible]="showModal" [showHeader]="false" (hide)="hideModal()">
  <app-payment-confirm-modal
    (confirm)="fieldsBlocked = !fieldsBlocked; hideModal()"
    (reject)="hideModal()"
  ></app-payment-confirm-modal>
</ag-modal>
<section class="section is-full" [formGroup]="paymentForm">
  <div class="page-title d-flex align-items-center mb-3">
    <corp-back-button></corp-back-button>
    {{ translationContext + '.title' | translate }}
    <span
      class="icon icon-edit edit toggle-edit"
      *ngIf="isEdit"
      (click)="!fieldsBlocked ? (fieldsBlocked = !fieldsBlocked) : showConfirm()"
    ></span>
    <ag-feedback-leave-page
      [visible]="feedbackVisible"
      [translationContext]="translationContext + '.feedback_leave_page'"
      (cancel)="onPageLeave(true)"
      (confirm)="onPageLeave(false)"
    ></ag-feedback-leave-page>
  </div>
  <div class="form-container">
    <div class="form-section column">
      <div class="section-title full-width row">
        <span class="column is-one-quarter">{{
          translationContext + '.section_1.general-parameters' | translate
        }}</span>
      </div>
      <div class="section-data row">
        <div class="column is-one-quarter name-data">
          <div class="row name-section">
            <span class="name-title">{{
              translationContext + '.section_1.animal_group' | translate
            }}</span>
            <span class="badge">{{ animalGroupName }}</span>
          </div>
        </div>
        <div class="column is-one-quarter data-card">
          <div class="card-title row">
            {{ translationContext + '.section_1.total_animals' | translate }}
          </div>
          <div class="card-data row">{{ total_animals || '-' }}</div>
        </div>
        <div class="column is-one-quarter data-card">
          <div class="card-title row">
            {{
              translationContext + '.section_1.total_weight_output'
                | translate: { weight: weight || 'KG' }
            }}
          </div>
          <div class="card-data row">{{ total_weight_output || '-' }}</div>
        </div>
        <div class="column is-one-quarter data-card">
          <div class="card-title row">
            {{
              translationContext + '.section_1.total_weight_gain'
                | translate: { weight: weight || 'KG' }
            }}
          </div>
          <div class="card-data row">{{ total_weight_gain || '-' }}</div>
        </div>
      </div>
    </div>
    <div class="form-section column" [formGroup]="paymentForm">
      <div class="section-title full-width row">
        <span class="column is-one-quarter">{{
          translationContext + '.section_2.payment_parameters' | translate
        }}</span>
      </div>
      <div class="section-data row">
        <div class="column is-one-fifth index-name-data"></div>
        <div class="column is-one-fifth input-container">
          <div class="form-group">
            <label class="control-label">
              {{ translationContext + '.section_2.payment_table' | translate }}
            </label>
            <ag-select
              [group]="paymentForm"
              controlName="rule"
              [items$]="ruleSelectData"
              [defaultLabel]="'agriness.feedback.no_item_selected' | translate"
              dataKey="key"
              (selectValueFilter)="onRuleSelect($event)"
              [isDisabled]="fieldsBlocked"
            ></ag-select>
          </div>
        </div>
        <div class="column is-one-fifth input-container">
          <ag-text-field
            class="form-field"
            [formGroup]="paymentForm"
            controlName="delivery_indicator"
            [disabled]="true"
            [label]="translationContext + '.section_2.weight_indicator' | translate"
          ></ag-text-field>
        </div>
        <div class="column is-one-fifth input-container">
          <ag-number-field
            class="form-field"
            [group]="paymentForm"
            controlName="priceByKg"
            inputWidth="90%"
            [disabled]="ruleSelected === null ? true : fieldsBlocked"
            [label]="
              translationContext + '.section_2.weight_price' | translate: { weight: weight || 'KG' }
            "
          ></ag-number-field>
        </div>
        <div class="column is-one-fifth input-container">
          <ag-number-field
            class="form-field"
            inputWidth="100%"
            [group]="paymentForm.controls['participation']"
            controlName="total_animal_value"
            [disabled]="true"
            [label]="translationContext + '.section_2.total_animal_value' | translate"
            [hasDecimal]="true"
          ></ag-number-field>
        </div>
      </div>
    </div>
    <div class="form-section column">
      <div class="section-title full-width row">
        <span class="column is-one-quarter">{{
          translationContext + '.section_3.index_participation' | translate
        }}</span>
      </div>
      <div *ngIf="payment_indexes?.controls.length > 0 && ruleSelected !== null">
        <div class="section-data row" *ngFor="let control of payment_indexes?.controls">
          <div class="column is-one-fifth index-name-data">{{ control.value.name }}</div>
          <div class="column is-one-fifth input-container">
            <ag-number-field
              class="form-field"
              inputWidth="100%"
              [group]="control"
              controlName="value"
              [disabled]="fieldsBlocked"
              [label]="translationContext + '.section_3.index_value' | translate"
            ></ag-number-field>
          </div>
          <div class="column is-one-fifth input-container">
            <ag-number-field
              class="form-field"
              inputWidth="100%"
              [group]="control"
              [disabled]="true"
              controlName="range"
              [label]="translationContext + '.section_3.range' | translate"
            ></ag-number-field>
          </div>
          <div class="column is-one-fifth input-container">
            <ag-number-field
              class="form-field"
              inputWidth="100%"
              [group]="control"
              [disabled]="true"
              controlName="percentage"
              [label]="translationContext + '.section_3.percentage' | translate"
            ></ag-number-field>
          </div>
          <div class="column is-one-fifth input-container">
            <ag-number-field
              class="form-field"
              inputWidth="100%"
              [disabled]="true"
              [group]="control"
              controlName="total_index_price"
              [label]="
                translationContext + '.section_3.total_by_index'
                  | translate
                    : { currency: currency_translator[currency] || currency_translator['BRL'] }
              "
              [hasDecimal]="true"
            ></ag-number-field>
          </div>
        </div>
      </div>
      <div class="results-section row">
        <div class="results-title row">
          <div class="column is-one-fifth">
            {{ translationContext + '.section_3.total_participation' | translate }}
          </div>
          <div class="column is-one-fifth">
            {{
              translationContext + '.section_3.animal_group_total'
                | translate
                  : { currency: currency_translator[currency] || currency_translator['BRL'] }
            }}
          </div>
        </div>
        <div class="results-data row">
          <div class="column is-one-fifth">
            {{ (paymentForm.get('participation.total_participation').value || '-') + '%' }}
          </div>
          <div class="column is-one-fifth">
            {{
              (paymentForm.get('participation.total_price').value | currency: currency || 'BRL') ||
                '-'
            }}
          </div>
        </div>
      </div>
      <div class="results-section row">
        <div class="results-title row">
          <div class="column is-one-fifth">
            {{
              translationContext + '.section_3.head_value'
                | translate
                  : { currency: currency_translator[currency] || currency_translator['BRL'] }
            }}
          </div>
        </div>
        <div class="results-data row">
          <div class="column is-one-fifth">
            {{
              (paymentForm.get('participation.price_by_head').value
                | currency: currency || 'BRL') || '-'
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="form-section column" [formGroup]="paymentForm">
      <div class="section-title full-width row">
        <span class="column is-one-quarter">{{
          translationContext + '.section_4.discounts_increses' | translate
        }}</span>
      </div>
      <div class="section-data row">
        <div class="column is-one-fifth index-name-data">
          {{ translationContext + '.section_4.increses' | translate }}
        </div>
        <div class="column is-one-fifth input-container">
          <ag-number-field
            class="form-field"
            [group]="paymentForm.controls['additions']"
            inputWidth="100%"
            controlName="value"
            [disabled]="ruleSelected === null ? true : fieldsBlocked"
            [label]="translationContext + '.section_4.increses' | translate"
          ></ag-number-field>
        </div>
        <div class="column is-three-fifths input-container">
          <ag-text-field
            class="form-field"
            [label]="translationContext + '.section_4.discription' | translate"
            [formGroup]="paymentForm.controls['additions']"
            controlName="description"
            [disabled]="ruleSelected === null ? true : fieldsBlocked"
            [required]="additions !== 0"
          ></ag-text-field>
        </div>
      </div>
      <div class="section-data row">
        <div class="column is-one-fifth index-name-data">
          {{ translationContext + '.section_4.discounts' | translate }}
        </div>
        <div class="column is-one-fifth input-container">
          <ag-number-field
            class="form-field full"
            [group]="paymentForm.controls['discounts']"
            inputWidth="100%"
            controlName="value"
            [label]="translationContext + '.section_4.discounts' | translate"
            [disabled]="ruleSelected === null ? true : fieldsBlocked"
          ></ag-number-field>
        </div>
        <div class="column is-three-fifths input-container">
          <ag-text-field
            class="form-field"
            [label]="translationContext + '.section_4.discription' | translate"
            [formGroup]="paymentForm.controls['discounts']"
            controlName="description"
            [disabled]="ruleSelected === null ? true : fieldsBlocked"
            [required]="discounts !== 0"
          ></ag-text-field>
        </div>
      </div>
      <div class="results-section row">
        <div class="row results-title is-one-quarter">
          <span class="total-value column"
            >{{
              translationContext + '.section_4.price_by_total_weight_gain'
                | translate
                  : { currency: currency_translator[currency] || currency_translator['BRL'] }
            }}
            <span class="result-total-producer">{{
              ' ' +
                ((paymentForm.get('participation.price_by_total_weight_gain').value
                  | currency: currency || 'BRL') || ' - ')
            }}</span>
          </span>
        </div>
      </div>
      <div class="results-section row">
        <div class="row results-title is-one-quarter">
          <span class="total-value column"
            >{{
              translationContext + '.section_4.total_productor_participation'
                | translate
                  : { currency: currency_translator[currency] || currency_translator['BRL'] }
            }}
            <span class="result-total-producer">{{
              ' ' +
                ((paymentForm.get('participation.total_participation_by_producer').value
                  | currency: currency || 'BRL') || ' - ')
            }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="button-container form-section row">
      <div class="button-box download-button" *ngIf="isEdit && fieldsBlocked">
        <button (click)="downloadRecordDocumentPdf()">
          <span class="d-flex icon icon-pdf button-icon"></span
          ><span class="button-text">{{ translationContext + '.pdf_download' | translate }}</span>
        </button>
      </div>
      <div class="button-box">
        <button class="btn btn-primary" (click)="saveForm()" [disabled]="ValidateErrorForm()">
          {{ translationContext + (documentId ? '.save_changes' : '.save') | translate }}
        </button>
      </div>
    </div>
  </div>
</section>
