import { Technician, TechnicianForm } from '@agriness/corp-app/services';
import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'technician-editor',
  templateUrl: './technician-editor.component.html',
  styleUrls: ['./technician-editor.component.scss'],
})
export class TechnicianEditorComponent implements OnInit {
  @Input() visible = false;
  @Input() technicianData: Technician;
  @Input() modalStatus = ModalStatusEnum.DEFAULT;

  @Output() closed = new EventEmitter<void>();
  @Output() createTechnician = new EventEmitter<void>();
  @Output() updateTechnician = new EventEmitter<void>();

  errorMessages = {
    name: '',
  };

  translationContext = 'agriness.settings.technicians';

  technicianForm = new FormGroup({
    name: new FormControl('', Validators.required.bind(this)),
  });

  constructor(@Inject(TRANSLATE_INSTANT) private t: TranslateInstant) {}

  ngOnInit(): void {
    this.updatedFormTechnician();
    this.listenValueChanges();
  }

  listenValueChanges(): void {
    for (const control in this.technicianForm.controls) {
      this.technicianForm.controls[control].valueChanges.subscribe(() => {
        const { errors } = this.technicianForm.get(control);
        this.errorMessages[control] = errors ? this.t('agriness.settings.validation_required') : '';
      });
    }
  }

  onSubmit(): void {
    if (!this.technicianData) {
      this.createTechnician.emit();
    } else {
      this.updateTechnician.emit();
    }
  }

  onClosed(): void {
    this.closed.emit();
  }

  updatedFormTechnician(): void {
    if (this.technicianData) {
      const { name } = this.technicianData;

      this.technicianForm.patchValue({ name });
    }
  }

  getTechnicianForm(): TechnicianForm {
    return this.technicianForm.getRawValue() as TechnicianForm;
  }
}
