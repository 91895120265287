import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BarChart } from '../card.model';

@Component({
  selector: 'ag-card-bar-chart',
  templateUrl: './ag-card-bar-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgCardBarChartComponent {
  @Input() items: BarChart;
}
