import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, SelectComponent } from '../corp-filter-abstractions';
import { FixedLoaderService, SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-target-period-type-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.select.html',
  providers: [{ provide: FilterComponent, useExisting: CorpTargetPeriodTypeFilterComponent }],
})
export class CorpTargetPeriodTypeFilterComponent extends SelectComponent {
  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(translate, containerService, activeRoute);
    this.assignDefaults(
      'period_type',
      new SelectLoader(
        new FixedLoaderService(
          [
            ['', 'agriness.filter.period_type.all'],
            ['DAILY', 'agriness.filter.period_type.daily'],
            ['GENERAL', 'agriness.filter.period_type.general'],
            ['WEEKLY', 'agriness.filter.period_type.weekly'],
          ],
          translate,
        ),
      ),
    );
  }
}
