import { ReportFilterQuery } from '@agriness/corp-app/shared/model/report-filter.model';
import { Observable } from 'rxjs';

import { DocumentType } from '../models/document-type.model';
import { DownloadStatus } from '../models/download-status.model';

export interface IndexMapGeneratorDownloadArgs extends ReportFilterQuery {
  holding_id: string;
  system_type: string;
  stage: string;
  index_key: string;
  group_by: string;
  group_by_begin?: string;
  data_format: DocumentType;
}

export abstract class IndexMapGeneratorAbstractService {
  abstract download(params: IndexMapGeneratorDownloadArgs): Observable<DownloadStatus>;
}
