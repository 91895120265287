<section>
  <form class="full-width" [formGroup]="form">
    <div class="title-container">
      <p class="subtitle">
        {{ 'agriness.payment-rule-configuration.main.title' | translate }}
      </p>
    </div>
    <div id="first-form-group" class="form-container">
      <div class="form-group is-half">
        <ag-text-field
          class="form-field"
          data-testid="form-name"
          [formGroup]="form"
          controlName="name"
          [label]="'agriness.payment-rule-configuration.main.name' | translate"
          [errorMessage]="errorMessages.name"
        ></ag-text-field>
      </div>
      <div class="form-group is-half">
        <label class="control-label config-input">
          {{ 'agriness.payment-rule-configuration.main.stage' | translate }}
        </label>
        <ag-select
          class="select-age form-field"
          [group]="form"
          controlName="stage"
          [items$]="stageSelectData"
          dataKey="key"
          (selectValueFilter)="fillKPIS($event)"
        ></ag-select>
      </div>
    </div>
    <div class="form-container" id="kpi-form-group">
      <div class="form-group is-half">
        <label class="control-label config-input">
          {{ 'agriness.payment-rule-configuration.main.select' | translate }}
        </label>
        <ag-multiselect
          class="form-field"
          [group]="form"
          controlName="indexes"
          [items$]="kpiMultiselectData.options"
          (searchChange)="kpiMultiselectData.filter($event)"
          (scrollToEnd)="kpiMultiselectData.paginate()"
          [loading]="kpiMultiselectData.loading"
          dataKey="name"
          [disabled]="true"
          [ngClass]="{ disabled: form.controls.stage.value === null }"
          [canAddItem]="true"
          [itemsSelect]="SelectedIndexes"
        >
        </ag-multiselect>
      </div>
      <div class="form-group is-half">
        <label class="control-label config-input">
          {{ 'agriness.payment-rule-configuration.main.total_weight' | translate }}
        </label>
        <ag-select
          class="select-age form-field"
          [group]="form"
          controlName="type_weight"
          [items$]="stageWeightData"
          dataKey="key"
        ></ag-select>
      </div>
    </div>
  </form>
</section>
