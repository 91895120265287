<section class="section is-full">
  <div class="page-title d-flex align-items-center mb-3">
    <corp-back-button></corp-back-button>
    {{ pageTitle | translate }}
  </div>

  <corp-report-filters
    #reportFilter
    [availableFilters]="filterIds"
    [periodDefaultValue]="periodDefaultValue"
    [hasMaxDate]="false"
    (filter)="filter()"
    (ready)="filter()"
  >
  </corp-report-filters>

  <ag-feedback [type]="feedbackType"></ag-feedback>

  <div [hidden]="feedbackType != null">
    <schedule-monitor-cards
      #cardsReport
      [sectionName]="sectionPerformanceTitle"
      [report]="report"
      data-testid="technician-kpi"
    ></schedule-monitor-cards>
  </div>

  <div [hidden]="feedbackType != null" class="row">
    <div class="col">
      <corp-presence-calendar #calendarReport> </corp-presence-calendar>
    </div>
  </div>
</section>
