import { StageEnum, TypeProductionEnum } from '@agriness/services';
import { Observable } from 'rxjs';

import { AnimalGroupMonitorPerformance } from '../models/animal-group-performance.model';
import { FilterMonitor } from '../models/monitor.model';
import { PreferenceId } from '../models/user.model';

export interface GetScheduleMonitorPerformanceArgs {
  holdingId: string;
  stage?: StageEnum;
  system_type: TypeProductionEnum;
  report: PreferenceId;
  filter: FilterMonitor;
}

export abstract class ScheduleMonitorAbstractService {
  abstract getMonitorPerformance(
    params: GetScheduleMonitorPerformanceArgs,
  ): Observable<AnimalGroupMonitorPerformance[]>;
}
