import { Search, TypeProductionService, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { Technician, TechnicianForm, TechnicianFilter } from '../models/technician.model';
import { TechnicianAbstractService } from './technician-abstract.service';

@Injectable()
export class TechnicianService extends TechnicianAbstractService {
  private pathBase = 'holdings/{holding_id}/technicians/';
  private pathTechnicianId = 'holdings/{holding_id}/technicians/{technician_id}/';
  private defaultPageSize = 10;

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {
    super();
  }

  /*
    queryCorpOnly = true
      Search the internal CORP database
    queryCorpOnly = false
      Search the MyAccount database
  */
  list({
    page = 1,
    page_size = this.defaultPageSize,
    stage,
    animalGroupStatus,
    queryCorpOnly = false,
    ...filters
  }: TechnicianFilter): Observable<Search<Technician>> {
    const url = this.getUrl();
    const params = {
      ...filters,
      page,
      stage,
      page_size,
      animal_group_status: animalGroupStatus,
      system_type: this.typeProductionService.getId(),
      query_corp_only: queryCorpOnly,
    };
    return this.apiCorpService.get<Search<Technician>>(url, params);
  }

  save(form: TechnicianForm): Observable<Technician> {
    const url = this.getUrl();
    const params = {
      ...form,
      system_type: this.typeProductionService.getId(),
    };

    return this.apiCorpService.post<Technician>(url, params);
  }

  update(form: TechnicianForm, technicianId: string): Observable<Technician> {
    const url = this.getUrl(technicianId);
    const params = {
      ...form,
      system_type: this.typeProductionService.getId(),
    };

    return this.apiCorpService.put<Technician>(url, params);
  }

  delete(technician: Technician): Observable<void> {
    const url = this.getUrl(technician.id);
    return this.apiCorpService.delete(url);
  }

  private getUrl(technicianId?: string): string {
    return this.apiCorpService.translateUrl(technicianId ? this.pathTechnicianId : this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
      technician_id: technicianId,
    });
  }
}
