<ag-feedback class="pt-5" [type]="typeFeedback"> </ag-feedback>

<ng-container *ngIf="!typeFeedback">
  <div class="page-title pb-3 pt-5">
    {{ 'agriness.sanity.vaccine_medications.title' | translate }}
  </div>

  <div class="card shadow-sm">
    <div class="card-body">
      <div class="card-title ml-2 d-flex align-items-center">
        {{ 'agriness.sanity.vaccine_medications.table.title' | translate }}

        <button (click)="tableService.exportCSV(pTable)" class="btn btn-ghost has-round-hover ml-2">
          <span class="icon is-16 icon-csv"></span>
        </button>
      </div>

      <ag-table
        #dt
        [exportFilename]="downloadFilename"
        [frozenColumns]="frozenTableColumns"
        [columns]="scrollableTableColumns"
        [data]="tableData"
        [rows]="rows"
        [rowsPerPageOptions]="rowsPerPageOptions"
        [totalRecords]="totalRecords"
        [scrollable]="true"
        (sort)="onSort($event)"
      >
        <ng-template pTemplate="bodycell" let-col="column" let-data="data" let-rowData="rowData">
          <td [ngSwitch]="col.field" [agTooltip]="isFrozenColumn(col) ? data.value : null">
            <span
              *ngSwitchCase="'supply_type'"
              class="badges badge"
              [ngClass]="getBadgeClass(data.value)"
            >
              {{ translateKey + data.value | translate }}
            </span>

            <span *ngSwitchDefault>
              <ag-table-cell-content
                [data]="data"
                [column]="col"
                [dateFormat]="dateFormat"
              ></ag-table-cell-content>
            </span>
          </td>
        </ng-template>
      </ag-table>
    </div>
  </div>
</ng-container>
