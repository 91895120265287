<div [formGroup]="group">
  <p-calendar
    #calendar
    class="form-control-wrapper"
    inputStyleClass="form-control"
    styleClass="w-100"
    [formControlName]="controlName"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [locale]="calendarLocale"
    [inputId]="inputId"
    [dateFormat]="calendarDateFormat"
    [yearRange]="yearRange"
    [selectionMode]="selectionMode"
    [numberOfMonths]="numberOfMonths"
    [defaultDate]="defaultDate"
    [name]="inputId"
    [showOnFocus]="false"
    (onBlur)="onBlur()"
    (onClose)="onClose()"
  ></p-calendar>
  <label *ngIf="label" class="control-label">
    {{ label | translate }}
  </label>
  <span
    *ngIf="label"
    role="button"
    (click)="toggleCalendar()"
    class="control-icon icon icon-calendar-gregorian"
  ></span>
  <div class="input-feedback" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
</div>
