import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';

@Injectable()
export class AgrinessTranslateParserService extends TranslateDefaultParser {
  getValue(target: any, key: string): any {
    key = key.toLowerCase();
    return super.getValue(target, key);
  }
}
