import { Component } from '@angular/core';
import { environment } from '@env/environment';

import { filterIdsAnimalGroupListByProduction } from '../../shared/filter.model';

@Component({
  templateUrl: './record-list.component.html',
})
export class RecordListComponent {
  filterIds = filterIdsAnimalGroupListByProduction[environment.typeProduction];
}
