<ag-popover
  #popover
  *ngIf="simulationTypes.length"
  autoClose="outside"
  styleClass="btn-ghost has-round-hover"
  placement="right-top"
  iconClass="icon-track-setting has-text-grey-4"
  [template]="popoverTemplate"
></ag-popover>

<ng-template #popoverTemplate>
  <div class="popover-header py-2C">
    <span class="popover-header-title d-flex justify-content-between align-items-center ml-1">
      <span class="d-flex">
        {{ getTitle() }}
      </span>
      <button (click)="onClose()" class="d-flex btn btn-ghost has-round-hover">
        <span class="icon icon-close has-text-grey-4"></span>
      </button>
    </span>
  </div>

  <form [formGroup]="form" class="simulation-popover columns mt-3 mr-0 mb-4 ml-1">
    <div
      *ngFor="let simulationType of simulationTypes; first as isFirst; last as isLast"
      class="pr-4 pt-0 column"
      [ngClass]="{ 'border-right': !isLast, 'pl-4': !isFirst }"
    >
      <div class="flex-column pb-2">
        <p class="has-text-grey-5 text-small font-weight-bold">
          {{ translateKey + simulationType | translate }}
        </p>
      </div>

      <div class="simulation-container form-group has-feedback">
        <span class="simulation-label text is-xsmall has-text-grey-4">
          {{ translateKey + 'value' | translate }}
        </span>
        <ag-number-field
          inputWidth="90px"
          [group]="form"
          [controlName]="simulationType"
          [attr.data-testid]="simulationType"
          class="simulation-input"
        ></ag-number-field>

        <span class="simulation-label text is-xsmall has-text-grey-4">
          {{ translateKey + 'date' | translate }}
        </span>
        <ag-calendar
          [group]="form"
          controlName="date_{{ simulationType }}"
          [dateFormat]="dateFormat"
          class="simulation-input"
          [attr.data-testid]="'date_' + simulationType"
        ></ag-calendar>
      </div>
    </div>
  </form>

  <div class="popover-footer d-flex justify-content-end">
    <button class="btn btn-ghost-light is-small" (click)="onReset()">
      {{ translateKey + 'cancel' | translate }}
    </button>

    <button
      [disabled]="!form.valid"
      type="button"
      class="btn btn-primary is-small ml-3"
      (click)="onVisualize()"
    >
      {{ translateKey + 'visualize' | translate }}
    </button>
  </div>
</ng-template>
