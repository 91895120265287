import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ag-feedback-leave-page',
  templateUrl: './feedback-leave-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackLeavePageComponent {
  @Input() translationContext = 'agriness.feedback_leave_page';
  @Input() visible = false;
  @Output() cancel = new EventEmitter<boolean>();
  @Output() confirm = new EventEmitter<boolean>();

  onCancel(): void {
    this.cancel.emit();
  }

  onConfirm(): void {
    this.confirm.emit();
  }
}
