import { AnimalGroups } from '@agriness/corp-app/services';
import { Service } from '@agriness/corp-app/shared/component/corp-record-list-farm-link/corp-record-list-farm-link.model';
import { UserStorageService } from '@agriness/services';
import { RedirectItemFeedback } from '@agriness/ui';
import { ChangeDetectionStrategy, Component, Input, ChangeDetectorRef } from '@angular/core';

import { CorpFarmLinkService } from '../../services/corp-farm-link.service';
import { LoaderUserPreference } from '../loader-user-preference';

@Component({
  selector: 'corp-record-list-farm-link',
  templateUrl: './corp-record-list-farm-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpRecordListFarmLinkComponent extends LoaderUserPreference {
  @Input() animalGroup: AnimalGroups;
  @Input() service: Service;
  feedback: RedirectItemFeedback;

  constructor(
    protected userStorageService: UserStorageService,
    protected changeDetectionRef: ChangeDetectorRef,
    protected corpFarmLinkService: CorpFarmLinkService,
  ) {
    super(userStorageService);
  }

  redirectUser(): void {
    this.corpFarmLinkService.redirectUser(this.animalGroup.farm_id, this.service);
  }
}
