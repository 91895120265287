import { Injectable } from '@angular/core';
import { head, isEmpty } from 'lodash';
import { Observable } from 'rxjs';

import { SearchHoldings } from './../../../models/holding.model';
import { User, UserAgriness, UserPreferences } from '../../../models/user.model';
import { AgrinessTranslateService } from '../../../translate/translate.service';
import { HoldingAbstractService } from '../../abstract/holding-abstract.service';
import { UserAbstractService } from '../../abstract/user-abstract.service';
import { ApiMyAccountService } from '../../common/api-myaccount.service';
import { UserStorageService } from './user-storage.service';

@Injectable()
export class UserService extends UserAbstractService {
  private pathUserProfile = 'user-profile/';
  user: User;
  holdings: SearchHoldings;

  constructor(
    private apiMyAccountService: ApiMyAccountService,
    private userStorageService: UserStorageService,
    private holdingService: HoldingAbstractService,
    private translateService: AgrinessTranslateService,
  ) {
    super();
  }

  async initUser() {
    const [user, holdings] = await Promise.all([
      this.getUserProfile().toPromise(),
      this.holdingService.searchHoldings().toPromise(),
    ]);

    this.user = user;
    this.holdings = holdings;

    const currentHoldingId = this.userStorageService.getCurrentHolding()?.id;
    let currentHolding = holdings.results.find(holding => holding.id == currentHoldingId);
    if (!currentHolding && !isEmpty(holdings?.results)) {
      currentHolding =
        holdings.results.find(holding => holding.is_super_holding) ?? head(holdings.results);
    }

    this.userStorageService.setUserAgriness(
      new UserAgriness(user, new UserPreferences(currentHolding), holdings?.results),
    );

    this.translateService.setLanguage(this.userStorageService.getLanguage());
    this.translateService.setDecimalSeparator(this.userStorageService.getDecimalSeparator());
  }

  setCurrentHolding(holdingId: string): void {
    const newHolding = this.holdings.results.find(holding => holding.id == holdingId);

    this.userStorageService.setUserAgriness(
      new UserAgriness(this.user, new UserPreferences(newHolding), this.holdings.results),
    );
  }

  private getUserProfile(): Observable<User> {
    return this.apiMyAccountService.get<User>(this.pathUserProfile);
  }
}
