import { Overlay, OverlayContainer } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { ChatWidgetService } from '../queue-manager/chat-widget.service';
import { AgBulkActionsComponent } from './ag-bulk-actions.component';
import { BulkActionsItem, SERVICE_BULK } from './ag-bulk-actions.model';

@Injectable({
  providedIn: 'root',
})
export class BulkActionsService {
  private item = new Subject<BulkActionsItem>();
  private positionRight = new BehaviorSubject<string>('0px');

  constructor(
    private overlay: Overlay,
    private overlayContainer: OverlayContainer,
    private injector: Injector,
    private chatWidgetService: ChatWidgetService,
  ) {
    this.createOverlay();
    this.chatWidgetService
      .watch(this.overlayContainer.getContainerElement())
      .subscribe(value => this.positionRight.next(`${value}px`));
  }

  private createOverlay(): void {
    const positionStrategy = this.overlay
      .position()
      .global()
      .bottom('0px')
      .right(this.positionRight.value);
    const overlayRef = this.overlay.create({ positionStrategy });
    const BulkPortal = new ComponentPortal(
      AgBulkActionsComponent,
      null,
      this.createInjector({
        item: this.item,
        positionRight: this.positionRight,
      }),
    );

    this.positionRight.subscribe(value => positionStrategy.right(value).apply());
    overlayRef.attach(BulkPortal);
  }

  private createInjector(data): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(SERVICE_BULK, data);
    return new PortalInjector(this.injector, injectorTokens);
  }

  add(data: BulkActionsItem): void {
    this.item.next(data);
  }
}
