import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ApiCorpService } from '../api-corp.service';
import { DownloadStatus } from '../models/download-status.model';
import { DocumentType } from './../models/document-type.model';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  constructor(private apiCorpService: ApiCorpService) {}

  download(
    url: string,
    type: DocumentType,
    queryParams?: Record<string, unknown>,
  ): Observable<DownloadStatus> {
    return this.apiCorpService
      .get<HttpResponse<Blob>>(
        url,
        {
          ...queryParams,
          responseType: 'blob',
        },
        null,
        true,
      )
      .pipe(
        switchMap((response: HttpResponse<Blob>) => {
          if (response.status === 200) {
            const filename = this.getFilenameFromResponse(response, type);
            saveAs(response.body, filename);
            return of(DownloadStatus.SUCCESS);
          } else if (response.status === 204) {
            return of(DownloadStatus.NO_CONTENT);
          } else {
            return throwError(response);
          }
        }),
      );
  }

  private getFilenameFromResponse(response: HttpResponse<Blob>, dataFormat: string): string {
    const contentDisposition = response.headers.get('Content-Disposition');
    const contentDispositionItems = contentDisposition.split(';').map(str => str.trim());
    for (const contentDispositionItem of contentDispositionItems) {
      if (contentDispositionItem.startsWith('filename=')) {
        return contentDispositionItem.split('=')[1];
      }
    }
    return `download.${dataFormat}`;
  }
}
