<div class="section is-full">
  <ng-container
    *ngIf="
      !typeFeedback || typeFeedback === FeedbackEnum.LOADING;
      then pageWithData;
      else pageWithoutData
    "
  >
  </ng-container>
</div>

<ng-template #pageWithoutData>
  <ag-feedback
    [type]="typeFeedback"
    *ngIf="typeFeedback === FeedbackEnum.NOT_RESULT_SEARCH"
  ></ag-feedback>
  <div
    class="columns is-mobile is-gapless flex-column flex-grow-1 align-items-center justify-content-center text-center"
    *ngIf="typeFeedback !== FeedbackEnum.NOT_RESULT_SEARCH"
  >
    <div class="column is-full">
      <ag-feedback-icon [type]="FeedbackEnum.SEARCH"></ag-feedback-icon>
    </div>
    <div class="column is-full mb-4">
      <ng-container [ngTemplateOutlet]="title"></ng-container>
    </div>
    <div
      class="column is-three-quarters-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd mb-3"
    >
      <ng-container [ngTemplateOutlet]="search"></ng-container>
    </div>
    <div class="column is-full text-small has-text-grey-4">
      {{ emptyPageInstructions }}
    </div>
  </div>
</ng-template>

<ng-template #pageWithData>
  <ag-feedback [type]="typeFeedback"></ag-feedback>

  <header *ngIf="!typeFeedback" class="columns is-mobile">
    <div class="column">
      <ng-container [ngTemplateOutlet]="title"></ng-container>
    </div>
    <div class="column is-one-quarter">
      <ng-container [ngTemplateOutlet]="search"></ng-container>
    </div>
  </header>

  <div class="columns" *ngIf="typeFeedback == FeedbackEnum.NOT_RESULT">
    <ag-feedback [type]="typeFeedback"></ag-feedback>
  </div>
  <div id="animal-group-info" class="columns" *ngIf="animalGroupReports">
    <div class="column is-narrow border-right">
      <!-- Card Lote -->
      <div class="card-lote">
        <div class="card-icon">
          <span class="main icon" [ngClass]="headerIconClass"></span>
        </div>

        <div class="card-content">
          <div class="card-label">
            {{ animalGroupReports.name }}
          </div>

          <div class="badges mb-1" *ngIf="animalGroupReports.stage">
            <span class="badge badge--degrade-blue">{{
              'agriness.stage.' + animalGroupReports.stage.name | translate
            }}</span>
            <span
              class="badge"
              [ngClass]="{
                'badge--degrade-green': isAnimalGroupOpened(),
                'badge--degrade-red': isAnimalGroupClosed(),
                'badge--degrade-purple': isAnimalGroupPlanned()
              }"
            >
              {{
                (isAnimalGroupOpened()
                  ? 'agriness.opened'
                  : isAnimalGroupClosed()
                  ? 'agriness.closed'
                  : 'agriness.planned'
                ) | translate
              }}
            </span>
            <div>
              <p
                *ngFor="let key of objectKeys(mainDetails)"
                class="text is-regular mt-1"
                [attr.data-testid]="key"
              >
                <ng-container>
                  {{ 'agriness.animal_group_record.' + key | translate }}:
                </ng-container>
                <strong class="has-text-grey-4">{{ mainDetails[key] }}</strong>
              </p>
            </div>
          </div>
        </div>
        <!-- /.card-object -->
      </div>
      <!-- /.card-lote -->
    </div>
    <div *ngFor="let detail of details" class="column is-narrow text-small">
      <p *ngFor="let key of objectKeys(detail)" [attr.data-testid]="key">
        <ng-container *ngIf="animalGroupReports.is_open && key === 'outgoing'; else elseDetail">
          {{ 'agriness.animal_group_record.prediction_' + key | translate }}:
        </ng-container>
        <ng-template #elseDetail>
          {{ 'agriness.animal_group_record.' + key | translate }}:
        </ng-template>
        <strong class="has-text-grey-4">{{ detail[key] }}</strong>
      </p>
    </div>
    <ng-container *ngIf="animalGroupReports.stage.name === StageEnum.LAYERS_PRODUCTION">
      <div id="animal-group-diet" class="column is-narrow text-small border-left">
        <span style="font-weight: bold;">Diet Selection</span>
        <p data-testid="current-diet">
          Current diet:
          <strong class="has-text-grey-4">{{ diet?.current_diet }}</strong>
        </p>
        <p data-testid="proposed-diet">
          Proposed diet:
          <strong class="has-text-grey-4">{{ diet?.proposed_diet }}</strong>
        </p>
        <div class="mt-1">
          <button
            data-testid="add-diet"
            class="btn btn-primary is-small"
            style="height: 32px;"
            (click)="dialog.displayDialog(DisplayMode.ADDING)"
          >
            <span class="icon icon-feed is-24 feed"></span>
            {{ 'agriness.diet_selection.buttons.add_diet' | translate }}
          </button>
          <button
            data-testid="edit-diet"
            class="btn btn-ghost-light is-small"
            style="height: 32px;"
            (click)="dialog.displayDialog(DisplayMode.EDITING)"
          >
            <span class="icon icon-edit is-24 edit"></span>
            {{ 'agriness.diet_selection.buttons.edit_diet' | translate }}
          </button>
        </div>
      </div>
      <diet-selection
        #dialog
        data-testid="diet-selection"
        [stage]="animalGroupReports.stage.name"
        [animalGroup]="animalGroupReports"
        (dietChanged)="setNewDiet($event)"
      ></diet-selection>
    </ng-container>
    <corp-animal-group-health-report
      *ngIf="hasFeatureHealthReport"
      [status]="statusHealthReport"
      [documentId]="healthReportId"
      [publishedUrl]="healthReportPublishedUrl"
      [animalGroupName]="animalGroupReports.name"
    ></corp-animal-group-health-report>
    <corp-animal-group-payment-report
      *ngIf="canEditPayment() && isPaymentSystemTypeEnable()"
      [status]="statusPaymentReport"
      [paymentCheck]="paymentCheckData"
      [documentId]="paymentReportId"
      [animalGroupName]="animalGroupReports.name"
      [holdingId]="holdingId"
      [stage]="animalGroupReports.stage.name"
      [isOpen]="isOpen"
      [measurmentUnits]="measurmentUnits"
    ></corp-animal-group-payment-report>
  </div>
  <!-- /.row -->

  <ng-container *ngIf="animalGroupReports">
    <div class="column is-full" *ngIf="isAnimalGroupPlanned(); else elsePlanned">
      <ag-feedback-icon [type]="FeedbackEnum.INFO"> </ag-feedback-icon>
      <h6 class="title font-weight-bold text-center text-wrap">
        {{ 'agriness.animal_group_record.feedback_planned' | translate }}
      </h6>
    </div>
    <ng-template #elsePlanned>
      <div class="tabs">
        <ul class="nav nav-tabs mb-4" id="nav-tab">
          <li class="nav-item" *ngFor="let tab of tabs">
            <a
              class="nav-link"
              routerLinkActive="active"
              [id]="tab.router"
              [routerLink]="tab.router"
              >{{ tab.translateKey | translate }}</a
            >
          </li>
        </ul>
        <div class="tab-content" id="nav-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </ng-template>
    <!-- /.tabs -->
  </ng-container>
</ng-template>

<ng-template #title>
  <div class="page-title" id="page-title">
    <span>{{ 'agriness.record_animal_group' | translate }}</span>

    <guavi-ghost-button
      *ngIf="(!typeFeedback || typeFeedback === FeedbackEnum.LOADING) && canEditAnimalGroup()"
      icon="edit"
      variant="grey"
      size="medium"
      data-testid="button-edit"
      (click)="showModalEditor()"
    ></guavi-ghost-button>
    <guavi-ghost-button
      *ngIf="
        (!typeFeedback || typeFeedback === FeedbackEnum.LOADING) &&
        canEditPayment() &&
        isPaymentSystemTypeEnable()
      "
      icon="pdf"
      variant="grey"
      size="medium"
      data-testid="button-pdf"
      (click)="downloadRecordDocumentPdf()"
    ></guavi-ghost-button>
  </div>

  <animal-group-editor
    #editor
    *ngIf="editorModalVisible && animalGroupReports"
    [stage]="animalGroupReports.stage.name"
    [visible]="editorModalVisible"
    [animalGroupData]="animalGroupReports"
    [modalStatus]="editorModalStatus"
    (closed)="onEditorClosed()"
    (updateAnimalGroup)="updateAnimalGroup()"
  ></animal-group-editor>
</ng-template>

<ng-template #search>
  <div class="form-group">
    <label class="control-label">{{ 'agriness.record.label_id' | translate }}</label>
    <ag-autocomplete
      inputId="search-lote"
      [model]="animalGroupSearchSelect"
      [suggestions]="filteredAnimalGroup"
      (completeMethod)="searchAnimalGroup($event)"
      (select)="selectAnimalGroupSearch($event)"
      (onBlur)="onBlurSearchAnimalGroup()"
      field="display_name"
      [templateItem]="templateAutoComplete"
    >
    </ag-autocomplete>
    <ng-template #templateAutoComplete let-item>
      <div class="d-flex">
        <span [ngClass]="getIconClass(item)" class="mr-1 icon is-20 has-text-grey-3"></span>
        <span class="has-text-grey-5 mr-1">{{ item.display_name }}</span>
        <span class="has-text-grey-3">
          - {{ (item.is_open ? 'agriness.opened' : 'agriness.closed') | translate }}
        </span>
      </div>
    </ng-template>
  </div>
</ng-template>
