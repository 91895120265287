import { PatchForm } from '@agriness/corp-app/settings/payment-rule-section/payment-rule/rule-editor/rule-editor-form.model';
import { TargetForm } from '@agriness/corp-app/settings/target-section/targets/targets.model';
import { Search } from '@agriness/services';
import { Observable } from 'rxjs';

import {
  Target,
  TargetForm as TargetFormV2,
  TargetFilter,
  TargetIndex,
  TargetV2,
  Targets,
} from '../models/target.model';

export abstract class TargetAbstractService {
  abstract list(): Observable<Targets>;
  abstract listV2(targetFilter: TargetFilter): Observable<Search<TargetV2>>;
  abstract save(targetForm: TargetFormV2): Observable<Target>;
  abstract update(targetId: string, targetData: TargetForm): Observable<Target[]>;
  abstract delete(target: Target): Observable<void>;
  abstract patch(targetForm: PatchForm, targetId: string): Observable<TargetV2>;
  abstract listKPI(): Observable<Search<TargetIndex>>;
}
