import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../common/shared.module';
import { DirectivesModule } from '../../directive/directives.module';
import { PipesModule } from '../../pipe/pipes.module';
import { AgFormatModule } from '../format/ag-format.module';
import { AgLinkModule } from '../link/ag-link.module';
import { AgTargetModule } from './../target/ag-target.module';
import { AgTargetIndicatorModule } from '../target/target-indicator/ag-target-indicator.module';
import { AgCardBarChartGraphComponent } from './bar-chart/ag-card-bar-chart-graph.component';
import { AgCardBarChartLinksComponent } from './bar-chart/ag-card-bar-chart-links.component';
import { AgCardBarChartComponent } from './bar-chart/ag-card-bar-chart.component';
import { AgCardTargetComponent } from './target/ag-card-target.component';
import { AgCardTooltipComponent } from './card-tooltip/ag-card-tooltip.component';
import { AgCardPerformancesComponent } from './complex/ag-card-performances.component';
import { AgCardComponent } from './simple/ag-card.component';

import { AgrinessIconModule } from './../../guavi/icon/agriness-icon.module';
import { AgrinessCardModule } from '../../guavi/card/agriness-card.module';
import { AgCardBalanceComponent } from './planning/ag-card-balance/ag-card-balance.component';

@NgModule({
  imports: [
    SharedModule,
    PipesModule,
    AgFormatModule,
    AgrinessCardModule,
    AgrinessIconModule,
    AgTargetModule,
    DirectivesModule,
    AgTargetIndicatorModule,
    AgLinkModule,
    RouterModule,
  ],
  declarations: [
    AgCardPerformancesComponent,
    AgCardComponent,
    AgCardTargetComponent,
    AgCardTooltipComponent,
    AgCardBarChartComponent,
    AgCardBarChartGraphComponent,
    AgCardBarChartLinksComponent,
    AgCardBalanceComponent,
  ],
  exports: [
    AgCardPerformancesComponent,
    AgCardComponent,
    AgCardTargetComponent,
    AgCardTooltipComponent,
    AgCardBalanceComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgCardModule {}
