import { AuthGuard } from '@agriness/corp-app/router-guard/auth.guard';
import { FeatureGuard } from '@agriness/corp-app/router-guard/feature.guard';
import { TypeProductionEnum } from '@agriness/services';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule } from '@angular/router';

const routerConfig: ExtraOptions = { paramsInheritanceStrategy: 'always' };

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          pathMatch: 'full',
          canLoad: [AuthGuard],
          children: [],
        },
        {
          path: TypeProductionEnum.DAIRY,
          canLoad: [AuthGuard],
          canActivateChild: [FeatureGuard],
          loadChildren: () => import('./corp-lib-loader').then(m => m.LoadDairyCorpLibLoader),
        },
        {
          path: TypeProductionEnum.POULTRY,
          canLoad: [AuthGuard],
          canActivateChild: [FeatureGuard],
          loadChildren: () => import('./corp-lib-loader').then(m => m.LoadPoultryCorpLibLoader),
        },
        {
          path: TypeProductionEnum.SWINES,
          canLoad: [AuthGuard],
          canActivateChild: [FeatureGuard],
          loadChildren: () => import('./corp-lib-loader').then(m => m.LoadSwinesCorpLibLoader),
        },
        {
          path: TypeProductionEnum.LAYERS,
          loadChildren: () => import('./corp-lib-loader').then(m => m.LoadLayersCorpLibLoader),
        },
      ],
      routerConfig,
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
