import { Target } from '@agriness/corp-app/services';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'target-list',
  templateUrl: './target-list.component.html',
})
export class TargetListComponent {
  @Input() targets: Target[] = [];

  @Output() targetSelect = new EventEmitter<Target>();

  handleSubmitTarget(target: Target): void {
    this.targetSelect.emit(target);
  }
}
