import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { GuaviButtonComponent } from './guavi-button.component';
import { AgrinessIconModule } from '../icon/agriness-icon.module';

@NgModule({
  declarations: [GuaviButtonComponent],
  imports: [SharedModule, AgrinessIconModule],
  exports: [GuaviButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GuaviButtonModule {}
