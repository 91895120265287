import { Search, TypeProductionService, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { Producer, ProducerFilter, ProducerForm } from '../models/producer.model';
import { ProducerAbstractService } from './producer-abstract.service';

@Injectable()
export class ProducerService extends ProducerAbstractService {
  private pathBase = 'holdings/{holding_id}/producers/';
  private pathProducerId = 'holdings/{holding_id}/producers/{producer_id}/';
  private defaultPageSize = 10;

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {
    super();
  }

  list({
    page = 1,
    page_size = this.defaultPageSize,
    stage,
    animalGroupStatus,
    queryCorpOnly = false,
    ...filters
  }: ProducerFilter): Observable<Search<Producer>> {
    const url = this.getUrl();
    const params = {
      ...filters,
      page,
      page_size,
      system_type: this.typeProductionService.get(),
      stage,
      animal_group_status: animalGroupStatus,
      query_corp_only: queryCorpOnly,
    };
    return this.apiCorpService.get<Search<Producer>>(url, params);
  }

  save(form: ProducerForm): Observable<Producer> {
    const url = this.getUrl();
    const params = {
      ...form,
      system_type: this.typeProductionService.getId(),
    };

    return this.apiCorpService.post<Producer>(url, params);
  }

  update(form: ProducerForm, producerId: string): Observable<Producer> {
    const url = this.getUrl(producerId);
    const params = {
      ...form,
      system_type: this.typeProductionService.getId(),
    };

    return this.apiCorpService.put<Producer>(url, params);
  }

  delete(producer: Producer): Observable<void> {
    const url = this.getUrl(producer.id);
    return this.apiCorpService.delete(url);
  }

  private getUrl(producerId?: string): string {
    return this.apiCorpService.translateUrl(producerId ? this.pathProducerId : this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
      producer_id: producerId,
    });
  }
}
