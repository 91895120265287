import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';

import { SharedModule } from '../shared/shared.module';
import { ReproductiveIdentifyComponent } from './identify/reproductive-identify.component';
import { ReproductiveComponent } from './reproductive.component';

@NgModule({
  imports: [ReactiveFormsModule, DropdownModule, SharedModule],
  declarations: [ReproductiveComponent, ReproductiveIdentifyComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReproductiveModule {}
