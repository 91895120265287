import { AgrinessTranslateService } from '@agriness/services';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { ValueFormatPipe } from '../../../pipe/value-format/value-format.pipe';
import { CardModelPerformances, CardLink } from '../card.model';

@Component({
  selector: 'ag-card-performance',
  templateUrl: './ag-card-performances.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgCardPerformancesComponent implements OnChanges {
  @Input() data: CardModelPerformances;
  @Input() link: CardLink;

  hasNavigation = false;

  constructor(
    private translateService: AgrinessTranslateService,
    private valueFormat: ValueFormatPipe,
  ) {}

  ngOnChanges(): void {
    this.hasNavigation = Boolean(this.data.action && this.link);
  }

  getTargetText(targetValue) {
    const representationType = this.data.target_representation_type;
    if (!representationType) {
      return null;
    }
    return this.translateService.instant(
      `agriness.target_representation_type.${representationType}`,
      {
        min_recommended: this.formatValue(this.data.target_min_recommended),
        max_recommended: this.formatValue(this.data.target_max_recommended),
        target: this.formatValue(targetValue),
      },
    );
  }

  private formatValue(value): string {
    return this.valueFormat.transform(
      value,
      this.data.style || this.data.measurement_unit,
      this.data.decimal_places,
    );
  }
}
