import { TableColumn } from '@agriness/ui';

export type DeletionTableData = {
  field: string;
  value: string;
}[];

export const deletionTableColumns: TableColumn[] = [
  {
    header: 'agriness.settings.deletion.field',
    field: 'field',
  },
  {
    header: 'agriness.settings.deletion.value',
    field: 'value',
  },
];

export const commonDeletionTableFields: string[] = ['name'];
