import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';

import { AgFeedbackModule } from '../feedback/ag-feedback.module';
import { AgModalModule } from '../modal/ag-modal.module';
import { AgRedirectModalComponent } from './ag-redirect-modal.component';

@NgModule({
  declarations: [AgRedirectModalComponent],
  imports: [
    CommonModule,
    OverlayModule,
    AgFeedbackModule,
    TranslateModule,
    DialogModule,
    AgModalModule,
  ],
  entryComponents: [AgRedirectModalComponent],
})
export class AgRedirectModalModule {}
