import { RegionAbstractService } from '@agriness/corp-app/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { StageEnum } from '@agriness/services';
import { Component, Inject, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, MultiSelectComponent } from '../corp-filter-abstractions';
import { SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-region-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.multiselect.html',
  providers: [{ provide: FilterComponent, useExisting: CorpRegionFilterComponent }],
})
export class CorpRegionFilterComponent extends MultiSelectComponent implements OnInit {
  @Input() stage: StageEnum;
  @Input() farmFilter = false;

  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
    private regionService: RegionAbstractService,
  ) {
    super(translate, containerService, activeRoute);
  }
  ngOnInit(): void {
    this.assignDefaults('region', new SelectLoader(this.regionService, { stage: this.stage }));
    super.ngOnInit();
  }
}
