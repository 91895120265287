import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges } from '@angular/core';

import { FeedbackContent, FeedbackEnum, FeedbackPosition } from './ag-feedback.model';
import { AgFeedbackService } from './ag-feedback.service';

@Component({
  selector: 'ag-feedback',
  templateUrl: './ag-feedback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgFeedbackComponent implements OnChanges {
  @Input() type: FeedbackEnum;
  @Input() position: FeedbackPosition;

  feedBack: FeedbackContent;

  constructor(private feedbackService: AgFeedbackService) {}

  ngOnChanges(): void {
    this.feedBack = this.feedbackService.getFeedback(this.type);
  }

  @HostBinding('class') get classes() {
    if (this.type == null) {
      return '';
    }
    const position = this.position || FeedbackPosition.CENTER_EXPAND;
    return `feedback-wrapper-${position}`;
  }
}
