import {
  AuthAbstractService,
  Holding,
  Product,
  ProductAbstractService,
  TagManagerService,
  TypeProductionService,
  UserAgriness,
  UserStorageService,
  userHoldingRelationByName,
  UserHoldingRelationType,
  UserAbstractService,
  StageEnum,
} from '@agriness/services';
import {
  AgSideMenuModel,
  AppMenuModel,
  AppMenuService,
  HoldingMenuModel,
  HoldingMenuService,
  HoldingModel,
  UserMenuModel,
} from '@agriness/ui';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

import { appInfoByProduction, IdsByProductionType, SwineSubmenuOrder } from './corp-app.model';
import { AuthGuard } from './router-guard/auth.guard';
import { HelpAgrinessService } from './services/help-agriness/help-agriness.service';
import { FeatureToggleService } from './shared/services/feature-toggle.service';

@Component({
  selector: 'corp-app',
  templateUrl: './corp-app.component.html',
})
export class CorpAppComponent implements OnInit {
  appMyAccount: string;
  holdingMenu: HoldingMenuModel;
  iconTitle;
  productId;
  sideMenuItems: AgSideMenuModel[];
  userAgriness: UserAgriness;
  userMenu: UserMenuModel;
  helpShowButtonHeader: boolean;
  helpShowTooltip: boolean;
  helpUrl: string;

  defaultLoginPath: string;

  constructor(
    private appMenuService: AppMenuService,
    private authService: AuthAbstractService,
    private authGuard: AuthGuard,
    private holdingMenuService: HoldingMenuService,
    private productAbstractService: ProductAbstractService,
    private tagManager: TagManagerService,
    private typeProductionService: TypeProductionService,
    private userStorageService: UserStorageService,
    private userService: UserAbstractService,
    private featureToggleService: FeatureToggleService,
    private helpAgrinessService: HelpAgrinessService,
    private router: Router,
  ) {
    this.appMyAccount = environment.appMyAccount;

    this.setHelp();
  }

  filterItemsMenu(itemsMenu: AgSideMenuModel[]): AgSideMenuModel[] {
    let items = itemsMenu;

    items = items.filter(
      item =>
        !item.userHoldingRelationType ||
        item.userHoldingRelationType.includes(this.userMenu?.profileName),
    );

    if (!this.featureToggleService.hasFeature('TECHNICAL_SUPPORT')) {
      items = items.filter(i => i.id !== 'presence');
      items = items.map(i => {
        if (i.id === 'settings') {
          i = { ...i, subItem: i.subItem.filter(a => a.id !== 'presence_settings') };
        }
        return i;
      });
    }

    if (!this.featureToggleService.isEnabled('target_new')) {
      items = items.map(i => {
        if (i.id === 'settings') {
          i = { ...i, subItem: i.subItem.filter(a => a.id !== 'targetsnews') };
        }
        return i;
      });
    }

    if (!this.featureToggleService.hasFeature('PLANNING')) {
      items = items.filter(i => i.id !== 'planning');
    }

    return this.setSubItemsSideMenu(items);
  }

  ngOnInit(): void {
    const appInfo = appInfoByProduction[this.typeProductionService.get()];
    if (appInfo) {
      this.iconTitle = appInfo.iconTitle;
      this.productId = appInfo.productId;
    }
    this.setUser();
    this.setHoldings();
    this.setApp();
    this.setUserMenu(appInfo.itemsMenu);
    this.holdingMenuService
      .getHoldingSelectModel()
      .subscribe(newHoldingMenu => this.changeActiveHolding(newHoldingMenu));

    this.tagManager.init();
  }

  onLogout(): void {
    this.authService.logout();
  }

  private setSubItemsSideMenu(items: AgSideMenuModel[]): AgSideMenuModel[] {
    return items.map(item => {
      if (item.id === 'production') {
        const product_info = this.userAgriness.userPreferences.currentHolding.products.find(
          i => i.product_id === this.productId,
        );
        const subs: AgSideMenuModel[] = [];

        switch (product_info.product_id) {
          case IdsByProductionType.poultry:
            subs.push(item.subItem.find(i => StageEnum.GROW_OUT === i.id));
            break;
          case IdsByProductionType.dairy:
            subs.push(item.subItem.find(i => StageEnum.MILKING === i.id));
            break;
          case IdsByProductionType.swines:
            for (const type of product_info.production_type) {
              switch (type) {
                case 'UPD_EARLY_NURSERY':
                  subs.push(item.subItem.find(i => StageEnum.REPRODUCTIVE === i.id));
                  break;
                case 'UPL':
                  subs.push(item.subItem.find(i => StageEnum.REPRODUCTIVE === i.id));
                  subs.push(item.subItem.find(i => StageEnum.NURSERY === i.id));
                  break;
                case 'UCC':
                  subs.push(item.subItem.find(i => StageEnum.REPRODUCTIVE === i.id));
                  subs.push(item.subItem.find(i => StageEnum.NURSERY === i.id));
                  subs.push(item.subItem.find(i => StageEnum.FINISHING === i.id));
                  break;
                case 'UCC_WEAN_TO_FINISH':
                  subs.push(item.subItem.find(i => StageEnum.REPRODUCTIVE === i.id));
                  subs.push(item.subItem.find(i => StageEnum.WEAN_TO_FINISH === i.id));
                  break;
                case 'NURSERY_FINISH':
                  subs.push(item.subItem.find(i => StageEnum.NURSERY === i.id));
                  subs.push(item.subItem.find(i => StageEnum.FINISHING === i.id));
                  break;
                case 'ENDING':
                  subs.push(item.subItem.find(i => StageEnum.FINISHING === i.id));
                  break;
                default:
                  subs.push(item.subItem.find(i => StageEnum[type] === i.id));
                  break;
              }
            }
            break;
        }

        subs.push(item.subItem.find(i => i?.id === 'record'));

        item.subItem = subs.filter(function (ele, pos) {
          return subs.indexOf(ele) == pos;
        });

        item.subItem = item.subItem.sort(
          ({ id: a }, { id: b }) => SwineSubmenuOrder[a] - SwineSubmenuOrder[b],
        );

        if (!window.localStorage.getItem('firstBuild')) {
          window.localStorage.setItem('firstBuild', 'true');
          this.redirectNavigation(String(item.subItem[0].id));
        }
      }

      return item;
    });
  }

  private redirectNavigation(route: string) {
    void this.router.navigate(['/', this.typeProductionService.get(), route]);
  }

  private setHelp() {
    this.helpShowButtonHeader = this.featureToggleService.isEnabled('help.show-button-header');
    this.helpShowTooltip =
      this.featureToggleService.isEnabled('help.show-tooltip') &&
      !this.userStorageService.getHelpUserDismiss();

    this.helpUrl = this.helpAgrinessService.getUrl();
  }

  private changeActiveHolding(newHoldingMenu: HoldingMenuModel) {
    this.holdingMenu = newHoldingMenu;
    const newHoldingId = newHoldingMenu.activeHolding.id;
    this.userService.setCurrentHolding(newHoldingId);
    this.setUserMenu(appInfoByProduction[this.typeProductionService.get()].itemsMenu);
  }

  private setUser() {
    this.userAgriness = this.userStorageService.getUserAgriness();
  }

  private setHoldings() {
    this.holdingMenu = {
      activeHolding: this.parseHoldingServiceToHoldingModel(
        this.userAgriness.userPreferences.currentHolding,
      ),
      holdings: _.map(this.userAgriness.holdings, holding =>
        this.parseHoldingServiceToHoldingModel(holding),
      ),
    };
    this.holdingMenuService.applyHolding(this.holdingMenu);
  }

  private parseHoldingServiceToHoldingModel(holding: Holding): HoldingModel {
    return {
      name: holding.name,
      type: holding.type ? holding.type.name : '',
      id: holding.id,
    } as HoldingModel;
  }

  private setApp() {
    this.productAbstractService
      .searchProducts()
      .pipe(
        map(searchProduct => searchProduct.results),
        map(products => this.parseProductToAppMenuModel(products)),
      )
      .subscribe(appsMenu => {
        this.appMenuService.applyAppMenu(appsMenu);
      });
  }

  private parseProductToAppMenuModel(products: Product[]): AppMenuModel[] {
    return products.reverse().map((product: Product) => {
      const appMenuModel: AppMenuModel = {
        name: product.name,
        description: product.description,
        icon: product.icon,
        link: product.link,
        appActive: product.id === this.productId,
      };
      return appMenuModel;
    });
  }

  private setUserMenu(itemsMenu: AgSideMenuModel[]) {
    this.userMenu = this.createUserMenuModel();
    this.sideMenuItems = this.filterItemsMenu(itemsMenu);
  }

  private createUserMenuModel(): UserMenuModel {
    const {
      userProfile,
      userPreferences: { currentHolding },
    } = this.userAgriness;
    const ownerUser = currentHolding.users_relations.find(
      relation =>
        userHoldingRelationByName[relation.relation_type.name] == UserHoldingRelationType.OWNER,
    );

    const userRelation = currentHolding.users_relations.find(
      relation => relation.user.id == userProfile.id,
    );

    const userMenuModel: UserMenuModel = {
      photo: userProfile.photo,
      name: userProfile.name,
      email: userProfile.email,
      company: currentHolding.name,
      countFarm: currentHolding.count_farms,
      ownerEmail: ownerUser?.user?.email,
      profileName: userHoldingRelationByName[userRelation?.relation_type?.name],
    };
    return userMenuModel;
  }
}
