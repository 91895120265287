import { Search, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { StructureProduction, StructureProductionFilter } from '../models/movement.model';
import { StructureProductionAbstractService } from './structure-production-abastract.service';

@Injectable({
  providedIn: 'root',
})
export class StructureProductionService extends StructureProductionAbstractService {
  private pathStructureProduction =
    'holdings/{holding_id}/poultry/production-map/structure-productions/';

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
  ) {
    super();
  }

  list(
    structureProductionFilter?: StructureProductionFilter,
  ): Observable<Search<StructureProduction>> {
    const url = this.apiCorpService.translateUrl(this.pathStructureProduction, {
      holding_id: this.userStorageService.getCurrentHolding().id,
    });

    const params = {
      // type: structureProductionFilter.type,
      // relationship: structureProductionFilter.relationship,
      // simulation: structureProductionFilter.simulation,
      // status: structureProductionFilter.status,
      begin_date: structureProductionFilter.begin_date,
      end_date: structureProductionFilter.end_date,
    };

    return this.apiCorpService.get<Search<StructureProduction>>(url, params);
  }
}
