import { deprecatedColors } from '@agriness/assets/deprecated';

type OptionsByKey = Record<string, Partial<Highcharts.SeriesOptionsType>>;

export const defaultOptions: Partial<Highcharts.SeriesOptionsType> = {
  type: 'column',
  dashStyle: 'Solid',
};

export const optionsByKey: OptionsByKey = {
  target: {
    type: 'line',
    dashStyle: 'ShortDash',
    color: deprecatedColors.blue5,
  },
};
