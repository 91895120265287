import { StageEnum } from '@agriness/services';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiCorpService } from '../api-corp.service';
import {
  PreferenceId,
  TypeViewPreference,
  TypeViewPreferenceDefault,
  TypeViewPreferenceUser,
} from '../models/user.model';
import { UserPreferencesAbstractService } from './user-preferences-abstract.service';

@Injectable()
export class UserPreferencesService extends UserPreferencesAbstractService {
  pathTypeWithoutStageViewPreference = 'preferences/users/{type}/';
  pathDefaultTypeWithoutStageViewPreference = 'preferences/defaults/{type}/';

  pathTypeViewPreference = 'preferences/{stage}/users/{type}/';
  pathDefaultTypeViewPreference = 'preferences/{stage}/defaults/{type}/';

  constructor(private apiCorpService: ApiCorpService) {
    super();
  }

  getViewPreference(type: PreferenceId, stage: StageEnum): Observable<TypeViewPreference[]> {
    let path: string;
    let params: { type: string; stage?: string };

    if (stage) {
      path = this.pathTypeViewPreference;
      params = { type: this.replaceType(type), stage: stage };
    } else {
      path = this.pathTypeWithoutStageViewPreference;
      params = { type: this.replaceType(type) };
    }

    const url = this.apiCorpService.translateUrl(path, params);
    return this.apiCorpService.get<TypeViewPreference[]>(url);
  }

  getDefaultViewPreference(
    type: PreferenceId,
    stage: StageEnum,
  ): Observable<TypeViewPreferenceDefault> {
    let path: string;
    let params: { type: string; stage?: string };

    if (stage) {
      path = this.pathDefaultTypeViewPreference;
      params = { type: this.replaceType(type), stage: stage };
    } else {
      path = this.pathDefaultTypeWithoutStageViewPreference;
      params = { type: this.replaceType(type) };
    }

    const url = this.apiCorpService.translateUrl(path, params);
    return this.apiCorpService.get<TypeViewPreferenceDefault>(url);
  }

  getViewPreferences(type: PreferenceId, stage: StageEnum): Observable<TypeViewPreferenceUser> {
    const sourceDefaultViewPreference = this.getDefaultViewPreference(type, stage);
    const sourceViewPreferenceUser = this.getViewPreference(type, stage);
    return forkJoin<TypeViewPreferenceDefault, TypeViewPreference[]>([
      sourceDefaultViewPreference,
      sourceViewPreferenceUser,
    ]).pipe(
      map(([defaultViewPreference, userViewPreference]) => {
        const obj: TypeViewPreferenceUser = {
          default: defaultViewPreference.configurations,
          user: userViewPreference,
        };

        return obj;
      }),
    );
  }

  saveViewPreference(
    type: PreferenceId,
    typeViewPreference: TypeViewPreference[],
    stage: StageEnum,
  ): Observable<void> {
    let path: string;
    let params: { type: string; stage?: string };

    if (stage) {
      path = this.pathTypeViewPreference;
      params = { type: this.replaceType(type), stage: stage };
    } else {
      path = this.pathTypeWithoutStageViewPreference;
      params = { type: this.replaceType(type) };
    }

    const url = this.apiCorpService.translateUrl(path, params);

    return this.apiCorpService.put(url, typeViewPreference, null, true);
  }

  deleteViewPreference(type: PreferenceId, stage: StageEnum): Observable<void> {
    const url = this.apiCorpService.translateUrl(this.pathTypeViewPreference, {
      type: this.replaceType(type),
      stage: stage,
    });
    return this.apiCorpService.delete(url, null, true);
  }

  private replaceType(type: string): string {
    return type.replace(/_/g, '-');
  }
}
