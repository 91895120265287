<div class="d-flex justify-content-between align-items-center">
  <div class="d-flex" data-testid="ag-status-header-values-content">
    <span>
      <ng-container *ngFor="let item of headerItems; let index = index; let isLast = last">
        <ag-format
          [attr.data-testid]="'ag-status-header-value-' + index"
          [value]="item.value"
          [style]="item.measurementUnit"
          [decimalPlaces]="item.decimalPlaces"
        >
        </ag-format>
        {{ isLast ? ' ' : '/' }}
      </ng-container>
    </span>
  </div>
  <ag-target-indicator
    data-testid="ag-status-header-target-indicator"
    *ngIf="header.status"
    [status]="header.status"
    iconSize="12px"
    wrapperClass="ag-status-header"
  >
  </ag-target-indicator>
</div>
