import { FeedbackEnum } from '@agriness/ui';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';

import { TableDisplayData } from '../../../services/report-section/report-section-table.model';
import { TableService } from '../../../services/table.service';
import { ReportSectionItem } from '../corp-report-section.model';

@Component({
  selector: 'corp-report-section-table',
  templateUrl: './corp-report-section-table.component.html',
})
export class CorpReportSectionTableComponent implements ReportSectionItem {
  @Input() icon = 'icon-column-view';
  @Input() parentAsCard = true;
  @Input() data: TableDisplayData;
  @ViewChild('actions', { static: true }) actions: TemplateRef<unknown>;
  @ViewChild('content', { static: true }) content: TemplateRef<unknown>;
  @ViewChild('tableInstance') tableInstance: Table;

  noResultFeedback = FeedbackEnum.NO_DATA_CHART;

  readonly tableHeight = '400px';
  readonly tableRows = 10;

  constructor(private tableService: TableService) {}

  downloadCsv(): void {
    this.tableService.exportCSV(this.tableInstance);
  }
}
