export enum FeedbackEnum {
  ERROR = 'error',
  LOADING = 'loading',
  NOT_RESULT = 'not_result',
  NOT_RESULT_SEARCH = 'not_result_search',
  SEARCH = 'search',
  RECORD_SEARCH = 'record_search',
  NO_DATA_CHART = 'no_data_chart',
  LOCK = 'lock',
  NO_ITEM_SELECTED = 'no_item_selected',
  NO_LOOKER_PANEL = 'no_looker_panel',
  INFO = 'info',
  PROCESSING = 'processing',
}

export enum FeedbackPosition {
  CENTER_EXPAND = 'center-expand',
  CENTER_PARENT = 'center-parent',
}

export interface FeedbackContent {
  id: string;
  icon: string;
  iconClass?: string;
  msg: string;
}

export type FeedbackEnumProps = { [key in keyof typeof FeedbackEnum]: string };
