import { APP_INITIALIZER, NgModule } from '@angular/core';
import { KeycloakAngularModule } from 'keycloak-angular';

import { AuthAbstractService } from '../services/abstract/auth-abstract.service';
import { UserAbstractService } from '../services/abstract/user-abstract.service';
import { UserService } from '../services/domain/user/user.service';
import { AgrinessKeycloakConfigService } from './agriness-keycloak-config.service';

@NgModule({
  imports: [KeycloakAngularModule],
  providers: [
    AgrinessKeycloakConfigService,
    { provide: UserAbstractService, useClass: UserService },
    { provide: AuthAbstractService, useClass: AgrinessKeycloakConfigService },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: AgrinessKeycloakConfigService.init,
      deps: [AgrinessKeycloakConfigService],
    },
  ],
})
export class AppInitializerModule {}
