import { InjectionToken } from '@angular/core';
import { AsyncSubject } from 'rxjs';

import { FeedbackEnum } from '../feedback/ag-feedback.model';

export const REDIRECT_DATA = new InjectionToken<{}>('REDIRECT_DATA');

export interface RedirectItem {
  data: RedirectItemData;
  asyncSubject: AsyncSubject<any>;
}

export interface RedirectItemData {
  service: string;
  url?: string;
  feedback?: RedirectItemFeedback;
}

export interface RedirectItemFeedback {
  icon?: FeedbackEnum;
  message: string;
}
