import { AnimalGroupStatus, StageEnum, UserStorageService } from '@agriness/services';
import { CardLink, FeedbackEnum } from '@agriness/ui';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { filterIdsByStageAndStatus } from '../../model/filter.model';
import {
  subReportsByStageAndStatus,
  subReportsByStage,
  MonitorExhibitionType,
  Index,
} from '../../model/monitor.model';
import { ReportFilterQuery, ReportFilterType } from '../../model/report-filter.model';
import { CorpReportComponent } from '../../model/report.model';
import { CorpReportFiltersComponent } from '../corp-report-filters/corp-report-filters.component';
import { LoaderUserPreference } from '../loader-user-preference';

@Component({
  selector: 'corp-animal-group-monitor-tab',
  templateUrl: './corp-animal-group-monitor-tabs.component.html',
})
export class CorpAnimalGroupMonitorTabsComponent extends LoaderUserPreference implements OnInit {
  @Input() animalGroupStatus: AnimalGroupStatus;
  @Input() type: MonitorExhibitionType;
  @ViewChild('reportFilter', { static: true })
  reportFilter: CorpReportFiltersComponent;

  @ViewChildren('report')
  reports: QueryList<CorpReportComponent>;

  typeFeedback = FeedbackEnum.LOADING;
  StageEnum = StageEnum;
  linkAnimalGroupList: CardLink;
  stage: StageEnum;
  filterIds: ReportFilterType[];
  currentFilter: ReportFilterQuery;
  cardsIndexes: Index[] = [];
  tableIndexes;

  constructor(
    protected userStorageService: UserStorageService,
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
  ) {
    super(userStorageService);
  }

  ngOnInit(): void {
    if (!this.animalGroupStatus) {
      this.route.url
        .subscribe(urlSegment => {
          const [animalGroupStatus] = urlSegment.map(url => url.path);

          this.animalGroupStatus = animalGroupStatus as AnimalGroupStatus;
        })
        .unsubscribe();
    }

    this.stage = this.route.snapshot.data.stage as StageEnum;
    this.filterIds = filterIdsByStageAndStatus[this.stage][this.animalGroupStatus];
    this.updateReports();
  }

  filter(): void {
    this.updateCurrentFilter();

    this.typeFeedback = FeedbackEnum.LOADING;

    const observables = this.getPerformancesObservables().map(obs =>
      obs.pipe(
        tap(() => (this.typeFeedback = null)),
        catchError(error => of({ error: error as unknown })),
      ),
    ) as Observable<{ error: Record<string, unknown> } | Record<string, unknown>>[];

    forkJoin(observables).subscribe(
      results => {
        if (results.every(item => 'error' in item)) {
          this.typeFeedback = FeedbackEnum.ERROR;
        }
      },
      () => {
        this.typeFeedback = null;
      },
    );
    this.updateLinkAnimalGroupList();
  }

  loadData(): void {
    this.loadPreferences().subscribe({ complete: () => this.filter() });
  }

  private updateReports() {
    if (this.type) {
      if (this.type === MonitorExhibitionType.TABLE) {
        this.tableIndexes = subReportsByStage[this.stage];
      } else {
        this.cardsIndexes = subReportsByStage[this.stage];
      }
    } else {
      if (this.animalGroupStatus === AnimalGroupStatus.OPEN) {
        this.tableIndexes = subReportsByStageAndStatus[this.stage][AnimalGroupStatus.OPEN];
        this.cardsIndexes = this.cardsIndexes.filter(
          (el: Index) => el.internalName !== 'monitor-quality-nutritional',
        );
      } else {
        this.cardsIndexes = subReportsByStageAndStatus[this.stage][AnimalGroupStatus.CLOSED];
        this.cardsIndexes = this.cardsIndexes.filter(
          (el: Index) =>
            el.internalName !== 'monitor-quality-nutritional' &&
            el.internalName !== 'monitor-closed-economic',
        );
      }
    }
  }

  private updateCurrentFilter(): void {
    this.currentFilter = {
      ...this.reportFilter.getCurrentFilterInQueryFormat(),
      is_open: (this.route.snapshot.routeConfig.path == AnimalGroupStatus.OPEN).toString(),
    };
  }

  private updateLinkAnimalGroupList(): void {
    this.linkAnimalGroupList = {
      url: `/${environment.typeProduction}/${this.stage}/analysis/animal-group-list`,
      queryParams: this.currentFilter,
    };
  }

  private getPerformancesObservables(): Observable<unknown>[] {
    return this.reports.map(p => p.loadPerformances(this.currentFilter));
  }

  private loadPreferences(): Observable<unknown[]> {
    return forkJoin(this.reports.map(p => p.loadPreferences()));
  }
}
