import { Icons } from '@agriness/assets';
import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

@Component({
  selector: 'corp-modal-form',
  templateUrl: './corp-modal-form.component.html',
  styleUrls: ['./corp-modal-form.component.scss'],
})
export class CorpModalFormComponent {
  @Input() visible = false;
  @Input() modalStatus = ModalStatusEnum.DEFAULT;
  @Input() translationContext = 'agriness.feedback_modal';
  @Input() formValid = false;
  @Input() title = '';
  @Input() contentWidth: string;
  @Input() icon: Icons;

  @Output() closed = new EventEmitter<void>();
  @Output() formSubmit = new EventEmitter<void>();
  @Output() formRestore = new EventEmitter<void>();

  showHeader = true;

  ModalStatusEnum = ModalStatusEnum;

  constructor(@Inject(TRANSLATE_INSTANT) private t: TranslateInstant) {}

  hideModal(): void {
    this.visible = false;
    this.closed.emit();
  }

  onSubmit(): void {
    this.formSubmit.emit();
  }

  onRestore(): void {
    this.formRestore.emit();
  }
}
