import { ReportCardDairyEnum, ReportTabDairyEnum } from '@agriness/corp-app/services';
import { PerformanceAnalysisTabReport } from '@agriness/corp-app/shared/component/corp-performance-analysis/corp-performance-analysis.model';

export const tabs: PerformanceAnalysisTabReport[] = [
  {
    router: 'shipped',
    translateKey: 'agriness.performance_analysis.tabs.milk_shipped',
    report: ReportTabDairyEnum.SHIPPED,
    cardReport: ReportCardDairyEnum.SHIPPED,
    indicators: [],
  },
  {
    router: 'fat',
    translateKey: 'agriness.performance_analysis.tabs.fat',
    report: ReportTabDairyEnum.FAT,
    cardReport: ReportCardDairyEnum.FAT,
    indicators: [],
  },
  {
    router: 'protein',
    translateKey: 'agriness.performance_analysis.tabs.protein',
    report: ReportTabDairyEnum.PROTEIN,
    cardReport: ReportCardDairyEnum.PROTEIN,
    indicators: [],
  },
  {
    router: 'scc',
    translateKey: 'agriness.performance_analysis.tabs.scc',
    report: ReportTabDairyEnum.SSC,
    cardReport: ReportCardDairyEnum.SSC,
    indicators: [],
  },
  {
    router: 'mun',
    translateKey: 'agriness.performance_analysis.tabs.mun',
    report: ReportTabDairyEnum.MUN,
    cardReport: ReportCardDairyEnum.MUN,
    indicators: [],
  },
  {
    router: 'bc',
    translateKey: 'agriness.performance_analysis.tabs.bc',
    report: ReportTabDairyEnum.BC,
    cardReport: ReportCardDairyEnum.BC,
    indicators: [],
  },
  /* {
    router: 'efficiency',
    translateKey: 'agriness.performance_analysis.tabs.efficiency',
    report: ReportTabDairyEnum.EFFICIENCY,
    cardReport: ReportCardDairyEnum.EFFICIENCY,
    indicators: [],
  }, */
];
