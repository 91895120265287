import { Component, Input, TemplateRef, OnInit } from '@angular/core';

import { Colors, Icons } from '@agriness/assets';

@Component({
  selector: 'agriness-card',
  templateUrl: './agriness-card.component.html',
  styleUrls: ['./agriness-card.component.scss'],
})
export class AgrinessCardComponent implements OnInit {
  @Input() cardTemplate: TemplateRef<any>; // Overrides whole card template
  @Input('title') titleText: string;
  @Input() titleTemplate: TemplateRef<any>; // Overrides title template only
  @Input('content') contentText: string;
  @Input() contentTemplate: TemplateRef<any>; // Overrides content template only
  @Input('icon') iconName: Icons;
  @Input() iconSize: number = 24;
  @Input() iconColor: Colors = 'color-grey-medium';
  @Input() clickable: boolean;

  ngOnInit() {
    this.clickable = this.clickable !== undefined;
  }
}
