import { PerformanceItems } from '@agriness/corp-app/services';
import { TableColumn } from '@agriness/ui';

export enum DisplayOption {
  BY_AGE = 'BY_AGE',
  BY_DATE = 'BY_DATE',
  BY_MONTH = 'BY_MONTH',
  WEEKS_5 = 'WEEKS_5',
  WEEKS_10 = 'WEEKS_10',
}

export const displayOptionNumeral: { [k in DisplayOption]?: number } = {
  [DisplayOption.WEEKS_5]: 5,
  [DisplayOption.WEEKS_10]: 10,
};

export enum DisplayType {
  CHART = 'CHART',
  TABLE = 'TABLE',
}

export const displayIcon: Record<DisplayType, string> = {
  [DisplayType.CHART]: 'icon-report1',
  [DisplayType.TABLE]: 'icon-column-view',
};

export interface PerformanceData {
  displayType: DisplayType;
  displayOption: DisplayOption;
  data: PerformanceItems;
  downloadFilename?: string;
}

export interface TableData {
  columns: TableColumn[];
  data: any;
}

export const columnWidth: Record<string, string> = {
  current_age: '80px',
};
