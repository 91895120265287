<span
  *ngIf="icon"
  class="agriness-icon"
  [ngStyle]="{
    'font-size.px': size,
    color: getColor(color)
  }"
>
  {{ getIcon(icon) }}
</span>
