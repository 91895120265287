import { SiteEnum, SiteSystemType } from '@agriness/services';
import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  templateUrl: './reproductive-identify.component.html',
})
export class ReproductiveIdentifyComponent {
  stage = SiteSystemType[environment.typeProduction][SiteEnum.SITE_1];
}
