import { StageEnum } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { AnimalGroupIndicators, FilterIndicators } from '../models/animal-group-indicators.model';
import { MapIndicatorsAbstractService } from './map-indicators-abstract.service';

@Injectable()
export class MapIndicatorsService extends MapIndicatorsAbstractService {
  private pathMapIndicators =
    'holdings/{holding_id}/{type_production}/{stage}/reports/indicators-map/';

  constructor(private apiCorpService: ApiCorpService) {
    super();
  }

  getIndicators(
    holdingId: string,
    stage: StageEnum,
    filter: FilterIndicators,
  ): Observable<AnimalGroupIndicators[]> {
    const url = this.apiCorpService.translateUrl(this.pathMapIndicators, {
      stage,
      holding_id: holdingId,
    });
    return this.apiCorpService.get<AnimalGroupIndicators[]>(url, filter);
  }
}
