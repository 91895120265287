<section class="section is-full">
  <div class="page-title d-flex align-items-center mb-3">
    <corp-back-button></corp-back-button>
    {{ pageTitle | translate }}
  </div>

  <corp-performance-analysis
    [analysisInterval]="analysisInterval"
    [graphTemplate]="graphTemplate"
    [tabs]="reportTabs"
  ></corp-performance-analysis>

  <ng-template #graphTemplate let-config>
    <reproductive-performance-analysis-graph-component
      class="position-absolute"
      style="right: 0; left: 0; bottom: 0; top: 0;"
      (chartInstance)="config.chartInstance($event)"
      [performanceData]="config.performanceData"
    ></reproductive-performance-analysis-graph-component>
  </ng-template>
</section>
