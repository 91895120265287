import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { TableColumn, AgTableComponent } from '@agriness/ui';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnChanges,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get, kebabCase } from 'lodash';
import { LazyLoadEvent } from 'primeng/api';

import { PaginationRequest } from './../../model/table.model';

export enum ActionEnum {
  EDIT = 'edit',
  DELETE = 'delete',
  TOGGLE = 'toggle',
  VIEW = 'view',
}

@Component({
  selector: 'corp-record-table',
  templateUrl: './corp-record-table.component.html',
  styleUrls: ['./corp-record-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpRecordTableComponent<T> implements OnChanges {
  @ViewChild('agTable') agTable: AgTableComponent;
  @Input() title = '';
  @Input() loading = false;
  @Input() error = false;
  @Input() rows = 10;
  @Input() rowsPerPageOptions = [5, 10, 20, 40, 60, 100];
  @Input() actions = Object.values(ActionEnum);

  @Input() tableColumns: TableColumn[];
  @Input() defaultOrdering: string[];
  @Input() data: T[];
  @Input() totalRecords: number;

  @Input() blockedIconTooltipKey = '';
  @Input() toggleIconTooltipKey = 'agriness.toggle';

  @Input() hasEditActionOnToggle = false;

  @Output() pageRequest = new EventEmitter<PaginationRequest>();
  @Output() addClick = new EventEmitter<void>();
  @Output() actionClick = new EventEmitter<{ action: string; item: T; checked?: boolean }>();

  alwaysShowPagination = true;

  blocked = 'blocked';
  blockedIcon = 'icon icon-block is-16 block';
  iconMapping: Record<ActionEnum, string> = {
    [ActionEnum.DELETE]: 'icon icon-delete is-16 delete',
    [ActionEnum.EDIT]: 'icon icon-edit is-16 edit',
    [ActionEnum.TOGGLE]: '',
    [ActionEnum.VIEW]: 'icon icon-visibility is-16 view',
  };

  frozenTableColumns: TableColumn[] = [];
  scrollableTableColumns: TableColumn[] = [];

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private translate: TranslateService,
  ) {}

  @Input() shouldBlockAction: ({ action, item }: { action: string; item: T }) => boolean = () =>
    false;

  ngOnChanges(): void {
    this.loadColumns();
  }

  handleAddClick(): void {
    this.addClick.emit();
  }

  loadLazy(event: LazyLoadEvent): void {
    const request = {
      page: (event ? event.first / (event.rows || 1) : 0) + 1,
      page_size: event ? event.rows : this.rows,
      order: event.sortField
        ? event.sortOrder > 0
          ? event.sortField
          : `-${event.sortField}`
        : this.defaultOrdering.join(','),
    };

    this.pageRequest.emit(request);
  }

  loadRowCell(rowData: T, column?: string): { id: string; value: string } | null {
    const value = get(rowData, column) as string;
    if (!value) {
      return null;
    }

    return {
      id: kebabCase(column),
      value,
    };
  }

  loadActionIcon(item: T, action: string): string {
    return (this.shouldBlockAction({ action, item })
      ? this.blockedIcon
      : this.iconMapping[action]) as string;
  }

  loadActionEvent(item: T, event: string): () => void {
    const icon = this.loadActionIcon(item, event);
    const action = icon == this.blockedIcon ? this.blocked : event;

    return () => this.actionClick.emit({ action, item });
  }

  onToggle(obj: { item: T; checked: boolean }): void {
    this.actionClick.emit({ action: 'toggle', ...obj });
  }

  loadActionTooltip(item: T, action: string): string {
    const icon = this.loadActionIcon(item, action);
    return this.t(
      icon == this.blockedIcon
        ? this.blockedIconTooltipKey
        : action == 'toggle'
        ? this.toggleIconTooltipKey
        : `agriness.${action}`,
    );
  }

  reset(): void {
    this.agTable?.reset();
  }

  private loadColumns() {
    this.frozenTableColumns = [];
    this.scrollableTableColumns = [];

    this.tableColumns.forEach(item => {
      if (item.hidden) {
        return;
      }

      if (item.fixed) {
        this.frozenTableColumns.push(item);
      } else {
        this.scrollableTableColumns.push(item);
      }
    });
  }
}
