import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'diet-selection-autocomplete',
  templateUrl: './diet-selection-autocomplete.component.html',
  styleUrls: ['./diet-selection-autocomplete.styles.scss'],
})
export class DietSelectionAutoCompleteComponent {
  @Input() suggestions: unknown[];
  @Input() placeholder: string;
  @Input() group: FormGroup;
  @Input() controlName: string;
  @Input() templateItem: TemplateRef<unknown>;
  @Input() emptyMessage = 'agriness.diet_selection.no_registry';

  @Output() completing = new EventEmitter();
  @Output() selecting = new EventEmitter();

  search(event: { query: string }): void {
    this.completing.emit(event);
  }

  select(event: { query: string }): void {
    this.selecting.emit(event);
  }

  clear(): void {
    this.search({ query: null });
  }
}
