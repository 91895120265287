import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'uninformed',
})
export class UninformedPipe implements PipeTransform {
  transform(value: any): any {
    if (_.isEmpty(value)) {
      return 'agriness.uninformed';
    }
    return value;
  }
}
