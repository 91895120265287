import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lazy',
  pure: false,
})
export class LazyPipe implements PipeTransform {
  transform<T>(value: T): () => T {
    return () => value;
  }
}
