import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { AsyncSubject, Subject, of, Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { AgRedirectModalComponent } from './ag-redirect-modal.component';
import {
  REDIRECT_DATA,
  RedirectItem,
  RedirectItemData,
  RedirectItemFeedback,
} from './ag-redirect-modal.model';

@Injectable({
  providedIn: 'root',
})
export class RedirectModalService {
  private current: RedirectItem = null;
  currentRedirect = new Subject<RedirectItemData>();

  constructor(private overlay: Overlay, private injector: Injector) {
    this.createOverlay();
  }

  private createOverlay(): void {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const overlayRef = this.overlay.create({
      positionStrategy,
    });
    const RedirectManagerPortal = new ComponentPortal(
      AgRedirectModalComponent,
      null,
      this.createInjector(this.currentRedirect),
    );

    overlayRef.attach(RedirectManagerPortal);
  }

  private createInjector(data): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(REDIRECT_DATA, data);
    return new PortalInjector(this.injector, injectorTokens);
  }

  redirect(
    payload: Observable<string>,
    service: string,
    errorProxy?: (err: Error) => RedirectItemFeedback,
  ) {
    const asyncSubject = new AsyncSubject();
    const errorTreatment = errorProxy || ((err: Error) => ({ message: err.message }));

    this.current = {
      data: { service },
      asyncSubject,
    };

    this.currentRedirect.next(this.current.data);

    payload
      .pipe(
        tap((url: string) => {
          this.current.data.url = url;
        }),
        catchError(err => {
          this.current.data.feedback = errorTreatment(err);
          this.currentRedirect.next(this.current.data);
          this.current.asyncSubject.error(err);
          return of(null);
        }),
        finalize(() => {
          this.currentRedirect.next(this.current.data);
          this.current.asyncSubject.next(this.current.data);
          this.current.asyncSubject.complete();
          this.current = null;
        }),
      )
      .subscribe();

    return asyncSubject;
  }
}
