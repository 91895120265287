<div class="row mb-4" [hidden]="!hasData() && !feedbackType" [attr.data-testid]="report">
  <div class="col">
    <div class="section-header d-flex align-items-center">
      <span>
        {{ 'agriness.zootechnical_indexes_by_week' | translate }}
      </span>
    </div>

    <ag-feedback [type]="feedbackType"></ag-feedback>

    <ng-container *ngIf="!feedbackType">
      <div class="card shadow-sm mt-2" *ngFor="let performance of performances">
        <div class="card-body">
          <div
            class="card-title d-flex align-items-center"
            style="display: flex; justify-content: space-between;"
          >
            <span>
              <ng-container>
                {{ performance.label }}
              </ng-container>
            </span>
            <span>
              <ng-container *ngFor="let totalizer of performance.totalizers; let last = last">
                <span class="font-weight-normal"> {{ totalizer.label }}: </span>
                <ag-format
                  [value]="totalizer.value"
                  [measurementUnit]="totalizer.measurementUnit"
                  [decimalPlaces]="totalizer.decimalPlaces"
                ></ag-format>
                <span class="ml-2 mr-2">{{ last ? '' : '|' }}</span>
              </ng-container>
            </span>
          </div>

          <div class="columns is-multiline">
            <div
              class="column is-4-tablet is-3-desktop is-2-widescreen is-2-fullhd"
              *ngFor="let period of performance.periods"
            >
              <span class="text is-xsmall has-text-grey-3">
                {{ period.label }}
              </span>
              <ag-status-card [header]="period" [items]="period.items"> </ag-status-card>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
