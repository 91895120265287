import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../common/shared.module';
import { AgInputComponent } from './ag-input.component';

@NgModule({
  imports: [SharedModule, ReactiveFormsModule, FormsModule],
  declarations: [AgInputComponent],
  exports: [AgInputComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgInputModule {}
