import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Input, ChangeDetectionStrategy, Inject } from '@angular/core';

import { HealthReportDocumentStatus } from './corp-animal-group-health-report.model';

@Component({
  selector: 'corp-animal-group-health-report',
  templateUrl: './corp-animal-group-health-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpAnimalGroupHealthReportComponent {
  @Input() status = HealthReportDocumentStatus.EDITING;
  @Input() documentId: string;
  @Input() publishedUrl: string;
  @Input() animalGroupName: string;

  constructor(@Inject(TRANSLATE_INSTANT) private t: TranslateInstant) {}

  statusTranslateKey(): string {
    const translateKey = `agriness.health_report.${this.status}`;

    return this.t(translateKey);
  }
}
