import { Target, TargetReportEnum } from '@agriness/corp-app/services';
import { StageEnum, TypeProductionEnum } from '@agriness/services';

export type ReportTargets = {
  report: TargetReportEnum | 'others';
  targets: Target[];
};

export type ReportsTargetsByStage = {
  [stage in StageEnum]?: ReportTargets[];
};

export type RangeValidation = {
  range: {
    min: number;
    max: number;
  };
};

export type ControlErrors = {
  required: boolean;
} & RangeValidation;

export type TargetForm = {
  target_value: number;
  value_variation_rate: number;
  min_allowed: number;
  max_allowed: number;
  min_recommended: number;
  max_recommended: number;
};

export const orderedTargetReport = [
  TargetReportEnum.REPRODUCTIVE_PLANTEL,
  TargetReportEnum.REPRODUCTIVE_REPRODUCTION,
  TargetReportEnum.REPRODUCTIVE_MATERNITY,
  TargetReportEnum.NURSERY_QUALITY,
  TargetReportEnum.NURSERY_DEADLINE,
  TargetReportEnum.NURSERY_COST,
  TargetReportEnum.FINISHING_QUALITY,
  TargetReportEnum.FINISHING_DEADLINE,
  TargetReportEnum.FINISHING_COST,
  TargetReportEnum.LAYERS_PRODUCTION_EGG,
  TargetReportEnum.LAYERS_PRODUCTION_FEED,
  TargetReportEnum.LAYERS_PRODUCTION_MORTALITY,
  TargetReportEnum.LAYERS_PRODUCTION_BODY_WEIGHT,
  TargetReportEnum.GROW_OUT_WEIGHT,
  TargetReportEnum.GROW_OUT_MORTALITY,
  TargetReportEnum.GROW_OUT_EFFICIENCY,
  TargetReportEnum.GROW_OUT_COST,
  TargetReportEnum.MILKING_ALL,
  'others',
];

export const orderedStagesBySystemTypes: { [k in TypeProductionEnum]?: StageEnum[] } = {
  [TypeProductionEnum.SWINES]: [StageEnum.REPRODUCTIVE, StageEnum.NURSERY, StageEnum.FINISHING],
  [TypeProductionEnum.LAYERS]: [StageEnum.LAYERS_PRODUCTION],
  [TypeProductionEnum.POULTRY]: [StageEnum.GROW_OUT],
  [TypeProductionEnum.DAIRY]: [StageEnum.MILKING],
};
