import {
  FilterComponent,
  InputTextComponent,
} from '@agriness/corp-app/shared/component/corp-filters/corp-filter-abstractions';
import { CorpFiltersContainerService } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.service';
import {
  ReportFilter,
  ReportFilterQueryKeys,
} from '@agriness/corp-app/shared/model/report-filter.model';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'corp-textsearch-filter',
  templateUrl: './corp-textsearch-filter.component.html',
  styleUrls: ['./filter.module.scss'],
  providers: [{ provide: FilterComponent, useExisting: CorpTextSearchFilterComponent }],
})
export class CorpTextSearchFilterComponent extends InputTextComponent implements OnInit {
  @Input() filterEntity: keyof ReportFilter;
  @Input() queryParamName: ReportFilterQueryKeys;

  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(translate, containerService, activeRoute);
  }

  ngOnInit(): void {
    this.assignDefaults(this.filterEntity);
    super.ngOnInit();
  }
}
