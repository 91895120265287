import {
  AnimalGroupAbstractService,
  BonificationService,
  Rule,
  RuleFilter,
} from '@agriness/corp-app/services';
import { DownloadStatus } from '@agriness/corp-app/services/models/download-status.model';
import {
  CalcPayment,
  CalcPaymentRequest,
  CheckPayment,
  Participation,
  PaymentForm,
  PaymentIndex,
  PaymentIndexCalcRequest,
  PaymentList,
  PaymentPostRequest,
} from '@agriness/corp-app/services/models/payment-record.model';
import { PaymentReportService } from '@agriness/corp-app/services/payment-report/payment-report.service';
import { AgrinessTranslateService, TypeProductionService } from '@agriness/services';
import { AgSelectComponent, QueueItem, QueueManagerService } from '@agriness/ui';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, Subscriber, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { CurrencyEnum } from './currency.model';

export interface RuleSelectOption {
  name: string;
  key: string;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, AfterViewInit {
  @ViewChild(AgSelectComponent) select: AgSelectComponent;
  isEdit = false;
  translationContext = 'agriness.animal_group_record.payment-form';
  animalGroupName: string;
  paymentCheckData: CheckPayment;

  farmId: string;
  animalGroupId: string;
  documentId: string;
  holdingId: string;
  stage: string;

  ruleList: Rule[] = [];
  ruleSelectData: BehaviorSubject<RuleSelectOption[]> = new BehaviorSubject<RuleSelectOption[]>([]);
  ruleSelected: Rule = null;

  paymentForm: FormGroup;

  isOpen: boolean;
  currency: string;
  weight: string;

  currency_translator = CurrencyEnum;

  currentFilter: RuleFilter = {};

  payment_indexes: FormArray;
  total_animals: number;
  total_weight_gain: number;
  total_weight_output: number;
  fieldsBlocked = true;

  feedbackVisible = false;
  pageLeaveSubscriber: Subscriber<boolean>;
  showModal = false;

  canLeave = false;

  need_add_desc = false;
  need_disc_desc = false;

  get additions(): number {
    return +this.paymentForm.get('additions.value').value;
  }
  get discounts(): number {
    return +this.paymentForm.get('discounts.value').value;
  }
  get additions_desc(): string {
    return this.paymentForm.get('additions.description').value as string;
  }
  get discounts_desc(): string {
    return this.paymentForm.get('discounts.description').value as string;
  }
  get price(): number {
    return +this.paymentForm.get('priceByKg').value;
  }

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private service: PaymentReportService,
    private rules: BonificationService,
    private router: Router,
    private t: TranslateService,
    private translateService: AgrinessTranslateService,
    private queueManager: QueueManagerService,
    private animalGroupService: AnimalGroupAbstractService,
    private typeProductionService: TypeProductionService,
  ) {}
  ngOnInit(): void {
    this.paymentForm = this.fb.group({
      rule: new FormControl(null),
      delivery_indicator: new FormControl(''),
      priceByKg: new FormControl('', Validators.required.bind(this)),
      total_animals_value: new FormControl(''),
      additions: new FormGroup({
        value: new FormControl(''),
        description: new FormControl(''),
      }),
      discounts: new FormGroup({
        value: new FormControl(''),
        description: new FormControl(''),
      }),
      participation: new FormGroup({
        total_participation: new FormControl(''),
        total_price: new FormControl(''),
        price_by_head: new FormControl(''),
        total_animal_value: new FormControl(''),
        price_by_total_weight_gain: new FormControl(''),
        total_participation_by_producer: new FormControl(''),
      }),
    });
    this.payment_indexes = new FormArray([], Validators.required.bind(this));

    this.getRoutParams();
    this.loadRuleList();
  }

  ngAfterViewInit(): void {
    this.watchBasePrice();
    this.watchAdditions();
    this.watchDiscounts();

    this.ruleSelectData.subscribe(res => {
      if (this.documentId && res?.length > 0) {
        this.loadFormData();
      }
    });
  }

  watchIndexValue(): void {
    this.payment_indexes.controls.forEach(control => {
      control.get('value').valueChanges.subscribe(() => {
        this.calculateIndexes();
      });
    });
  }

  calculateIndexes(addFormIndex = false): void {
    const form = this.paymentForm.getRawValue() as PaymentForm;
    const indexes = this.payment_indexes.getRawValue() as PaymentIndex[];
    const indexesFormated: PaymentIndexCalcRequest[] = [];

    if (!addFormIndex) {
      indexes.forEach(item => {
        const index = this.ruleSelected.indexes.find(a => a.name === item.name);
        indexesFormated.push({
          payment_rule_index: index.id,
          value: item.value,
          name: item.name,
          type: index.type,
        });
      });
    }

    const data: CalcPaymentRequest = {
      total_animal: this.total_animals,
      addition: form.additions.value || 0,
      discount: form.discounts.value || 0,
      payment_rule: this.ruleSelected.id,
      price_by_weight: form.priceByKg || 0,
      total_weight_output: this.total_weight_output,
      total_weight_gain: this.total_weight_gain,
      is_open: this.isOpen,
      payment_index: indexesFormated,
    };

    this.reCalc(data).subscribe(res => {
      if (addFormIndex) {
        this.addFormIndex(res.payment_index);
        this.watchIndexValue();
      }
      this.setIndexs(res.payment_index);
      this.setParticipation(res.participation);
    });
  }

  watchBasePrice(): void {
    this.paymentForm.get('priceByKg').valueChanges.subscribe(() => {
      this.calculateIndexes();
    });
  }

  watchAdditions(): void {
    this.paymentForm.get('additions.value').valueChanges.subscribe(value => {
      if (value) {
        this.need_add_desc = true;
      } else {
        this.need_add_desc = false;
      }
      this.calculateIndexes();
    });
  }

  watchDiscounts(): void {
    this.paymentForm.get('discounts.value').valueChanges.subscribe(value => {
      if (value) {
        this.need_disc_desc = true;
      } else {
        this.need_disc_desc = false;
      }
      this.calculateIndexes();
    });
  }

  addFormIndex(indexes?: PaymentIndex[]): void {
    this.payment_indexes.clear();

    if (indexes) {
      indexes.forEach(item => {
        this.payment_indexes.push(
          new FormGroup(
            {
              name: new FormControl(item.name),
              value: new FormControl(item.value, Validators.required.bind(this)),
              range: new FormControl(item.range),
              percentage: new FormControl(item.percentage),
              total_index_price: new FormControl(item.total_index_price),
            },
            Validators.required.bind(this),
          ),
        );
      });
    } else {
      this.payment_indexes.push(
        new FormGroup(
          {
            name: new FormControl(),
            value: new FormControl(Validators.required.bind(this)),
            range: new FormControl(),
            percentage: new FormControl(),
            total_index_price: new FormControl(),
          },
          Validators.required.bind(this),
        ),
      );
    }
  }

  getRoutParams(): void {
    this.route.queryParamMap
      .subscribe(res => {
        this.animalGroupName = res.get('animalGroupName');
        this.holdingId = res.get('holdingId');
        this.stage = res.get('stage');
        this.isOpen = JSON.parse(res.get('is_open')) as boolean;
        this.total_animals = +res.get('total_animal');
        this.total_weight_gain = +res.get('total_weight_gain');
        this.total_weight_output = +res.get('total_weight_output');
        this.documentId = res.get('documentId');
        this.currency = res.get('currency');
        this.weight = res.get('weight');

        this.fieldsBlocked = !!this.documentId;
      })
      .unsubscribe();

    this.route.paramMap.subscribe(params => {
      this.farmId = params.get('idFarm');
      this.animalGroupId = params.get('idAnimalGroup');
    });
  }

  loadRuleList(): void {
    this.currentFilter = {
      ...this.currentFilter,
      stage: this.stage,
      enabled: true,
    };

    this.rules
      .list(this.currentFilter)
      .pipe(
        map(data => {
          return data.results.filter(res => res.stage.name === this.stage);
        }),
      )
      .subscribe(res => {
        this.ruleList = res;

        this.setRuleOptions(this.ruleList);
      });
  }

  setRuleOptions(rules: Rule[]): void {
    const data: RuleSelectOption[] = [];

    rules.forEach(rule => {
      data.push({
        name: rule.name,
        key: rule.id,
      });
    });

    this.ruleSelectData.next(data);
  }

  loadFormData(): void {
    this.service.list(this.holdingId, this.animalGroupId).subscribe(res => {
      if (res.results.length > 0) {
        const formData: PaymentList = res.results[0];

        this.ruleSelected = this.ruleList.find(item => item.id === formData.payment_rule);
        const selectedRule = this.ruleSelectData?.value.find(
          item => item.key === formData.payment_rule,
        );

        this.paymentForm.controls['rule'].setValue(selectedRule.key);
        this.setDeliveryIndicator();

        this.addFormIndex(formData.payment_index);
        this.setIndexs(formData.payment_index);
        this.setParticipation(formData.participation);
        this.watchIndexValue();

        this.paymentForm.controls['priceByKg'].setValue(formData.price_by_weight, {
          emitEvent: false,
        });
        this.paymentForm.controls['additions']
          .get('value')
          .setValue(formData.addition ? formData.addition : null, { emitEvent: false });
        this.paymentForm.controls['additions']
          .get('description')
          .setValue(formData.addition_desc ? formData.addition_desc : '');
        this.paymentForm.controls['discounts']
          .get('value')
          .setValue(formData.discount ? formData.discount : null, { emitEvent: false });
        this.paymentForm.controls['discounts']
          .get('description')
          .setValue(formData.discount_desc ? formData.discount_desc : '');

        this.isEdit = true;
      }
    });
  }

  saveForm(): void {
    const form = this.paymentForm.getRawValue() as PaymentForm;

    const indexesForm = this.payment_indexes.getRawValue() as PaymentIndex[];

    const indexes: PaymentIndexCalcRequest[] = [];

    indexesForm.forEach(item => {
      const index = this.ruleSelected.indexes.find(a => a.name === item.name);
      indexes.push({
        payment_rule_index: index.id,
        value: item.value,
        name: item.name,
        type: index.type,
      });
    });

    const data: PaymentPostRequest = {
      animal_group: this.animalGroupId,
      payment_rule: this.ruleSelected.id,
      price_by_weight: form.priceByKg,
      total_animal: this.total_animals,
      addition: form.additions.value || 0,
      addition_desc: form.additions.description || null,
      discount: form.discounts.value || 0,
      discount_desc: form.discounts.description || null,
      total_weight_output: this.total_weight_output,
      total_weight_gain: this.total_weight_gain,
      payment_index: indexes,
    };

    const t = (key: string) =>
      this.translateService.instantWithFallback(`agriness.animal_group_record.payment-form.${key}`);

    this.queueManager
      .add({}, '')
      .pipe(
        tap(({ actions }: QueueItem) => {
          actions.update(t('waiting'));
          this.service
            .save(this.holdingId, this.animalGroupId, data, this.documentId)
            .pipe(
              map(result => (this.documentId = result.id)),
              catchError(({ error }) => {
                actions.error(t('error'));
                return throwError(error);
              }),
              tap(() => {
                this.canLeave = true;
                void this.router
                  .navigate([
                    '/',
                    this.typeProductionService.get(),
                    'record',
                    this.farmId,
                    this.animalGroupId,
                  ])
                  .then(() => {
                    actions.success(t('success'));
                  });
              }),
            )
            .subscribe();
        }),
      )
      .subscribe();
  }

  onRuleSelect(id: RuleSelectOption): void {
    this.ruleSelected = this.ruleList.find(item => item.id === id.key);

    this.calculateIndexes(true);
    this.setDeliveryIndicator();
  }

  reCalc(data: CalcPaymentRequest): Observable<CalcPayment> {
    return this.service.calc(this.holdingId, this.animalGroupId, this.farmId, this.stage, data);
  }

  downloadRecordDocumentPdf(): void {
    this.addDownloadToQueue(
      this.animalGroupService.getAnimalGroupRecordDocumentDowloadFile(
        this.holdingId,
        this.farmId,
        this.animalGroupId,
      ),
    );
  }

  onPageLeave(leave: boolean): void {
    this.pageLeaveSubscriber.next(leave);
    this.feedbackVisible = false;
  }

  confirmPageLeave(): Observable<boolean> {
    if (this.paymentForm.dirty && !this.canLeave) {
      this.feedbackVisible = true;
      return new Observable<boolean>(subscriber => {
        this.pageLeaveSubscriber = subscriber;
      });
    }
    return of(true);
  }

  hideModal(): void {
    this.showModal = false;
  }

  showConfirm(): void {
    this.showModal = true;
  }

  validadeIndexes(): boolean {
    const data = [];
    this.payment_indexes.controls.forEach(control => {
      if (control.get('value').value) {
        data.push(true);
      } else {
        data.push(false);
      }
    });

    return data.includes(false);
  }

  ValidateErrorForm(): boolean {
    return this.ruleSelected === null
      ? true
      : !(this.price > 0 && this.price !== null ? false : true) &&
        !(this.need_add_desc ? this.additions_desc === '' : this.need_add_desc) &&
        !(this.need_disc_desc ? this.discounts_desc === '' : this.need_disc_desc)
      ? this.fieldsBlocked
      : true;
  }

  private addDownloadToQueue(downloadObservable: Observable<DownloadStatus>): void {
    const t = (key: string): string =>
      this.translateService.instantWithFallback(`agriness.download_feedback.${key}`);
    this.queueManager
      .add(downloadObservable, t('waiting'))
      .pipe(
        tap(({ actions }: QueueItem) => actions.update(t('in_progress'))),
        switchMap(({ payload: download, actions }: QueueItem) => {
          return (download as Observable<DownloadStatus>).pipe(
            tap((status: DownloadStatus) => {
              if (status === DownloadStatus.NO_CONTENT) {
                actions.error(t('no_content'));
              } else {
                actions.success(t('success'));
              }
            }),
            catchError(err => {
              actions.error(t('error'));
              return throwError(err);
            }),
          );
        }),
      )
      .subscribe();
  }

  private setIndexs(payment_index: PaymentIndex[]): void {
    payment_index.forEach(res => {
      this.payment_indexes.controls.forEach(control => {
        if (control.get('name').value === res.name) {
          control.get('range').setValue(res.range);
          control.get('percentage').setValue(res.percentage);
          control.get('total_index_price').setValue(res.total_index_price);
        }
      });
    });
  }

  private setParticipation(participation: Participation): void {
    const control = this.paymentForm.controls['participation'] as FormGroup;
    control.get('total_participation').setValue(participation.total_participation);
    control.get('total_price').setValue(participation.total_price, { emitEvent: false });
    control.get('price_by_head').setValue(participation.price_by_head);
    control.get('total_animal_value').setValue(participation.total_animal_value);
    control.get('price_by_total_weight_gain').setValue(participation.price_by_total_weight_gain);
    control
      .get('total_participation_by_producer')
      .setValue(participation.total_participation_by_producer);
  }

  private setDeliveryIndicator(): void {
    this.paymentForm.controls['delivery_indicator'].setValue(
      this.t.instant(
        'agriness.payment-rule-configuration.main.total_weight_options.' +
          this.ruleSelected.type_weight,
      ),
    );
  }
}
