<div class="column is-narrow border-left pl-4">
  <strong class="is-uppercase text is-small">{{
    'agriness.payment_report.payment_report' | translate
  }}</strong>
  <div class="is-narrow pb-3 pt-1">
    <p class="text is-small has-text-grey-4">
      {{ 'agriness.payment_report.status' | translate }}:
      <strong class="text is-small has-text-grey-4">{{ statusTranslateKey() | translate }}</strong>
    </p>
  </div>
  <div>
    <button
      class="btn btn-primary is-small text is-xsmall"
      [disabled]="
        status == 'loading' ? true : paymentCheck?.has_payment_rule ? !paymentCheck?.has_sale : true
      "
      data-testid="form-group-card"
      [routerLink]="['payment']"
      [queryParams]="{
        animalGroupName: animalGroupName,
        holdingId: holdingId,
        stage: stage,
        total_weight_output: paymentCheck?.total_weight_output,
        total_weight_gain: paymentCheck?.total_weight_gain,
        total_animal: paymentCheck?.total_animal,
        documentId: documentId,
        is_open: isOpen,
        currency: measurmentUnits.currency_unit,
        weight: measurmentUnits.weight_measurement_unit
      }"
    >
      {{
        (documentId ? 'agriness.payment_report.view' : 'agriness.payment_report.form') | translate
      }}
    </button>
  </div>
</div>
