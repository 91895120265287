import { ComfortStrategy } from '@agriness/corp-app/services';
import { LineGraphConfiguration } from '@agriness/corp-app/shared/services/report-section/report-section-line-graph.model';
import { SelectItem } from 'primeng/api';

export enum Report {
  HUMIDITY = 'HUMIDITY',
  TEMPERATURE = 'TEMPERATURE',
  WATER_CONSUMPTION = 'WATER_CONSUMPTION',
  WATER_TEMPERATURE = 'WATER_TEMPERATURE',
}

export interface SelectItemStrategy extends SelectItem {
  value: ComfortStrategy;
}

export const configurationByReport = {
  [Report.HUMIDITY]: {
    title: 'agriness.comfort.avg_humidity',
    graph: {
      category: '',
      series_actual: 'agriness.comfort.humidity',
      series_target: 'agriness.comfort.zone',
      value: 'agriness.comfort.humidity',
    },
    detailedGraphConfiguration: {
      fields: {
        value: {
          min: 0,
          max: 100,
        },
      },
    } as LineGraphConfiguration,
  },
  [Report.TEMPERATURE]: {
    title: 'agriness.comfort.avg_temperature',
    graph: {
      category: '',
      series_actual: 'agriness.comfort.temperature',
      series_target: 'agriness.comfort.zone',
      value: 'agriness.comfort.temperature',
    },
  },
  [Report.WATER_CONSUMPTION]: {
    title: 'agriness.comfort.water_consumption',
    graph: {
      category: '',
      series_actual: 'agriness.comfort.water_consumption',
      series_target: 'agriness.comfort.zone',
      value: 'agriness.comfort.water_consumption',
    },
  },
  [Report.WATER_TEMPERATURE]: {
    title: 'agriness.comfort.title_water_temperature',
    graph: {
      category: '',
      series_actual: 'agriness.comfort.water_temperature',
      series_target: 'agriness.comfort.zone',
      value: 'agriness.comfort.water_temperature',
    },
  },
};
