import { AnimalGroupMortalityValueAccumulated } from '@agriness/corp-app/services';
import {
  Highcharts,
  HighchartsService,
} from '@agriness/corp-app/shared/services/highcharts.service';
import { AgrinessTranslateService } from '@agriness/services';
import { AgFeedbackService, FeedbackEnum, ValueFormatPipe, ValueFormatStyle } from '@agriness/ui';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { isEmpty, defaultsDeep } from 'lodash';

@Component({
  selector: 'corp-sanity-poultry-graph',
  templateUrl: './sanity-poultry-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SanityPoultryGraphComponent implements OnChanges {
  @Input() data: AnimalGroupMortalityValueAccumulated[];
  @Input() dataItemKey: keyof AnimalGroupMortalityValueAccumulated = 'percentage_accumulated';
  @Input() height: string | number;
  @Input() xAxisLabel = 'agriness.sanity.age_x_axis';
  @Input() yAxisLabel: string;
  @Input() noDataLabel: string;
  @Input() color: string;
  @Input() lineColor: string;

  Highcharts;
  chartOptions: Highcharts.Options;
  seriesData: Highcharts.PointOptionsObject[];

  constructor(
    private translate: AgrinessTranslateService,
    private feedbackService: AgFeedbackService,
    private valueFormat: ValueFormatPipe,
    private highchartsService: HighchartsService,
  ) {
    this.Highcharts = highchartsService.getHighchartsInstance();
  }

  ngOnChanges(): void {
    this.updateChartOptions();
  }

  updateChartOptions(): void {
    this.chartOptions = this.getChartOptions();
  }

  hasData(seriesData = this.seriesData): boolean {
    return !isEmpty(seriesData) && seriesData[seriesData.length - 1].y > 0;
  }

  private getChartOptions(): Highcharts.Options {
    this.seriesData = this.data.map((v, i) => ({
      x: i,
      y: v[this.dataItemKey],
    }));

    const hasData = this.hasData(this.seriesData);
    if (!hasData) {
      this.seriesData = [];
    }

    const color = this.color || this.lineColor;
    const lineColor = this.lineColor || this.color;
    const xAxisLabel = this.translate.instant(this.xAxisLabel);
    const yAxisLabel = this.translate.instant(this.yAxisLabel);

    const { valueFormat } = this;

    return this.addDefaultChartOptions({
      chart: {
        type: 'area',
      },
      xAxis: {
        title: {
          text: xAxisLabel,
        },
        visible: hasData,
        labels: {
          formatter: function () {
            return `${this.value}`;
          },
        },
        tickInterval: 7,
        allowDecimals: false,
      },
      yAxis: {
        title: {
          text: yAxisLabel,
        },
        visible: hasData,
        labels: {
          formatter: function () {
            const { value } = this;
            if (value === 0) {
              return '0';
            } else {
              return valueFormat.transform(this.value, ValueFormatStyle.PERCENTAGE);
            }
          },
        },
      },
      tooltip: {
        formatter: function () {
          return [
            `<b>${xAxisLabel}</b>: ${this.x}`,
            `<b>${yAxisLabel}</b>: ${valueFormat.transform(this.y, ValueFormatStyle.PERCENTAGE)}`,
          ].join('<br/>');
        },
      },
      plotOptions: {
        area: {
          pointStart: 0,
          lineWidth: 2,
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
      },
      series: [
        {
          type: 'area',
          name: yAxisLabel,
          data: this.seriesData,
          color,
          lineColor,
        },
      ],
      legend: {
        enabled: false,
      },
    });
  }

  private addDefaultChartOptions(options: Highcharts.Options): Highcharts.Options {
    const base: Partial<Highcharts.Options> = {
      chart: {
        height: this.height,
      },
      title: {
        text: '',
      },
      legend: {
        align: 'right',
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      lang: {
        noData: this.feedbackService.getFeedbackMessage(FeedbackEnum.NOT_RESULT),
      },
    };

    return defaultsDeep(base, options) as Highcharts.Options;
  }
}
