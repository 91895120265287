import { Injectable } from '@angular/core';

import { ApiAbstractService } from '../abstract/api-abstract.service';
import { BaseApiAbstractService } from './base-api-abstract.service';
import { ServicesConfigService } from './services-config.service';

@Injectable()
export class ApiMyAccountService extends ApiAbstractService {
  constructor(baseApiService: BaseApiAbstractService, servicesConfig: ServicesConfigService) {
    super(baseApiService, servicesConfig.apiMyAccount);
  }
}
