import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '../../common/shared.module';
import { AgSelectComponent } from './ag-select.component';

@NgModule({
  imports: [SharedModule, ReactiveFormsModule, FormsModule, NgSelectModule],
  declarations: [AgSelectComponent],
  exports: [AgSelectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgSelectModule {}
