import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class PageLeaveConfirmationGuard implements CanDeactivate<PageLeaveConfirmation> {
  constructor(private readonly router: Router, private readonly location: Location) {}

  canDeactivate(
    component: PageLeaveConfirmation,
    currentRoute: ActivatedRouteSnapshot,
  ): boolean | Observable<boolean> {
    // Forcing the browser location
    // This solution was taken as workaround from angular issue https://github.com/angular/angular/issues/13586#issuecomment-432795720
    return component.confirmPageLeave().pipe(
      switchMap((canLeave: boolean) => {
        if (!canLeave) {
          const currentUrlTree = this.router.createUrlTree([], currentRoute);
          const currentUrl = currentUrlTree.toString();
          this.location.go(currentUrl);
          return of(false);
        }
        return of(true);
      }),
    );
  }
}

export interface PageLeaveConfirmation {
  confirmPageLeave(): Observable<boolean>;
}
