import { SectionModel } from '@agriness/ui';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class PresenceSectionService {
  getSections(): SectionModel[] {
    const prefix = `/${environment.typeProduction}/settings/presence`;
    const sections: SectionModel[] = [
      {
        label: 'agriness.settings.presence.tools.title',
        id: 'section-presence-tools',
        routerLink: `${prefix}/tools`,
      },
    ];
    return sections;
  }
}
