import { AnimalGroupStatus, TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, SelectComponent } from '../corp-filter-abstractions';
import { FixedLoaderService, SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-is-open-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.select.html',
  providers: [{ provide: FilterComponent, useExisting: CorpIsOpenFilterComponent }],
})
export class CorpIsOpenFilterComponent extends SelectComponent implements OnInit {
  @Input()
  animalGroupStatus: AnimalGroupStatus;

  @Input()
  nullable?: boolean;

  @Input()
  initialValue?: 'true' | 'false';

  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(translate, containerService, activeRoute);
    this.assignDefaults(
      'isOpen',
      new SelectLoader(
        new FixedLoaderService(
          [
            ['true', 'agriness.opened'],
            ['false', 'agriness.closed'],
          ],
          translate,
        ),
      ),
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.emptySelectionKey = this.nullable ? 'agriness.filter.is_open.all' : undefined;
  }

  getResetData(): Promise<Record<string, unknown>> {
    return Promise.resolve({
      [this.controlName]: this.getDefaultData(),
    });
  }

  getInitialGroupData(): Promise<Record<string, [unknown]>> {
    const queryValue = this.getQueryParam(this.queryParamName);
    const initialValue: string | null = queryValue ? queryValue : this.getDefaultData();

    return Promise.resolve({
      [this.controlName]: [initialValue],
    });
  }

  private getDefaultData() {
    let initialValue: string | null = null;

    if (this.initialValue) {
      initialValue = this.initialValue;
    } else if (!this.nullable) {
      initialValue = this.animalGroupStatus == AnimalGroupStatus.CLOSED ? 'false' : 'true';
    }

    return initialValue;
  }
}
