import { TypeProductionEnum } from '@agriness/services';

import { ReportFilterType } from '../../model/report-filter.model';
import { ReportMonitorTabsEnum } from '../../model/report.model';

export interface SubReportEnum {
  internalName: string;
  name: string;
  isOpen: boolean;
}

export const subReportsByTabAndProduction: {
  [k in ReportMonitorTabsEnum]?: { [k in TypeProductionEnum]?: SubReportEnum[] };
} = {
  [ReportMonitorTabsEnum.DIAGNOSTIC_TREE]: {
    [TypeProductionEnum.SWINES]: [
      {
        internalName: 'diagnostic_tree',
        name: 'agriness.monitor_diagnostic_tree',
        isOpen: false,
      },
    ],
  },
  [ReportMonitorTabsEnum.INDICATORS]: {
    [TypeProductionEnum.SWINES]: [
      {
        internalName: 'monitor',
        name: 'agriness.reproductive_squad',
        isOpen: false,
      },
      {
        internalName: 'gestation',
        name: 'agriness.gestation',
        isOpen: false,
      },
      {
        internalName: 'maternity',
        name: 'agriness.maternity',
        isOpen: false,
      },
    ],
    [TypeProductionEnum.POULTRY]: [
      {
        internalName: 'monitor',
        name: 'agriness.performance_zootechnical',
        isOpen: false,
      },
    ],
    [TypeProductionEnum.DAIRY]: [
      {
        internalName: 'monitor',
        name: 'agriness.performance_zootechnical',
        isOpen: false,
      },
    ],
  },
};

export const filterIdsByTabAndProduction: {
  [k in ReportMonitorTabsEnum]?: { [k in TypeProductionEnum]?: ReportFilterType[] };
} = {
  [ReportMonitorTabsEnum.INDICATORS]: {
    [TypeProductionEnum.SWINES]: ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'TECHNICIAN'],
    [TypeProductionEnum.POULTRY]: ['COMPANY', 'PERIOD', 'REGION', 'FARM', 'TECHNICIAN'],
    [TypeProductionEnum.DAIRY]: ['COMPANY', 'PERIOD', 'ROUTE', 'FARM', 'TECHNICIAN'],
  },
  [ReportMonitorTabsEnum.DIAGNOSTIC_TREE]: {
    [TypeProductionEnum.SWINES]: ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'TECHNICIAN'],
  },
};
