import { ValueFormatStyle } from '../../../pipe/value-format/value-format.model';

export interface TableColumn {
  field: string;
  header: string;

  checkbox?: boolean;
  id?: string;
  fixed?: boolean;
  sortable?: boolean;
  style?: ValueFormatStyle;
  styleDecimalPlaces?: number;
  tooltip?: string;
  hidden?: boolean;
  width?: string;
}

export const checkboxColumn: TableColumn = {
  field: '',
  header: '',
  width: '30px',
  sortable: false,
  tooltip: null,
  checkbox: true,
  id: 'column-checkbox',
};

export const textColumn: TableColumn = {
  field: '',
  header: '',
  width: '150px',
  sortable: false,
  hidden: false,
  fixed: false,
};

export const actionColumn: TableColumn = {
  field: 'ACTION',
  header: 'agriness.action',
  width: '84px',
  sortable: false,
  hidden: false,
  fixed: false,
};
