<ag-popover
  #popover
  autoClose="outside"
  styleClass="btn-ghost has-round-hover"
  placement="right-top"
  iconClass="icon-track-setting has-text-grey-4"
  [template]="popoverTemplate"
></ag-popover>

<ng-template #popoverTemplate>
  <div class="popover-header py-2C">
    <span class="popover-header-title d-flex justify-content-between align-items-center">
      <span class="d-flex">
        {{ 'agriness.identify.quadrant.visualization' | translate }}
      </span>
      <button (click)="onClose()" class="d-flex btn btn-ghost has-round-hover">
        <span class="icon icon-close has-text-grey-4"></span>
      </button>
    </span>
  </div>

  <form [formGroup]="form" class="columns m-3">
    <div class="column pr-4 border-right">
      <div class="flex-column pb-3">
        <p class="has-text-grey-5 text-small font-weight-bold">
          {{ getIndexName(quadrantsAxes.axis_x.index.key) }}
        </p>
      </div>

      <div class="custom-interval-container">
        <span class="label text is-xsmall pr-2 has-text-grey-4">
          {{ 'agriness.identify.quadrant.bad' | translate }}
        </span>
        <ag-text-field
          inputWidth="80px"
          [formGroup]="form"
          controlName="x_max_readonly"
          [disabled]="true"
        ></ag-text-field>

        <span class="label text is-xsmall pr-2 has-text-grey-4">
          {{ 'agriness.identify.quadrant.good' | translate }}
        </span>
        <ag-number-field
          inputWidth="80px"
          [group]="form"
          controlName="x_max"
          (change)="updateReadOnlyFields()"
        ></ag-number-field>
        <ag-number-field
          inputWidth="80px"
          [group]="form"
          controlName="x_min"
          (change)="updateReadOnlyFields()"
        ></ag-number-field>

        <span class="label text is-xsmall pr-2 has-text-grey-4">
          {{ 'agriness.identify.quadrant.great' | translate }}
        </span>
        <ag-text-field
          inputWidth="80px"
          [formGroup]="form"
          controlName="x_min_readonly"
          [disabled]="true"
        ></ag-text-field>
      </div>
    </div>

    <div class="column pl-4">
      <div class="flex-column pb-3">
        <p class="has-text-grey-5 text-small font-weight-bold">
          {{ getIndexName(quadrantsAxes.axis_y.index.key) }}
        </p>
      </div>

      <div class="custom-interval-container">
        <span class="label text is-xsmall pr-2 has-text-grey-4">
          {{ 'agriness.identify.quadrant.bad' | translate }}
        </span>
        <ag-text-field
          inputWidth="80px"
          [formGroup]="form"
          controlName="y_max_readonly"
          [disabled]="true"
        ></ag-text-field>

        <span class="label text is-xsmall pr-2 flex-row has-text-grey-4">
          {{ 'agriness.identify.quadrant.good' | translate }}
        </span>
        <ag-number-field
          inputWidth="80px"
          [group]="form"
          controlName="y_max"
          (change)="updateReadOnlyFields()"
        ></ag-number-field>
        <ag-number-field
          inputWidth="80px"
          [group]="form"
          controlName="y_min"
          (change)="updateReadOnlyFields()"
        ></ag-number-field>

        <span class="label text is-xsmall pr-2 has-text-grey-4">
          {{ 'agriness.identify.quadrant.great' | translate }}
        </span>
        <ag-text-field
          inputWidth="80px"
          [formGroup]="form"
          controlName="y_min_readonly"
          [disabled]="true"
        ></ag-text-field>
      </div>
    </div>
  </form>

  <div class="popover-footer d-flex align-items-center justify-content-between">
    <button class="btn btn-modal" (click)="onReset()">
      <span class="icon is-18 icon-restore"></span>
    </button>

    <button type="button" class="btn btn-primary is-small ml-3" (click)="onVisualize()">
      {{ 'agriness.identify.quadrant.visualize' | translate }}
    </button>
  </div>
</ng-template>
