import { Pipe, PipeTransform } from '@angular/core';

import { AgrinessTranslateService, TranslateInstant } from './translate.service';

@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {
  constructor(private translateService: AgrinessTranslateService) {}

  transform: TranslateInstant = (key, interpolateParams) => {
    if (!key) {
      return null;
    }
    return this.translateService.instantWithFallback(key, interpolateParams);
  };
}
