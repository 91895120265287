/* eslint-disable */
import { Injectable } from '@angular/core';
import { format, formatISO, parseISO, toDate } from 'date-fns';
import { isString } from 'lodash';

import { UserStorageService } from '../services/domain/user/user-storage.service';

type dateInput = Date | string;

@Injectable()
export class DateService {
  private regexMonthAndDay = /[dm]{2}.[dm]{2}/gi;
  private dateFormat: string;

  constructor(private userStorageService: UserStorageService) {}

  toDate(date: dateInput): Date {
    if (isString(date)) {
      return parseISO(date);
    }
    return toDate(date);
  }

  formatDate(date: dateInput): string {
    return format(this.toDate(date), this.getDateFormat());
  }

  formatAsIsoDate(date: dateInput): string {
    return formatISO(this.toDate(date), { representation: 'date' });
  }

  formatAsMonthAndDay(date: dateInput): string {
    const [customFormat] = this.getDateFormat().match(this.regexMonthAndDay);
    return format(this.toDate(date), customFormat);
  }

  getDateFormat(): string {
    if (!this.dateFormat) {
      const userAgriness = this.userStorageService.getUserAgriness();
      if (userAgriness) {
        this.dateFormat = userAgriness.userProfile.date_format;
        this.dateFormat = this.dateFormat.replace(/D/g, 'd').replace(/Y/g, 'y');
      }
    }
    return this.dateFormat;
  }
}
