import { Injectable, NgZone } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject } from 'rxjs';

import { AuthAbstractService } from '../services/abstract/auth-abstract.service';
import { UserAbstractService } from '../services/abstract/user-abstract.service';
import { ServicesConfigService } from '../services/common/services-config.service';
import { UserStorageService } from '../services/domain/user/user-storage.service';

// @dynamic
@Injectable()
export class AgrinessKeycloakConfigService extends AuthAbstractService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private servicesConfig: ServicesConfigService,
    private keycloakService: KeycloakService,
    private userService: UserAbstractService,
    private zone: NgZone,
    private userStorageService: UserStorageService,
  ) {
    super();
  }

  static init(service: AgrinessKeycloakConfigService): any {
    return () => service.init();
  }

  init(): any {
    return this.zone.runOutsideAngular(() => {
      return this.keycloakService
        .init({
          config: this.servicesConfig.keycloak,
          initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: true,
            silentCheckSsoRedirectUri:
              window.location.origin + '/assets/keycloak-silent-check-sso.html',
          },
          loadUserProfileAtStartUp: false,
          bearerExcludedUrls: ['/assets'],
          bearerPrefix: '',
        })
        .then(() => this.keycloakService.isLoggedIn())
        .then(loggedIn => (loggedIn ? this.userService.initUser() : this.keycloakService.login()))
        .then(() => this.isAuthenticated.next(true));
    });
  }

  logout() {
    this.userStorageService.removeUserAgriness();
    this.keycloakService.logout();
    this.isAuthenticated.next(false);
  }
}
