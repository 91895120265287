import { StageEnum, TypeProductionEnum } from '@agriness/services';

import { ReportFilterType } from '../../model/report-filter.model';

export interface GroupByOption {
  label: string;
  value: string;
}

export interface GroupByWithSubOptions extends GroupByOption {
  beginOptions: GroupByOption[];
}

const groupByPrefix = 'agriness.index_map_generator.group_by.';
const groupByBeginPrefix = 'agriness.index_map_generator.group_by_begin.';
const dayOfTheWeekPrefix = 'agriness.date.week.abbreviated.';

const dayOfTheWeekOptions: GroupByOption[] = [
  createGroupByOption('SUNDAY', dayOfTheWeekPrefix + 'sunday'),
  createGroupByOption('MONDAY', dayOfTheWeekPrefix + 'monday'),
  createGroupByOption('TUESDAY', dayOfTheWeekPrefix + 'tuesday'),
  createGroupByOption('WEDNESDAY', dayOfTheWeekPrefix + 'wednesday'),
  createGroupByOption('THURSDAY', dayOfTheWeekPrefix + 'thursday'),
  createGroupByOption('FRIDAY', dayOfTheWeekPrefix + 'friday'),
  createGroupByOption('SATURDAY', dayOfTheWeekPrefix + 'saturday'),
];

const farmAndTechnicianGroup = [
  createGroupByOption('FARM_ID', groupByPrefix + 'farm'),
  createGroupByOption('TECHNICIAN_ID', groupByPrefix + 'technician'),
];

const defaultGroupByOptions = [
  {
    ...createGroupByOption('ANIMAL_GROUP_ID', groupByPrefix + 'animal_group'),
  },
  {
    ...createGroupByOption('WEEK', groupByPrefix + 'week'),
    beginOptions: dayOfTheWeekOptions,
  },
  {
    ...createGroupByOption('YEAR_MONTH', groupByPrefix + 'month'),
    beginOptions: [
      createGroupByOption('FIRST_DAY', groupByBeginPrefix + 'month_first_day'),
      ...dayOfTheWeekOptions,
    ],
  },
  {
    ...createGroupByOption('YEAR', groupByPrefix + 'year'),
    beginOptions: [
      createGroupByOption('FIRST_DAY', groupByBeginPrefix + 'year_first_day'),
      ...dayOfTheWeekOptions,
    ],
  },
];

export const groupByOptions: {
  [k in TypeProductionEnum]: (GroupByOption | GroupByWithSubOptions)[];
} = {
  [TypeProductionEnum.DAIRY]: [...defaultGroupByOptions],
  [TypeProductionEnum.LAYERS]: [...farmAndTechnicianGroup, ...defaultGroupByOptions],
  [TypeProductionEnum.POULTRY]: [...farmAndTechnicianGroup, ...defaultGroupByOptions],
  [TypeProductionEnum.SWINES]: [...farmAndTechnicianGroup, ...defaultGroupByOptions],
};

function createGroupByOption(value: string, label: string): GroupByOption {
  return {
    label: label,
    value: value.toUpperCase(),
  };
}

export const filterIdsByStage: Record<StageEnum, ReportFilterType[]> = {
  [StageEnum.REPRODUCTIVE]: ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'TECHNICIAN'],
  [StageEnum.NURSERY]: ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'TECHNICIAN', 'IS_OPEN'],
  [StageEnum.FINISHING]: [
    'COMPANY',
    'PERIOD',
    'FARM',
    'REGION',
    'TECHNICIAN',
    'NUTRITION_TYPE',
    'IS_OPEN',
  ],
  [StageEnum.WEAN_TO_FINISH]: [],
  [StageEnum.GROW_OUT]: ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'TECHNICIAN'],
  [StageEnum.MILKING]: ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'TECHNICIAN'],
  [StageEnum.LAYERS_PRODUCTION]: ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'TECHNICIAN'],
};
