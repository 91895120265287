import { Pipe, PipeTransform } from '@angular/core';

import { ValueFormatPipe } from './value-format/value-format.pipe';

@Pipe({
  name: 'currencyFormat',
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(private valueFormat: ValueFormatPipe) {}

  transform(
    value: number,
    locale?: string,
    style?: string,
    minimumFractionDigits?: number,
  ): string {
    return this.valueFormat.transform(value, style, minimumFractionDigits);
  }
}
