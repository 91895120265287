import { Location } from '@angular/common';
import {
  Directive,
  HostListener,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[ag-back]',
})
export class BackDirective implements OnInit {
  constructor(
    private location: Location,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    try {
      const visible = window.history.length > 1;

      if (visible) {
        const view = this.viewContainerRef.createEmbeddedView(this.templateRef);
        const el = view.rootNodes[0];
        el.dataset.testid = 'back';
        this.renderer.listen(el, 'click', () => this.onClick());
      } else {
        this.viewContainerRef.clear();
      }
    } catch (e) {
      console.error('Directive ag-back should be used as *ag-back (structural directive)', e);
    }
  }

  @HostListener('click')
  onClick() {
    this.location.back();
  }
}
