<ng-container *ngIf="type">
  <div class="columns is-mobile is-multiline is-centered flex-fill">
    <div class="column is-full">
      <ag-feedback-icon [type]="type"> </ag-feedback-icon>
    </div>

    <div class="column is-four-fifths-touch is-three-fifths-desktop is-two-fifths-fullhd mb-3">
      <h6 class="title font-weight-bold text-center text-wrap">
        {{ feedBack.msg | translate }}
      </h6>
    </div>
  </div>
</ng-container>
