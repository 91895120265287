import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { Colors, Icons } from '@agriness/assets';

export enum NavigationArrow {
  BACK = 'BACK',
  FORWARD = 'FORWARD',
}

@Component({
  selector: 'guavi-chevron-button',
  templateUrl: './chevron-button.component.html',
  styleUrls: ['./chevron-button.component.scss'],
})
export class ChevronButtonComponent implements OnChanges {
  @Input() disabled = false;
  @Input() direction = NavigationArrow.BACK;

  @Output() onClick = new EventEmitter<void>();

  icon: Icons;
  iconColor: Colors;
  iconSize: number;

  ngOnChanges(): void {
    this.icon = this.direction == NavigationArrow.BACK ? 'arrow-back-ios' : 'arrow-forward-ios';
    this.iconColor = this.disabled ? 'color-grey-light' : 'color-primary';
    this.iconSize = 12;
  }

  handleOnClick(): void {
    this.onClick.emit();
  }
}
