<ag-feedback [type]="feedbackType"></ag-feedback>

<div *ngIf="!feedbackType" class="row">
  <div class="col">
    <div class="card shadow-sm">
      <div class="card-body">
        <div class="card-title d-flex align-items-center">
          {{ sectionCalendarTitle | translate }}
        </div>
        <div>
          <div class="calendar">
            <div class="calendar-filter">
              <ag-week-calendar
                [day]="filterDay"
                [language]="language"
                [dateFormat]="dateFormat"
                labelDateFormat="mediumDate"
                (select)="onChangeDatepicker($event)"
              >
              </ag-week-calendar>
            </div>

            <div class="calendar-controls">
              <guavi-chevron-button
                class="prev"
                [disabled]="!canMove('backward')"
                [direction]="'BACK'"
                (onClick)="prev()"
              >
              </guavi-chevron-button>
              <guavi-chevron-button
                class="next"
                [disabled]="!canMove('forward')"
                [direction]="'FORWARD'"
                (onClick)="next()"
              >
              </guavi-chevron-button>
            </div>
            <div class="calendar-sidebar">
              <div class="calendar-header">
                <ul class="calendar-row">
                  <li class="calendar-item">{{ calendarColumnName | translate }}</li>
                </ul>
              </div>
            </div>
            <div class="calendar-grid calendar-grid--top">
              <div
                class="calendar-header"
                [ngStyle]="{ transform: 'translateX(' + counter * -1 + '00%)' }"
              >
                <ul class="calendar-row calendar-row--top">
                  <ng-container *ngFor="let date of getDays()">
                    <li class="calendar-cell calendar-cell--title">
                      <span class="calendar-title">{{ date.getWeekName() | translate }}</span>
                      <span class="calendar-subtitle">{{ date.getDateTitle() }}</span>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <div>
              <div class="calendar-sidebar">
                <div class="calendar-body">
                  <ng-container *ngFor="let technician of technicians">
                    <ul class="calendar-row">
                      <li class="calendar-item font-weight-bold">
                        {{ technician.getContent() }}
                      </li>
                    </ul>
                  </ng-container>
                </div>
              </div>
              <div class="calendar-grid">
                <div
                  class="calendar-body"
                  [ngStyle]="{ transform: 'translateX(' + counter * -1 + '00%)' }"
                  data-testid="calendar-body"
                >
                  <ng-container *ngFor="let row of rows">
                    <ul class="calendar-row">
                      <ng-container *ngFor="let cell of row.cells">
                        <li
                          class="calendar-cell font-weight-bold"
                          [ngClass]="getCellBackgroundClass(cell)"
                          (click)="modal.displayDialog(cell)"
                        >
                          {{ cell.getContent() }}
                        </li>
                      </ng-container>
                    </ul>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <presence-modal #modal></presence-modal>
    </div>
  </div>
</div>
