const DAYS = {
  SUNDAY: 0,
  SATURDAY: 6,
};

export class CalendarDate extends Date {
  week_names = [
    'agriness.date.week.long.sunday',
    'agriness.date.week.long.monday',
    'agriness.date.week.long.tuesday',
    'agriness.date.week.long.wednesday',
    'agriness.date.week.long.thursday',
    'agriness.date.week.long.friday',
    'agriness.date.week.long.saturday',
  ];
  getWeekName(): string {
    return this.week_names[this.getDay()];
  }

  getDateTitle(): string {
    return `${this.getDate()}/${this.getMonth() + 1}`;
  }

  isWeekend(): boolean {
    return this.getDay() === DAYS.SUNDAY || this.getDay() === DAYS.SATURDAY;
  }
}
