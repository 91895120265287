import { Status } from './common.model';
import { Holding } from './holding.model';
import { TypeProductionEnum } from './system.model';

export class UserPreferences {
  constructor(public currentHolding?: Holding) {}
}

export class UserAgriness {
  private _userPreferences: UserPreferences;

  constructor(
    public userProfile?: User,
    userPreferences?: UserPreferences,
    public holdings?: Holding[],
  ) {
    this._userPreferences = userPreferences;
  }

  get userPreferences(): UserPreferences {
    if (!(this._userPreferences instanceof UserPreferences)) {
      this._userPreferences = Object.assign(new UserPreferences(), this._userPreferences);
    }
    return this._userPreferences;
  }

  set userPreferences(value: UserPreferences) {
    this._userPreferences = value;
  }
}

export class User {
  constructor(
    public id?: string,
    public name?: string,
    public nickname?: string,
    public email?: string,
    public secondary_email?: string,
    public phone?: string,
    public gender?: GenderEnum,
    public job?: string,
    public birthday?: string,
    public address?: string,
    public language?: LanguageEnum,
    public date_format?: DateFormat,
    public decimal_separator?: DecimalSeparatorEnum,
    public status?: string,
    public created_date?: string,
    public updated_date?: string,
    public last_access?: string,
    public first_access?: string,
    public photo?: string,
  ) {}
}

export class UserRelation {
  constructor(
    public id?: string,
    public status?: Status,
    public user?: User,
    public relation_type?: RelationType,
  ) {}
}

export class RelationType {
  constructor(public description?: string, public id?: string, public name?: string) {}
}

export type DateFormat = 'DD/MM/YYYY' | 'MM/DD/YYYY' | 'YYYY/MM/DD';

export enum GenderEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export enum LanguageEnum {
  PTBR = 'pt-BR',
  ENUS = 'en-US',
  ESAR = 'es-AR',
}

export enum DecimalSeparatorEnum {
  BRAZILIAN = 'BRAZILIAN',
  ENGLISH = 'ENGLISH',
}

export const DecimalPointByDecimalSeparator: { [k in DecimalSeparatorEnum]: string } = {
  [DecimalSeparatorEnum.BRAZILIAN]: ',',
  [DecimalSeparatorEnum.ENGLISH]: '.',
};

export const ThousandsPointByDecimalSeparator: { [k in DecimalSeparatorEnum]: string } = {
  [DecimalSeparatorEnum.BRAZILIAN]: '.',
  [DecimalSeparatorEnum.ENGLISH]: ',',
};

export enum StageEnum {
  FINISHING = 'finishing',
  REPRODUCTIVE = 'reproductive',
  NURSERY = 'nursery',
  WEAN_TO_FINISH = 'wean-to-finish',
  GROW_OUT = 'grow-out',
  MILKING = 'milking',
  LAYERS_PRODUCTION = 'layers-production',
}

export enum AnimalGroupStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export enum SiteEnum {
  SITE_1 = 'site_1',
  SITE_2 = 'site_2',
  SITE_3 = 'site_3',
  SITE_4 = 'site_4',
}

export const SiteSystemType: { [K1 in TypeProductionEnum]: { [K2 in SiteEnum]?: StageEnum } } = {
  [TypeProductionEnum.SWINES]: {
    [SiteEnum.SITE_1]: StageEnum.REPRODUCTIVE,
    [SiteEnum.SITE_2]: StageEnum.NURSERY,
    [SiteEnum.SITE_3]: StageEnum.FINISHING,
    [SiteEnum.SITE_4]: StageEnum.WEAN_TO_FINISH,
  },

  [TypeProductionEnum.POULTRY]: {
    [SiteEnum.SITE_3]: StageEnum.GROW_OUT,
  },

  [TypeProductionEnum.DAIRY]: {
    [SiteEnum.SITE_3]: StageEnum.MILKING,
  },

  [TypeProductionEnum.LAYERS]: {
    [SiteEnum.SITE_3]: StageEnum.LAYERS_PRODUCTION,
  },
};

export enum UserHoldingRelationType {
  OWNER = 'OWNER',
  PARTNER = 'PARTNER',
  EMPLOYEE = 'EMPLOYEE',
  CONSULTING = 'CONSULTING',
}

export const userHoldingRelationByName: { [k: string]: UserHoldingRelationType } = {
  DONO: UserHoldingRelationType.OWNER,
  PARCEIRO: UserHoldingRelationType.PARTNER,
  FUNCIONÁRIO: UserHoldingRelationType.EMPLOYEE,
  CONSULTA: UserHoldingRelationType.CONSULTING,
};
