import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { filter, tap, delay, mergeMap } from 'rxjs/operators';

import { SERVICE_DATA, QueueManagerStatus, QueueManagerUiItem } from './ag-queue-manager.model';

@Component({
  templateUrl: './ag-queue-manager.component.html',
  styleUrls: ['./ag-queue-manager.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgQueueManagerComponent implements OnInit, OnDestroy {
  constructor(@Inject(SERVICE_DATA) public serviceData: any) {}

  items: QueueManagerUiItem[] = [];
  queuedItems: QueueManagerUiItem[] = [];
  currentUpdatesSubscription: Subscription;

  statusIconClass: { [k in QueueManagerStatus]: string } = {
    [QueueManagerStatus.IN_PROGRESS]: 'spinner',
    [QueueManagerStatus.WAITING]: 'icon-clock',
    [QueueManagerStatus.SUCCESS]: 'icon-check',
    [QueueManagerStatus.ERROR]: 'icon-error',
    [QueueManagerStatus.NO_STATUS]: '',
  };

  statusClass: { [k in QueueManagerStatus]: string } = {
    [QueueManagerStatus.IN_PROGRESS]: 'in-progress',
    [QueueManagerStatus.WAITING]: 'waiting',
    [QueueManagerStatus.SUCCESS]: 'success',
    [QueueManagerStatus.ERROR]: 'error',
    [QueueManagerStatus.NO_STATUS]: '',
  };

  TOAST_DISMISS_DELAY = 3000;

  ngOnInit() {
    this.currentUpdatesSubscription = this.serviceData.currentUpdates
      .pipe(
        filter(item => Boolean(item)),
        tap((item: QueueManagerUiItem) => {
          this.items = [item];
        }),
        mergeMap((item: QueueManagerUiItem) =>
          of(item).pipe(
            filter(
              (it: QueueManagerUiItem) =>
                it.status === QueueManagerStatus.SUCCESS || it.status === QueueManagerStatus.ERROR,
            ),
            delay(this.TOAST_DISMISS_DELAY),
            tap((dismissableItem: QueueManagerUiItem) => {
              this.items = this.items.filter(i => i !== dismissableItem);
            }),
          ),
        ),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.currentUpdatesSubscription.unsubscribe();
  }

  filterNoStatus(items: QueueManagerUiItem[]): QueueManagerUiItem[] {
    return items.filter(item => item.status != QueueManagerStatus.NO_STATUS);
  }
}
