import { TargetIndexAbstractService } from '@agriness/corp-app/services';
import { TRANSLATE_INSTANT, TranslateInstant, StageEnum } from '@agriness/services';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, MultiSelectComponent } from '../corp-filter-abstractions';
import { SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-target-kpi-list-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.multiselect.html',
  providers: [{ provide: FilterComponent, useExisting: CorpTargetKPIListFilterComponent }],
})
export class CorpTargetKPIListFilterComponent extends MultiSelectComponent implements OnInit {
  @Input() stage: StageEnum;
  queryParamWithId = false;
  dataKey = 'index_id';
  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
    private serviceIndex: TargetIndexAbstractService,
  ) {
    super(translate, containerService, activeRoute);
  }

  ngOnInit(): void {
    this.assignDefaults('indexes', new SelectLoader(this.serviceIndex, { stage: this.stage }));
    super.ngOnInit();
  }
}
