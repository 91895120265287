<ag-table
  #dt
  *ngIf="filteredProducerList.length > 0"
  [frozenColumns]="frozenColumnsTable"
  [columns]="scrollableColumnsTable"
  [data]="filteredProducerList"
  [totalRecords]="filteredProducerList.length"
  (sort)="onSort($event)"
>
  <ng-template pTemplate="bodycell" let-col="column" let-data="data" let-rowData="rowData">
    <td [ngSwitch]="data.type" [agTooltip]="showTooltip(col, data.type) ? data.value : null">
      <a
        *ngSwitchCase="'link'"
        [routerLink]="rowData.route.value"
        target="_blank"
        class="font-weight-bold link"
      >
        {{ rowData[col.field]?.value }}
        <span class="icon is-12 icon-open-new-tab-small"></span>
      </a>
      <span *ngSwitchCase="'quadrant'">
        <span
          class="icon icon-good is-14 group-card-icon"
          [ngClass]="quadrantColors[rowData[col.field]?.value]"
        ></span>
        {{ quadrantNames[rowData[col.field]?.value] }}
      </span>
      <span *ngSwitchDefault>
        <ng-container [ngSwitch]="col.field">
          <corp-record-list-farm-link
            *ngSwitchCase="'FARM_NAME'"
            [animalGroup]="{
              farm_name: rowData.object.farm_name.index_value,
              farm_id: rowData.object.farm_id.index_value
            }"
            service="S4Farm"
          ></corp-record-list-farm-link>

          <ag-table-cell-content
            *ngSwitchDefault
            [data]="rowData[col.field]"
            [ngClass]="{ 'font-weight-bold': col.field === 'PRODUCER_NAME' }"
          >
          </ag-table-cell-content>
        </ng-container>
      </span>
    </td>
  </ng-template>
</ag-table>
