import { TypeProductionEnum } from '../models/system.model';
import { StageEnum } from '../models/user.model';

export enum EventCategory {
  SITE = 'sitios',
  ANALYSIS = 'analisar',
}

export enum EventName {
  SITE_VIEW = 'visualizacao_sitio',
  FILTERS_USED = 'utilizacao_filtros',
  CLICK_PREFERENCE_SITE_1 = 'cliques_preference_sitio_1',
  FARM_LIST = 'lista_de_granjas',
  ANIMALGROUP_LIST = 'lista_de_lotes',
  QUADRANTS = '9_quadrantes',
  CLICK_KPI = 'cliques_kpis_sitio_1',
  ANIMALGROUP_RECORD = 'ficha_de_lote',
}

export enum PropertyName {
  FILTERS = 'filtro',
  VIEW = 'visualizacao',
  TOTAL_VIEWS = 'visualizacao_total',
  KPIS_PREFERENCE_USED = 'kpis_preference_utilizados',
  VIEW_FARM_LIST = 'visualizacao_lista_granja',
  VIEW_ANIMALGROUP_LIST = 'visualizacao_lote',
  EXPORT_CSV = 'exportacao_dados',
  TABLE_VIEW = 'visualizacao_tabela',
  SIMULATE_USED = 'utilizacao_simular_metas',
  KPI = 'kpi',
  KPI_ORDER = 'ordem_kpis',
  VIEW_ANIMALGROUP_RECORD = 'visualizacao_lote_pela_ficha_lote',
}

export enum SiteName {
  SITE_1 = 'sitio_1',
  SITE_2 = 'sitio_2',
  SITE_3 = 'sitio_3',
  SITE_4 = 'sitio_4',
}

export const SiteByStage: { [K1 in TypeProductionEnum]: { [K2 in StageEnum]?: SiteName } } = {
  [TypeProductionEnum.SWINES]: {
    [StageEnum.REPRODUCTIVE]: SiteName.SITE_1,
    [StageEnum.NURSERY]: SiteName.SITE_2,
    [StageEnum.FINISHING]: SiteName.SITE_3,
    [StageEnum.WEAN_TO_FINISH]: SiteName.SITE_4,
  },

  [TypeProductionEnum.POULTRY]: {
    [StageEnum.GROW_OUT]: SiteName.SITE_3,
  },

  [TypeProductionEnum.DAIRY]: {
    [StageEnum.MILKING]: SiteName.SITE_3,
  },

  [TypeProductionEnum.LAYERS]: {
    [StageEnum.LAYERS_PRODUCTION]: SiteName.SITE_3,
  },
};
