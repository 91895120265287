import { Component } from '@angular/core';
import { environment } from '@env/environment';

import { generalTabs } from './tools.model';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
})
export class ToolsComponent {
  translationContext = 'agriness.settings.presence.tools';
  tabs = generalTabs[environment.typeProduction];
}
