import { SectionModel, SectionService } from '@agriness/ui';
import { Component } from '@angular/core';

import { SectionAbstractComponent } from '../shared/component/section-abstract.component';
import { NurseryService } from './nursery.service';

@Component({
  templateUrl: './nursery.component.html',
})
export class NurseryComponent extends SectionAbstractComponent {
  constructor(protected sectionService: SectionService, protected nurseryService: NurseryService) {
    super(sectionService);
  }

  protected getSections(): SectionModel[] {
    return this.nurseryService.getSections();
  }
}
