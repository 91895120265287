import { defaultProducerOrder, Producer, ProducerFilter } from '@agriness/corp-app/services';
import { ProducerService } from '@agriness/corp-app/services/producer/producer.service';
import { CorpFiltersContainerComponent } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.component';
import { CorpFiltersContainerService } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.service';
import {
  ActionEnum,
  CorpRecordTableComponent,
} from '@agriness/corp-app/shared/component/corp-record-table/corp-record-table.component';
import {
  ReportFilterQueryKeys,
  ReportFilter,
} from '@agriness/corp-app/shared/model/report-filter.model';
import { PaginationRequest } from '@agriness/corp-app/shared/model/table.model';
import { TypeProductionEnum, TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, ViewChild, Inject } from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

import { DeletionTableData } from '../../../deletion-table/deletion-table.model';
import { ProducerEditorComponent } from './producer-editor/producer-editor.component';
import { producersDeletionTableFields, producersTableColumns } from './producers.model';

@Component({
  templateUrl: './producer.component.html',
  providers: [CorpFiltersContainerService.provider],
})
export class ProducerComponent {
  @ViewChild('filtersContainer', { static: true }) reportFilter: CorpFiltersContainerComponent;
  @ViewChild('table', { static: true }) table: CorpRecordTableComponent<Producer>;
  @ViewChild('editor') producerEditor: ProducerEditorComponent;

  translationContext = 'agriness.settings.producers';

  systemType: TypeProductionEnum;
  totalRecords: number;
  rows = 10;
  loading = false;
  currentFilter: ProducerFilter = {};
  availableActions = [ActionEnum.EDIT, ActionEnum.DELETE];
  error: boolean;

  editorModalVisible = false;
  editorModalStatus = ModalStatusEnum.DEFAULT;
  selectedProducer: Producer;
  deletionModalVisible = false;
  deletionModalStatus = ModalStatusEnum.DEFAULT;
  deletionTableData: DeletionTableData;

  filterEntity: keyof ReportFilter = 'producer';
  queryParamName: ReportFilterQueryKeys = 'producer_name';

  tableColumns = producersTableColumns;
  producers: Producer[];
  defaultOrdering = defaultProducerOrder;

  private queryCorpOnly = true;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private producerService: ProducerService,
  ) {}

  createProducer(): void {
    const formValues = this.producerEditor.getProducerForm();
    this.editorModalStatus = ModalStatusEnum.LOADING;

    this.producerService
      .save(formValues)
      .pipe(
        first(),
        map(() => (this.editorModalStatus = ModalStatusEnum.SUCCESS)),
        catchError(({ error }) => {
          this.editorModalStatus = ModalStatusEnum.FAILED;
          return throwError(error);
        }),
      )
      .subscribe(() => this.filter());
  }

  filter(): void {
    this.table.reset();
  }

  filterReset(): void {
    this.reportFilter.onFilter();
  }

  onAddProducer(): void {
    this.selectedProducer = null;
    this.editorModalVisible = true;
  }

  onDeletionModalClose(): void {
    this.selectedProducer = null;
    this.deletionModalVisible = false;
    this.deletionModalStatus = ModalStatusEnum.DEFAULT;
  }

  onDeletionModalDelete(): void {
    this.deletionModalStatus = ModalStatusEnum.LOADING;

    this.producerService.delete(this.selectedProducer).subscribe({
      complete: () => {
        this.deletionModalStatus = ModalStatusEnum.SUCCESS;
        this.filter();
      },
      error: error => {
        this.deletionModalStatus = ModalStatusEnum.FAILED;
        throw error;
      },
    });
  }

  onItemAction(obj: { action: ActionEnum; item: Producer }): void {
    switch (obj.action) {
      case ActionEnum.EDIT:
        this.selectedProducer = obj.item;
        this.editorModalVisible = true;
        break;
      case ActionEnum.DELETE:
        this.selectedProducer = obj.item;
        this.updateDeletionTableData(this.selectedProducer);
        this.deletionModalVisible = true;
    }
  }

  onPageRequest(request: PaginationRequest): void {
    this.loading = true;
    this.updateCurrentFilter(request);
    this.loadProducers();
  }

  onEditorClosed(): void {
    this.selectedProducer = null;
    this.editorModalVisible = false;
    this.editorModalStatus = ModalStatusEnum.DEFAULT;
  }

  shouldBlockAction(obj: { action: ActionEnum; item: Producer }): boolean {
    if (obj.action == ActionEnum.DELETE) {
      return obj.item.farm_count > 0 || obj.item.animalgroup_count > 0;
    }
    return false;
  }

  updateProducer(): void {
    if (this.selectedProducer) {
      const { id } = this.selectedProducer;
      const formValues = this.producerEditor.getProducerForm();
      this.editorModalStatus = ModalStatusEnum.LOADING;

      this.producerService
        .update(formValues, id)
        .pipe(
          first(),
          map(() => (this.editorModalStatus = ModalStatusEnum.SUCCESS)),
          catchError(({ error }) => {
            this.editorModalStatus = ModalStatusEnum.FAILED;
            return throwError(error);
          }),
        )
        .subscribe(() => this.filter());
    }
  }

  private loadProducers(): Subscription {
    return this.producerService
      .list({ ...this.currentFilter, queryCorpOnly: this.queryCorpOnly })
      .pipe(
        first(),
        map(({ count, results }) => {
          this.totalRecords = count;
          this.loading = false;
          this.producers = results;
        }),
        catchError(error => {
          this.error = true;
          return throwError(error);
        }),
      )
      .subscribe();
  }

  private updateCurrentFilter(request: PaginationRequest): void {
    const name = this.reportFilter.getCurrentFilterInQueryFormat().producer_name;
    const has_farm = {
      has_farm: this.reportFilter.getCurrentFilterInQueryFormat().has_farm,
    };

    this.currentFilter = {
      ...this.currentFilter,
      ...request,
      name,
      queryCorpOnly: this.queryCorpOnly,
      ...has_farm,
    };
  }

  private updateDeletionTableData(producer: Producer): void {
    this.deletionTableData = producersDeletionTableFields.map(field => {
      return {
        field: this.t(`agriness.settings.producers.table.${field}`),
        value: producer[field] as string,
      };
    });
  }
}
