import { TypeProductionEnum } from '@agriness/services';
import { TabModel } from '@agriness/ui';

const basicTabs: TabModel[] = [
  {
    label: 'agriness.settings.presence.tools.tabs.checklist.title',
    id: 'tab-checklist',
    routerLink: `checklist`,
  },
];

export const generalTabs: { [key in TypeProductionEnum]: TabModel[] } = {
  [TypeProductionEnum.SWINES]: basicTabs,
  [TypeProductionEnum.POULTRY]: basicTabs,
  [TypeProductionEnum.DAIRY]: basicTabs,
  [TypeProductionEnum.LAYERS]: [],
};
