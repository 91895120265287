import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { AgGridContainerComponent } from './ag-grid-container.component';
import { AgGridFrameComponent } from './ag-grid-frame.component';

@NgModule({
  declarations: [AgGridFrameComponent, AgGridContainerComponent],
  imports: [CommonModule, SharedModule],
  exports: [AgGridFrameComponent, AgGridContainerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgGridModule {}
