<div class="base horizontal-flex" *ngIf="isHorizontal" [ngClass]="reverseClass()">
  <div class="horizontal-left">
    <span
      class="arrow arrow-up"
      *ngIf="(!isReverse && arrowStart) || (isReverse && arrowEnd)"
    ></span>
  </div>
  <div class="horizontal-center"></div>
  <div class="horizontal-right">
    <span
      class="arrow arrow-down"
      *ngIf="(isReverse && arrowStart) || (!isReverse && arrowEnd)"
    ></span>
  </div>
</div>

<div class="base vertical-flex" *ngIf="!isHorizontal" [ngClass]="reverseClass()">
  <div class="vertical-top">
    <span class="arrow arrow-left" *ngIf="arrowStart"></span>
  </div>
  <div class="vertical-mid"></div>
  <div class="vertical-bottom">
    <span class="arrow arrow-right" *ngIf="arrowEnd"></span>
  </div>
</div>
