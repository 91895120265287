import {
  Component,
  ChangeDetectionStrategy,
  ContentChild,
  ContentChildren,
  QueryList,
  AfterContentInit,
} from '@angular/core';

import { AgStepComponent } from './ag-step.component';

@Component({
  selector: 'ag-steps',
  templateUrl: './ag-steps.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgStepsComponent implements AfterContentInit {
  @ContentChild(AgStepComponent, { static: true }) firstStep: AgStepComponent;
  @ContentChildren(AgStepComponent) steps: QueryList<AgStepComponent>;

  ngAfterContentInit(): void {
    this.steps.forEach(
      (step, i) => (step.indicator = step.indicator != null ? step.indicator : i + 1),
    );
    if (this.firstStep) {
      this.firstStep.focus();
    }
  }
}
