import { ReportEnum } from '@agriness/corp-app/services';
import { StageEnum } from '@agriness/services';
import { AgrinessTranslateService } from '@agriness/services';
import { CardModel, CardModelPerformances, FeedbackEnum } from '@agriness/ui';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  OnInit,
} from '@angular/core';

import { IndexTranslateService } from '../../services/index-translate.service';
import { CorpPreferenceToggleColumnComponent } from '../corp-preference-toggle-column/corp-preference-toggle-column.component';
import { CardsModel } from './corp-report-cards.model';

@Component({
  selector: 'corp-report-cards',
  templateUrl: './corp-report-cards.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpReportCardsComponent implements AfterViewInit, OnInit {
  @ViewChild('preferences', { static: true })
  preferences: CorpPreferenceToggleColumnComponent;

  @Input() feedbackType: FeedbackEnum;
  @Input() performances: CardsModel[] = [];
  @Input() preferenceLabelPrefix: string;
  @Input() report: ReportEnum;
  @Input() sectionName: string;
  @Input() stage: StageEnum;

  @Output() getPreferences = new EventEmitter<CorpPreferenceToggleColumnComponent>();
  @Output() preferenceChange = new EventEmitter<string>();

  helpText?: string;
  preferenceLabelProvider = this.getIndexName.bind(this) as (indexKey: string) => string;

  constructor(
    private indexTranslate: IndexTranslateService,
    private translateService: AgrinessTranslateService,
  ) {}

  ngAfterViewInit(): void {
    this.getPreferences.emit(this.preferences);
  }

  ngOnInit(): void {
    const helpTranslateKey = 'agriness.monitor.help';
    const helpText = this.translateService.instant(helpTranslateKey);

    if (helpTranslateKey !== helpText) {
      this.helpText = helpText;
    }
  }

  isComplexCard(card: CardModel | CardModelPerformances): card is CardModelPerformances {
    return (card != null && 'cardPerformances' in card) || 'barChart' in card;
  }

  hasComplexCard(): boolean {
    return this.performances.some(p => p.cards.some(card => this.isComplexCard(card)));
  }

  onPreferenceChange(): void {
    this.preferenceChange.next();
  }

  hasData(): boolean {
    return this.performances.length > 0;
  }

  private getIndexName(indexKey: string): string {
    return this.indexTranslate.instant(indexKey, this.preferenceLabelPrefix, this.stage);
  }
}
