import { DateService, TypeProductionEnum } from '@agriness/services';
import { Injectable } from '@angular/core';
import { omit, keys, head, get } from 'lodash';

import { PerformanceItems } from '../models/performance.model';

@Injectable()
export class PerformanceAnalysisFormatterService {
  private periodSeparator = ' - ';
  private fieldSeparator = '-';

  constructor(private dateService: DateService) {}

  formatPeriods(performances: PerformanceItems, production: TypeProductionEnum): PerformanceItems {
    const periods = keys(head(performances)).filter(this.isPeriodKey.bind(this));
    const formattedPeriods = this.getFormattedPeriods(periods, production);

    return performances.map(item =>
      periods.reduce((acc, period) => ({ ...acc, [formattedPeriods[period]]: item[period] }), {
        ...omit(item, periods),
      }),
    );
  }

  private isPeriodKey(key: string): boolean {
    return key.includes(this.periodSeparator);
  }

  private getFormattedPeriods(
    periods: string[],
    production: TypeProductionEnum,
  ): Record<string, string> {
    return periods.reduce(
      (acc, key) => ({
        ...acc,
        [key]: this.getDateFormatByTypeProduction(key, production),
      }),
      {},
    );
  }

  private getDateFormatByTypeProduction(period: string, production: TypeProductionEnum): string {
    const [start, end] = period.split(this.periodSeparator);
    const fallback = `${this.dateService.formatDate(start)} - ${this.dateService.formatDate(end)}`;

    const formatByProduction = {
      [TypeProductionEnum.DAIRY]: `${this.getYear(start)} - ${this.getYear(end)}`,
    };

    return get(formatByProduction, production, fallback) as string;
  }

  private getYear(date: string): string {
    return head(date.split(this.fieldSeparator));
  }
}
