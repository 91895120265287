<ag-grid-container [rows]="rows" [cols]="cols">
  <ag-grid-frame
    *ngFor="let line of lines"
    [rowStart]="line.start.row"
    [colStart]="line.start.col"
    [rowEnd]="line.end.row"
    [colEnd]="line.end.col"
  >
    <div style="width: calc(100% + 1px); height: calc(100% + 1px);">
      <ag-line
        [isHorizontal]="lineIsHorizontal(line)"
        [isReverse]="lineIsReverse(line)"
        [arrowStart]="lineHasArrowStart(line)"
        [arrowEnd]="lineHasArrowEnd(line)"
      ></ag-line>
    </div>
  </ag-grid-frame>
  <ag-grid-frame
    *ngFor="let node of nodes"
    [rowStart]="node.position.row"
    [colStart]="node.position.col"
    [rowEnd]="node.position.row + nodeSize.row"
    [colEnd]="node.position.col + nodeSize.col"
  >
    <ag-card [data]="node.card" [link]="node.card.link"></ag-card>
  </ag-grid-frame>
</ag-grid-container>
