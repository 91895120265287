import { StageEnum } from '@agriness/services';
import { CardModel, CardModelPerformances } from '@agriness/ui';

import { AnimalGroupTab } from '../../record/animal-group/animal-group.model';

export interface MonitorPerformance {
  name?: string;
  cards?: Array<CardModel | CardModelPerformances>;
}

const openAnimalGroupTab = {
  router: 'open',
  translateKey: 'agriness.open_animal_group',
};

const closedAnimalGroupTab = {
  router: 'closed',
  translateKey: 'agriness.closed_animal_group',
};

export const animalGroupTabsByStage: { [k in StageEnum]?: AnimalGroupTab[] } = {
  [StageEnum.FINISHING]: [openAnimalGroupTab, closedAnimalGroupTab],
  [StageEnum.WEAN_TO_FINISH]: [openAnimalGroupTab, closedAnimalGroupTab],
  [StageEnum.GROW_OUT]: [openAnimalGroupTab, closedAnimalGroupTab],
  [StageEnum.MILKING]: [closedAnimalGroupTab],
};
