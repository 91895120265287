import { textColumn, actionColumn } from '@agriness/ui';

import { commonDeletionTableFields } from '../../deletion-table/deletion-table.model';

const ruleKey = 'agriness.settings.rules.table';

export const rulesTableColumns = [
  {
    ...actionColumn,
    width: '70px',
    fixed: true,
  },
  {
    ...textColumn,
    field: 'name',
    header: `${ruleKey}.name`,
    width: '300px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'stage_name',
    header: `${ruleKey}.stage`,
    width: '300px',
    sortable: true,
    fixed: true,
  },
];

export const rulesDeletionTableFields: string[] = [...commonDeletionTableFields];
