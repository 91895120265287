import {
  AnimalGroupMonitorPerformanceByPeriod,
  AnimalGroupPerformanceByPeriod,
  Performance,
  Period,
  FilterMonitor,
  MonitorAbstractService,
  ReportEnum,
} from '@agriness/corp-app/services';
import { StageEnum, UserStorageService } from '@agriness/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { FeedbackEnum, IndexesTableCellLink, TargetStatus } from '@agriness/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { EMPTY } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { CorpReportComponent } from '../../model/report.model';
import { LoaderUserPreference } from '../loader-user-preference';
import { PerformanceByPeriod } from './corp-report-cards-by-period.model';

@Component({
  selector: 'corp-report-cards-by-period',
  templateUrl: './corp-report-cards-by-period.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpReportCardsByPeriodComponent extends LoaderUserPreference
  implements CorpReportComponent {
  @Input() sectionName: string;
  @Input() stage: StageEnum;
  @Input() report: ReportEnum;
  @Input() filter: FilterMonitor;
  @Input() indexLabelPrefix = 'agriness.monitor.performances.';
  @Input() cellLink: IndexesTableCellLink;

  performances: PerformanceByPeriod[] = [];

  feedbackType = FeedbackEnum.LOADING;

  combinedPerformances: string[][] = [['COUNT_ANIMAL_GROUP', 'BALANCE']];

  totalizedPerformances = ['COUNT_ANIMAL_GROUP'];
  linkedPerformances = ['COUNT_ANIMAL_GROUP'];
  linkedTarget: Record<string, string> = { AVERAGE_WEIGHT: 'AVERAGE_WEIGHT_EFFICIENCY' };

  constructor(
    protected userStorageService: UserStorageService,
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private monitorService: MonitorAbstractService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(userStorageService);
  }

  loadPreferences(): Observable<null> {
    return EMPTY;
  }

  loadPerformances(
    filter: FilterMonitor,
    withFeedback = true,
  ): Observable<AnimalGroupMonitorPerformanceByPeriod> {
    this.filter = filter;
    this.setPerformances({ performances: [], periods: [] });
    if (withFeedback) {
      this.feedbackType = FeedbackEnum.LOADING;
    }

    return this.monitorService
      .getMonitorPerformanceByPeriod({
        holdingId: this.holdingId,
        stage: this.stage,
        filter: this.filter,
      })
      .pipe(
        tap(response => this.setPerformances(response)),
        tap(() => {
          this.feedbackType = !this.hasData() ? FeedbackEnum.NOT_RESULT_SEARCH : null;
        }),
        catchError(err => {
          console.error(`error on '${this.report}' load`, err);
          this.feedbackType = FeedbackEnum.ERROR;
          return throwError(err);
        }),
        finalize(() => this.changeDetectorRef.markForCheck()),
      );
  }

  hasData(): boolean {
    return this.performances.length > 0;
  }

  private setPeriodLabel(numberOfPeriods: number, index: number): string {
    const isLastPeriod = numberOfPeriods === index + 1;
    const periodInDays = this.t(`agriness.monitor.period.${numberOfPeriods}`);
    const periodNumbers = periodInDays.replace(/\D+/g, '');
    const label = isLastPeriod
      ? `agriness.monitor.period.more_than`
      : `agriness.monitor.period.${index + 1}`;
    return this.t(label, { days: periodNumbers });
  }

  private classificationPeriodLink(
    performance: AnimalGroupPerformanceByPeriod,
    period: Period,
    target: TargetStatus,
  ) {
    const targetStatusKpi = this.linkedTarget[performance.index_name];

    if (!targetStatusKpi) {
      return null;
    }

    return {
      route: this.cellLink.url,
      queryParams: {
        ...this.cellLink.queryParams,
        ...this.filter,
        ...period,
        is_open: true,
        target_status_kpi: targetStatusKpi,
        target_status: target,
      },
    };
  }

  private calculatePerformancePeriodItems(
    performance: AnimalGroupPerformanceByPeriod,
    performanceOfPeriod: Performance,
    period: Period,
  ) {
    const { animal_group_count_by_target } = performanceOfPeriod;

    if (!animal_group_count_by_target) {
      return;
    }

    const targetTotal = Object.values(animal_group_count_by_target).reduce(
      (total, value) => (value ? total + value : total),
      0,
    );

    return Object.entries(animal_group_count_by_target).map(
      ([target, value]: [TargetStatus, number]) => {
        const percentage = value >= 0 && targetTotal > 0 ? (value / targetTotal) * 100 : 0.0;
        const link =
          percentage > 0 ? this.classificationPeriodLink(performance, period, target) : {};

        return {
          description: this.t('agriness.monitor.target_percentage_description', {
            value,
            percentage: percentage.toFixed(2),
          }),
          label: this.t(`agriness.${target}`),
          measurementUnit: 'percentage',
          decimalPlaces: 2,
          status: target,
          percentage,
          ...link,
        };
      },
    );
  }

  private buildPerformancePeriods(performance: AnimalGroupPerformanceByPeriod, periods: Period[]) {
    const common = {
      label: performance.index_name,
      measurementUnit: performance.measurement_unit,
      decimalPlaces: performance.decimal_places,
    };

    const getPeriodValues = (performanceOfPeriod: Performance) => {
      const values = [
        {
          ...common,
          value: performanceOfPeriod?.index_value,
        },
      ];
      if (performanceOfPeriod?.target_value != null) {
        values.push({
          ...common,
          value: performanceOfPeriod.target_value,
        });
      }
      return values;
    };

    return periods.map((period, index) => {
      const performanceOfPeriod = performance.performances_by_period[index];

      return {
        ...period,
        label: this.setPeriodLabel(periods.length, index),
        status: performanceOfPeriod.status,
        values: getPeriodValues(performanceOfPeriod),
        items: this.calculatePerformancePeriodItems(performance, performanceOfPeriod, period),
      };
    });
  }

  private performancePeriodLink(performances: AnimalGroupPerformanceByPeriod[], period: Period) {
    const isInLinkedPerformances = performances.some(performance =>
      this.linkedPerformances.includes(performance.index_name),
    );
    if (!isInLinkedPerformances) {
      return null;
    }
    return {
      route: this.cellLink.url,
      query: {
        ...this.cellLink.queryParams,
        ...this.filter,
        ...period,
        is_open: true,
      },
    };
  }

  private performancesGroupTotalizer(performancesGroup: AnimalGroupPerformanceByPeriod[]) {
    const isInTotalizedPerformances = performancesGroup.some(performance =>
      this.totalizedPerformances.includes(performance.index_name),
    );

    if (!isInTotalizedPerformances) {
      return null;
    }
    return performancesGroup.map(performance => ({
      label: this.t(`agriness.monitor.${performance.index_name.toUpperCase()}_TOTAL`),
      value: performance.performance.index_value,
      measurementUnit: performance.measurement_unit,
      decimalPlaces: performance.decimal_places,
    }));
  }

  private buildCombinedPerformancePeriods(
    performances: AnimalGroupPerformanceByPeriod[],
    periods: Period[],
  ): PerformanceByPeriod[] {
    return this.combinedPerformances.reduce(
      (combinedPerformances: PerformanceByPeriod[], group) => {
        const performancesGroup = performances.filter(performance =>
          group.some(name => performance.index_name === name),
        );

        if (!performancesGroup.length) {
          return combinedPerformances;
        }

        const item = {
          label: performancesGroup
            .map(({ index_name }) => this.t(`${this.indexLabelPrefix}${index_name}`))
            .join(' / '),
          periods: periods.map((period, index) => {
            return {
              ...period,
              label: this.setPeriodLabel(periods.length, index),
              values: performancesGroup.map(({ performances_by_period, ...performance }) => ({
                label: performance.index_name,
                measurementUnit: performance.measurement_unit,
                decimalPlaces: performance.decimal_places,

                value: performances_by_period[index]?.index_value,
              })),
              ...this.performancePeriodLink(performances, period),
            };
          }),
          totalizers: this.performancesGroupTotalizer(performancesGroup),
        };

        return [...combinedPerformances, item];
      },
      [],
    );
  }

  private setPerformances({ performances, periods }: AnimalGroupMonitorPerformanceByPeriod): void {
    const initialValue = {
      performancesPeriods: [] as PerformanceByPeriod[],
      combined: [] as AnimalGroupPerformanceByPeriod[],
    };
    const { performancesPeriods, combined } = performances.reduce((monitor, performance) => {
      const isInCombinedPerformances = !!this.combinedPerformances.find(group =>
        group.find(name => name === performance.index_name),
      );

      if (isInCombinedPerformances) {
        return {
          ...monitor,
          combined: [...monitor.combined, performance],
        };
      }

      return {
        ...monitor,
        performancesPeriods: [
          ...monitor.performancesPeriods,
          {
            label: this.buildLabelPerformancePeriods(performance),
            periods: this.buildPerformancePeriods(performance, periods),
          },
        ],
      };
    }, initialValue);
    const combinedPerformances = this.buildCombinedPerformancePeriods(combined, periods);

    this.performances = [...combinedPerformances, ...performancesPeriods];
  }

  private hasTarget({ performances_by_period }: AnimalGroupPerformanceByPeriod): boolean {
    return performances_by_period.some(p => p.target_value != null);
  }

  private buildLabelPerformancePeriods(performance: AnimalGroupPerformanceByPeriod): string {
    const label = this.t(`${this.indexLabelPrefix}${performance.index_name}`);
    if (this.hasTarget(performance)) {
      return `${label} / ${this.t('agriness.monitor.target')}`;
    }
    return label;
  }
}
