<div class="dual-list-box columns is-variable is-5 is-mobile">
  <div class="column is-half">
    <ag-list-box
      [items]="items"
      [labels]="labels.available"
      [selectable]="true"
      (select)="select($event)"
    ></ag-list-box>
  </div>

  <span class="arrows">
    <span class="icon is-40 icon-chevron-right"></span>
    <span class="icon is-40 icon-chevron-left"></span>
  </span>

  <div class="column is-half">
    <ag-list-box
      [items]="selectedItems"
      [labels]="labels.selected"
      [sortable]="true"
      [removable]="true"
      (drop)="onSelectedItemsSort($event)"
      (remove)="remove($event)"
    ></ag-list-box>
  </div>
</div>
