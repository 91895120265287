import { ModuleWithProviders, NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { CorpEmbedReportTabComponent } from '../../shared/component/corp-embed-report/corp-embed-report-tab.component';
import { EmbedReportData } from '../../shared/component/corp-embed-report/corp-embed-report-tab.model';
import { CorpIndexMapGeneratorComponent } from '../../shared/component/corp-index-map-generator/corp-index-map-generator.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IndicatorsComponent } from './indicators/indicators.component';
import { OnePageReportComponent } from './onepage-report/onepage-report.component';
import { PerformanceAnalysisComponent } from './performance-analysis/performance-analysis.component';
import { RecordListComponent } from './record-list/record-list.component';

type EmbedReportRoute = Omit<Route, 'data'> & {
  data?: EmbedReportData;
};

const onePageTabs: EmbedReportRoute[] = [
  { path: '', pathMatch: 'full', redirectTo: 'overview' },
  {
    path: 'overview',
    component: CorpEmbedReportTabComponent,
    data: {
      url:
        'https://datastudio.google.com/embed/reporting/2f774db8-7c45-438b-b196-6eeed6461c72/page/9KC4B',
      page_height: 4450,
      type: 'onepage',
    },
  },
  {
    path: 'farm_compare',
    component: CorpEmbedReportTabComponent,
    data: {
      url:
        'https://datastudio.google.com/embed/reporting/2f774db8-7c45-438b-b196-6eeed6461c72/page/GLC4B',
      page_height: 2150,
      type: 'onepage',
    },
  },
  {
    path: 'farm_status',
    component: CorpEmbedReportTabComponent,
    data: {
      url:
        'https://datastudio.google.com/embed/reporting/2f774db8-7c45-438b-b196-6eeed6461c72/page/CRz6B',
      page_height: 3700,
      type: 'onepage',
    },
  },
  {
    path: 'farm_sensors',
    component: CorpEmbedReportTabComponent,
    data: {
      url:
        'https://datastudio.google.com/embed/reporting/2f774db8-7c45-438b-b196-6eeed6461c72/page/oc17B',
      page_height: 2100,
      type: 'onepage',
    },
  },
];

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'animal-group-list', component: RecordListComponent },
  { path: 'indicators', component: IndicatorsComponent },
  { path: 'index-map-generator', component: CorpIndexMapGeneratorComponent },
  { path: 'performance-analysis', component: PerformanceAnalysisComponent },
  {
    path: 'onepage-report',
    component: OnePageReportComponent,
    children: onePageTabs,
  },
];

export const routerAnalysis: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);

@NgModule({
  imports: [routerAnalysis],
  exports: [RouterModule],
})
export class AnalysisRoutingModule {}
