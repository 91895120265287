import { AgrinessUiModule } from '@agriness/ui';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';

import { SharedModule } from '../../shared/shared.module';
import { FinishingMonitorRoutingModule } from './monitor-routing.module';
import { FinishingMonitorComponent } from './monitor.component';

@NgModule({
  imports: [
    SharedModule,
    FinishingMonitorRoutingModule,
    DropdownModule,
    TableModule,
    ReactiveFormsModule,
    AgrinessUiModule,
  ],
  declarations: [FinishingMonitorComponent],
  exports: [FinishingMonitorComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FinishingMonitorModule {}
