import { UserStorageService, TypeProductionService, Search } from '@agriness/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { TargetFilter, TargetIndex, TargetPeriodType } from '../models/target.model';
import { TargetIndexAbstractService } from './target-index-abstract.service';

@Injectable()
export class TargetIndexService extends TargetIndexAbstractService {
  private pathTargetIndexes = 'v2/holdings/{holding_id}/target-indexes/';
  private pathTargetPeriodType =
    'v2/holdings/{holding_id}/target-indexes/{target_index}/check-period-type/';

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {
    super();
  }

  list({ stage, group, ...filters }: TargetFilter): Observable<Search<TargetIndex>> {
    const url = this.apiCorpService.translateUrl(this.pathTargetIndexes, {
      holding_id: this.userStorageService.getCurrentHolding().id,
    });

    const params = {
      system_type: this.typeProductionService.get(),
      group,
      stage,
      ...filters,
    };

    return this.apiCorpService.get<Search<TargetIndex>>(url, params);
  }

  checkPeriodType(target_index: string): Observable<TargetPeriodType> {
    const url = this.apiCorpService.translateUrl(this.pathTargetPeriodType, {
      holding_id: this.userStorageService.getCurrentHolding().id,
      target_index,
    });

    return this.apiCorpService.get<TargetPeriodType>(url);
  }
}
