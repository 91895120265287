<button
  type="button"
  id="popover"
  class="btn {{ styleClass }}"
  [autoClose]="autoClose"
  #bPopover="ngbPopover"
  [ngbPopover]="template"
  [placement]="placement"
  [popoverTitle]="title"
  (shown)="emitOpen()"
  (hidden)="emitClose()"
>
  <span *ngIf="iconClass" class="icon" [ngClass]="iconClass"></span>
  <span *ngIf="buttonText" class="text is-xsmall ml-1 px-1">{{ buttonText }}</span>
  <ng-content></ng-content>
</button>
