import { NgModule } from '@angular/core';

import { RouterGuardModule } from '../router-guard/router-guard.module';
import { ProductionParamsAbstractService } from '../services/production-params/production-params-abstract.service';
import { ProductionParamsService } from '../services/production-params/production-params.service';
import { AppInitializerService } from './app-initializer.service';

@NgModule({
  imports: [RouterGuardModule],
  providers: [
    AppInitializerService,
    AppInitializerService.provider,
    { provide: ProductionParamsAbstractService, useClass: ProductionParamsService },
  ],
})
export class AppInitializerModule {}
