import { FeatureName } from '@agriness/corp-app/shared/model/feature-toggle.model';
import { FeatureToggleService } from '@agriness/corp-app/shared/services/feature-toggle.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, CanLoad, Data, Route } from '@angular/router';

@Injectable()
export class FeatureGuard implements CanLoad, CanActivateChild {
  constructor(private featureToggleService: FeatureToggleService) {}

  canLoad(route: Route): boolean {
    return this.isEnabled(route);
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    return this.isEnabled(route);
  }

  private isEnabled({ data }: { data?: Data }): boolean {
    const feature = (data?.feature as FeatureName) || null;
    return this.featureToggleService.isEnabled(feature);
  }
}

export function featureGuard(feature: FeatureName): { feature: FeatureName } {
  return {
    feature,
  };
}
