import { ModalStatusEnum } from '@agriness/ui';
import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Indexes, MainForm } from '../rule-editor-form.model';
import { IndexConfiguratorComponent } from './index-configurator/index-configurator.component';

@Component({
  selector: 'app-step-config',
  templateUrl: './step-config.component.html',
  styleUrls: ['./step-config.component.scss'],
})
export class StepConfigComponent implements OnInit, AfterContentChecked {
  @ViewChild('configurator') configEditor: IndexConfiguratorComponent;

  @Input() formData: BehaviorSubject<MainForm> = null;
  @Output() sendData = new EventEmitter<MainForm>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() isInvalid = new EventEmitter<boolean>();

  formDataSubcribed: MainForm = null;

  formatedIndexes: Indexes[];

  editorModalVisible = false;
  editorModalStatus = ModalStatusEnum.DEFAULT;
  selectedIndex: Indexes;

  cols = [];

  ngOnInit(): void {
    this.formData.subscribe(res => {
      this.formDataSubcribed = res;
    });

    this.cols = [
      { field: 'indexes', header: 'agriness.payment-rule-configuration.config.table.name' },
      { field: 'action', header: 'agriness.payment-rule-configuration.config.table.action' },
      { field: 'status', header: 'agriness.payment-rule-configuration.config.table.status' },
    ];
  }

  ngAfterContentChecked(): void {
    this.isFormValid();
  }

  isFormValid(): void {
    let indexesIsValid = false;
    if (this.formDataSubcribed.indexes.length >= 1) {
      indexesIsValid = this.formDataSubcribed.indexes.filter(x => x.ranges == null).length == 0;
    }

    this.isValid.emit(indexesIsValid);
  }

  receiveFormData(event: MainForm): void {
    this.formDataSubcribed = event;
  }

  setFormData(): void {
    this.sendData.emit(this.formDataSubcribed);
  }

  createConfig(): void {
    const formValues = this.configEditor.getConfigForm();

    this.editorModalStatus = ModalStatusEnum.SUCCESS;

    this.formDataSubcribed.indexes.splice(
      this.formDataSubcribed.indexes.findIndex(a => a.name === formValues.name),
      1,
      formValues,
    );
    this.sendData.emit(this.formDataSubcribed);
    this.isFormValid();
  }

  onAddConfig(event: Indexes): void {
    this.selectedIndex = event;
    this.editorModalVisible = true;
  }

  onEditorClosed(): void {
    this.selectedIndex = null;
    this.editorModalVisible = false;
    this.editorModalStatus = ModalStatusEnum.DEFAULT;
  }
}
