import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ag-table-action-delete-toggle-cell',
  templateUrl: './ag-table-action-delete-toggle-cell.component.html',
  styleUrls: ['./ag-table-action-delete-toggle-cell.component.scss'],
})
export class AgTableActionDeleteToggleCellComponent<T> {
  @Input() item: T;
  @Input() eventDelete: () => void;
  @Output() eventToggle = new EventEmitter<{ item: T; checked: boolean }>();

  @Input() hasToggle = true;
  @Input() checked = true;

  changeToggle($event: boolean) {
    this.eventToggle.emit({ item: this.item, checked: $event });
  }
}
