import { Component, Input, OnChanges } from '@angular/core';

import { ShowSymbol, ValueFormatStyle } from '../../../pipe/value-format/value-format.model';
import { ValueFormatPipe } from '../../../pipe/value-format/value-format.pipe';
import { TargetStatus } from '../../target/target.model';
import { TableColumn } from '../simple/ag-table.model';
import {
  getCellDecimalPlaces,
  getCellRawValue,
  getCellValueStyle,
} from './ag-table-cell-content.model';

@Component({
  selector: 'ag-table-cell-content',
  template: `
    <span [ngClass]="statusClass">
      {{ value }}
    </span>
  `,
})
export class AgTableCellContentComponent implements OnChanges {
  @Input() column: TableColumn;
  @Input() data: any;
  @Input() defaultStyle: ValueFormatStyle | string;
  @Input() showSymbol: boolean;
  @Input() dateFormat: string;

  statusClasses = {
    [TargetStatus.BAD]: 'has-text-red-danger',
  };

  value: string;
  statusClass: string;

  constructor(private valueFormatPipe: ValueFormatPipe) {}

  ngOnChanges(): void {
    const value = getCellRawValue(this.data);
    const style = getCellValueStyle(this.column, this.data, this.defaultStyle);
    const decimalPlaces = getCellDecimalPlaces(this.column, this.data);
    const showSymbolOpt: ShowSymbol = this.showSymbol ? 'show-symbol' : 'hide-symbol';
    this.statusClass = this.statusClasses[this.data?.status];
    this.valueFormatPipe.dateFormat = this.dateFormat;
    this.value = this.valueFormatPipe.transform(value, style, decimalPlaces, showSymbolOpt);
  }
}
