import { deprecatedColors } from '@agriness/assets/deprecated';
import {
  ComfortResult,
  ComfortService,
  ComfortStrategy,
  FilterComfort,
} from '@agriness/corp-app/services';
import { CorpReportSectionLineGraphComponent } from '@agriness/corp-app/shared/component/corp-report-section/line-graph/corp-report-section-line-graph.component';
import { HighchartsChart } from '@agriness/corp-app/shared/model/highcharts.model';
import {
  Highcharts,
  HighchartsOptions,
  HighchartsService,
} from '@agriness/corp-app/shared/services/highcharts.service';
import {
  LineGraphBySeriesConfiguration,
  LineGraphConfiguration,
  LineGraphData,
  LineGraphDisplayData,
} from '@agriness/corp-app/shared/services/report-section/report-section-line-graph.model';
import { ReportSectionLineGraphService } from '@agriness/corp-app/shared/services/report-section/report-section-line-graph.service';
import { AgrinessTranslateService, TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { DateService, UserStorageService } from '@agriness/services';
import { FeedbackEnum, FeedbackPosition, TargetStatus, ValueFormatStyle } from '@agriness/ui';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { defaultsDeep, isEmpty, mapValues, sortBy, sum } from 'lodash';
import { forkJoin, Observable, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { AnimalGroupStateService } from '../animal-group-state.service';
import { configurationByReport, Report, SelectItemStrategy } from './comfort.model';

@Component({
  templateUrl: './comfort.component.html',
})
export class ComfortComponent implements OnInit, OnDestroy {
  @ViewChild('chartHumidity') chartHumidity: HighchartsChart;
  @ViewChild('chartTemperature') chartTemperature: HighchartsChart;
  @ViewChild('chartWaterConsumption') chartWaterConsumption: CorpReportSectionLineGraphComponent;
  @ViewChild('chartWaterTemperature') chartWaterTemperature: HighchartsChart;

  subscription: Subscription;
  typeFeedback = FeedbackEnum.LOADING;
  FeedbackEnum = FeedbackEnum;
  FeedbackPosition = FeedbackPosition;

  filterHumidity: FilterComfort;
  chartOptionsHumidity: LineGraphDisplayData = null;
  chartOptionsHumidityTime: HighchartsOptions = null;

  filterTemperature: FilterComfort;
  chartOptionsTemperature: LineGraphDisplayData = null;
  chartOptionsTemperatureTime: HighchartsOptions = null;

  filterWaterTemperature: FilterComfort;
  chartOptionsWaterTemperature: LineGraphDisplayData = null;
  chartOptionsWaterTemperatureTime: HighchartsOptions = null;

  filterWaterConsumption: FilterComfort;
  chartOptionsWaterConsumption: LineGraphDisplayData = null;

  pieChartDecimalPlaces = 2;
  Highcharts: Highcharts;
  isEmpty = isEmpty;
  types: SelectItemStrategy[];

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private userStorageService: UserStorageService,
    private translate: AgrinessTranslateService,
    private comfortService: ComfortService,
    private animalGroupStateService: AnimalGroupStateService,
    private dateService: DateService,
    private lineGraphService: ReportSectionLineGraphService,
    private highchartsService: HighchartsService,
  ) {
    this.Highcharts = highchartsService.getHighchartsInstance();
  }

  ngOnInit(): void {
    this.typeFeedback = FeedbackEnum.LOADING;
    this.initTypes();

    const subAnimal = this.animalGroupStateService.getAnimalGroup().pipe(
      switchMap(animalGroup => {
        if (!animalGroup) {
          return;
        }
        this.filterTemperature = {
          holding_id: this.userStorageService.getCurrentHolding()?.id,
          stage: animalGroup.stage.name,
          animal_group_id: animalGroup.id,
          index: 'temperature',
          strategy: 'perday',
        };
        this.filterHumidity = {
          ...this.filterTemperature,
          index: 'humidity',
        };
        this.filterWaterTemperature = {
          ...this.filterTemperature,
          index: 'water-temperature',
        };
        this.filterWaterConsumption = {
          ...this.filterTemperature,
          index: 'water-flow',
          sum: true,
        };
        return forkJoin([
          this.getTemperature(),
          this.getTemperatureTime(),
          this.getHumidity(),
          this.getHumidityTime(),
          this.getWaterConsumption(),
          this.getWaterTemperature(),
          this.getWaterTemperatureTime(),
        ]);
      }),
      tap(() => (this.typeFeedback = !this.hasData() ? FeedbackEnum.NOT_RESULT : null)),
      catchError(err => {
        this.typeFeedback = FeedbackEnum.ERROR;
        return throwError(err);
      }),
    );

    this.subscription = subAnimal.subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initTypes(): void {
    this.types = [
      { label: this.t('agriness.date.hour.name'), value: 'perhour' },
      { label: this.t('agriness.date.day.name'), value: 'perday' },
      { label: this.t('agriness.date.week.name'), value: 'perweek' },
    ];
  }

  selectTypeTemperature({ value }: { value?: ComfortStrategy } = {}): void {
    if (value) {
      this.filterTemperature.strategy = value;
      this.getTemperature().subscribe();
    }
  }

  selectTypeHumidity({ value }: { value?: ComfortStrategy } = {}): void {
    if (value) {
      this.filterHumidity.strategy = value;
      this.getHumidity().subscribe();
    }
  }

  selectTypeWaterConsumption({ value }: { value?: ComfortStrategy } = {}): void {
    if (value) {
      this.filterWaterConsumption.strategy = value;
      this.getWaterConsumption().subscribe();
    }
  }

  selectTypeWaterTemperature({ value }: { value?: ComfortStrategy } = {}): void {
    if (value) {
      this.filterWaterTemperature.strategy = value;
      this.getWaterTemperature().subscribe();
    }
  }

  private getTemperature(): Observable<ComfortResult> {
    this.chartOptionsTemperature = { feedback: FeedbackEnum.LOADING };
    return this.comfortService
      .get(this.filterTemperature)
      .pipe(tap((data: ComfortResult) => this.builderChartTemperature(data)));
  }

  private getTemperatureTime(): Observable<ComfortResult> {
    return this.comfortService
      .get({ ...this.filterTemperature, strategy: 'perhour' })
      .pipe(tap(result => this.builderChartTemperatureTime(result)));
  }

  private getHumidity(): Observable<ComfortResult> {
    this.chartOptionsHumidity = { feedback: FeedbackEnum.LOADING };
    return this.comfortService
      .get(this.filterHumidity)
      .pipe(tap((data: ComfortResult) => this.builderChartHumidity(data)));
  }

  private getHumidityTime(): Observable<ComfortResult> {
    return this.comfortService
      .get({ ...this.filterHumidity, strategy: 'perhour' })
      .pipe(tap(result => this.builderChartHumidityTime(result)));
  }

  private getWaterConsumption(): Observable<ComfortResult> {
    this.chartOptionsWaterConsumption = { feedback: FeedbackEnum.LOADING };
    return this.comfortService
      .get(this.filterWaterConsumption)
      .pipe(tap((data: ComfortResult) => this.builderWaterConsumption(data)));
  }

  private getWaterTemperature(): Observable<ComfortResult> {
    this.chartOptionsWaterTemperature = { feedback: FeedbackEnum.LOADING };
    return this.comfortService
      .get(this.filterWaterTemperature)
      .pipe(tap((data: ComfortResult) => this.builderWaterTemperature(data)));
  }

  private getWaterTemperatureTime(): Observable<ComfortResult> {
    return this.comfortService
      .get({ ...this.filterWaterTemperature, strategy: 'perhour' })
      .pipe(tap(result => this.builderChartWaterTemperatureTime(result)));
  }

  private hasData() {
    return (
      this.chartOptionsHumidity.feedback == null ||
      !isEmpty(this.chartOptionsHumidityTime) ||
      this.chartOptionsTemperature.feedback == null ||
      !isEmpty(this.chartOptionsTemperatureTime)
    );
  }

  private builderChartTemperature(data: ComfortResult) {
    this.chartOptionsTemperature = this.getDetailedGraphDisplayData(Report.TEMPERATURE, data);
  }

  private builderChartDonutsTime(dataComfort: ComfortResult) {
    const countByStatus = dataComfort.count_by_target_status;
    const measurementsCount = sum(Object.values(countByStatus));

    const rawPercentageByStatus = mapValues(countByStatus, count =>
      this.roundPieChartValue((100 * count) / measurementsCount),
    );

    // round biggest number to ensure sum is 100%
    const [maxPercentage, ...remaining] = sortBy(
      Object.entries(rawPercentageByStatus),
      ([, value]) => -value,
    );

    const percentageByStatus = Object.fromEntries([
      [maxPercentage[0], 100 - sum(remaining.map(([, value]) => value))],
      ...remaining,
    ]);

    const builderDonuts: HighchartsOptions = {
      chart: {
        type: 'pie',
        height: 400,
      },
      title: {
        text: this.t('agriness.comfort.time_comfort'),
        verticalAlign: 'bottom',
        style: {
          color: deprecatedColors.grey4,
          fontSize: '12px',
        },
      },
      series: [
        {
          type: 'pie',
          innerSize: '50%',
          size: '80%',
          name: '',
          showInLegend: true,
          data: [
            {
              name: this.t(`agriness.comfort.${TargetStatus.GOOD}`),
              y: percentageByStatus[TargetStatus.GOOD],
              color: deprecatedColors.greenSuccess,
            },
            {
              name: this.t(`agriness.comfort.${TargetStatus.ATTENTION}`),
              y: percentageByStatus[TargetStatus.ATTENTION],
              color: deprecatedColors.yellowAttention,
            },
            {
              name: this.t(`agriness.comfort.${TargetStatus.BAD}`),
              y: percentageByStatus[TargetStatus.BAD],
              color: deprecatedColors.redDanger,
            },
          ],
        },
      ],
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: `{point.y:,.${this.pieChartDecimalPlaces}f}%`,
            style: {
              fontSize: '14px',
            },
          },
          tooltip: {
            valueDecimals: this.pieChartDecimalPlaces,
            pointFormat: this.t('agriness.comfort.time_comfort_point_format'),
          },
        },
      },
      legend: {
        verticalAlign: 'top',
        enabled: true,
        symbolRadius: 0,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    };

    return builderDonuts;
  }

  private builderChartTemperatureTime(result: ComfortResult) {
    if (isEmpty(result.count_by_target_status)) {
      this.chartOptionsTemperatureTime = {};
      return;
    }
    this.chartOptionsTemperatureTime = this.builderChartDonutsTime(result);
  }

  private builderChartHumidity(data: ComfortResult) {
    this.chartOptionsHumidity = this.getDetailedGraphDisplayData(Report.HUMIDITY, data);
  }

  private builderWaterConsumption(data: ComfortResult) {
    this.chartOptionsWaterConsumption = this.getSumDisplayData(Report.WATER_CONSUMPTION, data);
  }

  private builderWaterTemperature(data: ComfortResult) {
    this.chartOptionsWaterTemperature = this.getDetailedGraphDisplayData(
      Report.WATER_TEMPERATURE,
      data,
    );
  }

  private builderChartHumidityTime(result: ComfortResult) {
    if (isEmpty(result.count_by_target_status)) {
      this.chartOptionsHumidityTime = {};
      return;
    }
    this.chartOptionsHumidityTime = this.builderChartDonutsTime(result);
  }

  private builderChartWaterTemperatureTime(result: ComfortResult) {
    if (isEmpty(result.count_by_target_status)) {
      this.chartOptionsWaterTemperatureTime = {};
      return;
    }
    this.chartOptionsWaterTemperatureTime = this.builderChartDonutsTime(result);
  }

  private getDetailedGraphDisplayData(report: Report, data: ComfortResult) {
    return this.lineGraphService.asDisplayData({
      configuration: this.getDetailedGraphConfiguration(report, data),
      data: this.getDetailedGraphData(report, data),
    });
  }

  private getSumDisplayData(report: Report, data: ComfortResult) {
    return this.lineGraphService.asDisplayData({
      configuration: this.getDetailedGraphConfiguration(report, data),
      data: this.getSumGraphData(report, data),
    });
  }

  private getDetailedGraphConfiguration(
    report: Report,
    data: ComfortResult,
  ): LineGraphConfiguration {
    const reportConfiguration = configurationByReport[report];
    const dataColor = [
      deprecatedColors.aqua3,
      deprecatedColors.purple3,
      deprecatedColors.orange4,
      deprecatedColors.greenSuccess,
    ];

    const configuration: LineGraphConfiguration = {
      type: 'line',
      fields: {
        category: {
          name: reportConfiguration.graph.category,
          style: ValueFormatStyle.DATE,
        },
        series: {
          style: ValueFormatStyle.TEXT,
          order: [reportConfiguration.graph.series_actual, reportConfiguration.graph.series_target],
          bySeries: {
            [reportConfiguration.graph.series_actual]: {
              color: '#9ec8f3',
              markerEnabled: false,
              markerSymbol: 'square',
            },
            [reportConfiguration.graph.series_target]: {
              type: 'arearange',
              markerEnabled: false,
              markerSymbol: 'circle',
            },
            ...data.by_sensor.reduce((sensorSeries, sensor, index) => {
              sensorSeries[sensor.sensor_description] = {
                color: dataColor[index],
                markerEnabled: 'last-value',
              };
              return sensorSeries;
            }, {} as LineGraphBySeriesConfiguration),
          },
        },
        value: {
          name: reportConfiguration.graph.value,
          style: ValueFormatStyle.DECIMAL,
        },
      },
      tooltip: {
        type: 'by_series',
      },
    };
    return defaultsDeep(
      configuration,
      'detailedGraphConfiguration' in reportConfiguration
        ? reportConfiguration.detailedGraphConfiguration
        : {},
    ) as LineGraphConfiguration;
  }

  private getDetailedGraphData(report: Report, data: ComfortResult): LineGraphData {
    const reportConfiguration = configurationByReport[report];

    const sensorsData: LineGraphData = data.by_sensor.flatMap(sensor =>
      sensor.measurements.map(measurement => ({
        category: this.dateService.toDate(measurement.date).getTime(),
        series: sensor.sensor_description,
        value: measurement.value,
      })),
    );

    const targetData: LineGraphData = data.range.map(item => ({
      category: item.date,
      series: reportConfiguration.graph.series_target,
      value: [item.min, item.max],
    }));

    return [...sensorsData, ...targetData];
  }

  private getSumGraphData(report: Report, data: ComfortResult): LineGraphData {
    const reportConfiguration = configurationByReport[report];

    const sensorsData: LineGraphData = data.by_sensor.flatMap(sensor =>
      sensor.sum.map(sum => ({
        category: this.dateService.toDate(sum.date).getTime(),
        series: sensor.sensor_description,
        value: sum.value,
      })),
    );

    const targetData: LineGraphData = data.range.map(item => ({
      category: item.date,
      series: reportConfiguration.graph.series_target,
      value: [item.min, item.max],
    }));

    return [...sensorsData, ...targetData];
  }

  private roundPieChartValue(number: number): number {
    return Number(number.toFixed(this.pieChartDecimalPlaces));
  }
}
