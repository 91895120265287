import {
  QuadrantAnimalGroup,
  QuadrantEnum,
  QuadrantProducer,
  Quadrants,
  QuadrantsAxis,
} from '@agriness/corp-app/services';
import { CorpFarmLinkService } from '@agriness/corp-app/shared/services/corp-farm-link.service';
import { StageEnum, TypeProductionService } from '@agriness/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { ValueFormatPipe } from '@agriness/ui';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { isEmpty } from 'lodash';

import {
  getIndexesFromReportName,
  IdentifyReportInfo,
  quadrantColors,
  reportInfoByStage,
} from '../../../model/identify.model';
import { IndexTranslateService } from '../../../services/index-translate.service';

@Component({
  selector: 'corp-identify-quadrants',
  templateUrl: './corp-identify-quadrants.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpIdentifyQuadrantsComponent implements OnInit, OnChanges {
  @Input() stage: string;
  @Input() quadrantAnimalGroupList: QuadrantAnimalGroup;
  @Input() selectedReport: string;
  @Input() groupQuadrants: boolean;

  QuadrantEnum = QuadrantEnum;
  quadrantColors = quadrantColors;

  quadrants: Quadrants;
  quadrantsAxisX: QuadrantsAxis;
  quadrantsAxisY: QuadrantsAxis;
  quadrantHeaderAxisX: string;
  quadrantHeaderAxisY: string;
  quadrantNameTranslateParams: Record<string, string>;
  outputTranslateKey: string;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private valueFormatPipe: ValueFormatPipe,
    private indexTranslate: IndexTranslateService,
    private typeProductionService: TypeProductionService,
    private corpFarmLinkService: CorpFarmLinkService,
  ) {}

  ngOnInit(): void {
    const reportInfo = reportInfoByStage[this.stage] as IdentifyReportInfo;
    this.outputTranslateKey = reportInfo.outputTranslateKey;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quadrantAnimalGroupList || changes.selectedReport) {
      this.loadData();
    }
  }

  showQuadrantItemAsLink(producer: QuadrantProducer): boolean {
    const hasFarmId = !!producer.farm_id;
    const hasAnimalGroup = !isEmpty(producer.animalgroup);

    const hasLinkToAnimalGroupProfile = !this.groupQuadrants && hasFarmId && hasAnimalGroup;
    const hasLinkToS4 = hasFarmId && this.shouldRedirectToS4();

    return hasLinkToAnimalGroupProfile || hasLinkToS4;
  }

  shouldRedirectToS4(): boolean {
    const redirectsToS4Stages = [StageEnum.REPRODUCTIVE];
    return redirectsToS4Stages.includes(this.stage as StageEnum);
  }

  redirect(producer: QuadrantProducer): string[] | undefined {
    const farmId = producer.farm_id;

    if (this.shouldRedirectToS4()) {
      this.corpFarmLinkService.redirectUser(farmId, 'S4Farm');
    } else {
      const animalGroupId = producer.animalgroup[0].id;
      return ['/', this.typeProductionService.get(), 'record', farmId, animalGroupId];
    }
  }

  translateAxisInterval(
    quadrantsAxis: QuadrantsAxis,
    quadrantKey: keyof QuadrantsAxis['intervals'],
  ): string {
    const index = quadrantsAxis.index;
    const [type, ...values] = quadrantsAxis.intervals[quadrantKey][0];
    const formattedValues = values.map(value =>
      this.valueFormatPipe.transform(value, index.measurement_unit, index.decimal_places),
    );
    return this.t(`agriness.identify.quadrant.interval_${type}`, formattedValues);
  }

  private loadData(): void {
    this.quadrants = this.quadrantAnimalGroupList.quadrants;
    this.quadrantsAxisX = this.quadrantAnimalGroupList.quadrants_axes.axis_x;
    this.quadrantsAxisY = this.quadrantAnimalGroupList.quadrants_axes.axis_y;
    this.quadrantHeaderAxisX = this.getIndexName(this.quadrantsAxisX.index.key);
    this.quadrantHeaderAxisY = this.getIndexName(this.quadrantsAxisY.index.key);
    this.updateQuadrantNameTranslateParams();
  }

  private updateQuadrantNameTranslateParams() {
    const reportName = this.t('agriness.report.' + this.selectedReport);
    this.quadrantNameTranslateParams = getIndexesFromReportName(reportName);
  }

  private getIndexName(indexKey): string {
    return this.indexTranslate.instant(indexKey, null, this.stage);
  }
}
