import {
  AnimalGroup,
  AnimalGroupAbstractService,
  AnimalGroupMortality,
  ConsumptionList,
  Consumption,
  ConsumptionItem,
  TypeViewPreference,
} from '@agriness/corp-app/services';
import { RowData, RowObject } from '@agriness/corp-app/shared/model/table.model';
import { TableService } from '@agriness/corp-app/shared/services/table.service';
import { AgrinessTranslateService } from '@agriness/services';
import {
  DateService,
  TypeProductionEnum,
  TypeProductionService,
  UserStorageService,
} from '@agriness/services';
import { FeedbackEnum, TableColumn } from '@agriness/ui';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { Table } from 'primeng/table';
import { of, Subscription, throwError } from 'rxjs';

import { LoaderUserPreference } from '../../../../shared/component/loader-user-preference';
import { onSortRowObject } from '../../../../shared/model/table.model';

@Component({
  selector: 'vaccines-medications',
  templateUrl: './vaccines-medications.component.html',
})
export class VaccinesMedicationsComponent extends LoaderUserPreference
  implements OnInit, OnDestroy {
  @ViewChild('dt') pTable: Table;

  subscriptions: Subscription[] = [];

  typeProduction: TypeProductionEnum;
  TypeProduction = TypeProductionEnum;

  animalGroup: AnimalGroup;
  mortality: AnimalGroupMortality;
  typeFeedback = FeedbackEnum.LOADING;

  dateFormat: string;
  animalGroupId: string;
  farmId: string;
  downloadFilename: string;
  totalRecords: number;

  defaultTableWidth = '140px';
  translateKey = 'agriness.sanity.vaccine_medications.table.';

  rows = 10;
  rowsPerPageOptions = [5, 10, 20, 40, 60, 100];

  frozenColumns = new Set<string>();
  allTableColumns: TableColumn[] = [];
  frozenTableColumns: TableColumn[] = [];
  scrollableTableColumns: TableColumn[] = [];
  tableData: RowData<ConsumptionItem>[];

  onSort = onSortRowObject;

  constructor(
    protected userStorageService: UserStorageService,
    private animalService: AnimalGroupAbstractService,
    private translateService: AgrinessTranslateService,
    private typeProductionService: TypeProductionService,
    private route: ActivatedRoute,
    private dateService: DateService,
    private tableService: TableService,
  ) {
    super(userStorageService);
    this.typeProduction = this.typeProductionService.get();
    this.dateFormat = this.dateService.getDateFormat();
  }

  ngOnInit(): void {
    const routeSubscription = this.route.paramMap.subscribe(params => {
      if (isEmpty(params['params'])) {
        return of(null);
      }
      this.farmId = params.get('idFarm');
      this.animalGroupId = params.get('idAnimalGroup');
    });
    this.subscriptions.push(routeSubscription);

    this.getDataConsumption();
    this.updateDownloadFilename();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getBadgeClass(supplyType: string): string {
    const classes: Record<string, string> = {
      medicine: 'badge--degrade-pink',
      vaccine: 'badge--degrade-purple',
    };

    return classes[supplyType];
  }

  isFrozenColumn(column: TableColumn): boolean {
    return this.frozenTableColumns?.includes(column) === true;
  }

  private loadTableData(consumptionList: ConsumptionList) {
    if (consumptionList.length) {
      this.tableData = consumptionList.map(obj => this.parseDataToRowData(obj));
      this.totalRecords = this.tableData.length;
      this.typeFeedback = isEmpty(this.tableData) ? FeedbackEnum.NOT_RESULT : null;
    }
  }

  private getDataConsumption(): void {
    this.typeFeedback = FeedbackEnum.LOADING;

    this.animalService
      .getAnimalGroupConsumption(
        this.typeProduction,
        this.holdingId,
        this.farmId,
        this.animalGroupId,
      )
      .subscribe(
        result => {
          this.typeFeedback = null;

          this.loadColumns(result);
          this.loadTableData(result);
        },
        error => {
          this.typeFeedback = FeedbackEnum.ERROR;

          return throwError(error);
        },
      );
  }

  private getColumns(data: ConsumptionList) {
    return data.length ? Object.keys(data[0]).map(item => new TypeViewPreference(item)) : [];
  }

  private loadColumns(data: ConsumptionList) {
    this.allTableColumns = this.getColumns(data).map(preference => ({
      field: preference.index_name,
      header: this.translateService.instantWithFallback(
        `${this.translateKey}${preference.index_name}`,
      ),
      width: this.defaultTableWidth,
      sortable: true,
    }));

    this.frozenTableColumns = this.allTableColumns.filter(column =>
      this.frozenColumns.has(column.field),
    );

    this.scrollableTableColumns = this.allTableColumns.filter(
      item => !this.frozenTableColumns.includes(item),
    );
  }

  private updateDownloadFilename() {
    this.downloadFilename = this.translateService.instantWithFallback(
      'agriness.sanity.vaccine_medications.title',
    );
  }

  private parseDataToRowData(data: Consumption): RowData<ConsumptionItem> {
    const obj: RowData<ConsumptionItem> = {};
    for (const column of this.allTableColumns) {
      obj[column.field] = this.getValueFromField(column.field, data);
    }
    return obj;
  }

  private getValueFromField(field: string, data: Consumption): RowObject {
    const keysWithCustomEmptyValueMessage = ['active_substance', 'batch_information'];
    const indexValue = this.translateService.instantWithFallback(
      'agriness.sanity.vaccine_medications.non_value_custom_message.uninformed',
    );

    Object.entries(data).forEach(([key, item]) => {
      if (keysWithCustomEmptyValueMessage.includes(key)) {
        item.index_value = indexValue;
      }
    });

    const cellData = data[field] as ConsumptionItem;

    if (!cellData) return null;

    return {
      value: cellData.index_value,
      measurement_unit: cellData.measurement_unit,
      decimal_places: cellData.decimal_places,
    };
  }
}
