import {
  PreferenceId,
  TypeViewPreferenceUser,
  UserPreferencesAbstractService,
  TypeViewPreference,
} from '@agriness/corp-app/services';
import { StageEnum, TypeProductionService } from '@agriness/services';
import { EventName, SiteByStage } from '@agriness/services/analytics/analytics.model';
import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  ToggleColumnPreferenceItem,
  ToggleColumnPreferenceUser,
  ViewPreferences,
} from '../../model/view-preferences';
import {
  LabelPrefixOrProvider,
  ViewPreferenceService,
} from '../../services/view-preference.service';

@Component({
  selector: 'corp-preference-toggle-column',
  templateUrl: './corp-preference-toggle-column.component.html',
})
export class CorpPreferenceToggleColumnComponent implements OnChanges {
  @Input() preferenceType: PreferenceId;
  @Input() stage: StageEnum;
  @Input() buttonIconClass: string;
  @Input() labelPrefix: string;
  @Input() labelProvider: LabelPrefixOrProvider;
  @Input() trackEvent = false;
  @Input()
  set hiddenItems(items: string[]) {
    items = items || [];
    this._hiddenItems = items.map(item => item.toLowerCase());
  }

  get hiddenItems(): string[] {
    return this._hiddenItems;
  }

  @Output() changes = new EventEmitter<void>();

  allPreferences: ToggleColumnPreferenceUser = {};
  sectionPreferences: ToggleColumnPreferenceUser = {};
  visiblePreferences: ToggleColumnPreferenceUser = {};
  stageEvent = ['reproductive'];
  animalgroupList = ['animal-group-list'];

  private _hiddenItems: string[] = [];

  constructor(
    private userPreferencesService: UserPreferencesAbstractService,
    private viewPreferenceService: ViewPreferenceService,
    private readonly route: ActivatedRoute,
    private analyticsService: AmplitudeAnalyticsService,
    private typeProductionService: TypeProductionService,
  ) {}

  ngOnChanges(): void {
    this.updatePreferences();
  }

  load(): Observable<TypeViewPreferenceUser> {
    return this.userPreferencesService.getViewPreferences(this.preferenceType, this.stage).pipe(
      tap(preference => {
        this.onLoad(preference);
      }),
    );
  }

  save(preference: ToggleColumnPreferenceItem[]): void {
    if (this.trackEvent) {
      if (
        this.stageEvent.includes(this.stage) ||
        this.animalgroupList.includes(this.route.snapshot.routeConfig?.path)
      ) {
        if (this.stage == 'reproductive') {
          this.analyticsService.logPreferenceSave(
            EventName.FARM_LIST,
            SiteByStage[this.typeProductionService.get()][this.stage],
          );
        } else {
          this.analyticsService.logPreferenceSave(
            EventName.ANIMALGROUP_LIST,
            SiteByStage[this.typeProductionService.get()][this.stage],
          );
        }
      }
    }

    this.updateAllPreferences(preference);
    this.updatePreferences();

    ViewPreferences.saveViewPreferences(
      this.userPreferencesService,
      this.allPreferences.user,
      this.preferenceType,
      () => this.changes.emit(),
      this.stage,
    );
  }

  getPreferences(): TypeViewPreference[] {
    return (this.sectionPreferences.user || []).map(p => p.object);
  }

  setHiddenItems(items: string[]): void {
    const previousHiddenItems = this.hiddenItems;
    this.hiddenItems = items;
    if (!_.isEqual(this.hiddenItems, previousHiddenItems)) {
      this.ngOnChanges();
    }
  }

  private onLoad(preference: TypeViewPreferenceUser) {
    if (this.labelPrefix || this.labelProvider) {
      this.allPreferences = this.viewPreferenceService.convertUserPreferenceToToggleColumnPreference(
        preference,
        this.labelPrefix || this.labelProvider,
      );
    } else {
      throw new Error('"labelPrefix" or "labelProvider" should be informed');
    }
    this.updatePreferences();
  }

  private updateAllPreferences(newPreferenceWithoutHiddenItems: ToggleColumnPreferenceItem[]) {
    const currentPreference = this.allPreferences.user;
    const currentPreferenceByIndex = _.keyBy(currentPreference, 'object.index_name');

    const nextIndexMap: { [s: string]: string } = {};
    let firstIndex: string = null;
    currentPreference.forEach((current, i) => {
      if (this.isImplicitItem(current.object)) {
        if (i === 0) {
          firstIndex = current.object.index_name;
        } else {
          const previous = currentPreference[i - 1];
          nextIndexMap[previous.object.index_name] = current.object.index_name;
        }
      }
    });

    const finalNewPreference: ToggleColumnPreferenceItem[] = [];
    if (firstIndex) {
      finalNewPreference.push(currentPreferenceByIndex[firstIndex]);
    }
    for (const item of newPreferenceWithoutHiddenItems) {
      finalNewPreference.push(item);

      let lastIndexKey = item.object.index_name;
      while (nextIndexMap[lastIndexKey]) {
        const innerItem = currentPreferenceByIndex[nextIndexMap[lastIndexKey]];
        finalNewPreference.push(innerItem);

        lastIndexKey = innerItem.object.index_name;
      }
    }
    this.allPreferences = {
      ...this.allPreferences,
      user: finalNewPreference,
    };
  }

  private updatePreferences() {
    this.sectionPreferences = _.mapValues(this.allPreferences, value =>
      value.filter(item => !this.isHiddenItem(item.object)),
    );
    this.visiblePreferences = _.mapValues(this.allPreferences, value =>
      value.filter(item => !this.isImplicitItem(item.object)),
    );
  }

  private isHiddenItem(index: TypeViewPreference) {
    if ('parent' in index) {
      return this.hiddenItems.includes(index.parent.toLowerCase());
    }
    return this.hiddenItems.includes(index.index_name.toLowerCase());
  }

  private isImplicitItem(index: TypeViewPreference) {
    return this.isHiddenItem(index) || 'parent' in index;
  }
}
