import { ProductionSystem } from '@agriness/corp-app/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, SelectComponent } from '../corp-filter-abstractions';
import { FixedLoaderService, SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-production-system-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.select.html',
  providers: [{ provide: FilterComponent, useExisting: CorpProductionSystemFilterComponent }],
})
export class CorpProductionSystemFilterComponent extends SelectComponent {
  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(translate, containerService, activeRoute);

    this.assignDefaults(
      'productionSystem',
      new SelectLoader(
        new FixedLoaderService(
          [
            [ProductionSystem.CAGE, 'agriness.animal_group.production_system.cage'],
            [ProductionSystem.CAGE_FREE, 'agriness.animal_group.production_system.cage_free'],
          ],
          translate,
        ),
      ),
    );
  }
}
