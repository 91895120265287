import { Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ShowSymbol, ValueFormatStyle } from '../../../pipe/value-format/value-format.model';
import { ValueFormatPipe } from '../../../pipe/value-format/value-format.pipe';
import { TargetStatus } from '../../target/target.model';
import { TableColumn } from '../simple/ag-table.model';
import {
  getCellDecimalPlaces,
  getCellRawValue,
  getCellValueStyle,
} from './ag-table-cell-content.model';

@Component({
  selector: 'ag-table-cell-tag',
  template: `
    <div [class]="translatedValueToClass">
      <span class="tag-text">
        {{ translatedValue }}
      </span>
    </div>
  `,
  styleUrls: ['./ag-table-action-cell.component.scss'],
})
export class AgTableCellTagComponent implements OnChanges {
  @Input() column: TableColumn;
  @Input() data: any;
  @Input() defaultStyle: ValueFormatStyle | string;
  @Input() showSymbol: boolean;
  @Input() dateFormat: string;

  value: string | boolean;

  translatedValueToClass = '';
  translatedValue = '';
  constructor(private valueFormatPipe: ValueFormatPipe, private t: TranslateService) {}

  ngOnChanges(): void {
    const value = getCellRawValue(this.data);
    const style = getCellValueStyle(this.column, this.data, this.defaultStyle);
    const decimalPlaces = getCellDecimalPlaces(this.column, this.data);
    const showSymbolOpt: ShowSymbol = this.showSymbol ? 'show-symbol' : 'hide-symbol';
    this.valueFormatPipe.dateFormat = this.dateFormat;
    this.value = this.valueFormatPipe.transform(value, style, decimalPlaces, showSymbolOpt);

    if (this.column.field == 'farm_count') this.value = this.value == '-' ? false : true;

    if (this.value === true || this.value === false) {
      this.translatedValueToClass = this.value === true ? 'green-tag' : 'red-tag';
      this.translatedValue =
        this.value === true
          ? this.t.instant('agriness.filter.has_farm.bonded')
          : this.t.instant('agriness.filter.has_farm.not-bonded');
    }
  }
}
