import { FeatureToggleService } from '@agriness/corp-app/shared/services/feature-toggle.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';

const featureName = 'target_new';
@Injectable({ providedIn: 'root' })
export class TargetGuard implements CanActivate {
  constructor(private featureToggleService: FeatureToggleService) {}

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.featureToggleService.isEnabled(featureName);
  }
}
