import { AgrinessTranslateService, UserStorageService } from '@agriness/services';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ag-help-menu',
  templateUrl: './ag-help-menu.component.html',
})
export class HelpMenuComponent {
  @Input() showTooltip: false;
  @Input() url: string;

  hideHelpTooltip: boolean;

  constructor(
    public translateService: AgrinessTranslateService,
    public userStorageService: UserStorageService,
  ) {}

  dismiss() {
    this.showTooltip = false;
    this.userStorageService.setHelpUserDismiss(true);
  }
}
