import { SiteEnum, SiteSystemType } from '@agriness/services';
import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  templateUrl: './nursery-identify.component.html',
})
export class NurseryIdentifyComponent {
  stage = SiteSystemType[environment.typeProduction][SiteEnum.SITE_2];
}
