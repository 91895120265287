/* eslint-disable */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { isBoolean, memoize, template, TemplateOptions } from 'lodash';
import { Observable } from 'rxjs';

import { BaseApiAbstractService } from '../common/base-api-abstract.service';
import { TypeProductionService } from '../common/type-production.service';

export const LoadingInterceptorSkipHeader = 'X-Skip-Loading-Interceptor';

export abstract class ApiAbstractService {
  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
  private templateParams: TemplateOptions = {
    interpolate: /{([\s\S]+?)}/g,
  };

  private http: HttpClient;
  private typeProductionService: TypeProductionService;

  protected constructor(
    { http, typeProductionService }: BaseApiAbstractService,
    private baseUrl: string,
  ) {
    this.http = http;
    this.typeProductionService = typeProductionService;
  }

  getTemplate = memoize((url: string) => template(url, this.templateParams));

  get<T>(
    path: string,
    params?: any,
    ignoreLoadingInterceptor?: boolean,
    observeResponse = false,
  ): Observable<T> {
    const options = this.defineOptions(ignoreLoadingInterceptor, observeResponse, params);
    return this.http.get<T>(`${this.baseUrl}${path}`, options);
  }

  post<T>(
    path: string,
    data: any,
    params?: any,
    ignoreLoadingInterceptor?: boolean,
    observeResponse = false,
  ): Observable<T> {
    const body = JSON.stringify(data);
    const options = this.defineOptions(ignoreLoadingInterceptor, observeResponse, params);
    return this.http.post<T>(`${this.baseUrl}${path}`, body, options);
  }

  put<T>(
    path: string,
    data: any,
    params?: any,
    ignoreLoadingInterceptor?: boolean,
    observeResponse = false,
  ): Observable<T> {
    const body = JSON.stringify(data);
    const options = this.defineOptions(ignoreLoadingInterceptor, observeResponse, params);
    return this.http.put<T>(`${this.baseUrl}${path}`, body, options);
  }

  patch<T>(
    path: string,
    data: any,
    params?: any,
    ignoreLoadingInterceptor?: boolean,
    observeResponse = false,
  ): Observable<T> {
    const body = JSON.stringify(data);
    const options = this.defineOptions(ignoreLoadingInterceptor, observeResponse, params);
    return this.http.patch<T>(`${this.baseUrl}${path}`, body, options);
  }

  delete<T>(path: string, params?: any, ignoreLoadingInterceptor?: boolean): Observable<T> {
    const options = this.defineOptions(ignoreLoadingInterceptor, false, params);
    return this.http.delete<T>(`${this.baseUrl}${path}`, options);
  }

  serializeParams(params: any): HttpParams {
    let httpParams = null;
    if (params) {
      httpParams = new HttpParams();
      Object.keys(params).forEach(key => {
        httpParams = httpParams.append(key, this.getValueParams(key, params));
      });
    }
    return httpParams;
  }

  private getValueParams(key, params) {
    if (isBoolean(params[key])) {
      return params[key];
    }
    return params[key] ? params[key] : '';
  }

  private defineOptions(
    ignoreLoadingInterceptor: boolean,
    observeResponse = false,
    allParams: any,
  ): {} {
    allParams = allParams || {};
    const { responseType, ...params } = allParams;
    const options = {};

    const headers = ignoreLoadingInterceptor
      ? this.headers.set(LoadingInterceptorSkipHeader, ignoreLoadingInterceptor.toString())
      : this.headers;
    options['headers'] = headers;
    options['responseType'] = responseType;
    if (observeResponse) {
      options['observe'] = 'response';
    }
    options['params'] = this.serializeParams(params);
    return options;
  }

  /**
   * monta url com parametros
   * ex:
   * params = {'idFarm': 1}
   * url = 'farm/{idFram}'
   * translateUrl(url, params) = 'farm/1';
   */
  translateUrl(url: string, params?: object): string {
    if (!params) {
      params = {};
    }
    if (!params['type_production']) {
      params['type_production'] = this.typeProductionService.get();
    }
    return this.getTemplate(url)(params);
  }
}
