<div [attr.data-testid]="data?.id" class="card-full-height">
  <a
    *ngIf="hasNavigation; else cardContent"
    [routerLink]="link.url"
    [queryParams]="link.queryParams"
    target="_blank"
    (click)="onEvent()"
  >
    <ng-container *ngTemplateOutlet="cardContent"> </ng-container>
  </a>
</div>

<ng-template #cardContent>
  <div class="value-card">
    <header class="value-card-header">
      <p class="title text-uppercase" title="{{ data.name | translate }}" data-testid="card-name">
        {{ data.name | translate }}
      </p>
    </header>

    <div class="value-card-body">
      <h3 class="text font-weight-bold" data-testid="card-value">
        <ag-format
          [value]="data.value"
          [measurementUnit]="data.style || data.measurement_unit"
          [decimalPlaces]="data.decimal_places"
        >
        </ag-format>
      </h3>
    </div>

    <ag-target-indicator
      [status]="data.status"
      [tooltip]="tipContent"
      iconSize="16px"
      wrapperClass="card-status"
    >
    </ag-target-indicator>

    <div *ngIf="hasNavigation" class="link-icon">
      <span class="icon is-12 icon-open-new-tab"></span>
    </div>

    <footer class="value-card-footer" *ngIf="targetText" data-testid="card-target">
      <span [innerHTML]="targetText"></span>
    </footer>

    <footer class="value-card-footer" *ngIf="!targetText && data.target" data-testid="card-target">
      <p class="text">
        <span>{{ data.target_name | translate }}:</span>

        <span class="font-weight-bold">
          <ag-format
            [value]="data.target"
            [measurementUnit]="data.style || data.target_measurement_unit"
            [decimalPlaces]="data.decimal_places"
          >
          </ag-format>
        </span>
      </p>
    </footer>
  </div>
</ng-template>

<ng-template #tipContent>
  <ag-card-tooltip
    [decimalPlaces]="data.decimal_places"
    [measurementUnit]="data.style || data.measurement_unit"
    [minRecommended]="data.target_min_recommended"
    [maxRecommended]="data.target_max_recommended"
  ></ag-card-tooltip>
</ng-template>
