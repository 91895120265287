export interface MainForm {
  id?: string;
  name: string;
  holding_id: string;
  stage: string;
  type_weight: string;
  enabled: boolean;
  indexes: Indexes[];
}

export interface PatchForm {
  enabled: boolean;
}

export interface Indexes {
  id?: string;
  type: TypeIndex;
  name: string;
  index_available?: string;
  ranges?: Range[];
}

export interface Range {
  range: number;
  percentage: number;
}

export enum TypeWeight {
  TOTAL_WEIGHT = 'total_weight_output',
  WEIGHT_GAIN = 'total_weight_gain',
}

export enum TypeIndex {
  MANUAL = 'manual',
  AVAILABLE = 'available',
}

export interface Stage {
  id: string;
  name: string;
  system_type: string;
}
