<div [formGroup]="group" class="form-group has-feedback" [ngClass]="{ 'has-error': errorMessage }">
  <ng-select
    #select
    [items]="isEmpty ? (customItems$ | async) : isSelectAll() ? items : (items$ | async)"
    [(ngModel)]="itemsSelect"
    [multiple]="true"
    [bindLabel]="optionLabel"
    [placeholder]="defaultLabel | translate"
    [closeOnSelect]="false"
    [bindValue]="dataKey"
    [virtualScroll]="true"
    [searchable]="false"
    [clearOnBackspace]="false"
    [clearable]="clearable"
    [clearAllText]="clearText | translate"
    [formControlName]="controlName"
    [disabled]="disabled"
    [attr.name]="controlName"
    (scrollToEnd)="onScrollToEnd()"
    (add)="onAdd()"
    (remove)="onRemove()"
    (open)="onOpen()"
    (close)="onClose()"
    [loading]="loading"
    [notFoundText]="notFoundText | translate"
    [loadingText]="loadingText | translate"
    (change)="onChange($event)"
    ngDefaultControl
  >
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngIf="items?.length == maxItemsLabel">
        <span class="ng-value-label">{{ items[0].name }}</span>
      </div>
      <div class="ng-value" *ngIf="items?.length > maxItemsLabel">
        <span class="ng-value-label">{{ msgItemsSelects | translate: { '0': items.length } }}</span>
      </div>
    </ng-template>
    <ng-template ng-header-tmp>
      <div class="form-group">
        <input
          #searchInput
          data-testid="search"
          [value]="searchValue"
          (input)="onSearch($event)"
          class="ng-input-search"
          type="text"
        />

        <div *ngIf="isSelectAll() && items.length" class="select-all" (click)="onSelectAll()">
          <span class="checkbox" [ngClass]="{ checked: isSelectedAll }">
            <span class="icon icon-confirm"></span>
          </span>

          <span>{{ 'agriness.dual_list_box.select_all' | translate }}</span>
        </div>

        <span class="icon icon-search form-icon"></span>
      </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <span class="checkbox" [ngClass]="{ checked: item$.selected }">
        <span class="icon icon-confirm"></span>
      </span>
      <span class="icon icon-add" *ngIf="isEmpty"></span>
      <span class="ng-option-label" *ngIf="isEmpty">{{
        'agriness.multi-select.add-item' | translate: { name: item.name }
      }}</span>
      <span class="ng-option-label" *ngIf="!isEmpty">{{ item.name }}</span>
    </ng-template>
  </ng-select>
  <div class="input-feedback" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
</div>
