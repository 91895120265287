import { textColumn, actionColumn } from '@agriness/ui';

import { commonDeletionTableFields } from '../../../deletion-table/deletion-table.model';

const producerKey = 'agriness.settings.targets.table';

export const targetsTableColumns = [
  {
    ...actionColumn,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'target_index.group',
    header: `${producerKey}.group`,
    width: '150px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'target_index.name',
    header: `${producerKey}.index`,
    width: '250px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'period_type',
    header: `${producerKey}.period_type`,
    width: '150px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'target_index.representation_type',
    header: `${producerKey}.representation_type`,
    width: '400px',
    sortable: true,
    fixed: true,
  },
];

export const targetsDeletionTableFields: string[] = [...commonDeletionTableFields];
