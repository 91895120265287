import { Component, Input } from '@angular/core';

import { TargetStatus } from '../../target/target.model';
import { BarChart } from '../card.model';

@Component({
  selector: 'ag-card-bar-chart-graph',
  templateUrl: './ag-card-bar-chart-graph.component.html',
})
export class AgCardBarChartGraphComponent {
  @Input() items: BarChart;

  statusClass: { [k in TargetStatus]: string } = {
    [TargetStatus.GOOD]: 'card-bar-chart-good',
    [TargetStatus.ATTENTION]: 'card-bar-chart-attention',
    [TargetStatus.BAD]: 'card-bar-chart-bad',
  };

  defaultClass = 'card-bar-chart-disabled';
}
