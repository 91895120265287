import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  Input,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { unionBy, differenceBy } from 'lodash';

import { DualListBoxItem, DualListBoxLabels } from './ag-dual-list-box.model';

@Component({
  selector: 'ag-dual-list-box',
  templateUrl: './ag-dual-list-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AgDualListBoxComponent,
      multi: true,
    },
  ],
})
export class AgDualListBoxComponent implements OnChanges, ControlValueAccessor {
  @Input() items: DualListBoxItem[] = [];
  @Input() selectedItems: DualListBoxItem[] = [];
  @Input() labels: DualListBoxLabels;
  @Output() selected = new EventEmitter<DualListBoxItem[]>();
  @Output() removed = new EventEmitter<DualListBoxItem[]>();
  @Output() selectedItemsSort = new EventEmitter<CdkDragDrop<string[]>>();

  private onChange = (_: any) => {};
  private onTouched = () => {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItems) {
      this.onChange(this.selectedItems);
    }
  }

  writeValue(items: any): void {
    this.selectedItems = items || [];
    this.items = differenceBy(this.items, items, 'value');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.onChange(this.selectedItems);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  select(items: DualListBoxItem[]): void {
    this.selectedItems = unionBy(this.selectedItems, items, 'value');
    this.items = differenceBy(this.items, items, 'value');
    this.emit(this.selected, items);
  }

  remove(items: DualListBoxItem[]): void {
    this.selectedItems = differenceBy(this.selectedItems, items, 'value');
    this.items = unionBy(this.items, items, 'value').sort((a, b) => a.label.localeCompare(b.label));
    this.emit(this.removed, items);
  }

  onSelectedItemsSort(event: CdkDragDrop<string[]>): void {
    const selectedItems = [...this.selectedItems];
    moveItemInArray(selectedItems, event.previousIndex, event.currentIndex);
    this.selectedItems = selectedItems;
    this.emit(this.selectedItemsSort, event);
  }

  private emit<T>(emitter: EventEmitter<T>, ...args: T[]) {
    emitter.emit(...args);
    this.onTouched();
    this.onChange(this.selectedItems);
  }
}
