import { Search } from '@agriness/services';
import { Observable } from 'rxjs';

import { Region, RegionFilter, RegionForm } from '../models/region.model';

export abstract class RegionAbstractService {
  abstract list(regionFilter: RegionFilter): Observable<Search<Region>>;
  abstract save(regionForm: RegionForm): Observable<Region>;
  abstract update(regionForm: RegionForm, regionId: string): Observable<Region>;
  abstract delete(region: Region): Observable<void>;
}
