import { TypeProductionEnum } from '@agriness/services';

const typeProductionToIconSuffix: { [k in TypeProductionEnum]: string } = {
  [TypeProductionEnum.SWINES]: 'swine',
  [TypeProductionEnum.POULTRY]: 'poultry',
  [TypeProductionEnum.DAIRY]: 'dairy',
  [TypeProductionEnum.LAYERS]: 'poultry',
};

export function getTypeProductionIcon(typeProduction: TypeProductionEnum, isOpen: boolean): string {
  const status = isOpen ? 'open' : 'closed';
  const suffix = typeProductionToIconSuffix[typeProduction];

  return `icon-animal-group-${status}-default-${suffix}`;
}
