export abstract class Search<T> {
  constructor(
    public count?: number,
    public next?: string,
    public previous?: string,
    public results?: T[],
  ) {}
}

/**
 * @deprecated
 */
export abstract class SearchFilter {
  constructor(
    public search?: string,
    public order?: string,
    public page?: number,
    public per_page?: number,
    public all?: boolean,
  ) {}
}

export interface BaseEntityFilter {
  id?: string[] | number[];
  name?: string;
  order?: string;
  enabled?: boolean;
}

export interface PageNumberPagination {
  page?: number;
  page_size?: number;
}

export interface ContextFilter {
  stage?: string;
  animalGroupStatus?: string;
}

export interface QueryCorp {
  queryCorpOnly?: boolean;
  verbose?: boolean;
}
