import { deprecatedColors } from '@agriness/assets/deprecated';
import { Report } from '@agriness/corp-app/record/animal-group/nutrition/nutrition.model';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { AgFeedbackService, FeedbackEnum, ValueFormatPipe, ValueFormatStyle } from '@agriness/ui';
import { Inject, Injectable } from '@angular/core';
import { Options as HighchartsOptions, Point, PointOptionsObject } from 'highcharts';
import { castArray, defaultsDeep, sortBy } from 'lodash';

import {
  LineGraphDisplayData,
  LineGraphDisplayInput,
  LineGraphHighchartsSeriesOptions,
  LineGraphCategoryType,
  LineGraphBySeriesValueConfiguration,
} from './report-section-line-graph.model';

@Injectable()
export class ReportSectionLineGraphService {
  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private feedbackService: AgFeedbackService,
    private valueFormat: ValueFormatPipe,
  ) {}

  asDisplayData(inputOptions: LineGraphDisplayInput): LineGraphDisplayData {
    const options = this.mergeWithDefaultOptions(inputOptions);
    const { configuration } = options;
    const { valueFormat } = this;

    const categories = this.getCategoriesValues(options);
    const series = this.getSeriesOptions(options, categories);
    const hasData = series.some(s => s.data.some(d => d != null && d !== 0));

    const highchartsOptions: HighchartsOptions = {
      chart: {
        type: configuration.type,
        height: '400px',
      },
      title: {
        text: '',
      },
      tooltip: {
        enabled: Boolean(configuration.tooltip.type),
        shared: configuration.tooltip.type === 'shared',
        split: configuration.tooltip.type === 'split',
        pointFormatter: function () {
          const value = valueFormat.transform(this.y, configuration.fields.value.style);

          return Point.prototype.tooltipFormatter.call(
            this,
            `<span style="color:{point.color}">\u25CF</span> {series.name}: <b>${value}</b><br/>`,
          ) as string;
        },
      },
      legend: {
        align: 'left',
        verticalAlign: 'top',
        symbolWidth: 8,
        symbolHeight: 8,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      lang: {
        noData: this.feedbackService.getFeedbackMessage(FeedbackEnum.NOT_RESULT),
      },
      xAxis: {
        type: configuration.fields.category.type,
        title: {
          text: configuration.fields.category.name
            ? this.t(configuration.fields.category.name)
            : '',
        },
        categories: configuration.fields.category.type === 'category' ? categories : null,
        visible: hasData,
        labels: {
          formatter: function () {
            return valueFormat.transform(this.value, configuration.fields.category.style);
          },
        },
        tickInterval: configuration.fields.category.markerInterval,
        allowDecimals: false,
      },
      yAxis: {
        title: {
          text:
            configuration.fields.value.report === Report.FEED_BY_PERIOD
              ? `${this.t(configuration.fields.value.name)} (${this.valueFormat.getSymbol(
                  configuration.fields.value.style,
                  null,
                )})`
              : this.t(configuration.fields.value.name),
        },
        visible: hasData,
        labels: {
          formatter: function () {
            return valueFormat.transform(this.value, configuration.fields.series.style);
          },
        },
        min: configuration.fields.value.min,
        max: configuration.fields.value.max,
      },
      series: hasData ? series : [],
    };

    return {
      feedback: hasData ? null : FeedbackEnum.NO_DATA_CHART,
      highchartsOptions,
    };
  }

  private mergeWithDefaultOptions(options: LineGraphDisplayInput): LineGraphDisplayInput {
    const categoryByDataType: { [k in ValueFormatStyle]?: LineGraphCategoryType } = {
      [ValueFormatStyle.DATE]: 'datetime',
      [ValueFormatStyle.UNITARY]: 'linear',
    };
    const categoryType: LineGraphCategoryType =
      options.configuration.fields.category.type ||
      categoryByDataType[options.configuration.fields.category.style] ||
      'category';

    const defaultOptions = {
      configuration: {
        type: 'spline',
        fields: {
          category: {
            type: categoryType,
            markerInterval: categoryType === 'datetime' ? 7 * 24 * 3600 * 1000 : undefined,
          },
        },
        tooltip: {
          type: 'shared',
        },
      },
    } as LineGraphDisplayInput;

    return defaultsDeep({}, options, defaultOptions) as LineGraphDisplayInput;
  }

  private getCategoriesValues({ configuration, data }: LineGraphDisplayInput): string[] {
    const categories = new Set();
    for (const item of data) {
      categories.add(item.category);
    }
    const sortedCategories = sortBy(Array.from(categories));
    if (sortedCategories.length > 0 && configuration.fields.category.type === 'linear') {
      const biggest = sortedCategories[sortedCategories.length - 1] as number;
      return Array(biggest + 1)
        .fill(null)
        .map((v, i) => String(i));
    }
    return sortedCategories as string[];
  }

  private getSeriesOptions(
    options: LineGraphDisplayInput,
    categories: string[],
  ): LineGraphHighchartsSeriesOptions[] {
    const { configuration } = options;

    const valuesBySeries = this.getValuesBySeries(options, categories);
    const orderedSeries = this.getSeriesOrder(options, Object.keys(valuesBySeries));

    const seriesOptions: LineGraphHighchartsSeriesOptions[] = [];
    for (const seriesName of orderedSeries) {
      const values = valuesBySeries[seriesName];
      const customSeriesOptions = configuration.fields.series.bySeries[seriesName] || {};
      const id = seriesName;

      const markerEnabled = [null, undefined, 'last-value', true].includes(
        customSeriesOptions.markerEnabled,
      );

      const type = customSeriesOptions.type || configuration.type || 'spline';

      if (type === 'line' || type === 'spline') {
        seriesOptions.push({
          type,
          id,
          connectNulls: customSeriesOptions.connectNulls || false,
          name: this.t(seriesName),
          data: values,
          color: customSeriesOptions.color || deprecatedColors.blue5,
          marker: {
            enabled: markerEnabled,
            symbol: customSeriesOptions.markerSymbol,
          },
          states: {
            inactive: {
              opacity: 0.4,
            },
          },
          turboThreshold: 0,
        });
      } else if (type === 'arearange') {
        const color = customSeriesOptions.color || deprecatedColors.blue3;

        seriesOptions.push({
          type: type,
          id,
          name: this.t(seriesName),
          data: values,
          color: color,
          fillColor: color + '33', // alpha 0.2 in hexadecimal
          marker: {
            enabled: markerEnabled,
            symbol: customSeriesOptions.markerSymbol,
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        });
      } else {
        throw new Error(`series type not configured`);
      }
    }
    return seriesOptions;
  }

  private getValuesBySeries(
    { configuration, data }: LineGraphDisplayInput,
    categories: string[],
  ): { [k: string]: PointOptionsObject[] } {
    const valueByCategoryAndSeries: Record<string, Record<string, number | number[]>> = {};
    const unorderedSeries: Set<string> = new Set();

    for (const item of categories) {
      valueByCategoryAndSeries[item] = {};
    }

    for (const item of data) {
      unorderedSeries.add(item.series);
      const categoryValuesBySeries = valueByCategoryAndSeries[item.category];
      if (categoryValuesBySeries) {
        categoryValuesBySeries[item.series] = item.value;
      }
    }

    const result = {};
    for (const seriesName of unorderedSeries) {
      const values = [];
      const customSeriesOptions: LineGraphBySeriesValueConfiguration =
        configuration.fields.series.bySeries[seriesName];

      result[seriesName] = values;
      for (const category of categories) {
        const value = valueByCategoryAndSeries[category][seriesName] || null;

        if (configuration.fields.category.type === 'datetime') {
          if (value != null) {
            const pointSeries: PointOptionsObject = {
              x: Number(category),
              marker: { enabled: customSeriesOptions.markerEnabled === true },
            };

            if (customSeriesOptions.type === 'arearange') {
              const [low, high] = castArray(value);

              pointSeries.low = low;
              pointSeries.high = high;
            } else {
              pointSeries.y = Number(value);
            }

            values.push(pointSeries);
          }
        } else {
          values.push(value);
        }
      }

      if (customSeriesOptions.markerEnabled === 'last-value') {
        values[values.length - 1] = {
          ...values[values.length - 1],
          marker: {
            enabled: true,
          },
        };
      }
    }
    return result;
  }

  private getSeriesOrder({ configuration }: LineGraphDisplayInput, series: string[]) {
    const order = configuration.fields.series.order;
    return series.sort((a, b) => {
      const aIndex = order.indexOf(a);
      const bIndex = order.indexOf(b);
      if (aIndex >= 0 && bIndex >= 0) {
        return aIndex - bIndex;
      }
      if (aIndex >= 0 && bIndex < 0) {
        return -1;
      }
      if (aIndex < 0 && bIndex >= 0) {
        return 1;
      }
      return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
    });
  }
}
