<ng-template #content>
  <ng-container *ngIf="currentContent">
    <ng-container *ngTemplateOutlet="isCard ? templateCard : templatePlain"></ng-container>
  </ng-container>
</ng-template>

<ng-template #templateCard>
  <section class="section card shadow-sm mt-4 mt-3">
    <header *ngIf="showHeader()" class="has-text-grey-4">
      <ng-container *ngTemplateOutlet="templateHeader"></ng-container>
    </header>

    <ng-container *ngTemplateOutlet="templateContent"></ng-container>
  </section>
</ng-template>

<ng-template #templatePlain>
  <section class="wrapper">
    <header *ngIf="showHeader()" class="section-header">
      <ng-container *ngTemplateOutlet="templateHeader"></ng-container>
    </header>

    <ng-container *ngTemplateOutlet="templateContent"></ng-container>
  </section>
</ng-template>

<ng-template #templateHeader>
  <div class="columns is-variable is-1 is-mobile is-multiline">
    <div class="column is-full-mobile">
      <span *ngIf="sectionTitle" class="title font-weight-bold mr-2">
        {{ sectionTitle | translate }}
      </span>

      <ng-container *ngIf="!feedback">
        <button
          *ngIf="showNextContentButton()"
          type="button"
          class="btn btn-ghost has-round-hover"
          (click)="onNextContentClick()"
        >
          <span *ngIf="nextContentIcon()" class="icon is-16" [ngClass]="nextContentIcon()"></span>
        </button>

        <corp-export-button
          *ngIf="this.currentContent.icon === 'icon-report1'"
          [filename]="sectionTitle | translate"
          [lazyInstance]="currentContent.chart | lazy"
        ></corp-export-button>

        <ng-container *ngTemplateOutlet="currentActions"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #templateContent>
  <ag-feedback [type]="feedback"></ag-feedback>
  <ng-container *ngIf="!feedback">
    <ng-container *ngFor="let item of childrenContent">
      <div [ngClass]="isCurrentContent(item) ? '' : 'd-none'">
        <ng-container *ngTemplateOutlet="item.content"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-container *ngTemplateOutlet="content"></ng-container>
