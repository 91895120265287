import { Search, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { NutritionType, NutritionTypeFilter } from '../models/nutrition-type.model';

@Injectable()
export class NutritionTypeService {
  private pathBase = 'holdings/{holding_id}/nutrition-types/';

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
  ) {}

  list(filter: NutritionTypeFilter): Observable<Search<NutritionType>> {
    const url = this.apiCorpService.translateUrl(this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
    });
    return this.apiCorpService.get<Search<NutritionType>>(url, filter);
  }
}
