import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { LineModel } from './line.model';
import { NodeModel } from './node.model';
import { NodeVector } from './vector.model';

@Component({
  selector: 'ag-tree',
  templateUrl: './ag-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgTreeComponent {
  @Input() nodeSize: NodeVector;
  @Input() nodes: NodeModel[];
  @Input() lines: LineModel[];
  @Input() cols: number;
  @Input() rows: number;

  lineIsHorizontal(line: LineModel): boolean {
    const start = this.getStartVector(line);
    const end = this.getEndVector(line);

    const horizontalModulus = Math.abs(end.col - start.col);
    const verticalModulus = Math.abs(end.row - start.row);

    if (horizontalModulus === 0) {
      return true;
    }

    if (verticalModulus === 0) {
      return false;
    }

    return horizontalModulus > verticalModulus;
  }

  lineIsReverse(line: LineModel): boolean {
    return this.endInTheSecondQuadrant(line) || this.endInTheFourthQuadrant(line);
  }

  lineHasArrowStart(line: LineModel): boolean {
    const endIntTheFirstOrSecondQuadrant = this.endIntTheFirstOrSecondQuadrant(line);
    return (
      (endIntTheFirstOrSecondQuadrant && line.arrowEnd) ||
      (!endIntTheFirstOrSecondQuadrant && line.arrowStart)
    );
  }

  lineHasArrowEnd(line: LineModel): boolean {
    const endIntTheFirstOrSecondQuadrant = this.endIntTheFirstOrSecondQuadrant(line);
    return (
      (endIntTheFirstOrSecondQuadrant && line.arrowStart) ||
      (!endIntTheFirstOrSecondQuadrant && line.arrowEnd)
    );
  }

  private endIntTheFirstOrSecondQuadrant(line: LineModel): boolean {
    const start = this.getStartVector(line);
    const end = this.getEndVector(line);

    return end.row < start.row;
  }

  private endInTheSecondQuadrant(line: LineModel): boolean {
    const start = this.getStartVector(line);
    const end = this.getEndVector(line);

    return end.col > start.col && end.row < start.row;
  }

  private endInTheFourthQuadrant(line: LineModel): boolean {
    const start = this.getStartVector(line);
    const end = this.getEndVector(line);

    return end.col <= start.col && end.row >= start.row;
  }

  private getStartVector(line: LineModel): NodeVector {
    return {
      row: line.start.row,
      col: line.start.col,
    };
  }

  private getEndVector(line: LineModel): NodeVector {
    return {
      row: line.end.row,
      col: line.end.col,
    };
  }
}
