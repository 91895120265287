import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import {
  AnimalGroupMonitorPerformance,
  AnimalGroupPerformance,
} from '../models/animal-group-performance.model';
import {
  GetScheduleMonitorPerformanceArgs,
  ScheduleMonitorAbstractService,
} from './schedule-monitor-abstract.service';

@Injectable()
export class ScheduleMonitorService extends ScheduleMonitorAbstractService {
  private pathMonitorPerformance = 'holdings/{holding_id}/schedules/reports/{report}/';

  constructor(private apiCorpService: ApiCorpService) {
    super();
  }

  getMonitorPerformance({
    holdingId,
    stage,
    system_type,
    report,
    filter,
  }: GetScheduleMonitorPerformanceArgs): Observable<AnimalGroupMonitorPerformance[]> {
    const url = this.apiCorpService.translateUrl(this.pathMonitorPerformance, {
      holding_id: holdingId,
      stage,
      report,
    });
    return this.apiCorpService.get<AnimalGroupPerformance[]>(url, { ...filter, system_type });
  }
}
