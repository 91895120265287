import { Target, TargetReportEnum, TargetService } from '@agriness/corp-app/services';
import { TypeProductionEnum, StageEnum, TypeProductionService } from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { chain, defaults, sortBy } from 'lodash';
import { Subscription, throwError } from 'rxjs';

import { TargetEditorComponent } from './target-editor/target-editor.component';
import {
  ReportTargets,
  ReportsTargetsByStage,
  orderedStagesBySystemTypes,
  orderedTargetReport,
} from './targets.model';

@Component({
  templateUrl: './targets.component.html',
  styleUrls: ['./targets.component.scss'],
})
export class TargetsComponent implements OnInit, OnDestroy {
  @ViewChild('editor') targetEditor: TargetEditorComponent;

  reportTranslateKey = 'agriness.report.';
  tabTranslateKey = 'agriness.';
  tabTitle = 'agriness.settings.targets.title';

  systemType: TypeProductionEnum;
  subscription: Subscription;
  modalStatus = ModalStatusEnum.DEFAULT;

  sortedReportsTargetsByStage: ReportsTargetsByStage = {};

  tabs: StageEnum[] = [];
  selectedStage: StageEnum;
  targetSelected: Target;

  constructor(
    private targetService: TargetService,
    private typeProductionService: TypeProductionService,
  ) {}

  ngOnInit(): void {
    this.systemType = this.typeProductionService.get();
    this.subscription = this.loadTargetList();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getNumberOfReports(): number {
    return this.getReportsTargetsBySelectedStage().length;
  }

  getNumberOfStages(): number {
    return this.tabs.length;
  }

  getReportsTargetsBySelectedStage(): ReportTargets[] {
    return this.sortedReportsTargetsByStage[this.selectedStage] ?? [];
  }

  handleTargetSelect(target: Target): void {
    this.targetSelected = target;
  }

  loadTargetList(): Subscription {
    return this.targetService.list().subscribe(({ results }) => {
      this.initializeTabs();
      this.updateTargets(results);
    });
  }

  onEditorClosed(): void {
    this.targetSelected = null;
    this.modalStatus = ModalStatusEnum.DEFAULT;
    this.loadTargetList();
  }

  restoreTarget(): Subscription {
    const { id } = this.targetSelected;
    const is_default = true;

    return this.targetService.get(id, is_default).subscribe({
      error: ({ error }) => {
        this.modalStatus = ModalStatusEnum.FAILED;
        return throwError(error);
      },
      next: target => {
        this.targetEditor.setEditorValues(target);
      },
    });
  }

  updateSelectedStage(tab: StageEnum): void {
    this.selectedStage = tab;
  }

  updateTarget(): Subscription {
    const formValues = this.targetEditor.getForm();
    const { id } = this.targetSelected;

    this.modalStatus = ModalStatusEnum.LOADING;

    return this.targetService.update(id, formValues).subscribe({
      error: ({ error }) => {
        this.modalStatus = ModalStatusEnum.FAILED;
        return throwError(error);
      },
      next: () => {
        this.modalStatus = ModalStatusEnum.SUCCESS;
      },
    });
  }

  private updateTargets(targets: Target[]) {
    this.sortedReportsTargetsByStage = this.buildSortedReportsTargetsByStage(targets);
  }

  private initializeTabs(): void {
    this.tabs = orderedStagesBySystemTypes[this.systemType];
    if (!this.selectedStage) this.updateSelectedStage(this.tabs[0]);
  }

  private buildSortedReportsTargetsByStage(targets: Target[]): ReportsTargetsByStage {
    return chain(targets)
      .groupBy('stage')
      .mapValues(targets => this.buildSortedReportsTargets(targets))
      .value();
  }

  private buildSortedReportsTargets(targets: Target[]): ReportTargets[] {
    return chain(targets)
      .map((target): Target => defaults(target, { report: 'others' }))
      .groupBy('report')
      .entries()
      .map(([report, targets]: [TargetReportEnum | 'others', Target[]]) => ({
        report,
        targets: sortBy(targets, 'order'),
      }))
      .sortBy(reports => orderedTargetReport.indexOf(reports.report))
      .value();
  }
}
