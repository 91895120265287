import { Injectable } from '@angular/core';

import {
  OperatorsEnum,
  RepresentationTypeEnum,
  TargetData,
  TargetStatus,
} from '../../target.model';
import { TargetItem } from '../target-subtitles.model';
import { TargetSubtitlesService } from '../target-subtitles.service';

@Injectable()
export class TargetRepresentationConfig {
  private calculateTargetWithVariation: TargetSubtitlesService['calculateTargetWithVariation'];
  private roundToLower: TargetSubtitlesService['roundToLower'];
  private roundToHigher: TargetSubtitlesService['roundToHigher'];

  constructor(private service: TargetSubtitlesService) {
    this.calculateTargetWithVariation = this.service.calculateTargetWithVariation;
    this.roundToLower = this.service.roundToLower;
    this.roundToHigher = this.service.roundToHigher;
  }

  calculate(targetRepresentation, target) {
    return {
      [RepresentationTypeEnum.RANGE_BAD_ATTENTION_GOOD]: this.calculateRangeBadAttentionGoodConfig.bind(
        this,
      ),
      [RepresentationTypeEnum.RANGE_BAD_GOOD]: this.calculateRangeBadGoodConfig.bind(this),
      [RepresentationTypeEnum.SCALE_ATTENTION_GOOD_BAD]: this.calculateScaleAttentionGoodBadConfig.bind(
        this,
      ),
      [RepresentationTypeEnum.SCALE_BAD_ATTENTION_GOOD]: this.calculateScaleBadAttentionGoodConfig.bind(
        this,
      ),
      [RepresentationTypeEnum.SCALE_BAD_GOOD]: this.calculateScaleBadGoodConfig.bind(this),
      [RepresentationTypeEnum.SCALE_GOOD_ATTENTION_BAD]: this.calculateScaleGoodAttentionBadConfig.bind(
        this,
      ),
    }[targetRepresentation](target);
  }

  calculateRangeBadAttentionGoodConfig(target: TargetData): TargetItem[] {
    const [targetWithVariationMin, targetWithVariationMax] = this.calculateTargetWithVariation(
      target,
    );

    return [
      {
        status: TargetStatus.BAD,
        operator: OperatorsEnum.LOWER_THEN,
        value: [target.min_recommended],
      },
      {
        status: TargetStatus.ATTENTION,
        value: [
          target.min_recommended,
          this.roundToLower(targetWithVariationMin, target.index.decimal_places),
        ],
      },
      {
        status: TargetStatus.GOOD,
        value: this.calculateTargetWithVariation(target),
      },
      {
        status: TargetStatus.ATTENTION,
        value: [
          this.roundToHigher(targetWithVariationMax, target.index.decimal_places),
          target.max_recommended,
        ],
      },
      {
        status: TargetStatus.BAD,
        operator: OperatorsEnum.HIGHER_THEN,
        value: [target.max_recommended],
      },
    ];
  }

  calculateRangeBadGoodConfig(target: TargetData): TargetItem[] {
    return [
      {
        status: TargetStatus.BAD,
        operator: OperatorsEnum.LOWER_THEN,
        value: [target.min_recommended],
      },
      {
        status: TargetStatus.GOOD,
        value: [target.min_recommended, target.max_recommended],
      },
      {
        status: TargetStatus.BAD,
        operator: OperatorsEnum.HIGHER_THEN,
        value: [target.max_recommended],
      },
    ];
  }

  calculateScaleAttentionGoodBadConfig(target: TargetData): TargetItem[] {
    return [
      {
        status: TargetStatus.ATTENTION,
        operator: OperatorsEnum.LOWER_THEN,
        value: [target.min_recommended],
      },
      {
        status: TargetStatus.GOOD,
        value: [target.min_recommended, target.max_recommended],
      },
      {
        status: TargetStatus.BAD,
        operator: OperatorsEnum.HIGHER_THEN,
        value: [target.max_recommended],
      },
    ];
  }

  calculateScaleBadAttentionGoodConfig(target: TargetData): TargetItem[] {
    return [
      {
        status: TargetStatus.BAD,
        operator: OperatorsEnum.LOWER_THEN,
        value: [target.min_recommended],
      },
      {
        status: TargetStatus.ATTENTION,
        value: [
          target.min_recommended,
          this.roundToLower(target.target_value, target.index.decimal_places),
        ],
      },
      {
        status: TargetStatus.GOOD,
        operator: OperatorsEnum.HIGHER_THEN_OR_EQUAL,
        value: [target.target_value],
      },
    ];
  }

  calculateScaleBadGoodConfig(target: TargetData): TargetItem[] {
    return [
      {
        status: TargetStatus.BAD,
        operator: OperatorsEnum.LOWER_THEN,
        value: [target.target_value],
      },
      {
        status: TargetStatus.GOOD,
        operator: OperatorsEnum.HIGHER_THEN_OR_EQUAL,
        value: [target.target_value],
      },
    ];
  }

  calculateScaleGoodAttentionBadConfig(target: TargetData): TargetItem[] {
    return [
      {
        status: TargetStatus.GOOD,
        operator: OperatorsEnum.LOWER_THEN_OR_EQUAL,
        value: [target.target_value],
      },
      {
        status: TargetStatus.ATTENTION,
        value: [
          this.roundToHigher(target.target_value, target.index.decimal_places),
          target.max_recommended,
        ],
      },
      {
        status: TargetStatus.BAD,
        operator: OperatorsEnum.HIGHER_THEN,
        value: [target.max_recommended],
      },
    ];
  }
}
