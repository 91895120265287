import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoadingInterceptorSkipHeader } from '../services/abstract/api-abstract.service';
import { HttpService } from '../services/common/http.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private httpService: HttpService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(LoadingInterceptorSkipHeader)) {
      const headers = req.headers.delete(LoadingInterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }
    const result = next.handle(req).pipe(finalize(() => this.httpService.popRequest()));
    this.httpService.pushRequest();
    return result;
  }
}
