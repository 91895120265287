import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { PerformanceItems } from '../models/performance.model';

@Injectable()
export class ChartviewService {
  private pathAnimalGroupPerformanceGraph =
    'layers-production/multiple-flock/graph/animal-group/{id}/';

  constructor(private apiCorpService: ApiCorpService) {}

  getAnimalGroupLayersPerformanceGraph(
    idAnimalGroup: string | number,
    index: string,
    fixedIndex: string,
  ): Observable<PerformanceItems> {
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupPerformanceGraph, {
      id: idAnimalGroup,
    });
    return this.apiCorpService.get(url, { index: index, fixed_key: fixedIndex });
  }
}
