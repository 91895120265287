<ng-template #content>
  <ag-feedback [type]="feedbackType"></ag-feedback>

  <highcharts-chart
    (chartInstance)="registerChart($event)"
    *ngIf="!feedbackType"
    [Highcharts]="Highcharts"
    [options]="chartOptions"
    style="width: 100%; display: block;"
    class="pb-5"
  ></highcharts-chart>

  <ag-table
    #dt
    *ngIf="!feedbackType"
    [frozenColumns]="frozenTableColumns"
    [columns]="scrollableTableColumns"
    [data]="tableData"
    [scrollable]="true"
    (sort)="onSort($event)"
  >
    <ng-template pTemplate="bodycell" let-col="column" let-data="data" let-rowData="rowData">
      <td>
        <span>
          <ag-table-cell-content [data]="data" [column]="col"></ag-table-cell-content>
        </span>
      </td>
    </ng-template>
  </ag-table>
</ng-template>
