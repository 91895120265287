import { deprecatedColors } from '@agriness/assets/deprecated';
import {
  AnimalGroup,
  AnimalGroupAbstractService,
  AnimalGroupResume,
  MovementGraph,
  MovementTable,
  MovementTableItem,
} from '@agriness/corp-app/services';
import { HighchartsChart } from '@agriness/corp-app/shared/model/highcharts.model';
import { CardBuilderService } from '@agriness/corp-app/shared/services/card-builder.service';
import {
  HighchartsService,
  Highcharts,
} from '@agriness/corp-app/shared/services/highcharts.service';
import { AgrinessTranslateService, TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { TypeProductionEnum, UserStorageService } from '@agriness/services';
import { AgTableComponent, TableColumn, ValueFormatStyle, FeedbackEnum } from '@agriness/ui';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import { SelectItem } from 'primeng/api';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';

import { LoaderUserPreference } from '../../../shared/component/loader-user-preference';
import { TableService } from '../../../shared/services/table.service';
import { AnimalGroupStateService } from '../animal-group-state.service';
import { PerformanceModel } from './movement.model';

@Component({
  templateUrl: './movement.component.html',
})
export class MovementComponent extends LoaderUserPreference implements OnInit, OnDestroy {
  private static PREFIX = 'agriness.movement.table.';
  @ViewChild('dtInput') pTableInput: AgTableComponent;
  @ViewChild('dtOutPut') pTableOutPut: AgTableComponent;
  @ViewChild('chart') chartInstance: HighchartsChart;

  Highcharts: Highcharts;

  chartOptions = null;
  chartValues = {
    week: { index: [], serie1: [], serie2: [] },
    days: { index: [], serie1: [], serie2: [] },
  };
  tableValues: MovementTable = {};
  columns = { input: [] as TableColumn[], output: [] as TableColumn[] };
  seriesDataWeek: any;
  seriesDataDays: any;
  translateValues: Record<string, string> = {};
  objectKeys = Object.keys;
  populateChart: boolean;

  types: SelectItem[];
  selectedType = 'week';
  subPerformances = {
    swines: [this.getSubMovementPerformanceSwine.bind(this)],
  };
  movementsByProduct = {
    swines: [this.getSubMovementTable.bind(this)],
    poultry: [
      this.getSubMovementGraphPoultry.bind(this),
      this.getSubMovementTableAccommodation.bind(this),
      this.getSubMovementTableMortality.bind(this),
    ],
  };
  type = environment.typeProduction;
  TypeProduction = TypeProductionEnum;
  subscription: Subscription;
  typeFeedback = FeedbackEnum.LOADING;
  performances: PerformanceModel[] = [];

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private translate: AgrinessTranslateService,
    public tableService: TableService,
    private cardBuilderService: CardBuilderService,
    protected userStorageService: UserStorageService,
    private animalGroupService: AnimalGroupAbstractService,
    private animalGroupStateService: AnimalGroupStateService,
    private highchartsService: HighchartsService,
  ) {
    super(userStorageService);
    this.t = translate.instantWithFallback;
    this.Highcharts = highchartsService.getHighchartsInstance();
  }

  ngOnInit(): void {
    this.typeFeedback = FeedbackEnum.LOADING;
    this.initTypes();
    this.subscription = this.animalGroupStateService
      .getAnimalGroup()
      .pipe(
        switchMap(animalGroup => {
          return this.loadData(animalGroup);
        }),
      )
      .subscribe();
  }

  loadData(animalGroup: AnimalGroup): Observable<unknown> {
    const observables = this.getObservables(animalGroup);

    return forkJoin(observables).pipe(
      tap(() => {
        if (
          !this.hasDataTableAcommodation() &&
          !this.hasDataTableMortality() &&
          !this.hasDataPerformance()
        ) {
          this.typeFeedback = FeedbackEnum.NOT_RESULT;
        } else {
          this.typeFeedback = null;
        }
      }),
      catchError(error => {
        console.error(error);
        this.typeFeedback = FeedbackEnum.ERROR;
        return of({ error });
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initTypes(): void {
    this.types = [
      { label: this.t('agriness.date.day.name'), value: 'days' },
      { label: this.t('agriness.date.week.name'), value: 'week' },
    ];
  }

  selectType(event: { value: string }): void {
    const chart = this.Highcharts.charts[this.Highcharts.charts.length - 1];
    while (chart.series.length > 0) {
      chart.series[0].remove(true);
    }

    chart.get('graph-axes').remove(true);
    chart.addAxis(
      {
        title: {
          text: this.translateValues[
            event.value === 'week'
              ? 'agriness.movement.period_week'
              : 'agriness.movement.period_day'
          ],
        },
        categories: this.chartValues[event.value].index,
        id: 'graph-axes',
      },
      true,
      false,
    );

    const serie = event.value === 'week' ? this.seriesDataWeek : this.seriesDataDays;
    serie.forEach(item => {
      chart.addSeries(item);
    });
  }

  getColumnStyle(column: string): ValueFormatStyle {
    if (column === 'weight' || column === 'age') {
      return ValueFormatStyle.DECIMAL;
    } else if (column.endsWith('_percent')) {
      return ValueFormatStyle.PERCENTAGE;
    } else {
      return ValueFormatStyle.UNITARY;
    }
  }

  private getObservables(animalGroup: AnimalGroup): Observable<any>[] {
    return [this.subPerformance(animalGroup), this.subTrans(), this.subAnimal(animalGroup)];
  }

  private subTrans(): Observable<any> {
    return this.translate
      .get([
        'agriness.movement.input_quantity',
        'agriness.movement.output_quantity',
        'agriness.movement.period_week',
        'agriness.movement.period_day',
      ])
      .pipe(
        tap((result: Record<string, string>) => {
          this.translateValues = result;
        }),
      );
  }

  private subAnimal(animalGroup: AnimalGroup): Observable<any> {
    const farmId = animalGroup.farm.id;
    const animalGroupId = animalGroup.id;
    return forkJoin(this.callMovements(farmId, animalGroupId)).pipe(tap(() => this.buildColumns()));
  }

  private subPerformance(animalGroup: AnimalGroup): Observable<any> {
    return forkJoin(this.callPerformance(animalGroup));
  }

  private getSubMovementPerformanceSwine(animalGroup: AnimalGroup): Observable<AnimalGroupResume> {
    return this.animalGroupService
      .getAnimalGroupMovementPerformance(this.holdingId, animalGroup.farm.id, animalGroup.id)
      .pipe(
        tap(animalGroupResume => {
          this.performances = [this.parseToMovementModel(animalGroupResume)];
        }),
      );
  }

  private parseToMovementModel(
    { title, performances = [] }: AnimalGroupResume,
    prefixKeyTranslate?: string | string[],
  ): PerformanceModel {
    return {
      title,
      cards: performances.map(performance =>
        this.cardBuilderService.parsePerformanceToCardModel(performance, prefixKeyTranslate),
      ),
    };
  }

  private getSubMovementGraphPoultry(
    farmId: string | number,
    animalGroupId: string | number,
  ): Observable<MovementGraph> {
    return this.getAnimalGroupMovementGraph(farmId, animalGroupId).pipe(
      tap(data => this.populateMovementTotals(data)),
    );
  }

  private getSubMovementTable(
    farmId: string | number,
    animalGroupId: string | number,
  ): Observable<unknown> {
    return this.animalGroupService
      .getAnimalGroupMovementTable(this.holdingId, farmId, animalGroupId)
      .pipe(
        switchMap(data => this.translateData(data)),
        tap(data => {
          this.tableValues = data;
          this.tableValues['total_inputs'] = this.calculateTotal(data['input']);
          this.tableValues['total_outputs'] = this.calculateTotal(data['output']);
        }),
      );
  }

  private getSubMovementTableAccommodation(
    farmId: string | number,
    animalGroupId: string | number,
  ): Observable<MovementTable> {
    return this.animalGroupService
      .getAnimalGroupMovementTableAccommodation(this.holdingId, farmId, animalGroupId)
      .pipe(
        tap(data => {
          Object.assign(this.tableValues, data);
        }),
      );
  }

  private getSubMovementTableMortality(
    farmId: string | number,
    animalGroupId: string | number,
  ): Observable<MovementTable> {
    return this.animalGroupService
      .getAnimalGroupMovementTableMortality(this.holdingId, farmId, animalGroupId)
      .pipe(
        tap(data => {
          Object.assign(this.tableValues, data);
        }),
      );
  }

  private getAnimalGroupMovementGraph(
    farmId: string | number,
    animalGroupId: string | number,
  ): Observable<MovementGraph> {
    return this.animalGroupService
      .getAnimalGroupMovementGraph(this.holdingId, farmId, animalGroupId)
      .pipe(
        tap(data => {
          if (!data) {
            this.populateChart = false;
            return;
          }
          this.populateData(data, 'week');
          this.populateData(data, 'days');
          this.populateChart = true;
          this.buildOption();
        }),
      );
  }

  private callMovements(
    farmId: string | number,
    animalGroupId: string | number,
  ): Observable<AnimalGroupResume>[] {
    if (!(this.type in this.movementsByProduct)) return [of(<AnimalGroupResume>[])];
    return this.movementsByProduct[this.type].map(sub => sub.call(this, farmId, animalGroupId));
  }

  private callPerformance(animalGroup: AnimalGroup): Observable<AnimalGroupResume>[] {
    if (!(this.type in this.subPerformances)) return [of(<AnimalGroupResume>[])];
    return this.subPerformances[this.type].map(sub => sub.call(this, animalGroup));
  }

  private async buildColumns(data: MovementTable = this.tableValues) {
    const input = data.input;
    const output = data.output;
    const keys = (await this.getTranslateKeys(input, output)) as Record<string, string>;
    if (!_.isEmpty(input)) {
      this.columns.input = this.objectKeys(_.head(input))
        .filter(key => key != 'grouping')
        .map(key => ({
          field: key,
          header: keys[MovementComponent.PREFIX + 'input.' + key],
          style: this.getColumnStyle(key),
        }));
    }
    if (!_.isEmpty(output)) {
      this.columns.output = this.objectKeys(_.head(output))
        .filter(key => key != 'grouping')
        .map(key => ({
          field: key,
          header: keys[MovementComponent.PREFIX + 'output.' + key],
          style: this.getColumnStyle(key),
        }));
    }
  }

  private async getTranslateKeys(input: MovementTableItem[], output: MovementTableItem[]) {
    let keys = [];
    if (!_.isEmpty(input)) {
      keys = _.concat(
        keys,
        this.objectKeys(input[0]).map(key => MovementComponent.PREFIX + 'input.' + key),
      );
    }
    if (!_.isEmpty(output)) {
      keys = _.concat(
        keys,
        this.objectKeys(output[0]).map(key => MovementComponent.PREFIX + 'output.' + key),
      );
    }
    if (_.isEmpty(keys)) {
      return Promise.resolve({});
    }
    return await this.translate.get(_.uniq(keys)).toPromise().then();
  }

  private async translateData(data: MovementTable) {
    const inputItens = data.input as MovementTableItem[];
    let promises = inputItens.map(
      async item =>
        (item.grouping_name = await this.translate
          .get(`${MovementComponent.PREFIX}input.grouping_item.${item.grouping}`)
          .toPromise()
          .then()),
    );
    await Promise.all(promises);

    const outputItens = data.output as MovementTableItem[];
    promises = outputItens.map(
      async item =>
        (item.grouping_name = await this.translate
          .get(`${MovementComponent.PREFIX}output.grouping_item.${item.grouping}`)
          .toPromise()
          .then()),
    );
    await Promise.all(promises);

    return data;
  }

  private calculateTotal(data: []): number {
    let total = 0;
    data.forEach(item => {
      total = total += item['total'] || 0;
    });
    return total;
  }

  private populateData(data: MovementGraph, key: keyof MovementGraph): void {
    if (!data || !data[key]) {
      return;
    }
    for (let index = 0; index < data[key].length; index++) {
      this.chartValues[key].index.push(data[key][index]['index']);

      const result = data[key][index];

      result.input_value > 0
        ? this.chartValues[key].serie1.push(result.input_value)
        : this.chartValues[key].serie1.push(null);
      if (result['output_value'] !== undefined) {
        result.output_value > 0
          ? this.chartValues[key].serie2.push(result.output_value)
          : this.chartValues[key].serie2.push(null);
      }
    }
  }

  private populateMovementTotals(data: MovementGraph) {
    this.tableValues['total_inputs'] = data['total']['input'];
    this.tableValues['total_outputs'] = data['total']['output'];
  }

  private hasDataTableAcommodation(): boolean {
    return !_.isEmpty(this.tableValues) && !_.isEmpty(this.tableValues.input);
  }

  private hasDataTableMortality(): boolean {
    return !_.isEmpty(this.tableValues) && !_.isEmpty(this.tableValues.output);
  }

  private hasDataPerformance(): boolean {
    return !_.isEmpty(this.performances);
  }

  private buildOption(): void {
    this.seriesDataWeek = [];
    if (this.chartValues.week.serie1.length) {
      this.seriesDataWeek.push({
        name: this.translateValues['agriness.movement.input_quantity'],
        data: this.chartValues.week.serie1,
        color: deprecatedColors.purple2,
      });
    }
    if (this.chartValues.week.serie2.length) {
      this.seriesDataWeek.push({
        name: this.translateValues['agriness.movement.output_quantity'],
        data: this.chartValues.week.serie2,
        color: deprecatedColors.aqua3,
      });
    }

    this.seriesDataDays = [];
    if (this.chartValues.days.serie1.length) {
      this.seriesDataDays.push({
        name: this.translateValues['agriness.movement.input_quantity'],
        data: this.chartValues.days.serie1,
        color: deprecatedColors.purple2,
      });
    }
    if (this.chartValues.days.serie2.length) {
      this.seriesDataDays.push({
        name: this.translateValues['agriness.movement.output_quantity'],
        data: this.chartValues.days.serie2,
        color: deprecatedColors.aqua3,
      });
    }

    this.chartOptions = {
      chart: {
        type: 'column',
        height: 400,
      },
      title: {
        text: '',
      },
      xAxis: {
        title: {
          text: this.translateValues['agriness.movement.period_week'],
        },
        categories: this.chartValues.week.index,
        id: 'graph-axes',
      },
      yAxis: {
        title: {
          text: null,
        },
        opposite: true,
      },
      series: Object.assign([], this.seriesDataWeek),
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      legend: {
        enabled: true,
        align: 'right',
      },
      credits: {
        enabled: false,
      },
      exporting: { enabled: false },
    };
  }
}
