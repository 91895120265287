import { QuadrantAxisIntervalType, QuadrantsAxes } from '@agriness/corp-app/services';
import { Interval } from '@agriness/corp-app/shared/component/corp-identify/corp-identify.model';
import { IndexTranslateService } from '@agriness/corp-app/shared/services/index-translate.service';
import { TRANSLATE_INSTANT, TranslateInstant, TypeProductionService } from '@agriness/services';
import { StageEnum } from '@agriness/services';
import { PropertyName, SiteByStage } from '@agriness/services/analytics/analytics.model';
import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import { AgPopoverComponent } from '@agriness/ui';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'corp-identify-custom-interval',
  templateUrl: './corp-identify-custom-interval.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpIdentifyCustomIntervalComponent implements OnInit, OnChanges {
  @ViewChild('popover') popOver: AgPopoverComponent;
  @Input() quadrantsAxes: QuadrantsAxes;
  @Input() stage: StageEnum;
  @Output() visualize = new EventEmitter<Interval>();

  form: FormGroup;
  defaultInterval: Interval;
  xIntervalType: QuadrantAxisIntervalType;
  yIntervalType: QuadrantAxisIntervalType;
  isXReversed: boolean;
  isYReversed: boolean;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private formBuilder: FormBuilder,
    private indexTranslate: IndexTranslateService,
    private analyticsService: AmplitudeAnalyticsService,
    private typeProductionService: TypeProductionService,
  ) {}

  ngOnInit(): void {
    this.xIntervalType = this.quadrantsAxes.axis_x.intervals.bad[0][0];
    this.yIntervalType = this.quadrantsAxes.axis_y.intervals.bad[0][0];
    this.isXReversed = this.xIntervalType === 'LESS_THAN';
    this.isYReversed = this.yIntervalType === 'LESS_THAN';
    this.defaultInterval = this.getCustomInterval();
    this.onChanges();
  }

  ngOnChanges(): void {
    this.onChanges();
  }

  onVisualize(): void {
    this.visualize.emit(this.getIntervalFromForm());
    this.popOver.close();
    this.analyticsService.logSimulateQuadrants(
      PropertyName.SIMULATE_USED,
      SiteByStage[this.typeProductionService.get()][this.stage],
    );
  }

  onReset(): void {
    this.updateForm(this.defaultInterval);
  }

  onClose(): void {
    this.popOver.close();
  }

  updateReadOnlyFields(): void {
    this.updateForm(this.buildReadOnlyFields(this.getIntervalFromForm()));
  }

  getIndexName(indexKey: string): string {
    return this.indexTranslate.instant(indexKey, null, this.stage);
  }

  private onChanges(): void {
    this.updateForm(this.getCustomInterval());
  }

  private updateForm(interval: Interval): void {
    this.form = this.formBuilder.group(interval);
  }

  private getCustomInterval(): Interval {
    return this.buildReadOnlyFields(this.getIntervalFromQuadrantAxes());
  }

  private getIntervalFromQuadrantAxes(): Interval {
    let [, x_max, x_min] = this.quadrantsAxes.axis_x.intervals.good[0];
    if (this.isXReversed) {
      [x_max, x_min] = [x_min, x_max];
    }

    let [, y_max, y_min] = this.quadrantsAxes.axis_y.intervals.good[0];
    if (this.isYReversed) {
      [y_max, y_min] = [y_min, y_max];
    }

    return {
      x_max,
      x_min,
      y_max,
      y_min,
    };
  }

  private getIntervalFromForm(): Interval {
    const { x_max, x_min, y_max, y_min } = this.form.value as Interval;
    return { x_max, x_min, y_max, y_min };
  }

  private buildReadOnlyFields(interval: Interval): Interval {
    const { x_max, x_min, y_max, y_min } = interval;
    const [x_max_readonly, x_min_readonly] = this.isXReversed ? [x_min, x_max] : [x_max, x_min];
    const [y_max_readonly, y_min_readonly] = this.isYReversed ? [y_min, y_max] : [y_max, y_min];

    return {
      x_max,
      x_max_readonly: `${this.isXReversed ? '<' : '>'} ${x_max_readonly}`,
      x_min,
      x_min_readonly: `${this.isXReversed ? '>' : '<'} ${x_min_readonly}`,
      y_max,
      y_max_readonly: `${this.isYReversed ? '<' : '>'} ${y_max_readonly}`,
      y_min,
      y_min_readonly: `${this.isYReversed ? '>' : '<'} ${y_min_readonly}`,
    };
  }
}
