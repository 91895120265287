import { SectionAbstractComponent } from '@agriness/corp-app/shared/component/section-abstract.component';
import { SectionModel, SectionService } from '@agriness/ui';
import { Component } from '@angular/core';

@Component({
  selector: 'payment-rule-section',
  templateUrl: './payment-rule-section.component.html',
})
export class PaymentRuleSectionComponent extends SectionAbstractComponent {
  constructor(protected sectionService: SectionService) {
    super(sectionService);
  }

  protected getSections(): SectionModel[] {
    return [];
  }
}
