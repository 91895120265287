import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { PipesModule } from '../../pipe/pipes.module';
import { AgFormatComponent } from './ag-format.component';

@NgModule({
  imports: [SharedModule, PipesModule],
  declarations: [AgFormatComponent],
  exports: [AgFormatComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgFormatModule {}
