import { Search, systemType, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { Route, RouteFilter } from '../models/route.model';
import { RouteAbstractService } from './route-abstract.service';

@Injectable()
export class RouteService extends RouteAbstractService {
  private pathBase = 'holdings/{holding_id}/routes/';
  private defaultPageSize = 10;

  constructor(
    protected apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
  ) {
    super();
  }

  list({
    page = 1,
    page_size = this.defaultPageSize,
    ...filters
  }: RouteFilter): Observable<Search<Route>> {
    const url = this.apiCorpService.translateUrl(this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
    });
    const params = {
      ...filters,
      page,
      page_size,
      system_type: systemType[environment.typeProduction],
    };
    return this.apiCorpService.get<Search<Route>>(url, params);
  }
}
