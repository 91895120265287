<section>
  <div class="page-title d-flex align-items-center mb-3">
    <corp-back-button></corp-back-button>
    {{ pageTitle | translate }}
  </div>
  <app-corp-wizzard
    [items]="wizzardItems"
    (emitChangeIndex)="setActiveIndex($event)"
    (saveForm)="saveForm()"
    [isComplete]="isValid"
    (emitNext)="next()"
    [canNext]="stepValid | async"
  >
    <app-target-config-general-data
      *ngIf="activeIndex === 0"
      (isValid)="setValid($event, 0)"
      (sendData)="setData($event)"
      [formData]="data.asObservable()"
    ></app-target-config-general-data>
    <app-target-config-rules
      *ngIf="activeIndex === 1"
      (isValid)="setValid($event, 1)"
      (sendData)="setData($event)"
      [formData]="data.asObservable()"
    ></app-target-config-rules>
    <target-config-end
      *ngIf="activeIndex === 2"
      [formData]="data.asObservable()"
    ></target-config-end>
  </app-corp-wizzard>
</section>
