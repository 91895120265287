import { NgModule } from '@angular/core';

import { AnimalGroupListService } from './animal-group-list/animal-group-list.service';
import { AnimalGroupAbstractService } from './animal-group/animal-group-abstract.service';
import { AnimalGroupService } from './animal-group/animal-group.service';
import { MapIndicatorsAbstractService } from './animal-group/map-indicators-abstract.service';
import { MapIndicatorsService } from './animal-group/map-indicators.service';
import { chartResizeServiceProvider } from './chart-resize/chart-resize.service';
import { ChartviewService } from './chartview/chartview.service';
import { ComfortService } from './comfort/comfort.service';
import { CompanyService } from './company/company.service';
import { FarmAbstractService } from './farm/farm-abstract.service';
import { FarmService } from './farm/farm.service';
import { HelpAgrinessService } from './help-agriness/help-agriness.service';
import { InventoryService } from './inventory/inventory.service';
import { LocationAbstractService } from './location/location-abstract.service';
import { LocationService } from './location/location.service';
import { LookerService } from './looker/looker.service';
import { MonitorAbstractService } from './monitor/monitor-abstract.service';
import { MonitorService } from './monitor/monitor.service';
import { StructureProductionAbstractService } from './movement/structure-production-abastract.service';
import { StructureProductionService } from './movement/structure-production.service';
import { NutritionTypeService } from './nutrition-type/nutrition-type.service';
import { PerformanceAnalysisFormatterService } from './performance-analysis/performance-analysis-formatter.service';
import { PerformanceAnalysisService } from './performance-analysis/performance-analysis.service';
import { ProducerAbstractService } from './producer/producer-abstract.service';
import { ProducerService } from './producer/producer.service';
import { RegionAbstractService } from './region/region-abstract.service';
import { RegionService } from './region/region.service';
import { ReportDashboardAbstractService } from './report-dashboard/report-dashboard-abstract.service';
import { ReportDashboardService } from './report-dashboard/report-dashboard.service';
import { ReportFavoriteAbstractService } from './report-dashboard/report-favorite-abstract.service';
import { ReportFavoriteService } from './report-dashboard/report-favorites.service';
import { IndexMapGeneratorAbstractService } from './report/index-map-generator-abstract.service';
import { IndexMapGeneratorService } from './report/index-map-generator.service';
import { RouteAbstractService } from './route/route-abstract.service';
import { RouteService } from './route/route.service';
import { RoutesAbstractService } from './routes/routes-abstract.service';
import { RoutesService } from './routes/routes.service';
import { ScheduleMonitorAbstractService } from './schedule-monitor/schedule-monitor-abstract.service';
import { ScheduleMonitorService } from './schedule-monitor/schedule-monitor.service';
import { SiteService } from './site/site.service';
import { StrainService } from './strain/strain.service';
import { TagAbstractService } from './tag/tag-abstract.service';
import { TagService } from './tag/tag.service';
import { TargetStatusKpiService } from './target-status-kpi/target-status-kpi.service';
import { TargetIndexAbstractService } from './target/target-index-abstract.service';
import { TargetIndexService } from './target/target-index.service';
import { TechnicianAbstractService } from './technician/technician-abstract.service';
import { TechnicianService } from './technician/technician.service';
import { UserHoldingPermissionService } from './user/user-holding-permission.service';
import { UserPreferencesAbstractService } from './user/user-preferences-abstract.service';
import { UserPreferencesService } from './user/user-preferences.service';

@NgModule({
  providers: [
    AnimalGroupListService,
    ChartviewService,
    ComfortService,
    CompanyService,
    InventoryService,
    LookerService,
    NutritionTypeService,
    PerformanceAnalysisService,
    PerformanceAnalysisFormatterService,
    SiteService,
    StrainService,
    TargetStatusKpiService,
    chartResizeServiceProvider,
    HelpAgrinessService,
    { provide: AnimalGroupAbstractService, useClass: AnimalGroupService },
    { provide: FarmAbstractService, useClass: FarmService },
    { provide: IndexMapGeneratorAbstractService, useClass: IndexMapGeneratorService },
    { provide: LocationAbstractService, useClass: LocationService },
    { provide: MapIndicatorsAbstractService, useClass: MapIndicatorsService },
    { provide: MonitorAbstractService, useClass: MonitorService },
    { provide: ScheduleMonitorAbstractService, useClass: ScheduleMonitorService },
    { provide: ProducerAbstractService, useClass: ProducerService },
    { provide: RegionAbstractService, useClass: RegionService },
    { provide: TargetIndexAbstractService, useClass: TargetIndexService },
    { provide: RoutesAbstractService, useClass: RoutesService },
    { provide: ReportDashboardAbstractService, useClass: ReportDashboardService },
    { provide: ReportFavoriteAbstractService, useClass: ReportFavoriteService },
    { provide: RouteAbstractService, useClass: RouteService },
    { provide: TagAbstractService, useClass: TagService },
    { provide: TechnicianAbstractService, useClass: TechnicianService },
    { provide: UserPreferencesAbstractService, useClass: UserPreferencesService },
    { provide: StructureProductionAbstractService, useClass: StructureProductionService },
    { provide: UserHoldingPermissionService },
  ],
})
export class CorpServicesModule {}
