import { TypeProductionService } from '@agriness/services';
import { ValueFormatPipe } from '@agriness/ui';
import { Injectable } from '@angular/core';
import { Table } from 'primeng/table';

import { Column, RowObject } from '../model/table.model';

type FormatTableValues = string | ((dataTable: DataTableExport) => string);

@Injectable({ providedIn: 'root' })
export class TableService {
  constructor(
    private valueFormat: ValueFormatPipe,
    private typeProductionService: TypeProductionService,
  ) {
    this.evalValueExportTable = this.evalValueExportTable.bind(this);
  }

  exportCSV(table: Table, formatTableValues?: FormatTableValues): void {
    table.csvSeparator = this.typeProductionService.isLayers() ? ',' : ';';
    table.exportFunction = formatTableValues ? formatTableValues : this.evalValueExportTable;
    table.exportCSV();
  }

  evalValueExportTable(dataTable: DataTableExport): string {
    const rowObject = dataTable.data;
    if (rowObject != null && Object.prototype.hasOwnProperty.call(rowObject, 'value')) {
      return this.valueFormat.transform(
        rowObject.value,
        rowObject.measurement_unit,
        rowObject.decimal_places,
        'hide-symbol',
      );
    } else {
      return this.valueFormat.transform(dataTable.data, null, null, 'hide-symbol');
    }
  }

  buildColumn(field: string, header: string, width?: string, tooltip?: string): Column {
    return {
      id: `column-${field.toLowerCase().replace(/_/g, '-')}`,
      field: field,
      header: header,
      width: width,
      tooltip: tooltip,
    };
  }
}

export interface DataTableExport extends Table {
  data: RowObject;
  field: unknown;
  selectedData: unknown[];
}
