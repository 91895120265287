import {
  BaseEntityFilter,
  ContextFilter,
  PageNumberPagination,
  QueryCorp,
} from '@agriness/services';

export interface Producer {
  id: string;
  name: string;
  farm_count: number;
  animalgroup_count: number;
}

export interface ProducerForm {
  name: string;
}

export const defaultProducerOrder: (keyof Producer)[] = ['name'];

export type ProducerFilter = BaseEntityFilter & PageNumberPagination & ContextFilter & QueryCorp;
