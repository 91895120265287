<div
  data-testid="status-target-representation-container"
  class="status-target-representation-container"
>
  <div
    *ngFor="let item of statusItems; let index = index"
    class="status-target-representation-items"
    [ngStyle]="item.overflow ? { overflow: 'visible', 'z-index': index + 1 } : {}"
  >
    <div>
      <ag-target-indicator
        *ngIf="item.iconPosition"
        [class]="'icon-content-' + item.iconPosition"
        [attr.data-testid]="'status-target-representation-icon-' + item.status"
        [status]="item.status"
        [iconSize]="iconSize"
      >
      </ag-target-indicator>
    </div>
    <div
      [ngStyle]="item.overflow ? { width: item.overflow + '%' } : {}"
      [class]="item.noBarSeparator ? 'bar-content-without-separator' : 'bar-content'"
      [attr.data-testid]="'status-target-representation-bar-' + item.status"
    >
      <ag-horizontal-status-bar [items]="[item]"> </ag-horizontal-status-bar>
    </div>
  </div>
</div>
