import {
  displayIcon,
  DisplayOption,
  displayOptionNumeral,
  DisplayType,
  PerformanceData,
} from '@agriness/corp-app/record/animal-group/performance/performance.model';
import {
  GetMonitorPerformanceArgs,
  PerformanceItems,
  UserPreferencesAbstractService,
} from '@agriness/corp-app/services';
import {
  AnalysisInterval,
  PerformanceAnalysisUrlFilter,
  Report,
  SearchFilterPerformanceAnalysis,
} from '@agriness/corp-app/services/models/performance-analysis.model';
import { PerformanceAnalysisFormatterService } from '@agriness/corp-app/services/performance-analysis/performance-analysis-formatter.service';
import { PerformanceAnalysisService } from '@agriness/corp-app/services/performance-analysis/performance-analysis.service';
import { RadioOption } from '@agriness/corp-app/shared/component/corp-radio-options/corp-radio-options.model';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import {
  DateService,
  StageEnum,
  TypeProductionEnum,
  TypeProductionService,
  UserStorageService,
} from '@agriness/services';
import { FeedbackEnum, FeedbackPosition, Period, TableColumn } from '@agriness/ui';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { subYears } from 'date-fns';
import { Chart } from 'highcharts';
import { isEmpty } from 'lodash';
import { Table } from 'primeng/table';
import { forkJoin, Observable, Subscription, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ReportFilterQuery, ReportFilterType } from '../../model/report-filter.model';
import { onSortRowObject } from '../../model/table.model';
import { TableService } from '../../services/table.service';
import { CardsModel } from '../corp-report-cards/corp-report-cards.model';
import { CorpReportFiltersComponent } from '../corp-report-filters/corp-report-filters.component';
import { nullSelectFilterParamId } from '../corp-report-filters/corp-report-filters.model';
import { LoaderUserPreference } from '../loader-user-preference';
import { WalkthroughSimulationType } from './corp-performance-analysis-simulation/corp-performance-analysis-simulation.model';
import {
  defaultDisplayOptionByTypeProduction,
  defaultIndicatorOptionByTypeProduction,
  displayOptionsByTypeProduction,
  filterIdsPerformanceAnalysisByStageAndGroup,
  PerformanceAnalysisGroup,
  PerformanceAnalysisTabReport,
} from './corp-performance-analysis.model';
import { CorpReportPerformanceAnalysisCardsComponent } from './corp-report-performance-analysis-cards/corp-report-performance-analysis-cards.component';
import { PerformanceAnalysisPerformance } from './corp-report-performance-analysis-cards/corp-report-performance-analysis.model';

@Component({
  selector: 'corp-performance-analysis',
  styleUrls: ['./corp-performance-analysis.component.scss'],
  templateUrl: './corp-performance-analysis.component.html',
})
export class CorpPerformanceAnalysisComponent extends LoaderUserPreference
  implements OnInit, OnDestroy {
  @Input() tabs: PerformanceAnalysisTabReport[] = [];

  /**
   * Meant for graph rendering customization, the template will
   * receive a [PerformanceData] as a $implicit.
   *
   * Defaults to a standard graph template
   */
  @Input() graphTemplate?: TemplateRef<PerformanceData>;

  /**
   * The interval used for the analysis request
   * that is sent to the backend
   */
  @Input() analysisInterval?: AnalysisInterval;

  @ViewChild('reportFilter', { static: true }) reportFilter: CorpReportFiltersComponent;

  @ViewChild('cards') performanceCards: CorpReportPerformanceAnalysisCardsComponent;

  @ViewChildren('dt') pTables: QueryList<Table>;

  sectionName = 'agriness.performance_indicators';
  chartHeight = '400px';
  chart: Chart;
  filterIds: ReportFilterType[];
  FeedbackPosition = FeedbackPosition;
  performanceIndicatorFeedbackType = FeedbackEnum.LOADING;
  chartsFeedbackType = FeedbackEnum.LOADING;
  globalFeedbackType = FeedbackEnum.LOADING;
  stage: StageEnum;
  typeProduction: TypeProductionEnum;
  dateFormat: string;
  performanceAnalysisGroup: PerformanceAnalysisGroup;
  selectedPerformanceIndicator: string;
  performanceData: PerformanceData = {
    displayType: DisplayType.CHART,
    displayOption: DisplayOption.BY_DATE,
    data: [],
  };
  rawPerformanceData: PerformanceItems;
  displayOptions: DisplayOption[];
  tableViewData: PerformanceItems;
  tableHeaders: Set<string> = new Set();
  performances: CardsModel[] = [];
  groupsWithPeriod = [PerformanceAnalysisGroup.PERIOD, PerformanceAnalysisGroup.SEQUENCE];
  frozenColumns = new Set(['current_age', 'count', 'date', 'month', 'target']);
  frozenTableColumns: TableColumn[] = [];
  scrollableTableColumns: TableColumn[] = [];
  optionsPerformanceIndicator: RadioOption[];
  downloadFilename: string;
  selectedPeriods: Period[] = [];
  groupByOptions = [PerformanceAnalysisGroup.SEQUENCE, PerformanceAnalysisGroup.YEAR];
  selectedTab: PerformanceAnalysisTabReport | null;
  activeFlockRoute = [
    '/',
    this.typeProductionService.get().toString(),
    this.route.snapshot.data.stage as string,
    'analysis',
    'animal-group-list',
  ];

  onSort = onSortRowObject;
  simulationData: WalkthroughSimulationType;

  /**
   * Determines if the left side of the graph (usually a list of radio buttons)
   * will appear.
   *
   * This variable will be false if the number of [optionsPerformanceIndicator]
   * is less than 2
   */
  showPerformanceIndicatorOptions = false;

  /**
   * Tab data load subscription
   */
  private tabSubscription: Subscription = Subscription.EMPTY;

  /**
   * Performance cards subscription
   */
  private perfCardsSubscription: Subscription = Subscription.EMPTY;

  /**
   * Graphs and table request subscription
   */
  private graphsSubscription: Subscription = Subscription.EMPTY;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    public tableService: TableService,
    private route: ActivatedRoute,
    private dateService: DateService,
    protected userStorageService: UserStorageService,
    private performanceAnalysisService: PerformanceAnalysisService,
    private performanceAnalysisFormatter: PerformanceAnalysisFormatterService,
    private typeProductionService: TypeProductionService,
    private userPreferencesService: UserPreferencesAbstractService,
  ) {
    super(userStorageService);
  }

  ngOnDestroy(): void {
    this.tabSubscription.unsubscribe();
    this.perfCardsSubscription.unsubscribe();
    this.graphsSubscription.unsubscribe();
  }

  ngOnInit(): void {
    let performanceAnalysisGroupAsFilter: PerformanceAnalysisGroup;
    if (!this.performanceAnalysisGroup) {
      this.route.url
        .subscribe(urlSegment => {
          const [performanceAnalysisGroup] = urlSegment.map(url => url.path);

          performanceAnalysisGroupAsFilter = performanceAnalysisGroup as PerformanceAnalysisGroup;
        })
        .unsubscribe();
    }

    this.dateFormat = this.dateService.getDateFormat();
    this.stage = this.route.snapshot.data.stage as StageEnum;
    this.filterIds =
      filterIdsPerformanceAnalysisByStageAndGroup[this.stage][performanceAnalysisGroupAsFilter];
    this.performanceAnalysisGroup = this.getDefaultPerformanceAnalysisGroupByFilter(
      performanceAnalysisGroupAsFilter,
    );
    this.typeProduction = this.typeProductionService.get();
    this.selectedPeriods = this.getDefaultPeriods();
    this.initializeTabs();
    this.performanceData.displayOption = defaultDisplayOptionByTypeProduction[this.typeProduction];
    this.displayOptions = displayOptionsByTypeProduction[this.typeProduction];
  }

  registerChart(chart: Chart): void {
    this.chart = chart;
  }

  getCurrentFilter(): SearchFilterPerformanceAnalysis {
    const currentFilter = this.reportFilter.getCurrentFilterInQueryFormat();

    return {
      ...currentFilter,
      is_open: currentFilter.is_open ?? nullSelectFilterParamId,
      index_key: this.selectedPerformanceIndicator,
      group_by: this.performanceAnalysisGroup,
      periods: this.getPeriodsFilter(currentFilter),
      ...(this.analysisInterval ? { interval: this.analysisInterval } : {}),
      ...this.simulationData,
    };
  }

  getCurrentFilterWithAge(current_age: number): SearchFilterPerformanceAnalysis {
    return { ...this.getCurrentFilter(), begin_age: current_age, end_age: current_age };
  }

  async onFilterReady(): Promise<void> {
    await this.initializePerformanceIndicator();
    this.onChange();
  }

  filter(): void {
    this.onChange();
    this.perfCardsSubscription.unsubscribe();
    this.perfCardsSubscription = this.loadPerformanceCards()?.subscribe();
  }

  changePerformanceIndicator(event: { value: string }): void {
    this.selectedPerformanceIndicator = event.value;
    this.onChange();
  }

  changePeriods(event: Period[]): void {
    this.selectedPeriods = event;
    this.onChange();
  }

  changeTab(event: PerformanceAnalysisTabReport): void {
    this.selectedTab = event;
    if (this.selectedTab.indicators.length) {
      this.setIndicatorsFromCache();
    } else {
      this.tabSubscription.unsubscribe();
      this.tabSubscription = this.loadPerformanceIndicators().subscribe(() => {
        this.setDefaultPerformanceIndicator();
        this.onChange();
      });
    }

    this.perfCardsSubscription.unsubscribe();
    this.perfCardsSubscription = this.loadPerformanceCards().subscribe();
  }

  changeGroupBy(groupBy: PerformanceAnalysisGroup): void {
    this.performanceData.displayOption =
      groupBy === PerformanceAnalysisGroup.YEAR
        ? DisplayOption.BY_MONTH
        : defaultDisplayOptionByTypeProduction[this.typeProduction];
    this.performanceAnalysisGroup = groupBy;
    this.onChange();
  }

  changeDisplayOption(displayOption: DisplayOption): void {
    this.performanceData = {
      ...this.performanceData,
      displayOption,
    };
    this.performanceData.data = this.filterByDisplayOption(this.rawPerformanceData);
    this.tableViewData = this.buildTableViewData();
    this.loadColumns();
  }

  onChange(): void {
    this.loadPerformanceGraphs();
    this.updateDownloadFilename();
  }

  isChart(): boolean {
    return this.performanceData?.displayType === DisplayType.CHART;
  }

  isTable(): boolean {
    return this.performanceData?.displayType === DisplayType.TABLE;
  }

  nextDisplayIcon(): string {
    return displayIcon[
      this.getNextDisplayType(this.performanceData?.displayType || DisplayType.CHART)
    ];
  }

  onNextDisplayTypeClick(): void {
    this.performanceData.displayType = this.getNextDisplayType(this.performanceData.displayType);
  }

  isDairy(): boolean {
    return this.typeProductionService.isDairy();
  }

  getTitle(): string {
    if (this.showPerformanceIndicatorOptions) {
      return this.t('agriness.performance_analysis.chart_title');
    }

    return this.t(`agriness.performances.${this.selectedPerformanceIndicator}`);
  }

  changeSimulationInput(simulation: WalkthroughSimulationType): void {
    this.simulationData = simulation;
    this.filter();
  }

  private buildTableViewData(): PerformanceItems {
    this.tableHeaders.clear();

    return this.performanceData.data.map(performance => {
      const allPerformances = { ...performance };
      Object.keys(allPerformances).forEach(key => this.tableHeaders.add(key));
      return allPerformances;
    });
  }

  private updateDownloadFilename() {
    const currentFilter = this.getCurrentFilter();
    const dates = [
      this.dateService.formatAsIsoDate(currentFilter.begin_date),
      this.dateService.formatAsIsoDate(currentFilter.end_date),
    ];
    this.downloadFilename = [
      this.t('agriness.performance_analysis.title'),
      this.t(`agriness.performances.${this.selectedPerformanceIndicator}`),
      ...dates,
    ].join(' - ');
  }

  private getNextDisplayType(displayType: DisplayType): DisplayType {
    return displayType === DisplayType.CHART ? DisplayType.TABLE : DisplayType.CHART;
  }

  private async initializePerformanceIndicator(): Promise<void> {
    const indicatorFromUrl = this.route.snapshot.queryParamMap
      .get('performance_indicator')
      ?.toUpperCase();

    if (indicatorFromUrl) {
      for (const tab of this.tabs) {
        this.selectedTab = tab;
        await forkJoin([this.loadPerformanceIndicators(), this.loadPerformanceCards()]).toPromise();

        const foundInIndicators = this.isIndicatorInTabs(indicatorFromUrl);
        if (foundInIndicators) {
          this.selectedPerformanceIndicator = indicatorFromUrl;
          break;
        }

        const foundInCards = this.isIndicatorInCards(indicatorFromUrl);
        if (foundInCards) {
          this.selectedPerformanceIndicator = this.optionsPerformanceIndicator[0].value;
          break;
        }
      }
    } else {
      await forkJoin([this.loadPerformanceIndicators(), this.loadPerformanceCards()]).toPromise();
      if (!this.selectedPerformanceIndicator) {
        this.selectedPerformanceIndicator =
          defaultIndicatorOptionByTypeProduction[this.typeProduction];
      }
    }

    this.globalFeedbackType = null;
    this.onChange();
  }

  private isIndicatorInTabs(indicator: string): boolean {
    return this.selectedTab.indicators.some(({ value }) => value === indicator);
  }

  private isIndicatorInCards(indicator: string): boolean {
    const formatCardsIndicator = (id: string) => id.replace(/-/g, '_').toUpperCase();
    return this.performanceCards.cards.some(({ id }) => formatCardsIndicator(id) === indicator);
  }

  private setDefaultPerformanceIndicator(): void {
    this.selectedPerformanceIndicator = this.optionsPerformanceIndicator[0].value;
  }

  private initializeTabs(): void {
    this.selectedTab = this.tabs.length ? this.tabs[0] : null;
  }

  private loadPerformanceGraphs() {
    this.chartsFeedbackType = FeedbackEnum.LOADING;
    const searchFilter = this.getCurrentFilter();
    const urlFilter: PerformanceAnalysisUrlFilter = {
      holdingId: this.holdingId,
      typeProduction: this.typeProduction,
      stage: this.stage,
      report: Report.GRAPH,
    };

    this.graphsSubscription.unsubscribe();
    this.graphsSubscription = this.performanceAnalysisService
      .getPerformanceAnalysisGraph(urlFilter, searchFilter)
      .subscribe(
        performanceData => {
          this.chartsFeedbackType = isEmpty(performanceData) ? FeedbackEnum.NOT_RESULT : null;
          this.rawPerformanceData = this.formatPerformanceDataKeys(performanceData);
          this.performanceData.data = this.filterByDisplayOption(this.rawPerformanceData);
          this.tableViewData = this.buildTableViewData();
          this.loadColumns();
        },
        error => {
          this.chartsFeedbackType = FeedbackEnum.ERROR;

          return throwError(error);
        },
      );
  }

  private filterByDisplayOption(data: PerformanceItems) {
    const interval = displayOptionNumeral[this.performanceData.displayOption];
    if (interval) {
      return data.filter(item => (item.current_age.value as number) % interval === 0);
    }
    return data;
  }

  private loadPerformanceCards(): Observable<PerformanceAnalysisPerformance[]> {
    const args: GetMonitorPerformanceArgs = {
      holdingId: this.holdingId,
      stage: this.stage,
      report: this.selectedTab?.cardReport,
      filter: this.getCurrentFilter(),
    };

    return this.performanceCards.loadPerformanceCards(args);
  }

  private formatPerformanceDataKeys(items: PerformanceItems): PerformanceItems {
    if (!isEmpty(items) && this.groupsWithPeriod.includes(this.performanceAnalysisGroup)) {
      return this.performanceAnalysisFormatter.formatPeriods(items, this.typeProduction);
    }
    return items;
  }

  private getDefaultPerformanceAnalysisGroupByFilter(
    filter: PerformanceAnalysisGroup,
  ): PerformanceAnalysisGroup {
    return filter === PerformanceAnalysisGroup.PERFORMANCE_ANALYSIS
      ? PerformanceAnalysisGroup.SEQUENCE
      : filter;
  }

  private loadColumns(): void {
    const allTableColumns = this.getColumns();

    this.frozenTableColumns = [];
    this.scrollableTableColumns = [];
    allTableColumns.forEach(column => {
      if (this.frozenColumns.has(column.field)) {
        this.frozenTableColumns.push(column);
      } else {
        this.scrollableTableColumns.push(column);
      }
    });
  }

  private getColumns(): TableColumn[] {
    if (isEmpty(this.tableViewData)) {
      return [];
    }

    return [...this.tableHeaders].map(field => ({
      field,
      header: this.frozenColumns.has(field)
        ? this.t(`agriness.performance_analysis.${field}`)
        : field,
      sortable: true,
      width: '160px',
    }));
  }

  private loadPerformanceIndicators(): Observable<RadioOption[]> {
    const type = this.selectedTab.report;

    this.performanceIndicatorFeedbackType = FeedbackEnum.LOADING;
    return this.userPreferencesService.getDefaultViewPreference(type, this.stage).pipe(
      tap(report => {
        this.performanceIndicatorFeedbackType = isEmpty(report) ? FeedbackEnum.NOT_RESULT : null;
      }),
      map(report =>
        report.configurations.map(item => ({
          value: item.index_name,
          label: this.t(`agriness.performances.${item.index_name.toLowerCase()}`),
        })),
      ),
      tap(options => {
        this.setIndicatorOptions(options);
      }),
    );
  }

  private setIndicatorOptions(indicators: RadioOption[]): void {
    this.optionsPerformanceIndicator = indicators;
    this.showPerformanceIndicatorOptions = indicators.length > 1;
    this.selectedTab.indicators = indicators;
  }

  private setIndicatorsFromCache(): void {
    this.setIndicatorOptions(this.selectedTab.indicators);
    this.setDefaultPerformanceIndicator();
    this.onChange();
  }

  private getPeriodsFilter(currentFilter: ReportFilterQuery): string {
    return `${currentFilter.begin_date}--${currentFilter.end_date}`;
  }

  private getDefaultPeriods(): Period[] {
    const today = new Date();
    return [{ initialDate: subYears(today, 1), finalDate: today }];
  }
}
