import { SectionAbstractComponent } from '@agriness/corp-app/shared/component/section-abstract.component';
import { SectionModel, SectionService } from '@agriness/ui';
import { Component } from '@angular/core';
import { environment } from '@env/environment';

import { BasicRecordsSectionService } from './basic-records-section.service';

@Component({
  templateUrl: './basic-records-section.component.html',
})
export class BasicRecordsSectionComponent extends SectionAbstractComponent {
  typeProduction = environment.typeProduction;

  constructor(
    protected sectionService: SectionService,
    protected basicRecordsService: BasicRecordsSectionService,
  ) {
    super(sectionService);
  }

  protected getSections(): SectionModel[] {
    return this.basicRecordsService.getSections();
  }
}
