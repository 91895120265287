<div class="ag-layout-menu-help">
  <a [href]="url" target="_blank" class="help-btn">
    <i class="icon-help-outline"></i>
    {{ 'agriness.help' | translate }}
  </a>
  <div class="help-tooltip" *ngIf="false">
    <i class="icon-close" (click)="dismiss()"></i>
    <strong>{{ 'agriness.help_changed_tooltip.title' | translate }}</strong>
    <br />
    {{ 'agriness.help_changed_tooltip.message' | translate }}
  </div>
</div>
