import { Component, Input } from '@angular/core';

export enum BalanceEnum {
  CAPACITY = 'capacity',
  HATCHABLES = 'hatchables',
  INCUBATED = 'incubated',
  BALANCE = 'balance',
}

enum BalanceStatsuEnum {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

@Component({
  selector: 'ag-card-balance',
  templateUrl: './ag-card-balance.component.html',
  styleUrls: ['./ag-card-balance.component.scss'],
})
export class AgCardBalanceComponent {
  @Input() cell: unknown;
  @Input() status: string;
  @Input() balanceStatus: string;
  @Input() hatchingStatus: string;
  @Input() incubatedStatus: string;
  iconMapping: Record<BalanceEnum, string> = {
    [BalanceEnum.CAPACITY]: 'icon icon-farm mr-1',
    [BalanceEnum.HATCHABLES]: 'icon icon-layers-logo mr-1',
    [BalanceEnum.INCUBATED]: 'icon icon-hatchery mr-1',
    [BalanceEnum.BALANCE]: 'icon icon-good mr-1',
  };
  balanceEnum = BalanceEnum;

  loadBalanceIcon(key: string): string {
    if (key == BalanceEnum.BALANCE) {
      return this.status + ' ' + this.iconMapping[key];
    }
    return this.iconMapping[key];
  }

  returnZero(): number {
    return 0;
  }

  isValidValue(value: number): boolean {
    return !isNaN(value);
  }
}
