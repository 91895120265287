import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ag-toggle-button',
  templateUrl: './ag-toggle-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgToggleButtonComponent {
  @Input() isToggled = false;
  @Input() untoggledIconClass: string;
  @Input() toggledIconClass: string;
  @Output() toggle = new EventEmitter<boolean>();

  onToggle() {
    this.isToggled = !this.isToggled;
    this.toggle.emit(this.isToggled);
  }

  getButtonClass() {
    return this.isToggled ? 'toggle-button--on' : 'toggle-button--off';
  }

  getIconClass() {
    return this.isToggled ? this.toggledIconClass : this.untoggledIconClass;
  }
}
