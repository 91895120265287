<div class="columns is-multiline">
  <div class="column is-half-desktop">
    <div class="card shadow-sm">
      <div class="card-body">
        <div class="card-title">
          <span class="mr-2">
            {{ 'agriness.sanity.total_mortality' | translate }}
          </span>
          <span class="d-inline-flex">
            <button
              type="button"
              class="btn btn-ghost has-round-hover"
              (click)="onNextDisplayTypeClick('totalMortality')"
            >
              <span class="icon" [ngClass]="nextDisplayTypeIcon('totalMortality')"></span>
            </button>
            <button
              *ngIf="sanityDataByIndex.totalMortality.displayType === IndexDisplayType.TABLE"
              (click)="tableService.exportCSV(pTableTotalMortality)"
              class="btn btn-ghost has-round-hover"
            >
              <span class="icon icon-csv"></span>
            </button>
          </span>
        </div>

        <div [ngStyle]="{ minHeight: chartHeight }">
          <corp-sanity-poultry-graph
            *ngIf="sanityDataByIndex.totalMortality.displayType === IndexDisplayType.CHART"
            [data]="sanityDataByIndex.totalMortality.byDayChartData"
            yAxisLabel="agriness.sanity.total_mortality_y_axis"
            color="#e8ccf6"
            lineColor="#7e5b99"
          ></corp-sanity-poultry-graph>

          <ag-table
            #dtTotalMortality
            *ngIf="sanityDataByIndex.totalMortality.displayType === IndexDisplayType.TABLE"
            [exportFilename]="sanityDataByIndex.totalMortality.downloadFilename"
            [data]="sanityDataByIndex.totalMortality.byDayTableData.data"
            [columns]="sanityDataByIndex.totalMortality.byDayTableData.columns"
            [scrollable]="true"
            [style]="{ width: 'auto' }"
            [rows]="10"
            [scrollHeight]="chartHeight"
          ></ag-table>
        </div>
      </div>
    </div>
  </div>

  <div class="column is-half-desktop">
    <div class="card shadow-sm">
      <div class="card-body">
        <div class="card-title">
          <span class="mr-2">
            {{ 'agriness.sanity.eliminated' | translate }}
          </span>
          <span class="d-inline-flex">
            <button
              type="button"
              class="btn btn-ghost has-round-hover"
              (click)="onNextDisplayTypeClick('eliminated')"
            >
              <span class="icon" [ngClass]="nextDisplayTypeIcon('eliminated')"></span>
            </button>
            <button
              *ngIf="sanityDataByIndex.eliminated.displayType === IndexDisplayType.TABLE"
              (click)="tableService.exportCSV(pTableEliminated)"
              class="btn btn-ghost has-round-hover"
            >
              <span class="icon icon-csv"></span>
            </button>
          </span>
        </div>

        <div [ngStyle]="{ minHeight: chartHeight }">
          <corp-sanity-poultry-graph
            *ngIf="sanityDataByIndex.eliminated.displayType === IndexDisplayType.CHART"
            [data]="sanityDataByIndex.eliminated.byDayChartData"
            yAxisLabel="agriness.sanity.eliminated_y_axis"
            color="#d5fdfd"
            lineColor="#018787"
          ></corp-sanity-poultry-graph>

          <ag-table
            #dtEliminated
            *ngIf="sanityDataByIndex.eliminated.displayType === IndexDisplayType.TABLE"
            [exportFilename]="sanityDataByIndex.eliminated.downloadFilename"
            [data]="sanityDataByIndex.eliminated.byDayTableData.data"
            [columns]="sanityDataByIndex.eliminated.byDayTableData.columns"
            [scrollable]="true"
            [style]="{ width: 'auto' }"
            [rows]="10"
            [scrollHeight]="chartHeight"
          ></ag-table>
        </div>
      </div>
    </div>
  </div>
</div>
