<div *ngIf="filterForm" [hidden]="hidden">
  <div class="mt-1">
    <ag-text-field
      [formGroup]="filterForm"
      [controlName]="controlName"
      [label]="labelKey | translate"
      inputWidth="100%"
    >
    </ag-text-field>
  </div>
</div>
