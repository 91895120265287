<ag-feedback [type]="typeFeedback"> </ag-feedback>

<ng-container *ngIf="!typeFeedback">
  <div [ngSwitch]="typeProduction" class="tab-pane fade show active">
    <corp-sanity-swines
      *ngSwitchCase="TypeProduction.SWINES"
      [animalGroup]="animalGroup"
      [mortality]="mortality"
    >
    </corp-sanity-swines>
    <corp-sanity-poultry
      *ngSwitchCase="TypeProduction.POULTRY"
      [animalGroup]="animalGroup"
      [mortality]="mortality"
    >
    </corp-sanity-poultry>
  </div>
  <vaccines-medications></vaccines-medications>
</ng-container>
