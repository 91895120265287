<ag-feedback [type]="typeFeedback"> </ag-feedback>

<p-table
  #dt
  *ngIf="columns != null && columns.length > 0"
  [hidden]="typeFeedback != null"
  [style]="style"
  [frozenColumns]="frozenColumns"
  [frozenWidth]="frozenColumnWidth"
  [columns]="columns"
  [exportFunction]="exportFunction"
  [lazy]="isLazyLoad()"
  (onLazyLoad)="onLazyLoad($event)"
  [lazyLoadOnInit]="columns.length > 0"
  [loading]="loading"
  [error]="error"
  [scrollable]="scrollable"
  [scrollHeight]="scrollHeight"
  [rows]="rows"
  [value]="data"
  [customSort]="hasCustomSort()"
  [resizableColumns]="true"
  (sortFunction)="onSort($event)"
  [totalRecords]="totalRecords"
  [paginator]="showPagination"
  [pageLinks]="pageLinks"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [(selection)]="selectedData"
  (onRowSelect)="onRowSelect($event)"
  (onRowUnselect)="onRowUnselect($event)"
  (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)"
  columnResizeMode="expand"
>
  <ng-template pTemplate="frozencolgroup" let-frozenColumns>
    <colgroup>
      <col
        *ngFor="let col of frozenColumns"
        [attr.data-testid]="col.id"
        [style.width]="col.width"
      />
    </colgroup>
  </ng-template>

  <ng-template pTemplate="colgroup" let-cols>
    <colgroup>
      <col *ngFor="let col of cols" [attr.data-testid]="col.id" [style.width]="col.width" />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-cols>
    <tr>
      <th
        *ngFor="let col of cols"
        [attr.data-testid]="col.id"
        [pSortableColumn]="col.field"
        [pSortableColumnDisabled]="!col.sortable"
        [agTooltip]="col.tooltip ? (col.tooltip | translate) : null"
        pResizableColumn
        [ngClass]="{ 'modal-table': isModal }"
        (click)="onClick(col.field)"
      >
        <ng-container *ngIf="!col.checkbox; else headerCheckbox">
          {{ col.header | translate }}
        </ng-container>
        <ng-template #headerCheckbox>
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </ng-template>
        <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-cols="columns">
    <ng-container
      *ngTemplateOutlet="
        templateBody ? templateBody : defaultBody;
        context: { $implicit: rowData, columns: cols }
      "
    ></ng-container>
  </ng-template>

  <ng-template pTemplate="paginatorright" let-state>
    <div *ngIf="showPagination" class="paginator-sumario">
      <strong> {{ 'agriness.total_records' | translate }}: {{ totalRecords }} </strong>
      -
      {{ 'agriness.line_per_page' | translate }}:
    </div>
  </ng-template>

  <ng-content></ng-content>

  <ng-template #defaultBody let-rowData let-cols="columns">
    <tr>
      <ng-container *ngFor="let col of cols">
        <ng-container
          *ngIf="!col.checkbox; else checkbox"
          [ngTemplateOutlet]="
            templateBodyCell && col.id !== idSpacerColumn ? templateBodyCell : defaultBodyCell
          "
          [ngTemplateOutletContext]="{ column: col, data: rowData[col.field], rowData: rowData }"
        ></ng-container>
        <ng-template #checkbox>
          <td pResizableColumn>
            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
          </td>
        </ng-template>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template #defaultBodyCell let-col="column" let-data="data" let-rowData="rowData">
    <td
      [attr.data-testid]="col.id"
      [ngClass]="getCellClass(col, data)"
      pResizableColumn
      [agTooltip]="isFrozenColumn(col) ? getTooltipText(data) : null"
    >
      <ng-container
        *ngIf="col.id !== idSpacerColumn && col.id !== 'column-bond'"
        [ngTemplateOutlet]="
          templateBodyCellContent ? templateBodyCellContent : defaultBodyCellContent
        "
        [ngTemplateOutletContext]="{ column: col, data: data, rowData: rowData }"
      ></ng-container>
      <ag-table-cell-tag
        [data]="data"
        [column]="col"
        [defaultStyle]="measurementUnit"
        [dateFormat]="dateFormat"
        *ngIf="col.id === 'column-bond'"
      >
      </ag-table-cell-tag>
    </td>
  </ng-template>

  <ng-template #defaultBodyCellContent let-col="column" let-data="data">
    <ag-table-cell-content
      [data]="data"
      [column]="col"
      [defaultStyle]="measurementUnit"
      [dateFormat]="dateFormat"
    >
    </ag-table-cell-content>
  </ng-template>
</p-table>
