<ag-feedback [type]="typeFeedback"> </ag-feedback>

<ng-container *ngIf="typeFeedback == null">
  <corp-report-cards [performances]="performances"></corp-report-cards>

  <div class="page-title mb-3">
    {{ 'agriness.nutrition.feed.title' | translate }}
  </div>

  <corp-report-section *ngIf="tableIndexes.has(feedByPeriodIndex)">
    <corp-report-section
      #content
      sectionTitle="agriness.nutrition.feed_by_period.title"
      [feedback]="feedByPeriodSectionFeedback"
    >
      <corp-report-section-line-graph #content [data]="feedByPeriodGraphDisplayData">
      </corp-report-section-line-graph>

      <corp-report-section-table #content [data]="feedByPeriodTableDisplayData">
      </corp-report-section-table>
    </corp-report-section>
  </corp-report-section>

  <corp-report-section *ngIf="tableIndexes.has(weightIndex)">
    <corp-report-section
      #content
      sectionTitle="agriness.nutrition.body_weight.title"
      [feedback]="weightSectionFeedback"
    >
      <corp-report-section-line-graph #content [data]="weightGraphDisplayData">
      </corp-report-section-line-graph>

      <corp-report-section-table #content [data]="weightTableDisplayData">
      </corp-report-section-table>
    </corp-report-section>
  </corp-report-section>

  <corp-report-section *ngIf="tableIndexes.has(feedByLoadIndex)">
    <corp-report-section
      #content
      sectionTitle="agriness.nutrition.feed_by_load.title"
      [feedback]="feedByLoadSectionFeedback"
    >
      <consumption-graph #content [dataChart]="feedByLoadGraphDisplayData"> </consumption-graph>

      <corp-report-section-table #content [data]="feedByLoadTableDisplayData">
      </corp-report-section-table>
    </corp-report-section>
  </corp-report-section>

  <corp-report-section *ngIf="tableIndexes.has(waterIndex)">
    <corp-report-section
      #content
      sectionTitle="agriness.nutrition.water_consumption.title"
      [feedback]="waterConsumptionSectionFeedback"
    >
      <corp-report-section-table #content [data]="waterConsumptionTableDisplayData">
      </corp-report-section-table>

      <corp-report-section-line-graph #content [data]="waterConsumptionGraphDisplayData">
      </corp-report-section-line-graph>
    </corp-report-section>
  </corp-report-section>
</ng-container>
