import { Component, Input } from '@angular/core';

import { deletionTableColumns, DeletionTableData } from './deletion-table.model';

@Component({
  selector: 'deletion-table',
  templateUrl: './deletion-table.component.html',
})
export class DeletionTableComponent {
  @Input() data: DeletionTableData;
  columns = deletionTableColumns;
}
