import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ag-text-field',
  templateUrl: './ag-text-field.component.html',
})
export class AgTextFieldComponent {
  @Input() formGroup: FormGroup;
  @Input() controlName = '';
  @Input() inputId = '';
  @Input() inputWidth = '';
  @Input() label = '';
  @Input() errorMessage?: string;
  @Input() disabled = false;
  @Input() required = false;

  constructor(private cd: ChangeDetectorRef) {}

  // workaround for https://github.com/primefaces/primeng/issues/10716
  ngAfterViewInit() {
    this.cd.detectChanges();
  }
}
