import { ReportCardReproductiveEnum, ReportTabReproductiveEnum } from '@agriness/corp-app/services';
import { PerformanceAnalysisTabReport } from '@agriness/corp-app/shared/component/corp-performance-analysis/corp-performance-analysis.model';

export const tabs: PerformanceAnalysisTabReport[] = [
  {
    router: 'dnp',
    translateKey: 'agriness.performance_analysis.tabs.dnp',
    report: ReportTabReproductiveEnum.DNP,
    cardReport: ReportCardReproductiveEnum.DNP,
    indicators: [],
  },
  {
    router: 'reproductive-losses',
    translateKey: 'agriness.performance_analysis.tabs.reproductive_losses',
    report: ReportTabReproductiveEnum.REPRODUCTIVE_LOSSES,
    cardReport: ReportCardReproductiveEnum.REPRODUCTIVE_LOSSES,
    indicators: [],
  },
  {
    router: 'services',
    translateKey: 'agriness.performance_analysis.tabs.services',
    report: ReportTabReproductiveEnum.TOTAL_SERVICES,
    cardReport: ReportCardReproductiveEnum.TOTAL_SERVICES,
    indicators: [],
  },
  {
    router: 'born-alive',
    translateKey: 'agriness.performance_analysis.tabs.born_alive',
    report: ReportTabReproductiveEnum.BORN_ALIVE,
    cardReport: ReportCardReproductiveEnum.BORN_ALIVE,
    indicators: [],
  },
  {
    router: 'weaned',
    translateKey: 'agriness.performance_analysis.tabs.weaned',
    report: ReportTabReproductiveEnum.WEANED,
    cardReport: ReportCardReproductiveEnum.WEANED,
    indicators: [],
  },
  {
    router: 'mortality',
    translateKey: 'agriness.performance_analysis.tabs.mortality',
    report: ReportTabReproductiveEnum.MORTALITY,
    cardReport: ReportCardReproductiveEnum.MORTALITY,
    indicators: [],
  },
  {
    router: 'max-productive-potential',
    translateKey: 'agriness.performance_analysis.tabs.max_productive_potential',
    report: ReportTabReproductiveEnum.MAX_PRODUCTIVE_POTENTIAL,
    cardReport: ReportCardReproductiveEnum.MAX_PRODUCTIVE_POTENTIAL,
    indicators: [],
  },
];
