export const icons = {
  accommodation: '\uea19',
  activities: '\ue983',
  add: '\ue99b',
  admin: '\ue926',
  apps: '\ue95f',
  attention: '\ue989',
  audio: '\ue99e',
  audit: '\ue929',
  'add-circle-outline': '\ue999',
  'add-circle': '\ue99a',
  'add-cow': '\uea37',
  'add-data': '\ue924',
  'add-register': '\ue925',
  'alert-outline': '\ue982',
  'analysis-central': '\ue927',
  'animal-group-closed-add-swine': '\ue9b7',
  'animal-group-closed-default-poultry': '\ue9b4',
  'animal-group-closed-default-swine': '\ue9b8',
  'animal-group-closed-delete-swine': '\ue9b9',
  'animal-group-closed-edit-swine': '\ue9ba',
  'animal-group-closed-input-swine': '\ue9bb',
  'animal-group-closed-loss-swine': '\ue9bc',
  'animal-group-closed-many-swine': '\ue9bd',
  'animal-group-closed-output-swine': '\ue9be',
  'animal-group-continuous-add-swine': '\ue9bf',
  'animal-group-continuous-default-swine': '\ue9c0',
  'animal-group-continuous-delete-swine': '\ue9c1',
  'animal-group-continuous-edit-swine': '\ue9c2',
  'animal-group-continuous-input-swine': '\ue9c3',
  'animal-group-continuous-lost-swine': '\ue9c4',
  'animal-group-continuous-many-swine': '\ue9c5',
  'animal-group-continuous-output-swine': '\ue9c6',
  'animal-group-open-add-swine': '\ue9c7',
  'animal-group-open-default-poultry': '\ue9b6',
  'animal-group-open-default-swine': '\ue9c8',
  'animal-group-open-delete-swine': '\ue9c9',
  'animal-group-open-edit-swine': '\ue9ca',
  'animal-group-open-input-swine': '\ue9cb',
  'animal-group-open-lost-swine': '\ue9cc',
  'animal-group-open-many-swine': '\ue9cd',
  'animal-group-open-output-swine': '\ue9ce',
  'animal-group-selected': '\ue949',
  'arrange-a-to-z': '\ue958',
  'arrange-z-to-a': '\ue959',
  'arrow-back-ios': '\ue965',
  'arrow-back': '\ue962',
  'arrow-downward': '\ue963',
  'arrow-drop-down': '\ue964',
  'arrow-drop-up-down': '\ue965',
  'arrow-drop-up': '\ue966',
  'arrow-forward-ios': '\ue967',
  'arrow-forward': '\ue968',
  'arrow-left-circle': '\ue99c',
  'arrow-left': '\ue96a',
  'arrow-right-circle': '\ue99d',
  'arrow-right': '\ue96b',
  'arrow-upward': '\ue96c',
  'attention-1': '\ue981',
  'attention-outline': '\uea17',
  'audit-outline': '\ue928',
  bcs: '\uea2d',
  block: '\ue9a0',
  'back-outline': '\ue99f',
  'boar-active': '\ue9cf',
  'boar-cull': '\ue9d0',
  'boar-dead': '\ue9d1',
  'boar-default': '\ue9d2',
  'boar-purchase': '\ue9d3',
  'boar-sale': '\ue9d4',
  'box-flock': '\ue914',
  'broiler-logo': '\uea2a',
  calculators: '\ue9ff',
  calves: '\ue9fc',
  camera: '\ue9a1',
  chat: '\ue92f',
  check: '\ue986',
  checklist: '\ue912',
  chicken: '\ue9b3',
  clock: '\ue932',
  close: '\ue9a3',
  comfort: '\ue933',
  confirm: '\ue9a2',
  consumption: '\ue934',
  cow: '\ue9fa',
  csv: '\ue903',
  cull: '\ue988',
  'calendar-1000-days': '\ue92a',
  'calendar-add': '\uea23',
  'calendar-event': '\uea1e',
  'calendar-farmer': '\uea1f',
  'calendar-gregorian': '\ue92b',
  'calendar-home': '\ue92c',
  'calendar-information': '\uea22',
  'calendar-lightning-visit': '\uea36',
  'camera-outline': '\ue950',
  'camera-switch-outline': '\uea39',
  'camera-switch': '\uea3a',
  'cards-settings': '\ue95d',
  'cards-view': '\ue95c',
  'chat-bubbles': '\ue92d',
  'chat-outline': '\ue92e',
  'check-box-indeterminate': '\ue9fe',
  'check-box-outline-blank': '\uea08',
  'check-box': '\uea07',
  'check-list-outline': '\ue930',
  'check-list': '\ue931',
  'check-outline': '\ue985',
  'chevron-left': '\ue96d',
  'chevron-right': '\ue96e',
  'chicken-drum-stick': '\ue9b2',
  'clear-drilldraw': '\ue900',
  'column-settings': '\ue95b',
  'column-view': '\ue95a',
  'continue-outline': '\ue9a6',
  'cull-outline': '\ue987',
  dairy: '\ue9fb',
  danger: '\ue98f',
  dashboard: '\uea2e',
  dead: '\ue98c',
  delete: '\u{1f440}',
  disinfectant: '\uea1d',
  donation: '\ue935',
  dragdrop: '\ue907',
  'dairy-logo': '\uea2b',
  'danger-outline': '\ue98a',
  'data-download': '\ue904',
  'data-upload': '\ue905',
  'dead-outline': '\ue98b',
  'device-wireless': '\ue9a7',
  edit: '\ue908',
  email: '\uea20',
  error: '\ue98e',
  'error-outline': '\ue98d',
  'expand-less': '\ue96f',
  'expand-more': '\ue970',
  farm: '\ue93c',
  farmer: '\ue93d',
  feed: '\ue93f',
  filter: '\ue90f',
  form: '\ue940',
  fullscreen: '\ue973',
  'farm-farrow-to-finishing': '\ue936',
  'farm-finishing': '\ue937',
  'farm-nursery-finishing': '\ue938',
  'farm-nursery': '\ue939',
  'farm-ppu': '\ue93a',
  'farm-upd': '\ue93b',
  'farrowing-add-nurse': '\ue9d5',
  'farrowing-farrow': '\ue9d6',
  'farrowing-farrowing': '\ue9d7',
  'file-notes-add': '\ue909',
  'file-notes-empty': '\ue969',
  'file-notes': '\ue90a',
  'filter-add': '\ue90d',
  'filter-list': '\ue90e',
  'first-page': '\ue971',
  'flash-on-outline': '\uea3b',
  'flash-on': '\uea3c',
  'fullscreen-exit': '\ue972',
  genetic: '\ue913',
  good: '\uea1c',
  guide: '\ue942',
  'gilt-active': '\ue9d9',
  'gilt-cull': '\ue9da',
  'gilt-dead': '\ue9db',
  'gilt-default': '\ue9dc',
  'gilt-purchase': '\ue9dd',
  'gilt-sale': '\ue9de',
  'good-outline': '\ue990',
  harvest: '\uea18',
  hatchery: '\ue9b5',
  health: '\uea00',
  heard: '\ue948',
  help: '\ue9a9',
  herd: '\ue9df',
  herd1: '\uea2f',
  'hamburguer-menu': '\ue974',
  'health-medical-shield': '\uea28',
  'health-medicine': '\ue943',
  'help-outline': '\ue9a8',
  img: '\ue910',
  inf: '\ue992',
  inspection: '\ue984',
  'inf-outline': '\ue991',
  'inspect-animal': '\ue93e',
  'internal-consumption': '\ue944',
  'internal-replacement': '\ue9e0',
  jpg: '\ue9a5',
  kanban: '\uea3d',
  km: '\uea25',
  link: '\ue9aa',
  location: '\ue945',
  lock: '\uea0b',
  locomotion: '\uea38',
  love: '\uea0d',
  'last-page': '\ue975',
  'layers-logo': '\uea2c',
  'list-view': '\ue976',
  'lock-outline': '\uea09',
  'lock-unlock-outline': '\uea0a',
  'lock-unlock': '\uea0c',
  'love-outline': '\uea0e',
  mail: '\ue9ac',
  manager: '\ue946',
  manure: '\uea30',
  materials: '\ue947',
  more_horiz: '\ue977',
  'mail-outline': '\ue9ab',
  'manage-animal': '\ue941',
  'more-vert': '\ue978',
  notifications: '\ue995',
  nursery: '\ue9e1',
  nutrition: '\uea31',
  'no-data': '\uea24',
  'notifications-outline': '\ue994',
  operational: '\uea29',
  'open-new-tab-small': '\ue979',
  'open-new-tab': '\ue97a',
  p1: '\ue90c',
  pdf: '\ue915',
  place: '\ue94b',
  png: '\ue9a4',
  presence: '\ue94c',
  print: '\ue916',
  'piglet-dead': '\ue9e2',
  'piglet-default': '\ue9e3',
  'piglet-fostering': '\ue9e4',
  'piglet-purchase': '\ue9e5',
  'piglet-sale': '\ue9e6',
  'place-outime': '\ue94a',
  'pre-cull-outline': '\ue996',
  'pre-cull': '\ue997',
  'production-map': '\ue94d',
  quality: '\ue94e',
  'qr-code': '\ue9ad',
  'quadrantes-view': '\ue960',
  redo: '\ue9ae',
  refresh: '\ue97b',
  report: '\ue951',
  report1: '\ue917',
  restore: '\ue918',
  RFID: '\ue9b0',
  'radio-button-checked': '\uea0f',
  'radio-button-indeterminate': '\uea35',
  'radio-button-unchecked': '\uea11',
  'register-config': '\ue94f',
  'remove-circle-outline': '\ue9af',
  'remove-circle': '\uea34',
  'reproduction-abortion': '\ue9e7',
  'reproduction-loss': '\ue9e8',
  'reproduction-mating': '\ue9e9',
  'reproduction-repetition-heat': '\ue9ea',
  'reproduction-skip-heat': '\ue9eb',
  'room-moisture': '\uea01',
  'room-temperature': '\uea02',
  sale: '\ue953',
  save: '\ue91a',
  schedule: '\ue954',
  search: '\ue91b',
  selected: '\uea26',
  send: '\ue911',
  settings: '\ue91c',
  setup: '\uea3e',
  share: '\ue91d',
  shopping: '\ue955',
  star: '\uea12',
  subject: '\uea21',
  supervisor: '\ue956',
  svg: '\ue993',
  sync: '\ue998',
  'sale-outline': '\ue952',
  'save-continue': '\ue919',
  'semen-defaul': '\ue9ec',
  'semen-purchase': '\ue9ed',
  'sign-out': '\ue97c',
  'sow-active': '\ue9ee',
  'sow-cull': '\ue9ef',
  'sow-dead': '\ue9f0',
  'sow-default': '\ue9f1',
  'sow-gestant-purchase': '\ue9f2',
  'sow-gestant': '\ue9f3',
  'sow-purchase': '\ue9f4',
  'sow-sale': '\ue9f5',
  'star-half': '\uea13',
  'star-outline': '\uea14',
  'subdirectory-arrow-left': '\ue97d',
  'subdirectory-arrow-right': '\ue97e',
  'swine-logo': '\uea27',
  'switch-off-path1': '\uea03',
  'switch-off-path2': '\uea04',
  'switch-on-path1': '\uea05',
  'switch-on-path2': '\uea06',
  tag: '\ue920',
  toolbox: '\uea10',
  tools: '\uea1b',
  transicao: '\ue9fd',
  'tag-add': '\ue91e',
  'tag-find': '\ue91f',
  'technical-manager': '\ue957',
  'tmr-particle': '\uea32',
  'toggle-horizontal-off': '\ue901',
  'toggle-horizontal-on': '\ue902',
  'toggle-vertical-off': '\uea15',
  'toggle-vertical-on': '\uea16',
  'track-setting': '\ue921',
  'transfer-animal': '\ue9f6',
  'transfer-batch': '\ue9f7',
  'transfer-location': '\ue9f8',
  'transfer-phase': '\ue9f9',
  undo: '\ue9b1',
  'unfold-less': '\ue97f',
  'unfold-more': '\ue980',
  'urine-ph': '\uea33',
  'user-ico': '\ue90b',
  vaccine: '\ue95e',
  visibility: '\ue923',
  'visibility-off': '\ue922',
  water: '\uea1a',
  weight: '\ue9d8',
  xls: '\ue906',
} as const;

export type Icons = keyof typeof icons;

export const iconOptions = Object.keys(icons);
