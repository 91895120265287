<ag-feedback [type]="typeFeedback"> </ag-feedback>

<div *ngIf="typeFeedback == null" class="tab-pane fade show active" id="nav-movimentacoes">
  <corp-report-cards
    *ngIf="type == TypeProduction.SWINES"
    [performances]="performances"
  ></corp-report-cards>

  <div class="row pt-4" *ngIf="populateChart && type != TypeProduction.SWINES">
    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="card-title">
            {{ 'agriness.movement.input_output_comparation' | translate }}
            <corp-export-button
              *ngIf="!typeFeedback"
              class="pl-2"
              [filename]="'agriness.movement.input_output_comparation' | translate"
              [lazyInstance]="chartInstance?.chart | lazy"
            >
            </corp-export-button>
          </div>
          <p-selectButton
            [options]="types"
            [(ngModel)]="selectedType"
            (onChange)="selectType($event)"
            class="float-right text-uppercase"
          ></p-selectButton>
          <p class="tabular-info">
            <span class="text-uppercase">
              {{ 'agriness.movement.input_total' | translate }}: {{ tableValues['total_inputs'] }}
            </span>
            <span class="text-uppercase">
              {{ 'agriness.movement.output_total' | translate }}: {{ tableValues['total_outputs'] }}
            </span>
          </p>
          <highcharts-chart
            #chart
            *ngIf="chartOptions"
            [Highcharts]="Highcharts"
            [options]="chartOptions"
            style="width: 100%; height: 400px; display: block;"
          ></highcharts-chart>
        </div>
      </div>
    </div>
  </div>

  <div class="row pt-4">
    <div class="col-lg-6" *ngIf="columns?.input?.length">
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="card-title d-flex align-items-center">
            <span class="mr-2">
              {{ 'agriness.movement.input.header_title' | translate }}
            </span>
            <button
              (click)="tableService.exportCSV(pTableInput.table)"
              class="btn btn-ghost has-round-hover"
            >
              <span class="icon icon-csv"></span>
            </button>
          </div>

          <ag-table
            #dtInput
            [exportFunction]="tableService.evalValueExportTable"
            [scrollable]="true"
            [data]="tableValues.input"
            [columns]="columns.input"
            [style]="{ width: 'auto' }"
            scrollHeight="350px"
            [rows]="10"
          >
          </ag-table>
        </div>
      </div>
      <!-- /.card -->
    </div>
    <div class="col-lg-6" *ngIf="columns?.output?.length">
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="card-title d-flex align-items-center">
            <span class="mr-2">
              {{ 'agriness.movement.output.header_title' | translate }}
            </span>
            <button
              (click)="tableService.exportCSV(pTableOutPut.table)"
              class="btn btn-ghost has-round-hover"
            >
              <span class="icon icon-csv"></span>
            </button>
          </div>

          <ag-table
            #dtOutPut
            [exportFunction]="tableService.evalValueExportTable"
            [scrollable]="true"
            [data]="tableValues.output"
            [columns]="columns.output"
            [style]="{ width: 'auto' }"
            scrollHeight="350px"
            [rows]="10"
          >
          </ag-table>
        </div>
      </div>
      <!-- /.card -->
    </div>
  </div>
  <!-- /.row -->
</div>
