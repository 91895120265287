import {
  defaultTargetOrder,
  Target,
  TargetFilter,
  TargetService,
  TargetV2,
} from '@agriness/corp-app/services';
import { DeletionTableData } from '@agriness/corp-app/settings/deletion-table/deletion-table.model';
import { CorpFiltersContainerComponent } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.component';
import { CorpFiltersContainerService } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.service';
import {
  CorpRecordTableComponent,
  ActionEnum,
} from '@agriness/corp-app/shared/component/corp-record-table/corp-record-table.component';
import {
  ReportFilter,
  ReportFilterQueryKeys,
} from '@agriness/corp-app/shared/model/report-filter.model';
import { PaginationRequest } from '@agriness/corp-app/shared/model/table.model';
import { TypeProductionEnum, TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { ModalStatusEnum, QueueItem, QueueManagerService } from '@agriness/ui';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';

import { TargetEditorComponent } from './target-editor/target-editor.component';
import { targetsTableColumns, targetsDeletionTableFields } from './target.model';

@Component({
  selector: 'app-targets-tab',
  templateUrl: './targets-tab.component.html',
  styleUrls: ['./targets-tab.component.scss'],
  providers: [CorpFiltersContainerService.provider],
})
export class TargetsTabComponent implements OnInit {
  @ViewChild('filtersContainer', { static: true }) reportFilter: CorpFiltersContainerComponent;
  @ViewChild('table', { static: true }) table: CorpRecordTableComponent<Target>;
  @ViewChild('editor') targetEditor: TargetEditorComponent;

  translationContext = 'agriness.settings.targets';

  stage: string;
  systemType: TypeProductionEnum;
  totalRecords: number;
  rows = 10;
  loading = false;
  currentFilter: TargetFilter = {};
  availableActions = [ActionEnum.EDIT, ActionEnum.VIEW, ActionEnum.TOGGLE];
  error: boolean;

  editorModalVisible = false;
  editorModalStatus = ModalStatusEnum.DEFAULT;
  selectedTarget: TargetV2;
  deletionModalVisible = false;
  deletionModalStatus = ModalStatusEnum.DEFAULT;
  deletionTableData: DeletionTableData;

  queryParamName: ReportFilterQueryKeys = 'target_name';

  tableColumns = targetsTableColumns;
  targets: TargetV2[];
  defaultOrdering = defaultTargetOrder;
  filterEntity: keyof ReportFilter = 'target';

  private queryCorpOnly = true;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private targetService: TargetService,
    private route: ActivatedRoute,
    private router: Router,
    private queueManager: QueueManagerService,
  ) {}

  ngOnInit(): void {
    this.stage = this.router.url.split('/').pop().split('?')[0];
  }

  createTarget(): void {
    return;
  }

  filter(): void {
    this.table.reset();
  }

  filterReset(): void {
    this.reportFilter.onFilter();
  }

  onAddTarget(): void {
    this.selectedTarget = null;
    void this.router.navigate(['add'], {
      relativeTo: this.route,
    });
  }

  onDeletionModalClose(): void {
    this.selectedTarget = null;
    this.deletionModalVisible = false;
    this.deletionModalStatus = ModalStatusEnum.DEFAULT;
  }

  onDeletionModalDelete(): void {
    this.deletionModalStatus = ModalStatusEnum.LOADING;

    return;
  }

  onItemAction(obj: { item: TargetV2; action: ActionEnum; checked?: boolean }): void {
    switch (obj.action) {
      case ActionEnum.EDIT:
        void this.router.navigate(['edit'], {
          relativeTo: this.route,
          state: obj.item,
        });
        break;
      case ActionEnum.DELETE:
        this.selectedTarget = obj.item;
        this.updateDeletionTableData(this.selectedTarget);
        this.deletionModalVisible = true;
        break;
      case ActionEnum.TOGGLE:
        this.updateEnableTarget(obj.item, obj.checked);
        break;
      case ActionEnum.VIEW:
        void this.router.navigate(['view'], {
          relativeTo: this.route,
          queryParams: { id: obj.item.id },
        });
        break;
    }
  }

  updateEnableTarget(item: TargetV2, enabled: boolean): void {
    this.addOperationToQueue(
      this.targetService.patch(
        { id: item.id, target_detail: item.target_detail, enabled },
        item.id,
      ),
    );
  }

  addOperationToQueue(targetObservable: Observable<TargetV2>): void {
    const t = (key: string): string => this.t(`${this.translationContext}.feedback.${key}`);
    this.queueManager
      .add(targetObservable)
      .pipe(
        switchMap(({ payload: target, actions }: QueueItem) => {
          return (target as Observable<TargetV2>).pipe(
            tap((target: TargetV2) => {
              actions.success(target.enabled ? t('target_enabled') : t('target_disabled'));
            }),
            catchError(err => {
              actions.error(this.t(`${this.translationContext}.editor.failed.message`));
              return throwError(err);
            }),
          );
        }),
        catchError(err => {
          return throwError(err);
        }),
      )
      .subscribe();
  }

  onPageRequest(request: PaginationRequest): void {
    this.loading = true;
    this.updateCurrentFilter(request);
    this.loadTargets();
  }

  onEditorClosed(): void {
    this.selectedTarget = null;
    this.editorModalVisible = false;
    this.editorModalStatus = ModalStatusEnum.DEFAULT;
  }

  shouldBlockAction(obj: { action: ActionEnum; item: TargetV2 }): boolean {
    if (obj.action == ActionEnum.DELETE) {
      return obj.item.enabled;
    }
    return false;
  }

  updateTarget(): void {
    if (this.selectedTarget) {
      this.editorModalStatus = ModalStatusEnum.LOADING;

      return;
    }
  }

  private loadTargets() {
    this.targetService
      .listV2({ ...this.currentFilter })
      .pipe(
        first(),
        map(({ count, results }) => {
          this.totalRecords = count;
          this.loading = false;
          this.targets = results as TargetV2[];
        }),
        catchError(error => {
          this.error = true;
          return throwError(error);
        }),
      )
      .subscribe();
  }

  private updateCurrentFilter(request: PaginationRequest): void {
    const group = this.reportFilter.getCurrentFilterInQueryFormat().group;
    const period_type = this.reportFilter.getCurrentFilterInQueryFormat().period_type;
    const indexes = this.reportFilter.getCurrentFilterInQueryFormat().indexes;

    const targetFilters = {
      group: this.fixFormatData(group),
      period_type: this.fixFormatData(period_type),
      indexes: this.fixFormatData(indexes),
    };

    this.currentFilter = {
      ...this.currentFilter,
      ...targetFilters,
      stage: this.stage,
      ...request,
      queryCorpOnly: this.queryCorpOnly,
    };
  }

  private updateDeletionTableData(target: TargetV2): void {
    this.deletionTableData = targetsDeletionTableFields.map(field => {
      return {
        field: this.t(`agriness.settings.targets.table.${field}`),
        value: target[field] as string,
      };
    });
  }

  private fixFormatData(data: string): string {
    return data
      ? data
          .split(',')
          .filter(e => e)
          .toString()
      : null;
  }
}
