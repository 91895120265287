import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { RadioOption } from './corp-radio-options.model';

/**
 * Renders a list of [DualListBoxItem] using radio buttons
 */
@Component({
  selector: 'corp-radio-options',
  templateUrl: './corp-radio-options.component.html',
  styleUrls: ['./corp-radio-options.component.scss'],
})
export class CorpRadioOptionsComponent implements OnChanges {
  @Input()
  controlName: string;

  @Input()
  selected: string | null = null;

  @Input()
  options: RadioOption[];

  @Output()
  optionSelected = new EventEmitter<RadioOption>();

  @Input()
  form: FormGroup;

  @Input()
  isHorizontal = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    const selected = changes['selected'];

    if (selected && selected.previousValue !== selected.currentValue) {
      this.setSelectedOption(selected.currentValue);
    }
  }

  private setSelectedOption(option: string | null) {
    this.form = this.formBuilder.group({
      [this.controlName]: option ? [option] : [],
    });
  }
}
