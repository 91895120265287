import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { FeedbackContent, FeedbackEnum } from '../ag-feedback.model';
import { AgFeedbackService } from '../ag-feedback.service';

@Component({
  selector: 'ag-feedback-icon',
  templateUrl: './ag-feedback-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgFeedbackIconComponent implements OnChanges {
  @Input() type: FeedbackEnum;

  feedBack: FeedbackContent;

  constructor(private feedbackService: AgFeedbackService) {}

  ngOnChanges(): void {
    this.feedBack = this.feedbackService.getFeedback(this.type);
  }
}
