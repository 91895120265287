import { Search } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ChecklistFilter, Checklist, ChecklistForm } from '../models/checklists.model';

@Injectable({
  providedIn: 'root',
})
export abstract class ChecklistAbstractService {
  abstract list(checklistFilter: ChecklistFilter): Observable<Search<Checklist>>;
  abstract save(form: ChecklistForm): Observable<Checklist>;
  abstract update(form: ChecklistForm, checklistId: string): Observable<Checklist>;
  abstract delete(checklist: Checklist): Observable<void>;
}
