import { MeasurementUnitEnum } from '@agriness/corp-app/services';
import { BehaviorSubject } from 'rxjs';

import { Diet } from './diet.model';
import { ItemProperties } from './performance.model';

export enum PerformanceKeys {
  CURRENT_AGE = 'current_age',
  EGG_PRODUCTION_HEN_DAY = 'egg_production_hen_day',
  CASE_WEIGHT = 'case_weight',
  AVERAGE_BODY_WEIGHT = 'average_body_weight',
  CUMULATIVE_MORTALITY_RATE_TOTAL = 'cumulative_mortality_rate_total',
  WEEKLY_TOTAL_FEED = 'weekly_total_feed',
}

export enum DietSelectionKeys {
  CURRENT_DIET = 'current_diet',
  PROPOSED_DIET = 'proposed_diet',
  COMMENTS = 'comments',
  FEED_ID = 'feed_id',
}

export type PerformanceItems = {
  [key in PerformanceKeys]?: ItemProperties;
};

export type DietItems = {
  [key in DietSelectionKeys]?: DietItem;
};

export type DietItem = {
  value: string;
  measurement_unit?: MeasurementUnitEnum;
  decimal_places?: number;
};

export type DietSelectionEntry = PerformanceItems & DietItems;

export type DietSelectionEntries = DietSelectionEntry[];

export type CurrentPerformances = {
  current_age: number;
  current_diet: string;
  proposed_diet: string;
};

export type ItemOptions = { id: string | number; name: string | number };

export type DietOptions = {
  age: BehaviorSubject<ItemOptions[]>;
  proposed_diet: BehaviorSubject<ItemOptions[]>;
  comments: BehaviorSubject<string>;
};

export type DietFormData = {
  age: number;
  proposed_diet: string;
  comments: string;
};

export enum DisplayMode {
  ADDING = 'adding',
  EDITING = 'editing',
}

export enum ModalStatusEnum {
  LOADING = 'loading',
  SUCCESS = 'success',
  FAIL = 'fail',
  WARNING = 'warning',
}

export type FeedSearch = {
  count: number;
  next?: string;
  previous?: string;
  results: Diet[];
};
