import { CurrentCycle } from '@agriness/corp-app/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, SelectComponent } from '../corp-filter-abstractions';
import { FixedLoaderService, SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-current-cycle-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.select.html',
  providers: [{ provide: FilterComponent, useExisting: CorpCurrentCycleFilterComponent }],
})
export class CorpCurrentCycleFilterComponent extends SelectComponent {
  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(translate, containerService, activeRoute);

    this.assignDefaults(
      'currentCycle',
      new SelectLoader(
        new FixedLoaderService(
          [
            [CurrentCycle.FIRST_CYCLE, 'agriness.filter.current_cycle.first_cycle'],
            [CurrentCycle.MOLTING, 'agriness.filter.current_cycle.molting'],
            [CurrentCycle.SECOND_CYCLE, 'agriness.filter.current_cycle.second_cycle'],
            [CurrentCycle.MOLTING_2, 'agriness.filter.current_cycle.molting_2'],
            [CurrentCycle.THIRD_CYCLE, 'agriness.filter.current_cycle.third_cycle'],
          ],
          translate,
        ),
      ),
    );
  }
}
