<div class="card-report card shadow-sm">
  <div class="card-header">
    {{ 'agriness.report.' + data.internal_name | translate }}
  </div>
  <div class="card-body">
    <p>
      {{ 'agriness.description.report.' + data.internal_name | translate }}
    </p>
    <div class="card-report-tags">
      <span>{{ arrayToString(data.tags) }}</span>
    </div>
  </div>
  <div class="card-footer justify-content-end">
    <a
      class="btn btn-primary"
      id="btn-open-report_{{ data.internal_name }}"
      routerLink="{{ data.route }}"
      (click)="onOpen()"
      >{{ 'agriness.open' | translate }}</a
    >
  </div>
</div>
