import { Search } from '@agriness/services';
import { Observable } from 'rxjs';

import { Tank, TankFilter, TankForm } from '../models/tank.model';

export abstract class TankAbstractService {
  abstract list(tankFilter: TankFilter): Observable<Search<Tank>>;
  abstract save(tankForm: TankForm): Observable<Tank>;
  abstract update(tankForm: TankForm, tankId: string): Observable<Tank>;
  abstract delete(tank: Tank): Observable<void>;
}
