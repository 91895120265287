import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  Component,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  HostBinding,
  ChangeDetectionStrategy,
} from '@angular/core';

import { ListBoxLabels, DualListBoxItem } from './ag-dual-list-box.model';

@Component({
  selector: 'ag-list-box',
  templateUrl: './ag-list-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgListBoxComponent implements OnChanges {
  @Input() items: DualListBoxItem[] = [];
  @Input() sortable: boolean;
  @Input() removable: boolean;
  @Input() selectable: boolean;
  @Input() labels: ListBoxLabels;
  @Output() drop = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() select = new EventEmitter();

  filterText = '';

  filteredItems: DualListBoxItem[] = [];

  ngOnChanges(): void {
    this.updateList();
  }

  onSelect(list: DualListBoxItem[]): void {
    this.select.emit(list);
  }

  onRemove(list: DualListBoxItem[]): void {
    this.remove.emit(list);
  }

  filter(value: string): void {
    this.filterText = value.toLowerCase();
    this.updateList();
  }

  onDrop(event: CdkDragDrop<string[]>): void {
    this.drop.emit(event);
  }

  private updateList(): void {
    this.filteredItems = this.items.filter(item =>
      item.label.toLowerCase().includes(this.filterText),
    );
    if (!this.items.length) {
      this.filterText = '';
    }
  }

  trackBy(item: DualListBoxItem) {
    return item.value;
  }
}
