import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TabsModel } from './tabs.model';

@Component({
  selector: 'ag-tabs',
  templateUrl: './ag-tabs.component.html',
})
export class AgTabsComponent {
  @Input() data: TabsModel[];
  @Output() callback = new EventEmitter<TabsModel>();

  onChange(tab: TabsModel): void {
    this.callback.emit(tab);
  }
}
