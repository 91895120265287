import { PageLeaveConfirmationGuard } from '@agriness/corp-app/router-guard/page-leave-confirmation.guard';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { AnimalGroupComponent } from './animal-group.component';
import { ComfortComponent } from './comfort/comfort.component';
import { MovementComponent } from './movement/movement.component';
import { NutritionComponent } from './nutrition/nutrition.component';
import { OverviewComponent } from './overview/overview.component';
import { PaymentComponent } from './payment/payment.component';
import { PerformanceComponent } from './performance/performance.component';
import { SanityComponent } from './sanity/sanity.component';
import { TraceabilityComponent } from './traceability/traceability.component';

const routes: Routes = [
  {
    path: '',
    component: AnimalGroupComponent,
  },
  {
    path: ':idFarm/:idAnimalGroup',
    component: AnimalGroupComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'overview' },
      { path: 'comfort', component: ComfortComponent },
      { path: 'movement', component: MovementComponent },
      { path: 'overview', component: OverviewComponent },
      { path: 'performance', component: PerformanceComponent },
      { path: 'nutrition', component: NutritionComponent },
      { path: 'sanity', component: SanityComponent },
    ],
  },
  {
    path: ':idFarm/:idAnimalGroup/traceability',
    component: TraceabilityComponent,
    canDeactivate: [PageLeaveConfirmationGuard],
  },
  {
    path: ':idFarm/:idAnimalGroup/traceability/:documentId',
    component: TraceabilityComponent,
    canDeactivate: [PageLeaveConfirmationGuard],
  },
  {
    path: ':idFarm/:idAnimalGroup/payment',
    component: PaymentComponent,
    canDeactivate: [PageLeaveConfirmationGuard],
  },
  {
    path: ':idFarm/:idAnimalGroup/payment/:documentId',
    component: PaymentComponent,
    canDeactivate: [PageLeaveConfirmationGuard],
  },
];

export const routerAnimalGroup: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, routerAnimalGroup],
  exports: [RouterModule],
})
export class AnimalGroupRoutingModule {}
