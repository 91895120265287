import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { CurrencyFormatPipe } from './currency-format.pipe';
import { UninformedPipe } from './uninformed.pipe';
import { ValueFormatPipe } from './value-format/value-format.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CurrencyFormatPipe, UninformedPipe, ValueFormatPipe],
  exports: [CurrencyFormatPipe, UninformedPipe, ValueFormatPipe],
  providers: [ValueFormatPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PipesModule {}
