import { SiteService } from '@agriness/corp-app/services';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, MultiSelectComponent } from '../corp-filter-abstractions';
import { SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-site-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.multiselect.html',
  providers: [{ provide: FilterComponent, useExisting: CorpSiteFilterComponent }],
})
export class CorpSiteFilterComponent extends MultiSelectComponent {
  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
    siteService: SiteService,
  ) {
    super(translate, containerService, activeRoute);
    this.assignDefaults('site', new SelectLoader(siteService));
  }
}
