import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { ReportFavoriteAbstractService } from './report-favorite-abstract.service';

@Injectable()
export class ReportFavoriteService extends ReportFavoriteAbstractService {
  pathTypeViewFavorites = 'users/favorite-reports/{report_uuid}/';

  constructor(private apiCorpService: ApiCorpService) {
    super();
  }

  getReportFavorite(): Observable<string[]> {
    const url = this.apiCorpService.translateUrl(this.pathTypeViewFavorites, {});
    return this.apiCorpService.get<string[]>(url);
  }

  saveReportFavorite(reportId: string): Observable<void> {
    const url = this.apiCorpService.translateUrl(this.pathTypeViewFavorites, {});
    return this.apiCorpService.put(url, { report_id: reportId });
  }

  deleteReportFavorite(reportId: string): Observable<void> {
    const url = this.apiCorpService.translateUrl(this.pathTypeViewFavorites, {
      report_id: reportId,
    });
    return this.apiCorpService.delete(url, {});
  }
}
