import { QuadrantEnum } from '@agriness/corp-app/services';
import { StageEnum } from '@agriness/services';

import { ReportFilterType } from './report-filter.model';

export interface IdentifyReportInfo {
  rootReport: string;
  defaultReport: string;
  filters: ReportFilterType[];
  outputTranslateKey: string;
  tableConfig: { [k: string]: string };
}

const baseTableConfig = {
  producer_name: '160px',
  farm_name: '160px',
  quadrant: '195px',
  technician_name: '160px',
  animalgroup: '95px',
  adjusted_feed_conversion_ratio: '70px',
  feed_conversion_ratio: '70px',
  average_weight_input: '130px',
  average_weight_output: '115px',
  total_feed_per_animal: '140px',
  mortality_rate: '105px',
  mortality_rate_by_harvested: '105px',
  total_animal_number_input: '115px',
  total_animal_number_output: '100px',
  average_age_output: '90px',
  daily_weight_gain: '70px',
  days_in_stage: '125px',
  cost_medication_per_head: '150px',
  total_medication_cost_per_weight: '150px',
};

export const reportInfoByStage: { [k in StageEnum]?: IdentifyReportInfo } = formatReportInfoByStage(
  {
    [StageEnum.REPRODUCTIVE]: {
      rootReport: `quadrant_${StageEnum.REPRODUCTIVE}`,
      defaultReport: `quadrant_${StageEnum.REPRODUCTIVE}_average_female_inventory_pigs_weaned_female_year`,
      filters: ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'TECHNICIAN'],
      outputTranslateKey: 'agriness.performances.average_female_inventory',
      tableConfig: {
        ...baseTableConfig,
        pigs_weaned_mated_female_year: '70px',
        litters_female_year: '70px',
        average_non_productive_days: '70px',
        average_born_alive: '105px',
        average_weaned_pigs: '105px',
        weaning_mortality_related: '160px',
        rate_mortality_active_sow: '140px',
      },
    },
    [StageEnum.NURSERY]: {
      rootReport: `quadrant_${StageEnum.NURSERY}`,
      defaultReport: `quadrant_${StageEnum.NURSERY}_ca_mortality`,
      filters: ['COMPANY', 'PERIOD', 'LAST_ANIMAL_GROUP', 'FARM', 'REGION', 'TECHNICIAN'],
      outputTranslateKey: 'agriness.performances.animals_delivered',
      tableConfig: {
        ...baseTableConfig,
      },
    },
    [StageEnum.FINISHING]: {
      rootReport: `quadrant_${StageEnum.FINISHING}`,
      defaultReport: `quadrant_${StageEnum.FINISHING}_caaj_mortality`,
      filters: [
        'COMPANY',
        'PERIOD',
        'LAST_ANIMAL_GROUP',
        'FARM',
        'REGION',
        'TECHNICIAN',
        'NUTRITION_TYPE',
      ],
      outputTranslateKey: 'agriness.performances.animals_delivered',
      tableConfig: {
        ...baseTableConfig,
      },
    },
    [StageEnum.GROW_OUT]: {
      rootReport: `quadrant_${StageEnum.GROW_OUT}`,
      defaultReport: `quadrant_${StageEnum.GROW_OUT}_caaj_gpd`,
      filters: ['COMPANY', 'PERIOD', 'LAST_ANIMAL_GROUP', 'FARM', 'REGION', 'TECHNICIAN'],
      outputTranslateKey: 'agriness.performances.animals_delivered',
      tableConfig: {
        ...baseTableConfig,
        average_weight_output: '120px',
        average_age_output: '120px',
      },
    },
    [StageEnum.MILKING]: {
      rootReport: `quadrant_${StageEnum.MILKING}`,
      defaultReport: `quadrant_${StageEnum.MILKING}_average_result_ccs_average_result_cbt`,
      filters: ['COMPANY', 'PERIOD', 'FARM', 'REGION', 'TECHNICIAN'],
      outputTranslateKey: 'agriness.performances.total_shipped_milk',
      tableConfig: {
        ...baseTableConfig,
      },
    },
  },
);

function formatReportInfoByStage(data: { [k in StageEnum]?: IdentifyReportInfo }) {
  const toBeReplaced = /-/g;
  Object.values(data).forEach(reportInfo => {
    reportInfo.rootReport = reportInfo.rootReport.replace(toBeReplaced, '_');
    reportInfo.defaultReport = reportInfo.defaultReport.replace(toBeReplaced, '_');
  });
  return data;
}

export const quadrantColors: { [k in QuadrantEnum]: string } = {
  [QuadrantEnum.BAD_GREAT]: 'is-yellow',
  [QuadrantEnum.GOOD_GREAT]: 'is-aqua',
  [QuadrantEnum.GREAT_GREAT]: 'is-green',
  [QuadrantEnum.BAD_GOOD]: 'is-orange',
  [QuadrantEnum.GOOD_GOOD]: 'is-aqua',
  [QuadrantEnum.GREAT_GOOD]: 'is-aqua',
  [QuadrantEnum.BAD_BAD]: 'is-red',
  [QuadrantEnum.GOOD_BAD]: 'is-orange',
  [QuadrantEnum.GREAT_BAD]: 'is-yellow',
};

export type QuadrantAxisName = { index_x: string; index_y: string };

export function getIndexesFromReportName(reportName: string): QuadrantAxisName {
  const [index_x, index_y] = reportName.split(' - ');
  return { index_x, index_y };
}

type FrozenColumnsByStage = { [k in StageEnum]?: string[] };

export function getFrozenColumnsByStage(stage: StageEnum): string[] {
  const defaultFrozenColumns = ['PRODUCER_NAME'];
  const frozenColumnsByStage: FrozenColumnsByStage = {
    [StageEnum.REPRODUCTIVE]: ['PRODUCER_NAME', 'FARM_NAME', 'QUADRANT'],
  };
  return frozenColumnsByStage[stage] ?? defaultFrozenColumns;
}
