<corp-modal-form
  [title]="translationContext + '.editor.title' | translate"
  [modalStatus]="modalStatus"
  [visible]="visible"
  [formValid]="animalGroupForm.valid"
  (closed)="onClosed()"
  (formSubmit)="onSubmit()"
  class="full-width"
>
  <div>
    <p class="subtitle">
      {{ translationContext + '.editor.subtitle' | translate }}
    </p>
  </div>

  <form class="full-width">
    <div class="form-container full-width">
      <ag-text-field
        class="form-field"
        [formGroup]="animalGroupForm"
        controlName="name"
        [disabled]="true"
        [label]="translationContext + '.editor.fields.name.label' | translate"
      >
      </ag-text-field>
      <ag-text-field
        class="form-field"
        [formGroup]="animalGroupForm"
        controlName="farm"
        [disabled]="true"
        [label]="translationContext + '.editor.fields.farm.label' | translate"
      >
      </ag-text-field>
    </div>

    <div class="form-container full-width">
      <corp-producer-select
        class="form-field has-feedback"
        [form]="animalGroupForm"
        [errorMessage]="errorMessages.producer"
        [stage]="stage"
        [queryCorpOnly]="false"
      ></corp-producer-select>
      <corp-region-select
        class="form-field"
        [form]="animalGroupForm"
        [stage]="stage"
        [queryCorpOnly]="false"
      ></corp-region-select>
    </div>

    <div class="form-container full-width">
      <corp-technician-select
        class="form-field"
        [form]="animalGroupForm"
        [stage]="stage"
        [queryCorpOnly]="false"
      ></corp-technician-select>
      <corp-nutrition-type-select
        class="form-field"
        [form]="animalGroupForm"
        [stage]="stage"
        [queryCorpOnly]="false"
      ></corp-nutrition-type-select>
    </div>
  </form>
</corp-modal-form>
