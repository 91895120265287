import { StageEnum, TypeProductionService } from '@agriness/services';
import { SiteByStage } from '@agriness/services/analytics/analytics.model';
import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as _ from 'lodash';
import { Observable, from } from 'rxjs';

import { ParentScrollPin } from '../../common/parent-scroll-pin';

@Component({
  selector: 'ag-select',
  templateUrl: 'ag-select.component.html',
})
export class AgSelectComponent implements OnDestroy {
  @ViewChild('searchInput') searchInput?: ElementRef;
  @ViewChild('select', { static: true }) select: NgSelectComponent;
  @Output() selectValueFilter = new EventEmitter<any[]>();
  @Output() searchChange = new EventEmitter<string>();
  @Output() scrollToEnd = new EventEmitter<void>();
  @Input() itemsSelect: any[];
  @Input() items$: Observable<any> = from([]);
  @Input() controlName: string;
  @Input() optionLabel = 'name';
  @Input() dataKey = 'id';
  @Input() group: FormGroup;
  @Input() inputId: string;
  @Input() styleClass: string;
  @Input() defaultLabel: string;
  @Input() loading = false;
  @Input() notFoundText = 'agriness.no_registry';
  @Input() loadingText = 'agriness.loading';
  @Input() isSearchable = false;
  @Input() isDisabled = false;
  @Input() clearable = false;
  @Input() clearText = 'agriness.clear';
  @Input() errorMessage?: string;

  stage: StageEnum;
  searchValue = '';
  stageEvent = ['reproductive', 'nursery', 'finishing'];

  private parentScrollPin: ParentScrollPin;

  constructor(
    elementRef: ElementRef<HTMLElement>,
    private readonly route: ActivatedRoute,
    private analyticsService: AmplitudeAnalyticsService,
    private typeProductionServe: TypeProductionService,
  ) {
    this.parentScrollPin = new ParentScrollPin(elementRef);
  }
  ngOnDestroy(): void {
    this.parentScrollPin.destroy();
  }

  onOpen(): void {
    this.parentScrollPin.pin();
    _.defer(() => this.searchInput?.nativeElement.focus());
    this.stage = this.route.snapshot.data.stage;
    if (this.stageEvent.includes(this.stage)) {
      this.analyticsService.logFilters(
        SiteByStage[this.typeProductionServe.get()][this.stage],
        this.controlName,
      );
    }
  }

  onChange(value): void {
    this.selectValueFilter.emit(value);
    this.parentScrollPin.updatePin();
    this.select.close();
  }

  onClose(): void {
    this.parentScrollPin.unpin();
  }

  onRemove(): void {
    this.searchInput?.nativeElement.focus();
  }

  onSearch(event): void {
    this.searchValue = event.target.value;
    if (this.searchChange.observers.length) {
      this.searchChange.emit(this.searchValue);
    } else {
      this.select.filter(this.searchValue);
    }
  }
}
