import { Search, TypeProductionService, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { Company, CompanyFilter } from '../models/company.model';

@Injectable()
export class CompanyService {
  private pathBase = 'holdings/{holding_id}/companies/';

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {}

  list(filter: CompanyFilter): Observable<Search<Company>> {
    const url = this.apiCorpService.translateUrl(this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
    });
    const params = {
      ...filter,
      system_type: this.typeProductionService.get(),
    };
    return this.apiCorpService.get<Search<Company>>(url, params);
  }
}
