import {
  AnimalGroup,
  Checklist,
  Company,
  CurrentCycle,
  Farm,
  KPIList,
  Location,
  NutritionType,
  Producer,
  ProductionSystem,
  Region,
  Route,
  Rule,
  Site,
  Strain,
  Tank,
  Target,
  TargetStatusKpi,
  Technician,
} from '@agriness/corp-app/services';
import { ProductionMethod } from '@agriness/corp-app/services/models/production-method';
import { Routes } from '@agriness/corp-app/services/models/routes.model';
import { PeriodLabels } from '@agriness/corp-app/shared/component/corp-filters/inputs/corp-period-filter.component';
import { AnimalGroupStatus, StageEnum } from '@agriness/services';
import { TargetStatus } from '@agriness/ui';

export enum PeriodInterval {
  CURRENT = 'CURRENT',
  LAST = 'LAST',
}

export enum PeriodUnit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export type ReportFilterPeriodDefaultValue = [PeriodInterval, PeriodUnit, number?];

export type PeriodFilter = {
  type: 'PERIOD';
  rangeLimit?: number;
  periodDefaultValue?: ReportFilterPeriodDefaultValue;
  periodLabels?: PeriodLabels;
};

export type ReportFilterTypeDefinition =
  | { type: 'AGE' }
  | { type: 'ANIMAL_GROUP' }
  | { type: 'COMPANY' }
  | { type: 'COMPANY_SINGLE' }
  | { type: 'CURRENT_CYCLE' }
  | { type: 'FARM' }
  | { type: 'LAST_ANIMAL_GROUP' }
  | { type: 'NUTRITION_TYPE' }
  | { type: 'PRODUCER' }
  | { type: 'TARGET' }
  | { type: 'PRODUCTION_METHOD' }
  | { type: 'PRODUCTION_SYSTEM' }
  | { type: 'REGION' }
  | { type: 'TANK' }
  | { type: 'ROUTE' }
  | { type: 'ROUTES' }
  | { type: 'RULE' }
  | { type: 'SITE' }
  | { type: 'STRAIN' }
  | { type: 'STRAIN_SINGLE' }
  | { type: 'STAGE' }
  | { type: 'TARGET_STATUS_KPI'; targetStatus?: TargetStatus[] }
  | { type: 'TECHNICIAN'; hasUser?: boolean }
  | {
      type: 'IS_OPEN';
      nullable?: boolean;
      initialValue?: 'true' | 'false';
      animalGroupStatus?: AnimalGroupStatus;
    }
  | PeriodFilter;

export type ReportFilterName = ReportFilterTypeDefinition['type'];

export type ReportFilterType = ReportFilterTypeDefinition | ReportFilterName;

export interface ReportFilter {
  animalGroup: AnimalGroup[];
  beginAge: number;
  beginDate: Date;
  company: Company[];
  companySingle: Company[];
  currentCycle: CurrentCycle;
  endAge: number;
  endDate: Date;
  farm: Farm[];
  isOpen: boolean;
  lastAnimalGroup: number;
  location: Location[];
  nutritionType: NutritionType[];
  producer: Producer[];
  productionSystem: ProductionSystem;
  productionMethod: ProductionMethod;
  region: Region[];
  tank: Tank[];
  route: Route[];
  routes: Routes[];
  site: Site[];
  strain: Strain[];
  target: Target[];
  group: string;
  targetStatus: TargetStatus[];
  targetStatusKpi: TargetStatusKpi[];
  technician: Technician[];
  rules: Rule[];
  stage: StageEnum;
  kpi_list: KPIList[];
  has_farm: string;
  checklists: Checklist[];
  period_type: string;
  index: string;
  indexes: string;
  hatcheries: string;
}

export type ReportFilterQueryKeys = keyof ReportFilterQuery;

export interface ReportFilterQuery {
  animal_group_id?: string;
  begin_age?: number;
  begin_date?: string;
  company_id?: string;
  company_id_single?: string;
  current_cycle?: string;
  end_age?: number;
  end_date?: string;
  farm_id?: string;
  farm_name?: string;
  is_open?: string;
  last_animal_group?: number;
  location_id?: string;
  nutrition_type_id?: string;
  producer_id?: string;
  producer_name?: string;
  target_name?: string;
  target_id?: string;
  target_group?: string;
  group?: string;
  production_method?: string;
  production_system?: string;
  region_id?: string;
  region_name?: string;
  tank_id?: string;
  tank_name?: string;
  route_id?: string;
  site_id?: string;
  strain_id?: string;
  index?: string;
  indexes?: string;
  target_status_kpi?: string;
  target_status?: TargetStatus;
  technician_id?: string;
  technician_name?: string;
  routes_name?: string;
  routes_id?: string;
  rule_id?: string;
  rule_name?: string;
  rule_fase?: string;
  stage_id?: string;
  has_farm?: boolean;
  checklist_name?: string;
  checklist_id?: string;
  period_type?: string;
  hatcheries?: string;
}
