import { TargetDetail } from '@agriness/corp-app/services';
import { Injectable } from '@angular/core';

import { TargetData } from '../target.model';
import { TargetRange } from './target-subtitles.model';

@Injectable()
export class TargetSubtitlesService {
  constructor() {}

  roundToLower(value: number, decimalPlaces = 0): number {
    return value - 1 / 10 ** decimalPlaces;
  }

  roundToHigher(value: number, decimalPlaces = 0): number {
    return value + 1 / 10 ** decimalPlaces;
  }

  calculateTargetWithVariation(value: TargetDetail | TargetData): TargetRange {
    const targetVariation = (value.value_variation_rate / 100) * value.target_value;
    return [value.target_value - targetVariation, value.target_value + targetVariation];
  }
}
