<div [formGroup]="group">
  <p-autoComplete
    class="autocomplete"
    [styleClass]="suggestions?.length ? 'w-100' : 'w-100 autocomplete-empty'"
    [suggestions]="suggestions"
    [placeholder]="placeholder | translate"
    (completeMethod)="search($event)"
    (onClear)="clear()"
    [emptyMessage]="emptyMessage | translate"
    [formControlName]="controlName"
  >
    <ng-template *ngIf="templateItem" let-item pTemplate="item">
      <ng-container *ngTemplateOutlet="templateItem; context: { $implicit: item }"></ng-container>
    </ng-template>
  </p-autoComplete>
</div>
