<corp-report-cards
  [performances]="performances"
  [sectionName]="sectionName"
  [stage]="stage"
  [report]="report"
  [preferenceLabelPrefix]="preferenceLabelPrefix"
  [feedbackType]="typeFeedback"
  (getPreferences)="this.getPreferences($event)"
  (preferenceChange)="this.onPreferenceChange()"
></corp-report-cards>
