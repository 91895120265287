<ag-modal
  [visible]="visible"
  [showHeader]="modalStatus === ModalStatusEnum.DEFAULT"
  [closable]="modalStatus != ModalStatusEnum.LOADING"
  [title]="title"
  icon="delete"
  (hide)="onCancel()"
>
  <div class="container">
    <ag-modal-feedback
      [status]="modalStatus"
      [translationContext]="translationContext"
      (hide)="onCancel()"
    ></ag-modal-feedback>

    <ng-content *ngIf="modalStatus === ModalStatusEnum.DEFAULT"></ng-content>
  </div>

  <ag-modal-footer *ngIf="modalStatus === ModalStatusEnum.DEFAULT">
    <div class="content-footer">
      <guavi-button
        variant="ghost"
        format="regular"
        size="medium"
        data-testid="cancel"
        (onClick)="onCancel()"
      >
        {{ 'agriness.cancel' | translate }}
      </guavi-button>
      <guavi-button
        variant="danger"
        format="regular"
        size="medium"
        data-testid="delete"
        (onClick)="onDelete()"
      >
        {{ 'agriness.delete' | translate }}
      </guavi-button>
    </div>
  </ag-modal-footer>
</ag-modal>
