import { Component } from '@angular/core';
import { environment } from '@env/environment.dev';

import { generalTabs } from './production.model';

@Component({
  selector: 'app-production',
  templateUrl: './production.component.html',
  styleUrls: ['./production.component.scss'],
})
export class ProductionComponent {
  tabs = generalTabs[environment.typeProduction];
}
