<svg viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M20.5 38.5C30.4411 38.5 38.5 30.4411 38.5 20.5C38.5 10.5589 30.4411 2.5 20.5 2.5C10.5589 2.5 2.5 10.5589 2.5 20.5C2.5 30.4411 10.5589 38.5 20.5 38.5Z"
    fill="url(#paint0_linear)"
  />
  <path
    d="M20.5 40C9.748 40 1 31.252 1 20.5C1 9.748 9.748 1 20.5 1C31.252 1 40 9.748 40 20.5C40 31.252 31.252 40 20.5 40ZM20.5 4C11.402 4 4 11.402 4 20.5C4 29.598 11.402 37 20.5 37C29.598 37 37 29.598 37 20.5C37 11.402 29.598 4 20.5 4Z"
    fill="#1B3858"
  />
  <path
    d="M18.3331 27.9C17.8031 27.9 17.2941 27.689 16.9191 27.314L11.9891 22.383C11.2081 21.602 11.2081 20.336 11.9891 19.555C12.7701 18.774 14.0371 18.774 14.8171 19.555L18.3331 23.072L26.2531 15.152C27.0341 14.371 28.3001 14.371 29.0811 15.152C29.8621 15.933 29.8621 17.199 29.0811 17.98L19.7471 27.314C19.3721 27.69 18.8631 27.9 18.3331 27.9Z"
    fill="url(#paint1_linear)"
  />
  <defs>
    <linearGradient
      id="paint0_linear"
      x1="20.5"
      y1="38.5"
      x2="20.5"
      y2="2.5"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="#59D051" />
      <stop offset="0.3598" stop-color="#3EBF35" />
      <stop offset="0.7561" stop-color="#27B01D" />
      <stop offset="1" stop-color="#1EAA14" />
    </linearGradient>
    <linearGradient
      id="paint1_linear"
      x1="20.535"
      y1="27.9005"
      x2="20.535"
      y2="14.566"
      gradientUnits="userSpaceOnUse"
    >
      <stop stop-color="white" />
      <stop offset="1" stop-color="white" />
    </linearGradient>
  </defs>
</svg>
