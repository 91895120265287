<div data-testid="report-filters-subtitle" class="container-subtitles">
  <div>
    <ng-container *ngFor="let filter of subtitles">
      <span class="has-text-grey-3"> {{ filter[0] }}: </span>
      <span> {{ filter[1] }}; </span>
    </ng-container>
  </div>
  <div class="actions-wrapper">
    <button
      type="button"
      class="btn btn-ghost-light is-small"
      data-testid="filter-bar-button"
      (click)="resetFilterForm()"
    >
      {{ 'agriness.filter.clear' | translate }}
    </button>
  </div>
</div>
