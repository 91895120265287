export enum ValueFormatStyle {
  DATE = 'date',
  DECIMAL = 'decimal',
  GRAM = 'gram',
  KILOGRAM = 'kilogram',
  POUNDS = 'pounds',
  LITER = 'liter',
  MONETARY = 'monetary',
  PERCENTAGE = 'percentage',
  TEXT = 'text',
  TONNES = 'tonnes',
  UNITARY = 'unitary',
  WEEKS = 'weeks',
  CELSIUS = 'celsius',
}

export enum LocaleByDecimalPlaces {
  'BRAZILIAN' = 'pt-BR',
  'ENGLISH' = 'en-US',
}

export type ShowSymbol = 'show-symbol' | 'hide-symbol';

export function getValueStyle(
  value: any,
  initialStyle: ValueFormatStyle | string,
): ValueFormatStyle {
  let style: string = initialStyle ? initialStyle : ValueFormatStyle.TEXT;

  style = style.toLowerCase();

  if (value == null) {
    return style as ValueFormatStyle;
  }

  if (style === ValueFormatStyle.DATE || value instanceof Date) {
    return ValueFormatStyle.DATE;
  }
  if (typeof value === 'number') {
    if (style === ValueFormatStyle.TEXT) {
      if (Number.isInteger(value)) {
        return ValueFormatStyle.UNITARY;
      } else {
        return ValueFormatStyle.DECIMAL;
      }
    }
    return style as ValueFormatStyle;
  }
  return ValueFormatStyle.TEXT;
}
