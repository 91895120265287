import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../../common/shared.module';
import { AgFilterBarActionComponent } from './ag-filter-bar-action.component';

@NgModule({
  imports: [NgbDropdownModule, SharedModule],
  declarations: [AgFilterBarActionComponent],
  exports: [AgFilterBarActionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgFilterBarActionModule {}
