import { TypeViewPreference } from '@agriness/corp-app/services';

export enum DefaultTickIntervals {
  ONE_WEEK = 7 * 24 * 3600 * 1000,
  ONE_MONTH = 30 * 24 * 3600 * 1000,
}

export type GraphSeriesType = 'line' | 'column';

export type ValueFormat = 'numeric' | 'percentage';

export type TypeViewPreferenceWithColor = TypeViewPreference & { color?: string };
