import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

import { SharedModule } from '../../common/shared.module';
import { AgNumberFieldComponent } from './ag-number-field.component';

@NgModule({
  declarations: [AgNumberFieldComponent],
  imports: [FormsModule, ReactiveFormsModule, CommonModule, SharedModule, InputTextModule],
  exports: [AgNumberFieldComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgNumberFieldModule {}
