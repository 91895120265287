import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, SelectComponent } from '../corp-filter-abstractions';
import { FixedLoaderService, SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-farm-bond-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.select.html',
  providers: [{ provide: FilterComponent, useExisting: CorpFarmBondFilterComponent }],
})
export class CorpFarmBondFilterComponent extends SelectComponent {
  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(translate, containerService, activeRoute);

    this.assignDefaults(
      'has_farm',
      new SelectLoader(
        new FixedLoaderService(
          [
            ['null', 'agriness.filter.has_farm.all'],
            ['true', 'agriness.filter.has_farm.bonded'],
            ['false', 'agriness.filter.has_farm.not-bonded'],
          ],
          translate,
        ),
      ),
    );
  }
}
