import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { ModalStatusEnum } from '@agriness/ui';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';

import { Indexes } from '../../rule-editor-form.model';

@Component({
  selector: 'app-index-configurator',
  templateUrl: './index-configurator.component.html',
  styleUrls: ['./index-configurator.component.scss'],
})
export class IndexConfiguratorComponent implements OnInit {
  @Input() visible = false;
  @Input() configData: Indexes;
  @Input() modalStatus = ModalStatusEnum.DEFAULT;

  @Output() closed = new EventEmitter<void>();
  @Output() createConfig = new EventEmitter<void>();
  @Output() updateConfig = new EventEmitter<void>();

  isEdit = true;

  errorMessages = false;

  translationContext = 'agriness.payment-rule-configuration.config.modal';

  ranges = new FormArray([], Validators.required.bind(this));

  constructor(@Inject(TRANSLATE_INSTANT) private t: TranslateInstant, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.addConfigRange();
  }

  onSubmit(): void {
    this.createConfig.emit();
  }

  onClosed(): void {
    this.closed.emit();
  }

  updatedFormConfig(): void {
    this.configData.ranges.forEach(item => {
      this.ranges.push(
        this.fb.group({
          range: new FormControl(item.range, [Validators.required.bind(this), Validators.min(0)]),
          percentage: new FormControl(item.percentage, [Validators.required.bind(this)]),
        }),
      );
    });

    this.isEdit = false;
  }

  validateForm(): void {
    this.ranges.valueChanges.subscribe(() => {
      this.ranges.controls.forEach(control => {
        if (!control.touched && !control.valid) {
          this.errorMessages = false;
        } else if (control.touched && !control.valid) {
          this.errorMessages = true;
        }
      });
    });
  }

  getConfigForm(): Indexes {
    const form = this.ranges.getRawValue();

    const kpi = cloneDeep(this.configData);

    kpi.ranges = [];

    form.forEach(item => {
      kpi.ranges.push(item);
    });

    return kpi;
  }

  addConfigRange(): void {
    if (this.configData.ranges && this.isEdit) {
      this.updatedFormConfig();
    } else {
      this.ranges.push(
        new FormGroup({
          range: new FormControl(null, [Validators.required.bind(this), Validators.min(0)]),
          percentage: new FormControl(null, [Validators.required.bind(this)]),
        }),
      );
    }
  }

  cantRemove(): boolean {
    return this.ranges.length <= 1;
  }

  remove(index: number): void {
    if (this.cantRemove()) {
      return;
    }
    this.ranges.removeAt(index);
  }
}
