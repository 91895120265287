import { Component, Input } from '@angular/core';

@Component({
  selector: 'ag-root',
  templateUrl: 'ag-root.component.html',
})
export class AgRootComponent {
  @Input() headerLabel: string;
  @Input() iconTitle: string;

  panelCollapse: boolean;

  constructor() {}

  toggleCollapse(): void {
    this.panelCollapse = !this.panelCollapse;
  }
}
