<div *ngIf="hasError(); else renderLink" class="d-flex flex-row">
  <span class="icon is-20 has-text-grey-4 align-middle" [ngClass]="iconClass"></span>
  <span
    [agTooltip]="getStatusMessage()"
    class="icon is-14 align-middle ml-1"
    [ngClass]="iconStatusClass"
  >
  </span>
  <p class="font-weight-bold ml-1 mb-0" [agTooltip]="animalGroup.animal_group_name">
    {{ animalGroup.animal_group_name }}
  </p>
</div>

<ng-template #renderLink>
  <a
    [routerLink]="route"
    target="_blank"
    class="font-weight-bold link"
    [ngStyle]="{ 'pointer-events': 'auto' }"
    [agTooltip]="animalGroup.animal_group_name"
    (click)="onClick()"
  >
    <span class="icon is-20 has-text-grey-4 align-middle" [ngClass]="iconClass"></span>
    <span class="icon is-14 align-middle" [ngClass]="iconStatusClass"> </span>
    {{ animalGroup.animal_group_name }}
    <span class="icon is-12 icon-open-new-tab-small"></span>
  </a>
</ng-template>
