import {
  BaseEntityFilter,
  ContextFilter,
  PageNumberPagination,
  QueryCorp,
  StageEnum,
} from '@agriness/services';
import { RepresentationTypeEnum } from '@agriness/ui/component/target/target.model';

export enum TargetReportEnum {
  MILKING_ALL = 'milking_all_targets',

  LAYERS_PRODUCTION_EGG = 'layers_production_egg_targets',
  LAYERS_PRODUCTION_FEED = 'layers_production_feed_targets',
  LAYERS_PRODUCTION_MORTALITY = 'layers_production_mortality_targets',
  LAYERS_PRODUCTION_BODY_WEIGHT = 'layers_production_body_weight_targets',

  GROW_OUT_WEIGHT = 'grow_out_weight_targets',
  GROW_OUT_MORTALITY = 'grow_out_mortality_targets',
  GROW_OUT_EFFICIENCY = 'grow_out_efficiency_targets',
  GROW_OUT_COST = 'grow_out_cost_targets',

  FINISHING_QUALITY = 'finishing_quality_targets',
  FINISHING_DEADLINE = 'finishing_deadline_targets',
  FINISHING_COST = 'finishing_cost_targets',

  NURSERY_QUALITY = 'nursery_quality_targets',
  NURSERY_DEADLINE = 'nursery_deadline_targets',
  NURSERY_COST = 'nursery_cost_targets',

  REPRODUCTIVE_PLANTEL = 'reproductive_plantel_targets',
  REPRODUCTIVE_REPRODUCTION = 'reproductive_reproduction_targets',
  REPRODUCTIVE_MATERNITY = 'reproductive_maternity_targets',
}

interface Index {
  decimal_places: number;
  id: string;
  key: string;
  name: string;
  measurement_unit: string;
}

export interface Target {
  id: string;
  is_default: boolean;
  max_allowed: number;
  max_recommended: number;
  min_allowed: number;
  min_recommended: number;
  name: string;
  order: number;
  representation_type: string;
  target_value: number;
  value_variation_rate: number;

  index: Index;
  report?: TargetReportEnum;
  stage: StageEnum;
}
export interface TargetV2 {
  id: string;
  name: string;
  holding_id: string;
  target_index: TargetIndex;
  stage: StageInfo;
  period_type: PeriodType;
  target_detail: TargetDetail[];
  created_date: Date;
  updated_date: Date;
  can_delete: boolean;
  enabled: boolean;
}

export interface Targets {
  count: number;
  results: Target[] | any;
}

export interface TargetsIndexes {
  results: TargetIndex[];
}

export interface IndexInfo {
  id: string;
  key: string;
  name: string;
}

export interface TargetDetail {
  id?: number;
  period: number;
  max_recommended: number;
  min_recommended: number;
  max_allowed: number;
  min_allowed: number;
  value_variation_rate: number;
  target_value: number;
}

export interface TargetIndex {
  id: string;
  index_id?: string;
  group?: string;
  name: string;
  stage: string;
  max_allowed: number;
  min_allowed: number;
  representation_type: RepresentationTypeEnum;
}

export interface Target {
  id: string;
  name: string;
  farm_count: number;
  animalgroup_count: number;
}

export interface TargetPeriodType {
  DAILY: boolean;
  GENERAL: boolean;
  WEEKLY: boolean;
}

export interface StageInfo {
  id: string;
  name: string;
}
export interface TargetGroup {
  id: string;
  name: string;
}

export enum PeriodType {
  GENERAL = 'GENERAL',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}

export interface TargetForm {
  id?: string;
  name?: string;
  holding_id: string;
  stage: string;
  target_index: string;
  period_type: string;
  target_group: string;
  target_detail: TargetDetail[];
  enabled: boolean;
}

export interface TargetGroupFilter {
  group?: string;
}

export interface TargetPeriodTypeFilter {
  period_type?: string;
}

export const defaultTargetOrder: (keyof Target)[] = ['name'];

export type TargetFilter = BaseEntityFilter &
  PageNumberPagination &
  ContextFilter &
  QueryCorp &
  TargetGroupFilter &
  TargetPeriodTypeFilter;
