import { AgrinessUiModule } from '@agriness/ui';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { SelectButtonModule } from 'primeng/selectbutton';

import { SharedModule } from '../../shared/shared.module';
import { AnimalGroupEditorComponent } from './animal-group-editor/animal-group-editor.component';
import { AnimalGroupRoutingModule } from './animal-group-routing.module';
import { AnimalGroupStateService } from './animal-group-state.service';
import { AnimalGroupComponent } from './animal-group.component';
import { AnimalGroupService } from './animal-group.service';
import { ComfortComponent } from './comfort/comfort.component';
import { MovementComponent } from './movement/movement.component';
import { ConsumptionGraphComponent } from './nutrition/consumption-graph/consumption-graph.component';
import { NutritionComponent } from './nutrition/nutrition.component';
import { OverviewComponent } from './overview/overview.component';
import { PaymentConfirmModalComponent } from './payment/payment-confirm-modal/payment-confirm-modal.component';
import { PaymentComponent } from './payment/payment.component';
import { PerformanceExporterComponent } from './performance/performance-exporter/performance-exporter.component';
import { PerformanceComponent } from './performance/performance.component';
import { SanityPoultryGraphComponent } from './sanity/poultry-graph/sanity-poultry-graph.component';
import { SanityPoultryComponent } from './sanity/poultry/sanity-poultry.component';
import { SanityComponent } from './sanity/sanity.component';
import { SanitySwinesComponent } from './sanity/swines/sanity-swines.component';
import { SanitySwinesService } from './sanity/swines/sanity-swines.service';
import { VaccinesMedicationsComponent } from './sanity/vaccines-medications/vaccines-medications.component';
import { TraceabilityComponent } from './traceability/traceability.component';

@NgModule({
  imports: [
    SharedModule,
    AnimalGroupRoutingModule,
    HighchartsChartModule,
    FormsModule,
    ReactiveFormsModule,
    SelectButtonModule,
    AgrinessUiModule,
  ],
  declarations: [
    AnimalGroupComponent,
    ComfortComponent,
    MovementComponent,
    OverviewComponent,
    PerformanceComponent,
    PerformanceExporterComponent,
    NutritionComponent,
    SanityComponent,
    SanitySwinesComponent,
    SanityPoultryComponent,
    SanityPoultryGraphComponent,
    TraceabilityComponent,
    ConsumptionGraphComponent,
    VaccinesMedicationsComponent,
    AnimalGroupEditorComponent,
    PaymentComponent,
    PaymentConfirmModalComponent,
  ],
  providers: [AnimalGroupService, AnimalGroupStateService, SanitySwinesService],
  exports: [AnimalGroupComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AnimalGroupModule {}
