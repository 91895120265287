import { NutritionType, NutritionTypeService } from '@agriness/corp-app/services';
import { SelectLoader } from '@agriness/corp-app/shared/component/corp-filters/corp-filter.service';
import { TRANSLATE_INSTANT, TranslateInstant, StageEnum } from '@agriness/services';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { kebabCase, snakeCase } from 'lodash';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'corp-nutrition-type-select',
  templateUrl: './corp-field-select.component.html',
})
export class CorpNutritionTypeSelectComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() stage: StageEnum;
  @Input() queryCorpOnly = true;

  controlName: string;
  labelKey: string;
  emptySelectionKey: string;
  isSearchable = true;
  loader: SelectLoader<NutritionType>;
  kebabCase = kebabCase;

  protected loadSubscription = Subscription.EMPTY;

  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    private nutritionTypeService: NutritionTypeService,
  ) {}

  ngOnInit(): void {
    this.assignDefaults(
      'nutrition_type',
      new SelectLoader(this.nutritionTypeService, {
        queryCorpOnly: this.queryCorpOnly,
        stage: this.stage,
      }),
    );
    const ids = this.form.value as string;
    this.loader.loadInitialValues([ids['nutrition_type']]).pipe(first()).subscribe();
    this.loadSubscription = this.loader.loadOnFilter().subscribe();
  }

  ngOnDestroy(): void {
    this.loadSubscription.unsubscribe();
  }

  private assignDefaults(name: string, loader: SelectLoader<NutritionType>): void {
    const snakeName = snakeCase(name);

    this.controlName = name;
    this.loader = loader;
    this.labelKey = `agriness.filter.${snakeName}.title`;
    this.emptySelectionKey = `agriness.feedback.no_item_selected`;
  }
}
