import { LookerPanelService } from '@agriness/corp-app/services/looker/looker-panel.service';
import { LookerPanel } from '@agriness/corp-app/services/models/looker-panel.model';
import { StageEnum } from '@agriness/services';
import { FeedbackEnum } from '@agriness/ui';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  cards: LookerPanel[];
  stage: StageEnum;
  typeFeedback = FeedbackEnum.LOADING;

  constructor(private lookerPanel: LookerPanelService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.searchLookerPanels();
  }

  searchLookerPanels(): void {
    this.stage = this.route.snapshot.data.stage as StageEnum;
    this.lookerPanel.list(this.stage, 'labs', {}).subscribe(response => {
      this.cards = response;
      this.typeFeedback = !this.cards.length ? FeedbackEnum.NO_LOOKER_PANEL : null;
    });
  }
}
