import { DisplayOption } from '@agriness/corp-app/record/animal-group/performance/performance.model';
import { PreferenceId } from '@agriness/corp-app/services';
import { StageEnum, TypeProductionEnum } from '@agriness/services';
import { DualListBoxItem } from '@agriness/ui';

import { ReportFilterType } from '../../model/report-filter.model';

export enum PerformanceAnalysisGroup {
  STRAIN = 'strain',
  PERIOD = 'period',
  PERFORMANCE_ANALYSIS = 'performance-analysis',
  SEQUENCE = 'sequence',
  YEAR = 'year',
}

export interface PerformanceAnalysisTab {
  router: string;
  translateKey: string;
}

export type PerformanceAnalysisTabReport = PerformanceAnalysisTab & {
  report: PreferenceId;
  cardReport: PreferenceId;
  indicators: DualListBoxItem[];
};

export const filterIdsPerformanceAnalysisByStageAndGroup: {
  [k in StageEnum]?: { [k in PerformanceAnalysisGroup]?: ReportFilterType[] };
} = {
  [StageEnum.MILKING]: {
    [PerformanceAnalysisGroup.PERFORMANCE_ANALYSIS]: [
      'COMPANY',
      'PERIOD',
      'FARM',
      'REGION',
      'TECHNICIAN',
    ],
  },
  [StageEnum.REPRODUCTIVE]: {
    [PerformanceAnalysisGroup.PERFORMANCE_ANALYSIS]: [
      'COMPANY',
      'PERIOD',
      'FARM',
      'REGION',
      'TECHNICIAN',
    ],
  },
};

export const defaultDisplayOptionByTypeProduction: { [k in TypeProductionEnum]?: DisplayOption } = {
  [TypeProductionEnum.DAIRY]: DisplayOption.BY_DATE,
  [TypeProductionEnum.SWINES]: DisplayOption.BY_DATE,
};

export const displayOptionsByTypeProduction: { [k in TypeProductionEnum]?: DisplayOption[] } = {
  [TypeProductionEnum.DAIRY]: [],
  [TypeProductionEnum.SWINES]: [],
};

export const defaultIndicatorOptionByTypeProduction: { [k in TypeProductionEnum]?: string } = {
  [TypeProductionEnum.DAIRY]: 'AVERAGE_VOLUME_MILK_PER_COLLECTION_DAY_CALCULATED',
  [TypeProductionEnum.SWINES]: 'AVERAGE_NON_PRODUCTIVE_DAYS',
};
