import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  splashVisible = true;

  constructor(private router: Router) {}

  activate(): void {
    this.splashVisible = false;
  }

  deactivate(): void {
    this.splashVisible = true;
  }
}
