import { Search, DateService, TypeProductionService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiCorpService } from '../services/api-corp.service';
import { Technician } from '../services/models/technician.model';
import {
  CalendarDate,
  ScheduleCell,
  TechnicianCell,
} from './presence-calendar/presence-calendar.model';
import { ScheduleParams, ScheduleResponse, GroupedScheduleResponse } from './presence.model';

@Injectable({ providedIn: 'root' })
export class PresenceService {
  private pathBase = 'holdings/{holding_id}';
  private pathSchedules = `${this.pathBase}/schedules/`;

  constructor(
    private typeProductionService: TypeProductionService,
    protected apiCorpService: ApiCorpService,
    protected dateService: DateService,
  ) {}

  getData(
    holding_id: string,
    start_date: CalendarDate = null,
    end_date: CalendarDate = null,
    technician_id?: string,
  ): Observable<[ScheduleCell[], TechnicianCell[]]> {
    const schedules = this.searchTechnicianGroupedSchedule(
      holding_id,
      start_date,
      end_date,
      technician_id,
    );
    const technicians = this.getTechnicians(holding_id);

    return forkJoin([schedules, technicians]);
  }

  searchTechnicianSchedule(
    holding_id: string,
    date: Date,
    technician_id: string,
  ): Observable<ScheduleResponse[]> {
    const url = this.apiCorpService.translateUrl(this.pathSchedules, { holding_id });
    const paramDate = this.dateService.formatAsIsoDate(date);
    const params: ScheduleParams = {
      system_type: this.typeProductionService.get(),
      begin_date: paramDate,
      end_date: paramDate,
      technician_id,
    };
    return this.apiCorpService.get<ScheduleResponse[]>(url, params);
  }

  searchTechnicianGroupedSchedule(
    holding_id: string,
    begin_date: Date,
    end_date: Date,
    technician_id?: string,
  ): Observable<ScheduleCell[]> {
    const url = this.apiCorpService.translateUrl(this.pathSchedules, { holding_id });
    const param_begin_date = this.dateService.formatAsIsoDate(begin_date);
    const param_end_date = this.dateService.formatAsIsoDate(end_date);
    const params: ScheduleParams = {
      system_type: this.typeProductionService.get(),
      begin_date: param_begin_date,
      end_date: param_end_date,
      group_by: 'day',
      technician_id,
    };
    return this.apiCorpService.get<GroupedScheduleResponse[]>(url, params).pipe(
      map(resp => {
        return resp.map(
          schedule =>
            new ScheduleCell({
              date: new CalendarDate(schedule.date.replace(/-/g, '/')),
              technician: null,
              technician_user_id: schedule.technician,
              schedules_finished: schedule.schedules_finished,
              schedules_total: schedule.schedules_total,
            }),
        );
      }),
    );
  }

  getTechnicians(holding_id: string): Observable<TechnicianCell[]> {
    const url = `holdings/${holding_id}/technicians/`;
    const queryParams = { system_type: this.typeProductionService.get() };
    return this.apiCorpService.get<Search<Technician>>(url, queryParams).pipe(
      map(resp => resp.results.filter(result => result.user_id)),
      map(technicians =>
        technicians.map(
          technician => new TechnicianCell(technician.id, technician.user_id, technician.name),
        ),
      ),
    );
  }
}
