<section class="is-full">
  <corp-report-filters
    #reportFilter
    [availableFilters]="filterIds"
    [stage]="stage"
    [animalGroupStatus]="animalGroupStatus"
    (filter)="filter()"
    (ready)="loadData()"
  >
  </corp-report-filters>
  <ag-feedback [type]="typeFeedback"> </ag-feedback>

  <div [hidden]="typeFeedback != null">
    <ng-container *ngFor="let item of tableIndexes">
      <ng-container [ngSwitch]="stage">
        <corp-report-cards-by-period
          *ngSwitchCase="StageEnum.GROW_OUT"
          #report
          [sectionName]="item.name"
          [stage]="stage"
          [report]="item.internalName"
          [filter]="currentFilter"
          [cellLink]="linkAnimalGroupList"
        >
        </corp-report-cards-by-period>

        <corp-report-cards-by-period
          *ngSwitchCase="StageEnum.NURSERY"
          #report
          [sectionName]="item.name"
          [stage]="stage"
          [report]="item.internalName"
          [filter]="currentFilter"
          [cellLink]="linkAnimalGroupList"
        >
        </corp-report-cards-by-period>

        <corp-report-cards-by-period
          *ngSwitchCase="StageEnum.FINISHING"
          #report
          [sectionName]="item.name"
          [stage]="stage"
          [report]="item.internalName"
          [filter]="currentFilter"
          [cellLink]="linkAnimalGroupList"
        >
        </corp-report-cards-by-period>

        <corp-report-cards-by-period
          *ngSwitchCase="StageEnum.WEAN_TO_FINISH"
          #report
          [sectionName]="item.name"
          [stage]="stage"
          [report]="item.internalName"
          [filter]="currentFilter"
          [cellLink]="linkAnimalGroupList"
        >
        </corp-report-cards-by-period>

        <corp-report-indexes-table-by-period
          *ngSwitchDefault
          #report
          [sectionName]="item.name"
          [stage]="stage"
          [report]="item.internalName"
          [filter]="currentFilter"
          [cellLink]="linkAnimalGroupList"
        >
        </corp-report-indexes-table-by-period>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let item of cardsIndexes">
      <corp-report-monitor-cards
        #report
        [sectionName]="item.name"
        [stage]="stage"
        [report]="item.internalName"
        [filter]="currentFilter"
      ></corp-report-monitor-cards>
    </ng-container>
  </div>
</section>
