import { UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { LookerPanel, LookerPanelFilter } from '../models/looker-panel.model';
import { LookerPanelAbstractService } from './looker-panel-abstract.service';

@Injectable({
  providedIn: 'root',
})
export class LookerPanelService extends LookerPanelAbstractService {
  private pathBase = 'holdings/{holding_id}/reports/looker/{stage}/{type}/';

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
  ) {
    super();
  }

  list(stage: string, type: string, { ...filters }: LookerPanelFilter): Observable<LookerPanel[]> {
    const url = this.apiCorpService.translateUrl(this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
      stage: stage,
      type,
    });

    delete filters.order;
    const params = {
      ...filters,
      is_super_holding: this.userStorageService.isSuperHolding(),
    };
    return this.apiCorpService.get<LookerPanel[]>(url, params);
  }
}
