import { Component } from '@angular/core';

/**
 * Our default back button that is shown
 * usually at the left side of the page title
 */
@Component({
  selector: 'corp-back-button',
  template: `
    <button *ag-back class="btn btn-ghost has-round-hover mr-1" data-testid="back-button">
      <span class="icon is-16 icon-back-outline"></span>
    </button>
  `,
})
export class CorpBackButtonComponent {}
