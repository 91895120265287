import { EventCategory, EventName } from '@agriness/services/analytics/analytics.model';
import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';

import { AgPopoverComponent } from '../popover/ag-popover.component';
import { ToggleColumnItem } from './ag-toggle-column.model';

@Component({
  selector: 'ag-toggle-column',
  templateUrl: './ag-toggle-column.component.html',
  styleUrls: ['./ag-toggle-column.component.scss'],
})
export class AgToggleColumnComponent {
  @ViewChild('popover', { static: true }) popOver: AgPopoverComponent;
  @Input('itemsUser')
  set inputItemsUser(value: ToggleColumnItem[]) {
    this.rawItemsUser = value;
    this.itemsUser = null;
  }
  @Input() buttonIconClass = 'icon-column-settings';
  @Input() itemsDefault: ToggleColumnItem[];
  @Output() save = new EventEmitter<ToggleColumnItem[]>();

  stageEvent = ['reproductive'];
  isDragged = false;
  itemsUser: ToggleColumnItem[];
  rawItemsUser: ToggleColumnItem[];
  constructor(
    private readonly route: ActivatedRoute,
    private analyticsService: AmplitudeAnalyticsService,
  ) {}

  onOpen(): void {
    if (this.stageEvent.includes(this.route.snapshot.data.stage)) {
      this.analyticsService.logPreference(EventName.CLICK_PREFERENCE_SITE_1);
    }
    this.itemsUser = this.prepareItems(this.rawItemsUser);
  }

  private prepareItems(items: ToggleColumnItem[]): ToggleColumnItem[] {
    if (!items) {
      return items;
    }
    return items.filter(item => item != null).map(item => ({ ...item }));
  }

  revertToggleColumn() {
    this.itemsUser = [...this.prepareItems(this.itemsDefault)];
  }

  saveToggleColumn() {
    this.save.emit(this.itemsUser);
    this.popOver.close();
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (this.isDragged) {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDrop(event: DndDropEvent, list?: any[]) {
    this.isDragged = false;
    const index = event.index;
    if (list[index] != null && list[index]['frozen']) {
      list = this.itemsUser;
      return;
    }
    this.isDragged = true;
    list.splice(index, 0, event.data);
  }
}
