import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { PipesModule } from '../../pipe/pipes.module';
import { AgFormatModule } from '../format/ag-format.module';
import { AgCardLookerComponent } from './ag-card-looker.component';

@NgModule({
  imports: [SharedModule, PipesModule, AgFormatModule],
  declarations: [AgCardLookerComponent],
  exports: [AgCardLookerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgCardLookerModule {}
