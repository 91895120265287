import {
  PreferenceId,
  TypeViewPreference,
  UserPreferencesAbstractService,
} from '@agriness/corp-app/services';
import { StageEnum } from '@agriness/services';
import { ToggleColumnItem } from '@agriness/ui';
import * as _ from 'lodash';

export interface ToggleColumnPreferenceItem extends ToggleColumnItem {
  object?: TypeViewPreference;
}

export interface ToggleColumnPreferenceUser {
  default?: ToggleColumnPreferenceItem[];
  user?: ToggleColumnPreferenceItem[];
}

function isChildIndex(item: ToggleColumnPreferenceItem) {
  return 'parent' in item.object;
}

function getParentName(item: ToggleColumnPreferenceItem) {
  return item.object.parent;
}

export class ViewPreferences {
  static saveViewPreferences(
    services: UserPreferencesAbstractService,
    toggleColumnsItems: ToggleColumnPreferenceItem[],
    preference: PreferenceId,
    callback: () => void,
    stage: StageEnum,
  ): void {
    if (!toggleColumnsItems) {
      services.deleteViewPreference(preference, stage).subscribe(() => callback());
      return;
    }
    _.forEach(toggleColumnsItems, (item, index) => (item.order = index));

    const [children, parents] = _.partition(toggleColumnsItems, isChildIndex);

    const enabledParents = new Set<string>();
    const allPreferences: TypeViewPreference[] = [
      ...parents.map(item => {
        if (item.checked) {
          enabledParents.add(item.object.index_name);
        }
        item.object.enable = item.checked;
        item.object.order = item.order;
        return item.object;
      }),
      ...children.map(item => {
        item.object.enable = enabledParents.has(getParentName(item));
        item.object.order = item.order;
        return item.object;
      }),
    ];

    const sortedPreferences = allPreferences.sort((a, b) => a.order - b.order);

    services.saveViewPreference(preference, sortedPreferences, stage).subscribe(() => callback());
  }
}
