import { Component, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';

@Component({
  selector: 'ag-line',
  templateUrl: './ag-line.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgLineComponent {
  @HostBinding('class') class = 'ag-line';

  @Input() isHorizontal: boolean;
  @Input() isReverse: boolean;
  @Input() arrowStart: boolean;
  @Input() arrowEnd: boolean;

  reverseClass(): string {
    return this.isReverse ? 'reverse' : '';
  }
}
