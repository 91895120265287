import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { AgFormatModule } from '../format/ag-format.module';
import { AgStatusModule } from '../status/ag-status.module';
import { AgTargetIndicatorModule } from './target-indicator/ag-target-indicator.module';
import { AgTargetSubtitlesComponent } from './target-subtitles/ag-target-subtitles.component';
import { TargetRepresentationConfig } from './target-subtitles/representation-types/target-representation-config.service';
import { TargetSubtitlesService } from './target-subtitles/target-subtitles.service';

@NgModule({
  imports: [SharedModule, AgTargetIndicatorModule, AgFormatModule, AgStatusModule],
  declarations: [AgTargetSubtitlesComponent],
  exports: [AgTargetSubtitlesComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [TargetSubtitlesService, TargetRepresentationConfig],
})
export class AgTargetModule {}
