<section class="section is-full">
  <div class="page-title mb-4">
    {{ translationContext + '.title' | translate }}
  </div>

  <div class="mb-3" style="width: 100%;">
    <div class="d-flex">
      <corp-filters-container #filtersContainer (filter)="filter()" (resetFilter)="filterReset()">
        <corp-textsearch-filter
          [filterEntity]="filterEntity"
          [queryParamName]="queryParamName"
          class="mb-2"
        ></corp-textsearch-filter>
        <corp-producer-filter [farmFilter]="true"></corp-producer-filter>
        <corp-region-filter [farmFilter]="true"></corp-region-filter>
        <corp-technician-filter [farmFilter]="true"></corp-technician-filter>
      </corp-filters-container>
    </div>

    <div>
      <corp-report-filters-subtitle></corp-report-filters-subtitle>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col">
      <corp-record-table
        #table
        [title]="translationContext + '.selected' | translate"
        [tableColumns]="tableColumns"
        [defaultOrdering]="defaultOrdering"
        [data]="farms"
        [totalRecords]="totalRecords"
        [loading]="loading"
        [error]="error"
        [actions]="availableActions"
        (actionClick)="onItemAction($event)"
        (pageRequest)="onPageRequest($event)"
      >
      </corp-record-table>
    </div>
  </div>

  <farm-editor
    #editor
    *ngIf="editorModalVisible"
    [visible]="editorModalVisible"
    [farmData]="selectedFarm"
    [modalStatus]="editorModalStatus"
    (closed)="onEditorClosed()"
    (updateFarm)="updateFarm()"
  ></farm-editor>
</section>
