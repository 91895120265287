import {
  FarmHoldingRelation,
  FarmService,
  SearchFarmHoldingRelations,
  UserStorageService,
} from '@agriness/services';
import { AgrinessTranslateService } from '@agriness/services';
import { FeedbackEnum, RedirectItemFeedback, RedirectModalService } from '@agriness/ui';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError, flatMap, last, map, mergeMap, pluck } from 'rxjs/operators';

import { ApiS4FarmService } from '../../services/api-s4-farm.service';
import {
  S4FarmRequestParams,
  S4FarmResponse,
  Service,
} from '../component/corp-record-list-farm-link/corp-record-list-farm-link.model';
import { LoaderUserPreference } from '../component/loader-user-preference';

const HOLDER_RELATION_TYPE_NAME = 'HOLDER';

@Injectable({ providedIn: 'root' })
export class CorpFarmLinkService extends LoaderUserPreference {
  apiS4FarmPath = 'api/accounts/{holding_id}/farms/{farm_id}';
  appS4FarmPath = `${environment.appS4Farm}{account_id}/{farm_id}`;

  constructor(
    protected userStorageService: UserStorageService,
    protected farmService: FarmService,
    protected apiS4FarmService: ApiS4FarmService,
    protected redirectManager: RedirectModalService,
    protected translate: AgrinessTranslateService,
  ) {
    super(userStorageService);
  }

  redirectUser(farmId: string, service: Service): void {
    this.redirectManager
      .redirect(this.getFarmLink(farmId), service, (err: Error) => this.manageFeedbackError(err))
      .pipe(catchError(() => of(null)))
      .subscribe();
  }

  getFarmLink(farm_id: string): Observable<string> {
    return this.farmService.getHoldingRelations(farm_id).pipe(
      flatMap((r: SearchFarmHoldingRelations) => r.results),
      last((r: FarmHoldingRelation) => r.relation_type.name == HOLDER_RELATION_TYPE_NAME),
      pluck('holding', 'id'),
      map((holding_id: string) =>
        this.apiS4FarmService.translateUrl(this.apiS4FarmPath, {
          holding_id,
          farm_id,
        }),
      ),
      mergeMap((url: string) => this.apiS4FarmService.get<S4FarmResponse>(url)),
      map((r: S4FarmResponse) => ({ account_id: r.data.account.id, farm_id: r.data.id })),
      map((params: S4FarmRequestParams) =>
        this.apiS4FarmService.translateUrl(this.appS4FarmPath, params),
      ),
    );
  }

  private manageFeedbackError(err: Error): RedirectItemFeedback {
    if (err instanceof HttpErrorResponse && err.status === 403) {
      return { icon: FeedbackEnum.LOCK, message: this.t('error.unauthorized') };
    }

    return { message: this.t('error.fallback') };
  }

  private t(key: string) {
    return this.translate.instant(`agriness.farm_link.${key}`);
  }
}
