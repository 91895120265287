<div class="queue-manager">
  <div
    class="toast showing"
    [ngClass]="statusClass[item.status]"
    [attr.data-testid]="'status-' + item.status"
    *ngFor="let item of filterNoStatus(items)"
  >
    <ng-container *ngTemplateOutlet="toastBody; context: { $implicit: item }"></ng-container>
  </div>
  <div *ngIf="queuedItems.length" class="queue">
    <div class="toast showing" *ngFor="let queuedItem of queuedItems">
      <ng-container
        *ngTemplateOutlet="toastBody; context: { $implicit: queuedItem }"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template #toastBody let-item>
  <div class="toast-body">
    <span class="message">
      {{ item.label }}
    </span>
    <span class="status-icon icon is-20" [ngClass]="statusIconClass[item.status]"></span>
    <div class="actions">
      <button class="btn btn-ghost has-round-hover">
        <span class="icon is-20 icon-close-circle-outline has-text-grey-3"></span>
      </button>
    </div>
  </div>
</ng-template>
