import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'ag-grid-frame',
  templateUrl: './ag-grid-frame.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridFrameComponent {
  @HostBinding('style.grid-row-start') @Input() rowStart = 0;
  @HostBinding('style.grid-row-end') @Input() rowEnd = 0;
  @HostBinding('style.grid-column-start') @Input() colStart = 1;
  @HostBinding('style.grid-column-end') @Input() colEnd = 1;

  @HostBinding('style.height.%') get width() {
    return this.rowStart === this.rowEnd ? 0 : 100;
  }
  @HostBinding('style.width.%') get height() {
    return this.colStart === this.colEnd ? 0 : 100;
  }
}
