import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, SelectComponent } from '../corp-filter-abstractions';
import { FixedLoaderService, SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-last-animal-group-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.select.html',
  providers: [{ provide: FilterComponent, useExisting: CorpLastAnimalGroupFilterComponent }],
})
export class CorpLastAnimalGroupFilterComponent extends SelectComponent {
  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(translate, containerService, activeRoute);

    this.assignDefaults(
      'lastAnimalGroup',
      new SelectLoader(
        new FixedLoaderService([['1', 'agriness.filter.last_animal_group.last']], translate),
      ),
    );
    this.labelKey = 'agriness.filter.last_animal_group.label';
    this.emptySelectionKey = null;
  }

  async getInitialGroupData(): Promise<Record<string, [unknown]>> {
    return Promise.resolve({
      [this.controlName]: [this.getQueryParam(this.queryParamName) || '1'],
    });
  }
}
