import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';

import { SharedModule } from '../../shared/shared.module';
import { AnalysisRoutingModule } from './analysis-routing.module';
import { ReproductiveAnalysisComponent } from './analysis.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReproductivePerformanceAnalysisGraphComponent } from './performance-analysis/graph/reproductive-performance-analysis-graph.component';
import { PerformanceAnalysisComponent } from './performance-analysis/performance-analysis.component';
import { RecordListComponent } from './record-list/record-list.component';

@NgModule({
  imports: [
    SharedModule,
    HighchartsChartModule,
    AnalysisRoutingModule,
    DropdownModule,
    TableModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ReproductiveAnalysisComponent,
    DashboardComponent,
    RecordListComponent,
    PerformanceAnalysisComponent,
    ReproductivePerformanceAnalysisGraphComponent,
  ],
  exports: [ReproductiveAnalysisComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReproductiveAnalysisModule {}
