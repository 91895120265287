import { SiteEnum, SiteSystemType, UserStorageService } from '@agriness/services';
import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { snakeCase } from 'lodash';

import { LoaderUserPreference } from '../../shared/component/loader-user-preference';
import { MonitorTab, monitorTabs } from './monitor.model';

@Component({
  templateUrl: './monitor.component.html',
})
export class ReproductiveMonitorComponent extends LoaderUserPreference {
  stage = SiteSystemType[environment.typeProduction][SiteEnum.SITE_1];
  titleKey = `agriness.monitor_${snakeCase(this.stage)}`;

  tabs = monitorTabs[environment.typeProduction] as MonitorTab[];

  constructor(protected userStorageService: UserStorageService) {
    super(userStorageService);
  }
}
