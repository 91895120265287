<div class="flex-grow-1 d-flex align-content-stretch h-100">
  <highcharts-chart
    *ngIf="chartOptions"
    class="d-block flex-grow-1"
    data-testid="corp-performance-graph-renderer"
    (chartInstance)="registerChart($event)"
    [Highcharts]="Highcharts"
    [oneToOne]="true"
    [options]="chartOptions"
  ></highcharts-chart>
</div>
