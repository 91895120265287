import {
  BaseEntityFilter,
  PageNumberPagination,
  QueryCorp,
  ContextFilter,
} from '@agriness/services';

import { Farm } from './farm.model';

export interface Tank {
  id: string;
  name: string;
  farm: Farm;
}

export interface TankForm {
  name: string;
  farm_id: string;
}

export type TankFilter = BaseEntityFilter & PageNumberPagination & ContextFilter & QueryCorp;

export const defaultTankOrder: (keyof Tank)[] = ['name', 'id'];
