import { SectionModel, SectionService } from '@agriness/ui';
import { Component } from '@angular/core';

import { SectionAbstractComponent } from '../shared/component/section-abstract.component';
import { WeanToFinishService } from './wean-to-finish.service';

@Component({
  templateUrl: './wean-to-finish.component.html',
})
export class WeanToFinishComponent extends SectionAbstractComponent {
  constructor(
    protected sectionService: SectionService,
    protected weanToFinish: WeanToFinishService,
  ) {
    super(sectionService);
  }

  protected getSections(): SectionModel[] {
    return this.weanToFinish.getSections();
  }
}
