<div class="list-box">
  <header class="header">
    <p class="text font-weight-bold is-regular">{{ labels.title }}</p>
  </header>

  <ng-container *ngIf="items.length; else emptyList">
    <div class="body">
      <div class="form-group mb-2">
        <input
          data-testid="search"
          #filterInput
          (keyup)="filter(filterInput.value)"
          name="filter"
          class="form-control"
          type="text"
          pInputText
        />
        <label class="control-label">{{ labels.filterLabel }}</label>
        <button id="icon-search" class="btn btn-ghost form-icon">
          <span class="icon icon-search"></span>
        </button>
      </div>
      <h6
        data-testid="totalResults"
        class="title font-weight-bold text-uppercase is-xsmall mb-2 has-text-grey-4"
      >
        {{ 'agriness.dual_list_box.total_results' | translate }}: {{ filteredItems.length }}
      </h6>

      <ng-container *ngIf="filteredItems.length; else searchNoResults">
        <ng-container
          *ngIf="sortable && !filterText; then listWithDrag; else listWithoutDrag"
        ></ng-container>
      </ng-container>

      <ng-template #listWithDrag>
        <ol
          data-testid="list"
          class="list pr-2 has-moving-cards"
          cdkDropList
          (cdkDropListDropped)="onDrop($event)"
        >
          <li
            data-testid="listItem"
            class="card list-box-card"
            *ngFor="let item of filteredItems"
            (click)="onSelect([item])"
            cdkDrag
          >
            <span class="dragdrop-handle icon-dragdrop"></span>
            <h6 class="title">{{ item.label }}</h6>
            <button
              data-testid="removeButton"
              *ngIf="removable"
              class="btn btn-ghost has-round-hover"
              (click)="onRemove([item])"
            >
              <span class="icon is-16 icon-delete"></span>
            </button>
          </li>
        </ol>
      </ng-template>
      <ng-template #listWithoutDrag>
        <ol data-testid="list" class="list pr-2">
          <li
            data-testid="listItem"
            class="card list-box-card"
            *ngFor="let item of filteredItems"
            (click)="onSelect([item])"
          >
            <h6 class="title">{{ item.label }}</h6>
            <button
              data-testid="listItem"
              *ngIf="removable"
              class="btn btn-ghost has-round-hover"
              (click)="onRemove([item])"
            >
              <span class="icon is-16 icon-delete"></span>
            </button>
          </li>
        </ol>
      </ng-template>
      <ng-template #searchNoResults>
        <div
          data-testid="searchNoResults"
          class="body d-flex flex-column justify-content-center align-items-center"
        >
          <span class="icon is-63 icon-no-data mb-4 has-text-blue-5"></span>
          <p class="text font-weight-bold mb-2">
            {{ 'agriness.dual_list_box.no_results_title' | translate }}
          </p>
          <p class="text is-regular has-text-grey-4 text-center">
            {{ 'agriness.dual_list_box.no_results_description' | translate }}
          </p>
        </div>
      </ng-template>
    </div>
  </ng-container>
  <ng-template #emptyList>
    <div
      data-testid="emptyList"
      class="body d-flex flex-column justify-content-center align-items-center"
    >
      <span class="icon is-63 icon-no-data mb-4 has-text-blue-5"></span>
      <p class="text font-weight-bold mb-2">{{ labels.emptyTitle }}</p>
      <p class="text is-regular has-text-grey-4 text-center">{{ labels.emptyDescription }}</p>
    </div>
  </ng-template>

  <footer class="footer text-right">
    <button
      data-testid="selectAllButton"
      [disabled]="!filteredItems.length"
      *ngIf="selectable"
      class="btn btn-primary is-small"
      (click)="onSelect(filteredItems)"
    >
      <span>{{ 'agriness.dual_list_box.select_all' | translate }}</span>
    </button>
    <button
      data-testid="removeAllButton"
      [disabled]="!filteredItems.length"
      *ngIf="removable"
      class="btn btn-primary is-small"
      (click)="onRemove(filteredItems)"
    >
      <span>{{ 'agriness.dual_list_box.remove_all' | translate }}</span>
    </button>
  </footer>
</div>
