export enum DeathTableStatus {
  GOOD = 'GOOD',
  ATTENTION_1 = 'ATTENTION_1',
  ATTENTION_2 = 'ATTENTION_2',
  DANGER = 'DANGER',
}

export interface DeathTableColumn {
  field: string;
  header?: string;
}

export interface DeathTableRow {
  field: string;
  name: string;
}

export interface DeathTableData {
  [cause: string]: DeathTableDataCause;
}

export interface DeathTableDataCause {
  [period: string]: DeathTableCell;
  total?: DeathTableCell;
}

export interface DeathTableCell {
  value: number;
  percentage?: number;
}

export interface DeathTableFooter {
  [period: string]: DeathTableFooterCell;
  total: DeathTableFooterCell;
}

export interface DeathTableFooterCell {
  value: number;
  percentage: number;
}
