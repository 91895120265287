import { FormEntry } from '@agriness/corp-app/record/animal-group/traceability/traceability.model';
import { Search } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { DownloadFileService } from '../download-file/download-file.service';
import { DownloadStatus } from '../models/download-status.model';
import { TraceabilityDocument } from '../models/traceability-documents.model';
import { DocumentType } from './../models/document-type.model';

@Injectable({
  providedIn: 'root',
})
export class TraceabilityDocumentService {
  private basePath = 'holdings/{holding_id}/animal-groups/{animal_group}/traceability-documents/';
  private pathPublish = `${this.basePath}publish/`;
  private pathById = `${this.basePath}{id}/`;
  private pathDownloadsById = `${this.pathById}published/`;
  private pathDraft = `${this.pathById}draft/`;
  private pathPopulate = `${this.basePath}populate/{document_type}/`;

  constructor(
    private apiCorpService: ApiCorpService,
    private downloadFileService: DownloadFileService,
  ) {}

  get(
    holdingId: string,
    animalgroupId: string,
    documentId: string,
  ): Observable<TraceabilityDocument> {
    const params = { holding_id: holdingId, animal_group: animalgroupId, id: documentId };
    const url = this.apiCorpService.translateUrl(this.pathById, params);

    return this.apiCorpService.get<TraceabilityDocument>(url);
  }

  downloadDraft(
    holdingId: string,
    animalgroupId: string,
    documentId: string,
  ): Observable<DownloadStatus> {
    const params = { holding_id: holdingId, animal_group: animalgroupId, id: documentId };
    const url = this.apiCorpService.translateUrl(this.pathDraft, params);
    return this.downloadFileService.download(url, DocumentType.PDF);
  }

  list(holdingId: string, animalgroupId: string): Observable<Search<TraceabilityDocument>> {
    const params = { holding_id: holdingId, animal_group: animalgroupId };
    const url = this.apiCorpService.translateUrl(this.basePath, params);

    return this.apiCorpService.get<Search<TraceabilityDocument>>(url);
  }

  publish(
    holdingId: string,
    animalgroupId: string,
    documentData: TraceabilityDocument,
  ): Observable<TraceabilityDocument> {
    const params = { holding_id: holdingId, animal_group: animalgroupId };
    const url = this.apiCorpService.translateUrl(this.pathPublish, params);

    return this.apiCorpService.post<TraceabilityDocument>(url, documentData);
  }

  save(
    holdingId: string,
    animalgroupId: string,
    documentData: TraceabilityDocument,
    documentId?: string,
  ): Observable<TraceabilityDocument> {
    if (documentId) {
      return this.update(holdingId, animalgroupId, documentId, documentData);
    } else {
      return this.create(holdingId, animalgroupId, documentData);
    }
  }

  getHealthReportDownloadUrl(
    holdingId: string,
    animalgroupId: string,
    documentId: string,
  ): Observable<string> {
    const params = { holding_id: holdingId, animal_group: animalgroupId, id: documentId };
    const url = this.apiCorpService.translateUrl(this.pathDownloadsById, params);

    return this.apiCorpService.get<string>(url);
  }

  getDocumentDefaultData(
    holdingId: string,
    animalgroupId: string,
    documentType: string,
  ): Observable<FormEntry> {
    const params = {
      holding_id: holdingId,
      animal_group: animalgroupId,
      document_type: documentType,
    };
    const url = this.apiCorpService.translateUrl(this.pathPopulate, params);

    return this.apiCorpService.get<FormEntry>(url);
  }

  private create(
    holdingId: string,
    animalgroupId: string,
    documentData: TraceabilityDocument,
  ): Observable<TraceabilityDocument> {
    const params = { holding_id: holdingId, animal_group: animalgroupId };
    const url = this.apiCorpService.translateUrl(this.basePath, params);

    return this.apiCorpService.post<TraceabilityDocument>(url, documentData);
  }

  private update(
    holdingId: string,
    animalgroupId: string,
    documentId: string,
    documentData: TraceabilityDocument,
  ): Observable<TraceabilityDocument> {
    const params = { holding_id: holdingId, animal_group: animalgroupId, id: documentId };
    const url = this.apiCorpService.translateUrl(this.pathById, params);

    return this.apiCorpService.put(url, documentData);
  }
}
