import { ProductionParamsAbstractService } from '@agriness/corp-app/services/production-params/production-params-abstract.service';
import { AgrinessTranslateService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { castArray, trim } from 'lodash';

@Injectable({ providedIn: 'root' })
export class IndexTranslateService {
  constructor(
    private translate: AgrinessTranslateService,
    private productionParamsService: ProductionParamsAbstractService,
  ) {}

  instant(
    indexKey: string,
    customPrefix?: string | string[],
    stage?: string,
    interpolateParams?: Record<string, unknown>,
  ): string {
    const config = this.productionParamsService.getParamsTranslate();
    const stageConf = config?.[stage] as Record<string, Record<string, unknown>>;
    const translateParams = {
      ...(stageConf?.[indexKey.toLowerCase()] ?? {}),
      ...interpolateParams,
    };

    const keys = [...castArray(customPrefix), 'agriness.performances'].map(str =>
      [trim(str, '.'), indexKey].join('.'),
    );

    return this.translate.instantWithFallback(keys, translateParams);
  }
}
