import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ag-grid-container',
  templateUrl: './ag-grid-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridContainerComponent {
  @Input() rows = 16;
  @Input() cols = 16;

  style() {
    return {
      display: 'grid',
      'grid-gap': '0px',
      'grid-template': `${this.rowStyle()} / ${this.colStyle()}`,
    };
  }

  private rowStyle(): string {
    return `repeat(${this.rows}, 8px)`;
  }

  private colStyle(): string {
    return `repeat(${this.cols}, 1fr)`;
  }
}
