<div id="performance-tab">
  <div class="row">
    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="card-title d-flex align-items-center">
            {{ 'agriness.performances.flock_performance_over_time.title' | translate }}

            <button class="btn btn-ghost has-round-hover ml-2" (click)="onNextDisplayTypeClick()">
              <span class="icon" [ngClass]="nextDisplayIcon()"></span>
            </button>

            <performance-exporter
              [filename]="'agriness.performances.flock_performance_over_time.title' | translate"
            ></performance-exporter>

            <button
              *ngIf="isTable()"
              (click)="tableService.exportCSV(pTable)"
              class="btn btn-ghost has-round-hover"
            >
              <span class="icon is-16 icon-csv"></span>
            </button>

            <corp-preference-toggle-column
              #preferences
              [stage]="stage"
              [preferenceType]="preferenceType"
              buttonIconClass="icon-column-settings"
              [labelProvider]="preferenceLabelProvider"
              [hiddenItems]="hiddenColumns"
              (changes)="onPreferenceChange()"
            ></corp-preference-toggle-column>
          </div>

          <div *ngIf="!feedbackType">
            <div *ngIf="isChart()">
              <div style="display: flex; flex-direction: row-reverse;">
                <p-selectButton
                  [options]="displayOptions"
                  [(ngModel)]="selectedDisplayOption"
                  (onChange)="selectDisplayOption($event)"
                  class="text-uppercase mb-2"
                ></p-selectButton>
              </div>

              <corp-performance-graph
                #pg
                [height]="chartHeight"
                [performanceData]="performanceData"
                [graphPreferences]="graphPreferences"
              ></corp-performance-graph>
            </div>

            <ag-table
              #dt
              *ngIf="isTable()"
              [exportFilename]="performanceData.downloadFilename"
              [data]="performanceData.data"
              [frozenColumns]="frozenTableColumns"
              [columns]="scrollableTableColumns"
              [scrollable]="true"
              [scrollHeight]="chartHeight"
              (sort)="onSort($event)"
            >
              <ng-template
                pTemplate="bodycell"
                let-col="column"
                let-data="data"
                let-rowData="rowData"
              >
                <td>
                  <ag-table-cell-content
                    [data]="data"
                    [column]="col"
                    [showSymbol]="false"
                    [dateFormat]="dateFormat"
                  ></ag-table-cell-content>
                </td>
              </ng-template>
            </ag-table>
          </div>

          <ag-feedback [type]="feedbackType"></ag-feedback>
        </div>
      </div>
    </div>
  </div>
</div>
