import { ReportFilterQuery } from '@agriness/corp-app/shared/model/report-filter.model';
import { EventEmitter, FactoryProvider } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isArray, isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';

export class CorpFiltersContainerService {
  static provider: FactoryProvider = {
    provide: CorpFiltersContainerService,
    deps: [ActivatedRoute, Router],
    useFactory: (activatedRoute: ActivatedRoute, router: Router) =>
      new CorpFiltersContainerService(activatedRoute, router),
  };

  formGroup = new BehaviorSubject<FormGroup>(null);

  /**
   * This emitter should be invoked when a filter
   * component requests the container to apply a filter
   */
  requestFilter = new EventEmitter();
  onResetFilter = new EventEmitter();

  subtitles = new BehaviorSubject<[string, string][] | null>(null);

  /**
   * This emitter can be used by the children to request
   * the parent for a subtitle recollection.
   */
  requestSubtitleCollection = new BehaviorSubject<void>(null);

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  /**
   * Register the given queryFormat object in the current route
   * replacing its history
   *
   * Note: this function only register entries that are not empty
   */
  registerQueryOnRoute(queryFormat: ReportFilterQuery): Promise<boolean> {
    const withoutEmptyEntries = Object.entries(queryFormat).reduce(
      (registry, [key, value]: [string, string | number]) => {
        const empty = (isArray(value) || typeof value === 'string') && isEmpty(value);

        return {
          ...registry,
          [key]: empty ? null : value,
        };
      },
      {},
    );

    return this.router
      .navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: withoutEmptyEntries,
        replaceUrl: true,
        // We assume that the system may be using additional query params
        // so for the sake of safety, we're using the merge strategy
        queryParamsHandling: 'merge',
      })
      .catch(error => {
        console.log(error);

        return false;
      });
  }
}
