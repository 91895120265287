import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { SharedModule } from '../../common/shared.module';
import { AgMultiselectComponent } from './ag-multiselect.component';

@NgModule({
  imports: [SharedModule, ReactiveFormsModule, FormsModule, NgSelectModule],
  declarations: [AgMultiselectComponent],
  exports: [AgMultiselectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgMultiselectModule {}
