import { commonDeletionTableFields } from '@agriness/corp-app/settings/deletion-table/deletion-table.model';
import { textColumn, actionColumn } from '@agriness/ui';

const regionsKey = 'agriness.settings.regions.table';

export const regionsTableColumns = [
  {
    ...actionColumn,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'name',
    header: `${regionsKey}.name`,
    width: '500px',
    sortable: true,
    fixed: true,
  },
  {
    ...textColumn,
    field: 'farm_count',
    header: `${regionsKey}.bond`,
    width: '500px',
    sortable: true,
    fixed: true,
  },
];

export const regionsDeletionTableFields: string[] = [...commonDeletionTableFields];
