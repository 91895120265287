import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, SelectComponent } from '../corp-filter-abstractions';
import { FixedLoaderService, SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-target-group-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.select.html',
  providers: [{ provide: FilterComponent, useExisting: CorpTargetGroupFilterComponent }],
})
export class CorpTargetGroupFilterComponent extends SelectComponent {
  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(translate, containerService, activeRoute);
    this.assignDefaults(
      'group',
      new SelectLoader(
        new FixedLoaderService(
          [
            ['', 'agriness.filter.group.all'],
            ['PRODUCTION', 'agriness.filter.group.production'],
            ['NUTRITION', 'agriness.filter.group.nutrition'],
            ['AMBIENCE', 'agriness.filter.group.ambience'],
            ['SANITY', 'agriness.filter.group.sanity'],
          ],
          translate,
        ),
      ),
    );
  }
}
