import { Search } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { KPIList } from '..';
import { KPIListFilter } from '../models/bonification.model';

@Injectable({
  providedIn: 'root',
})
export abstract class KPIListAbstractService {
  abstract list(farmFilter: KPIListFilter): Observable<Search<KPIList>>;
}
