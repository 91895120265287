import { CorpFiltersContainerService } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.service';
import { PeriodLabels } from '@agriness/corp-app/shared/component/corp-filters/inputs/corp-period-filter.component';
import {
  AnimalGroupStatus,
  StageEnum,
  TypeProductionService,
  UserStorageService,
} from '@agriness/services';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {
  ReportFilterPeriodDefaultValue,
  ReportFilterQuery,
  ReportFilterType,
  PeriodFilter,
  ReportFilterTypeDefinition,
} from '../../model/report-filter.model';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { CorpFiltersContainerComponent } from '../corp-filters/corp-filters-container.component';
import { LoaderUserPreference } from '../loader-user-preference';
import { superHoldingFilters } from './corp-report-filters.model';

@Component({
  selector: 'corp-report-filters',
  styleUrls: ['./corp-report-filters.component.scss'],
  templateUrl: './corp-report-filters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CorpFiltersContainerService.provider],
})
export class CorpReportFiltersComponent extends LoaderUserPreference implements OnInit {
  @ViewChild('filtersContainer', { static: true }) container: CorpFiltersContainerComponent;

  @Input() availableFilters: ReportFilterType[] = [];
  @Input() periodDefaultValue: ReportFilterPeriodDefaultValue;
  @Input() hasMaxDate = true;
  @Input() stage: StageEnum;
  @Input() periodLabels: PeriodLabels;
  @Input() animalGroupStatus: AnimalGroupStatus;

  @Output() filter = new EventEmitter<ReportFilterQuery>();
  @Output() ready = new EventEmitter<void>();
  @Output() valid = new EventEmitter<boolean>();

  isSuperHolding: boolean;
  visibleFilters: ReportFilterTypeDefinition[];
  limitedPeriod?: number;

  constructor(
    private typeProductionService: TypeProductionService,
    private featureToggleService: FeatureToggleService,
    protected userStorageService: UserStorageService,
  ) {
    super(userStorageService);
  }

  get showFilterButton(): boolean {
    return !!this.filter.observers.length;
  }

  ngOnInit(): void {
    this.isSuperHolding = this.userStorageService.isSuperHolding();

    const normalized = this.normalizeFilter(this.availableFilters || []);
    this.visibleFilters = this.getAvailableFilters(normalized);

    if (
      !this.featureToggleService.isEnabled('filter.nutrition_type') &&
      this.visibleFilters.some(a => a.type === 'NUTRITION_TYPE')
    ) {
      this.visibleFilters.splice(
        this.visibleFilters.findIndex(a => a.type === 'NUTRITION_TYPE'),
        1,
      );
    }

    const periodFilter = this.visibleFilters.find(
      (filter): filter is PeriodFilter => filter.type === 'PERIOD',
    );

    if (periodFilter) {
      this.limitedPeriod = periodFilter.rangeLimit;
    }
  }

  onFilter(): void {
    this.filter.emit(this.getCurrentFilterInQueryFormat());
  }

  getCurrentFilterInQueryFormat(): ReportFilterQuery {
    return this.container.getCurrentFilterInQueryFormat();
  }

  resetFilterForm(): void {
    void this.container?.resetFilterForm();
  }

  private normalizeFilter(filters: ReportFilterType[]): ReportFilterTypeDefinition[] {
    return filters.map(filter => {
      if (typeof filter === 'string') {
        return { type: filter };
      }

      return filter;
    });
  }

  private getAvailableFilters(filters: ReportFilterTypeDefinition[]) {
    if (this.typeProductionService.isLayers && !this.isSuperHolding) {
      return filters.filter(filter => {
        if (typeof filter === 'string') {
          return !superHoldingFilters.includes(filter);
        }

        return !superHoldingFilters.includes(filter.type);
      });
    }

    return filters;
  }
}
