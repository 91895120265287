import { HealthReportDocumentStatus } from '@agriness/corp-app/shared/component/corp-animal-group-health-report/corp-animal-group-health-report.model';

export interface TraceabilityDocument {
  id?: string;
  document_type: TraceabilityDocumentTypeEnum;
  status?: HealthReportDocumentStatus;
  data: Record<string, unknown>;
}

export enum TraceabilityDocumentTypeEnum {
  SWINE_BRA_HEALTH_REPORT = 'SWINE_BRA_HEALTH_REPORT',
  PAYMENT_REPORT = 'ANIMAL_GROUP_PAYMENT_REPORT',
}
