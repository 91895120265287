import {
  BaseEntityFilter,
  PageNumberPagination,
  ContextFilter,
  QueryCorp,
} from '@agriness/services';

export interface Technician {
  id: string;
  name: string;
  farm_count: number;
  animalgroup_count: number;
  user_id?: string;
}

export interface TechnicianForm {
  name: string;
}

export interface FarmBond {
  has_farm?: boolean;
}

export const defaultTechnicianOrder: (keyof Technician)[] = ['name'];

export type TechnicianFilter = BaseEntityFilter &
  PageNumberPagination &
  ContextFilter &
  QueryCorp &
  FarmBond;

export enum ModalStatusEnum {
  FAILED = 'FAILED',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  DEFAULT = 'DEFAULT',
}
