import { TranslateInstant, Search } from '@agriness/services';
import { defer } from 'lodash';
import { BehaviorSubject, EMPTY, from, Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap, first } from 'rxjs/operators';

import { LocalStorageService } from '../../services/local-storage.service';

export type IdName = { id: string | number; name?: string };

export type SelectSearchQuery = {
  id?: string[];
  name?: string;
  page: number;
};

export type SelectLoaderService<T> = {
  list(args: unknown): Observable<Search<T>>;
};

export class FixedLoaderService implements SelectLoaderService<IdName> {
  private readonly items: IdName[];

  constructor(items: Array<[string, string]>, translate?: TranslateInstant) {
    this.items = items.map(([id, name]) => ({ id, name: translate ? translate(name) : name }));
  }

  list(): Observable<Search<IdName>> {
    return from([{ results: this.items }]);
  }
}

export class SelectLoader<T> {
  options: BehaviorSubject<T[]> = new BehaviorSubject([] as T[]);
  loadAll = false;
  filterSubject: BehaviorSubject<SelectSearchQuery>;
  loading = false;
  localStorageService = new LocalStorageService();

  lastCount = Infinity;
  private service: SelectLoaderService<T>;
  private additionalParams: Record<string, unknown>;

  constructor(service: SelectLoaderService<T>, additionalParams: Record<string, unknown> = {}) {
    this.service = service;
    this.additionalParams = additionalParams;
    this.filterSubject = new BehaviorSubject({
      name: null,
      page: 1,
    });
  }

  loadInitialValues(id: string[]): Observable<Search<T>> {
    if (id == null || id.length === 0) {
      return of({});
    }

    return this.service.list({ id, page_size: id.length, ...this.additionalParams }).pipe(
      first(),
      tap(({ results }) => {
        const currentList = this.options.getValue();
        this.options.next(results);
        if (currentList.length) {
          // Defer utilizado pois com os 2 next em sequencia não da a oportunidade do select capturar o valor
          defer(() => this.options.next(currentList));
        }
      }),
      catchError(() => EMPTY),
    );
  }

  loadOnFilter(): Observable<Search<T>> {
    return this.filterSubject.pipe(
      filter(({ page }) => page === 1 || (page - 1) * 10 < this.lastCount),
      tap(() => (this.loading = true)),
      switchMap(searchQuery => this.loadPage(searchQuery)),
      tap(() => (this.loading = false)),
    );
  }

  filter(value: string): void {
    delete this.additionalParams.page_size;

    this.filterSubject.next({
      name: value,
      page: 1,
    });

    this.loadAll = false;
  }

  paginate(): void {
    delete this.additionalParams.page_size;

    this.filterSubject.next({
      name: this.filterSubject.getValue().name,
      page: this.filterSubject.getValue().page + 1,
    });

    this.loadAll = false;
  }

  selectAll(): void {
    this.additionalParams.page_size = this.lastCount;

    this.filterSubject.next({
      name: this.filterSubject.getValue().name,
      page: 1,
    });

    this.loadAll = true;
  }

  private handleLocalCache(type: string) {
    const localCache = this.localStorageService.getItem(type);

    const today = new Date();
    const { items, lastUpdate } = localCache;

    if (new Date(lastUpdate).getDate() !== today.getDate()) {
      return null;
    }

    return items;
  }

  private checkIfAllIsLoaded(type: string): boolean {
    const items = this.handleLocalCache(type);
    if (items) {
      this.lastCount = items.length;
      this.options.next([...items] as T[]);
      return true;
    }

    return false;
  }

  private loadPage(searchQuery: SelectSearchQuery): Observable<Search<T>> {
    const { name, page } = searchQuery;
    // const base = String(this.service['pathBase']);
    // const type = String(base ? base.split('/')[2] : '');
    // if (type === 'producers') {
    //   if (this.checkIfAllIsLoaded(type)) {
    //     return of({});
    //   }
    // }

    return this.service.list({ name, page, ...this.additionalParams }).pipe(
      tap(({ count, results }) => {
        this.lastCount = count;
        const currentList = page === 1 ? [] : this.options.getValue();

        this.options.next([...currentList, ...results]);
        this.loading = false;
      }),
      // tap(() => {
      //   if (type === 'producers') {
      //     this.localStorageService.setItem(type, this.options.getValue());
      //   }
      // }),
      catchError(() => {
        this.loading = false;
        return EMPTY;
      }),
    );
  }
}
