import { Search, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { Strain, StrainFilter } from '../models/strain.model';

@Injectable()
export class StrainService {
  private pathBase = 'holdings/{holding_id}/strains/';
  private defaultPageSize = 10;

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
  ) {}

  list(params: StrainFilter): Observable<Search<Strain>> {
    const url = this.apiCorpService.translateUrl(this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
    });
    return this.apiCorpService.get<Search<Strain>>(url, {
      ...params,
      page_size: this.defaultPageSize,
    });
  }
}
