<div
  *ngIf="filterForm && loader"
  [hidden]="hidden"
  class="input-content"
  [ngStyle]="{ width: inputWidth }"
>
  <div class="form-group" id="filter-{{ kebabCase(controlName) }}-form-group">
    <label class="control-label">
      {{ labelKey | translate }}
    </label>
    <ag-multiselect
      [group]="filterForm"
      [controlName]="controlName"
      [items$]="loader.options"
      (searchChange)="loader.filter($event)"
      (scrollToEnd)="loader.paginate()"
      [lastCount]="loader.lastCount"
      (selectAll)="loader.selectAll()"
      [loadAll]="loader.loadAll"
      [loading]="loader.loading"
      [msgItemsSelects]="multiSelectedKey"
      [defaultLabel]="allSelectedKey"
      [isFarmFilter]="farmFilter"
      [subtitlesCache]="subtitlesCache"
      [dataKey]="dataKey || 'id'"
    ></ag-multiselect>
  </div>
</div>
