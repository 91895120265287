import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SearchFarmHoldingRelations } from '../../../models/farm.model';
import { ApiMyAccountService } from '../../common/api-myaccount.service';

@Injectable()
export class FarmService {
  private pathSearchFarm = 'farms/';

  constructor(private apiMyAccountService: ApiMyAccountService) {}

  getHoldingRelations(farm_id: string): Observable<SearchFarmHoldingRelations> {
    const holdingRelationPath = `${this.pathSearchFarm}{farm_id}/holding-relations/`;
    const url = this.apiMyAccountService.translateUrl(holdingRelationPath, {
      farm_id,
    });
    return this.apiMyAccountService.get<SearchFarmHoldingRelations>(url);
  }
}
