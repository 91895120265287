import { deprecatedColors } from '@agriness/assets/deprecated';
import { AnimalGroupDailyData, TypeViewPreference } from '@agriness/corp-app/services';
import { RowData } from '@agriness/corp-app/shared/model/table.model';
import {
  HighchartsService,
  HighchartsOptions,
  Highcharts,
} from '@agriness/corp-app/shared/services/highcharts.service';
import { AgrinessTranslateService, TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { DateService } from '@agriness/services';
import { TableColumn } from '@agriness/ui';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  OnChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Chart } from 'highcharts';

import { AnimalGroupService } from '../../animal-group.service';
import { ConsumptionData, ConsumptionDataEnum } from './consumption-graph.model';

@Component({
  selector: 'consumption-graph',
  templateUrl: './consumption-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsumptionGraphComponent implements OnInit, OnChanges {
  @Input() icon = 'icon-report1';
  @Input() parentAsCard = true;
  @Input() dataChart: AnimalGroupDailyData;
  @ViewChild('content', { static: true }) content: TemplateRef<unknown>;

  Highcharts: Highcharts;
  chart: Chart;
  chartOptions: HighchartsOptions;

  frozenTableColumns: TableColumn[] = [];
  scrollableTableColumns: TableColumn[] = [];
  tableData: RowData<unknown>[];
  tableRowsKeys: Array<ConsumptionDataEnum> = Object.values(ConsumptionDataEnum);

  chartDecimalPlaces = 2;
  defaultTableWidth = '140px';

  dataTable: ConsumptionData;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private changeDetectionRef: ChangeDetectorRef,
    private translateService: AgrinessTranslateService,
    private highchartsService: HighchartsService,
    private animalGroupService: AnimalGroupService,
    private dataService: DateService,
  ) {
    this.t = this.translateService.instantWithFallback;
    this.Highcharts = highchartsService.getHighchartsInstance();
  }

  ngOnInit(): void {
    this.chartOptions = this.getChartOptions();
    this.loadColumns();
    this.loadTableData();
    this.addVerticalHeader();
  }

  ngOnChanges(): void {
    this.chartOptions = this.getChartOptions();
    this.changeDetectionRef.detectChanges();
  }

  registerChart(chart: Chart): void {
    this.chart = chart;
  }

  private createNewStructureForGraphSeries(): ConsumptionData {
    const dailyConsumption = (this.dataChart?.daily || []).reduce<ConsumptionData>(
      (acc, current) => {
        const beginDate = this.dataService.formatAsMonthAndDay(current.begin_date);
        const endDate = this.dataService.formatAsMonthAndDay(current.end_date);

        return {
          average_daily_consumption: [
            ...acc.average_daily_consumption,
            current.average_daily_consumption,
          ],
          average_daily_consumption_target: [
            ...acc.average_daily_consumption_target,
            current.average_daily_consumption_target,
          ],
          difference: [...acc.difference, current.difference],
          difference_percentage: [...acc.difference_percentage, current.difference_percentage],
          nutrition_type: [...acc.nutrition_type, current.nutrition_type],
          date: [
            ...acc.date,
            this.t('agriness.nutrition.feed_by_load.chart.date_range', {
              begin_date: beginDate,
              end_date: endDate,
            }),
          ],
        };
      },
      {
        average_daily_consumption: [],
        average_daily_consumption_target: [],
        difference: [],
        difference_percentage: [],
        date: [],
        nutrition_type: [],
      },
    );

    return dailyConsumption;
  }

  private getChartOptions(): HighchartsOptions {
    this.dataTable = this.createNewStructureForGraphSeries();
    this.highchartsService.setSvgSymbolsToCustomRectangle();

    return {
      chart: { type: 'column' },
      colors: [deprecatedColors.purple3, deprecatedColors.blue5],
      legend: { enabled: true, verticalAlign: 'top', align: 'left' },
      tooltip: {
        shared: true,
        valueDecimals: this.chartDecimalPlaces,
      },
      navigation: { buttonOptions: { enabled: false } },
      title: { text: '' },
      xAxis: {
        categories: this.dataTable.date,
      },
      yAxis: { title: { text: '' } },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: 'column',
          name: this.t('agriness.nutrition.feed_by_load.chart.average_daily_consumption'),
          data: this.dataTable.average_daily_consumption,
          marker: {
            enabled: true,
            symbol: undefined,
          },
          dataLabels: {
            borderWidth: 10,
          },
          maxPointWidth: 31,
        },
        {
          type: 'spline',
          name: this.t('agriness.nutrition.feed_by_load.chart.average_daily_consumption_target'),
          data: this.dataTable.average_daily_consumption_target,
          lineWidth: 0,
          borderColor: deprecatedColors.white,
          states: {
            hover: {
              lineWidthPlus: 0,
            },
          },
          marker: {
            enabled: true,
            symbol: 'rectangle',
            fillColor: deprecatedColors.white,
            lineColor: deprecatedColors.blue5,
            lineWidth: 5,
            radius: 15,
          },
        },
      ],
    };
  }

  private loadTableData() {
    this.tableData = this.tableRowsKeys.map(kpi =>
      this.scrollableTableColumns.reduce((obj, column, index) => {
        obj[column.field] = {
          value: this.dataTable[kpi][index],
        };
        return obj;
      }, {}),
    );
  }

  private addVerticalHeader() {
    const key = 'name';

    this.tableData = this.tableData.map((row, index) => ({
      ...row,
      [key]: {
        value: this.t(`agriness.nutrition.feed_by_load.table.${this.tableRowsKeys[index]}`),
      },
    }));

    this.frozenTableColumns = [
      {
        field: key,
        header: this.t('agriness.nutrition.feed_by_load.table.date'),
        width: this.defaultTableWidth,
      },
    ];
  }

  private getColumns() {
    return this.dataTable.date
      .map((item, index) => `col-${index}`)
      .map(item => new TypeViewPreference(item));
  }

  private loadColumns() {
    this.scrollableTableColumns = this.getColumns().map((preference, index) => {
      return {
        field: preference.index_name,
        header: this.dataTable.date[index],
        width: this.defaultTableWidth,
        sortable: true,
      };
    });
  }
}
