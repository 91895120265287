import { FarmAbstractService } from '@agriness/corp-app/services';
import { TRANSLATE_INSTANT, TranslateInstant, StageEnum } from '@agriness/services';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FilterComponent, MultiSelectComponent } from '../corp-filter-abstractions';
import { SelectLoader } from '../corp-filter.service';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

@Component({
  selector: 'corp-farm-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './filter.multiselect.html',
  providers: [{ provide: FilterComponent, useExisting: CorpFarmFilterComponent }],
})
export class CorpFarmFilterComponent extends MultiSelectComponent implements OnInit {
  @Input() stage: StageEnum;

  constructor(
    @Inject(TRANSLATE_INSTANT) translate: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
    private farmService: FarmAbstractService,
  ) {
    super(translate, containerService, activeRoute);
  }

  ngOnInit(): void {
    this.assignDefaults('farm', new SelectLoader(this.farmService, { stage: this.stage }));
    super.ngOnInit();
  }
}
