import { deprecatedColors } from '@agriness/assets/deprecated';
import {
  DisplayOption,
  PerformanceData,
} from '@agriness/corp-app/record/animal-group/performance/performance.model';
import {
  MeasurementUnitEnum,
  PerformanceItem,
  PerformanceItems,
  PerformanceType,
  TypeViewPreference,
} from '@agriness/corp-app/services';
import {
  ChartResizeService,
  chartResizeServiceProvider,
} from '@agriness/corp-app/services/chart-resize/chart-resize.service';
import {
  DefaultTickIntervals,
  GraphSeriesType,
  TypeViewPreferenceWithColor,
  ValueFormat,
} from '@agriness/corp-app/shared/component/corp-performance-graph/corp-performance-graph.model';
import { AgrinessTranslateService, DateService } from '@agriness/services';
import { AgFeedbackService, ValueFormatPipe } from '@agriness/ui';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { getDay, parseISO } from 'date-fns';
import { Chart, DashStyleValue, SeriesOptionsType } from 'highcharts';
import { partition } from 'lodash';

import { Highcharts, HighchartsService } from '../../services/highcharts.service';
import { CorpPerformanceGraphService } from './corp-performance-graph.service';

@Component({
  selector: 'corp-performance-graph',
  templateUrl: './corp-performance-graph.component.html',
  providers: [chartResizeServiceProvider, CorpPerformanceGraphService],
})
export class CorpPerformanceGraphComponent implements OnChanges, OnDestroy {
  /**
   * Series exhibition type, this dictates
   * how the graph will render the series data,
   * defaults to line
   */
  @Input()
  seriesType: GraphSeriesType = 'line';

  /**
   * When the [displayOption] is BY_DATE
   * this property will be used to specify
   * the ticks interval, defaults to one week
   *
   * Note: this is not exactly respected, highcharts
   * will try to fit the interval in the nearest unit
   * specified.
   * see: https://api.highcharts.com/highcharts/xAxis.units
   */
  @Input()
  byDateTicksInterval?: number = DefaultTickIntervals.ONE_WEEK;

  @Input()
  performanceData: PerformanceData;

  @Input()
  min: { x?: number; y?: number };

  @Input()
  max: { x?: number; y?: number };

  @Input()
  height: string;

  @Input()
  graphPreferences: TypeViewPreference[];

  @Input()
  useDefaultLegend: boolean;

  @Output()
  chartInstance = new EventEmitter<Chart>();

  Highcharts: Highcharts;
  chartOptions: Highcharts.Options;
  chart: Chart;
  hasUpdated: boolean;
  parents: TypeViewPreferenceWithColor[];
  children: TypeViewPreferenceWithColor[];

  private readonly graphColors: string[];
  private readonly targetColor = deprecatedColors.blue5;
  private series: Highcharts.SeriesOptionsType[];
  private excludePreferences = new Set(['date', 'current_age', 'month']);
  private ignoredMarkerKeys = new Set(['target']);
  private defaultSeriesValues = {
    percentage: { yAxis: 0, tooltip: { valueSuffix: '%' } },
    numeric: { yAxis: 1 },
  };

  constructor(
    public chartResizer: ChartResizeService,
    private translate: AgrinessTranslateService,
    private feedbackService: AgFeedbackService,
    private valueFormat: ValueFormatPipe,
    private highchartsService: HighchartsService,
    private dateService: DateService,
    private performanceGraphService: CorpPerformanceGraphService,
  ) {
    this.graphColors = this.performanceGraphService.getGraphColors();
    this.Highcharts = highchartsService.getHighchartsInstance();
  }

  ngOnChanges(): void {
    this.updateChartOptions();
  }

  ngOnDestroy(): void {
    this.chartResizer.destroy();
  }

  changeSelectedSeries(event: SeriesOptionsType[]): void {
    this.chartOptions.series = event;
    if (this.chart) {
      this.chart.options.series = event;
    }
    this.hasUpdated = true;
  }

  registerChart(chart: Chart): void {
    this.chartResizer.registerChartInstance(chart);
    this.chart = chart;
    this.chartInstance.emit(chart);
  }

  private updateChartOptions(): void {
    this.chartOptions = this.getChartOptions();
    this.hasUpdated = true;
  }

  private getChartOptions(): Highcharts.Options {
    const formatter = this.performanceGraphService.makeStandardFormatter(
      this.performanceData.displayOption,
    );
    this.series = this.getSeries();

    return this.performanceGraphService.addDefaultChartOptions({
      xAxis: this.getXAxisByDisplayOption(),
      yAxis: [
        {
          title: { text: '' },
          labels: { format: '{value}%' },
        },
        { title: { text: '' }, opposite: true, min: this.min?.y, max: this.max?.y },
      ],
      tooltip: {
        formatter: function () {
          return formatter(this);
        },
      },
      series: this.series,
    });
  }

  private getSeries(): Highcharts.SeriesOptionsType[] {
    const { data } = this.performanceData;
    const values = this.getValues(data);

    return [
      ...this.getSeriesByYAxis(data, 'numeric', values),
      ...this.getSeriesByYAxis(data, 'percentage', values),
    ];
  }

  private getValues(chartData: PerformanceItems): string[] {
    const keys = Array.from(new Set(chartData.flatMap(dataItem => Object.keys(dataItem))));
    return keys.filter(key => !this.excludePreferences.has(key));
  }

  private getSeriesByYAxis(
    chartData: PerformanceItems,
    yAxis: ValueFormat,
    values: string[],
  ): Highcharts.SeriesOptionsType[] {
    if (this.graphPreferences) {
      values = this.getValuesByPreferences(values);
      this.splitPreferencesAndAddColor();
    }

    const valuesByYAxis = this.getValuesByYAxis(chartData, yAxis, values.sort());

    return valuesByYAxis.map<Highcharts.SeriesOptionsType>(value => {
      const translate = this.translate.instantWithFallback(`agriness.performances.${value}`);
      const name = translate === `agriness.performances.${value}` ? value : translate;

      return {
        ...this.defaultSeriesValues[yAxis],
        name,
        type: this.seriesType,
        color: this.getColor(values, value),
        dashStyle: this.getDashStyle(chartData, value),
        data: this.getSeriesData(chartData, value, valuesByYAxis),
      };
    });
  }

  private splitPreferencesAndAddColor(): void {
    const graphPreferences = this.graphPreferences.filter(
      ({ index_name }) => !this.excludePreferences.has(index_name.toLowerCase()),
    );

    const [parents, children]: Array<TypeViewPreferenceWithColor[]> = partition(
      graphPreferences,
      this.isParent.bind(this),
    );

    this.parents = parents.map((p, i) => ({ ...p, color: this.getColorByIndex(i) }));

    this.children = children.map(c => {
      const { color } = this.parents.find(({ index_name }) => index_name === c.parent);
      return { ...c, color };
    });
  }

  private isParent(preference: TypeViewPreference): boolean {
    return !preference.parent;
  }

  private getValuesByPreferences(values: string[]): string[] {
    return this.graphPreferences
      .map(p => p.index_name.toLowerCase())
      .filter(value => values.includes(value));
  }

  private getValuesByYAxis(
    chartData: PerformanceItems,
    yAxis: ValueFormat,
    values: string[],
  ): string[] {
    return values.filter(value => this.isPercentage(chartData, value) === (yAxis === 'percentage'));
  }

  private isPercentage(chartData: PerformanceItems, value: string): boolean {
    return chartData.some(data => data[value]?.measurement_unit === MeasurementUnitEnum.PERCENTAGE);
  }

  private getColor(values: string[], value: string): string {
    return this.graphPreferences
      ? this.getColorByPreference(value)
      : this.getColorByValues(values, value);
  }

  private getColorByPreference(value: string): string {
    return [...this.parents, ...this.children].find(
      ({ index_name }) => index_name.toLowerCase() === value,
    ).color;
  }

  private getColorByValues(values: string[], value: string): string {
    if (value === 'target') {
      return this.targetColor;
    }
    const position = values.indexOf(value);
    return this.getColorByIndex(position);
  }

  private getColorByIndex(index: number): string {
    return this.graphColors[index % this.graphColors.length];
  }

  private getDashStyle(chartData: PerformanceItems, value: string): DashStyleValue {
    if (this.is('goal', chartData, value)) {
      return 'Dash';
    }

    if (this.is('target', chartData, value)) {
      return 'ShortDash';
    }

    return 'Solid';
  }

  private is(type: PerformanceType, chartData: PerformanceItems, value: string) {
    return chartData.some(data => data[value]?.type === type);
  }

  private getSeriesData(
    chartData: PerformanceItems,
    key: string,
    valuesByYAxis: string[],
  ): Highcharts.PointOptionsObject[] {
    const xAxisDisplayOption = this.getXAxisByDisplayOption();

    return chartData.map<Highcharts.PointOptionsObject>(data => {
      const xAxisPos = data.current_age?.value as number;
      const hasMarker = this.hasMarker(
        key,
        valuesByYAxis,
        xAxisDisplayOption.tickInterval,
        xAxisPos,
      );
      return {
        x: this.getXAxisValue(data),
        y: this.performanceGraphService.parseYValue(data[key]),
        dataLabels: { enabled: hasMarker },
        marker: { enabled: hasMarker },
      };
    });
  }

  private hasMarker(
    key: string,
    valuesByYAxis: string[],
    currentTickInterval: number,
    xAxisPos: number,
  ) {
    const filteredValuesByYAxis = valuesByYAxis.filter(v => !this.ignoredMarkerKeys.has(v));

    const hasMultipleLines = filteredValuesByYAxis.length > 1;
    const isIgnored = this.ignoredMarkerKeys.has(key);
    const isDisplayByDate = currentTickInterval >= DefaultTickIntervals.ONE_WEEK;
    const isSeriesDataOnTick = Number.isInteger(xAxisPos / currentTickInterval);

    return !isIgnored && !hasMultipleLines && (isDisplayByDate || isSeriesDataOnTick);
  }

  private getXAxisValue(data: PerformanceItem): number {
    if (this.performanceData.displayOption === DisplayOption.BY_DATE) {
      const date = parseISO(data.date.value as string);

      return date.getTime();
    }

    if (this.performanceData.displayOption === DisplayOption.BY_MONTH) {
      return Number(data.month.value) - 1;
    }

    return data.current_age.value as number;
  }

  private getXAxisByDisplayOption(): Highcharts.XAxisOptions {
    const [performance] = this.performanceData.data;
    const startWeekNumber = performance?.date?.value
      ? getDay(parseISO(performance.date.value as string))
      : 0;

    const defaults: Highcharts.XAxisOptions = {
      title: {
        text: this.translate.instantWithFallback(
          this.performanceGraphService.getXAxisLabel(this.performanceData.displayOption),
        ),
      },
      crosshair: true,
      tickInterval: 1,
      min: this.min?.x,
      max: this.max?.x,
    };
    const linear: Highcharts.XAxisOptions = {
      ...defaults,
      type: 'linear',
    };

    const xAxisByDisplayOption: Record<DisplayOption, Highcharts.XAxisOptions> = {
      [DisplayOption.BY_AGE]: linear,
      [DisplayOption.BY_DATE]: {
        ...defaults,
        type: 'datetime',
        units: [
          ['week', [1]],
          ['month', [1]],
        ],
        tickInterval: this.byDateTicksInterval,
        tickmarkPlacement: 'on',
        startOfWeek: startWeekNumber,
        // we never expect a empty interval at the graph start, so don't set this
        // to true.
        startOnTick: false,
      },
      [DisplayOption.BY_MONTH]: {
        ...linear,
        categories: this.Highcharts.getOptions().lang.shortMonths,
      },
      [DisplayOption.WEEKS_5]: { ...linear, tickInterval: 5 },
      [DisplayOption.WEEKS_10]: { ...linear, tickInterval: 10 },
    };

    return xAxisByDisplayOption[this.performanceData.displayOption];
  }
}
