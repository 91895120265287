import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ag-autocomplete',
  templateUrl: './ag-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgAutocompleteComponent {
  @Input() model;
  @Input() suggestions;
  @Input() forceSelection = false;
  @Input() multiple = false;
  @Input() placeholder: string;
  @Input() emptyMessage = 'agriness.no_registry';
  @Input() showEmptyMessageOnEmptySearch = false;
  @Input() inputId;
  @Input() field;
  @Input() templateItem: any;
  @Output() completeMethod = new EventEmitter<any>();
  @Output() select = new EventEmitter<any>();
  @Output() unselect = new EventEmitter<any>();
  /**
   * @deprecated use {@link blur}
   */
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onBlur = new EventEmitter<any>();
  @Output() blur = new EventEmitter<any>();

  private lastSearch: string;

  constructor(private analyticsService: AmplitudeAnalyticsService) {}

  search($event) {
    this.lastSearch = $event.query;
    this.completeMethod.emit($event);
  }

  onClear() {
    this.search({ query: null });
  }

  selectItem($event) {
    this.analyticsService.logRecord();
    this.select.emit($event);
  }

  unselectItem($event) {
    this.unselect.emit($event);
  }

  onFocusOut($event) {
    this.onBlur.emit($event);
    this.blur.emit($event);
  }

  getEmptyMessage() {
    const canShowMessage = this.lastSearch || this.showEmptyMessageOnEmptySearch;
    return canShowMessage ? this.emptyMessage : null;
  }
}
