import { PerformanceItems } from '@agriness/corp-app/services';
import {
  DateService,
  StageEnum,
  TypeProductionEnum,
  TypeProductionService,
} from '@agriness/services';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiCorpService } from '../api-corp.service';
import { DownloadFileService } from '../download-file/download-file.service';
import {
  AnimalGroupListDownloadArgs,
  QuadrantAnimalGroup,
  QueryParams,
  SearchAnimalGroupList,
  SearchFilterAnimalGroupList,
  SearchFilterQuadrantAnimalGroup,
} from '../models/animal-group-list.model';
import { AnimalGroupNutritionFeed } from '../models/animal-group-nutrition.models';
import {
  AnimalGroupPerformance,
  AnimalGroupResume,
} from '../models/animal-group-performance.model';
import {
  AnimalGroupMortality,
  AnimalGroupMortalityPoultry,
  AnimalGroupMortalitySwines,
  ConsumptionList,
} from '../models/animal-group-sanity.model';
import {
  AnimalGroup,
  AnimalGroupDailyData,
  AnimalGroupForm,
  AnimalGroupRecord,
  AnimalGroupRecordDetail,
  ResultAnimalGroupFarm,
  SearchAnimalGroupFarm,
} from '../models/animal-group.model';
import { DocumentType } from '../models/document-type.model';
import { DownloadStatus } from '../models/download-status.model';
import { MovementGraph } from '../models/movement-graph.model';
import { MovementTable } from '../models/movement-table.model';
import { WaterFlowTableItem } from '../models/water-flow-table.model';
import { WeightTableItem } from '../models/weight-table.model';
import { AnimalGroupAbstractService } from './animal-group-abstract.service';

@Injectable()
export class AnimalGroupService extends AnimalGroupAbstractService {
  private pathBase = 'holdings/{holding_id}';
  private pathReports = `${this.pathBase}/farms/{farm_id}/{type_production}/reports`;
  private pathSearchAnimalGroupFarm = `${this.pathBase}/animal-groups-search/{type_production}/`;
  private pathAnimalGroupList = `${this.pathBase}/{type_production}/{stage}/reports/animal-group-list/`;
  private pathAnimalGroupReport = `${this.pathReports}/animal-group-record/{id}/`;
  private pathAnimalGroupPerformance = `${this.pathReports}/animal-group-performance/{id}/`;
  private pathAnimalGroupPerformanceGraph = `${this.pathReports}/animal-group-performance/graph/{id}/`;
  private pathAnimalGroupNutritionPerformance = `${this.pathReports}/animal-group-nutrition/performance/{id}/`;
  private pathAnimalGroupWaterFlow = `${this.pathReports}/animal-group-nutrition/water-flow/{id}/`;
  private pathAnimalGroupNutritionFeed = `${this.pathReports}/animal-group-nutrition/feed/{id}/`;
  private pathAnimalGroupNutritionAnimalWeight = `${this.pathReports}/animal-group-nutrition/weight/{id}/`;
  private pathAnimalGroupFinancial = `${this.pathReports}/animal-group-financial/{id}/`;
  private pathAnimalGroupWeight = `${this.pathReports}/{type_production}/animal-group-weight/{id}/`;
  private pathAnimalGroupMovementGraph = `${this.pathReports}/animal-group-movement/graph/{id}/`;
  private pathAnimalGroupMovementTable = `${this.pathReports}/animal-group-movement/table/{id}/`;
  private pathAnimalGroupMovementPerformance = `${this.pathReports}/animal-group-movement/performance/{id}/`;
  private pathAnimalGroupSanityMortality = `${this.pathReports}/animal-group-sanity/mortality/{id}/`;
  private pathAnimalGroupSanityConsumption = `${this.pathReports}/animal-group-consumption-list/sanity/{id}/`;
  private pathAnimalGroupMovementTableAccommodation = `${this.pathReports}/animal-group-movement/table/accommodation/{id}/`;
  private pathAnimalGroupMovementTableMortality = `${this.pathReports}/animal-group-movement/table/mortality/{id}/`;
  private pathQuadrantAnimalGroup = `${this.pathBase}/{stage}/reports/quadrants/`;
  private pathAnimalGroupRecordDocument = `${this.pathBase}/farms/{farm_id}/animal-groups/{id}/record-document/`;

  constructor(
    private apiCorpService: ApiCorpService,
    private typeProductionService: TypeProductionService,
    private dateService: DateService,
    private downloadFileService: DownloadFileService,
  ) {
    super();
  }

  searchAnimalFarmGroup(
    holdingId: string,
    term: string,
    pageSize: number,
  ): Observable<SearchAnimalGroupFarm> {
    const url = this.apiCorpService.translateUrl(this.pathSearchAnimalGroupFarm, {
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    });
    const params = { search: term, page_size: pageSize };
    return this.apiCorpService
      .get<SearchAnimalGroupFarm>(url, params, true)
      .pipe(map(groupFarm => this.jsonDataToSearchAnimalGroupFarm(groupFarm)));
  }

  getAnimalGroup(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<AnimalGroupRecord> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupReport, params);
    return this.apiCorpService
      .get<AnimalGroup>(url)
      .pipe(map(animalGroup => ({ ...animalGroup, detail: this.buildDetail(animalGroup) })));
  }

  getAnimalGroupPerformance(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<AnimalGroupResume> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupPerformance, params);
    return this.apiCorpService
      .get<AnimalGroupPerformance[]>(url)
      .pipe(map(response => new AnimalGroupResume('performance', response)));
  }

  getAnimalGroupPerformances(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<AnimalGroupResume[]> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupPerformance, params);
    return this.apiCorpService.get<AnimalGroupResume[]>(url);
  }

  getAnimalGroupLayersPerformanceGraph(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
    fixedIndex: string,
  ): Observable<PerformanceItems> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupPerformanceGraph, params);

    return this.apiCorpService.get(url, { fixed_key: fixedIndex });
  }

  getAnimalGroupFinancial(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<AnimalGroupResume> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupFinancial, params);
    return this.apiCorpService
      .get<AnimalGroupPerformance[]>(url)
      .pipe(map(response => new AnimalGroupResume('financial', response)));
  }

  getAnimalGroupWeight(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<WeightTableItem[]> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupWeight, params);
    return this.apiCorpService.get<WeightTableItem[]>(url);
  }

  getAnimalGroupMovementGraph(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<MovementGraph> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupMovementGraph, params);
    return this.apiCorpService.get<MovementGraph>(url);
  }

  getAnimalGroupMovementTable(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<MovementTable> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupMovementTable, params);
    return this.apiCorpService.get<MovementTable>(url);
  }

  getAnimalGroupNutritionPerformance(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<AnimalGroupResume> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupNutritionPerformance, params);
    return this.apiCorpService
      .get<AnimalGroupPerformance[]>(url)
      .pipe(map(response => new AnimalGroupResume('nutrition', response)));
  }

  getAnimalGroupMovementPerformance(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<AnimalGroupResume> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupMovementPerformance, params);
    return this.apiCorpService
      .get<AnimalGroupPerformance[]>(url)
      .pipe(map(response => new AnimalGroupResume('movement', response)));
  }

  getAnimalGroupNutritionFeed(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
    aggregateBy?: string,
  ): Observable<AnimalGroupNutritionFeed> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const query_params = aggregateBy ? { aggregate_by: aggregateBy } : {};
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupNutritionFeed, params);
    return this.apiCorpService.get(url, query_params);
  }

  getAnimalGroupNutritionWeight(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<AnimalGroupDailyData> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupNutritionAnimalWeight, params);
    return this.apiCorpService.get(url);
  }

  getAnimalGroupList(
    holdingId: string,
    searchFilter: SearchFilterAnimalGroupList,
    stage: StageEnum,
  ): Observable<SearchAnimalGroupList> {
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupList, {
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
      stage: stage,
    });
    return this.apiCorpService
      .get<SearchAnimalGroupList>(url, searchFilter)
      .pipe(map(response => this.parsePerformances(response, searchFilter)));
  }

  getAnimalGroupMovementTableAccommodation(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<MovementTable> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(
      this.pathAnimalGroupMovementTableAccommodation,
      params,
    );
    return this.apiCorpService.get<MovementTable>(url);
  }

  getAnimalGroupMovementTableMortality(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<MovementTable> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(
      this.pathAnimalGroupMovementTableMortality,
      params,
    );
    return this.apiCorpService.get<MovementTable>(url);
  }

  getAnimalGroupSanityMortality(
    typeProduction: TypeProductionEnum,
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<AnimalGroupMortality>;
  getAnimalGroupSanityMortality(
    typeProduction: TypeProductionEnum.POULTRY,
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<AnimalGroupMortalityPoultry>;
  getAnimalGroupSanityMortality(
    typeProduction: TypeProductionEnum.SWINES,
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<AnimalGroupMortalitySwines>;
  getAnimalGroupSanityMortality(
    typeProduction: TypeProductionEnum,
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<AnimalGroupMortality> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupSanityMortality, params);
    return this.apiCorpService.get<AnimalGroupMortality>(url);
  }

  getAnimalGroupConsumption(
    typeProduction: TypeProductionEnum,
    holdingId: string,
    idFarm: string,
    idAnimalGroup: string,
  ): Observable<ConsumptionList> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupSanityConsumption, params);
    return this.apiCorpService.get<ConsumptionList>(url);
  }

  getQuadrantAnimalGroupList(
    holdingId: string,
    stage: string,
    searchFilter: SearchFilterQuadrantAnimalGroup,
  ): Observable<QuadrantAnimalGroup> {
    const params = {
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
      stage: stage,
    };
    const url = this.apiCorpService.translateUrl(this.pathQuadrantAnimalGroup, params);
    searchFilter.system_type = this.typeProductionService.getId();
    return this.apiCorpService.get(url, searchFilter);
  }

  getWaterFlow(
    holdingId: string,
    idFarm: string,
    idAnimalGroup: string,
  ): Observable<WaterFlowTableItem[]> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupWaterFlow, params);
    return this.apiCorpService.get<WaterFlowTableItem[]>(url);
  }

  downloadList(params: AnimalGroupListDownloadArgs): Observable<DownloadStatus> {
    const { holding_id, stage, ...queryParams }: AnimalGroupListDownloadArgs = {
      ...params,
      system_type: this.typeProductionService.get(),
      all: true,
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupList, {
      holding_id,
      stage,
    });
    return this.downloadFileService.download(url, params.data_format, queryParams as QueryParams);
  }

  getAnimalGroupRecordDocumentDowloadFile(
    holdingId: string,
    idFarm: string | number,
    idAnimalGroup: string,
  ): Observable<DownloadStatus> {
    const params = {
      farm_id: idFarm,
      id: idAnimalGroup,
      holding_id: holdingId,
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupRecordDocument, params);
    return this.downloadFileService.download(url, DocumentType.PDF);
  }

  update(
    holdingId: string,
    farmId: string,
    animalGroupForm: AnimalGroupForm,
    animalGroupId: string,
  ): Observable<AnimalGroupRecord> {
    const params = {
      farm_id: farmId,
      id: animalGroupId,
      holding_id: holdingId,
      system_type: this.typeProductionService.get(),
    };
    const url = this.apiCorpService.translateUrl(this.pathAnimalGroupReport, params);

    const body = {
      id: animalGroupId,
      ...animalGroupForm,
    };

    return this.apiCorpService
      .patch<AnimalGroup>(url, body, params)
      .pipe(map(animalGroup => ({ ...animalGroup, detail: this.buildDetail(animalGroup) })));
  }

  private jsonDataToSearchAnimalGroupFarm(jsonData: SearchAnimalGroupFarm): SearchAnimalGroupFarm {
    jsonData.results = jsonData.results.map(resultAnimalGroupFarm =>
      Object.assign(new ResultAnimalGroupFarm(), resultAnimalGroupFarm),
    );
    return jsonData;
  }

  private buildDetail(animalGroup: AnimalGroup): AnimalGroupRecordDetail {
    const { typeProduction } = environment;
    switch (typeProduction) {
      case TypeProductionEnum.SWINES:
        return this.buildSwineDetail(animalGroup);
      case TypeProductionEnum.LAYERS:
        return this.buildLayersDetail(animalGroup);
      case TypeProductionEnum.POULTRY:
        return this.buildPoultryDetail(animalGroup);
      default:
        throw new Error(`'buildDetail()' not implemented for production type ${typeProduction}`);
    }
  }

  private buildCommonDetail(animalGroup: AnimalGroup): AnimalGroupRecordDetail {
    const datePredictionOutgoing = animalGroup.is_open
      ? animalGroup.prediction_date
      : animalGroup.closed_date;

    return {
      incoming: animalGroup.incoming_date
        ? this.dateService.formatDate(animalGroup.incoming_date)
        : '-',
      outgoing: datePredictionOutgoing ? this.dateService.formatDate(datePredictionOutgoing) : '-',
    };
  }

  private buildLayersDetail(animalGroup: AnimalGroup): AnimalGroupRecordDetail {
    return {
      ...this.buildCommonDetail(animalGroup),
      farm: animalGroup.farm.name,
      location: animalGroup.locations.map(item => item.name).join(', ') || '-',
      total_animal_number_input: String(animalGroup.total_animal_number_input),
      age_at_transfer: String(animalGroup.age_at_transfer),
      production_system:
        animalGroup.locations.map(item => item.production_system).join(', ') || '-',
      site:
        animalGroup.locations.map(location => location.sites.map(site => site.name)).join(', ') ||
        '-',
      strain: animalGroup.strain,
      cycle_plan: animalGroup.cycle_plan,
    };
  }

  private buildSwineDetail(animalGroup: AnimalGroup): AnimalGroupRecordDetail {
    return {
      ...this.buildCommonDetail(animalGroup),
      producer: animalGroup.producer?.name || '-',
      location: animalGroup.locations.map(item => item.name).join(', ') || '-',
      technician: animalGroup.technician?.name || '-',
      gender: animalGroup.animal_group_type,
    };
  }

  private buildPoultryDetail(animalGroup: AnimalGroup): AnimalGroupRecordDetail {
    return {
      ...this.buildCommonDetail(animalGroup),
      producer: animalGroup.producer?.name || '-',
      location: animalGroup.locations.map(item => item.name).join(', ') || '-',
      technician: animalGroup.technician?.name || '-',
      installation_type: animalGroup.locations
        .map(item => (item.installation_type ? item.installation_type.name : ''))
        .join(', '),
      pen: animalGroup.pen,
      hatchery: animalGroup.hatcheries.map(item => item.name).join(', '),
      crossbred: animalGroup.crossbred?.name || '-',
      gender: animalGroup.animal_group_type,
    };
  }

  private parsePerformances(
    response: SearchAnimalGroupList,
    searchFilter: SearchFilterAnimalGroupList,
  ): SearchAnimalGroupList {
    if (searchFilter && !searchFilter.is_open) {
      return response;
    }
    response.results.forEach(result => {
      _.remove(result.performances, performance => performance.index_name === 'average_date_input');
      _.forEach(result.performances, performance => {
        if (performance.index_name === 'prediction_date_output') {
          performance.index_name = 'average_date_input';
          return;
        }
      });
    });
    return response;
  }
}
