import { Search, TypeProductionService, UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { Tank, TankFilter, TankForm } from '../models/tank.model';
import { TankAbstractService } from './tank-abstract.service';

@Injectable({
  providedIn: 'root',
})
export class TankService extends TankAbstractService {
  private pathBase = 'holdings/{holding_id}/tanks/';
  private pathTankId = 'holdings/{holding_id}/tanks/{tank_id}/';
  private defaultPageSize = 10;

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
    private typeProductionService: TypeProductionService,
  ) {
    super();
  }

  list({
    page = 1,
    page_size = this.defaultPageSize,
    queryCorpOnly = false,
    stage,
    ...filters
  }: TankFilter): Observable<Search<Tank>> {
    const url = this.getUrl();
    const params = {
      ...filters,
      page,
      page_size,
      system_type: this.typeProductionService.get(),
      stage,
      query_corp_only: queryCorpOnly,
    };

    return this.apiCorpService.get<Search<Tank>>(url, params);
  }

  save(tankForm: TankForm): Observable<Tank> {
    const url = this.getUrl();
    const params = {
      ...tankForm,
      system_type: this.typeProductionService.getId(),
    };

    return this.apiCorpService.post<Tank>(url, params);
  }

  update(tankForm: TankForm, tankId: string): Observable<Tank> {
    const url = this.getUrl(tankId);
    const params = {
      ...tankForm,
      system_type: this.typeProductionService.getId(),
    };

    return this.apiCorpService.put<Tank>(url, params);
  }

  delete(tank: Tank): Observable<void> {
    const url = this.getUrl(tank.id);
    const params = {
      system_type: this.typeProductionService.getId(),
    };
    return this.apiCorpService.delete(url, params);
  }

  private getUrl(tankId?: string): string {
    return this.apiCorpService.translateUrl(tankId ? this.pathTankId : this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
      tank_id: tankId,
    });
  }
}
