<ag-modal
  [visible]="visible"
  [showHeader]="modalStatus === ModalStatusEnum.DEFAULT ? showHeader : false"
  [title]="title"
  (hide)="hideModal()"
  [icon]="icon"
>
  <div class="corp-modal-form-container">
    <ag-modal-feedback
      [status]="modalStatus"
      [translationContext]="translationContext"
      (hide)="hideModal()"
    ></ag-modal-feedback>

    <div *ngIf="modalStatus === ModalStatusEnum.DEFAULT" [style]="contentWidth">
      <ng-content></ng-content>
    </div>
  </div>

  <ag-modal-footer *ngIf="modalStatus === ModalStatusEnum.DEFAULT">
    <div class="content-footer">
      <div>
        <guavi-button
          *ngIf="formRestore.observers.length"
          variant="secondary"
          format="circle"
          size="medium"
          icon="restore"
          data-testid="restore"
          title="{{ 'agriness.settings.restore' | translate }}"
          (onClick)="onRestore()"
        >
        </guavi-button>
      </div>

      <div>
        <guavi-button
          [disabled]="!formValid"
          variant="primary"
          format="regular"
          size="medium"
          data-testid="save"
          (onClick)="onSubmit()"
        >
          {{ 'agriness.save' | translate }}
        </guavi-button>
      </div>
    </div>
  </ag-modal-footer>
</ag-modal>
