<div *ngIf="feedbackType" style="display: flex; align-items: center; justify-content: center;">
  <ag-feedback [type]="feedbackType"></ag-feedback>
</div>

<ng-container *ngIf="!feedbackType">
  <div class="columns is-multiline is-variable is-1">
    <div
      *ngFor="let card of cards"
      class="column is-half-tablet is-one-third-desktop is-one-quarter-widescreen is-one-fifth-fullhd"
    >
      <ag-card [data]="card" [link]="card.link"></ag-card>
    </div>
  </div>
</ng-container>
