<section class="section is-full">
  <div class="mb-3">
    <div class="d-flex">
      <corp-filters-container #filtersContainer (filter)="filter()" (resetFilter)="filterReset()">
        <corp-textsearch-filter
          [filterEntity]="filterEntity"
          [queryParamName]="queryParamName"
          class="col"
        ></corp-textsearch-filter>
        <corp-farm-bond-filter class="col"></corp-farm-bond-filter>
      </corp-filters-container>
    </div>
    <div>
      <corp-report-filters-subtitle></corp-report-filters-subtitle>
    </div>
  </div>

  <div class="row mb-5">
    <div class="col">
      <corp-record-table
        #table
        [title]="translationContext + '.selected' | translate"
        (addClick)="onAddTechnician()"
        [tableColumns]="tableColumns"
        [defaultOrdering]="defaultOrdering"
        [data]="technicians"
        [totalRecords]="totalRecords"
        [loading]="loading"
        [error]="error"
        [actions]="availableActions"
        (actionClick)="onItemAction($event)"
        (pageRequest)="onPageRequest($event)"
        [shouldBlockAction]="shouldBlockAction"
        [blockedIconTooltipKey]="translationContext + '.table.blocked_tooltip'"
      >
      </corp-record-table>
    </div>
  </div>

  <technician-editor
    #editor
    *ngIf="editorModalVisible"
    [visible]="editorModalVisible"
    [technicianData]="selectedTechnician"
    [modalStatus]="editorModalStatus"
    (closed)="onEditorClosed()"
    (createTechnician)="createTechnician()"
    (updateTechnician)="updateTechnician()"
  ></technician-editor>

  <corp-modal-deletion
    [visible]="deletionModalVisible"
    [modalStatus]="deletionModalStatus"
    title="{{ translationContext + '.deletion' | translate }}"
    (delete)="onDeletionModalDelete()"
    (cancel)="onDeletionModalClose()"
  >
    <deletion-table [data]="deletionTableData"></deletion-table>
  </corp-modal-deletion>
</section>
