import { TargetStatusKpiService } from '@agriness/corp-app/services';
import { ReportFilterQueryKeys } from '@agriness/corp-app/shared/model/report-filter.model';
import { StageEnum, TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { TargetStatus } from '@agriness/ui';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { FilterComponent } from '../corp-filter-abstractions';
import { CorpFiltersContainerService } from '../corp-filters-container.service';

type SelectGroupOptionType = {
  id: string;
  name: string;
  group?: string;
}[];

@Component({
  selector: 'corp-target-status-kpi-filter',
  styleUrls: ['./filter.module.scss'],
  templateUrl: './corp-target-status-kpi.component.html',
  providers: [{ provide: FilterComponent, useExisting: CorpTargetStatusKpiFilterComponent }],
})
export class CorpTargetStatusKpiFilterComponent extends FilterComponent implements OnInit {
  @Input() targetStatusList = [TargetStatus.GOOD, TargetStatus.ATTENTION, TargetStatus.BAD];
  @Input() stage: StageEnum;

  items = new BehaviorSubject<SelectGroupOptionType>([]);
  placeholder: string;
  loading = false;

  labelKey = 'agriness.filter.target_status_kpi.label';
  controlName = 'targetStatusKpi';
  queryParamName = null; // legacy uses a list

  constructor(
    private targetStatusKpiService: TargetStatusKpiService,
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    containerService: CorpFiltersContainerService,
    activeRoute: ActivatedRoute,
  ) {
    super(containerService, activeRoute);

    this.placeholder = this.t('agriness.filter.target_status.all');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchOptionsForSelect();
  }

  getValuesInQueryFormat(): { [key in ReportFilterQueryKeys]?: string | number } {
    const [targetStatusKpi, targetStatus] = this.getLegacyFormValue(this.controlName);

    return {
      target_status_kpi: targetStatusKpi,
      target_status: targetStatus,
    };
  }

  collectSubtitle(): [string, string] {
    const [key, status] = this.getLegacyFormValue(this.controlName);

    if (isEmpty(key)) {
      return [
        this.t('agriness.filter.target_status_kpi.all'),
        this.t('agriness.filter.target_status.all'),
      ];
    }

    const translateKey = this.t(`agriness.animal_group_list.${key}`);
    const translateValue = this.t(`agriness.filter.target_status.${status}`);

    return [this.t(this.labelKey), `${translateKey} - ${translateValue}`];
  }

  async getInitialGroupData(): Promise<Record<string, [unknown]>> {
    // Should we require both params?
    const value = ['target_status_kpi', 'target_status']
      .map(key => this.getQueryParam(key))
      .filter(it => it?.length)
      .join(' - ');

    return Promise.resolve({
      [this.controlName]: [value],
    });
  }

  async getResetData(): Promise<Record<string, unknown>> {
    return Promise.resolve({
      [this.controlName]: '',
    });
  }

  private getLegacyFormValue(controlName: string): string[] {
    return (this.getFormValue<string>(controlName) || '').split(' - ');
  }

  private fetchOptionsForSelect(): void {
    this.loading = true;
    const initialItems = [
      {
        id: '',
        name: this.t('agriness.filter.target_status.all'),
        group: this.t('agriness.filter.target_status_kpi.all'),
      },
    ];
    this.targetStatusKpiService.list({ stage: this.stage }).subscribe(({ results }) => {
      this.items.next(
        results.reduce((items, { key }) => {
          return [
            ...items,
            ...this.targetStatusList.map(status => ({
              id: `${key} - ${status}`,
              name: this.t(`agriness.filter.target_status.${status}`),
              group: this.t(`agriness.animal_group_list.${key}`),
            })),
          ];
        }, initialItems),
      );
      this.loading = false;
    });
  }
}
