import { ReportEnum } from '@agriness/corp-app/services';
import { StageEnum } from '@agriness/services';

export type Link = {
  url: string;
  queryParams: {
    [k: string]: string | number;
  };
};

export enum LinkSection {
  MONITOR = 'monitor',
  IDENTIFY = 'identify',
  ANALYSIS = 'analysis',
}

export type LinkData = {
  stage: StageEnum;
  section: LinkSection;
  report: ReportEnum;
  additionalPath?: string[];
  queryParams: { [k: string]: string | number };
};
