import { SiteEnum, SiteSystemType, TypeProductionService } from '@agriness/services';
import { SiteName } from '@agriness/services/analytics/analytics.model';
import { AmplitudeAnalyticsService } from '@agriness/services/analytics/analytics.service';
import { SectionModel } from '@agriness/ui';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FinishingService {
  constructor(
    private typeProductionService: TypeProductionService,
    private analyticsService: AmplitudeAnalyticsService,
  ) {}
  getSections(): SectionModel[] {
    this.analyticsService.logSiteClick(SiteName.SITE_3);
    const stage = SiteSystemType[this.typeProductionService.get()][SiteEnum.SITE_3];
    const prefix = `/${this.typeProductionService.get()}/${stage}`;
    const sections: SectionModel[] = [
      {
        label: 'agriness.monitor.title',
        id: 'section-monitor',
        routerLink: `${prefix}/monitor`,
      },
      {
        label: 'agriness.identify.title',
        id: 'section-identify',
        routerLink: `${prefix}/identify`,
      },
      {
        label: 'agriness.analysis',
        id: 'section-analysis',
        routerLink: `${prefix}/analysis`,
      },
      {
        label: 'agriness.labs.title',
        id: 'section-labs',
        routerLink: `${prefix}/labs`,
      },
    ];
    return sections;
  }
}
