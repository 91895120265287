import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { SharedModule } from '../../common/shared.module';
import { PipesModule } from '../../pipe/pipes.module';
import { AgTabsComponent } from './ag-tabs.component';

@NgModule({
  imports: [SharedModule, PipesModule],
  declarations: [AgTabsComponent],
  exports: [AgTabsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgTabsModule {}
