import { defaultRuleOrder, Rule, RuleFilter, ModalStatusEnum } from '@agriness/corp-app/services';
import { BonificationService } from '@agriness/corp-app/services/bonification/bonification.service';
import { CorpFiltersContainerComponent } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.component';
import { CorpFiltersContainerService } from '@agriness/corp-app/shared/component/corp-filters/corp-filters-container.service';
import {
  ActionEnum,
  CorpRecordTableComponent,
} from '@agriness/corp-app/shared/component/corp-record-table/corp-record-table.component';
import { SectionAbstractComponent } from '@agriness/corp-app/shared/component/section-abstract.component';
import {
  ReportFilter,
  ReportFilterQueryKeys,
} from '@agriness/corp-app/shared/model/report-filter.model';
import { PaginationRequest } from '@agriness/corp-app/shared/model/table.model';
import { TranslateInstant, TRANSLATE_INSTANT } from '@agriness/services';
import { QueueItem, QueueManagerService, SectionModel, SectionService } from '@agriness/ui';
import { Component, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { catchError, first, map } from 'rxjs/operators';

import { DeletionTableData } from '../../deletion-table/deletion-table.model';
import { rulesDeletionTableFields, rulesTableColumns } from './payment-rule.model';
import { RuleEditorComponent } from './rule-editor/rule-editor.component';
@Component({
  selector: 'app-payment-rule',
  templateUrl: './payment-rule.component.html',
  styleUrls: ['./payment-rule.component.scss'],
  providers: [CorpFiltersContainerService.provider],
})
export class PaymentRuleComponent extends SectionAbstractComponent {
  @ViewChild('filtersContainer', { static: true }) reportFilter: CorpFiltersContainerComponent;
  @ViewChild('table', { static: true }) table: CorpRecordTableComponent<Rule>;
  @ViewChild('editor') ruleEditor: RuleEditorComponent;

  translationContext = 'agriness.settings.rules';

  tableColumns = rulesTableColumns;
  defaultOrdering = defaultRuleOrder as unknown;
  availableActions = [ActionEnum.EDIT, ActionEnum.TOGGLE];
  rules: Rule[];
  totalRecords: number;
  rows = 10;

  editorModalVisible = false;
  editorModalStatus = ModalStatusEnum.DEFAULT;
  selectedRule: Rule;
  deletionModalVisible = false;
  deletionModalStatus = ModalStatusEnum.DEFAULT;
  deletionTableData: DeletionTableData;

  error = false;
  loading = true;
  currentFilter: RuleFilter = {};
  filterEntity: keyof ReportFilter = 'rules';
  queryParamName: ReportFilterQueryKeys = 'rule_name';

  private verbose = true;

  constructor(
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
    private ruleService: BonificationService,
    private router: Router,
    private route: ActivatedRoute,
    private queueManager: QueueManagerService,
    protected sectionService: SectionService,
  ) {
    super(sectionService);
  }

  filter(): void {
    this.table.reset();
  }

  filterReset(): void {
    this.reportFilter.onFilter();
  }

  onEditorClosed(): void {
    this.selectedRule = null;
    this.editorModalVisible = false;
    this.editorModalStatus = ModalStatusEnum.DEFAULT;
    this.filter();
  }

  onAddRoute(): void {
    void this.router.navigate(['add'], { relativeTo: this.route });
  }

  onItemAction(obj: { item: Rule; action: ActionEnum; checked?: boolean }): void {
    switch (obj.action) {
      case ActionEnum.EDIT:
        void this.router.navigate(['edit'], { state: obj.item, relativeTo: this.route });
        break;
      case ActionEnum.DELETE:
        this.selectedRule = obj.item;
        this.updateDeletionTableData(this.selectedRule);
        this.deletionModalVisible = true;
        break;
      case ActionEnum.TOGGLE:
        this.updateEnableRule(obj.item, obj.checked);
        break;
    }
  }

  updateEnableRule(item: Rule, enabled: boolean): void {
    this.addOperationToQueue(this.ruleService.patch({ enabled: enabled }, item.id));
  }

  addOperationToQueue(ruleObservable: Observable<Rule>): void {
    const t = (key: string): string => this.t(`${this.translationContext}.feedback.${key}`);
    this.queueManager
      .add(ruleObservable)
      .pipe(
        switchMap(({ payload: rule, actions }: QueueItem) => {
          return (rule as Observable<Rule>).pipe(
            tap((rule: Rule) => {
              actions.success(rule.enabled ? t('rule_enabled') : t('rule_disabled'));
            }),
            catchError(err => {
              return throwError(err);
            }),
          );
        }),
        catchError(err => {
          return throwError(err);
        }),
      )
      .subscribe();
  }

  onDeletionModalClose(): void {
    this.selectedRule = null;
    this.deletionModalVisible = false;
    this.deletionModalStatus = ModalStatusEnum.DEFAULT;
    this.filter();
  }

  onDeletionModalDelete(): void {
    this.deletionModalStatus = ModalStatusEnum.LOADING;

    this.ruleService.delete(this.selectedRule).subscribe({
      complete: () => {
        this.deletionModalStatus = ModalStatusEnum.SUCCESS;
      },
      error: error => {
        this.deletionModalStatus = ModalStatusEnum.FAILED;
        throw error;
      },
    });
  }

  shouldBlockAction(obj: { action: ActionEnum; item: Rule }): boolean {
    return obj.action == ActionEnum.DELETE ? !obj.item.can_delete : false;
  }

  onPageRequest(request: PaginationRequest): void {
    this.loading = true;
    this.updateCurrentFilter(request);
    this.loadRules();
  }

  protected getSections(): SectionModel[] {
    return [];
  }

  private loadRules(): void {
    this.ruleService
      .list(this.currentFilter)
      .pipe(
        first(),
        map(({ count, results }) => {
          const resultsFiltered: Rule[] = [];

          results.forEach(res => {
            resultsFiltered.push({ ...res, stage_name: res.stage.name });
          });

          this.totalRecords = count;
          this.loading = false;
          this.rules = resultsFiltered;
        }),
        catchError(error => {
          this.error = true;
          return throwError(error);
        }),
      )
      .subscribe();
  }

  private updateCurrentFilter(request: PaginationRequest): void {
    const name = this.reportFilter.getCurrentFilterInQueryFormat().rule_name;

    this.currentFilter = {
      ...this.currentFilter,
      ...request,
      name,
      verbose: this.verbose,
    } as unknown;
  }

  private updateDeletionTableData(rule: Rule): void {
    this.deletionTableData = rulesDeletionTableFields.map(field => {
      return {
        field: this.t(`agriness.settings.rules.table.${field}`),
        value: rule[field] as string,
      };
    });
  }
}
