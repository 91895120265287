import { TargetIndex, TargetIndexService } from '@agriness/corp-app/services';
import { SelectLoader } from '@agriness/corp-app/shared/component/corp-filters/corp-filter.service';
import { TRANSLATE_INSTANT, TranslateInstant } from '@agriness/services';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { MainForm, TargetConfigDataForm, TargetGroupsEnum } from '../target-editor-form.model';

export interface TargetGroupSelect {
  name: string;
  key: string;
}

export interface IndexSelect {
  name: string;
  key: string;
}

@Component({
  selector: 'app-target-config-general-data',
  templateUrl: './target-config-general-data.component.html',
  styleUrls: ['./target-config-general-data.component.scss'],
  providers: [TargetIndexService],
})
export class TargetConfigGeneralDataComponent implements OnInit {
  @Output() sendData = new EventEmitter<MainForm>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() isInvalid = new EventEmitter<boolean>();
  @Input() formData: BehaviorSubject<MainForm> = null;

  formDataSubscribed: MainForm = {
    id: null,
    period_type: null,
    stage: null,
    target_group: null,
    target_detail: null,
    target_index: null,
  };

  SelectedIndexes: string[] = [];

  form: FormGroup;

  data: TargetConfigDataForm = null;

  errorMessages = {
    name: '',
  };

  stage: string;

  targetGroupOptions: SelectLoader<unknown> = new SelectLoader<unknown>(null);
  indexOptions: SelectLoader<unknown> = new SelectLoader<unknown>(null);
  targetGroupSelectData: BehaviorSubject<TargetGroupSelect[]>;
  indexData: BehaviorSubject<IndexSelect[]>;
  indexList: TargetIndex[];
  protected loadSubscription = Subscription.EMPTY;

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private serviceIndex: TargetIndexService,
    @Inject(TRANSLATE_INSTANT) private t: TranslateInstant,
  ) {}

  ngOnInit(): void {
    this.stage = window.location.pathname.split('/')[5];
    this.fillTargetGroups();

    this.formData.subscribe(res => {
      this.formDataSubscribed = res;
    });

    this.form = this.formBuilder.group({
      target_group: [null, Validators.required.bind(this)],
      index: [null, Validators.required.bind(this)],
    });

    if (this.formDataSubscribed !== null) {
      const targetGroup = Object.values(TargetGroupsEnum).find(
        val =>
          this.translateService.instant('agriness.targets.target_group.' + val) ==
          this.formDataSubscribed.target_index.group,
      );
      this.form.controls.target_group.setValue(
        targetGroup ? targetGroup.toUpperCase() : this.formDataSubscribed.target_group,
      );
      this.form.controls.index.setValue(this.formDataSubscribed.target_index.id);
      this.fillKPIS();
      this.validate();
    }

    this.form.valueChanges.subscribe(() => {
      this.validate();
    });
  }

  validate(): void {
    if (this.form.valid) {
      this.isValid.emit(true);
    } else {
      this.isValid.emit(false);
    }
  }

  fillTargetGroups(): void {
    const data: TargetGroupSelect[] = [];

    Object.values(TargetGroupsEnum).forEach(val => {
      data.push({
        name: this.translateService.instant('agriness.targets.target_group.' + val) as string,
        key: val.toUpperCase(),
      });
    });

    this.targetGroupSelectData = new BehaviorSubject(data);
  }

  fillKPIS(): void {
    this.indexOptions = new SelectLoader(this.serviceIndex, {
      group: this.form.controls.target_group.value,
      locale: this.translateService.currentLang,
      stage: this.stage,
    });
    this.loadSubscription = this.indexOptions
      .loadOnFilter()
      .pipe(
        map(res => {
          const data: IndexSelect[] = [];
          const results: TargetIndex[] = res.results as TargetIndex[];

          results.forEach((value: TargetIndex) => {
            data.push({
              key: value.id,
              name: value.name,
            });
          });

          this.indexList = res.results as TargetIndex[];

          this.indexData = new BehaviorSubject(data);
          return data;
        }),
      )
      .subscribe();
  }

  setFormData(): void {
    const data: TargetConfigDataForm = this.form.getRawValue() as TargetConfigDataForm;
    const indexSelected = this.indexList.find(item => item.id === data.index);
    const formData: MainForm = {
      id: this.formDataSubscribed?.id ? this.formDataSubscribed.id : null,
      target_group: this.form.controls.target_group.value as string,
      period_type: this.formDataSubscribed ? this.formDataSubscribed.period_type : null,
      stage: this.stage,
      target_detail: this.formDataSubscribed ? this.formDataSubscribed.target_detail : null,
      target_index: {
        name: indexSelected.name,
        representation_type: indexSelected.representation_type,
        stage: indexSelected.stage,
        group: indexSelected.group,
        id: indexSelected.id,
        max_allowed: indexSelected.max_allowed,
        min_allowed: indexSelected.min_allowed,
      },
    };

    this.formDataSubscribed = formData;

    this.sendData.emit(formData);
  }
}
