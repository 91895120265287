<div class="death-table">
  <div class="columns is-gapless is-mobile">
    <div class="column">
      <table class="table">
        <thead>
          <tr>
            <th>{{ 'agriness.date.week.name' | translate }}</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let row of rows">
            <td>{{ row.name | translate }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="border-bottom">
              <div class="total">
                <span class="value">{{ 'agriness.total' | translate }}</span>
                <span class="percentage">%</span>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="column is-10">
      <div class="scroll-shadow">
        <div class="scroll">
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th *ngFor="let col of columns">
                  {{ col.header || col.field | translate }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let row of rows">
                <td
                  *ngFor="let col of columns"
                  [ngClass]="statusClasses[getStatus(rowsData[row.field][col.field]?.value)]"
                >
                  <ng-container
                    *ngTemplateOutlet="
                      cellContent;
                      context: { $implicit: rowsData[row.field][col.field] }
                    "
                  ></ng-container>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td *ngFor="let col of columns">
                  <div class="total">
                    <ng-container
                      *ngTemplateOutlet="cellContent; context: { $implicit: footerData[col.field] }"
                    ></ng-container>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <div class="column">
      <table class="table">
        <thead>
          <tr>
            <th>{{ 'agriness.total' | translate }}</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let row of rows">
            <td>
              <div class="total">
                <ng-container
                  *ngTemplateOutlet="
                    cellContent;
                    context: { $implicit: rowsData[row.field]?.total }
                  "
                ></ng-container>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="border-bottom">
              <div class="total">
                <ng-container
                  *ngTemplateOutlet="cellContent; context: { $implicit: footerData.total }"
                ></ng-container>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <footer class="death-table-footer">
    <span class="text is-small mr-1">{{ 'agriness.death_table.scale.smaller' | translate }}</span>
    <span
      *ngFor="let status of deathTableStatus"
      [ngClass]="statusClasses[status]"
      class="legend-item"
    ></span>
    <span class="text is-small ml-1">{{ 'agriness.death_table.scale.bigger' | translate }}</span>
  </footer>
</div>

<ng-template #cellContent let-cell>
  <span class="value">
    <ag-format [value]="cell.value" [style]="valueFormatStyle.UNITARY"></ag-format>
  </span>
  <span *ngIf="cell.percentage != null" class="percentage">
    <ag-format [value]="cell.percentage" [style]="valueFormatStyle.PERCENTAGE"></ag-format>
  </span>
</ng-template>
