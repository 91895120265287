import { AgrinessUiModule } from '@agriness/ui';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';

import { SharedModule } from '../../shared/shared.module';
import { AnalysisRoutingModule } from './analysis-routing.module';
import { AnalysisComponent } from './analysis.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { IndicatorsComponent } from './indicators/indicators.component';
import { OnePageReportComponent } from './onepage-report/onepage-report.component';
import { RecordListComponent } from './record-list/record-list.component';

@NgModule({
  imports: [
    SharedModule,
    AnalysisRoutingModule,
    DropdownModule,
    TableModule,
    ReactiveFormsModule,
    AgrinessUiModule,
  ],
  declarations: [
    AnalysisComponent,
    DashboardComponent,
    IndicatorsComponent,
    OnePageReportComponent,
    RecordListComponent,
  ],
  exports: [AnalysisComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NurseryAnalysisModule {}
