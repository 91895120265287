import { UserStorageService } from '@agriness/services';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiCorpService } from '../api-corp.service';
import { LookerUrl, LookerData } from '../models/looker.model';

@Injectable()
export class LookerService {
  private pathBase = 'holdings/{holding_id}/reports/looker-report/{looker_panel_id}/';

  constructor(
    private apiCorpService: ApiCorpService,
    private userStorageService: UserStorageService,
  ) {}

  getEmbeddedUrl(looker_panel_id: string, data: LookerData): Observable<LookerUrl> {
    const url = this.apiCorpService.translateUrl(this.pathBase, {
      holding_id: this.userStorageService.getCurrentHolding()?.id,
      looker_panel_id: looker_panel_id,
    });

    return this.apiCorpService.get<LookerUrl>(url, data);
  }
}
