import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../common/shared.module';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { HelpMenuComponent } from './help-menu/ag-help-menu.component';
import { HoldingMenuComponent } from './holding-menu/holding-menu.component';
import { AgRootComponent } from './root/ag-root.component';
import { SectionComponent } from './section/section.component';
import { AgSideMenuComponent } from './side-menu/ag-side-menu.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

@NgModule({
  imports: [NgbDropdownModule, SharedModule],
  declarations: [
    AgRootComponent,
    SectionComponent,
    AgSideMenuComponent,
    HoldingMenuComponent,
    AppMenuComponent,
    UserMenuComponent,
    HelpMenuComponent,
  ],
  exports: [AgRootComponent, AgSideMenuComponent, UserMenuComponent, HelpMenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgLayoutModule {}
