<div class="bulk-actions" [ngStyle]="{ width: changeWidthSize(chatWidth) }">
  <div class="bullk" *ngFor="let item of items">
    <ng-container *ngTemplateOutlet="bullkBody; context: { $implicit: item }"></ng-container>
  </div>
</div>

<ng-template #bullkBody let-item>
  <div class="bullk-body">
    <button *ngIf="item.clean" class="btn btn-ghost" (click)="item.clean()">
      <span class="icon icon-check-box-indeterminate is-18"></span>
    </button>
    <span
      class="message"
      [innerHTML]="
        (item.quantity === 1
          ? 'agriness.bulk_action.message.one'
          : 'agriness.bulk_action.message.other'
        ) | translate: { quantity: item.quantity, total: item.total }
      "
    ></span>

    <ng-container *ngFor="let button of item.buttons">
      <button class="btn btn-ghost ml-5" (click)="button.action()">
        <span *ngIf="button?.icon" class="icon is-20 mr-1" [ngClass]="button.icon"></span>
        {{ button.text }}
      </button>
    </ng-container>
  </div>
</ng-template>
