<div [formGroup]="group">
  <ng-select
    #select
    [items]="items$ | async"
    [multiple]="false"
    [bindLabel]="optionLabel"
    [placeholder]="defaultLabel || '' | translate"
    [closeOnSelect]="false"
    [bindValue]="dataKey"
    [virtualScroll]="true"
    [searchable]="false"
    [clearOnBackspace]="false"
    [clearable]="clearable"
    [clearAllText]="clearText | translate"
    [formControlName]="controlName"
    [attr.name]="controlName"
    groupBy="group"
    [readonly]="isDisabled"
    (scrollToEnd)="scrollToEnd.emit()"
    (remove)="onRemove()"
    (open)="onOpen()"
    (close)="onClose()"
    [loading]="loading"
    [notFoundText]="notFoundText | translate"
    [loadingText]="loadingText | translate"
    (change)="onChange($event)"
    ngDefaultControl
  >
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div class="ng-value" *ngIf="items?.length == 1">
        <span class="ng-value-label">{{ items[0].name }}</span>
      </div>
    </ng-template>
    <ng-template ng-header-tmp *ngIf="isSearchable">
      <div class="form-group">
        <input
          #searchInput
          data-testid="search"
          [value]="searchValue"
          (input)="onSearch($event)"
          class="ng-input-search"
          type="text"
        />
        <span class="icon icon-search form-icon"></span>
      </div>
    </ng-template>
    <ng-template ng-optgroup-tmp let-item="item">
      <div class="ng-select-group-label">
        <span>{{ item.group }}</span>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <span class="ng-option-label">{{ item.name }}</span>
    </ng-template>
  </ng-select>
  <div class="input-feedback" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
</div>
