import { TargetStatus } from '@agriness/ui';
import { SortEvent } from 'primeng/api';

export interface RowData<T> {
  [key: string]: RowObject;
  object?: T;
}

export interface RowObject {
  value?: any;
  id?: string;
  type?: string;
  action?: boolean;
  measurement_unit?: any;
  decimal_places?: any;
  status?: TargetStatus;
}

export interface Column {
  id?: string;
  field?: string;
  header?: string;
  width?: string;
  tooltip?: string;
}

export interface Action {
  event: () => void;
  icon: string;
  tooltip?: string;
}

export function onSortRowObject({ data, field, order }: SortEvent): void {
  data.sort((data1: RowData<unknown>, data2: RowData<unknown>) => {
    let value1;
    let value2;
    let result;

    if (field === 'date') {
      value1 = data1[field];
      value2 = data2[field];
    } else {
      value1 = (data1[field]?.value as number) ?? null;
      value2 = (data2[field]?.value as number) ?? null;
    }

    if (value1 == null && value2 != null) {
      result = -1;
    } else if (value1 != null && value2 == null) {
      result = 1;
    } else if (value1 == null && value2 == null) {
      result = 0;
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
    }

    return order * result;
  });
}

export interface PaginationRequest {
  page: number;
  page_size: number;
  order: string;
}
