<corp-modal-form
  [title]="translationContext + '.editor.title' | translate"
  [modalStatus]="modalStatus"
  [visible]="visible"
  [formValid]="farmForm.valid"
  (closed)="onClosed()"
  (formSubmit)="onSubmit()"
  class="full-width"
>
  <div>
    <p class="subtitle">
      {{ translationContext + '.editor.subtitle' | translate }}
    </p>
  </div>

  <form class="full-width">
    <div class="form-container full-width">
      <ag-text-field
        class="form-field"
        [formGroup]="farmForm"
        controlName="name"
        [disabled]="true"
        [label]="translationContext + '.editor.fields.name.label' | translate"
      >
      </ag-text-field>

      <corp-producer-select
        class="form-field has-feedback"
        [form]="farmForm"
      ></corp-producer-select>
    </div>

    <div class="form-container full-width">
      <corp-region-select class="form-field fix-bottom" [form]="farmForm"></corp-region-select>

      <corp-technician-select
        class="form-field fix-bottom"
        [form]="farmForm"
      ></corp-technician-select>
    </div>
    <div class="form-container full-width" *ngIf="showRoute">
      <corp-route-select class="form-field" [form]="farmForm"></corp-route-select>
    </div>
  </form>
</corp-modal-form>
