<section class="section is-full">
  <div class="page-title d-flex align-items-center mb-3">
    <corp-back-button></corp-back-button>
    {{ pageTitle | translate }}
  </div>

  <corp-report-filters
    #reportFilter
    [stage]="stage"
    [availableFilters]="filterIds"
    (filter)="filter()"
    class="mb-3"
  ></corp-report-filters>

  <div class="row">
    <div class="col">
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="card-title d-flex align-items-center">
            {{ sectionTitle | translate }}

            <button (click)="download(DocumentType.CSV)" class="btn btn-ghost has-round-hover ml-2">
              <span class="icon is-16 icon-csv"></span>
            </button>

            <corp-preference-toggle-column
              #preferences
              [stage]="stage"
              [preferenceType]="preferenceType"
              buttonIconClass="icon-column-settings"
              [labelProvider]="preferenceLabelProvider"
              [hiddenItems]="hiddenColumns"
              (changes)="this.onPreferenceChange()"
            ></corp-preference-toggle-column>
          </div>

          <ag-table
            #dt
            [exportFilename]="downloadFilename"
            [loading]="loading"
            (lazyLoad)="loadLazy($event)"
            [frozenColumns]="frozenTableColumns"
            [columns]="scrollableTableColumns"
            [data]="animalGroupList"
            [error]="error"
            [rows]="rows"
            [rowsPerPageOptions]="rowsPerPageOptions"
            [totalRecords]="totalRecords"
            [noResultFeedback]="FeedbackEnum.NOT_RESULT_SEARCH"
            [trackEvent]="trackEvent"
          >
            <ng-template
              pTemplate="bodycell"
              let-col="column"
              let-data="data"
              let-rowData="rowData"
            >
              <td
                [ngSwitch]="data?.id"
                [attr.data-testid]="data?.id"
                [agTooltip]="isFrozenColumn(col) ? data.value : null"
              >
                <corp-record-list-farm-link
                  *ngSwitchCase="'farm-name'"
                  [animalGroup]="rowData.object.animal_group"
                  service="S4Farm"
                ></corp-record-list-farm-link>
                <corp-animal-group-link
                  *ngSwitchCase="'animal-group-name'"
                  [animalGroup]="rowData.object.animal_group"
                ></corp-animal-group-link>
                <span
                  *ngSwitchDefault
                  [ngClass]="{ 'font-weight-bold': data?.id === 'producer-name' }"
                >
                  <ag-table-cell-content
                    [data]="data"
                    [column]="col"
                    [showSymbol]="false"
                  ></ag-table-cell-content>
                </span>
              </td>
            </ng-template>
          </ag-table>
        </div>
      </div>
    </div>
  </div>
</section>
