import {
  AnimalGroupStatus,
  SiteEnum,
  SiteSystemType,
  StageEnum,
  UserStorageService,
} from '@agriness/services';
import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { snakeCase } from 'lodash';

import { AnimalGroupTab } from '../../record/animal-group/animal-group.model';
import { LoaderUserPreference } from '../../shared/component/loader-user-preference';
import { animalGroupTabsByStage } from './monitor.model';

@Component({
  templateUrl: './monitor.component.html',
})
export class FinishingMonitorComponent extends LoaderUserPreference {
  AnimalGroupStatus = AnimalGroupStatus;
  StageEnum = StageEnum;
  stage = SiteSystemType[environment.typeProduction][SiteEnum.SITE_3];
  titleKey = `agriness.monitor_${snakeCase(this.stage)}`;

  tabs: AnimalGroupTab[];

  constructor(protected userStorageService: UserStorageService) {
    super(userStorageService);
    this.tabs = animalGroupTabsByStage[this.stage];
  }
}
