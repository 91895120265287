<section>
  <div class="page-title d-flex align-items-center mb-3">
    <corp-back-button></corp-back-button>
    <span>{{ pageTitle | translate }}</span>
    <guavi-ghost-button
      icon="edit"
      variant="grey"
      size="medium"
      data-testid="button-edit"
      (click)="editTarget()"
    ></guavi-ghost-button>
  </div>

  <div class="target">
    <div class="target-container">
      <section *ngIf="target">
        <div class="full-width">
          <div class="badge-container title-container">
            <p class="subtitle">
              {{ 'agriness.settings.targets.editor.pre-badge' | translate }}
            </p>
            <p class="badge">
              {{ 'agriness.stage.' + target.stage.name | translate }}
            </p>
          </div>
        </div>
        <div class="full-width">
          <div class="badge-container title-container">
            <p class="subtitle">
              {{ ('agriness.settings.targets.editor.step_2.target_group' | translate) + ':' }}
            </p>
            <p class="plain-text">
              {{ target.target_index.group | translate }}
            </p>
          </div>
        </div>
        <div class="full-width">
          <div class="badge-container title-container">
            <p class="subtitle">
              {{ ('agriness.settings.targets.editor.step_2.index' | translate) + ':' }}
            </p>
            <p class="plain-text">
              {{ target.target_index.name | translate }}
            </p>
          </div>
        </div>
        <div class="full-width">
          <div class="badge-container title-container">
            <p class="subtitle">
              {{ ('agriness.settings.targets.editor.step_2.period_type.title' | translate) + ':' }}
            </p>
            <p class="plain-text">
              {{ target.period_type }}
            </p>
          </div>
        </div>

        <div class="table-container">
          <p-table [columns]="cols" [value]="target.target_detail">
            <ng-template pTemplate="header" let-columns>
              <tr class="theader">
                <th *ngFor="let col of columns">
                  <span>
                    <ag-target-indicator
                      *ngIf="col.field !== 'period'"
                      class="icon-content-center"
                      [attr.data-testid]="
                        'status-target-representation-icon-' + getStatusIndicator(col.field)
                      "
                      [status]="getStatusIndicator(col.field)"
                      [iconSize]="16"
                    >
                    </ag-target-indicator>
                    {{ col.header | translate }}
                  </span>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-target let-columns="columns">
              <tr>
                <td *ngFor="let col of columns">
                  <span>{{ target[col.field] }}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </section>
    </div>
  </div>
</section>
