import {
  HighchartsService,
  Highcharts,
} from '@agriness/corp-app/shared/services/highcharts.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Chart } from 'highcharts';

import { LineGraphDisplayData } from '../../../services/report-section/report-section-line-graph.model';
import { ReportSectionItem } from '../corp-report-section.model';

@Component({
  selector: 'corp-report-section-line-graph',
  templateUrl: './corp-report-section-line-graph.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorpReportSectionLineGraphComponent implements ReportSectionItem, OnChanges {
  @Input() icon = 'icon-report1';
  @Input() parentAsCard = true;
  @Input() data: LineGraphDisplayData;
  @ViewChild('actions', { static: true }) actions: TemplateRef<unknown>;
  @ViewChild('content', { static: true }) content: TemplateRef<unknown>;

  Highcharts: Highcharts;
  chart: Chart;

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private highchartsService: HighchartsService,
  ) {
    this.Highcharts = highchartsService.getHighchartsInstance();
  }

  ngOnChanges(): void {
    this.changeDetectionRef.detectChanges();
  }

  registerChart(chart: Chart): void {
    this.chart = chart;
  }
}
